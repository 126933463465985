define("donor-elf-web/templates/admin/users/not-found", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "RBF9HtbY",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,\"h3\"],[14,0,\"mb-4\"],[12],[1,\"Can't find the user\"],[13],[1,\"\\n  \"],[10,0],[14,0,\"alert alert-danger\"],[12],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      Whoops, it looks like this user no longer exists. Did you delete it?\\n    \"],[13],[1,\"\\n    \"],[10,2],[14,0,\"mb-0\"],[12],[1,\"\\n      Please email us at \"],[10,3],[14,6,\"mailto:support@donorelf.com\"],[12],[1,\"support@donorelf.com\"],[13],[1,\"\\n      if you think this error should not have occurred. We'll look into it for you.\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[6,[39,1],null,[[\"class\",\"activeClass\",\"route\"],[\"btn btn-outline-brand\",\"\",\"admin.users\"]],[[\"default\"],[[[[1,\"Go to all Users\"]],[]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"de-form-card\",\"link-to\"]]",
    "moduleName": "donor-elf-web/templates/admin/users/not-found.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});