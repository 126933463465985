define("donor-elf-web/validations/journal-group", ["exports", "@ember/utils", "ember-changeset-validations/validators"], function (_exports, _utils, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: (0, _validators.validatePresence)({
      presence: true,
      message: 'Name is required'
    }),
    journalIds: (0, _validators.validateLength)({
      min: 1,
      message: 'At least one journal is required'
    }),
    users: (_key, newValue, _oldValue, _changes, _content) => {
      if ((0, _utils.isEmpty)(newValue)) {
        return 'At least one user is required';
      }

      if (newValue.every(u => !u.is_owner)) {
        return 'At leaset one user must be an owner';
      }

      return true;
    }
  };
  _exports.default = _default;
});