define("donor-elf-web/controllers/coaching/individuals/individual/edit-goal", ["exports", "@ember/controller", "@ember/service", "ember-concurrency", "@ember/object", "@ember/object/computed", "@ember/utils"], function (_exports, _controller, _service, _emberConcurrency, _object, _computed, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    coachingService: (0, _service.inject)(),
    store: (0, _service.inject)(),
    changeset: (0, _computed.alias)('model.changeset'),
    reportingPeriodsToGoalDate: (0, _computed.alias)('model.individualRecord.reportingPeriodsToGoalDate'),
    error: null,

    reset() {
      (0, _object.setProperties)(this, {
        error: null
      });
    },

    isOverallGoal: (0, _object.computed)('model.field.t', function () {
      return (0, _object.get)(this, 'coachingService.overallGoalTypes')[(0, _object.get)(this, 'model.field.t')] || false;
    }),
    isIntervalGoal: (0, _object.computed)('model.field.t', function () {
      return (0, _object.get)(this, 'coachingService.intervalGoalTypes')[(0, _object.get)(this, 'model.field.t')] || false;
    }),
    save: (0, _emberConcurrency.task)(function* () {
      let changeset = (0, _object.get)(this, 'changeset');
      yield changeset.validate();

      if ((0, _object.get)(changeset, 'isValid')) {
        try {
          let individualRecord = (0, _object.get)(this, 'model.individualRecord');
          let field = (0, _object.get)(this, 'model.field');
          let {
            overallGoal,
            intervalGoal
          } = (0, _object.getProperties)(changeset, 'overallGoal', 'intervalGoal');

          if ((0, _utils.isEmpty)(overallGoal)) {
            delete field.overallGoal;
          } else {
            (0, _object.set)(field, 'overallGoal', Number(overallGoal));
          }

          if ((0, _utils.isEmpty)(intervalGoal)) {
            delete field.intervalGoal;
          } else {
            if ((0, _object.get)(field, 'intervalGoal') == null) {
              (0, _object.set)(field, 'intervalGoal', {});
            }

            (0, _object.set)(field, 'intervalGoal.low', Number(intervalGoal));
          }

          yield individualRecord.save();
          window.history.back();
        } catch (error) {
          (0, _object.set)(this, 'error', error);
        }
      }
    }).drop(),
    actions: {
      handleOverallGoalChanged(val) {
        let changeset = (0, _object.get)(this, 'changeset');
        (0, _object.set)(changeset, 'overallGoal', val);
        changeset.validate();

        if ((0, _object.get)(changeset, 'isValid')) {
          let intervalGoal = (0, _object.get)(this, 'coachingService').calculateIntervalGoal({
            individualRecord: (0, _object.get)(this, 'model.individualRecord'),
            overallGoal: val
          });
          (0, _object.set)(this, 'changeset.intervalGoal', intervalGoal);
        }
      }

    }
  });

  _exports.default = _default;
});