define("donor-elf-web/routes/admin/users/add", ["exports", "@ember/routing/route", "rsvp", "@ember/service", "ember-changeset", "ember-changeset-validations", "donor-elf-web/validations/admin/create-user"], function (_exports, _route, _rsvp, _service, _emberChangeset, _emberChangesetValidations, _createUser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AdminUserAddRoute = (_class = class AdminUserAddRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "abilities", _descriptor, this);

      _initializerDefineProperty(this, "navbarSettings", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "storeCache", _descriptor4, this);
    }

    beforeModel(transition) {
      if (this.abilities.noAbility('admin_edit_users')) {
        transition.abort();
        return;
      }

      this.navbarSettings.title = 'Add User';
    }

    async model() {
      let promise = _rsvp.default.hash({
        allUsers: this.storeCache.findAll('admin/user'),
        funds: this.storeCache.findAll('admin/fund'),
        roles: this.storeCache.findAll('admin/role')
      });

      let data = await promise;
      let validationInstance = (0, _createUser.default)({
        allUsers: data.allUsers
      });
      let record = this.store.createRecord('admin/create-user', {
        role: 'User'
      });
      let changeset = new _emberChangeset.default(record, (0, _emberChangesetValidations.default)(validationInstance), validationInstance);
      return {
        changeset: changeset,
        funds: data.funds,
        selectedFundIds: [],
        roles: data.roles
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "abilities", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "navbarSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "storeCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = AdminUserAddRoute;
});