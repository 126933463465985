define("donor-elf-web/controllers/funds/fund/journals/journal/report/intervarsity", ["exports", "@ember/controller", "@ember/object", "@ember/service", "ember-local-storage", "@glimmer/tracking", "ember-cached-decorator-polyfill"], function (_exports, _controller, _object, _service, _emberLocalStorage, _tracking, _emberCachedDecoratorPolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    Highcharts,
    currency,
    moment
  } = window;
  let JournalReportIntervarsityController = (_dec = (0, _emberLocalStorage.storageFor)('local-settings'), (_class = class JournalReportIntervarsityController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "formatAmountService", _descriptor, this);

      _initializerDefineProperty(this, "localSettings", _descriptor2, this);

      _initializerDefineProperty(this, "moreInfoModalInfo", _descriptor3, this);

      _initializerDefineProperty(this, "showLastIntervalCount", _descriptor4, this);

      _defineProperty(this, "sameHeightElementGroups", {});
    }

    get showAllIntervals() {
      return this.showLastIntervalCount === 0 || this.showLastIntervalCount === this.totalIntervalCount;
    }

    get firstGoal() {
      return this.model.reportIntervarsity.goals[0];
    }

    get showLastOptions() {
      let {
        totalIntervalCount
      } = this;
      let options = [{
        label: 'All Weeks',
        val: 0
      }];

      for (let index = 1; index <= totalIntervalCount; index++) {
        let label = index === 1 ? 'Last Week' : `Last ${index} Weeks`;
        options.push({
          label,
          val: index
        });
      }

      return options;
    }

    get totalIntervalCount() {
      return this.model.reportIntervarsity.intervalData.length;
    }

    get selectedIntervalItems() {
      if (this.showLastIntervalCount === 0) {
        return this.model.reportIntervarsity.intervalData;
      } else {
        return this.model.reportIntervarsity.intervalData.slice(this.model.reportIntervarsity.intervalData.length - this.showLastIntervalCount);
      }
    }

    get selectedIntervalYesDecisions() {
      let yesDecisions = [];
      this.selectedIntervalItems.forEach(item => {
        item.decisionInfos.forEach(decisionInfo => {
          if (decisionInfo.journalDecision.yesNo) {
            yesDecisions.push(decisionInfo);
          }
        });
      });
      return yesDecisions;
    }

    get overviewTotals() {
      let {
        selectedIntervalItems,
        firstGoal
      } = this;
      return {
        engagementsTotal: selectedIntervalItems.map(x => x.completedTaskCounts.engagement).reduce((a, b) => {
          return a + b;
        }, 0),
        asksMadeTotal: selectedIntervalItems.map(x => x.completedTaskCounts.ask).reduce((a, b) => {
          return a + b;
        }, 0),
        recommendationAsksMadeTotal: selectedIntervalItems.map(x => x.completedTaskCounts.recommendationAsk).reduce((a, b) => {
          return a + b;
        }, 0),
        followupsTotal: selectedIntervalItems.map(x => x.completedTaskCounts.followup).reduce((a, b) => {
          return a + b;
        }, 0),
        closedAsksTotal: selectedIntervalItems.map(x => x.decisionInfos.length).reduce((a, b) => {
          return a + b;
        }, 0),
        decisionAmountTotal: selectedIntervalItems.map(x => x.goalsData[firstGoal.id].decisionNewAmount).reduce((a, b) => {
          return currency(a).add(b).value;
        }, 0)
      };
    } // returns the sum of decisions made (excludes multiple decisions from same contact)
    // format: { yes: 1, no: 1, total: 1 }


    get allDecisionCounts() {
      let contactHasDecision = {};
      let decisions = {
        yes: 0,
        no: 0
      };
      this.model.reportIntervarsity.intervalData.forEach(item => {
        item.decisionInfos.forEach(decisionInfo => {
          if (contactHasDecision[decisionInfo.journalDecision.journalContactId] == null) {
            if (decisionInfo.journalDecision.yesNo) {
              decisions.yes += 1;
            } else {
              decisions.no += 1;
            }

            contactHasDecision[decisionInfo.journalDecision.journalContactId] = true;
          }
        });
      });
      return decisions;
    }

    templateDidInsert() {
      this.sameHeightElementGroups = {};
      setTimeout(() => {
        this.showLastIntervalCount = Math.min(this.localSettings.get('journalShowLastIntervalCount'), this.totalIntervalCount);
      });
    }

    handleShowLastIntervalCountChanged(val) {
      if (this.showLastIntervalCount !== val) {
        this.showLastIntervalCount = val;
        this.localSettings.set('journalShowLastIntervalCount', this.showLastIntervalCount);
      }
    }

    makeElementsSameHeight(groupName, el) {
      if (this.sameHeightElementGroups[groupName] == null) {
        this.sameHeightElementGroups[groupName] = [];
      }

      let elementGroup = this.sameHeightElementGroups[groupName];
      elementGroup.push(el);

      if (elementGroup.length === 2) {
        let maxHeight = elementGroup.reduce((max, el) => {
          el.style.height = '';
          return Math.max(max, el.offsetHeight);
        }, 0);
        elementGroup.forEach(el => {
          el.style.height = `${maxHeight}px`;
        });
      }
    }

    closeMoreInfoModal() {
      this.moreInfoModalInfo = undefined;
    }

    activityDivInserted(e) {
      Highcharts.chart(e, this.activityChart);
    }

    get activityChart() {
      let {
        selectedIntervalItems
      } = this;
      let series = [];
      series.push({
        type: 'column',
        name: 'Engagements',
        color: '#0b68a1',
        events: {
          click: event => {
            this.moreInfoModalInfo = {
              taskIds: event.point.taskIds,
              heading: 'Engagements',
              dateFormatted: event.point.dateFormatted
            };
          }
        },
        data: selectedIntervalItems.map(item => {
          return {
            y: item.completedTaskCounts.engagement,
            taskIds: item.completedTaskCounts.engagementTaskIds,
            dateFormatted: item.dateFormatted
          };
        })
      });
      series.push({
        type: 'column',
        name: 'Asks Made',
        color: '#f0ad4e',
        events: {
          click: event => {
            this.moreInfoModalInfo = {
              taskIds: event.point.taskIds,
              heading: 'Asks',
              dateFormatted: event.point.dateFormatted
            };
          }
        },
        data: selectedIntervalItems.map(item => {
          return {
            y: item.completedTaskCounts.ask,
            taskIds: item.completedTaskCounts.askTaskIds,
            dateFormatted: item.dateFormatted
          };
        })
      });
      series.push({
        type: 'column',
        name: 'Follow Ups',
        color: '#aaafb4',
        events: {
          click: event => {
            this.moreInfoModalInfo = {
              taskIds: event.point.taskIds,
              heading: 'Follow Ups',
              dateFormatted: event.point.dateFormatted
            };
          }
        },
        data: selectedIntervalItems.map(item => {
          return {
            y: item.completedTaskCounts.followup,
            taskIds: item.completedTaskCounts.followupTaskIds,
            dateFormatted: item.dateFormatted
          };
        })
      });
      return {
        chart: {
          height: 200
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        xAxis: {
          categories: selectedIntervalItems.map(x => x.date),
          labels: {
            formatter: function () {
              // @ts-expect-error
              return moment(this.value).format('M/D');
            }
          },
          min: 0,
          max: selectedIntervalItems.length - 1
        },
        yAxis: {
          min: 0,
          title: {
            text: null
          }
        },
        tooltip: {
          headerFormat: '',
          pointFormat: '{series.name}: {point.y}',
          style: {
            fontSize: '14px'
          }
        },
        legend: {
          enabled: false
        },
        series
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "formatAmountService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "localSettings", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "moreInfoModalInfo", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showLastIntervalCount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "selectedIntervalItems", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "selectedIntervalItems"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "overviewTotals", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "overviewTotals"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "allDecisionCounts", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "allDecisionCounts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleShowLastIntervalCountChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleShowLastIntervalCountChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "makeElementsSameHeight", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "makeElementsSameHeight"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeMoreInfoModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeMoreInfoModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "activityDivInserted", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "activityDivInserted"), _class.prototype)), _class));
  _exports.default = JournalReportIntervarsityController;
});