define("donor-elf-web/routes/admin-error", ["exports", "@ember/routing/route", "donor-elf-web/lib/logger"], function (_exports, _route, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    setupController(controller, error) {
      _logger.default.error(error);

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});