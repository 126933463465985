define("donor-elf-web/routes/admin/funds/fund/users/add", ["exports", "@ember/routing/route", "@ember/object", "rsvp", "@ember/service"], function (_exports, _route, _object, _rsvp, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    fundsService: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),
    storeCache: (0, _service.inject)(),

    beforeModel() {
      (0, _object.set)(this, 'navbarSettings.title', 'Add Users to Fund');
      return (0, _object.get)(this, 'fundsService').confirmValidFundToken();
    },

    model() {
      let fund = (0, _object.get)(this.modelFor('admin.funds.fund'), 'record');
      let storeCache = (0, _object.get)(this, 'storeCache');

      let promise = _rsvp.default.hash({
        users: storeCache.findAll('admin/user'),
        indexUserFundsByFundId: storeCache.indexMultipleBy('admin/user-fund', 'fundId')
      });

      return promise.then(data => {
        let fundUsers = data.indexUserFundsByFundId[(0, _object.get)(fund, 'id')] || [];
        let fundUserIds = {};
        fundUsers.forEach(userFund => {
          fundUserIds[(0, _object.get)(userFund, 'userId')] = true;
        });
        return {
          fund: fund,
          items: data.users.filter(user => {
            return !fundUserIds[(0, _object.get)(user, 'id')];
          }).map(user => {
            return {
              isSelected: false,
              user: user
            };
          }),
          userFunds: data.indexUserFundsByFundId[(0, _object.get)(fund, 'id')]
        };
      });
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.reset();
      }
    }

  });

  _exports.default = _default;
});