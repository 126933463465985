define("donor-elf-web/components/contact/details/properties/custom-property/show", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@ember/debug", "ember-cached-decorator-polyfill"], function (_exports, _component, _templateFactory, _component2, _service, _debug, _emberCachedDecoratorPolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes>
    {{#if (is-present this.customProperty.v)}}
      {{#if (eq this.propertyType 's')}}
        {{#if this.valueLooksLikeUrl}}
          <a
            href={{this.customProperty.v}}
            target='_blank'
            rel='noopener noreferrer'
          >
            {{this.customProperty.v}}
          </a>
        {{else}}
          {{this.customProperty.v}}
        {{/if}}
      {{else if (eq this.propertyType 'b')}}
        {{this.booleanValue}}
      {{else if (eq this.propertyType 'n')}}
        {{format-number this.customProperty.v}}
      {{else if (eq this.propertyType 'c')}}
        {{format-amount this.customProperty.v}}
      {{else if (eq this.propertyType 'l')}}
        {{#if this.singleSelectionList}}
          {{this.singleSelectionListValue}}
        {{else}}
          <div class='d-flex flex-wrap mt-1'>
            {{#each this.listValueAsArray as |val|}}
              <div class='mr-2 mb-2 px-2 tw-py-2px bg-brand-100 tw-rounded-xl tw-text-sm tw-leading-none'>
                {{val}}
              </div>
            {{/each}}
          </div>
        {{/if}}
      {{/if}}
    {{/if}}
  </div>
  */
  {
    "id": "DokHB2VV",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,0,[\"customProperty\",\"v\"]]],null],[[[41,[28,[37,2],[[30,0,[\"propertyType\"]],\"s\"],null],[[[41,[30,0,[\"valueLooksLikeUrl\"]],[[[1,\"        \"],[10,3],[15,6,[30,0,[\"customProperty\",\"v\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n          \"],[1,[30,0,[\"customProperty\",\"v\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,0,[\"customProperty\",\"v\"]]],[1,\"\\n\"]],[]]]],[]],[[[41,[28,[37,2],[[30,0,[\"propertyType\"]],\"b\"],null],[[[1,\"      \"],[1,[30,0,[\"booleanValue\"]]],[1,\"\\n\"]],[]],[[[41,[28,[37,2],[[30,0,[\"propertyType\"]],\"n\"],null],[[[1,\"      \"],[1,[28,[35,3],[[30,0,[\"customProperty\",\"v\"]]],null]],[1,\"\\n\"]],[]],[[[41,[28,[37,2],[[30,0,[\"propertyType\"]],\"c\"],null],[[[1,\"      \"],[1,[28,[35,4],[[30,0,[\"customProperty\",\"v\"]]],null]],[1,\"\\n\"]],[]],[[[41,[28,[37,2],[[30,0,[\"propertyType\"]],\"l\"],null],[[[41,[30,0,[\"singleSelectionList\"]],[[[1,\"        \"],[1,[30,0,[\"singleSelectionListValue\"]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,0],[14,0,\"d-flex flex-wrap mt-1\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"listValueAsArray\"]]],null]],null],null,[[[1,\"            \"],[10,0],[14,0,\"mr-2 mb-2 px-2 tw-py-2px bg-brand-100 tw-rounded-xl tw-text-sm tw-leading-none\"],[12],[1,\"\\n              \"],[1,[30,2]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[2]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"]],[]],null]],[]]]],[]]]],[]]]],[]]]],[]],null],[13]],[\"&attrs\",\"val\"],false,[\"if\",\"is-present\",\"eq\",\"format-number\",\"format-amount\",\"each\",\"-track-array\"]]",
    "moduleName": "donor-elf-web/components/contact/details/properties/custom-property/show.hbs",
    "isStrictMode": false
  });

  let UrlRegex = RegExp(/^https?:/i);
  let ContactDetailsPropertiesCustomPropertyShowComponent = (_class = class ContactDetailsPropertiesCustomPropertyShowComponent extends _component2.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "contactsCache", _descriptor, this);

      if (args.customProperty == null && (args.contact == null || args.customPropertyLabel == null)) {
        (false && !(false) && (0, _debug.assert)("customProperty or (contact and customPropertyLabel) must be present", false));
      }
    }

    get customProperty() {
      if (this.args.customProperty) {
        return this.args.customProperty;
      }

      return this.args.contact.customPropertiesByLabel[this.args.customPropertyLabel];
    }

    get contactsCacheCustomPropertyInfo() {
      return this.contactsCache.customPropertiesByLowecaseLabel[this.customProperty.l.toLowerCase()];
    }

    get propertyType() {
      return this.customProperty.t || this.contactsCacheCustomPropertyInfo.t;
    }

    get valueLooksLikeUrl() {
      return UrlRegex.test(this.customProperty.v);
    }

    get booleanValue() {
      return this.customProperty.v === true || this.customProperty.v === 'true' ? 'Yes' : 'No';
    }

    get listValueAsArray() {
      let allOptions = this.contactsCacheCustomPropertyInfo.o;
      return [...allOptions.filter(x => this.customProperty.v[x]), // also include any keys that are not in the list of options
      ...Object.keys(this.customProperty.v).filter(x => !allOptions.includes(x))];
    }

    get singleSelectionList() {
      var _this$contactsCacheCu;

      return (_this$contactsCacheCu = this.contactsCacheCustomPropertyInfo.s) === null || _this$contactsCacheCu === void 0 ? void 0 : _this$contactsCacheCu.single_selection;
    }

    get singleSelectionListValue() {
      return Object.keys(this.customProperty.v)[0];
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "contactsCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "customProperty", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "customProperty"), _class.prototype)), _class);
  _exports.default = ContactDetailsPropertiesCustomPropertyShowComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ContactDetailsPropertiesCustomPropertyShowComponent);
});