define("donor-elf-web/components/modal/header-second", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='py-1 px-3 bg-gray-100 border-bottom' ...attributes>
    <div class='tw-text-2xl tw-tracking-wide tw-font-semibold'>
      {{yield}}
    </div>
  </div>
  
  */
  {
    "id": "k7LGVM7A",
    "block": "[[[11,0],[24,0,\"py-1 px-3 bg-gray-100 border-bottom\"],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"tw-text-2xl tw-tracking-wide tw-font-semibold\"],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "donor-elf-web/components/modal/header-second.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});