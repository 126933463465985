define("donor-elf-web/templates/components/dashboard-donut-chart", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ZAESBtbf",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,0],[15,\"onclick\",[28,[37,2],[[30,0],\"handleClick\"],null]],[14,0,\"dashboard-donut-chart\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"dashboard-donut-chart\"],[12],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"hasClickHandler\",\"action\"]]",
    "moduleName": "donor-elf-web/templates/components/dashboard-donut-chart.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});