define("donor-elf-web/templates/funds/fund/more/donor-hub/none", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "7xOTZazB",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,2],[12],[1,\"\\n    You are not connected to a DonorHub server.\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"mt-4\"],[12],[1,\"\\n\"],[6,[39,1],null,[[\"class\",\"route\"],[\"btn btn-warning\",\"funds.fund.more.donor-hub.connect.organization\"]],[[\"default\"],[[[[1,\"      Connect to a DonorHub server\\n\"]],[]]]]],[1,\"  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"de-form-card\",\"link-to\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/more/donor-hub/none.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});