define("donor-elf-web/controllers/funds/fund/dashboard/ruf", ["exports", "donor-elf-web/controllers/funds/fund/dashboard/ytd"], function (_exports, _ytd) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DashboardRufController extends _ytd.default {}

  _exports.default = DashboardRufController;
});