define("donor-elf-web/validations/communication", ["exports", "@ember/object", "@ember/utils", "ember-changeset-validations/validators"], function (_exports, _object, _utils, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    contactId(key, newValue, oldValue, changes
    /*, content*/
    ) {
      if ((0, _object.get)(changes, 'for') === 'contact') {
        if ((0, _utils.isEmpty)(newValue)) {
          return "Contact can't be blank";
        }
      }

      return true;
    },

    group(key, newValue, oldValue, changes
    /*, content*/
    ) {
      if ((0, _object.get)(changes, 'for') === 'group') {
        if ((0, _utils.isEmpty)(newValue)) {
          return "Group can't be blank";
        }
      }

      return true;
    },

    completedAt: (0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    }),
    summary: (0, _validators.validateLength)({
      allowBlank: true,
      max: 1024,
      message: "Can't be longer than 1,024 characters"
    })
  };
  _exports.default = _default;
});