define("donor-elf-web/helpers/journal-cell-class", ["exports", "@ember/string", "@ember/component/helper"], function (_exports, _string, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _helper.default.extend({
    compute(_args, {
      journalStageColumn,
      journalRow
    }) {
      if (journalRow.active) {
        return (0, _string.htmlSafe)('bg-brand-50');
      }

      if (journalStageColumn.isStageColumn && (journalStageColumn.stageIndex <= journalRow.journalContactInfo.lastCompletedStageIndex || journalRow.journalContactInfo.journalContact.finished)) {
        return (0, _string.htmlSafe)('bg-gray-100');
      }
    }

  });

  _exports.default = _default;
});