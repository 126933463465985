define("donor-elf-web/models/admin/fund-group", ["exports", "@ember-data/model", "ember-cached-decorator-polyfill"], function (_exports, _model, _emberCachedDecoratorPolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AdminFundGroupModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)(), _dec3 = (0, _model.attr)(), _dec4 = (0, _model.attr)('string'), (_class = class AdminFundGroupModel extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "name", _descriptor, this);

      _initializerDefineProperty(this, "userIds", _descriptor2, this);

      _initializerDefineProperty(this, "fundIds", _descriptor3, this);

      _initializerDefineProperty(this, "role", _descriptor4, this);
    }

    get nameLowerCase() {
      var _this$name;

      return (_this$name = this.name) === null || _this$name === void 0 ? void 0 : _this$name.toLowerCase();
    } // the userIds array converted to an object of keys for fast searching


    get userIdKeys() {
      let userIdKeys = {};
      (this.userIds || []).forEach(userId => {
        userIdKeys[userId] = true;
      });
      return userIdKeys;
    } // the fundIds array converted to an object of keys for fast searching


    get fundIdKeys() {
      let fundIdKeys = {};
      (this.fundIds || []).forEach(fundId => {
        fundIdKeys[fundId] = true;
      });
      return fundIdKeys;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "userIds", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fundIds", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "role", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "userIdKeys", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "userIdKeys"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fundIdKeys", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "fundIdKeys"), _class.prototype)), _class));
  _exports.default = AdminFundGroupModel;
});