define("donor-elf-web/templates/components/de-contact", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "lmtHJG08",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,0],[14,0,\"text-muted\"],[12],[1,\"Anonymous Donor\"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[6,[39,2],null,[[\"route\",\"model\"],[\"funds.fund.contacts.contact\",[33,3,[\"id\"]]]],[[\"default\"],[[[[1,[33,3,[\"name\"]]]],[]]]]],[1,\"\\n\"]],[]]],[41,[33,3,[\"cityAndState\"]],[[[1,\"  \"],[10,0],[14,0,\"contact-city-state\"],[12],[1,[33,3,[\"cityAndState\"]]],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"isAnonymous\",\"link-to\",\"contact\"]]",
    "moduleName": "donor-elf-web/templates/components/de-contact.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});