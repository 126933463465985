define("donor-elf-web/components/journal/summary/decisions-made", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-cached-decorator-polyfill"], function (_exports, _component, _templateFactory, _component2, _emberCachedDecoratorPolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes>
    <div class='d-flex flex-wrap'>
      <div>
        <div class='text-brand tw-text-3xl font-weight-bold tw-tracking-wide'>
          {{format-amount this.decisionsMadeAmount round=true}}
        </div>
        <div class='text-brand tw-text-xl text-right -tw-mt-1 -tw-mb-3'>
          {{format-number this.decisionsMadePercent round-true}}%
        </div>
      </div>
      <div class='ml-3 -tw-mb-3'>
        <DonutChart
          @percent={{this.decisionsMadePercent}}
          @color='blue'
          @innerSize='70%'
          @size={{80}}
        />
      </div>
    </div>
  </div>
  */
  {
    "id": "T/zY+sZ4",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"d-flex flex-wrap\"],[12],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[10,0],[14,0,\"text-brand tw-text-3xl font-weight-bold tw-tracking-wide\"],[12],[1,\"\\n        \"],[1,[28,[35,0],[[30,0,[\"decisionsMadeAmount\"]]],[[\"round\"],[true]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"text-brand tw-text-xl text-right -tw-mt-1 -tw-mb-3\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[[30,0,[\"decisionsMadePercent\"]],[33,2]],null]],[1,\"%\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"ml-3 -tw-mb-3\"],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@percent\",\"@color\",\"@innerSize\",\"@size\"],[[30,0,[\"decisionsMadePercent\"]],\"blue\",\"70%\",80]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"format-amount\",\"format-number\",\"round-true\",\"donut-chart\"]]",
    "moduleName": "donor-elf-web/components/journal/summary/decisions-made.hbs",
    "isStrictMode": false
  });

  let JournalSummaryDecisionsMadeComponent = (_class = class JournalSummaryDecisionsMadeComponent extends _component2.default {
    get decisionsMadeAmount() {
      if (this.args.intervalSummaries.length == 0) {
        return 0;
      }

      let last = this.args.intervalSummaries[this.args.intervalSummaries.length - 1];
      return last.cumulativeAmount;
    }

    get decisionsMadePercent() {
      return this.decisionsMadeAmount * 100 / this.args.goalAmount;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "decisionsMadeAmount", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "decisionsMadeAmount"), _class.prototype)), _class);
  _exports.default = JournalSummaryDecisionsMadeComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, JournalSummaryDecisionsMadeComponent);
});