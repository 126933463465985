define("donor-elf-web/routes/funds/view-classic-site", ["exports", "@ember/routing/route", "@ember/object", "@ember/service", "donor-elf-web/config/environment"], function (_exports, _route, _object, _service, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    fundsService: (0, _service.inject)(),
    store: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),

    beforeModel() {
      (0, _object.set)(this, 'navbarSettings.title', 'View Classic Site');
      return (0, _object.get)(this, 'fundsService').confirmValidFundToken();
    },

    model() {
      return (0, _object.get)(this, 'store').findRecord('classic-site-token', 1).then(record => {
        let token = (0, _object.get)(record, 'token');
        window.location = `${_environment.default.apiUrl}/users/login?token=${token}&token_type=classic_site_token`;
      });
    }

  });

  _exports.default = _default;
});