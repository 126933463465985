define("donor-elf-web/lib/export-contacts-to-csv-rows", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    perform(contacts) {
      let headerRow = [];
      let headerInfo = this.addContactsHeaderColumns(headerRow, contacts);
      let rows = [headerRow];

      for (let contact of contacts) {
        let row = [];
        this.addContactInfoToRow(row, contact, headerInfo);
        rows.push(row);
      }

      return rows;
    },

    /*
      addContactsHeaderColumns() and addContactInfoToRow() work together
       Example code:
      let rows = [['Date', 'Amount']];
      let headerInfo = exportContactsToCsvRows.addContactsHeaderColumns(rows[0], get(contactsCache, 'contacts'));
      lineItems.forEach(line => {
        let row = [get(line, 'transDate'), get(line, 'amount')];
        let contact = contactsCache.getById(get(line, 'contactId'));
        exportContactsToCsvRows.addContactInfoToRow(row, contact, headerInfo);
        rows.push(row);
      });
    */
    addContactsHeaderColumns(headerRow, contacts) {
      let i = 0;
      let headerInfo = getHeaderInfo(contacts);
      headerInfo.regularColumnMapping.forEach(x => {
        headerRow.push(x.column);
      });

      for (i = 0; i < headerInfo.maxPhonesLength; i++) {
        headerRow.push(`Phone ${i + 1}`);
      }

      for (i = 0; i < headerInfo.maxEmailsLength; i++) {
        headerRow.push(`Email ${i + 1}`);
      }

      for (let label of headerInfo.customPropertyLabels) {
        headerRow.push(label);
      }

      ;

      for (let group of headerInfo.groupNames) {
        headerRow.push(`Group: ${group}`);
      }

      ;
      return headerInfo;
    },

    addContactInfoToRow(row, contact, headerInfo) {
      let i = 0;

      if (contact == null) {
        contact = {};
      }

      for (let x of headerInfo.regularColumnMapping) {
        row.push((0, _object.get)(contact, x.property));
      }

      ;
      let phonesData = (0, _object.get)(contact, 'phones.data') || [];

      for (i = 0; i < headerInfo.maxPhonesLength; i++) {
        if (phonesData.length > i) {
          row.push((0, _object.get)(phonesData[i], 'v'));
        } else {
          row.push(null);
        }
      }

      let emailsData = (0, _object.get)(contact, 'emails.data') || [];

      for (i = 0; i < headerInfo.maxEmailsLength; i++) {
        if (emailsData.length > i) {
          row.push((0, _object.get)(emailsData[i], 'v'));
        } else {
          row.push(null);
        }
      }

      for (let label of headerInfo.customPropertyLabels) {
        var _contact$customProper;

        let prop = (_contact$customProper = contact.customPropertiesByLabel) === null || _contact$customProper === void 0 ? void 0 : _contact$customProper[label];

        if (prop) {
          if (prop.t === 'l') {
            row.push(Object.keys(prop.v).join(' || '));
          } else {
            row.push(prop.v);
          }
        } else {
          row.push(null);
        }
      }

      ;

      for (let group of headerInfo.groupNames) {
        if (((0, _object.get)(contact, 'groups') || []).indexOf(group) >= 0) {
          row.push('X');
        } else {
          row.push(null);
        }
      }

      ;
    }

  };
  _exports.default = _default;

  let getHeaderInfo = function (contacts) {
    let maxPhonesLength = 0;
    let maxEmailsLength = 0;
    let customPropertyMapping = {};
    let groupMapping = {};

    for (let contact of contacts) {
      var _contact$phones, _contact$phones$data, _contact$emails, _contact$emails$data;

      let phonesLength = ((_contact$phones = contact.phones) === null || _contact$phones === void 0 ? void 0 : (_contact$phones$data = _contact$phones.data) === null || _contact$phones$data === void 0 ? void 0 : _contact$phones$data.length) || 0;

      if (phonesLength > maxPhonesLength) {
        maxPhonesLength = phonesLength;
      }

      let emailsLength = ((_contact$emails = contact.emails) === null || _contact$emails === void 0 ? void 0 : (_contact$emails$data = _contact$emails.data) === null || _contact$emails$data === void 0 ? void 0 : _contact$emails$data.length) || 0;

      if (emailsLength > maxEmailsLength) {
        maxEmailsLength = emailsLength;
      }

      for (let prop of ((_contact$custom = contact.custom) === null || _contact$custom === void 0 ? void 0 : _contact$custom.properties) || []) {
        var _contact$custom;

        customPropertyMapping[prop.l] = true;
      }

      ;

      for (let group of contact.groups || []) {
        groupMapping[group] = true;
      }

      ;
    }

    ;
    return {
      maxPhonesLength,
      maxEmailsLength,
      regularColumnMapping,
      customPropertyLabels: Object.keys(customPropertyMapping).sort(),
      groupNames: Object.keys(groupMapping).sort()
    };
  };

  const regularColumnMapping = [{
    column: 'Display Name',
    property: 'name'
  }, {
    column: 'Mailing Name',
    property: 'mailingName'
  }, {
    column: 'Greeting',
    property: 'greeting'
  }, {
    column: 'First Name',
    property: 'firstName'
  }, {
    column: 'Last Name',
    property: 'lastName'
  }, {
    column: 'Spouse First Name',
    property: 'spouseFirstName'
  }, {
    column: 'Spouse Last Name',
    property: 'spouseLastName'
  }, {
    column: 'Address Line 1',
    property: 'address1'
  }, {
    column: 'Address Line 2',
    property: 'address2'
  }, {
    column: 'City',
    property: 'city'
  }, {
    column: 'State',
    property: 'state'
  }, {
    column: 'Zip',
    property: 'zip'
  }, {
    column: 'Country',
    property: 'country'
  }, {
    column: 'Deceased',
    property: 'deceased'
  }, {
    column: 'Spouse is Deceased',
    property: 'spouseDeceased'
  }, {
    column: 'Hidden',
    property: 'hidden'
  }];
});