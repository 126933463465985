define("donor-elf-web/validations/journal-decision-change", ["exports", "@ember/utils", "ember-changeset-validations/validators", "donor-elf-web/lib/string-utils", "donor-elf-web/lib/number-utils", "donor-elf-web/lib/date-utils", "donor-elf-web/lib/object-utils", "donor-elf-web/lib/pledge-utils"], function (_exports, _utils, _validators, _stringUtils, _numberUtils, _dateUtils, _objectUtils, _pledgeUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateJournalDecisionChange;

  function decisionYes(changes, content) {
    let decisionMade = (0, _objectUtils.assignSingleProperty)(content, changes, 'decisionMade') || false;
    return decisionMade && (0, _objectUtils.assignSingleProperty)(content, changes, 'journalDecisionYesNo') === true;
  }

  function validateJournalDecisionChange(hasYtdGoalType) {
    return {
      journalContactId: (0, _validators.validatePresence)({
        presence: true,
        message: "journalContactId can't be blank"
      }),
      journalDecisionJournalGoalId: (key, newValue, oldValue, changes, content) => {
        if (decisionYes(changes, content) && (0, _utils.isEmpty)(newValue)) {
          return "journalDecisionJournalGoalId can't be blank";
        }

        return true;
      },
      journalDecisionDecisionDate: (key, newValue, oldValue, changes, content) => {
        let decisionMade = (0, _objectUtils.assignSingleProperty)(content, changes, 'decisionMade') || false;

        if (decisionMade) {
          if ((0, _utils.isEmpty)(newValue)) {
            return "Decision Date can't be blank";
          } else if (_dateUtils.default.serializeToDateOnly(newValue) > _dateUtils.default.serializeToDateOnly(new Date())) {
            return "Decision Date can't be in the future";
          }
        }

        return true;
      },
      pledgeAmount: (key, newValue, oldValue, changes, content) => {
        if (decisionYes(changes, content) && (!(0, _stringUtils.isNumber)(newValue) || Number(newValue) <= 0)) {
          return "Pledge Amount must be positive number";
        }

        return true;
      },
      pledgeFrequency: (key, newValue, oldValue, changes, content) => {
        if (decisionYes(changes, content) && (0, _utils.isEmpty)(newValue)) {
          return "Pledge Frequency can't be blank";
        }

        return true;
      },
      pledgeStartDate: (key, newValue, oldValue, changes, content) => {
        if (decisionYes(changes, content) && (0, _utils.isEmpty)(newValue)) {
          return "Pledge Start Date can't be blank";
        }

        return true;
      },
      pledgeEndDate: (key, newValue, oldValue, changes, content) => {
        if ((0, _utils.isPresent)(newValue)) {
          let startDate = (0, _objectUtils.assignSingleProperty)(content, changes, 'pledgeStartDate');

          if ((0, _utils.isPresent)(startDate)) {
            let endDateString = _dateUtils.default.serializeToDateOnly(newValue);

            let startDateString = _dateUtils.default.serializeToDateOnly(startDate);

            if (startDateString > endDateString) {
              return "Pledge End Date can't be before Start Date";
            }
          }
        }

        return true;
      },
      pledgeEndDateMonths: (key, newValue, oldValue, changes, content) => {
        let frequency = (0, _objectUtils.assignSingleProperty)(content, changes, 'pledgeFrequency');

        if (decisionYes(changes, content) && frequency === _pledgeUtils.pledgeOneTimeFrequency && !hasYtdGoalType) {
          if (!(0, _stringUtils.isNumber)(newValue) || !_numberUtils.default.isInteger(Number(newValue)) || Number(newValue) <= 0) {
            return '# Months must be a positive whole number';
          }
        }

        return true;
      }
    };
  }

  ;
});