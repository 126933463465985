define("donor-elf-web/components/goal/annual-chart", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "@ember/service"], function (_exports, _component, _object, _runloop, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Highcharts
  } = window;
  /*
    attrs
    - goalAmount
    - pledgedAmount
    - nonPledgedAmount
  */

  var _default = _component.default.extend({
    formatAmountService: (0, _service.inject)(),
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    totalAmount: (0, _object.computed)('pledgedAmount', 'nonPledgedAmount', function () {
      return +(0, _object.get)(this, 'pledgedAmount') + +(0, _object.get)(this, 'nonPledgedAmount');
    }),
    totalPercent: (0, _object.computed)('pledgedAmount', 'goalAmount', function () {
      return Math.round((0, _object.get)(this, 'totalAmount') * 100 / +(0, _object.get)(this, 'goalAmount'));
    }),
    pledgedPercent: (0, _object.computed)('pledgedAmount', 'goalAmount', function () {
      let percent = +(0, _object.get)(this, 'pledgedAmount') * 100 / +(0, _object.get)(this, 'goalAmount');

      if (percent > 100) {
        percent = 100;
      } else if (percent < 0) {
        percent = 0;
      }

      return Math.round(percent);
    }),
    nonPledgedPercent: (0, _object.computed)('pledgedPercent', 'nonPledgedAmount', 'goalAmount', function () {
      let percent = +(0, _object.get)(this, 'nonPledgedAmount') * 100 / +(0, _object.get)(this, 'goalAmount');

      if (percent > 100) {
        percent = 100;
      } else if (percent + (0, _object.get)(this, 'pledgedPercent') > 100) {
        percent = 100 - (0, _object.get)(this, 'pledgedPercent');
      } else if (percent < 0) {
        percent = 0;
      }

      return Math.round(percent);
    }),
    chartSettings: (0, _object.computed)(function () {
      let {
        pledgedPercent,
        nonPledgedPercent,
        totalPercent
      } = (0, _object.getProperties)(this, 'pledgedPercent', 'nonPledgedPercent', 'totalPercent');
      let fillerPercentage = 100 - pledgedPercent - nonPledgedPercent;

      if (fillerPercentage < 0) {
        fillerPercentage = 0;
      }

      let formattedAmount = (0, _object.get)(this, 'formatAmountService').format((0, _object.get)(this, 'totalAmount'), {
        roundToDollar: true
      });
      return {
        chart: {
          type: 'pie',
          spacing: [0, 0, 0, 0],
          height: 164
        },
        title: {
          text: `<span>${totalPercent}%</span><div style='font-size: 1rem; color: #636c72'>${formattedAmount}</div>`,
          align: 'center',
          verticalAlign: 'middle',
          useHTML: true,
          style: {
            fontSize: '2rem',
            color: '#0b68a1',
            fontFamily: (0, _object.get)(this, 'fontFamily')
          },
          y: 0
        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          pie: {
            shadow: false,
            center: ['50%', '50%']
          }
        },
        series: [{
          size: '100%',
          innerSize: '90%',
          dataLabels: {
            enabled: false
          },
          data: [{
            name: 'Pledged Percentage',
            y: pledgedPercent,
            color: '#0b68a1',
            tooltip: {}
          }, {
            name: 'Non-Pledged Percentage',
            y: nonPledgedPercent,
            color: '#3aacf2'
          }, {
            y: fillerPercentage,
            color: '#eceeef'
          }]
        }]
      };
    }),

    didInsertElement() {
      this._super(...arguments);

      _runloop.run.scheduleOnce('afterRender', () => {
        Highcharts.chart(this.element, this.chartSettings);
      });
    }

  });

  _exports.default = _default;
});