define("donor-elf-web/helpers/format-number", ["exports", "@ember/component/helper", "accounting"], function (_exports, _helper, _accounting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(function ([val]) {
    if (val == null) {
      val = '';
    }

    let precision = 0;
    let decimalsMatch = val.toString().match(/\..+$/);

    if (decimalsMatch) {
      precision = decimalsMatch[0].length - 1;
    }

    return _accounting.accounting.formatNumber(val, precision);
  });

  _exports.default = _default;
});