define("donor-elf-web/controllers/admin/funds/fund/index", ["exports", "@ember/object/computed", "@ember/controller", "@ember/object", "donor-elf-web/config/environment"], function (_exports, _computed, _controller, _object, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    sortUsersBy: ['displayName'],
    sortedUsers: (0, _computed.sort)('model.users', 'sortUsersBy'),
    sortFundGroupsBy: ['name'],
    sortedFundGroups: (0, _computed.sort)('model.fundGroups', 'sortFundGroupsBy'),
    rootURL: (0, _object.computed)(function () {
      return _environment.default.routerRootURL;
    })
  });

  _exports.default = _default;
});