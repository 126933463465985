define("donor-elf-web/components/contact/details/properties/custom-property/add/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes>
    {{#if (is-present this.existingPropertyLabel)}}
      <Contact::Details::Properties::CustomProperty::Add::Existing
        @contact={{@contact}}
        @propertyInfo={{this.existingPropertyInfo}}
        @afterSave={{@afterSave}}
        @onCancel={{@onCancel}}
      />
    {{else if this.newProperty}}
      <Contact::CustomProperty::Add::New
        @onSave={{this.handleNewPropertyOnSave}}
        @onCancel={{@onCancel}}
      />
    {{else}}
      <Contact::CustomProperty::Add::SelectExistingOrNew
        @existingPropertyInfos={{this.existingPropertyInfosNotOnContact}}
        @contentClass='tw-max-h-56' 
        @onNewPropertySelected={{this.handleNewPropertySelected}}
        @onExistingPropertySelected={{this.handleExistingPropertySelected}}
        class='pt-2 px-3 mb-3'
      />
    {{/if}}
  </div>
  */
  {
    "id": "UZcxrExi",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,0,[\"existingPropertyLabel\"]]],null],[[[1,\"    \"],[8,[39,2],null,[[\"@contact\",\"@propertyInfo\",\"@afterSave\",\"@onCancel\"],[[30,2],[30,0,[\"existingPropertyInfo\"]],[30,3],[30,4]]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"newProperty\"]],[[[1,\"    \"],[8,[39,3],null,[[\"@onSave\",\"@onCancel\"],[[30,0,[\"handleNewPropertyOnSave\"]],[30,4]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,4],[[24,0,\"pt-2 px-3 mb-3\"]],[[\"@existingPropertyInfos\",\"@contentClass\",\"@onNewPropertySelected\",\"@onExistingPropertySelected\"],[[30,0,[\"existingPropertyInfosNotOnContact\"]],\"tw-max-h-56\",[30,0,[\"handleNewPropertySelected\"]],[30,0,[\"handleExistingPropertySelected\"]]]],null],[1,\"\\n  \"]],[]]]],[]]],[13]],[\"&attrs\",\"@contact\",\"@afterSave\",\"@onCancel\"],false,[\"if\",\"is-present\",\"contact/details/properties/custom-property/add/existing\",\"contact/custom-property/add/new\",\"contact/custom-property/add/select-existing-or-new\"]]",
    "moduleName": "donor-elf-web/components/contact/details/properties/custom-property/add/index.hbs",
    "isStrictMode": false
  });

  let ContactDetailsPropertiesCustomPropertyAddIndexComponent = (_class = class ContactDetailsPropertiesCustomPropertyAddIndexComponent extends _component2.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "contactsCache", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "existingPropertyLabel", _descriptor3, this);

      _initializerDefineProperty(this, "newProperty", _descriptor4, this);

      if (this.existingPropertyInfosNotOnContact.length === 0 || this.args.newProperty === true) {
        this.newProperty = true;
      }
    }

    get existingLabelsOnContact() {
      var _this$args$contact$cu;

      return (((_this$args$contact$cu = this.args.contact.custom) === null || _this$args$contact$cu === void 0 ? void 0 : _this$args$contact$cu.properties) || []).reduce((obj, x) => (obj[x.l.toLowerCase()] = true, obj), {});
    }

    get existingPropertyInfosNotOnContact() {
      let {
        existingLabelsOnContact
      } = this;
      return this.args.existingPropertyInfos || this.contactsCache.customProperties.filter(x => x.r !== true && existingLabelsOnContact[x.l.toLowerCase()] !== true);
    }

    get existingPropertyInfo() {
      var _this$existingPropert;

      return this.contactsCache.customPropertiesByLowecaseLabel[((_this$existingPropert = this.existingPropertyLabel) === null || _this$existingPropert === void 0 ? void 0 : _this$existingPropert.toLowerCase()) || ''];
    }

    handleNewPropertySelected() {
      this.newProperty = true;
    }

    handleExistingPropertySelected(info) {
      this.existingPropertyLabel = info.l;
    }

    async handleNewPropertyOnSave(saveArgs) {
      let saveCustomProperty = this.store.createRecord('custom-property', {
        contactId: this.args.contact.id,
        l: saveArgs.l,
        v: saveArgs.v,
        t: saveArgs.t,
        o: saveArgs.o,
        s: saveArgs.s
      });
      await saveCustomProperty.save();

      if (saveCustomProperty.customPropertyInfo != null) {
        this.contactsCache.updateCustomPropertyInfo(saveCustomProperty.customPropertyInfo);
      }

      await this.args.contact.reload();
      this.args.afterSave();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "contactsCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "existingPropertyLabel", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "newProperty", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleNewPropertySelected", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleNewPropertySelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleExistingPropertySelected", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleExistingPropertySelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleNewPropertyOnSave", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleNewPropertyOnSave"), _class.prototype)), _class);
  _exports.default = ContactDetailsPropertiesCustomPropertyAddIndexComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ContactDetailsPropertiesCustomPropertyAddIndexComponent);
});