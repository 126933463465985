define("donor-elf-web/components/form-group-select", ["exports", "@ember/component", "@ember/utils", "@ember/object"], function (_exports, _component, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    attrs
    - changeset
    - property
    - label
    - labelIsHtmlSafe
      - if true, will treat the label as html safe
        - which allows you to have html elements in the label
      - default is false
    - showValidationErrors
    - disabled - boolean
    - selectOptions
      - array of { label: '', val: '' } for select options
      - can use interface SelectOption from lib/form-component-utils.ts
    - stringSelectOptions
      - array of strings for select options
    - booleanField
      - if true, then a select with Yes/No options will be presented
    - numberField
      - if true, then selected option val is converted to a number when setting the property on the changeset
    - onChange(value) - action fired when select is changed
  */
  var _default = _component.default.extend({
    classNames: ['form-group'],
    classNameBindings: ['hasErrors'],
    label: null,
    changeset: null,
    // the associated property on the changeset
    property: null,
    showValidationErrors: false,
    // the 'type' attribute on the input element
    selectOptions: null,
    stringSelectOptions: null,
    booleanField: false,
    numberField: false,
    options: (0, _object.computed)('selectOptions', 'stringSelectOptions', 'booleanField', function () {
      let {
        stringSelectOptions,
        selectOptions,
        booleanField
      } = (0, _object.getProperties)(this, 'stringSelectOptions', 'selectOptions', 'booleanField');

      if (booleanField) {
        return [{
          label: 'Yes',
          val: true
        }, {
          label: 'No',
          val: false
        }];
      } else if (!(0, _utils.isEmpty)(selectOptions)) {
        return selectOptions;
      } else if (!(0, _utils.isEmpty)(stringSelectOptions)) {
        return stringSelectOptions.map(option => {
          return {
            label: option,
            val: option
          };
        });
      } else {
        return null;
      }
    }),
    hasErrors: (0, _object.computed)('showValidationErrors', 'changeset.errors.[]', 'property', function () {
      if ((0, _object.get)(this, 'showValidationErrors') === false) {
        return false;
      }

      let property = (0, _object.get)(this, 'property');
      return (0, _object.get)(this, 'changeset.errors').find(function (item) {
        return (0, _object.get)(item, 'key') === property;
      }) != null;
    }),
    actions: {
      onSelectChange: function (val) {
        var _this$onChange;

        let parsedVal = val;

        if (this.booleanField) {
          if (val === 'true') {
            parsedVal = true;
          } else if (val === 'false') {
            parsedVal = false;
          }
        }

        if (this.numberField && (0, _utils.isPresent)(parsedVal)) {
          parsedVal = Number(parsedVal);
        }

        (0, _object.set)(this, `changeset.${this.property}`, parsedVal);
        (_this$onChange = this.onChange) === null || _this$onChange === void 0 ? void 0 : _this$onChange.call(this, parsedVal);
      }
    }
  });

  _exports.default = _default;
});