define("donor-elf-web/components/handle-404-error", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    attrs
      - errorModel - the model for the error substate
      - modelLabel - the lower case label of the model that has the error
  */
  var _default = _component.default.extend({
    errorModel: null,
    is404Error: (0, _object.computed)(function () {
      return (0, _object.get)(this, 'errorModel.errors.firstObject.status') === '404' || (0, _object.get)(this, 'errorModel.message').match(/^Attempted to handle event `loadingData` on .+ while in state root\.deleted\.saved/) != null;
    }),
    actions: {
      refreshBrowser() {
        window.location.reload();
      }

    }
  });

  _exports.default = _default;
});