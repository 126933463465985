define("donor-elf-web/controllers/coaching/individuals/individual/edit", ["exports", "@ember/controller", "@ember/object", "@ember/service", "ember-concurrency", "moment", "@ember/object/computed", "@ember/utils", "donor-elf-web/lib/date-utils"], function (_exports, _controller, _object, _service, _emberConcurrency, _moment, _computed, _utils, _dateUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    coachingService: (0, _service.inject)(),
    formatDate: (0, _service.inject)('formatDateService'),
    store: (0, _service.inject)(),
    changeset: (0, _computed.alias)('model.changeset'),
    error: null,
    showValidationErrors: false,

    reset() {
      (0, _object.setProperties)(this, {
        error: null,
        showValidationErrors: false
      });
    },

    frequencyNumberOptions: [{
      label: '1',
      val: 1
    }, {
      label: '2',
      val: 2
    }, {
      label: '3',
      val: 3
    }, {
      label: '4',
      val: 4
    }],
    frequencyTimespanOptions: [{
      label: 'Week',
      val: 'week'
    }, {
      label: 'Month',
      val: 'month'
    }],
    firstReportEndDate: (0, _object.computed)('changeset.{startDate,frequencyNumber,frequencyTimespan}', function () {
      let changeset = (0, _object.get)(this, 'changeset');
      let {
        startDate,
        frequencyNumber,
        frequencyTimespan
      } = (0, _object.getProperties)(changeset, 'startDate', 'frequencyNumber', 'frequencyTimespan');

      if ((0, _utils.isEmpty)(startDate) || (0, _utils.isEmpty)(frequencyNumber) || (0, _utils.isEmpty)(frequencyTimespan)) {
        return null;
      }

      return (0, _object.get)(this, 'coachingService').reportEndDate({
        startDate,
        frequencyNumber,
        frequencyTimespan
      });
    }),
    // [{ date, dateString, dateFormatted, reportCount }]
    goalDateItems: (0, _object.computed)('changeset.{startDate,frequencyNumber,frequencyTimespan}', function () {
      let changeset = (0, _object.get)(this, 'changeset');
      let {
        coachingService,
        formatDate
      } = (0, _object.getProperties)(this, 'coachingService', 'formatDate');
      let {
        startDate,
        frequencyNumber,
        frequencyTimespan
      } = (0, _object.getProperties)(changeset, 'startDate', 'frequencyNumber', 'frequencyTimespan');
      let maxDate = (0, _moment.default)(startDate).add(2, 'years');
      let currentDate = startDate;
      let reportCount = 1;
      let items = [];

      while (currentDate < maxDate) {
        currentDate = coachingService.reportEndDate({
          startDate: currentDate,
          frequencyNumber,
          frequencyTimespan
        });
        items.push({
          date: currentDate,
          dateString: (0, _moment.default)(currentDate).format('YYYY-MM-DD'),
          dateFormatted: formatDate.format(currentDate, {
            fourYear: true
          }),
          reportCount: reportCount
        });
        currentDate = (0, _moment.default)(currentDate).add(1, 'day').toDate();
        reportCount += 1;
      }

      return items;
    }),
    goalDateOptions: (0, _computed.map)('goalDateItems', function (goalDateItem) {
      return {
        val: goalDateItem.dateString,
        label: goalDateItem.dateFormatted
      };
    }),
    reportingPeriodsToGoalDate: (0, _object.computed)('changeset.goalDateString', function () {
      let goalDateString = (0, _object.get)(this, 'changeset.goalDateString');
      let selectedGoalDateItem = (0, _object.get)(this, 'goalDateItems').find(x => x.dateString === goalDateString);

      if (selectedGoalDateItem) {
        return selectedGoalDateItem.reportCount;
      } else {
        return null;
      }
    }),

    convertFieldGoals() {
      (0, _object.get)(this, 'changeset.sections').forEach(section => {
        (0, _object.get)(section, 'fields').forEach(field => {
          if (field.overallGoal !== undefined) {
            if ((0, _utils.isEmpty)(field.overallGoal)) {
              delete field.overallGoal;
            } else {
              (0, _object.set)(field, 'overallGoal', Number(field.overallGoal));
            }
          } // right now just doing intervalGoal.low
          // later we'll have to handle intervalGoal.high


          let intervalGoal = (0, _object.get)(field, 'intervalGoal.low');

          if (intervalGoal == null) {
            delete field.intervalGoal;
          } else {
            (0, _object.set)(field, 'intervalGoal.low', Number(intervalGoal));
          }
        });
      });
    },

    save: (0, _emberConcurrency.task)(function* () {
      this.convertFieldGoals();
      let changeset = (0, _object.get)(this, 'changeset');
      yield changeset.validate();

      if ((0, _object.get)(changeset, 'isValid')) {
        try {
          (0, _object.set)(changeset, 'filledOut', true);
          yield changeset.save();
          this.transitionToRoute('coaching.individuals.individual', (0, _object.get)(changeset, 'id'));
        } catch (error) {
          (0, _object.set)(this, 'error', error);
        }
      }
    }).drop(),
    actions: {
      handleGoalDateStringChanged(newVal) {
        if (!(0, _utils.isEmpty)(newVal)) {
          (0, _object.set)(this, 'changeset.goalDate', _dateUtils.default.deserializeDateOnly(newVal));
        }
      }

    }
  });

  _exports.default = _default;
});