define("donor-elf-web/models/coaching/individual-summary", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    name: attr('string'),
    startDate: attr('date-only'),
    goalDate: attr('date-only'),
    filledOut: attr('boolean'),
    frequencyTimespan: attr('string'),
    frequencyNumber: attr('number')
  });

  _exports.default = _default;
});