define("donor-elf-web/components/alert/info", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <LeftBorderCard class='tw-shadow-md' ...attributes>
    {{#if (has-block 'raw')}}
      {{yield to='raw'}}
    {{else}}
      <div class='bg-brand-50 rounded-right {{unless @noPadding 'p-2'}}'>
        {{yield}}
      </div>
    {{/if}}
  </LeftBorderCard>
  */
  {
    "id": "rH4l9NOt",
    "block": "[[[8,[39,0],[[24,0,\"tw-shadow-md\"],[17,1]],null,[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,3]],[[[1,\"    \"],[18,3,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[15,0,[29,[\"bg-brand-50 rounded-right \",[52,[51,[30,2]],\"p-2\"]]]],[12],[1,\"\\n      \"],[18,4,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[]]]]]],[\"&attrs\",\"@noPadding\",\"&raw\",\"&default\"],false,[\"left-border-card\",\"if\",\"has-block\",\"yield\",\"unless\"]]",
    "moduleName": "donor-elf-web/components/alert/info.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});