define("donor-elf-web/controllers/admin/fund-groups/fund-group/show", ["exports", "@ember/object/computed", "@ember/controller"], function (_exports, _computed, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    sortFundsBy: ['name:asc'],
    sortedFunds: (0, _computed.sort)('model.funds', 'sortFundsBy'),
    sortUsersBy: ['displayName:asc'],
    sortedUsers: (0, _computed.sort)('model.users', 'sortUsersBy')
  });

  _exports.default = _default;
});