define("donor-elf-web/routes/coaching/individuals/individual/edit-goal-date", ["exports", "donor-elf-web/lib/route-reset-controller", "ember-changeset", "@ember/object", "@ember/service"], function (_exports, _routeResetController, _emberChangeset, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    moment
  } = window;

  var _default = _routeResetController.default.extend({
    abilities: (0, _service.inject)(),
    coachingService: (0, _service.inject)(),

    beforeModel(transition) {
      if ((0, _object.get)(this, 'abilities').noCoachingAbility('edit_goal_date')) {
        transition.abort();
      }
    },

    model() {
      let individualRecord = this.modelFor('coaching.individuals.individual').record;
      let goalDate = moment((0, _object.get)(individualRecord, 'goalDate')).format('YYYY-MM-DD');
      let changeset = new _emberChangeset.default({
        goalDate
      });
      return {
        individualRecord,
        changeset
      };
    }

  });

  _exports.default = _default;
});