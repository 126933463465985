define("donor-elf-web/templates/components/event-info-new-monthly-donor", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "SLsIwRrM",
    "block": "[[[11,0],[17,1],[4,[38,0],[[30,0,[\"templateDidInsert\"]]],null],[12],[1,\"\\n  It looks like they started giving \"],[1,[28,[35,1],[[30,2,[\"custom\",\"donation_amount\"]]],[[\"removeNoCents\"],[true]]]],[1,\" a month.\\n\"],[41,[30,0,[\"canAddPledge\"]],[[[1,\"    \"],[10,0],[12],[1,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-xs btn-warning mt-2\"],[24,4,\"button\"],[4,[38,3],[\"click\",[30,0,[\"addPledge\"]]],null],[12],[1,\"\\n        \"],[1,[28,[35,4],[\"plus\"],null]],[1,\" Pledge\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"pledgeAdded\"]],[[[1,\"    \"],[10,0],[14,0,\"font-weight-light mt-2\"],[12],[1,\"\\n      A pledge has been added for this donor\\n    \"],[13],[1,\"\\n  \"]],[]],null]],[]]],[13],[1,\"\\n\"]],[\"&attrs\",\"@eventModel\"],false,[\"did-insert\",\"format-amount\",\"if\",\"on\",\"svg-jar\"]]",
    "moduleName": "donor-elf-web/templates/components/event-info-new-monthly-donor.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});