define("donor-elf-web/components/dashboard/assigned-tasks", ["exports", "@glimmer/component", "@ember/service", "@ember/object", "@glimmer/tracking"], function (_exports, _component, _service, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AssignedTasksDashboardComponent = (_class = class AssignedTasksDashboardComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fundsService", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "dueCount", _descriptor3, this);

      _initializerDefineProperty(this, "totalCount", _descriptor4, this);

      _initializerDefineProperty(this, "reviewItemsCount", _descriptor5, this);

      _defineProperty(this, "toReviewLink", void 0);

      _defineProperty(this, "toReviewLinkTimeout", void 0);
    }

    templateDidInsert() {
      this.load();
    }

    async load() {
      let dashboard = await this.store.findRecord('assign-tasks/dashboard', this.fundsService.selectedFund.id);

      if (!this.isDestroyed) {
        this.dueCount = dashboard.dueCount.toString();
        this.totalCount = dashboard.totalCount.toString();
        this.reviewItemsCount = dashboard.reviewItemsCount;
      }
    }

    handleToReviewLinkInserted(el) {
      this.toReviewLink = el;
      this.pulseToReviewLink();
    }

    handleToReviewLinkWillDestroy() {
      this.toReviewLink = undefined;

      if (this.toReviewLinkTimeout) {
        clearTimeout(this.toReviewLinkTimeout);
        this.toReviewLinkTimeout = undefined;
      }
    }

    pulseToReviewLink() {
      if (this.toReviewLink == null) {
        return;
      }

      this.toReviewLinkTimeout = setTimeout(() => {
        this.toReviewLinkTimeout = undefined;

        if (this.toReviewLink) {
          this.toReviewLink.classList.remove('bg-warning');
          this.toReviewLink.classList.add('bg-brand');
          this.toReviewLinkTimeout = setTimeout(() => {
            this.toReviewLinkTimeout = undefined;

            if (this.toReviewLink) {
              this.toReviewLink.classList.add('bg-warning');
              this.toReviewLink.classList.remove('bg-brand');
            } // pulse every 2 seconds


            this.toReviewLinkTimeout = setTimeout(() => {
              this.pulseToReviewLink();
            }, 2000);
          }, 1250);
        }
      }, 1500);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "dueCount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '--';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "totalCount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '--';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "reviewItemsCount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "load", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "load"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleToReviewLinkInserted", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleToReviewLinkInserted"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleToReviewLinkWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleToReviewLinkWillDestroy"), _class.prototype)), _class);
  _exports.default = AssignedTasksDashboardComponent;
});