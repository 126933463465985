define("donor-elf-web/templates/logout-loading", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "kt+VelzA",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Logging out of DonorElf...\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"form-card\"]]",
    "moduleName": "donor-elf-web/templates/logout-loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});