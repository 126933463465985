define("donor-elf-web/templates/funds/fund/more/user-settings", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "lsk8MyA8",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[1,[28,[35,1],null,[[\"changeset\",\"property\",\"label\",\"class\",\"booleanField\"],[[33,2,[\"changeset\"]],\"hideMaps\",\"Hide Maps\",\"mb-1\",true]]]],[1,\"\\n  \"],[10,2],[14,0,\"text-muted\"],[12],[1,\"\\n    By default, a map is displayed on the contact detail page to show where they\\n    live. You can turn this off if you have slow Internet and don't want the map\\n    to show.\\n  \"],[13],[1,\"\\n\\n  \"],[1,[28,[35,1],null,[[\"changeset\",\"property\",\"label\",\"class\",\"booleanField\"],[[33,2,[\"changeset\"]],\"showAnnualGoal\",\"Show Annual Goal\",\"mb-1\",true]]]],[1,\"\\n  \"],[10,2],[14,0,\"text-muted\"],[12],[1,\"\\n    The monthly goal is shown by default on the dashboard. This setting allows\\n    you to convert the monthly goal to an annual goal instead.\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"mt-4 mb-3\"],[12],[1,\"\\n    \"],[10,\"button\"],[15,\"onclick\",[28,[37,3],[[33,4]],null]],[14,0,\"btn btn-warning\"],[15,\"disabled\",[29,[[52,[33,4,[\"isRunning\"]],\"disable\"]]]],[12],[1,\"\\n      Save\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[33,4,[\"isRunning\"]],[[[1,\"    \"],[10,0],[14,0,\"alert alert-info\"],[12],[1,\"\\n      Saving...\\n    \"],[13],[1,\"\\n\"]],[]],null],[41,[33,6],[[[1,\"    \"],[10,0],[14,0,\"alert alert-success\"],[12],[1,\"\\n      Your settings have been saved.\\n    \"],[13],[1,\"\\n\"]],[]],null],[41,[33,7],[[[1,\"    \"],[1,[28,[35,8],null,[[\"error\"],[[33,7]]]]],[1,\"\\n\"]],[]],null]],[]]]]]],[],false,[\"de-form-card\",\"form-group-select\",\"model\",\"perform\",\"save\",\"if\",\"saveComplete\",\"error\",\"generic-error\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/more/user-settings.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});