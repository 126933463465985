define("donor-elf-web/components/balance-adjustment-row/edit-modal", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='p-2'>
    <div class='font-weight-bold'>{{@title}}</div>
    <div>
      <Form::Underline::Text
        @changeset={{@changeset}}
        @property={{@property}}
        @showValidationErrors={{this.showValidationErrors}}
        {{focus-on-insert}}
        {{on-enter this.save}}
        {{on-keydown 'Escape' @onCancel}}
        class='w-100 mt-1'
      />
    </div>
    {{#if this.error}}
      <GenericError
        @error={{this.error}}
        @useLeftBorderCard={{true}}
        class='mt-3 tw-text-sm tw-max-w-64'
      />
    {{/if}}
  </div>
  <div class='px-2 tw-pb-2 tw-pt-1 border-top bg-gray-100 rounded-bottom'>
    <button
      {{on 'click' this.save}}
      class='btn btn-sm btn-warning py-0 '
      type='button'
      disabled='{{if this.saving 'disabled'}}'
    >
      {{#if this.saving}}
        Saving...
      {{else}}
        Save
      {{/if}}
    </button>
    {{#unless this.saving}}
      <button
        {{on 'click' @onCancel}}
        class='btn btn-sm btn-link py-0 ml-2'
        type='button'
      >
        Cancel
      </button>
    {{/unless}}
  </div>
  */
  {
    "id": "ME0GXjj0",
    "block": "[[[10,0],[14,0,\"p-2\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"font-weight-bold\"],[12],[1,[30,1]],[13],[1,\"\\n  \"],[10,0],[12],[1,\"\\n    \"],[8,[39,0],[[24,0,\"w-100 mt-1\"],[4,[38,1],null,null],[4,[38,2],[[30,0,[\"save\"]]],null],[4,[38,3],[\"Escape\",[30,4]],null]],[[\"@changeset\",\"@property\",\"@showValidationErrors\"],[[30,2],[30,3],[30,0,[\"showValidationErrors\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"error\"]],[[[1,\"    \"],[8,[39,5],[[24,0,\"mt-3 tw-text-sm tw-max-w-64\"]],[[\"@error\",\"@useLeftBorderCard\"],[[30,0,[\"error\"]],true]],null],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"],[10,0],[14,0,\"px-2 tw-pb-2 tw-pt-1 border-top bg-gray-100 rounded-bottom\"],[12],[1,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-sm btn-warning py-0 \"],[16,\"disabled\",[29,[[52,[30,0,[\"saving\"]],\"disabled\"]]]],[24,4,\"button\"],[4,[38,6],[\"click\",[30,0,[\"save\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"saving\"]],[[[1,\"      Saving...\\n\"]],[]],[[[1,\"      Save\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[41,[51,[30,0,[\"saving\"]]],[[[1,\"    \"],[11,\"button\"],[24,0,\"btn btn-sm btn-link py-0 ml-2\"],[24,4,\"button\"],[4,[38,6],[\"click\",[30,4]],null],[12],[1,\"\\n      Cancel\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@title\",\"@changeset\",\"@property\",\"@onCancel\"],false,[\"form/underline/text\",\"focus-on-insert\",\"on-enter\",\"on-keydown\",\"if\",\"generic-error\",\"on\",\"unless\"]]",
    "moduleName": "donor-elf-web/components/balance-adjustment-row/edit-modal.hbs",
    "isStrictMode": false
  });

  let BalanceAdjustmentEditModalComponent = (_class = class BalanceAdjustmentEditModalComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "saving", _descriptor, this);

      _initializerDefineProperty(this, "error", _descriptor2, this);

      _initializerDefineProperty(this, "showValidationErrors", _descriptor3, this);
    }

    async save() {
      this.saving = true;
      this.error = undefined;
      this.showValidationErrors = false;

      try {
        let {
          changeset
        } = this.args;
        await changeset.validate();

        if (changeset.get('isValid')) {
          await changeset.save();
          this.saving = false;
          this.args.onSaved();
        } else {
          this.showValidationErrors = true;
        }
      } catch (error) {
        this.error = error;
      }

      this.saving = false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "saving", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "error", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showValidationErrors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class);
  _exports.default = BalanceAdjustmentEditModalComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BalanceAdjustmentEditModalComponent);
});