define("donor-elf-web/routes/admin/fund-groups/fund-group/edit", ["exports", "@ember/routing/route", "@ember/object", "@ember/utils", "rsvp", "@ember/service", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators"], function (_exports, _route, _object, _utils, _rsvp, _service, _emberChangeset, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const fundGroupValidation = {
    name: (0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    })
  };

  var _default = _route.default.extend({
    fundsService: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),
    storeCache: (0, _service.inject)(),

    beforeModel() {
      return (0, _object.get)(this, 'fundsService').confirmValidFundToken();
    },

    model() {
      let fundGroupModel = this.modelFor('admin.fund-groups.fund-group');
      let fundGroup = (0, _object.get)(fundGroupModel, 'fundGroup');

      if ((0, _utils.isEmpty)(fundGroup)) {
        (0, _object.set)(this, 'navbarSettings.title', 'Add Fund Group');
        fundGroup = (0, _object.get)(this, 'store').createRecord('admin/fund-group');
      } else {
        (0, _object.set)(this, 'navbarSettings.title', 'Edit Fund Group');
      }

      return _rsvp.default.hash({
        record: fundGroup,
        changeset: new _emberChangeset.default(fundGroup, (0, _emberChangesetValidations.default)(fundGroupValidation), fundGroupValidation),
        roleOptions: (0, _object.get)(this, 'storeCache').findAll('admin/role').then(roles => {
          let options = [];
          roles.forEach(role => {
            if ((0, _object.get)(role, 'name') !== 'Org-Admin') {
              let roleName = (0, _object.get)(role, 'name');
              options.push({
                label: roleName,
                val: roleName
              });
            }
          });
          return options.sort().insertAt(0, {
            label: "User's Default Role",
            val: ''
          });
        })
      });
    }

  });

  _exports.default = _default;
});