define("donor-elf-web/routes/coaching/individuals/individual/report", ["exports", "@ember/routing/route", "@ember/service", "@ember/object"], function (_exports, _route, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    navbarSettings: (0, _service.inject)(),
    store: (0, _service.inject)(),

    model(params) {
      let individualRecord = this.modelFor('coaching.individuals.individual').record;
      (0, _object.set)(this, 'navbarSettings.title', (0, _object.get)(individualRecord, 'name'));
      let record = (0, _object.get)(individualRecord, 'reports').find(x => (0, _object.get)(x, 'id') === params.reportId);
      return {
        record,
        individualRecord
      };
    }

  });

  _exports.default = _default;
});