define("donor-elf-web/controllers/funds/fund/more/mailchimp/connected", ["exports", "@ember/controller", "@ember/object", "@ember/service", "ember-concurrency"], function (_exports, _controller, _object, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    fundsService: (0, _service.inject)(),
    store: (0, _service.inject)(),
    errorMessage: null,

    reset() {
      (0, _object.set)(this, 'errorMessage', null);
    },

    hasMultipleFunds: (0, _object.computed)('model.funds.[]', function () {
      return (0, _object.get)(this, 'model.funds.length') > 1;
    }),
    fundsOptions: (0, _object.computed)('model.funds.[]', function () {
      return (0, _object.get)(this, 'model.funds').sortBy('name').map(fund => {
        return {
          val: (0, _object.get)(fund, 'id'),
          label: (0, _object.get)(fund, 'name')
        };
      });
    }),
    mailchimpListOptions: (0, _object.computed)('model.mailchimpSetting.lists.[]', function () {
      return (0, _object.get)(this, 'model.mailchimpSetting.lists').sortBy('name').map(list => {
        return {
          val: (0, _object.get)(list, 'id'),
          label: (0, _object.get)(list, 'name')
        };
      });
    }),
    save: (0, _emberConcurrency.task)(function* () {
      try {
        yield (0, _object.get)(this, 'fundsService').confirmValidFundToken();
        let changeset = (0, _object.get)(this, 'model.changeset');
        let record = (0, _object.get)(this, 'store').createRecord('mailchimp-fund-setting', (0, _object.getProperties)(changeset, 'mailchimpSettingId', 'fundId', 'mailchimpListId'));
        yield record.save();
        this.transitionToRoute('funds.fund.more.mailchimp.new-connected-to-fund');
      } catch (e) {
        (0, _object.set)(this, 'errorMessage', "Whoops! There was an error saving. Sorry about that. Please email us at support@donorelf.com to let us know and we'll get it fixed");
      }
    }).drop()
  });

  _exports.default = _default;
});