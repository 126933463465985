define("donor-elf-web/controllers/funds/fund/contacts/upload/file", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@glimmer/tracking"], function (_exports, _controller, _object, _service, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    Papa
  } = window;
  let ContactsUploadController = (_class = class ContactsUploadController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "abilities", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "uploadContacts", _descriptor3, this);

      _initializerDefineProperty(this, "mightBeProspectToolFile", _descriptor4, this);

      _initializerDefineProperty(this, "errorMessage", _descriptor5, this);
    }

    templateDidInsert() {
      this.mightBeProspectToolFile = false;
      this.errorMessage = undefined;
    }

    onFileInputChange(e) {
      this.mightBeProspectToolFile = false;
      this.errorMessage = undefined;

      if (e.target.files.length === 0) {
        return;
      }

      let file = e.target.files[0];

      if (file.type === 'text/csv') {
        let reader = new FileReader();

        reader.onload = e => {
          this.processFileString(e.target.result);
        };

        reader.readAsText(file);
      } else if (file.name.match(/prospect/i) && this.abilities.hasAbility('import_iv_prospects')) {
        this.mightBeProspectToolFile = true;
      } else {
        this.errorMessage = 'Please select a file in the .csv format';
      }
    }

    processFileString(fileString) {
      let csv = Papa.parse(fileString, {
        header: true
      });
      let columns = csv.meta.fields;
      (0, _object.set)(this.uploadContacts, 'columns', columns);
      (0, _object.set)(this.uploadContacts, 'fileData', csv.data);
      this.router.transitionTo('funds.fund.contacts.upload.map');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "abilities", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "uploadContacts", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "mightBeProspectToolFile", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "errorMessage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onFileInputChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onFileInputChange"), _class.prototype)), _class);
  _exports.default = ContactsUploadController;
});