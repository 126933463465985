define("donor-elf-web/components/de-contact", ["exports", "@ember/component", "@ember/object", "@ember/service"], function (_exports, _component, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    Either contactId or lineItem must be passed in
  
    attrs
    - contactId
    - lineItem 
  */
  var _default = _component.default.extend({
    contactsCache: (0, _service.inject)(),
    contactId: null,
    lineItem: null,
    contact: (0, _object.computed)('contactId', 'lineItem.id', function () {
      if (this.isAnonymous) {
        return null;
      }

      let contactId = this.contactId || (0, _object.get)(this, 'lineItem.contactId');
      return this.contactsCache.getById(contactId);
    }),
    isAnonymous: (0, _object.computed)('lineItem.anonymous', function () {
      return (0, _object.get)(this, 'lineItem.anonymous') || false;
    })
  });

  _exports.default = _default;
});