define("donor-elf-web/templates/coaching/individuals/individual/add-report", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "LS+sYNBY",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,2],[12],[1,\"\\n    Whoops! It looks like the last report has not been marked complete. The\\n    last report needs to be marked complete before creating a new report.\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    The 'Mark as Complete' checkbox is at the bottom of the edit report page\\n  \"],[13],[1,\"\\n  \"],[6,[39,1],null,[[\"class\",\"route\",\"model\"],[\"btn btn-warning\",\"coaching.individuals.individual.report.edit\",[33,2,[\"lastReportId\"]]]],[[\"default\"],[[[[1,\"Edit Last Report\"]],[]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"de-form-card\",\"link-to\",\"model\"]]",
    "moduleName": "donor-elf-web/templates/coaching/individuals/individual/add-report.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});