define("donor-elf-web/components/coaching-static-info-edit-item", ["exports", "@ember/component", "@ember/object", "@ember/utils", "ember-uuid"], function (_exports, _component, _object, _utils, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* attrs
    - item
    - editing
  */
  var _default = _component.default.extend({
    editing: false,

    didReceiveAttrs() {
      this._super(...arguments);

      let item = (0, _object.get)(this, 'item');

      if ((0, _utils.isEmpty)((0, _object.get)(item, 'id'))) {
        (0, _object.set)(item, 'id', (0, _emberUuid.v4)());
      }
    },

    actions: {
      toggleEditing() {
        this.toggleProperty('editing');
      }

    }
  });

  _exports.default = _default;
});