define("donor-elf-web/templates/funds/fund/goal/total-and-expecting", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ZnqVsxF9",
    "block": "[[[8,[39,0],[[4,[38,1],[[30,0,[\"templateWillDestroy\"]]],null]],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],null,[[\"@changeset\",\"@property\",\"@label\",\"@focusOnInsert\",\"@showValidationErrors\"],[[30,1,[\"changeset\"]],\"annualGoal\",\"Goal Amount\",true,[30,0,[\"showValidationErrors\"]]]],null],[1,\"\\n\\n  \"],[8,[39,3],null,[[\"@changeset\",\"@property\",\"@label\",\"@showValidationErrors\"],[[30,1,[\"changeset\"]],\"goalDate\",\"Goal Date\",[30,0,[\"showValidationErrors\"]]]],null],[1,\"\\n\\n  \"],[10,0],[14,0,\"mt-2\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-warning\"],[16,\"disabled\",[29,[[52,[30,0,[\"saving\"]],\"disabled\"]]]],[24,4,\"button\"],[4,[38,5],[\"click\",[30,0,[\"save\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"saving\"]],[[[1,\"        Saving...\\n\"]],[]],[[[1,\"        Save\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n    \"],[8,[39,6],[[24,0,\"btn btn-link ml-3\"]],[[\"@route\"],[\"funds.fund.dashboard\"]],[[\"default\"],[[[[1,\"\\n      Cancel\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"error\"]],[[[1,\"    \"],[8,[39,7],[[24,0,\"mt-3\"]],[[\"@error\"],[[30,0,[\"error\"]]]],null],[1,\"\\n\"]],[]],null]],[]]]]]],[\"@model\"],false,[\"form-card\",\"will-destroy\",\"form-group\",\"form-group-date\",\"if\",\"on\",\"link-to\",\"generic-error\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/goal/total-and-expecting.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});