define("donor-elf-web/components/event-info-new-monthly-donor", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "@glimmer/tracking", "donor-elf-web/lib/pledge-utils"], function (_exports, _component, _object, _service, _tracking, _pledgeUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let EventInfoNewMonthlyDonorComponent = (_class = class EventInfoNewMonthlyDonorComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "abilities", _descriptor, this);

      _initializerDefineProperty(this, "addSomethingModal", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "canAddPledge", _descriptor4, this);

      _initializerDefineProperty(this, "pledgeAdded", _descriptor5, this);

      _defineProperty(this, "savePledgeSubsciptionToken", void 0);
    }

    addPledge() {
      this.addSomethingModal.open({
        type: 'pledge',
        pledgeOptions: {
          contactId: this.args.eventModel.contactId,
          amount: this.args.eventModel.custom.donation_amount,
          frequency: _pledgeUtils.pledgeMonthlyFrequency,
          startDate: this.args.eventModel.occurredAt
        }
      });
    }

    async loadPledges() {
      if (this.abilities.noAbility('edit_pledges')) {
        this.canAddPledge = false;
        return;
      }

      let pledges = (await this.store.query('pledge', {
        filter: {
          contact_id: this.args.eventModel.contactId
        }
      })).toArray();
      this.canAddPledge = pledges.length === 0;

      if (this.canAddPledge) {
        this.savePledgeSubsciptionToken = this.addSomethingModal.subscribeToPledgeSaved(this.handlePledgeSaved);
      }
    }

    async handlePledgeSaved(_msg, data) {
      if (data.newPledge && data.pledge.contactId === this.args.eventModel.contactId) {
        this.pledgeAdded = true;
        this.canAddPledge = false;
      }
    }

    templateDidInsert() {
      this.loadPledges();
    }

    willDestroy() {
      if (this.pledgeAdded) {
        this.pledgeAdded = false;
      }

      if (this.savePledgeSubsciptionToken) {
        this.addSomethingModal.unsubscribeWithToken(this.savePledgeSubsciptionToken);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "abilities", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "addSomethingModal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "canAddPledge", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "pledgeAdded", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "addPledge", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addPledge"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loadPledges", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadPledges"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handlePledgeSaved", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handlePledgeSaved"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype)), _class);
  _exports.default = EventInfoNewMonthlyDonorComponent;
});