define("donor-elf-web/routes/funds/fund/communications/edit", ["exports", "@ember/routing/route", "@ember/object", "rsvp", "@ember/utils", "@ember/service", "ember-changeset", "ember-changeset-validations", "donor-elf-web/validations/communication"], function (_exports, _route, _object, _rsvp, _utils, _service, _emberChangeset, _emberChangesetValidations, _communication) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    store: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),
    contactsCache: (0, _service.inject)(),
    queryParams: {
      contactId: {},
      fromDetailPage: {},
      fromTaskId: {} // if set, then copies contact, summary and note from task. expects params.communicationId = 'add'

    },

    model(params) {
      let navbarTitle = null;
      let returnToContactDetailsPage = params.fromDetailPage === 'true';
      let recordPromise = null;
      let contact = null;

      if (params.communicationId === 'add') {
        if ((0, _utils.isPresent)(params.fromTaskId)) {
          recordPromise = this.store.find('task', params.fromTaskId).then(task => {
            contact = this.contactsCache.getById(task.contactId);
            return this.store.createRecord('communication', {
              contactId: task.contactId,
              summary: task.summary,
              notes: task.notes,
              completedAt: new Date()
            });
          });
        } else {
          recordPromise = _rsvp.default.resolve(this.store.createRecord('communication', {
            contactId: params.contactId,
            completedAt: new Date()
          }));
        }

        if (!(0, _utils.isEmpty)(params.contactId)) {
          contact = (0, _object.get)(this, 'contactsCache').getById(params.contactId);
        }

        if (returnToContactDetailsPage && (0, _utils.isPresent)(contact)) {
          navbarTitle = (0, _object.get)(contact, 'name');
        } else {
          navbarTitle = 'Add Communication';
        }
      } else {
        recordPromise = this.store.find('communication', params.communicationId);
        navbarTitle = 'Edit Communication';
      }

      (0, _object.set)(this, 'navbarSettings.title', navbarTitle);
      return recordPromise.then(record => {
        let changeset = new _emberChangeset.default(record, (0, _emberChangesetValidations.default)(_communication.default), _communication.default);
        (0, _object.set)(changeset, 'for', 'contact');

        if ((0, _utils.isPresent)(record.contactId)) {
          contact = this.contactsCache.getById(record.contactId);
          (0, _object.set)(this, 'navbarSettings.title', contact.name);
        }

        return {
          record,
          changeset,
          contact,
          returnToContactDetailsPage,
          saved: false
        };
      });
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.reset();
      }
    }

  });

  _exports.default = _default;
});