define("donor-elf-web/templates/funds/fund/groups/create", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "3zNpLgNc",
    "block": "[[[6,[39,0],null,[[\"class\"],[\"row-bottom-margin\"]],[[\"default\"],[[[[6,[39,1],null,[[\"changeset\",\"saveTask\"],[[33,2,[\"changeset\"]],[33,3]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,4],null,[[\"changeset\",\"property\",\"label\",\"showValidationErrors\"],[[33,2,[\"changeset\"]],\"name\",\"Group Name\",[30,1]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"],[41,[33,6],[[[1,\"    \"],[1,[28,[35,7],null,[[\"error\",\"class\"],[[33,6],\"mt-3\"]]]],[1,\"\\n\"]],[]],null]],[]]]]],[1,\"\\n\"],[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,0],[14,0,\"mb-2\"],[12],[1,\"\\n    These contacts will be added to the new group\\n  \"],[13],[1,\"\\n  \"],[10,\"table\"],[14,0,\"table\"],[12],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,9],[[28,[37,9],[[33,10]],null]],null],null,[[[1,\"        \"],[10,\"tr\"],[12],[1,\"\\n          \"],[10,\"td\"],[12],[1,\"\\n            \"],[1,[28,[35,11],null,[[\"contactId\"],[[30,2,[\"id\"]]]]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"showValidationErrors\",\"contact\"],false,[\"de-form-card\",\"save-form\",\"model\",\"save\",\"form-group\",\"if\",\"error\",\"generic-error\",\"each\",\"-track-array\",\"sortedContacts\",\"de-contact\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/groups/create.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});