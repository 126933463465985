define("donor-elf-web/templates/components/modal", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "l2IdkCL5",
    "block": "[[[40,[[[1,\"  \"],[11,0],[16,0,[29,[\"modal fade d-block \",[52,[30,0,[\"applyShowClass\"]],\"show\"]]]],[4,[38,2],[[30,0,[\"templateDidInsert\"]]],null],[4,[38,3],[[30,0,[\"templateWillDestroy\"]]],null],[4,[38,4],[\"click\",[30,0,[\"handleClick\"]]],null],[12],[1,\"\\n    \"],[11,0],[16,0,[29,[\"modal-dialog modal-dialog-centered \",[52,[30,1],\"modal-lg\"]]]],[17,2],[12],[1,\"\\n      \"],[10,0],[14,0,\"modal-content\"],[12],[1,\"\\n        \"],[18,3,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[\"modal-backdrop fade \",[52,[30,0,[\"applyShowClass\"]],\"show\"]]]],[12],[13],[1,\"\\n\"]],[]],\"%cursor:0%\",[30,0,[\"modalRootElement\"]]]],[\"@large\",\"&attrs\",\"&default\"],false,[\"in-element\",\"if\",\"did-insert\",\"will-destroy\",\"on\",\"yield\"]]",
    "moduleName": "donor-elf-web/templates/components/modal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});