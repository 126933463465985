define("donor-elf-web/components/form-group/amount-filter", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='form-group'>
    <label>{{@label}}</label>
    <div class='input-group'>
        <BasicDropdown
          as |dd|>
          <dd.Trigger class='input-group-prepend cursor-pointer'>
            <div class='input-group-text'>
              {{this.operatorLabel}}
            </div>
          </dd.Trigger>
          <dd.Content class='de-dropdown-content'>
            <ul class='list-group'>
              {{#each this.operatorOptions as |option|}}
                <li
                  {{on 'click' (close-dropdown dd (fn this.handleOperatorClicked option.val))}}
                  class='list-group-item cursor-pointer px-4 py-2 tw-hover:bg-gray-200'
                >
                  {{option.label}}
                </li>
              {{/each}}
            </ul>
          </dd.Content>
        </BasicDropdown>
      <input
        type='text'
        class='form-control'
        value={{this.numberVal}}
        {{on 'change' this.handleNumberChange}}
      />
    </div>
  </div>
  */
  {
    "id": "7wsbQoNW",
    "block": "[[[10,0],[14,0,\"form-group\"],[12],[1,\"\\n  \"],[10,\"label\"],[12],[1,[30,1]],[13],[1,\"\\n  \"],[10,0],[14,0,\"input-group\"],[12],[1,\"\\n      \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[30,2,[\"Trigger\"]],[[24,0,\"input-group-prepend cursor-pointer\"]],null,[[\"default\"],[[[[1,\"\\n          \"],[10,0],[14,0,\"input-group-text\"],[12],[1,\"\\n            \"],[1,[30,0,[\"operatorLabel\"]]],[1,\"\\n          \"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n        \"],[8,[30,2,[\"Content\"]],[[24,0,\"de-dropdown-content\"]],null,[[\"default\"],[[[[1,\"\\n          \"],[10,\"ul\"],[14,0,\"list-group\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"operatorOptions\"]]],null]],null],null,[[[1,\"              \"],[11,\"li\"],[24,0,\"list-group-item cursor-pointer px-4 py-2 tw-hover:bg-gray-200\"],[4,[38,3],[\"click\",[28,[37,4],[[30,2],[28,[37,5],[[30,0,[\"handleOperatorClicked\"]],[30,3,[\"val\"]]],null]],null]],null],[12],[1,\"\\n                \"],[1,[30,3,[\"label\"]]],[1,\"\\n              \"],[13],[1,\"\\n\"]],[3]],null],[1,\"          \"],[13],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"]],[2]]]]],[1,\"\\n    \"],[11,\"input\"],[24,0,\"form-control\"],[16,2,[30,0,[\"numberVal\"]]],[24,4,\"text\"],[4,[38,3],[\"change\",[30,0,[\"handleNumberChange\"]]],null],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@label\",\"dd\",\"option\"],false,[\"basic-dropdown\",\"each\",\"-track-array\",\"on\",\"close-dropdown\",\"fn\"]]",
    "moduleName": "donor-elf-web/components/form-group/amount-filter.hbs",
    "isStrictMode": false
  });

  let FormGroupAmountFilterComponent = (_class = class FormGroupAmountFilterComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "operatorOptions", [{
        label: '<',
        val: 'lt'
      }, {
        label: '<=',
        val: 'lte'
      }, {
        label: '=',
        val: ''
      }, {
        label: '>',
        val: 'gt'
      }, {
        label: '>=',
        val: 'gte'
      }]);
    }

    get operatorLabel() {
      var _operatorOptions$find;

      let {
        operatorVal,
        operatorOptions
      } = this;
      return (_operatorOptions$find = operatorOptions.find(x => x.val === operatorVal)) === null || _operatorOptions$find === void 0 ? void 0 : _operatorOptions$find.label;
    }

    get operatorVal() {
      return this.args.changeset.get(this.args.operatorProperty) || '';
    }

    get numberVal() {
      return this.args.changeset.get(this.args.numberProperty);
    }

    handleOperatorClicked(operatorVal) {
      (0, _object.set)(this.args.changeset, this.args.operatorProperty, operatorVal);
    }

    handleNumberChange(e) {
      let scrubbedVal = e.target.value.replace(/[^0-9.]/g, '');
      (0, _object.set)(this.args.changeset, this.args.numberProperty, scrubbedVal);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "handleOperatorClicked", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleOperatorClicked"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleNumberChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleNumberChange"), _class.prototype)), _class);
  _exports.default = FormGroupAmountFilterComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormGroupAmountFilterComponent);
});