define("donor-elf-web/components/admin-user-add-fund", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/utils"], function (_exports, _computed, _component, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    attrs
    - funds
    - userFunds - list of current user funds (null for new user)
  
    actions
    - onSave(selectedItems)
      - selectedItems = [{ fund: '', userFund: '' })
        - userFund will be null if it didn't exist before
  */
  var _default = _component.default.extend({
    q: '',
    list: (0, _object.computed)('funds', 'userFunds', function () {
      let {
        funds,
        userFunds
      } = (0, _object.getProperties)(this, 'funds', 'userFunds');
      let userFundsByFundId = {};

      if (userFunds != null) {
        userFunds.forEach(userFund => {
          userFundsByFundId[(0, _object.get)(userFund, 'fundId')] = userFund;
        });
      }

      return funds.map(fund => {
        let userFund = userFundsByFundId[(0, _object.get)(fund, 'id')];
        return {
          fund: fund,
          userFund: userFund,
          isSelected: userFund != null
        };
      });
    }),
    sortBy: ['fund.name:asc'],
    sortedList: (0, _computed.sort)('list', 'sortBy'),
    filteredList: (0, _object.computed)('q', 'sortedList', function () {
      let {
        q,
        sortedList
      } = (0, _object.getProperties)(this, 'q', 'sortedList');

      if ((0, _utils.isEmpty)(q)) {
        return sortedList.filter(item => {
          return (0, _object.get)(item, 'fund.active');
        });
      }

      q = q.toLowerCase();
      return sortedList.filter(item => {
        if ((0, _object.get)(item, 'fund.active') === false) {
          return false;
        }

        return (0, _object.get)(item, 'fund.nameLowerCase').indexOf(q) >= 0;
      });
    }),
    selectedList: (0, _object.computed)('sortedList.@each.isSelected', function () {
      let sortedList = (0, _object.get)(this, 'sortedList');
      return sortedList.filter(item => {
        return (0, _object.get)(item, 'isSelected');
      });
    }),
    actions: {
      save() {
        (0, _object.get)(this, 'onSave')((0, _object.get)(this, 'selectedList'));
      }

    }
  });

  _exports.default = _default;
});