define("donor-elf-web/templates/components/card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "2fXEuMKp",
    "block": "[[[11,0],[24,0,\"card de-card de-card-no-border-sm-down\"],[17,1],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[\"card-body \",[52,[30,2],\"p-0\"]]]],[12],[1,\"\\n    \"],[18,3,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@noPadding\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "donor-elf-web/templates/components/card.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});