define("donor-elf-web/controllers/funds/fund/journals/journal/review-items", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking"], function (_exports, _controller, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.JournalReviewItem = void 0;

  var _class, _descriptor, _class3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let JournalReviewItem = (_class = class JournalReviewItem {
    constructor(args) {
      _defineProperty(this, "contactDuplicate", void 0);

      _defineProperty(this, "reviewItem", void 0);

      _initializerDefineProperty(this, "processed", _descriptor, this);

      this.contactDuplicate = args.contactDuplicate;
      this.reviewItem = args.reviewItem;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "processed", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class);
  _exports.JournalReviewItem = JournalReviewItem;
  let JournalReviewItemController = (_class3 = class JournalReviewItemController extends _controller.default {
    get allItemsProcessed() {
      return this.model.items.every(x => x.processed);
    }

    handleProcessed(item) {
      item.processed = true;
    }

  }, (_applyDecoratedDescriptor(_class3.prototype, "handleProcessed", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "handleProcessed"), _class3.prototype)), _class3);
  _exports.default = JournalReviewItemController;
});