define("donor-elf-web/components/contact/bulk-actions/header", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='bg-brand text-white py-2 px-3 d-flex justify-content-between align-items-end'>
    <div class='tw-text-2xl tw-tracking-wide mr-2'>
      {{yield}}
    </div>
    <div class='tw-mb-2px tw-opacity-80'>
      {{#if @contactIds}}
        for {{format-number @contactIds.length}} {{pluralize @contactIds.length 'contact' without-count=true}}
      {{/if}}
    </div>
  </div>
  
  */
  {
    "id": "KzCL/+ds",
    "block": "[[[10,0],[14,0,\"bg-brand text-white py-2 px-3 d-flex justify-content-between align-items-end\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"tw-text-2xl tw-tracking-wide mr-2\"],[12],[1,\"\\n    \"],[18,2,null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"tw-mb-2px tw-opacity-80\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"      for \"],[1,[28,[35,2],[[30,1,[\"length\"]]],null]],[1,\" \"],[1,[28,[35,3],[[30,1,[\"length\"]],\"contact\"],[[\"without-count\"],[true]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@contactIds\",\"&default\"],false,[\"yield\",\"if\",\"format-number\",\"pluralize\"]]",
    "moduleName": "donor-elf-web/components/contact/bulk-actions/header.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});