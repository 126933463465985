define("donor-elf-web/models/selected-fund-token", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr
  } = _emberData.default;

  var _default = _emberData.default.Model.extend({
    token: attr('string')
  });

  _exports.default = _default;
});