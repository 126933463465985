define("donor-elf-web/models/admin/email", ["exports", "@ember/object", "ember-data"], function (_exports, _object, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;
  const EMAIL_TYPE_LABELS = {
    1: 'New Donations',
    2: 'Weekly Update',
    3: 'Approve Forwarding',
    4: 'Other',
    5: 'Contact Date Alert'
  };

  var _default = Model.extend({
    userId: attr(),
    emailTypeId: attr('number'),
    emailTypeLabel: (0, _object.computed)('emailTypeId', function () {
      return EMAIL_TYPE_LABELS[(0, _object.get)(this, 'emailTypeId')];
    }),
    sentAt: attr('date'),
    openedAt: attr('date'),
    bounced: attr('boolean'),
    bouncedDescription: attr()
  });

  _exports.default = _default;
});