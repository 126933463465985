define("donor-elf-web/controllers/funds/fund/groups/group/add", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/utils", "@ember/service", "ember-concurrency"], function (_exports, _controller, _object, _computed, _utils, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    contactsCache: (0, _service.inject)(),
    q: '',
    qInput: '',
    filterApplied: (0, _computed.notEmpty)('q'),
    sortBy: Object.freeze(['name:asc']),
    sortedContacts: (0, _computed.sort)('model.contactsNotInGroup', 'sortBy'),
    filteredContacts: (0, _object.computed)('sortedContacts.[]', 'q', function () {
      let {
        sortedContacts,
        q
      } = (0, _object.getProperties)(this, 'sortedContacts', 'q');

      if ((0, _utils.isEmpty)(q) || q.length < 2) {
        return sortedContacts;
      }

      let matchingContactIds = {};
      (0, _object.get)(this, 'contactsCache').search(q).forEach(contact => {
        matchingContactIds[(0, _object.get)(contact, 'id')] = true;
      });
      return sortedContacts.filter(contact => {
        return matchingContactIds[(0, _object.get)(contact, 'id')];
      });
    }),

    reset() {
      (0, _object.setProperties)(this, {
        q: '',
        qInput: ''
      });
    },

    debounceApplyFilter: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(500);
      this.send('applyFilter', false);
    }).restartable(),
    actions: {
      applyFilter() {
        (0, _object.set)(this, 'q', (0, _object.get)(this, 'qInput'));
      },

      handleAddedToGroup(contact) {
        (0, _object.get)(this, 'model.contactsNotInGroup').removeObject(contact);
      }

    }
  });

  _exports.default = _default;
});