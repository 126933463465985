define("donor-elf-web/components/progress-bar", ["exports", "@ember/component", "@ember/template-factory", "@ember/template", "@glimmer/component"], function (_exports, _component, _templateFactory, _template, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='progress' ...attributes>
    <div class='progress-bar {{@backgroundClass}}' style={{this.progressBarStyle}} />
  </div>
  */
  {
    "id": "bIRdw/rT",
    "block": "[[[11,0],[24,0,\"progress\"],[17,1],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[\"progress-bar \",[30,2]]]],[15,5,[30,0,[\"progressBarStyle\"]]],[12],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@backgroundClass\"],false,[]]",
    "moduleName": "donor-elf-web/components/progress-bar.hbs",
    "isStrictMode": false
  });

  class ProgressBarComponent extends _component2.default {
    get progressBarStyle() {
      return (0, _template.htmlSafe)(`width: ${this.args.percent}%`);
    }

  }

  _exports.default = ProgressBarComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ProgressBarComponent);
});