define("donor-elf-web/routes/funds/fund", ["exports", "@ember/routing/route", "@ember/object", "rsvp", "@ember/service", "donor-elf-web/lib/logger", "donor-elf-web/adapters/expired-fund-token-error"], function (_exports, _route, _object, _rsvp, _service, _logger, _expiredFundTokenError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FundRoute = (_class = class FundRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "abilities", _descriptor, this);

      _initializerDefineProperty(this, "campaignService", _descriptor2, this);

      _initializerDefineProperty(this, "contactsCache", _descriptor3, this);

      _initializerDefineProperty(this, "currentUser", _descriptor4, this);

      _initializerDefineProperty(this, "fundsService", _descriptor5, this);

      _initializerDefineProperty(this, "navbarSettings", _descriptor6, this);

      _initializerDefineProperty(this, "router", _descriptor7, this);

      _initializerDefineProperty(this, "settings", _descriptor8, this);

      _initializerDefineProperty(this, "store", _descriptor9, this);

      _initializerDefineProperty(this, "taskService", _descriptor10, this);
    }

    beforeModel(transition) {
      var _transition$intent$ur, _transition$intent$co;

      // replaced '/funds/selected-fund/*' with the user's selected fund
      if ((_transition$intent$ur = transition.intent.url) !== null && _transition$intent$ur !== void 0 && _transition$intent$ur.startsWith('/funds/selected-fund')) {
        let subPath = transition.intent.url.replace('/funds/selected-fund', '');
        this.router.transitionTo(`/funds/${this.currentUser.selectedFundId}${subPath}`);
      } else if (transition.intent.url == null && ((_transition$intent$co = transition.intent.contexts) === null || _transition$intent$co === void 0 ? void 0 : _transition$intent$co.join('')) === 'selected-fund') {
        this.router.transitionTo(`/funds/${this.currentUser.selectedFundId}`);
      }
    }

    async model(params) {
      // expecting the browser will be hard-refreshed when switching funds so that
      // all the in-memory caching will be broke
      try {
        let fund = await this.store.findRecord('fund', params.fundId);
        this.fundsService.selectedFund = fund;
        await this.fundsService.populateSelectedFundToken();
        let response = await _rsvp.default.hash({
          fund: fund,
          settings: this.settings.loadSettings(fund.id),
          contactsCache: this.contactsCache.loadContacts(),
          abilities: this.abilities.loadForFund(fund.id)
        });

        if (this.campaignService.campaignsEnabled) {
          await this.campaignService.load();
        } // start loading the taskService data but don't want on it to finish


        this.taskService.load({
          forceLoad: true
        });
        return response;
      } catch (reason) {
        var _reason$errors;

        let firstError = (_reason$errors = reason.errors) === null || _reason$errors === void 0 ? void 0 : _reason$errors.firstObject;

        if ((firstError === null || firstError === void 0 ? void 0 : firstError.status) === '404') {
          return this.transitionTo('funds.no-access');
        }

        throw reason;
      }
    }

    afterModel() {
      this.navbarSettings.topNavbarComponent = 'top-navbar-fund';
    }

    willTransition(transition) {
      var _transition$intent;

      let intentName = ((_transition$intent = transition.intent) === null || _transition$intent === void 0 ? void 0 : _transition$intent.name) || '';

      if (intentName.length > 0 && intentName.indexOf('funds.') !== 0) {
        this.navbarSettings.topNavbarComponent = '';
      }
    }

    error(error, transition) {
      if (error instanceof _expiredFundTokenError.default) {
        _logger.default.error(`invalid fund token while going to route: ${transition.intent.name}`);
      }

      return true;
    } // for some reason when going to the Journal, the `deactivate()` method is called even though
    // it's not leaving the route, so we have to check if there is an active journal in 
    // the `activate` method too.


    activate() {
      var _this$fundsService$da;

      if ((_this$fundsService$da = this.fundsService.dashboard) !== null && _this$fundsService$da !== void 0 && _this$fundsService$da.hasActiveJournal) {
        this.navbarSettings.showActiveJournalButton = true;
      }
    }

    deactivate() {
      if (this.navbarSettings.showActiveJournalButton) {
        this.navbarSettings.showActiveJournalButton = false;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "abilities", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "campaignService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "contactsCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "navbarSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "taskService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "willTransition", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willTransition"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "error", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "error"), _class.prototype)), _class);
  _exports.default = FundRoute;
});