define("donor-elf-web/services/format-date-service", ["exports", "@ember/utils", "@ember/service", "donor-elf-web/lib/date-utils"], function (_exports, _utils, _service, _dateUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    moment
  } = window;
  let FormatDateService = (_class = class FormatDateService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "settings", _descriptor, this);
    }

    // options
    // - fourYear
    //   - display 4 digit year instead of 2 digit (default is false)
    // - noYear
    //   - excludes the year
    format(date, options = {}) {
      if ((0, _utils.isEmpty)(date)) {
        return null;
      }

      if (typeof date === 'string') {
        date = _dateUtils.default.deserializeDateOnly(date);
      }

      let format = 'dateFormat';

      if (options.fourYear) {
        format = `${format}4Year`;
      } else if (options.noYear) {
        format = `${format}NoYear`;
      }

      format = this.settings[format] || 'MM/DD/YY';
      return moment(date).format(format);
    }

    formatForFile(date) {
      if ((0, _utils.isEmpty)(date)) {
        return null;
      }

      return moment(date).format('YYYY-MM-DD');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = FormatDateService;
});