define("donor-elf-web/models/contact", ["exports", "@ember/utils", "@ember-data/model", "ember-cached-decorator-polyfill"], function (_exports, _utils, _model, _emberCachedDecoratorPolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.generateNameFields = generateNameFields;
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ContactModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.attr)('string'), _dec8 = (0, _model.attr)('string'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.attr)('string'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('boolean'), _dec15 = (0, _model.attr)('boolean'), _dec16 = (0, _model.attr)(), _dec17 = (0, _model.attr)(), _dec18 = (0, _model.attr)(), _dec19 = (0, _model.attr)(), _dec20 = (0, _model.attr)('boolean'), _dec21 = (0, _model.attr)('number'), _dec22 = (0, _model.attr)('number'), _dec23 = (0, _model.attr)('number'), _dec24 = (0, _model.attr)('boolean'), _dec25 = (0, _model.attr)('boolean'), _dec26 = (0, _model.attr)('string'), _dec27 = (0, _model.attr)(), _dec28 = (0, _model.attr)('boolean'), (_class = class ContactModel extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "name", _descriptor, this);

      _initializerDefineProperty(this, "mailingName", _descriptor2, this);

      _initializerDefineProperty(this, "greeting", _descriptor3, this);

      _initializerDefineProperty(this, "firstName", _descriptor4, this);

      _initializerDefineProperty(this, "spouseFirstName", _descriptor5, this);

      _initializerDefineProperty(this, "lastName", _descriptor6, this);

      _initializerDefineProperty(this, "spouseLastName", _descriptor7, this);

      _initializerDefineProperty(this, "address1", _descriptor8, this);

      _initializerDefineProperty(this, "address2", _descriptor9, this);

      _initializerDefineProperty(this, "city", _descriptor10, this);

      _initializerDefineProperty(this, "state", _descriptor11, this);

      _initializerDefineProperty(this, "zip", _descriptor12, this);

      _initializerDefineProperty(this, "country", _descriptor13, this);

      _initializerDefineProperty(this, "deceased", _descriptor14, this);

      _initializerDefineProperty(this, "spouseDeceased", _descriptor15, this);

      _initializerDefineProperty(this, "phones", _descriptor16, this);

      _initializerDefineProperty(this, "emails", _descriptor17, this);

      _initializerDefineProperty(this, "groups", _descriptor18, this);

      _initializerDefineProperty(this, "custom", _descriptor19, this);

      _initializerDefineProperty(this, "active", _descriptor20, this);

      _initializerDefineProperty(this, "latitude", _descriptor21, this);

      _initializerDefineProperty(this, "longitude", _descriptor22, this);

      _initializerDefineProperty(this, "locationConfidence", _descriptor23, this);

      _initializerDefineProperty(this, "anonymousDonor", _descriptor24, this);

      _initializerDefineProperty(this, "subscribeToNewsletter", _descriptor25, this);

      _initializerDefineProperty(this, "recommendedByContactId", _descriptor26, this);

      _initializerDefineProperty(this, "emailAddressesToSyncWithMailchimp", _descriptor27, this);

      _initializerDefineProperty(this, "applyMailchimpAutomaticSyncSettings", _descriptor28, this);
    }

    get nameLowerCase() {
      return (this.name || '').toLowerCase();
    }

    get allNamesLowerCase() {
      return `${(this.name || '').toLowerCase()}${(this.firstName || '').toLowerCase()}${(this.lastName || '').toLowerCase()}${(this.spouseFirstName || '').toLowerCase()}${(this.spouseLastName || '').toLowerCase()}`;
    }

    get cityAndState() {
      let {
        city,
        state
      } = this;

      if ((0, _utils.isEmpty)(city) && (0, _utils.isEmpty)(state)) {
        return undefined;
      } else if (!(0, _utils.isEmpty)(city) && !(0, _utils.isEmpty)(state)) {
        return `${city}, ${state}`;
      } else if (!(0, _utils.isEmpty)(city)) {
        return city;
      } else {
        return state;
      }
    }

    get deceasedMessage() {
      let {
        deceased,
        spouseDeceased,
        firstName,
        spouseFirstName
      } = this;

      if (deceased && spouseDeceased) {
        return 'Deceased';
      } else if (deceased) {
        if ((0, _utils.isEmpty)(firstName) || (0, _utils.isEmpty)(spouseFirstName)) {
          return 'Deceased';
        } else {
          return `${firstName} is Deceased`;
        }
      } else if (spouseDeceased) {
        if ((0, _utils.isEmpty)(spouseFirstName)) {
          return 'Spouse is Deceased';
        } else {
          return `${spouseFirstName} is Deceased`;
        }
      }

      return undefined;
    }

    get hasPhones() {
      var _this$phones, _this$phones$data;

      return (((_this$phones = this.phones) === null || _this$phones === void 0 ? void 0 : (_this$phones$data = _this$phones.data) === null || _this$phones$data === void 0 ? void 0 : _this$phones$data.length) || 0) > 0;
    }

    get hasEmails() {
      var _this$emails, _this$emails$data;

      return (((_this$emails = this.emails) === null || _this$emails === void 0 ? void 0 : (_this$emails$data = _this$emails.data) === null || _this$emails$data === void 0 ? void 0 : _this$emails$data.length) || 0) > 0;
    }

    get sortedGroups() {
      var _this$groups;

      return (_this$groups = this.groups) === null || _this$groups === void 0 ? void 0 : _this$groups.sort();
    }

    get hasGroups() {
      return (0, _utils.isPresent)(this.groups);
    }

    get hasCustom() {
      var _this$custom, _this$custom$properti;

      return (((_this$custom = this.custom) === null || _this$custom === void 0 ? void 0 : (_this$custom$properti = _this$custom.properties) === null || _this$custom$properti === void 0 ? void 0 : _this$custom$properti.length) || 0) > 0;
    }

    get customPropertiesByLabel() {
      var _this$custom2;

      return (((_this$custom2 = this.custom) === null || _this$custom2 === void 0 ? void 0 : _this$custom2.properties) || []).reduce((obj, x) => (obj[x.l] = x, obj), {});
    } // is firstName, spouseFirstName, lastName different than name


    get namePartsDifferentThanDisplayName() {
      let {
        nameLowerCase,
        firstName,
        spouseFirstName,
        lastName
      } = this;
      let different = false; // @ts-ignore

      if ((0, _utils.isPresent)(firstName) && nameLowerCase.indexOf(firstName.toLowerCase()) === -1) {
        different = true;
      } // @ts-ignore


      if ((0, _utils.isPresent)(spouseFirstName) && nameLowerCase.indexOf(spouseFirstName.toLowerCase()) === -1) {
        different = true;
      } // @ts-ignore


      if ((0, _utils.isPresent)(lastName) && nameLowerCase.indexOf(lastName.toLowerCase()) === -1) {
        different = true;
      }

      return different;
    }

    get hidden() {
      return !this.active;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "mailingName", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "greeting", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "firstName", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "spouseFirstName", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "lastName", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "spouseLastName", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "address1", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "address2", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "city", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "state", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "zip", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "country", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "deceased", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "spouseDeceased", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "phones", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "emails", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "groups", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "custom", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "active", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "latitude", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "longitude", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "locationConfidence", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "anonymousDonor", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "subscribeToNewsletter", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "recommendedByContactId", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "emailAddressesToSyncWithMailchimp", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "applyMailchimpAutomaticSyncSettings", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "allNamesLowerCase", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "allNamesLowerCase"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cityAndState", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "cityAndState"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortedGroups", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "sortedGroups"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "customPropertiesByLabel", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "customPropertiesByLabel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "namePartsDifferentThanDisplayName", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "namePartsDifferentThanDisplayName"), _class.prototype)), _class));
  _exports.default = ContactModel;

  function generateNameFields(args) {
    var _args$firstName, _args$lastName, _args$spouseFirstName, _args$spouseLastName;

    let names = {};
    let firstName = (_args$firstName = args.firstName) === null || _args$firstName === void 0 ? void 0 : _args$firstName.trim();
    let lastName = (_args$lastName = args.lastName) === null || _args$lastName === void 0 ? void 0 : _args$lastName.trim();
    let spouseFirstName = (_args$spouseFirstName = args.spouseFirstName) === null || _args$spouseFirstName === void 0 ? void 0 : _args$spouseFirstName.trim();
    let spouseLastName = (_args$spouseLastName = args.spouseLastName) === null || _args$spouseLastName === void 0 ? void 0 : _args$spouseLastName.trim();

    if ((0, _utils.isPresent)(firstName) && (0, _utils.isPresent)(lastName) && (0, _utils.isPresent)(spouseFirstName) && (0, _utils.isPresent)(spouseLastName) && spouseLastName !== lastName) {
      names.name = `${lastName}, ${firstName} & ${spouseFirstName} ${spouseLastName}`;
      names.mailingName = `${firstName} ${lastName} & ${spouseFirstName} ${spouseLastName}`;
      names.greeting = `${firstName} & ${spouseFirstName}`;
    } else if ((0, _utils.isPresent)(firstName) && (0, _utils.isPresent)(lastName) && (0, _utils.isPresent)(spouseFirstName)) {
      names.name = `${lastName}, ${firstName} & ${spouseFirstName}`;
      names.mailingName = `${firstName} & ${spouseFirstName} ${lastName}`;
      names.greeting = `${firstName} & ${spouseFirstName}`;
    } else if ((0, _utils.isPresent)(firstName) && (0, _utils.isPresent)(lastName)) {
      names.name = `${lastName}, ${firstName}`;
      names.mailingName = `${firstName} ${lastName}`;
      names.greeting = `${firstName}`;
    } else if ((0, _utils.isPresent)(firstName) && (0, _utils.isPresent)(spouseFirstName)) {
      names.name = `${firstName} & ${spouseFirstName}`;
      names.mailingName = `${firstName} & ${spouseFirstName}`;
      names.greeting = `${firstName} & ${spouseFirstName}`;
    } else if ((0, _utils.isPresent)(firstName)) {
      names.name = firstName;
      names.mailingName = firstName;
      names.greeting = firstName;
    }

    return names;
  }
});