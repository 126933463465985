define("donor-elf-web/routes/funds/fund/groups/group/delete", ["exports", "@ember/routing/route", "@ember/object"], function (_exports, _route, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    model() {
      let groupModel = this.modelFor('funds.fund.groups.group');
      let record = (0, _object.get)(this, 'store').createRecord('edit-group', {
        action: 'delete',
        name: groupModel.group
      });
      return Object.assign({}, groupModel, {
        record
      });
    }

  });

  _exports.default = _default;
});