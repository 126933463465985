define("donor-elf-web/lib/custom-reports/oms-monthly-summary", ["exports", "ember-cached-decorator-polyfill", "donor-elf-web/lib/array-utils"], function (_exports, _emberCachedDecoratorPolyfill, _arrayUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const {
    currency
  } = window;
  let OmsMonthlySummary = (_class = class OmsMonthlySummary {
    constructor(fundCustomRecord) {
      _defineProperty(this, "startDate", void 0);

      _defineProperty(this, "openingBalance", void 0);

      _defineProperty(this, "closingBalance", void 0);

      _defineProperty(this, "status", void 0);

      _defineProperty(this, "revenueAccountSummaries", void 0);

      _defineProperty(this, "expenseAccountSummaries", void 0);

      this.startDate = fundCustomRecord.data.start_date;
      this.openingBalance = fundCustomRecord.data.opening_balance || 0;
      this.closingBalance = fundCustomRecord.data.closing_balance || 0;
      this.status = fundCustomRecord.data.status;
      this.revenueAccountSummaries = (0, _arrayUtils.sortByProperties)(fundCustomRecord.data.account_summaries.filter(x => x.type === 'Revenue'), ['name']);
      this.expenseAccountSummaries = (0, _arrayUtils.sortByProperties)(fundCustomRecord.data.account_summaries.filter(x => x.type === 'Expense'), ['name']);
    }

    get totalRevenue() {
      return this.revenueAccountSummaries.reduce((sum, item) => {
        return currency(sum).add(item.amount).value;
      }, 0);
    }

    get totalExpense() {
      return this.expenseAccountSummaries.reduce((sum, item) => {
        return currency(sum).add(item.amount).value;
      }, 0);
    }

    get netIncome() {
      return this.totalRevenue + this.totalExpense;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "totalRevenue", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "totalRevenue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "totalExpense", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "totalExpense"), _class.prototype)), _class);
  _exports.default = OmsMonthlySummary;
});