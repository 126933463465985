define("donor-elf-web/components/event-info-above-regular-amount", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    attrs
    - eventModel
  */
  var _default = _component.default.extend({
    eventModel: null,
    increaseAmount: (0, _object.computed)('eventModel.id', function () {
      return (0, _object.get)(this, 'eventModel.custom.donation_amount') - (0, _object.get)(this, 'eventModel.custom.regular_amount');
    })
  });

  _exports.default = _default;
});