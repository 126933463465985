define("donor-elf-web/validations/notification", ["exports", "@ember/utils", "ember-changeset-validations/validators"], function (_exports, _utils, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    fundIds: (0, _validators.validatePresence)({
      presence: true,
      message: 'At least one fund needs to be selected'
    }),
    emailTo: (key, newValue
    /*, oldValue, changes, content*/
    ) => {
      if ((0, _utils.isEmpty)(newValue)) {
        return 'An email address needs to be entered';
      }

      if (newValue.length === 1 && (0, _utils.isEmpty)(newValue[0])) {
        return 'An email address needs to be entered';
      }

      newValue.forEach(item => {
        if ((0, _utils.isEmpty)(item)) {
          return 'One of the email addresses entered is empty';
        }
      });
      return true;
    }
  };
  _exports.default = _default;
});