define("donor-elf-web/controllers/funds/fund/total-given-expecting", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "donor-elf-web/lib/array-utils"], function (_exports, _controller, _object, _tracking, _arrayUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    moment
  } = window;
  let TotalGivenExpectingController = (_class = class TotalGivenExpectingController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "contactExpectingItemsSortBy", _descriptor, this);

      _initializerDefineProperty(this, "contactOverdueItemsSortBy", _descriptor2, this);

      _initializerDefineProperty(this, "contactNoGiftItemsSortBy", _descriptor3, this);
    }

    get sortedContactExpectingItems() {
      return (0, _arrayUtils.sortByProperties)(this.model.contactExpectingItems, [`${this.contactExpectingItemsSortBy.property}:${this.contactExpectingItemsSortBy.direction}`]);
    }

    get sortedContactOverdueItems() {
      return (0, _arrayUtils.sortByProperties)(this.model.contactOverdueItems, [`${this.contactOverdueItemsSortBy.property}:${this.contactOverdueItemsSortBy.direction}`]);
    }

    get report() {
      return this.model.givenExpectingReport;
    }

    get givenAmount() {
      var _this$report$data;

      return (_this$report$data = this.report.data) === null || _this$report$data === void 0 ? void 0 : _this$report$data.given_amount;
    }

    get givenPercent() {
      var _this$report$data2;

      return (_this$report$data2 = this.report.data) === null || _this$report$data2 === void 0 ? void 0 : _this$report$data2.given_percent;
    }

    get expectingAmount() {
      var _this$report$data3;

      return (_this$report$data3 = this.report.data) === null || _this$report$data3 === void 0 ? void 0 : _this$report$data3.expected_amount;
    }

    get expectingPercent() {
      var _this$report$data4;

      return (_this$report$data4 = this.report.data) === null || _this$report$data4 === void 0 ? void 0 : _this$report$data4.expected_percent;
    }

    get overdueAmount() {
      var _this$report$data5;

      return (_this$report$data5 = this.report.data) === null || _this$report$data5 === void 0 ? void 0 : _this$report$data5.overdue_amount;
    }

    get overduePercent() {
      var _this$report$data6;

      return (_this$report$data6 = this.report.data) === null || _this$report$data6 === void 0 ? void 0 : _this$report$data6.overdue_percent;
    }

    get totalAmount() {
      var _this$report$data7;

      return (_this$report$data7 = this.report.data) === null || _this$report$data7 === void 0 ? void 0 : _this$report$data7.total_amount;
    }

    get totalPercent() {
      var _this$report$data8;

      return (_this$report$data8 = this.report.data) === null || _this$report$data8 === void 0 ? void 0 : _this$report$data8.total_percent;
    }

    get leftAmount() {
      var _this$report$data9;

      return (_this$report$data9 = this.report.data) === null || _this$report$data9 === void 0 ? void 0 : _this$report$data9.left_amount;
    }

    get leftPercent() {
      var _this$report$data10;

      return (_this$report$data10 = this.report.data) === null || _this$report$data10 === void 0 ? void 0 : _this$report$data10.left_percent;
    }

    get givenFrom() {
      var _this$report$data11;

      return moment((_this$report$data11 = this.report.data) === null || _this$report$data11 === void 0 ? void 0 : _this$report$data11.start_date).format('YYYY-MM-DD');
    }

    get today() {
      return new Date();
    }

    get pledgesTo() {
      return this.report.goalDate;
    }

    sortContactExpectingItemsBy(sortProperty) {
      if (sortProperty === this.contactExpectingItemsSortBy.property) {
        this.contactExpectingItemsSortBy = {
          property: sortProperty,
          direction: this.contactExpectingItemsSortBy.direction === 'asc' ? 'desc' : 'asc'
        };
      } else {
        this.contactExpectingItemsSortBy = {
          property: sortProperty,
          direction: 'asc'
        };
      }
    }

    sortContactOverdueItemsBy(sortProperty) {
      if (sortProperty === this.contactOverdueItemsSortBy.property) {
        this.contactOverdueItemsSortBy = {
          property: sortProperty,
          direction: this.contactOverdueItemsSortBy.direction === 'asc' ? 'desc' : 'asc'
        };
      } else {
        this.contactOverdueItemsSortBy = {
          property: sortProperty,
          direction: 'asc'
        };
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "contactExpectingItemsSortBy", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        property: 'totalExpecting',
        direction: 'desc'
      };
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "contactOverdueItemsSortBy", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        property: 'totalExpecting',
        direction: 'desc'
      };
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "contactNoGiftItemsSortBy", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        property: 'totalExpecting',
        direction: 'desc'
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "sortContactExpectingItemsBy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sortContactExpectingItemsBy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortContactOverdueItemsBy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sortContactOverdueItemsBy"), _class.prototype)), _class);
  _exports.default = TotalGivenExpectingController;
});