define("donor-elf-web/templates/admin/funds/fund/users/delete", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Up2AL0Bf",
    "block": "[[[10,\"ol\"],[14,0,\"breadcrumb row-bottom-margin\"],[12],[1,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[39,0],null,[[\"route\"],[\"admin.funds\"]],[[\"default\"],[[[[1,\"Funds\"]],[]]]]],[13],[1,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[6,[39,0],null,[[\"route\"],[\"admin.funds.fund\"]],[[\"default\"],[[[[1,[33,1,[\"fund\",\"name\"]]]],[]]]]],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[28,[37,3],[[33,1,[\"userFundPermissionsCount\"]],1],null],[[[6,[39,4],null,[[\"cancelRoute\",\"deleteTask\"],[\"admin.funds.fund\",[33,5]]],[[\"default\"],[[[[1,\"    \"],[10,2],[12],[1,\"\\n      Are you sure you want to remove this user from seeing this fund?\\n    \"],[13],[1,\"\\n    \"],[10,\"dl\"],[12],[1,\"\\n      \"],[10,\"dt\"],[12],[1,\"User\"],[13],[1,\"\\n      \"],[10,\"dd\"],[12],[1,[33,1,[\"user\",\"displayName\"]]],[13],[1,\"\\n      \"],[10,\"dt\"],[12],[1,\"Fund\"],[13],[1,\"\\n      \"],[10,\"dd\"],[12],[1,[33,1,[\"fund\",\"name\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]]],[]],[[[6,[39,6],null,null,[[\"default\"],[[[[1,\"    \"],[10,\"h4\"],[14,0,\"mb-3\"],[12],[1,\"Can't Delete Fund Permission\"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      This user doesn't have permission to view any other fund, so you can't remove\\n      their permission to this fund.\\n    \"],[13],[1,\"\\n    \"],[10,\"dl\"],[12],[1,\"\\n      \"],[10,\"dt\"],[12],[1,\"User\"],[13],[1,\"\\n      \"],[10,\"dd\"],[12],[1,[33,1,[\"user\",\"displayName\"]]],[13],[1,\"\\n      \"],[10,\"dt\"],[12],[1,\"Fund\"],[13],[1,\"\\n      \"],[10,\"dd\"],[12],[1,[33,1,[\"fund\",\"name\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[6,[39,0],null,[[\"class\",\"activeClass\",\"route\"],[\"btn btn-outline-secondary mt-3\",\"\",\"admin.funds.fund\"]],[[\"default\"],[[[[1,\"Go Back\"]],[]]]]],[1,\"\\n\"]],[]]]]]],[]]]],[],false,[\"link-to\",\"model\",\"if\",\"gt\",\"delete-form\",\"delete\",\"de-form-card\"]]",
    "moduleName": "donor-elf-web/templates/admin/funds/fund/users/delete.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});