define("donor-elf-web/models/custom-reports/disbursement-availability-report", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    toMonth: attr('number'),
    toYear: attr('number'),
    fundId: attr('number'),
    balance: attr('number'),
    notReimbursed: attr('number'),
    advances: attr('number'),
    availableForDisbursement: attr('number')
  });

  _exports.default = _default;
});