define("donor-elf-web/templates/funds/fund/goal/total", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ixcn0gqO",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n\"],[6,[39,1],null,[[\"changeset\",\"saveTask\"],[[30,0,[\"model\",\"changeset\"]],[30,0,[\"save\"]]]],[[\"default\"],[[[[1,\"    \"],[1,[28,[35,2],null,[[\"changeset\",\"property\",\"label\",\"focusOnInsert\",\"showValidationErrors\"],[[30,0,[\"model\",\"changeset\"]],\"annualGoal\",\"Goal Amount\",true,[30,0,[\"showValidationErrors\"]]]]]],[1,\"\\n\\n    \"],[1,[28,[35,3],null,[[\"changeset\",\"property\",\"label\",\"showValidationErrors\"],[[30,0,[\"model\",\"changeset\"]],\"goalDate\",\"Goal Date\",[30,0,[\"showValidationErrors\"]]]]]],[1,\"\\n\"]],[1]]]]]],[]]]]]],[\"showValidationErrors\"],false,[\"form-card\",\"save-form\",\"form-group\",\"form-group-date\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/goal/total.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});