define("donor-elf-web/templates/funds/fund/more/donor-hub/view", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Fg8bbxFi",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,2],[12],[1,\"\\n    You are connected to the DonorHub server for \"],[10,\"b\"],[12],[1,[33,1,[\"tntOrganization\",\"name\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[33,1,[\"record\",\"lastErrorMessage\"]],[[[1,\"    \"],[10,0],[14,0,\"alert alert-danger\"],[12],[1,\"\\n      \"],[10,2],[12],[1,\"\\n        The following error occurred when DonorElf tried to download gifts from the DonorHub server:\\n      \"],[13],[1,\"\\n      \"],[10,2],[14,0,\"font-weight-bold\"],[12],[1,\"\\n        \"],[1,[33,1,[\"record\",\"lastErrorMessage\"]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,2],[12],[1,\"\\n        We recommend editing your login info to see if that fixes the error. You\\n        can click the button below to edit your login info.\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"mt-4\"],[12],[1,\"\\n\"],[6,[39,3],null,[[\"class\",\"route\"],[\"btn btn-sm btn-outline-danger float-right\",\"funds.fund.more.donor-hub.delete\"]],[[\"default\"],[[[[1,\"      Disconnect from DonorHub\\n\"]],[]]]]],[6,[39,3],null,[[\"class\",\"route\"],[\"btn btn-outline-warning\",\"funds.fund.more.donor-hub.connect.organization\"]],[[\"default\"],[[[[1,\"      Edit Login Info\\n\"]],[]]]]],[1,\"  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"de-form-card\",\"model\",\"if\",\"link-to\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/more/donor-hub/view.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});