define("donor-elf-web/services/power-menu", ["exports", "@ember/service", "fuzzysort", "donor-elf-web/config/environment"], function (_exports, _service, _fuzzysort, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PowerMenuService = (_class = class PowerMenuService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "abilities", _descriptor, this);

      _initializerDefineProperty(this, "addSomethingModal", _descriptor2, this);

      _initializerDefineProperty(this, "campaignService", _descriptor3, this);

      _initializerDefineProperty(this, "currentUser", _descriptor4, this);

      _initializerDefineProperty(this, "fundGoalService", _descriptor5, this);

      _initializerDefineProperty(this, "fundsService", _descriptor6, this);

      _initializerDefineProperty(this, "settings", _descriptor7, this);

      _initializerDefineProperty(this, "store", _descriptor8, this);

      _defineProperty(this, "_currentOptions", null);

      _defineProperty(this, "groupOptions", {});

      _defineProperty(this, "groupOptionsFns", {});

      _defineProperty(this, "prependedGroupIdOrder", []);

      _defineProperty(this, "appendedGroupIdOrder", []);

      _defineProperty(this, "_globalOptions", null);
    }

    get currentOptions() {
      if (this._currentOptions) {
        return this._currentOptions;
      }

      return this._currentOptions = this.setCurrentOptions();
    } // { groupId: [options] }


    // optionFn is function that returns [{
    //   label: 'string' required,
    //   searchBy: 'string' other string to search by. optional
    //   fn: function() function to call when chosen
    //     - if present, then `route` and `models` aren't needed
    //   route: '' route name
    //   models: [] route models
    // }]
    prependOptionsFn(groupId, optionsFn) {
      this.groupOptionsFns[groupId] = optionsFn;
      this.prependedGroupIdOrder = [groupId, ...this.prependedGroupIdOrder.filter(x => x !== groupId)];
      this._currentOptions = null;
    } // see `prependOptionsFn` comment for the expected format of `optionsFn`


    appendOptionsFn(groupId, optionsFn) {
      this.groupOptionsFns[groupId] = optionsFn;
      this.appendedGroupIdOrder = [...this.appendedGroupIdOrder.filter(x => x !== groupId), groupId];
      this._currentOptions = null;
    } // maps the menuItems for the <ReportAction /> component to the format
    // the powerMenu expects


    mapReportActionMenuItems(reportActionMenuItems, {
      prependToLabel
    } = {}) {
      return reportActionMenuItems.map(item => {
        return {
          label: `${prependToLabel}${item.label}`,
          fn: item.onClick
        };
      });
    }

    removeOptions(groupId) {
      delete this.groupOptionsFns[groupId];
      delete this.groupOptions[groupId];
      this.prependedGroupIdOrder = this.prependedGroupIdOrder.filter(x => x !== groupId);
      this.appendedGroupIdOrder = this.appendedGroupIdOrder.filter(x => x !== groupId);
      this._currentOptions = null;
    }

    setCurrentOptions() {
      let options = [];
      this.prependedGroupIdOrder.forEach(groupId => {
        // check if options have already been retrieved
        if (this.groupOptions[groupId]) {
          options = options.concat(this.groupOptions[groupId]);
        } else if (this.groupOptionsFns[groupId]) {
          let groupOptions = this.groupOptionsFns[groupId]();
          this.prepareFuzzySortOnOptions(groupOptions);
          options = options.concat(groupOptions);
        }
      });
      options = options.concat(this.globalOptions);
      this.appendedGroupIdOrder.forEach(groupId => {
        // check if options have already been retrieved
        if (this.groupOptions[groupId]) {
          options = options.concat(this.groupOptions[groupId]);
        } else if (this.groupOptionsFns[groupId]) {
          let groupOptions = this.groupOptionsFns[groupId]();
          this.prepareFuzzySortOnOptions(groupOptions);
          options = options.concat(groupOptions);
        }
      });
      return options;
    }

    get globalOptions() {
      var _this$fundsService$se, _this$settings, _this$settings$fundSe, _this$settings$fundSe2;

      if (this._globalOptions) {
        return this._globalOptions;
      }

      let options = [];
      let selectedFundId = (_this$fundsService$se = this.fundsService.selectedFund) === null || _this$fundsService$se === void 0 ? void 0 : _this$fundsService$se.id;

      if (selectedFundId == null) {
        options = [{
          label: 'Dashboard',
          route: 'funds.fund',
          models: 'selected-fund'
        }, {
          label: 'Logout',
          route: 'logout'
        }];
        this.prepareFuzzySortOnOptions(options);
        return options;
      }

      options.push({
        label: 'Dashboard',
        route: 'funds.fund.dashboard',
        models: selectedFundId
      });

      if (this.abilities.hasAbility('import_ag_usa')) {
        options.push({
          label: 'Import > from Daily Faith Promise File',
          route: 'funds.fund.import.ag-usa',
          models: selectedFundId
        });
      } else if (this.abilities.hasAbility('import_ag_wm')) {
        options.push({
          label: 'Import > from Gifts File',
          route: 'funds.fund.import.ag-wm',
          models: selectedFundId
        });
      }

      options.push({
        label: 'Contacts',
        route: 'funds.fund.contacts',
        models: selectedFundId
      });
      options.push({
        label: 'Intro Video',
        route: 'funds.fund.more.intro-video',
        models: selectedFundId
      });

      if (this.currentUser.hasMultipleFunds) {
        options.push({
          label: 'Change Selected Fund',
          searchBy: 'Fund',
          route: 'funds.index'
        });

        if (this.currentUser.canViewAllFunds) {
          options.push({
            label: 'All Funds',
            route: 'funds.all'
          });
        }
      }

      options.push({
        label: 'Add > Task',
        fn: () => {
          this.addSomethingModal.open({
            type: 'task'
          });
        }
      });
      options.push({
        label: 'Add > Contact',
        fn: () => {
          this.addSomethingModal.open({
            type: 'contact'
          });
        }
      });

      if (this.abilities.hasAbility('edit_pledges') || this.abilities.hasAbility('edit_manual_pledges')) {
        options.push({
          label: 'Add > Pledge',
          fn: () => {
            this.addSomethingModal.open({
              type: 'pledge'
            });
          }
        });
      }

      if (this.abilities.hasAbility('edit_transactions')) {
        options.push({
          label: 'Add > Transaction',
          route: 'funds.fund.transactions.edit.enter',
          models: [selectedFundId, 'add']
        });
      }

      if (this.campaignService.campaignsEnabled) {
        options.push({
          label: 'Campaigns',
          route: 'funds.fund.campaigns',
          models: selectedFundId
        });
      }

      if (this.abilities.hasAbility('journal')) {
        options.push({
          label: 'Active Journal',
          route: 'funds.fund.journals.active',
          models: selectedFundId
        });
        options.push({
          label: 'Reports > Journals',
          route: 'funds.fund.journals',
          models: selectedFundId
        });
      }

      options.push({
        label: 'Reports > Donations by Month',
        route: 'funds.fund.donations-by-month',
        models: selectedFundId
      });
      options.push({
        label: 'Reports > Transactions',
        searchBy: 'Transactions',
        route: 'funds.fund.transactions',
        models: selectedFundId
      });

      if (this.abilities.hasAbility('feature_flag_1') || this.currentUser.isSiteAdmin) {
        options.push({
          label: 'Reports > Annual Comparison',
          route: 'funds.fund.annual-comparison',
          models: selectedFundId
        });
      }

      if ((_this$settings = this.settings) !== null && _this$settings !== void 0 && (_this$settings$fundSe = _this$settings.fundSettings) !== null && _this$settings$fundSe !== void 0 && (_this$settings$fundSe2 = _this$settings$fundSe.customReports) !== null && _this$settings$fundSe2 !== void 0 && _this$settings$fundSe2.includes('oms-monthly-summary')) {
        options.push({
          label: 'Reports > OMS Monthly Summary',
          route: 'funds.fund.custom-reports.oms-monthly-summary',
          models: selectedFundId
        });
      } else {
        options.push({
          label: 'Reports > Monthly Summary',
          route: 'funds.fund.monthly-summary',
          models: selectedFundId
        });
      }

      options.push({
        label: 'Reports > Tasks',
        searchBy: 'Tasks',
        route: 'funds.fund.tasks',
        models: selectedFundId
      });
      options.push({
        label: 'Reports > Donors by Time Frame',
        route: 'funds.fund.donors-by-time-frame',
        models: selectedFundId
      });
      options.push({
        label: 'Reports > Pledges',
        searchBy: 'Pledge',
        route: 'funds.fund.pledges',
        models: selectedFundId
      });
      options.push({
        label: 'Reports > Pledge Fulfillment',
        searchBy: '-Pledge',
        route: 'funds.fund.pledge-fulfillment',
        models: selectedFundId
      });

      if (this.abilities.noAbility('view_admin_area')) {
        options.push({
          label: 'Reports > Communications',
          route: 'funds.fund.communications',
          models: selectedFundId
        });
      }

      options.push({
        label: 'Reports > News Feed',
        route: 'funds.fund.news-feed',
        models: selectedFundId
      });

      if (this.abilities.hasAbility('review_items')) {
        options.push({
          label: 'Reports > Review Items',
          route: 'funds.fund.review-items',
          models: selectedFundId
        });
      }

      options.push({
        label: 'Reports > Dates',
        route: 'funds.fund.dates',
        models: selectedFundId
      });

      if (this.abilities.hasAbility('view_contact_name')) {
        options.push({
          label: 'Reports > Map my Contacts',
          route: 'funds.fund.map-my-contacts',
          models: selectedFundId
        });
      }

      if (this.fundGoalService.hasYtdGoalType) {
        options.push({
          label: 'Reports > Next Year Projection',
          route: 'funds.fund.ytd-next-year-projection',
          models: selectedFundId
        });
      }

      if (this.currentUser.hasMultipleFunds) {
        options.push({
          label: 'Reports > All Funds Summary',
          route: 'funds.fund.funds-summary',
          models: selectedFundId
        });
      }

      if (!this.currentUser.isDemoUser) {
        options.push({
          label: 'More > Notifications',
          route: 'funds.fund.more.notifications',
          models: selectedFundId
        });
      }

      if (this.abilities.hasAbility('feature_flag_2') && this.abilities.hasAbility('edit_contact')) {
        options.push({
          label: 'More > Edit Property Definitions',
          route: 'funds.fund.properties',
          models: selectedFundId
        });
      }

      if (this.abilities.hasAbility('sync_with_mailchimp')) {
        options.push({
          label: 'More > MailChimp',
          route: 'funds.fund.more.mailchimp-sync',
          models: selectedFundId
        });
      }

      if (this.abilities.hasAbility('edit_contact') && this.currentUser.newsletterSyncEnabled) {
        options.push({
          label: 'More > Newsletter Sync',
          route: 'funds.fund.more.newsletter',
          models: selectedFundId
        });
      }

      options.push({
        label: 'Import > Contacts from CSV file',
        route: 'funds.fund.contacts.upload.file',
        models: selectedFundId
      });
      options.push({
        label: 'Import > from MPDx',
        route: 'funds.fund.import.mpdx',
        models: selectedFundId
      });
      options.push({
        label: 'Import > from TntConnect',
        route: 'funds.fund.more.tnt-connect-import',
        models: selectedFundId
      });
      options.push({
        label: 'Import > from Karani',
        route: 'funds.fund.more.karani-import',
        models: selectedFundId
      });

      if (this.abilities.hasAbility('connect_to_donor_hub')) {
        options.push({
          label: 'Import > from DonorHub',
          route: 'funds.fund.more.donor-hub',
          models: selectedFundId
        });
      }

      if (this.abilities.hasAbility('import_iv_prospects')) {
        options.push({
          label: 'Import > from InterVarsity Prospect Tool',
          route: 'funds.fund.import.intervarsity-prospects',
          models: selectedFundId
        });
      }

      if (this.abilities.hasAbility('view_admin_area')) {
        var _this$settings2, _this$settings2$userS;

        if (this.abilities.hasAbility('admin_view_users_and_funds')) {
          options.push({
            label: 'Admin > Users',
            route: 'admin.users'
          });
          options.push({
            label: 'Admin > Funds',
            route: 'admin.funds'
          });
        }

        if (this.abilities.hasAbility('admin_address_changes')) {
          options.push({
            label: 'Admin > Address Changes',
            route: 'admin.address-changes'
          });
        }

        if (this.abilities.hasAbility('view_fund_groups')) {
          options.push({
            label: 'Admin > Fund Groups',
            route: 'admin.fund-groups'
          });
        }

        if (this.abilities.hasAbility('edit_fund_mappings')) {
          options.push({
            label: 'Admin > Fund Mappings',
            route: 'admin.fund-mappings'
          });
        }

        if (this.abilities.hasAbility('edit_balance_adjustments')) {
          options.push({
            label: 'Admin > Balance Adjustments',
            route: 'admin.balance-adjustments'
          });
        }

        if ((_this$settings2 = this.settings) !== null && _this$settings2 !== void 0 && (_this$settings2$userS = _this$settings2.userSettings) !== null && _this$settings2$userS !== void 0 && _this$settings2$userS.showUploadFiles) {
          options.push({
            label: 'Admin > Upload Reports',
            route: 'admin.upload.new'
          });
        }
      }

      if (this.abilities.hasAbility('import_iv_prospects')) {
        options.push({
          label: 'Help Videos',
          route: 'funds.fund.more.intervarsity-help',
          models: selectedFundId
        });
      }

      if (this.currentUser.isSiteAdmin) {
        options.push({
          label: 'Passkeys > Add',
          route: 'funds.fund.more.passkeys.add',
          models: selectedFundId
        });
        options.push({
          label: 'Passkeys > Login',
          route: 'funds.fund.more.passkeys.login',
          models: selectedFundId
        });
        options.push({
          label: 'Site Admin > Sidekiq Dashboard',
          fn: async () => {
            await this.store.createRecord('devise-sign-in').save();
            window.open(_environment.default.environment === 'development' ? 'http://localhost:3000/sidekiq' : '/sidekiq', '_blank');
          }
        });
      }

      options.push({
        label: 'Ask a Question',
        route: 'funds.fund.question',
        models: selectedFundId
      });
      options.push({
        label: 'Help',
        route: 'funds.fund.question',
        models: selectedFundId
      });
      options.push({
        label: 'Logout',
        route: 'logout'
      });
      this.prepareFuzzySortOnOptions(options);
      return this._globalOptions = options;
    }

    prepareFuzzySortOnOptions(options) {
      options.forEach(x => {
        x.labelPrepared = _fuzzysort.default.prepare(x.label);

        if (x.searchBy) {
          x.searchByPrepared = _fuzzysort.default.prepare(x.searchBy);
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "abilities", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "addSomethingModal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "campaignService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "fundGoalService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = PowerMenuService;
});