define("donor-elf-web/templates/funds/fund/groups/group", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ZTH40ROF",
    "block": "[[[10,\"ol\"],[14,0,\"breadcrumb row-bottom-margin\"],[12],[1,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[39,0],null,[[\"route\"],[\"funds.fund.contacts\"]],[[\"default\"],[[[[1,\"Contacts\"]],[]]]]],[13],[1,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[39,0],null,[[\"route\"],[\"funds.fund.groups\"]],[[\"default\"],[[[[1,\"Groups\"]],[]]]]],[13],[1,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[1,\"\\n\"],[41,[28,[37,2],[[33,3,[\"currentRouteName\"]],\"funds.fund.groups.group.add\"],null],[[[1,\"      \"],[6,[39,0],null,[[\"route\"],[\"funds.fund.groups.group\"]],[[\"default\"],[[[[1,[33,4,[\"group\"]]]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[33,4,[\"group\"]]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[46,[28,[37,6],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"link-to\",\"if\",\"eq\",\"router\",\"model\",\"component\",\"-outlet\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/groups/group.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});