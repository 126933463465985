define("donor-elf-web/components/contact/details/properties/custom-property/add/existing", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "ember-changeset", "ember-changeset-validations", "donor-elf-web/validations/contact-custom-property"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _service, _emberChangeset, _emberChangesetValidations, _contactCustomProperty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes>
    <Modal::HeaderSecond>
      {{@propertyInfo.l}}
    </Modal::HeaderSecond>
    <div class='pt-2 px-3'>
      <div class='mb-3'>
        <Contact::Details::Properties::CustomProperty::EditValue
          @propertyInfo={{@propertyInfo}}
          @changeset={{this.changeset}}
          @showValidationErrors={{this.showValidationErrors}}
          {{on-enter this.save}}
          {{focus-on-insert}}
          class='w-100 {{if this.notListType 'mt-2'}}'
        />
      </div>
    </div>
    {{#if this.saveError}}
      <div class='px-3 pb-3'>
        <GenericError
          @error={{this.saveError}}
          @useLeftBorderCard={{true}}
          class='tw-text-sm'
        />
      </div>
    {{/if}}
    <Modal::Footer>
      <button
        {{on 'click' this.save}}
        class='mr-2 btn btn-xs btn-warning'
        disabled='{{if this.saving 'disabled'}}'
        type='button'
      >
        {{#if this.saving}}
          Saving...
        {{else}}
          Save
        {{/if}}
      </button>
      {{#unless this.saving}}
        <button
          {{on 'click' @onCancel}}
          class='btn btn-sm py-0 pl-0 btn-link'
          type='button'
        >
          Cancel
        </button>
      {{/unless}}
    </Modal::Footer>
  </div>
  */
  {
    "id": "ysCQOcvY",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[1,[30,2,[\"l\"]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[10,0],[14,0,\"pt-2 px-3\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"mb-3\"],[12],[1,\"\\n      \"],[8,[39,1],[[16,0,[29,[\"w-100 \",[52,[30,0,[\"notListType\"]],\"mt-2\"]]]],[4,[38,3],[[30,0,[\"save\"]]],null],[4,[38,4],null,null]],[[\"@propertyInfo\",\"@changeset\",\"@showValidationErrors\"],[[30,2],[30,0,[\"changeset\"]],[30,0,[\"showValidationErrors\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"saveError\"]],[[[1,\"    \"],[10,0],[14,0,\"px-3 pb-3\"],[12],[1,\"\\n      \"],[8,[39,5],[[24,0,\"tw-text-sm\"]],[[\"@error\",\"@useLeftBorderCard\"],[[30,0,[\"saveError\"]],true]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[8,[39,6],null,null,[[\"default\"],[[[[1,\"\\n    \"],[11,\"button\"],[24,0,\"mr-2 btn btn-xs btn-warning\"],[16,\"disabled\",[29,[[52,[30,0,[\"saving\"]],\"disabled\"]]]],[24,4,\"button\"],[4,[38,7],[\"click\",[30,0,[\"save\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"saving\"]],[[[1,\"        Saving...\\n\"]],[]],[[[1,\"        Save\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"],[41,[51,[30,0,[\"saving\"]]],[[[1,\"      \"],[11,\"button\"],[24,0,\"btn btn-sm py-0 pl-0 btn-link\"],[24,4,\"button\"],[4,[38,7],[\"click\",[30,3]],null],[12],[1,\"\\n        Cancel\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@propertyInfo\",\"@onCancel\"],false,[\"modal/header-second\",\"contact/details/properties/custom-property/edit-value\",\"if\",\"on-enter\",\"focus-on-insert\",\"generic-error\",\"modal/footer\",\"on\",\"unless\"]]",
    "moduleName": "donor-elf-web/components/contact/details/properties/custom-property/add/existing.hbs",
    "isStrictMode": false
  });

  let ContactDetailsPropertiesCustomPropertyAddExistingComponent = (_class = class ContactDetailsPropertiesCustomPropertyAddExistingComponent extends _component2.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "contactsCache", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _defineProperty(this, "changeset", void 0);

      _initializerDefineProperty(this, "showValidationErrors", _descriptor3, this);

      _initializerDefineProperty(this, "saving", _descriptor4, this);

      _initializerDefineProperty(this, "saveError", _descriptor5, this);

      let validation = (0, _contactCustomProperty.default)({
        contactsCache: this.contactsCache,
        isNewProperty: false
      });
      this.changeset = new _emberChangeset.default({
        l: args.propertyInfo.l,
        t: args.propertyInfo.t,
        v: ''
      }, (0, _emberChangesetValidations.default)(validation), validation);
    }

    get notListType() {
      return this.args.propertyInfo.t !== 'l';
    }

    async save() {
      await this.changeset.validate();

      if (this.changeset.get('isValid')) {
        this.saving = true;

        try {
          let saveCustomProperty = this.store.createRecord('custom-property', {
            contactId: this.args.contact.id,
            l: this.args.propertyInfo.l,
            t: this.args.propertyInfo.t,
            v: this.changeset.get('v'),
            o: this.changeset.get('o')
          });
          await saveCustomProperty.save();

          if (saveCustomProperty.customPropertyInfo != null) {
            this.contactsCache.updateCustomPropertyInfo(saveCustomProperty.customPropertyInfo);
          }

          await this.args.contact.reload();
          this.changeset = undefined;
          this.args.afterSave();
        } catch (e) {
          this.saveError = e;
        }

        this.saving = false;
      } else {
        this.showValidationErrors = true;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "contactsCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showValidationErrors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "saving", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "saveError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class);
  _exports.default = ContactDetailsPropertiesCustomPropertyAddExistingComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ContactDetailsPropertiesCustomPropertyAddExistingComponent);
});