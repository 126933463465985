define("donor-elf-web/helpers/to-lower-case", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _helper.default.extend({
    compute(params) {
      let val = params[0];

      if (typeof val === 'string') {
        return val.toLowerCase();
      }

      return val;
    }

  });

  _exports.default = _default;
});