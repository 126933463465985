define("donor-elf-web/validations/journal-stage", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: (0, _validators.validatePresence)({
      presence: true,
      message: "Name can't be blank"
    }),
    weeklyGoal: (0, _validators.validateNumber)({
      gte: 0,
      integer: true,
      allowBlank: true,
      message: "Weekly Goal must be a positive whole number"
    })
  };
  _exports.default = _default;
});