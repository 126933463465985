define("donor-elf-web/templates/funds/fund/more/mailchimp/connected-to-another-fund", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "qQjzy5Rc",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],[[24,0,\"mb-5\"]],null,null],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Your \"],[10,\"strong\"],[12],[1,[30,0,[\"listName\"]]],[13],[1,\" Mailchimp List is connected to\\n    another fund\\n    (\"],[10,\"strong\"],[12],[1,[30,0,[\"connectedToFundName\"]]],[13],[1,\")\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    You will need to switch to your \\n    \"],[11,3],[24,6,\"#\"],[4,[38,2],[\"click\",[28,[37,3],[[30,0,[\"switchToConnectedFund\"]]],null]],null],[12],[1,\"\\n      \"],[1,[30,0,[\"connectedToFundName\"]]],[1,\"\\n    \"],[13],[1,\"\\n    fund to make changes to the connection\\n    with MailChimp.\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"form-card\",\"mailchimp-header\",\"on\",\"prevent-default\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/more/mailchimp/connected-to-another-fund.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});