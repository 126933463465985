define("donor-elf-web/lib/journal-utils", ["exports", "@ember/utils", "@glimmer/tracking", "ember-cached-decorator-polyfill", "donor-elf-web/lib/date-utils", "donor-elf-web/lib/models/journal-report-default"], function (_exports, _utils, _tracking, _emberCachedDecoratorPolyfill, _dateUtils, _journalReportDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.CalculateIntervalCumulativeDecisionSummaries = CalculateIntervalCumulativeDecisionSummaries;
  _exports.GetIntervalCumulativeAmounts = GetIntervalCumulativeAmounts;
  _exports.JournalGroupDownloadInterVarsity = _exports.JournalCalculations = _exports.JournalStageColumn = _exports.JournalRow = _exports.JournalRowTaskInfo = _exports.JournalIntervalDate = _exports.goalTypeLabels = void 0;

  var _class, _descriptor, _class3, _descriptor2, _descriptor3, _descriptor4, _class5, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _class7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const {
    currency
  } = window;
  const goalTypeLabels = {
    1: 'Monthly',
    2: 'Annual',
    3: 'One Time'
  };
  _exports.goalTypeLabels = goalTypeLabels;

  class JournalIntervalDate {
    constructor(args) {
      _defineProperty(this, "start", void 0);

      _defineProperty(this, "end", void 0);

      _defineProperty(this, "dateRangeFormatted", void 0);

      _defineProperty(this, "_startDate", void 0);

      _defineProperty(this, "_endDate", void 0);

      this.start = args.start;
      this.end = args.end; // TODO: look into getting rid of JournalReportDefault.getDateRangeFormatted and just
      // put the calculation in here

      this.dateRangeFormatted = _journalReportDefault.default.getDateRangeFormatted({
        formatDateService: args.formatDateService,
        start: args.start,
        end: args.end
      });
    }

    get startDate() {
      if (this._startDate) {
        return this._startDate;
      }

      return this._startDate = _dateUtils.default.deserializeDateOnly(this.start);
    }

    get endDate() {
      if (this._endDate) {
        return this._endDate;
      }

      return this._endDate = _dateUtils.default.deserializeDateOnly(this.end);
    }

  }

  _exports.JournalIntervalDate = JournalIntervalDate;

  // yesDecisions must be for the same goal
  function CalculateIntervalCumulativeDecisionSummaries(intervalDates, yesDecisions) {
    let intervalAmounts = {};
    intervalDates.forEach(({
      start
    }) => {
      intervalAmounts[start] = {
        decision: 0,
        withGift: 0
      };
    });
    yesDecisions.forEach(decision => {
      let found = {
        decision: false,
        withGift: (0, _utils.isEmpty)(decision.firstGiftDate)
      };

      for (let dateIndex = intervalDates.length - 1; dateIndex >= 0; dateIndex--) {
        if (!found.decision && (decision.decisionDate >= intervalDates[dateIndex].start || dateIndex === 0)) {
          intervalAmounts[intervalDates[dateIndex].start].decision = currency(intervalAmounts[intervalDates[dateIndex].start].decision).add(decision.amount).value;
          found.decision = true; // giftDate can't be before decisionDate

          if (!found.withGift) {
            intervalAmounts[intervalDates[dateIndex].start].withGift = currency(intervalAmounts[intervalDates[dateIndex].start].withGift).add(decision.amount).value;
            found.withGift = true;
          }
        }

        if (!found.withGift && (decision.firstGiftDate >= intervalDates[dateIndex].start || dateIndex === 0)) {
          intervalAmounts[intervalDates[dateIndex].start].withGift = currency(intervalAmounts[intervalDates[dateIndex].start].withGift).add(decision.amount).value;
          found.withGift = true;
        }

        if (found.decision && found.withGift) {
          break;
        }
      }
    });
    let cumulativeDecisionAmount = 0;
    let cumulativeWithGiftAmount = 0;
    return intervalDates.map(intervalDate => {
      let intervalAmount = intervalAmounts[intervalDate.start];
      cumulativeDecisionAmount = currency(cumulativeDecisionAmount).add(intervalAmount.decision).value;
      cumulativeWithGiftAmount = currency(cumulativeWithGiftAmount).add(intervalAmount.withGift).value;
      return {
        date: intervalDate,
        cumulativeAmount: cumulativeDecisionAmount,
        cumulativeWithGiftAmount: cumulativeWithGiftAmount,
        newAmount: intervalAmount.decision,
        newWithGiftAmount: intervalAmount.withGift
      };
    });
  } // yesDecisions must be for the same goal


  function GetIntervalCumulativeAmounts(intervalDates, yesDecisions) {
    let intervalAmounts = {};
    intervalDates.forEach(({
      start
    }) => {
      intervalAmounts[start] = {
        decision: 0,
        withGift: 0
      };
    });
    yesDecisions.forEach(decision => {
      let found = {
        decision: false,
        withGift: (0, _utils.isEmpty)(decision.firstGiftDate)
      };

      for (let dateIndex = intervalDates.length - 1; dateIndex >= 0; dateIndex--) {
        if (!found.decision && (decision.decisionDate >= intervalDates[dateIndex].start || dateIndex === 0)) {
          intervalAmounts[intervalDates[dateIndex].start].decision = currency(intervalAmounts[intervalDates[dateIndex].start].decision).add(decision.amount).value;
          found.decision = true;
        }

        if (!found.withGift && (decision.firstGiftDate >= intervalDates[dateIndex].start || dateIndex === 0)) {
          intervalAmounts[intervalDates[dateIndex].start].withGift = currency(intervalAmounts[intervalDates[dateIndex].start].withGift).add(decision.amount).value;
          found.withGift = true;
        }

        if (found.decision && found.withGift) {
          break;
        }
      }
    });
    let cumulativeDecisionAmount = 0;
    let cumulativeWithGiftAmount = 0;
    return intervalDates.map(dateInfo => {
      cumulativeDecisionAmount = currency(cumulativeDecisionAmount).add(intervalAmounts[dateInfo.start].decision).value;
      cumulativeWithGiftAmount = currency(cumulativeWithGiftAmount).add(intervalAmounts[dateInfo.start].withGift).value;
      return { ...dateInfo,
        decision: cumulativeDecisionAmount,
        withGift: cumulativeWithGiftAmount
      };
    });
  }

  let JournalRowTaskInfo = (_class = class JournalRowTaskInfo {
    constructor(task) {
      _defineProperty(this, "task", void 0);

      _initializerDefineProperty(this, "errorSavingTask", _descriptor, this);

      this.task = task;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "errorSavingTask", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class);
  _exports.JournalRowTaskInfo = JournalRowTaskInfo;
  let JournalRow = (_class3 = class JournalRow {
    constructor(journalContactInfo) {
      _defineProperty(this, "journalContactInfo", void 0);

      _defineProperty(this, "taskInfosById", {});

      _initializerDefineProperty(this, "showTasks", _descriptor2, this);

      _initializerDefineProperty(this, "active", _descriptor3, this);

      _initializerDefineProperty(this, "cellState", _descriptor4, this);

      this.journalContactInfo = journalContactInfo;
      this.journalContactInfo.journalInfo.sortedStages.forEach(stage => {
        this.cellState[stage.id] = {};
      });
      this.cellState['contact'] = {};
    }

    get taskInfosByStageId() {
      let data = {};
      let {
        tasksByStageId
      } = this.journalContactInfo;
      Object.keys(tasksByStageId).forEach(stageId => {
        var _tasksByStageId$stage;

        data[stageId] = [];
        (_tasksByStageId$stage = tasksByStageId[stageId]) === null || _tasksByStageId$stage === void 0 ? void 0 : _tasksByStageId$stage.forEach(task => {
          if (this.taskInfosById[task.id] == null) {
            this.taskInfosById[task.id] = new JournalRowTaskInfo(task);
          }

          data[stageId].push(this.taskInfosById[task.id]);
        });
      });
      return data;
    }

    // call this whenever the cellState is changed so the template knows about it
    fireCellStateChanged() {
      this.cellState = { ...this.cellState
      };
    }

  }, (_applyDecoratedDescriptor(_class3.prototype, "taskInfosByStageId", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class3.prototype, "taskInfosByStageId"), _class3.prototype), _descriptor2 = _applyDecoratedDescriptor(_class3.prototype, "showTasks", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class3.prototype, "active", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "cellState", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  })), _class3);
  _exports.JournalRow = JournalRow;
  let JournalStageColumn = (_class5 = class JournalStageColumn {
    constructor(props = {}) {
      _defineProperty(this, "taskService", void 0);

      _defineProperty(this, "abilities", void 0);

      _defineProperty(this, "name", void 0);

      _defineProperty(this, "isFixed", void 0);

      _defineProperty(this, "width", void 0);

      _defineProperty(this, "isContactColumn", false);

      _defineProperty(this, "isStageColumn", false);

      _defineProperty(this, "isFollowUpColumn", false);

      _defineProperty(this, "isDecisionColumn", false);

      _defineProperty(this, "isLastStage", false);

      _defineProperty(this, "stageId", void 0);

      _defineProperty(this, "stageIndex", void 0);

      _initializerDefineProperty(this, "unfinishedTasksCount", _descriptor5, this);

      _initializerDefineProperty(this, "finishedTasksCount", _descriptor6, this);

      _initializerDefineProperty(this, "decisionsCount", _descriptor7, this);

      _initializerDefineProperty(this, "lastTaskActionId", _descriptor8, this);

      _initializerDefineProperty(this, "lastTaskMediumId", _descriptor9, this);

      _initializerDefineProperty(this, "showCompletedQuickAddButton", _descriptor10, this);

      _initializerDefineProperty(this, "showAttemptedQuickAddButton", _descriptor11, this);

      Object.assign(this, props);
      this.calculateShowQuickAddButtons();
    } // not needed for contact column


    calculateShowQuickAddButtons() {
      var _this$taskService$ava;

      if (this.isContactColumn) {
        return;
      }

      this.showCompletedQuickAddButton = this.lastTaskActionId != null;
      this.showAttemptedQuickAddButton = this.lastTaskActionId != null && ((_this$taskService$ava = this.taskService.availableResultsByActionId[this.lastTaskActionId]) === null || _this$taskService$ava === void 0 ? void 0 : _this$taskService$ava.some(x => x.label === 'Attempted'));
    }

  }, (_descriptor5 = _applyDecoratedDescriptor(_class5.prototype, "unfinishedTasksCount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class5.prototype, "finishedTasksCount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class5.prototype, "decisionsCount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class5.prototype, "lastTaskActionId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class5.prototype, "lastTaskMediumId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class5.prototype, "showCompletedQuickAddButton", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class5.prototype, "showAttemptedQuickAddButton", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class5);
  _exports.JournalStageColumn = JournalStageColumn;
  let JournalCalculations = (_class7 = class JournalCalculations {
    constructor(journalInfo, journalContactInfos, journalDashboard) {
      _defineProperty(this, "journalInfo", void 0);

      _defineProperty(this, "journalContactInfos", void 0);

      _defineProperty(this, "journalDashboard", void 0);

      this.journalInfo = journalInfo;
      this.journalContactInfos = journalContactInfos;
      this.journalDashboard = journalDashboard;
    }

    get decisionsRequireGift() {
      return this.journalInfo.journal.decisionsRequireGift;
    }

    get goal() {
      return this.journalInfo.goals[0];
    }

    get goalAmount() {
      var _this$goal;

      return ((_this$goal = this.goal) === null || _this$goal === void 0 ? void 0 : _this$goal.amount) || 1;
    }

    get goalDate() {
      return this.journalInfo.journal.goalDate;
    }

    get raisedAmount() {
      return this.decisionsRequireGift ? this.withGiftAmount : this.decisionAmount;
    }

    get raisedPercent() {
      return Math.round(this.raisedAmount * 100 / this.goalAmount);
    }

    get decisionAmount() {
      var _intervalCumulativeAm;

      let {
        intervalCumulativeAmounts
      } = this;
      return ((_intervalCumulativeAm = intervalCumulativeAmounts[intervalCumulativeAmounts.length - 1]) === null || _intervalCumulativeAm === void 0 ? void 0 : _intervalCumulativeAm.decision) || 0;
    }

    get decisionPercent() {
      return Math.round(this.decisionAmount * 100 / this.goalAmount) - this.withGiftPercent;
    }

    get withGiftAmount() {
      var _intervalCumulativeAm2;

      let {
        intervalCumulativeAmounts
      } = this;
      return ((_intervalCumulativeAm2 = intervalCumulativeAmounts[intervalCumulativeAmounts.length - 1]) === null || _intervalCumulativeAm2 === void 0 ? void 0 : _intervalCumulativeAm2.withGift) || 0;
    }

    get withGiftPercent() {
      return Math.round(this.withGiftAmount * 100 / this.goalAmount);
    } // Array<YesDecision> [{ amount: 5.5, decisionDate: '2021-04-02', firstGiftDate: '2021-04-02' }]


    get yesDecisions() {
      let {
        goal
      } = this;

      if (goal == null) {
        return [];
      }

      let decisions = [];
      this.journalContactInfos.forEach(info => {
        info.journalDecisions.forEach(decision => {
          if (decision.yesNo && decision.journalGoalId === goal.id) {
            decisions.push({
              amount: decision.amount,
              decisionDate: _dateUtils.default.serializeToDateOnly(decision.decisionDate),
              firstGiftDate: (0, _utils.isPresent)(decision.firstGiftDate) ? _dateUtils.default.serializeToDateOnly(decision.firstGiftDate) : null
            });
          }
        });
      });
      return decisions;
    }

    get intervalCumulativeAmounts() {
      if (this.goal == null) {
        return [];
      }

      let {
        yesDecisions,
        journalDashboard: {
          intervalDates
        }
      } = this;
      return GetIntervalCumulativeAmounts(intervalDates, yesDecisions);
    }

  }, (_applyDecoratedDescriptor(_class7.prototype, "raisedAmount", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class7.prototype, "raisedAmount"), _class7.prototype), _applyDecoratedDescriptor(_class7.prototype, "decisionAmount", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class7.prototype, "decisionAmount"), _class7.prototype), _applyDecoratedDescriptor(_class7.prototype, "withGiftAmount", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class7.prototype, "withGiftAmount"), _class7.prototype), _applyDecoratedDescriptor(_class7.prototype, "intervalCumulativeAmounts", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class7.prototype, "intervalCumulativeAmounts"), _class7.prototype)), _class7); // Journal Group Download specific for InterVarsity

  _exports.JournalCalculations = JournalCalculations;

  class JournalGroupDownloadInterVarsity {
    constructor(journalDigestInfos, formatDateService) {
      _defineProperty(this, "journalDigestInfos", void 0);

      _defineProperty(this, "formatDateService", void 0);

      this.journalDigestInfos = journalDigestInfos;
      this.formatDateService = formatDateService;
    }

    generateReport() {
      let lastSixIntervalDateInfos = this.getLastSixIntervalDateInfos(this.journalDigestInfos);
      let headerRow = ['Fund', 'Journal Id', 'Journal Name', 'Start Date', 'Goal Amount', 'Goal Date', 'Raised Amount', 'Raised %', 'Total Engages', 'Total Asks Made', 'Total Recommendation Asks', 'Total Recommendations Collected', 'Total Follow Ups', 'Total Closed Asks'];
      lastSixIntervalDateInfos.forEach(x => {
        headerRow.push(`Engages ${x.dateFormatted.split(' ')[0]}`);
      });
      lastSixIntervalDateInfos.forEach(x => {
        headerRow.push(`Asks Made ${x.dateFormatted.split(' ')[0]}`);
      });
      headerRow.push('Recurring');
      headerRow.push('Yes Decisions');
      headerRow.push('# Contacts');
      headerRow.push('# Contacts w Task or Decision');
      let rows = [headerRow];
      this.journalDigestInfos.forEach(info => {
        var _info$reportIntervars, _info$reportIntervars2, _info$journalContactI, _info$journalContactI2, _info$journalContactI3;

        let journalCalculations = new JournalCalculations(info.journalInfo, info.journalContactInfos, info.journalDashboard);
        let lastFullIntervalIndex = this.getLastFullIntervalIndex(info);
        let lastFullIntervalItem = info.reportIntervarsity.intervalData[lastFullIntervalIndex];
        let lastSixFullIntervalItems = lastSixIntervalDateInfos.map(dateInfo => info.reportIntervarsity.intervalData.find(x => x.date == dateInfo.date));
        let row = [];
        row.push(info.journalSlim.fundName);
        row.push(info.journalSlim.id);
        row.push(info.journalSlim.name);
        row.push(this.formatDateService.formatForFile(info.journalSlim.startDate));
        row.push(journalCalculations.goalAmount);
        row.push(this.formatDateService.formatForFile(journalCalculations.goalDate));
        row.push(journalCalculations.raisedAmount);
        row.push(journalCalculations.raisedPercent);
        row.push((lastFullIntervalItem === null || lastFullIntervalItem === void 0 ? void 0 : lastFullIntervalItem.completedTaskCounts.engagementCumulative) || 0);
        row.push((lastFullIntervalItem === null || lastFullIntervalItem === void 0 ? void 0 : lastFullIntervalItem.completedTaskCounts.askCumulative) || 0);
        row.push((lastFullIntervalItem === null || lastFullIntervalItem === void 0 ? void 0 : lastFullIntervalItem.completedTaskCounts.recommendationAskCumulative) || 0);
        row.push((_info$reportIntervars = info.reportIntervarsity) === null || _info$reportIntervars === void 0 ? void 0 : _info$reportIntervars.totalRecommendations.recommendationsCollected);
        row.push((lastFullIntervalItem === null || lastFullIntervalItem === void 0 ? void 0 : lastFullIntervalItem.completedTaskCounts.followupCumulative) || 0);
        row.push((lastFullIntervalItem === null || lastFullIntervalItem === void 0 ? void 0 : lastFullIntervalItem.decisionCumulativeCount) || 0);
        lastSixFullIntervalItems.forEach(x => {
          var _x$completedTaskCount;

          row.push(x === null || x === void 0 ? void 0 : (_x$completedTaskCount = x.completedTaskCounts) === null || _x$completedTaskCount === void 0 ? void 0 : _x$completedTaskCount.engagement);
        });
        lastSixFullIntervalItems.forEach(x => {
          var _x$completedTaskCount2;

          row.push(x === null || x === void 0 ? void 0 : (_x$completedTaskCount2 = x.completedTaskCounts) === null || _x$completedTaskCount2 === void 0 ? void 0 : _x$completedTaskCount2.ask);
        });
        row.push((_info$reportIntervars2 = info.reportIntervarsity) === null || _info$reportIntervars2 === void 0 ? void 0 : _info$reportIntervars2.decisionTypeAmounts.recurring);
        row.push((_info$journalContactI = info.journalContactInfos) === null || _info$journalContactI === void 0 ? void 0 : _info$journalContactI.filter(x => x.journalDecisions.some(y => y.yesNo)).length);
        row.push((_info$journalContactI2 = info.journalContactInfos) === null || _info$journalContactI2 === void 0 ? void 0 : _info$journalContactI2.length);
        row.push((_info$journalContactI3 = info.journalContactInfos) === null || _info$journalContactI3 === void 0 ? void 0 : _info$journalContactI3.filter(x => x.journalDecisions.length > 0 || x.tasks.length > 0).length);
        rows.push(row);
      });
      return rows;
    }

    getLastFullIntervalIndex(info) {
      let lastFullIntervalIndex = info.reportIntervarsity.intervalData.length - 2;

      if (info.journalInfo.journal.closedDate != null) {
        lastFullIntervalIndex += 1;
      }

      return lastFullIntervalIndex;
    }

    getLastSixIntervalDateInfos(journalDigestInfos) {
      let dates = {};
      journalDigestInfos.forEach(info => {
        let lastFullInterval = info.reportIntervarsity.intervalData[this.getLastFullIntervalIndex(info)];
        info.reportIntervarsity.intervalData.forEach(interval => {
          if (lastFullInterval && interval.date <= lastFullInterval.date) {
            if (dates[interval.date] == null) {
              dates[interval.date] = {
                date: interval.date,
                dateFormatted: interval.dateFormatted
              };
            }
          }
        });
      });
      let allDates = Object.keys(dates).sort();
      return allDates.slice(Math.max(allDates.length - 6, 0)).map(x => dates[x]);
    }

  }

  _exports.JournalGroupDownloadInterVarsity = JournalGroupDownloadInterVarsity;
});