define("donor-elf-web/models/fund-summary", ["exports", "@ember-data/model", "@ember/utils"], function (_exports, _model, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let {
    currency
  } = window;
  let FundSummaryModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('number'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)('number'), _dec6 = (0, _model.attr)(), _dec7 = (0, _model.attr)('number'), _dec8 = (0, _model.attr)('number'), _dec9 = (0, _model.attr)('number'), _dec10 = (0, _model.attr)('number'), _dec11 = (0, _model.attr)('number'), _dec12 = (0, _model.belongsTo)('fund-summary-rolling-goal', {
    async: false
  }), _dec13 = (0, _model.belongsTo)('fund-summary-ytd-goal', {
    async: false
  }), (_class = class FundSummaryModel extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fundId", _descriptor, this);

      _initializerDefineProperty(this, "fundName", _descriptor2, this);

      _initializerDefineProperty(this, "balance", _descriptor3, this);

      _initializerDefineProperty(this, "goalType", _descriptor4, this);

      _initializerDefineProperty(this, "monthlyGoal", _descriptor5, this);

      _initializerDefineProperty(this, "monthlyData", _descriptor6, this);

      _initializerDefineProperty(this, "lastYearAvgAmount", _descriptor7, this);

      _initializerDefineProperty(this, "pledgedNoGiftsAmount", _descriptor8, this);

      _initializerDefineProperty(this, "pledgedDelinquentAmount", _descriptor9, this);

      _initializerDefineProperty(this, "pledgedGivingAmount", _descriptor10, this);

      _initializerDefineProperty(this, "pledgedMonthlyAmount", _descriptor11, this);

      _initializerDefineProperty(this, "rollingGoal", _descriptor12, this);

      _initializerDefineProperty(this, "ytdGoal", _descriptor13, this);
    }

    get annualGoal() {
      return currency(this.monthlyGoal || 0).multiply(12).value;
    }

    get fundNameLowerCase() {
      return (this.fundName || '').toLowerCase();
    }

    get hasMonthlyGoal() {
      return (0, _utils.isPresent)(this.monthlyGoal);
    }

    lastYearAnnualAmount() {
      return currency(this.lastYearAvgAmount || 0).multiply(12).value;
    }

    get lastYearAvgPercentage() {
      if (!this.hasMonthlyGoal) {
        return 0;
      }

      let lastYearAvgAmount = this.lastYearAvgAmount || 0;
      return Math.round(lastYearAvgAmount * 100 / this.monthlyGoal);
    }

    get pledgedNoGiftsPercent() {
      if (!this.hasMonthlyGoal) {
        return 0;
      }

      return Math.round((this.pledgedNoGiftsAmount || 0) * 100 / this.monthlyGoal);
    }

    get pledgedDelinquentPercent() {
      if (!this.hasMonthlyGoal) {
        return 0;
      }

      return Math.round((this.pledgedDelinquentAmount || 0) * 100 / this.monthlyGoal);
    }

    get pledgedGivingPercent() {
      if (!this.hasMonthlyGoal) {
        return 0;
      }

      return Math.round((this.pledgedGivingAmount || 0) * 100 / this.monthlyGoal);
    }

    get pledgedAllAmount() {
      return (this.pledgedNoGiftsAmount || 0) + (this.pledgedDelinquentAmount || 0) + (this.pledgedGivingAmount || 0);
    }

    get pledgedAllPercent() {
      if (!this.hasMonthlyGoal) {
        return 0;
      }

      return Math.round((this.pledgedAllAmount || 0) * 100 / this.monthlyGoal);
    }

    get pledgedAnnualAmount() {
      return currency(this.pledgedMonthlyAmount || 0).multiply(12).value;
    }

    get pledgedMonthlyPercent() {
      if (!this.hasMonthlyGoal) {
        return 0;
      }

      return Math.round((this.pledgedMonthlyAmount || 0) * 100 / this.monthlyGoal);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fundId", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fundName", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "balance", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "goalType", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "monthlyGoal", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "monthlyData", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "lastYearAvgAmount", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "pledgedNoGiftsAmount", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "pledgedDelinquentAmount", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "pledgedGivingAmount", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "pledgedMonthlyAmount", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "rollingGoal", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "ytdGoal", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = FundSummaryModel;
});