define("donor-elf-web/routes/funds/fund/contacts/contact/dates/delete", ["exports", "donor-elf-web/routes/funds/fund/contacts/contact/dates/edit"], function (_exports, _edit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // just re-using item route.
  // it has a few more properties than needed, but that's ok
  var _default = _edit.default.extend({});

  _exports.default = _default;
});