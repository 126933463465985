define("donor-elf-web/templates/components/coaching-static-info-edit-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "jnl4bz9N",
    "block": "[[[10,0],[14,0,\"py-2 border-bottom\"],[12],[1,\"\\n\"],[41,[33,1],[[[1,\"    \"],[11,\"button\"],[24,0,\"btn btn-sm btn-outline-warning\"],[4,[38,2],[[30,0],\"toggleEditing\"],null],[12],[1,\"\\n      Done\\n    \"],[13],[1,\"\\n    \\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"d-flex\"],[12],[1,\"\\n      \"],[10,0],[12],[1,\"\\n        \"],[10,0],[14,0,\"font-weight-light text-muted\"],[12],[1,[33,3,[\"l\"]]],[1,\":\"],[13],[1,\"\\n        \"],[10,0],[12],[1,[33,3,[\"v\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"ml-auto\"],[12],[1,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-sm btn-outline-warning\"],[4,[38,2],[[30,0],\"toggleEditing\"],null],[12],[1,\"\\n          Edit\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[],false,[\"if\",\"editing\",\"action\",\"item\"]]",
    "moduleName": "donor-elf-web/templates/components/coaching-static-info-edit-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});