define("donor-elf-web/routes/funds/fund/groups/create", ["exports", "@ember/routing/route", "@ember/object", "@ember/service", "ember-changeset-validations", "ember-changeset", "ember-changeset-validations/validators"], function (_exports, _route, _object, _service, _emberChangesetValidations, _emberChangeset, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const createGroupValidation = {
    name: (0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    })
  };

  var _default = _route.default.extend({
    contactsCache: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),
    queryParams: {
      groupName: {
        refreshModel: true
      },
      contactIds: {
        refreshModel: true
      }
    },

    beforeModel() {
      (0, _object.set)(this, 'navbarSettings.title', 'Create Group');
    },

    model(params) {
      let changeset = new _emberChangeset.default({}, (0, _emberChangesetValidations.default)(createGroupValidation), createGroupValidation);
      let contactIds = params.contactIds.split('-');
      let contactsCache = (0, _object.get)(this, 'contactsCache');
      let contacts = contactIds.map(id => {
        return (0, _object.get)(contactsCache, `contactsById.${id}`);
      });
      return {
        groupName: params.groupName,
        contacts,
        changeset
      };
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.reset();
      }
    }

  });

  _exports.default = _default;
});