define("donor-elf-web/lib/pledge-utils", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.calculateEndDateMonthsForOneTimePledge = calculateEndDateMonthsForOneTimePledge;
  _exports.calculateEndDateForOneTimePledge = calculateEndDateForOneTimePledge;
  _exports.canEditPledge = canEditPledge;
  _exports.pledgeOneTimeFrequency = _exports.pledgeMonthlyFrequency = _exports.pledgeFrequencyLabels = void 0;
  const {
    moment
  } = window;
  let pledgeFrequencyLabels = {
    1: 'Monthly',
    2: 'Bi-Monthly',
    3: 'Quarterly',
    4: 'Semi-Annual',
    5: 'Annual',
    6: 'One Time',
    7: 'Weekly'
  };
  _exports.pledgeFrequencyLabels = pledgeFrequencyLabels;
  let pledgeMonthlyFrequency = 1;
  _exports.pledgeMonthlyFrequency = pledgeMonthlyFrequency;
  let pledgeOneTimeFrequency = 6;
  _exports.pledgeOneTimeFrequency = pledgeOneTimeFrequency;

  function calculateEndDateMonthsForOneTimePledge(args) {
    if ((0, _utils.isPresent)(args.endDate)) {
      let dateRangeMonthsApart = moment(args.endDate).diff(args.startDate, 'months', true);
      return Math.round(dateRangeMonthsApart);
    } else {
      return null;
    }
  }

  function calculateEndDateForOneTimePledge(args) {
    return args.hasYtdGoalType ? null : moment(args.startDate).add(Number(args.endDateMonths), 'month').subtract(1, 'day').toDate();
  }

  function canEditPledge(pledge, abilities) {
    if (abilities.hasAbility('edit_pledges')) {
      return true;
    }

    if ((pledge.isManual || pledge.isNew) && abilities.hasAbility('edit_manual_pledges')) {
      return true;
    }

    if (!pledge.isManual && abilities.hasAbility('edit_system_pledge_end_date')) {
      return true;
    }

    return false;
  }
});