define("donor-elf-web/routes/admin/fund-groups/fund-group/edit-funds", ["exports", "@ember/routing/route", "@ember/object", "rsvp", "@ember/service", "donor-elf-web/lib/model-utils"], function (_exports, _route, _object, _rsvp, _service, _modelUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    fundsService: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),
    store: (0, _service.inject)(),
    storeCache: (0, _service.inject)(),

    beforeModel() {
      (0, _object.set)(this, 'navbarSettings.title', 'Edit Funds');
      return (0, _object.get)(this, 'fundsService').confirmValidFundToken();
    },

    model() {
      let fundGroupModel = this.modelFor('admin.fund-groups.fund-group');
      let fundGroup = (0, _object.get)(fundGroupModel, 'fundGroup');
      return _rsvp.default.hash({
        fundGroupFunds: (0, _object.get)(this, 'store').query('admin/fund-group-fund', {
          filter: {
            fund_group_id: (0, _object.get)(fundGroup, 'id')
          }
        }),
        allFunds: (0, _object.get)(this, 'storeCache').findAll('admin/fund').then(funds => {
          return _modelUtils.default.filterPersisted(funds);
        }),
        fundsById: (0, _object.get)(this, 'storeCache').indexSingleBy('admin/fund', 'id')
      }).then(data => {
        let fgFundsByFundId = {};
        data.fundGroupFunds.forEach(fgFund => {
          fgFundsByFundId[(0, _object.get)(fgFund, 'fundId')] = fgFund;
        });
        return {
          fundGroup: fundGroup,
          fundItems: data.allFunds.map(fund => {
            let fgFund = fgFundsByFundId[(0, _object.get)(fund, 'id')];
            return {
              fund: fund,
              fundGroupFund: fgFund,
              isSelected: fgFund != null
            };
          })
        };
      });
    },

    setupController(controller) {
      this._super(...arguments);

      let params = this.paramsFor('admin.fund-groups.fund-group.edit-funds');
      (0, _object.setProperties)(controller, {
        q: params.q,
        qInput: params.q
      });
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.reset();
      }
    }

  });

  _exports.default = _default;
});