define("donor-elf-web/templates/components/navbar-search-contacts-contact", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "hSA9SJfe",
    "block": "[[[10,0],[14,0,\"font-weight-bold\"],[12],[1,[33,0,[\"name\"]]],[13],[1,\"\\n\"],[41,[33,0,[\"namePartsDifferentThanDisplayName\"]],[[[1,\"  \"],[10,0],[12],[1,\"\\n    \"],[1,[33,0,[\"firstName\"]]],[1,\"\\n\"],[41,[33,0,[\"spouseFirstName\"]],[[[1,\"      & \"],[1,[33,0,[\"spouseFirstName\"]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[1,[33,0,[\"lastName\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[41,[33,0,[\"cityAndState\"]],[[[1,\"  \"],[10,0],[14,0,\"contact-city-state\"],[12],[1,[33,0,[\"cityAndState\"]]],[13],[1,\"\\n\"]],[]],null]],[],false,[\"contact\",\"if\"]]",
    "moduleName": "donor-elf-web/templates/components/navbar-search-contacts-contact.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});