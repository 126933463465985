define("donor-elf-web/controllers/funds/fund/transactions/delete", ["exports", "@ember/controller", "@ember/object", "@ember/service", "ember-concurrency"], function (_exports, _controller, _object, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    fundsService: (0, _service.inject)(),
    flashMessages: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),
    lineDeleted: false,

    reset() {
      (0, _object.set)(this, 'lineDeleted', false);
    },

    delete: (0, _emberConcurrency.task)(function* () {
      yield (0, _object.get)(this, 'fundsService').confirmValidFundToken();
      let line = (0, _object.get)(this, 'model.line');
      yield line.destroyRecord();
      (0, _object.set)(this, 'lineDeleted', true);
      (0, _object.get)(this, 'flashMessages').success('The transaction was deleted');
      this.transitionToRoute('funds.fund.transactions');
    }).drop()
  });

  _exports.default = _default;
});