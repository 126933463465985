define("donor-elf-web/models/contact-detail", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ContactDetailModel = (_dec = (0, _model.attr)(), _dec2 = (0, _model.hasMany)('line-item', {
    async: false
  }), _dec3 = (0, _model.hasMany)('note', {
    async: false
  }), _dec4 = (0, _model.hasMany)('event', {
    async: false
  }), _dec5 = (0, _model.hasMany)('contact-date', {
    async: false
  }), _dec6 = (0, _model.hasMany)('pledge', {
    async: false
  }), _dec7 = (0, _model.hasMany)('task', {
    async: false
  }), _dec8 = (0, _model.hasMany)('communication', {
    async: false
  }), _dec9 = (0, _model.hasMany)('v2-task', {
    async: false
  }), _dec10 = (0, _model.hasMany)('review-item', {
    async: false
  }), _dec11 = (0, _model.belongsTo)('newsletter-contact', {
    async: false
  }), _dec12 = (0, _model.hasMany)('mailchimp-email-sync', {
    async: false
  }), _dec13 = (0, _model.hasMany)('contact-campaign-summary', {
    async: false
  }), _dec14 = (0, _model.hasMany)('campaign-pledge', {
    async: false
  }), (_class = class ContactDetailModel extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "lastGiftToOtherFunds", _descriptor, this);

      _initializerDefineProperty(this, "lines", _descriptor2, this);

      _initializerDefineProperty(this, "notes", _descriptor3, this);

      _initializerDefineProperty(this, "events", _descriptor4, this);

      _initializerDefineProperty(this, "dates", _descriptor5, this);

      _initializerDefineProperty(this, "pledges", _descriptor6, this);

      _initializerDefineProperty(this, "tasks", _descriptor7, this);

      _initializerDefineProperty(this, "communications", _descriptor8, this);

      _initializerDefineProperty(this, "v2Tasks", _descriptor9, this);

      _initializerDefineProperty(this, "reviewItems", _descriptor10, this);

      _initializerDefineProperty(this, "newsletterContact", _descriptor11, this);

      _initializerDefineProperty(this, "mailchimpEmailSyncs", _descriptor12, this);

      _initializerDefineProperty(this, "campaignSummaries", _descriptor13, this);

      _initializerDefineProperty(this, "campaignPledges", _descriptor14, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "lastGiftToOtherFunds", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "lines", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notes", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "events", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "dates", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "pledges", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "tasks", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "communications", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "v2Tasks", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "reviewItems", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "newsletterContact", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "mailchimpEmailSyncs", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "campaignSummaries", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "campaignPledges", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ContactDetailModel;
});