define("donor-elf-web/components/contact/bulk-actions/header-third", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='tw-font-semibold tw-text-lg tw-leading-tight mb-1' ...attributes>
    {{yield}}
  </div>
  
  */
  {
    "id": "k9+f3TbL",
    "block": "[[[11,0],[24,0,\"tw-font-semibold tw-text-lg tw-leading-tight mb-1\"],[17,1],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "donor-elf-web/components/contact/bulk-actions/header-third.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});