define("donor-elf-web/templates/components/de-form-card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "YFAzE7FN",
    "block": "[[[10,0],[15,0,[29,[[36,0]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"card de-card de-card-no-border-sm-down\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"card-body\"],[12],[1,\"\\n      \"],[18,1,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&default\"],false,[\"default-form-columns\",\"yield\"]]",
    "moduleName": "donor-elf-web/templates/components/de-form-card.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});