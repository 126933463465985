define("donor-elf-web/templates/funds/fund/more/mailchimp/connected", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "rgUPGSA/",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[8,[39,1],[[24,0,\"mb-5\"]],null,null],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Great! DonorElf can now communicate with your MailChimp account.\\n  \"],[13],[1,\"\\n  \"],[10,2],[14,0,\"mb-5\"],[12],[1,\"\\n    The next step is to choose the\\n\"],[41,[33,3],[[[1,\"      DonorElf fund and\\n\"]],[]],null],[1,\"    MailChimp list to sync.\\n  \"],[13],[1,\"\\n\"],[41,[33,3],[[[1,\"    \"],[10,2],[12],[1,\"Which DonorElf fund would you like to sync to your MailChimp account?\"],[13],[1,\"\\n    \"],[1,[28,[35,4],null,[[\"changeset\",\"property\",\"selectOptions\"],[[33,5,[\"changeset\"]],\"fundId\",[33,6]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,2],[12],[1,\"Which MailChimp List would you like to sync to DonorElf?\"],[13],[1,\"\\n  \"],[1,[28,[35,4],null,[[\"changeset\",\"property\",\"selectOptions\"],[[33,5,[\"changeset\"]],\"mailchimpListId\",[33,7]]]]],[1,\"\\n  \"],[10,0],[14,0,\"mt-4\"],[12],[1,\"\\n    \"],[10,\"button\"],[15,\"onclick\",[28,[37,8],[[33,9]],null]],[14,0,\"btn btn-warning\"],[15,\"disabled\",[29,[[52,[33,9,[\"isRunning\"]],\"disable\"]]]],[12],[1,\"\\n      Save\\n    \"],[13],[1,\"\\n\"],[41,[33,9,[\"isRunning\"]],[[[1,\"      \"],[10,0],[14,0,\"alert alert-info mt-3\"],[12],[1,\"\\n        Saving...\\n      \"],[13],[1,\"\\n\"]],[]],null],[41,[33,10],[[[1,\"      \"],[10,0],[14,0,\"alert alert-danger mt-3\"],[12],[1,\"\\n        \"],[1,[34,10]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"de-form-card\",\"mailchimp-header\",\"if\",\"hasMultipleFunds\",\"form-group-select\",\"model\",\"fundsOptions\",\"mailchimpListOptions\",\"perform\",\"save\",\"errorMessage\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/more/mailchimp/connected.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});