define("donor-elf-web/controllers/funds/fund/custom-reports/pbt-disbursement-availability", ["exports", "@ember/controller", "ember-parachute", "ember-changeset", "@ember/service", "@ember/object", "@ember/utils", "@ember/object/computed", "donor-elf-web/lib/object-utils"], function (_exports, _controller, _emberParachute, _emberChangeset, _service, _object, _utils, _computed, _objectUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.queryParams = void 0;
  const {
    moment
  } = window;
  const queryParams = new _emberParachute.default({
    month: {
      defaultValue: '',
      refresh: true
    },
    year: {
      defaultValue: '',
      refresh: true
    }
  });
  _exports.queryParams = queryParams;

  var _default = _controller.default.extend(queryParams.Mixin, {
    fundsService: (0, _service.inject)(),
    store: (0, _service.inject)(),
    report: null,
    month: '',
    year: '',
    loading: false,
    filterExpanded: false,
    filterChangeset: null,
    toDateMoment: (0, _object.computed)('report.id', function () {
      return moment({
        year: this.report.toYear,
        month: this.report.toMonth - 1,
        day: 1
      }).endOf('month');
    }),
    transactionsQueryParamsTo: (0, _object.computed)('toDateMoment', function () {
      return this.toDateMoment.format('YYYY-MM-DD');
    }),
    queryParamsChanged: (0, _computed.or)('queryParamsState.{month,year}.changed'),

    setup({
      queryParams
    }) {
      this.fetchData(queryParams);
    },

    queryParamsDidChange({
      shouldRefresh,
      queryParams
    }) {
      // if any query param with `refresh: true` is changed, `shouldRefresh` is `true`
      if (shouldRefresh) {
        this.fetchData(queryParams);
      }
    },

    reset(event, isExiting) {
      if (isExiting) {
        this.resetQueryParams();
        (0, _object.setProperties)(this, {
          filterChangeset: null,
          filterExpanded: false
        });
      }
    },

    fetchData(queryParams) {
      (0, _object.setProperties)(this, {
        loading: true
      });
      let filter = {};

      if ((0, _utils.isEmpty)(queryParams.month)) {
        queryParams.month = (new Date().getMonth() + 1).toString();
      }

      if ((0, _utils.isEmpty)(queryParams.year)) {
        queryParams.year = new Date().getFullYear().toString();
      }

      filter.month = queryParams.month;
      filter.year = queryParams.year;
      filter.fundId = this.fundsService.selectedFund.id;
      let filterChangeset = new _emberChangeset.default(filter);
      (0, _object.set)(this, 'filterChangeset', filterChangeset);
      return this.fundsService.confirmValidFundToken().then(() => {
        return this.store.query('custom-reports/disbursement-availability-report', {
          filter: _objectUtils.default.decamelizeKeys(filter)
        }).then(records => {
          (0, _object.setProperties)(this, {
            report: records.firstObject,
            loading: false
          });
        });
      }, error => {
        (0, _object.set)(this, 'error', error);
      });
    },

    monthOptions: [{
      label: 'January',
      val: '1'
    }, {
      label: 'February',
      val: '2'
    }, {
      label: 'March',
      val: '3'
    }, {
      label: 'April',
      val: '4'
    }, {
      label: 'May',
      val: '5'
    }, {
      label: 'June',
      val: '6'
    }, {
      label: 'July',
      val: '7'
    }, {
      label: 'August',
      val: '8'
    }, {
      label: 'September',
      val: '9'
    }, {
      label: 'October',
      val: '10'
    }, {
      label: 'November',
      val: '11'
    }, {
      label: 'December',
      val: '12'
    }],
    yearOptions: (0, _object.computed)(function () {
      let options = [];
      let thisYear = new Date().getFullYear();

      for (let year = thisYear - 4; year <= thisYear; year++) {
        options.push({
          label: year.toString(),
          val: year.toString()
        });
      }

      return options;
    }),
    actionsMenuItems: (0, _object.computed)('filterExpanded', function () {
      return [{
        label: (0, _object.get)(this, 'filterExpanded') ? 'Collapse Filter' : 'Adjust Filter',
        svgJar: 'filter',
        onClick: () => {
          this.send('toggleExpanded');
        }
      }];
    }),
    filterMessage: (0, _object.computed)('toDateMoment', function () {
      return `${this.toDateMoment.format('MMMM YYYY')}`;
    }),
    actions: {
      applyFilter() {
        let filterChangeset = (0, _object.get)(this, 'filterChangeset');

        if ((0, _object.get)(filterChangeset, 'isPristine')) {
          alert('No filter values have changed');
          return;
        }

        (0, _object.setProperties)(this, {
          month: (0, _object.get)(filterChangeset, 'month'),
          year: (0, _object.get)(filterChangeset, 'year'),
          filterExpanded: false
        });
      },

      toggleExpanded() {
        this.toggleProperty('filterExpanded');
      }

    }
  });

  _exports.default = _default;
});