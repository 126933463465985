define("donor-elf-web/controllers/funds/index", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "@ember/utils", "@ember/service", "donor-elf-web/config/environment", "donor-elf-web/lib/array-utils", "fuzzysort"], function (_exports, _controller, _object, _tracking, _utils, _service, _environment, _arrayUtils, _fuzzysort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _class3, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FundItem = (_class = class FundItem {
    constructor(id, name, starred) {
      _defineProperty(this, "namePrepared", void 0);

      _initializerDefineProperty(this, "starred", _descriptor, this);

      this.id = id;
      this.name = name;
      this.namePrepared = _fuzzysort.default.prepare(name);
      this.starred = starred || false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "starred", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  let FundsIndexController = (_class3 = class FundsIndexController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "currentUser", _descriptor2, this);

      _initializerDefineProperty(this, "fundsService", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "loading", _descriptor5, this);

      _defineProperty(this, "starredUserFunds", []);

      _defineProperty(this, "allFundsInfo", void 0);

      _initializerDefineProperty(this, "fundItems", _descriptor6, this);

      _defineProperty(this, "queryParams", ['q', 'page']);

      _initializerDefineProperty(this, "q", _descriptor7, this);

      _initializerDefineProperty(this, "page", _descriptor8, this);

      _defineProperty(this, "perPage", 50);

      _initializerDefineProperty(this, "selectedIndex", _descriptor9, this);
    }

    setAndSortTheFundItems() {
      let starredByFundIds = {};
      this.starredUserFunds.forEach(userFund => {
        starredByFundIds[userFund.fundId] = userFund.starred;
      });
      let fundItems = this.allFundsInfo.funds.map(fund => {
        return new FundItem(fund.id, fund.name, starredByFundIds[fund.id]);
      });
      this.fundItems = (0, _arrayUtils.sortByProperties)(fundItems, ['starred:desc', 'name:asc']);
    }

    get filteredFundItems() {
      if ((0, _utils.isEmpty)(this.q) || this.q.length < 2) {
        return this.fundItems;
      }

      return _fuzzysort.default.go(this.q, this.fundItems, {
        keys: ['namePrepared']
      }).map(result => result.obj);
    }

    get pagedFundItems() {
      let offset = (this.page - 1) * this.perPage;
      return this.filteredFundItems.slice(offset, offset + this.perPage);
    }

    get totalPages() {
      return Math.ceil(this.filteredFundItems.length / this.perPage);
    }

    get rootURL() {
      //@ts-expect-error
      return _environment.default.routerRootURL;
    }

    async loadStarredUserFunds() {
      this.starredUserFunds = (await this.store.query('starred-user-fund', {})).toArray();
    }

    async loadFunds() {
      this.allFundsInfo = await this.fundsService.getAllFundsInfo();
      this.allFundsInfo.onRecordsFromServer(_args => {
        this.setAndSortTheFundItems();
      });
    }

    async templateDidInsert() {
      this.loading = true;
      await this.loadStarredUserFunds();
      await this.loadFunds();
      this.setAndSortTheFundItems();
      this.loading = false;
    }

    templateWillDestroy() {
      this.loading = true;
      this.q = '';
      this.page = 1;
      this.selectedIndex = 0;
    }

    handleSearchKeyDown(e) {
      if (e.key === 'ArrowDown') {
        e.preventDefault();

        if (this.selectedIndex + 1 < this.filteredFundItems.length) {
          this.incrementProperty('selectedIndex');
        }
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();

        if (this.selectedIndex > 0) {
          this.decrementProperty('selectedIndex');
        }
      } else if (e.key === 'Escape') {
        this.q = '';
      }
    }

    handleSearchInput(e) {
      this.q = e.target.value;
      this.page = 1;
      this.selectedIndex = 0;
    }

    handleSearchEnter(e) {
      if (e.target.value !== (this.q || '')) {
        this.handleSearchInput(e);
      }

      let selectedFund = this.filteredFundItems[this.selectedIndex];

      if (selectedFund) {
        this.fundsService.viewFund(selectedFund.id);
      }
    }

    toggleFundStarred(fundItem) {
      let starredUserFund = this.starredUserFunds.find(x => x.fundId === fundItem.id);

      if (starredUserFund) {
        starredUserFund.starred = !starredUserFund.starred;
        starredUserFund.save();
      } else {
        let newStarredUserFund = this.store.createRecord('starred-user-fund', {
          fundId: fundItem.id,
          starred: !fundItem.starred
        });
        newStarredUserFund.save();
      }

      fundItem.starred = !fundItem.starred;
    }

    viewFund(fundId, event) {
      if (event.metaKey || event.ctrlKey) {// let the browser open a new tab if the cmd or ctrl key is pressed
      } else {
        event.stopPropagation();
        this.fundsService.viewFund(fundId);
      }
    }

    changePage(pageNumber) {
      this.page = pageNumber;
      this.selectedIndex = 0;
    }

  }, (_descriptor2 = _applyDecoratedDescriptor(_class3.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class3.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class3.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class3.prototype, "fundItems", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class3.prototype, "q", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class3.prototype, "page", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class3.prototype, "selectedIndex", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class3.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "templateDidInsert"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "templateWillDestroy"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "handleSearchKeyDown", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "handleSearchKeyDown"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "handleSearchInput", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "handleSearchInput"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "handleSearchEnter", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "handleSearchEnter"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "toggleFundStarred", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "toggleFundStarred"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "viewFund", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "viewFund"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "changePage", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "changePage"), _class3.prototype)), _class3);
  _exports.default = FundsIndexController;
});