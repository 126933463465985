define("donor-elf-web/templates/funds/fund/contacts/contact/edit-note/delete", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "8A6x0yXx",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,\"h2\"],[14,0,\"mb-3\"],[12],[1,\"Delete Note\"],[13],[1,\"\\n  \"],[10,0],[14,0,\"card p-2 mb-4\"],[12],[1,\"\\n    \"],[1,[33,1,[\"record\",\"text\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"Are you sure you want to delete this note?\"],[13],[1,\"\\n  \"],[1,[28,[35,2],null,[[\"deleteTask\",\"cancelRoute\"],[[33,3],\"funds.fund.contacts.contact.edit-note\"]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"de-form-card\",\"model\",\"delete-buttons\",\"delete\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/contacts/contact/edit-note/delete.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});