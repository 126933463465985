define("donor-elf-web/components/contact/details/properties/custom-property/edit-options-modal/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/service", "donor-elf-web/services/contacts-cache", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _service, _contactsCache, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Modal>
    {{#if (eq this.stage 'showOptions')}}
      <Contact::Details::Properties::CustomProperty::EditOptionsModal::ShowOptions
        @propertyInfo={{@propertyInfo}}
        @onReorderOptions={{this.handleReorderOptions}}
        @onRenameOption={{this.handleRenameOption}}
        @onMergeOption={{this.handleMergeOption}}
        @onDeleteOption={{this.handleDeleteOption}}
        @onClose={{@onClose}}
      />
    {{else if (eq this.stage 'reorder')}}
      <Contact::Details::Properties::CustomProperty::EditOptionsModal::Reorder
        @propertyInfo={{@propertyInfo}}
        @onGoBack={{this.showOptions}}
        @onClose={{@onClose}}
      />
    {{else if (eq this.stage 'rename')}}
      <Contact::Details::Properties::CustomProperty::EditOptionsModal::Rename
        @propertyInfo={{@propertyInfo}}
        @optionAndCount={{this.selectedOptionAndCount}}
        @onGoBack={{this.showOptions}}
        @onClose={{@onClose}}
      />
    {{else if (eq this.stage 'merge')}}
      <Contact::Details::Properties::CustomProperty::EditOptionsModal::Merge
        @propertyInfo={{@propertyInfo}}
        @optionAndCount={{this.selectedOptionAndCount}}
        @onGoBack={{this.showOptions}}
        @onClose={{@onClose}}
      />
    {{else if (eq this.stage 'delete')}}
      <Contact::Details::Properties::CustomProperty::EditOptionsModal::Delete
        @propertyInfo={{@propertyInfo}}
        @optionAndCount={{this.selectedOptionAndCount}}
        @onGoBack={{this.showOptions}}
        @onClose={{@onClose}}
      />
    {{/if}}
  </Modal>
  */
  {
    "id": "7rx5tF8M",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[28,[37,2],[[30,0,[\"stage\"]],\"showOptions\"],null],[[[1,\"    \"],[8,[39,3],null,[[\"@propertyInfo\",\"@onReorderOptions\",\"@onRenameOption\",\"@onMergeOption\",\"@onDeleteOption\",\"@onClose\"],[[30,1],[30,0,[\"handleReorderOptions\"]],[30,0,[\"handleRenameOption\"]],[30,0,[\"handleMergeOption\"]],[30,0,[\"handleDeleteOption\"]],[30,2]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,2],[[30,0,[\"stage\"]],\"reorder\"],null],[[[1,\"    \"],[8,[39,4],null,[[\"@propertyInfo\",\"@onGoBack\",\"@onClose\"],[[30,1],[30,0,[\"showOptions\"]],[30,2]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,2],[[30,0,[\"stage\"]],\"rename\"],null],[[[1,\"    \"],[8,[39,5],null,[[\"@propertyInfo\",\"@optionAndCount\",\"@onGoBack\",\"@onClose\"],[[30,1],[30,0,[\"selectedOptionAndCount\"]],[30,0,[\"showOptions\"]],[30,2]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,2],[[30,0,[\"stage\"]],\"merge\"],null],[[[1,\"    \"],[8,[39,6],null,[[\"@propertyInfo\",\"@optionAndCount\",\"@onGoBack\",\"@onClose\"],[[30,1],[30,0,[\"selectedOptionAndCount\"]],[30,0,[\"showOptions\"]],[30,2]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,2],[[30,0,[\"stage\"]],\"delete\"],null],[[[1,\"    \"],[8,[39,7],null,[[\"@propertyInfo\",\"@optionAndCount\",\"@onGoBack\",\"@onClose\"],[[30,1],[30,0,[\"selectedOptionAndCount\"]],[30,0,[\"showOptions\"]],[30,2]]],null],[1,\"\\n  \"]],[]],null]],[]]]],[]]]],[]]]],[]]]],[]]]]]],[\"@propertyInfo\",\"@onClose\"],false,[\"modal\",\"if\",\"eq\",\"contact/details/properties/custom-property/edit-options-modal/show-options\",\"contact/details/properties/custom-property/edit-options-modal/reorder\",\"contact/details/properties/custom-property/edit-options-modal/rename\",\"contact/details/properties/custom-property/edit-options-modal/merge\",\"contact/details/properties/custom-property/edit-options-modal/delete\"]]",
    "moduleName": "donor-elf-web/components/contact/details/properties/custom-property/edit-options-modal/index.hbs",
    "isStrictMode": false
  });

  let ContactDetailsPropertiesCustomPropertyEditOptionsModalIndexComponent = (_class = class ContactDetailsPropertiesCustomPropertyEditOptionsModalIndexComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "contactsCache", _descriptor, this);

      _initializerDefineProperty(this, "stage", _descriptor2, this);

      _initializerDefineProperty(this, "selectedOptionAndCount", _descriptor3, this);
    }

    get isGroupsProperty() {
      return (0, _contactsCache.isGroupsContactCustomPropertyInfo)(this.args.propertyInfo);
    }

    showOptions() {
      this.stage = 'showOptions';
    }

    handleReorderOptions() {
      this.stage = 'reorder';
    }

    handleRenameOption(optionAndCount) {
      this.selectedOptionAndCount = optionAndCount;
      this.stage = 'rename';
    }

    handleMergeOption(optionAndCount) {
      this.selectedOptionAndCount = optionAndCount;
      this.stage = 'merge';
    }

    handleDeleteOption(optionAndCount) {
      this.selectedOptionAndCount = optionAndCount;
      this.stage = 'delete';
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "contactsCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "stage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'showOptions';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selectedOptionAndCount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showOptions", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "showOptions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleReorderOptions", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleReorderOptions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleRenameOption", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleRenameOption"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleMergeOption", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleMergeOption"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDeleteOption", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleDeleteOption"), _class.prototype)), _class);
  _exports.default = ContactDetailsPropertiesCustomPropertyEditOptionsModalIndexComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ContactDetailsPropertiesCustomPropertyEditOptionsModalIndexComponent);
});