define("donor-elf-web/services/journal-service", ["exports", "@ember/service", "@glimmer/tracking", "donor-elf-web/lib/store-utils", "donor-elf-web/lib/models/journal-info", "donor-elf-web/lib/models/journal-contact-info"], function (_exports, _service, _tracking, _storeUtils, _journalInfo, _journalContactInfo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PAGE_SIZE = 50;
  let JournalService = (_class = class JournalService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "contactsCache", _descriptor, this);

      _initializerDefineProperty(this, "currentUser", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "_cache", _descriptor4, this);

      _defineProperty(this, "_loadPromise", void 0);

      _initializerDefineProperty(this, "journals", _descriptor5, this);

      _initializerDefineProperty(this, "activeJournals", _descriptor6, this);

      _initializerDefineProperty(this, "hasMultipleJournals", _descriptor7, this);
    }

    journalInfoById(journalId) {
      return this._cache.journalInfoById[journalId];
    }

    get sortedJournalInfos() {
      let sortedJournalInfos = Object.values(this._cache.journalInfoById).sort((a, b) => {
        return a.journal.startDate < b.journal.startDate ? 1 : -1;
      }); // active journals first

      return [...sortedJournalInfos.filter(x => x.journal.active), ...sortedJournalInfos.filter(x => !x.journal.active)];
    } // use getter property so the tracked properties will correctly break the cache


    get journalInfosByContactId() {
      return this._cache.journalInfosByContactId;
    }

    activeJournalInfosForContactId(contactId) {
      let journals = this._cache.activeJournalInfosForContact[contactId];

      if (journals) {
        return journals;
      }

      journals = (this.journalInfosByContactId[contactId] || []).filter(x => x.journal.active);
      this._cache.activeJournalInfosForContact[contactId] = journals;
      return journals;
    }

    stageInfoById(stageId) {
      return this._cache.stageInfoById[stageId];
    } // Only loads journals for current fund.


    load({
      forceReload
    } = {}) {
      if (forceReload) {
        this._loadPromise = undefined;
      }

      if (this._loadPromise) {
        return this._loadPromise;
      }

      this._cache = {
        journalInfoById: {},
        journalInfosByContactId: {},
        activeJournalInfosForContact: {},
        stageInfoById: {}
      };
      return this._loadPromise = this.store.query('journal', {}).then(records => {
        this.journals = records.toArray();
        this._cache.journalInfoById = {};
        this._cache.journalInfosByContactId = {};
        this._cache.stageInfoById = {};
        this._cache.activeJournalInfosForContact = {};
        this.journals.forEach(journal => {
          this.createJournalInfo(journal);
        });
        this._cache = this._cache; // break the cache

        this.activeJournals = this.journals.filter(x => x.active);
        this.hasMultipleJournals = this.journals.length > 1;
      });
    }

    createJournalInfo(journal) {
      let journalInfo = new _journalInfo.default(journal);
      this._cache.journalInfoById[journal.id] = journalInfo;
      journalInfo.sortedStages.forEach(stage => {
        this._cache.stageInfoById[stage.id] = {
          stage,
          journalInfo
        };
      });
      journal.contactIds.forEach(contactId => {
        let contactIdString = contactId.toString();

        if (this._cache.journalInfosByContactId[contactIdString] == null) {
          this._cache.journalInfosByContactId[contactIdString] = [];
        }

        this._cache.journalInfosByContactId[contactIdString].push(journalInfo);
      });
      return journalInfo;
    }

    async loadJournalContactInfos(journalId, {
      forceReload,
      pageCallback
    } = {}) {
      var _this$_cache$journalC2;

      if (forceReload) {
        var _this$_cache$journalC;

        (_this$_cache$journalC = this._cache.journalContactInfosByJournalId) === null || _this$_cache$journalC === void 0 ? true : delete _this$_cache$journalC[journalId];
      }

      if (((_this$_cache$journalC2 = this._cache.journalContactInfosByJournalId) === null || _this$_cache$journalC2 === void 0 ? void 0 : _this$_cache$journalC2[journalId]) != null) {
        return this._cache.journalContactInfosByJournalId[journalId];
      }

      if (this._cache.journalContactInfosByJournalId == null) {
        this._cache.journalContactInfosByJournalId = {};
      }

      let records = [];
      await (0, _storeUtils.queryWithPages)({
        store: this.store,
        modelName: 'all-funds/journal-contact-detail',
        queryOptions: {
          filter: {
            journal_id: journalId
          }
        },
        pageSize: PAGE_SIZE,
        pageCallback: args => {
          records.push(...args.pageRecords.map(x => this.createJournalContactInfo(x)));
          pageCallback === null || pageCallback === void 0 ? void 0 : pageCallback(args);
        }
      });
      return this._cache.journalContactInfosByJournalId[journalId] = records;
    }

    createJournalContactInfo(journalContactDetailModel) {
      let journalInfo = this.journalInfoById(journalContactDetailModel.journalContact.journalId);
      let contact = this.contactsCache.contactsById[journalContactDetailModel.journalContact.contactId]; // todo: this needs removed but we have to add it when retrieving journals from other funds

      if (contact == null) {
        contact = {
          id: journalContactDetailModel.journalContact.contactId
        };
      } // ***


      return new _journalContactInfo.default({
        journalContactDetailModel,
        journalInfo,
        contact
      });
    }

    removeJournalInfoForContactId(contactId) {
      delete this._cache.journalInfosByContactId[contactId];
    }

    getDistinctJournalSegments(journalContactInfos) {
      let segments = {};
      journalContactInfos.forEach(x => {
        x.journalContact.segments.forEach(segment => segments[segment] = true);
      });
      return Object.keys(segments).sort();
    }

    async addContactToJournal(journalId, contact) {
      var _this$journalInfosByC;

      let record = this.store.createRecord('journal-add-contact', {
        journalId: journalId,
        contactIds: [contact.id]
      });
      await record.save();
      let results = (await this.store.query('all-funds/journal-contact-detail', {
        filter: {
          contact_id: contact.id,
          journal_id: journalId
        }
      })).toArray();
      let journalContactInfo = this.createJournalContactInfo(results[0]);

      if (this._cache.journalContactInfosByJournalId != null) {
        this._cache.journalContactInfosByJournalId[journalId] = [...this._cache.journalContactInfosByJournalId[journalId], journalContactInfo];
      }

      let journalInfo = this.journalInfoById(journalId);

      if (((_this$journalInfosByC = this.journalInfosByContactId[contact.id]) === null || _this$journalInfosByC === void 0 ? void 0 : _this$journalInfosByC.includes(journalInfo)) !== true) {
        journalInfo.journal.contactIds = [...journalInfo.journal.contactIds, contact.id];

        if (this._cache.journalInfosByContactId[contact.id] == null) {
          this._cache.journalInfosByContactId[contact.id] = [];
        }

        this._cache.journalInfosByContactId[contact.id].push(journalInfo);
      }

      this._cache = this._cache; // break the cache

      return journalContactInfo;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "contactsCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "_cache", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        journalInfoById: {},
        journalInfosByContactId: {},
        activeJournalInfosForContact: {},
        stageInfoById: {}
      };
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "journals", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "activeJournals", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "hasMultipleJournals", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class);
  _exports.default = JournalService;
});