define("donor-elf-web/components/header-bar", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    attrs
      - links = [{
        route:
        models:
        label:
      }]
  */
  class _default extends _component.default {}

  _exports.default = _default;
  ;
});