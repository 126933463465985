define("donor-elf-web/templates/logout", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "dEl4mXeM",
    "block": "[[[8,[39,0],[[4,[38,1],[[30,0,[\"templateDidInsert\"]]],null]],null,[[\"default\"],[[[[1,\"\\n  \"],[10,2],[12],[1,\"\\n    You have been logged out of DonorElf.\\n  \"],[13],[1,\"\\n  \"],[11,3],[24,6,\"#\"],[24,0,\"btn btn-warning\"],[4,[38,2],[\"click\",[30,0,[\"refreshToLoginPage\"]]],null],[12],[1,\"\\n    Login Again\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"form-card\",\"did-insert\",\"on\"]]",
    "moduleName": "donor-elf-web/templates/logout.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});