define("donor-elf-web/routes/funds/fund/goal/rolling", ["exports", "@ember/routing/route", "@ember/object", "@ember/object/computed", "@ember/service", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators"], function (_exports, _route, _object, _computed, _service, _emberChangeset, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const monthlyGoalValidation = {
    monthlyGoal: [(0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    }), (0, _validators.validateNumber)({
      message: 'Must be a number '
    })]
  };
  const annualGoalValidation = {
    annualGoal: [(0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    }), (0, _validators.validateNumber)({
      message: 'Must be a number '
    })]
  };

  var _default = _route.default.extend({
    fundsService: (0, _service.inject)(),
    abilities: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),
    settings: (0, _service.inject)(),
    store: (0, _service.inject)(),
    isAnnualRollingGoal: (0, _computed.alias)('settings.userSettings.showAnnualGoal'),

    beforeModel(transition) {
      if ((0, _object.get)(this, 'abilities').noAbility('edit_goal')) {
        transition.abort();
      }

      return (0, _object.get)(this, 'fundsService').confirmValidFundToken();
    },

    model() {
      return (0, _object.get)(this, 'store').findAll('rolling-fund-goal').then(records => {
        let record = (0, _object.get)(records, 'firstObject');

        if (record) {
          (0, _object.set)(this, 'navbarSettings.title', 'Edit Monthly Goal');
        } else {
          (0, _object.set)(this, 'navbarSettings.title', 'Add Monthly Goal');
          record = (0, _object.get)(this, 'store').createRecord('rolling-fund-goal');
        }

        let validation = (0, _object.get)(this, 'isAnnualRollingGoal') ? annualGoalValidation : monthlyGoalValidation;
        let changeset = new _emberChangeset.default(record, (0, _emberChangesetValidations.default)(validation), validation);

        if ((0, _object.get)(this, 'isAnnualRollingGoal')) {
          let monthlyGoal = (0, _object.get)(changeset, 'monthlyGoal') || 0;
          (0, _object.set)(changeset, 'annualGoal', window.currency(monthlyGoal).multiply(12).value);
        }

        return {
          record,
          changeset
        };
      });
    }

  });

  _exports.default = _default;
});