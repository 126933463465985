define("donor-elf-web/helpers/pledge-frequency-label", ["exports", "@ember/component/helper", "donor-elf-web/lib/pledge-utils"], function (_exports, _helper, _pledgeUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(function ([pledgeFrequency]) {
    return _pledgeUtils.pledgeFrequencyLabels[pledgeFrequency];
  });

  _exports.default = _default;
});