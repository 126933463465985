define("donor-elf-web/templates/components/event-info-ask-for-increase", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "0jBYZdmb",
    "block": "[[[1,\"They have been giving \"],[1,[28,[35,0],[[33,1,[\"custom\",\"giving_amount\"]]],null]],[1,\" for\\n\"],[1,[33,1,[\"custom\",\"giving_for_months\"]]],[1,\" months. Consider asking them to\\nincrease their donation amount.\"]],[],false,[\"format-amount\",\"eventModel\"]]",
    "moduleName": "donor-elf-web/templates/components/event-info-ask-for-increase.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});