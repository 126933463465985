define("donor-elf-web/templates/coaching/individuals/individual/edit-goal-date", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "hzPN0aJm",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,0],[14,0,\"mb-3 border-bottom\"],[12],[1,\"\\n    \"],[10,\"h3\"],[14,0,\"mb-1\"],[12],[1,\"Fully Funded Goal Date\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[6,[39,1],null,[[\"changeset\",\"saveTask\"],[[33,2,[\"changeset\"]],[33,3]]],[[\"default\"],[[[[1,\"    \"],[1,[28,[35,4],null,[[\"changeset\",\"property\",\"selectOptions\"],[[33,2,[\"changeset\"]],\"goalDate\",[33,5]]]]],[1,\"\\n\"]],[1]]]]],[41,[33,7],[[[1,\"    \"],[1,[28,[35,8],null,[[\"error\",\"class\"],[[33,7],\"mt-3\"]]]],[1,\"\\n\"]],[]],null]],[]]]]]],[\"showValidationErrors\"],false,[\"de-form-card\",\"save-form\",\"model\",\"save\",\"form-group-select\",\"goalDateOptions\",\"if\",\"error\",\"generic-error\"]]",
    "moduleName": "donor-elf-web/templates/coaching/individuals/individual/edit-goal-date.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});