define("donor-elf-web/components/form-group", ["exports", "@ember/component", "@ember/utils", "@ember/object"], function (_exports, _component, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    attrs
    - changeset
    - property
    - label
    - labelIsHtmlSafe
      - if true, will treat the label as html safe
        - which allows you to have html elements in the label
      - default is false
    - showValidationErrors
    - focusOnInsert - if the input box should receive focus when it's inserted
    - inputType - defaults to 'text'
    - additionalInputClasses - string
    - disabled
    - textArea
      - if true, then creates a textarea instead of an textbox
    - textAreaRows
      - number of rows for a textarea. Default is 4
    - selectOptions
      - if present, a select element will be used in place of a textbox
      - array of strings for select options - default is null
    - booleanField
      - if true, then a select with Yes/No options will be presented
    - onEnter
      - action to call when the enter button is pressed
    - onChange
      - action to call when the change event occurs
  */
  var _default = _component.default.extend({
    classNames: ['form-group'],
    classNameBindings: ['hasErrors'],
    label: null,
    changeset: null,
    // the associated property on the changeset
    property: null,
    showValidationErrors: false,
    // the 'type' attribute on the input element
    inputType: 'text',
    additionalInputClasses: '',
    inputClasses: (0, _object.computed)('additionalInputClasses', function () {
      return `form-control ${this.additionalInputClasses}`;
    }),
    textAreaRows: 4,
    disabled: false,
    focusOnInsert: false,
    selectOptions: null,
    booleanField: false,
    options: (0, _object.computed)('selectOptions', 'booleanField', function () {
      let {
        selectOptions,
        booleanField
      } = (0, _object.getProperties)(this, 'selectOptions', 'booleanField');

      if (booleanField) {
        return [{
          label: 'Yes',
          val: true
        }, {
          label: 'No',
          val: false
        }];
      } else if (!(0, _utils.isEmpty)(selectOptions)) {
        return selectOptions.map(option => {
          return {
            label: option,
            val: option
          };
        });
      } else {
        return null;
      }
    }),
    hasErrors: (0, _object.computed)('showValidationErrors', 'changeset.errors.[]', 'property', function () {
      if ((0, _object.get)(this, 'showValidationErrors') === false) {
        return false;
      }

      let property = (0, _object.get)(this, 'property');
      return (0, _object.get)(this, 'changeset.errors').find(function (item) {
        return (0, _object.get)(item, 'key') === property;
      }) != null;
    }),
    didInsertElement: function () {
      this._super(...arguments);

      if (this.focusOnInsert) {
        setTimeout(() => {
          let textbox = this.element.querySelector('input, textarea');

          if (textbox) {
            textbox.select();
          }
        }, 50);
      }
    },
    actions: {
      onSelectChange: function (val) {
        let parsedVal = val;

        if (val === 'true') {
          parsedVal = true;
        } else if (val === 'false') {
          parsedVal = false;
        }

        (0, _object.set)(this, `changeset.${(0, _object.get)(this, 'property')}`, parsedVal);
      },

      onEnter() {
        let onEnterAction = (0, _object.get)(this, 'onEnter');

        if (onEnterAction) {
          onEnterAction();
        }
      },

      onChange() {
        let onChangeAction = this.onChange;

        if (onChangeAction) {
          onChangeAction();
        }
      }

    }
  });

  _exports.default = _default;
});