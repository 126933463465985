define("donor-elf-web/helpers/fund-mapping-database-label", ["exports", "@ember/component/helper", "donor-elf-web/services/fund-mappings-service"], function (_exports, _helper, _fundMappingsService) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(function ([databaseId]) {
    return _fundMappingsService.HostDatabaseLabels[databaseId] || databaseId;
  });

  _exports.default = _default;
});