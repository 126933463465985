define("donor-elf-web/services/fund-mappings-service", ["exports", "@ember/service", "@glimmer/tracking", "ember-cached-decorator-polyfill", "donor-elf-web/lib/store-utils", "@ember/utils", "fuzzysort"], function (_exports, _service, _tracking, _emberCachedDecoratorPolyfill, _storeUtils, _utils, _fuzzysort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.HostDatabaseLabels = _exports.FundMapping = _exports.default = void 0;

  var _class, _descriptor, _class3, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FundMappingsService = (_class3 = class FundMappingsService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "funds", _descriptor3, this);

      _initializerDefineProperty(this, "hostIdentities", _descriptor4, this);

      _initializerDefineProperty(this, "hostFunds", _descriptor5, this);

      _defineProperty(this, "databaseHostFundOptions", {});

      _defineProperty(this, "_loadPromise", void 0);
    }

    get fundMappings() {
      let {
        hostIdentitiesByFundId
      } = this;
      return this.funds.map(fund => {
        return new FundMapping({
          fund: fund,
          hostIdentities: hostIdentitiesByFundId[fund.id]
        });
      });
    }

    get hostIdentitiesByFundId() {
      let byId = {};
      this.hostIdentities.forEach(hostIdentity => {
        let fundId = hostIdentity.identifiableId;
        byId[fundId] = byId[fundId] || [];
        byId[fundId].push(hostIdentity);
      });
      return byId;
    }

    getHostFund(args) {
      var _this$hostFundsByData;

      let database = (0, _utils.isEmpty)(args.database) ? 'null' : args.database;
      return (_this$hostFundsByData = this.hostFundsByDatabaseAndHostId[database]) === null || _this$hostFundsByData === void 0 ? void 0 : _this$hostFundsByData[args.hostId];
    } // indexed by database then host id
    // for host funds with a blank database, then 'null' is used for its key


    get hostFundsByDatabaseAndHostId() {
      let data = {};
      this.hostFunds.forEach(x => {
        let database = x.databaseId;

        if ((0, _utils.isEmpty)(database)) {
          database = 'null';
        }

        if (data[database] == null) {
          data[database] = {};
        }

        data[database][x.hostId] = x;
      });
      return data;
    }

    get availableDatabaseOptions() {
      let databaseAndLabels = {};
      this.hostIdentities.forEach(x => {
        let database = x.database || '';

        if (databaseAndLabels[database] == null) {
          databaseAndLabels[database] = HostDatabaseLabels[database] || database;
        }
      });
      this.hostFunds.forEach(x => {
        let database = x.databaseId || '';

        if (databaseAndLabels[database] == null) {
          databaseAndLabels[database] = HostDatabaseLabels[database] || database;
        }
      });
      return Object.keys(databaseAndLabels).map(database => {
        return {
          val: database,
          label: databaseAndLabels[database]
        };
      }).sortBy('label');
    }

    load() {
      if (this._loadPromise) {
        return this._loadPromise;
      }

      return this._loadPromise = (0, _storeUtils.queryWithPages)({
        store: this.store,
        modelName: 'admin/fund',
        pageSize: 500
      }).then(funds => {
        this.funds = funds;
        return (0, _storeUtils.queryWithPages)({
          store: this.store,
          modelName: 'admin/fund-host-identity',
          pageSize: 500
        }).then(hostIdentities => {
          this.hostIdentities = hostIdentities;
          return (0, _storeUtils.queryWithPages)({
            store: this.store,
            modelName: 'admin/host-fund',
            pageSize: 1000
          }).then(hostFunds => {
            this.hostFunds = hostFunds;
            this.createDatabaseHostFundOptions();
          });
        });
      });
    }

    createDatabaseHostFundOptions() {
      this.databaseHostFundOptions = {};
      let databases = this.availableDatabaseOptions.map(x => x.val);
      let {
        hostFundsByDatabaseAndHostId
      } = this;
      databases.forEach(database => {
        let databaseHostFundsById = hostFundsByDatabaseAndHostId[database];

        if (databaseHostFundsById) {
          this.databaseHostFundOptions[database] = Object.keys(databaseHostFundsById).map(hostId => {
            let hostFund = databaseHostFundsById[hostId];
            return {
              val: hostFund.hostId,
              label: hostFund.name
            };
          }).sortBy('label');
        }
      });
    }

    updateMappingsForFund(fund, fundHostIdentities) {
      this.funds = [fund, ...this.funds.filter(x => x.id !== fund.id)];
      this.hostIdentities = [...fundHostIdentities, ...this.hostIdentities.filter(x => x.identifiableId !== fund.id)];
    }

  }, (_descriptor2 = _applyDecoratedDescriptor(_class3.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class3.prototype, "funds", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "hostIdentities", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class3.prototype, "hostFunds", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class3.prototype, "fundMappings", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class3.prototype, "fundMappings"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "hostFundsByDatabaseAndHostId", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class3.prototype, "hostFundsByDatabaseAndHostId"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "availableDatabaseOptions", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class3.prototype, "availableDatabaseOptions"), _class3.prototype)), _class3);
  _exports.default = FundMappingsService;
  let FundMapping = (_class = class FundMapping {
    constructor(args) {
      _defineProperty(this, "fund", void 0);

      _defineProperty(this, "fuzzySortPrepared", void 0);

      _initializerDefineProperty(this, "hostIdentities", _descriptor, this);

      this.fund = args.fund;
      this.hostIdentities = args.hostIdentities || [];
      this.fuzzySortPrepared = _fuzzysort.default.prepare(`${this.fund.name} ${this.hostIdentities.map(x => x.hostId).join(' ')}`);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "hostIdentities", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.FundMapping = FundMapping;
  let HostDatabaseLabels = {
    'aplos': 'Aplos',
    'aplos_fund': 'Aplos Fund',
    'breeze': 'Breeze',
    'ccb': 'Church Community Builder',
    'csv': 'CSV File',
    'dp': 'Donor Perfect',
    'etap': 'eTapestry',
    'f1': 'Fellowship 1',
    'FE': 'Financial Edge',
    'GP': 'Great Plains',
    'kf': 'Kindful',
    'pc_c': 'Power Church Contribution',
    'pc_f': 'Power Church Fund',
    'QuickBooks': 'QuickBooks',
    'RE': 'Raisers Edge',
    'sage': 'Sage',
    'SF': 'SalesForce',
    'sky': 'Raisers Edge NXT',
    'sky_fe': 'Financial Edge NXT',
    'ss': 'SiteStacker',
    'virt': 'Virtuous',
    'xero': 'Xero'
  };
  _exports.HostDatabaseLabels = HostDatabaseLabels;
});