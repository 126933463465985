define("donor-elf-web/templates/admin/oauth/quickbooks/disconnected", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "qFDd7zJn",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[10,2],[12],[1,\"\\n    You have disconnected the connection to QuickBooks Online.\\n  \"],[13],[1,\"\\n  \"],[8,[39,1],[[24,0,\"btn btn-warning\"]],[[\"@route\",\"@model\"],[\"funds.fund\",\"selected-fund\"]],[[\"default\"],[[[[1,\"\\n    Go to the Dashboard\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"form-card\",\"link-to\"]]",
    "moduleName": "donor-elf-web/templates/admin/oauth/quickbooks/disconnected.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});