define("donor-elf-web/templates/components/delete-buttons", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "sSlKkvuW",
    "block": "[[[41,[51,[33,1]],[[[1,\"  \"],[10,0],[14,0,\"mb-3\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-danger\"],[16,\"disabled\",[29,[[52,[33,3,[\"isRunning\"]],\"disable\"]]]],[4,[38,4],[[30,0],\"delete\"],null],[12],[1,\"\\n      \"],[1,[28,[35,5],[\"trash\"],null]],[1,\"\\n      Yes, Delete\\n    \"],[13],[1,\"\\n\"],[41,[33,3,[\"isIdle\"]],[[[1,\"      \"],[6,[39,6],null,[[\"class\",\"route\"],[\"ml-3\",[33,7]]],[[\"default\"],[[[[1,\"Cancel\"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null],[41,[33,3,[\"isRunning\"]],[[[1,\"  \"],[10,0],[14,0,\"alert alert-danger\"],[12],[1,\"\\n    Deleting...\\n  \"],[13],[1,\"\\n\"]],[]],null],[41,[33,1],[[[1,\"  \"],[10,0],[14,0,\"alert alert-warning\"],[12],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      Whoops! There was an error deleting the record. Sorry about that.\\n    \"],[13],[1,\"\\n    Please email us at support@donorelf.com to let us know about it and we'll\\n    get it fixed.\\n  \"],[13],[1,\"\\n  \"],[6,[39,6],null,[[\"class\",\"route\"],[\"btn btn-warning\",[33,7]]],[[\"default\"],[[[[1,\"Go Back\"]],[]]]]],[1,\"\\n\"]],[]],null]],[],false,[\"unless\",\"errorDeleting\",\"if\",\"deleteTask\",\"action\",\"svg-jar\",\"link-to\",\"cancelRoute\"]]",
    "moduleName": "donor-elf-web/templates/components/delete-buttons.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});