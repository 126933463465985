define("donor-elf-web/controllers/funds/fund/dashboard/spo", ["exports", "donor-elf-web/controllers/funds/fund/dashboard/ytd"], function (_exports, _ytd) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DashboardSpoController extends _ytd.default {
    get monthlyGoalAmount() {
      var _this$goal;

      let goalAmount = (_this$goal = this.goal) === null || _this$goal === void 0 ? void 0 : _this$goal.goalAmount;

      if (goalAmount) {
        return goalAmount / 12;
      } else {
        return undefined;
      }
    }

  }

  _exports.default = DashboardSpoController;
});