define("donor-elf-web/components/merge-contact/wizard/review/row", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr ...attributes>
    <td class='text-muted'>{{@label}}</td>
    <td>{{yield}}</td>
  </tr>
  */
  {
    "id": "0ZO2jW2V",
    "block": "[[[11,\"tr\"],[17,1],[12],[1,\"\\n  \"],[10,\"td\"],[14,0,\"text-muted\"],[12],[1,[30,2]],[13],[1,\"\\n  \"],[10,\"td\"],[12],[18,3,null],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@label\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "donor-elf-web/components/merge-contact/wizard/review/row.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});