define("donor-elf-web/templates/coaching/individuals/individual/add-report-loading", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Bf4W9wAy",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  Creating new report...\\n\"]],[]]]]]],[],false,[\"de-form-card\"]]",
    "moduleName": "donor-elf-web/templates/coaching/individuals/individual/add-report-loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});