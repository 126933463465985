define("donor-elf-web/components/contact/details/properties/date", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes>
    <div class='tw-font-bold'>{{@date.label}}</div>
    <div>{{format-date @date.originalDate}}</div>
  </div>
  */
  {
    "id": "0J3CwAg5",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"tw-font-bold\"],[12],[1,[30,2,[\"label\"]]],[13],[1,\"\\n  \"],[10,0],[12],[1,[28,[35,0],[[30,2,[\"originalDate\"]]],null]],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@date\"],false,[\"format-date\"]]",
    "moduleName": "donor-elf-web/components/contact/details/properties/date.hbs",
    "isStrictMode": false
  });

  class ContactDetailsPropertiesDateComponent extends _component2.default {}

  _exports.default = ContactDetailsPropertiesDateComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ContactDetailsPropertiesDateComponent);
});