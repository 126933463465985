define("donor-elf-web/templates/components/mailchimp-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Apeeid2R",
    "block": "[[[11,0],[17,1],[24,0,\"d-flex justify-content-center align-items-end\"],[12],[1,\"\\n  \"],[10,\"img\"],[14,\"src\",\"https://assets.donorelf.com/images/misc/mc_freddie_color_web.png\"],[14,\"height\",\"150px\"],[14,\"alt\",\"Mailchimp\"],[12],[13],[1,\"\\n  \"],[10,\"img\"],[14,\"src\",\"https://assets.donorelf.com/images/misc/mc_script_black_web.png\"],[14,\"height\",\"65px\"],[14,\"alt\",\"Mailchimp\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\"],false,[]]",
    "moduleName": "donor-elf-web/templates/components/mailchimp-header.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});