define("donor-elf-web/templates/funds/fund/more/mailchimp/new-connected-to-fund", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "h71kEq5a",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[8,[39,1],[[24,0,\"mb-5\"]],null,null],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Sweet, DonorElf is now syncing with your MailChimp list in the background.\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    This usually takes 5 or 10 minutes, and then you'll see all your DonorElf\\n    contacts with email addresses in your MailChimp list.\\n  \"],[13],[1,\"\\n\"],[6,[39,2],null,[[\"route\"],[\"funds.fund.dashboard\"]],[[\"default\"],[[[[1,\"    Go to my dashboard\\n\"]],[]]]]]],[]]]]]],[],false,[\"de-form-card\",\"mailchimp-header\",\"link-to\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/more/mailchimp/new-connected-to-fund.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});