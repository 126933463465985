define("donor-elf-web/templates/funds/fund/more/newsletter/connected-to-other-fund", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "dflOPCns",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[10,\"h4\"],[14,0,\"border-bottom mb-4\"],[12],[1,\"\\n    Synced with a different Fund\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Your prayerletters.com account is synced with the\\n    \"],[10,\"strong\"],[12],[1,[30,1,[\"newsletterFund\",\"name\"]]],[13],[1,\" fund, which is not the\\n    currently selected fund.\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    To make changes to your newsletter sync, you'll need to change your selected\\n    fund.\\n  \"],[13],[1,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-warning\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"gotoOtherFund\"]]],null],[12],[1,\"\\n    Change Selected Fund\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@model\"],false,[\"form-card\",\"on\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/more/newsletter/connected-to-other-fund.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});