define("donor-elf-web/templates/components/event-info-catchup-gift", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "pMEo76AR",
    "block": "[[[1,\"They gave \"],[1,[28,[35,0],[[33,1,[\"custom\",\"donation_amount\"]]],[[\"removeNoCents\"],[true]]]],[1,\" to help catch-up\\nfrom being behind \"],[1,[28,[35,0],[[33,1,[\"custom\",\"behind_amount\"]]],[[\"removeNoCents\"],[true]]]],[1,\" in giving\\ntowards their pledge.\\n\"]],[],false,[\"format-amount\",\"eventModel\"]]",
    "moduleName": "donor-elf-web/templates/components/event-info-catchup-gift.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});