define("donor-elf-web/modifiers/ember-table-scroll-right", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // scrolls an <EmberTable> to the right after it is inserted
  var _default = (0, _emberModifier.modifier)(function emberTableScrollRight(element) {
    setTimeout(function () {
      element.scrollLeft = element.scrollWidth;
    });
  });

  _exports.default = _default;
});