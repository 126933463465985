define("donor-elf-web/components/form-group-power-select", ["exports", "@ember/component", "@ember/object", "@ember/utils", "@ember/runloop"], function (_exports, _component, _object, _utils, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    attrs
    - changeset
    - property
    - label
    - hideLabel - boolean - default false
    - showValidationErrors
    - selectOptions
      - array of { label: '', val: '' } for select options
    - stringSelectOptions
      - array of strings for select options
    - placeholder
    - onChange(value) - action fired when select is changed
  */
  var _default = _component.default.extend({
    classNames: ['form-group'],
    classNameBindings: ['hasErrors'],
    changeset: null,
    property: null,
    label: null,
    showValidationErrors: null,
    focusOnInsert: false,
    selectOptions: null,
    placeholder: undefined,
    options: (0, _object.computed)('selectOptions', 'stringSelectOptions', function () {
      if ((0, _utils.isPresent)(this.selectOptions)) {
        return this.selectOptions;
      } else if ((0, _utils.isPresent)(this.stringSelectOptions)) {
        return this.stringSelectOptions.map(option => {
          return {
            label: option,
            val: option
          };
        });
      } else {
        return null;
      }
    }),
    changesetPropertyVal: '',
    selectedOption: (0, _object.computed)('changesetPropertyVal', function () {
      let changesetPropertyVal = (0, _object.get)(this, 'changesetPropertyVal');
      return this.options.find(x => (0, _object.get)(x, 'val') === changesetPropertyVal);
    }),
    hasErrors: (0, _object.computed)('showValidationErrors', 'changeset.errors.[]', 'property', function () {
      if ((0, _object.get)(this, 'showValidationErrors') === false) {
        return false;
      }

      let property = (0, _object.get)(this, 'property');
      return (0, _object.get)(this, 'changeset.errors').find(function (item) {
        return (0, _object.get)(item, 'key') === property;
      }) != null;
    }),
    didInsertElement: function () {
      this._super(...arguments);

      (0, _object.set)(this, 'changesetPropertyVal', (0, _object.get)(this, `changeset.${(0, _object.get)(this, 'property')}`));
    },

    focusComesFromOutside(e) {
      if (e.target.classList.contains('ember-basic-dropdown-trigger--below')) {
        return false;
      }

      let blurredEl = e.relatedTarget;
      return (0, _utils.isEmpty)(blurredEl) || !blurredEl.classList.contains('ember-power-select-search-input');
    },

    handleFocused: (0, _object.action)(function (select, e) {
      if (!e.target.classList.contains('ember-power-select-trigger')) {
        return;
      }

      if (this.focusComesFromOutside(e)) {
        _runloop.run.next(() => {
          select.actions.open();
        });
      }
    }),
    handleChanged: (0, _object.action)(function (option) {
      let val = (0, _object.get)(option, 'val');
      (0, _object.set)(this, `changeset.${(0, _object.get)(this, 'property')}`, val);
      (0, _object.set)(this, 'changesetPropertyVal', val);

      if ((0, _object.get)(this, 'onChange')) {
        (0, _object.get)(this, 'onChange')(val);
      }
    })
  });

  _exports.default = _default;
});