define("donor-elf-web/models/contact-download", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ContactDownloadModel extends _model.default {}

  _exports.default = ContactDownloadModel;
});