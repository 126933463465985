define("donor-elf-web/templates/funds/fund/tasks/delete", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "bN6z7v9U",
    "block": "[[[6,[39,0],null,[[\"deleteTask\"],[[33,1]]],[[\"default\"],[[[[1,\"  \"],[10,2],[12],[1,\"\\n    Are you sure you want to delete this task?\\n  \"],[13],[1,\"\\n    \"],[10,\"dl\"],[12],[1,\"\\n      \"],[10,\"dt\"],[12],[1,\"Contact\"],[13],[1,\"\\n      \"],[10,\"dd\"],[12],[1,[28,[35,2],null,[[\"contactId\"],[[33,3,[\"contactId\"]]]]]],[13],[1,\"\\n      \"],[10,\"dt\"],[12],[1,\"Due Date\"],[13],[1,\"\\n      \"],[10,\"dd\"],[12],[1,[28,[35,4],[[33,3,[\"dueAt\"]]],null]],[13],[1,\"\\n      \"],[10,\"dt\"],[12],[1,\"Summary\"],[13],[1,\"\\n      \"],[10,\"dd\"],[12],[1,[33,3,[\"summary\"]]],[13],[1,\"\\n      \"],[10,\"dt\"],[12],[1,\"Notes\"],[13],[1,\"\\n      \"],[10,\"dd\"],[14,0,\"preserve-line-breaks\"],[12],[1,[33,3,[\"notes\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"delete-form\",\"delete\",\"de-contact\",\"model\",\"format-date\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/tasks/delete.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});