define("donor-elf-web/adapters/expired-fund-token-error", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.AdapterError.extend({
    message: 'The fund token has expired.'
  });

  _exports.default = _default;
});