define("donor-elf-web/controllers/admin/users/delete", ["exports", "@ember/controller", "@ember/object", "@ember/service", "ember-concurrency"], function (_exports, _controller, _object, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    fundsService: (0, _service.inject)(),
    storeCache: (0, _service.inject)(),
    flashMessages: (0, _service.inject)(),
    delete: (0, _emberConcurrency.task)(function* () {
      let record = (0, _object.get)(this, 'model.record');
      let displayName = (0, _object.get)(record, 'displayName');
      yield (0, _object.get)(this, 'fundsService').confirmValidFundToken();
      yield record.destroyRecord();
      (0, _object.get)(this, 'flashMessages').success(`${displayName} has been deleted`);
      (0, _object.get)(this, 'storeCache').breakFindAllCache('admin/user');
      (0, _object.get)(this, 'storeCache').breakFindAllCache('admin/user-fund');
      this.send('refreshAdminRoute');
      this.transitionToRoute('admin.users');
    }).drop()
  });

  _exports.default = _default;
});