define("donor-elf-web/routes/coaching/individuals/individual/edit-goal", ["exports", "donor-elf-web/lib/route-reset-controller", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators", "@ember/object", "@ember/service"], function (_exports, _routeResetController, _emberChangeset, _emberChangesetValidations, _validators, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const overallGoalValidation = {
    overallGoal: (0, _validators.validateNumber)({
      gt: 0,
      integer: true,
      allowBlank: false,
      message: 'Must be a positive whole number'
    })
  };
  const intervalGoalValidation = {
    intervalGoal: (0, _validators.validateNumber)({
      gte: 0,
      allowBlank: true,
      message: 'Must be a positive whole number or blank'
    })
  };

  var _default = _routeResetController.default.extend({
    abilities: (0, _service.inject)(),
    coachingService: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),

    beforeModel(transition) {
      if ((0, _object.get)(this, 'abilities').noCoachingAbility('edit_goal')) {
        transition.abort();
      }

      (0, _object.set)(this, 'navbarSettings.title', 'Edit Goal');
    },

    model(params) {
      let individualRecord = this.modelFor('coaching.individuals.individual').record;
      let fieldSectionAndIndex = (0, _object.get)(individualRecord, `fieldsSectionAndIndex.${params.fieldId}`);
      let field = (0, _object.get)(individualRecord, 'sections')[fieldSectionAndIndex.sectionIndex]['fields'][fieldSectionAndIndex.fieldIndex];
      let editFieldModel = {};
      let validation = null;

      if ((0, _object.get)(this, 'coachingService.overallGoalTypes')[(0, _object.get)(field, 't')]) {
        editFieldModel.overallGoal = (0, _object.get)(field, 'overallGoal');
        editFieldModel.intervalGoal = (0, _object.get)(field, 'intervalGoal.low');
        validation = overallGoalValidation;
      } else {
        editFieldModel.intervalGoal = (0, _object.get)(field, 'intervalGoal.low');
        validation = intervalGoalValidation;
      }

      let changeset = new _emberChangeset.default(editFieldModel, (0, _emberChangesetValidations.default)(validation), validation);
      return {
        individualRecord,
        fieldSectionAndIndex,
        field,
        changeset
      };
    }

  });

  _exports.default = _default;
});