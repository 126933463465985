define("donor-elf-web/templates/funds/fund/tasks/v2/by-contact-loading", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "0IGmpe8o",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],null,null,null],[1,\"\\n\"]],[]]]]]],[],false,[\"tasks-header\",\"card\",\"de-loading\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/tasks/v2/by-contact-loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});