define("donor-elf-web/templates/reset-password/token-error", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "wuSC+YYY",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,2],[12],[1,\"\\n    Whoops. It looks like the token to reset your password is invalid. Sorry\\n    about that.\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Please email us at support@donorelf.com to let us know and we'll get it\\n    fixed for you.\\n  \"],[13],[1,\"\\n  \"],[6,[39,1],null,[[\"class\",\"route\"],[\"btn btn-outline-warning\",\"login\"]],[[\"default\"],[[[[1,\"Login\"]],[]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"de-form-card\",\"link-to\"]]",
    "moduleName": "donor-elf-web/templates/reset-password/token-error.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});