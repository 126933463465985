define("donor-elf-web/lib/filter-utils-new", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.GetOperationLabel = GetOperationLabel;
  _exports.ContactIdsIntersection = ContactIdsIntersection;
  _exports.serializeAppliedFilters = serializeAppliedFilters;
  _exports.deserializeAppliedFilters = deserializeAppliedFilters;
  _exports.OperationFilters = _exports.OperationsWithoutVal = _exports.AvailableOperationsForSingleSelectionList = _exports.AvailableOperationsByPropertyType = _exports.OperationLabelOverridesForGiftHistory = _exports.OperationLabelOverridesForDate = _exports.OperationLabels = void 0;
  const OperationLabels = {
    '=': 'is',
    '!=': 'is not',
    'stringContains': 'contains',
    'absent': 'is absent',
    'present': 'is present',
    '>': '>',
    // TODO: Make operation labels specific to date propertyTypes
    '>=': '>=',
    '<': '<',
    '<=': '<=',
    '><': 'between',
    'any': 'in any',
    'all': 'in all',
    'none': 'not in'
  };
  _exports.OperationLabels = OperationLabels;
  const OperationLabelOverridesForDate = {
    '>=': 'after',
    '<=': 'before'
  };
  _exports.OperationLabelOverridesForDate = OperationLabelOverridesForDate;
  const OperationLabelOverridesForGiftHistory = {
    '>=': 'at least',
    '<=': 'at most',
    '=': 'exactly',
    'absent': 'nothing'
  };
  _exports.OperationLabelOverridesForGiftHistory = OperationLabelOverridesForGiftHistory;

  function GetOperationLabel(operation, options = {}) {
    if (options.propertyType === 'date') {
      return OperationLabelOverridesForDate[operation] || OperationLabels[operation];
    } else if (options.propertyType === 'giftHistory') {
      return OperationLabelOverridesForGiftHistory[operation] || OperationLabels[operation];
    }

    return OperationLabels[operation];
  }

  const AvailableOperationsByPropertyType = {
    string: ['stringContains', '=', '!=', 'present', 'absent'],
    number: ['=', '!=', '>', '>=', '<', '<=', 'present', 'absent'],
    amount: ['=', '!=', '>', '>=', '<', '<=', 'present', 'absent'],
    boolean: ['=', 'present', 'absent'],
    // if listIsSingleSelection is true then 'all' is removed
    list: ['any', 'all', 'none', 'present', 'absent'],
    date: ['=', '>=', '<=', '><', 'present', 'absent'],
    giftHistory: ['>=', '<=', '=', 'absent'],
    contactIds: ['any'],
    "no-operation": []
  };
  _exports.AvailableOperationsByPropertyType = AvailableOperationsByPropertyType;
  const AvailableOperationsForSingleSelectionList = AvailableOperationsByPropertyType.list.filter(x => x !== 'all');
  _exports.AvailableOperationsForSingleSelectionList = AvailableOperationsForSingleSelectionList;
  const OperationsWithoutVal = {
    'absent': true,
    'present': true
  }; // This is for the available filters and their options

  _exports.OperationsWithoutVal = OperationsWithoutVal;

  // returns the common contact ids in 2 arrays
  // if the first array is undefined then it returns the second array
  // typically used in `applyToQueryFilter` to handle the scenario where multiple applied filters
  // use contactsCache.filter()
  // example: 
  //   applyToQueryFilter: async (appliedFilter: AppliedPropertyFilter, queryFilter: any) => {
  //     let contactIds = (await this.contactsCache.filter([appliedFilter])).map(x => x.id);
  //     queryFilter.contactIds = ContactIdsIntersection(queryFilter.contactIds, contactIds);
  //   }
  function ContactIdsIntersection(contactIdsOne, contactIdsTwo) {
    if (contactIdsOne == null) {
      return contactIdsTwo;
    }

    return (contactIdsOne || []).filter(x => contactIdsTwo.includes(x));
  }

  const OperationFilters = {
    absent: val => (0, _utils.isEmpty)(val),
    present: val => (0, _utils.isPresent)(val),
    stringContains: (filterValLowerCase, val) => {
      return val && val.indexOf(filterValLowerCase) >= 0;
    },
    stringEquals: (filterValLowerCase, val) => {
      return (val === null || val === void 0 ? void 0 : val.toLowerCase()) === filterValLowerCase;
    }
  }; // TODO: think through ways to shorten the serialized string

  _exports.OperationFilters = OperationFilters;

  function serializeAppliedFilters(filters) {
    if (filters == null || filters.length === 0) {
      return undefined;
    }

    return JSON.stringify(filters);
  }

  function deserializeAppliedFilters(serializedFilters) {
    if (serializedFilters == null || serializedFilters == '') {
      return undefined;
    }

    return JSON.parse(serializedFilters);
  }
});