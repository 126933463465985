define("donor-elf-web/routes/coaching/individuals/edit", ["exports", "@ember/routing/route", "@ember/service", "@ember/object", "ember-changeset"], function (_exports, _route, _service, _object, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    coachingService: (0, _service.inject)(),
    store: (0, _service.inject)(),

    beforeModel() {
      (0, _object.get)(this, 'coachingService').clearSelectedIndividual();
    },

    model(params) {
      if (params.id === 'new') {
        let record = (0, _object.get)(this, 'store').createRecord('coaching/individual');
        let changeset = new _emberChangeset.default(record);
        (0, _object.set)(changeset, 'staticInfo', [{
          l: 'Name',
          v: 'Paul'
        }]);
        return {
          record,
          changeset
        };
      } else {
        return (0, _object.get)(this, 'store').find('coaching/individual', params.id).then(record => {
          let changeset = new _emberChangeset.default(record);
          return {
            record,
            changeset
          };
        });
      }
    }

  });

  _exports.default = _default;
});