define("donor-elf-web/routes/coaching/individuals/copy", ["exports", "donor-elf-web/lib/route-reset-controller", "@ember/service", "@ember/object", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators"], function (_exports, _routeResetController, _service, _object, _emberChangeset, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const validation = {
    templateId: (0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    }),
    name: (0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    })
  };

  var _default = _routeResetController.default.extend({
    abilities: (0, _service.inject)(),
    coachingService: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),
    store: (0, _service.inject)(),

    beforeModel(transition) {
      if ((0, _object.get)(this, 'abilities').noCoachingAbility('create_individual')) {
        transition.abort();
        return;
      }

      (0, _object.set)(this, 'navbarSettings.title', 'Add Individual');
      (0, _object.get)(this, 'coachingService').clearSelectedIndividual();
    },

    model() {
      return (0, _object.get)(this, 'abilities').loadCoachingAbilities().then(() => {
        return (0, _object.get)(this, 'store').query('coaching/individual', {
          filter: {
            template: true
          }
        }).then(templates => {
          return {
            templates,
            changeset: new _emberChangeset.default({}, (0, _emberChangesetValidations.default)(validation), validation)
          };
        });
      });
    }

  });

  _exports.default = _default;
});