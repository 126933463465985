define("donor-elf-web/lib/de-ember-router-scroll-mixin", ["exports", "@ember/object/mixin", "@ember/object", "@ember/service", "ember-app-scheduler", "@ember/runloop"], function (_exports, _mixin, _object, _service, _emberAppScheduler, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // this overrides the mixin from ember-router-scroll
  // and takes into consideration ember-burger-menu
  var _default = _mixin.default.create({
    deRouterScroll: (0, _service.inject)(),

    init() {
      this._super(...arguments);

      (0, _emberAppScheduler.setupRouter)(this);
      this.on('routeWillChange', () => {
        this.deRouterScroll.update();
      });
      this.on('routeDidChange', transition => {
        (0, _runloop.scheduleOnce)('render', this, () => this.updateScrollPosition(transition));
      });
    },

    destroy() {
      (0, _emberAppScheduler.reset)();

      this._super(...arguments);
    },

    updateScrollPosition(transition) {
      let scrollPosition = this.deRouterScroll.position;
      let preserveScrollPosition = (0, _object.getWithDefault)(transition, 'router.currentRouteInfos', []).some(routeInfo => (0, _object.get)(routeInfo, 'route.controller.preserveScrollPosition'));

      if (!preserveScrollPosition) {
        let bmContent = document.querySelector('.bm-content');

        if (bmContent) {
          bmContent.scrollTop = scrollPosition.y;
        }
      }
    }

  });

  _exports.default = _default;
});