define("donor-elf-web/templates/funds/fund/groups/group/edit-name", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "smr91LPY",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,\"h3\"],[12],[1,\"Edit Group Name\"],[13],[1,\"\\n\"],[6,[39,1],null,[[\"changeset\",\"cancelRoute\",\"saveTask\"],[[33,2,[\"changeset\"]],\"funds.fund.groups.group\",[33,3]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,4],null,[[\"changeset\",\"property\",\"label\",\"focusOnInsert\",\"showValidationErrors\"],[[33,2,[\"changeset\"]],\"newName\",\"Name\",true,[30,1]]]]],[1,\"\\n\\n\"]],[1]]]]],[41,[33,6],[[[1,\"    \"],[1,[28,[35,7],null,[[\"error\",\"class\"],[[33,6],\"mt-3\"]]]],[1,\"\\n\"]],[]],null]],[]]]]]],[\"showValidationErrors\"],false,[\"de-form-card\",\"save-form\",\"model\",\"save\",\"form-group\",\"if\",\"error\",\"generic-error\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/groups/group/edit-name.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});