define("donor-elf-web/controllers/funds/fund/journals/add", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "@ember/service", "donor-elf-web/lib/journal-goal-utils", "ember-changeset", "ember-changeset-validations", "donor-elf-web/validations/journal-create", "@ember/utils"], function (_exports, _controller, _object, _tracking, _service, _journalGoalUtils, _emberChangeset, _emberChangesetValidations, _journalCreate, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    moment
  } = window;
  let JournalsAddController = (_class = class JournalsAddController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "currentUser", _descriptor, this);

      _initializerDefineProperty(this, "journalService", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "showValidationErrors", _descriptor4, this);

      _initializerDefineProperty(this, "saving", _descriptor5, this);

      _initializerDefineProperty(this, "error", _descriptor6, this);

      _initializerDefineProperty(this, "changeset", _descriptor7, this);

      _defineProperty(this, "journalCreate", void 0);

      _initializerDefineProperty(this, "duplicateJournalBulkAction", _descriptor8, this);

      _initializerDefineProperty(this, "duplicateJournalId", _descriptor9, this);

      _initializerDefineProperty(this, "setClosedDateOnDuplicateJournal", _descriptor10, this);

      _defineProperty(this, "queryParams", ['duplicateJournalId']);

      _defineProperty(this, "model", void 0);
    }

    get isDuplicatingJournal() {
      return this.duplicateJournalId != null;
    }

    get duplicateJournalHasNoClosedDate() {
      var _this$model$duplicate;

      return ((_this$model$duplicate = this.model.duplicateJournalInfo) === null || _this$model$duplicate === void 0 ? void 0 : _this$model$duplicate.journal.closedDate) == null;
    }

    get multipleTemplatesAvailable() {
      return this.model.templates.length > 1;
    }

    get isUniversityChristianOutreachUser() {
      return this.currentUser.organizationId === '239';
    }

    get templateOptions() {
      // templates with a fundGroupId are first
      let sortedTemplates = this.model.templates.slice().sort(a => (0, _utils.isPresent)(a.fundGroupId) ? -1 : 0);
      return [{
        val: undefined,
        label: ''
      }].concat(sortedTemplates.map(x => {
        return {
          val: x.id,
          label: x.name
        };
      }));
    }

    get templateIsSelectedOrDuplicatingJournal() {
      return this.selectedTemplate != null || this.isDuplicatingJournal;
    }

    get selectedTemplate() {
      return this.model.templates.find(x => {
        var _this$changeset;

        return x.id === ((_this$changeset = this.changeset) === null || _this$changeset === void 0 ? void 0 : _this$changeset.get('journalTemplateId'));
      });
    }

    get canSelectMainGoalType() {
      var _this$selectedTemplat;

      return !this.isDuplicatingJournal && ((_this$selectedTemplat = this.selectedTemplate) === null || _this$selectedTemplat === void 0 ? void 0 : _this$selectedTemplat.mainGoalTypeId) == null;
    }

    get showDecisionsRequireGift() {
      let {
        selectedTemplate
      } = this;
      return selectedTemplate && !selectedTemplate.hideDecisionsRequireGift && !selectedTemplate.decisionsRequireGift;
    }

    get canHaveSecondaryOneTimeGoal() {
      var _this$selectedTemplat2;

      return ((_this$selectedTemplat2 = this.selectedTemplate) === null || _this$selectedTemplat2 === void 0 ? void 0 : _this$selectedTemplat2.canHaveSecondaryOneTimeGoal) && this.changeset.get('mainGoalTypeId') !== _journalGoalUtils.oneTimeGoalTypeId;
    }

    get mainGoalTypeOptions() {
      return Object.keys(_journalGoalUtils.journalGoalLabels).filter(x => Number(x) !== _journalGoalUtils.annualGoalTypeId).map(goalTypeId => {
        return {
          // @ts-expect-error
          label: _journalGoalUtils.journalGoalLabels[goalTypeId],
          val: Number(goalTypeId)
        };
      });
    }

    get mainGoalTypeLabel() {
      if (this.canSelectMainGoalType || this.isDuplicatingJournal) {
        return 'Goal Amount';
      } else {
        let mainGoalTypeLabel = _journalGoalUtils.journalGoalLabels[this.changeset.get('mainGoalTypeId')];

        return `${mainGoalTypeLabel} Goal Amount`.trim();
      }
    }

    onJournalTemplateChanged() {
      let {
        selectedTemplate
      } = this;

      if (selectedTemplate) {
        // if you update this code block, then update the code block in createChangeset() that sets the same fields
        this.changeset.set('mainGoalTypeId', selectedTemplate.mainGoalTypeId || _journalGoalUtils.monthlyGoalTypeId);
        this.changeset.set('decisionsRequireGift', selectedTemplate.decisionsRequireGift);
      }
    }

    templateDidInsert() {
      this.createChangeset();
    }

    templateWillDestroy() {
      this.showValidationErrors = false;
      this.saving = false;
      this.error = undefined;
      this.changeset = undefined;
      this.journalCreate = undefined;
      this.duplicateJournalBulkAction = undefined;
      this.duplicateJournalId = undefined;
    }

    createChangeset() {
      let today = moment();
      this.journalCreate = this.store.createRecord('journal-create', {
        startDate: today.toDate(),
        goalDate: moment().add(6, 'months').toDate(),
        active: true
      });

      if (this.isDuplicatingJournal) {
        this.journalCreate.duplicateJournalId = this.model.duplicateJournalInfo.journal.id;
        this.journalCreate.mainGoalTypeId = this.model.duplicateJournalInfo.goals[0].goalTypeId;
        this.journalCreate.duplicateMoveUnfinishedTasks = true;

        if (this.model.duplicateJournalInfo.journal.closedDate == null) {
          this.setClosedDateOnDuplicateJournal = true;
          this.journalCreate.duplicateJournalClosedDate = today.toDate();
        } else {
          this.setClosedDateOnDuplicateJournal = false;
        }
      } else if (this.model.templates.length === 1) {
        let template = this.model.templates[0];
        this.journalCreate.journalTemplateId = template.id;
        this.journalCreate.mainGoalTypeId = template.mainGoalTypeId || _journalGoalUtils.monthlyGoalTypeId;
        this.journalCreate.decisionsRequireGift = template.decisionsRequireGift;
      }

      if (this.isUniversityChristianOutreachUser) {
        this.journalCreate.mainGoalTypeId = _journalGoalUtils.annualGoalTypeId;
        this.journalCreate.mainGoalAmount = 1; // it doesn't matter since they just show the dashboard goal
      }

      this.changeset = new _emberChangeset.default(this.journalCreate, (0, _emberChangesetValidations.default)(_journalCreate.default), _journalCreate.default);
    }

    toggleSetClosedDateOnDuplicateJournal(e) {
      this.setClosedDateOnDuplicateJournal = e.target.checked;

      if (this.setClosedDateOnDuplicateJournal) {
        this.changeset.set('duplicateJournalClosedDate', moment().toDate());
      } else {
        this.changeset.set('duplicateJournalClosedDate', undefined);
      }
    }

    async save() {
      this.saving = true;
      let {
        changeset
      } = this;

      try {
        await changeset.validate();

        if (changeset.get('isValid')) {
          await changeset.save();

          if (this.journalCreate.duplicateJournalBulkAction != null) {
            this.duplicateJournalBulkAction = this.journalCreate.duplicateJournalBulkAction;
          } else {
            await this.journalService.load({
              forceReload: true
            });
            this.router.transitionTo('funds.fund.journals.journal.add-contacts', changeset.get('id'));
          }
        } else {
          this.showValidationErrors = true;
        }
      } catch (error) {
        this.error = error;
      }

      this.saving = false;
    }

    async onDuplicateJournalBulkActionStatusChange(bulkAction) {
      if (bulkAction.status === 'completed') {
        await this.journalService.load({
          forceReload: true
        });
        this.router.transitionTo('funds.fund.journals.journal', bulkAction.response.new_journal_id);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "journalService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showValidationErrors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "saving", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "error", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "changeset", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "duplicateJournalBulkAction", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "duplicateJournalId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "setClosedDateOnDuplicateJournal", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onJournalTemplateChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onJournalTemplateChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleSetClosedDateOnDuplicateJournal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleSetClosedDateOnDuplicateJournal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onDuplicateJournalBulkActionStatusChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onDuplicateJournalBulkActionStatusChange"), _class.prototype)), _class);
  _exports.default = JournalsAddController;
});