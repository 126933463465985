define("donor-elf-web/controllers/funds/fund/more/donor-hub/connect/organization", ["exports", "@ember/controller", "@ember/object/computed", "@ember/object", "@ember/service", "ember-concurrency"], function (_exports, _controller, _computed, _object, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    fundsService: (0, _service.inject)(),
    store: (0, _service.inject)(),
    showValidationErrors: false,
    error: null,

    reset() {
      (0, _object.setProperties)(this, {
        showValidationErrors: false,
        error: null
      });
    },

    sortTntOrganizationsBy: Object.freeze(['name']),
    sortedTntOrganizations: (0, _computed.sort)('model.tntOrganizations', 'sortTntOrganizationsBy'),
    tntOrganizationOptions: (0, _object.computed)('sortedTntOrganizations.[]', function () {
      let options = [];
      (0, _object.get)(this, 'sortedTntOrganizations').forEach(tntOrg => {
        options.push({
          label: (0, _object.get)(tntOrg, 'name'),
          val: (0, _object.get)(tntOrg, 'id')
        });
      });
      return options;
    }),
    selectedTntOrganization: (0, _object.computed)('model.changeset.tntOrganizationId', function () {
      let tntOrgId = (0, _object.get)(this, 'model.changeset.tntOrganizationId');

      if (tntOrgId) {
        return (0, _object.get)(this, 'model.tntOrganizations').find(x => {
          return (0, _object.get)(x, 'id') === tntOrgId;
        });
      } else {
        return null;
      }
    }),
    save: (0, _emberConcurrency.task)(function* () {
      (0, _object.set)(this, 'error', null);
      let changeset = (0, _object.get)(this, 'model.changeset');

      try {
        yield changeset.validate();
        (0, _object.set)(this, 'showValidationErrors', true);

        if ((0, _object.get)(changeset, 'isValid')) {
          // remove previously set username or password
          (0, _object.setProperties)(changeset, {
            username: null,
            password: null
          });
          yield (0, _object.get)(this, 'fundsService').confirmValidFundToken();
          yield changeset.save();
          let tntOrg = (0, _object.get)(this, 'selectedTntOrganization');

          if ((0, _object.get)(tntOrg, 'oauthLogin')) {
            this.transitionToRoute('funds.fund.more.donor-hub.connect.oauth-redirect');
          } else {
            this.transitionToRoute('funds.fund.more.donor-hub.connect.credentials');
          }
        }
      } catch (error) {
        (0, _object.set)(this, 'error', error);
      }
    }).drop()
  });

  _exports.default = _default;
});