define("donor-elf-web/controllers/funds/fund/tasks/edit", ["exports", "@ember/controller", "@ember/object", "@ember/service", "ember-concurrency", "@ember/utils", "@ember/object/computed"], function (_exports, _controller, _object, _service, _emberConcurrency, _utils, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    communicationsService: (0, _service.inject)(),
    fundsService: (0, _service.inject)(),
    queryParams: ['contactId', 'fromDetailPage'],
    error: null,
    contactId: null,
    fromDetailPage: null,

    reset() {
      (0, _object.setProperties)(this, {
        error: null,
        contactId: null,
        fromDetailPage: null
      });
    },

    reasons: (0, _computed.alias)('communicationsService.reasons'),
    mediums: (0, _computed.alias)('communicationsService.mediums'),
    cancelRoute: (0, _object.computed)('model.record.id', function () {
      let route = undefined;

      if ((0, _object.get)(this, 'model.record.isNew')) {
        if ((0, _object.get)(this, 'model.returnToContactDetailsPage')) {
          route = 'funds.fund.contacts.contact';
        }
      }

      return route;
    }),
    cancelRouteParams: (0, _object.computed)('model.record.id', function () {
      let params = undefined;

      if ((0, _object.get)(this, 'model.record.isNew')) {
        if ((0, _object.get)(this, 'model.returnToContactDetailsPage')) {
          params = (0, _object.get)(this, 'model.record.contactId');
        }
      }

      return params;
    }),
    deleteRoute: (0, _object.computed)('model.record.isNew', function () {
      if ((0, _object.get)(this, 'model.record.isNew')) {
        return undefined;
      }

      return 'funds.fund.tasks.delete';
    }),
    deleteRouteParams: (0, _object.computed)('model.record.id', function () {
      if ((0, _object.get)(this, 'model.record.isNew')) {
        return undefined;
      }

      return (0, _object.get)(this, 'model.record.id');
    }),
    showCompletedAtDate: (0, _object.computed)('model.record.isNew', 'model.record.completedAt', 'model.changeset.completedAt', function () {
      return this.model.record.isNew || (0, _utils.isPresent)(this.model.record.completedAt) || (0, _utils.isPresent)((0, _object.get)(this, 'model.changeset.completedAt'));
    }),
    showSaved: (0, _object.computed)('model.saved', 'model.changeset.isPristine', function () {
      return this.model.saved && (0, _object.get)(this, 'model.changeset.isPristine');
    }),
    showGoBack: (0, _computed.alias)('model.saved'),
    showCreateCommunication: (0, _object.computed)('model.record.isNew', 'model.record.completedAt', 'model.record.contactId', function () {
      return !this.model.record.isNew && (0, _utils.isPresent)(this.model.record.completedAt) && (0, _utils.isPresent)(this.model.record.contactId);
    }),
    showSaveFormActions: (0, _object.computed)('model.saved', 'model.changeset.isDirty', function () {
      return !this.model.saved || (0, _object.get)(this, 'model.changeset.isDirty');
    }),
    save: (0, _emberConcurrency.task)(function* () {
      let changeset = this.model.changeset;
      yield changeset.validate();

      if ((0, _object.get)(changeset, 'isValid')) {
        try {
          yield this.fundsService.confirmValidFundToken();
          yield changeset.save();
          (0, _object.set)(this, 'model.saved', true);
        } catch (error) {
          if ((0, _object.get)(this, 'model.record.errors.length') > 0) {
            (0, _object.get)(this, 'model.record.errors').forEach(({
              attribute,
              message
            }) => {
              changeset.pushErrors(attribute, message);
            });
          } else {
            (0, _object.set)(this, 'error', error);
          }
        }
      }
    }).drop(),
    actions: {
      markComplete() {
        (0, _object.set)(this, 'model.changeset.completedAt', new Date());
        this.save.perform();
      },

      goBack() {
        if (this.model.returnToContactDetailsPage && (0, _utils.isPresent)((0, _object.get)(this, 'changeset.contactId'))) {
          this.transitionToRoute('funds.fund.contacts.contact', (0, _object.get)(this, 'changeset.contactId'));
        } else {
          window.history.back();
        }
      }

    }
  });

  _exports.default = _default;
});