define("donor-elf-web/templates/funds/fund/more/notifications/none", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "4uAz1S1I",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,2],[12],[1,\"\\n    Would you like to be emailed when you receive new transactions or news feed\\n    events?\\n  \"],[13],[1,\"\\n\"],[6,[39,1],null,[[\"class\",\"route\"],[\"btn btn-warning\",\"funds.fund.more.notifications.edit\"]],[[\"default\"],[[[[1,\"    Yes\\n\"]],[]]]]]],[]]]]]],[],false,[\"de-form-card\",\"link-to\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/more/notifications/none.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});