define("donor-elf-web/validations/coach/report", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: (0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    }),
    coachTemplateId: (0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    }),
    startDate: (0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    }),
    fundedGoalDate: (0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    }),
    periodTimespan: (0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    }),
    periodNumber: (0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    })
  };
  _exports.default = _default;
});