define("donor-elf-web/components/journal/menu-bar", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Card @noPadding={{true}} ...attributes class='my-3 border'>
    <div class='d-flex'>
      <LinkTo
        @route='funds.fund.journals.journal.index'
        @activeClass='bg-gray-200'
        class='d-block px-3 py-2 border-right rounded-left'
      >
        Journal
      </LinkTo>
      <LinkTo
        @route='funds.fund.journals.journal.report'
        @activeClass='bg-gray-200'
        class='d-block px-3 py-2 border-right'
      >
        Report
      </LinkTo>
      {{#if (has-block)}}
        {{yield}}
      {{/if}}
    </div>
  </Card>
  */
  {
    "id": "yZSoBvo6",
    "block": "[[[8,[39,0],[[17,1],[24,0,\"my-3 border\"]],[[\"@noPadding\"],[true]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"d-flex\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,0,\"d-block px-3 py-2 border-right rounded-left\"]],[[\"@route\",\"@activeClass\"],[\"funds.fund.journals.journal.index\",\"bg-gray-200\"]],[[\"default\"],[[[[1,\"\\n      Journal\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[39,1],[[24,0,\"d-block px-3 py-2 border-right\"]],[[\"@route\",\"@activeClass\"],[\"funds.fund.journals.journal.report\",\"bg-gray-200\"]],[[\"default\"],[[[[1,\"\\n      Report\\n    \"]],[]]]]],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"      \"],[18,2,null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]]]]]],[\"&attrs\",\"&default\"],false,[\"card\",\"link-to\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "donor-elf-web/components/journal/menu-bar.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});