define("donor-elf-web/controllers/funds/fund/journals/journal/segments/segment", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@ember/utils", "@glimmer/tracking", "donor-elf-web/lib/array-utils"], function (_exports, _controller, _object, _service, _utils, _tracking, _arrayUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let JournalSegmentController = (_class = class JournalSegmentController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "saving", _descriptor2, this);

      _initializerDefineProperty(this, "error", _descriptor3, this);

      _initializerDefineProperty(this, "inSegmentFilter", _descriptor4, this);

      _initializerDefineProperty(this, "outSegmentFilter", _descriptor5, this);

      _initializerDefineProperty(this, "inSegment", _descriptor6, this);

      _initializerDefineProperty(this, "outSegment", _descriptor7, this);
    }

    get filteredInSegment() {
      if ((0, _utils.isPresent)(this.inSegmentFilter)) {
        let lowerCaseFilter = this.inSegmentFilter.toLowerCase();
        return this.inSegment.filter(x => {
          return x.contact.nameLowerCase.includes(lowerCaseFilter);
        });
      } else {
        return this.inSegment;
      }
    }

    get filteredOutSegment() {
      if ((0, _utils.isPresent)(this.outSegmentFilter)) {
        let lowerCaseFilter = this.outSegmentFilter.toLowerCase();
        return this.outSegment.filter(x => {
          return x.contact.nameLowerCase.includes(lowerCaseFilter);
        });
      } else {
        return this.outSegment;
      }
    }

    templateDidInsert() {
      let segmentName = this.model.segmentName;
      this.inSegment = (0, _arrayUtils.sortByProperties)(this.model.journalContactInfos.filter(info => info.journalContact.segments.some(x => x === segmentName)), ['contact.name']);
      this.outSegment = (0, _arrayUtils.sortByProperties)(this.model.journalContactInfos.filter(info => !info.journalContact.segments.some(x => x === segmentName)), ['contact.name']);
    }

    templateWillDestroy() {
      this.saving = false;
      this.error = undefined;
    }

    handleInSegmentFilterInput(e) {
      this.inSegmentFilter = e.target.value;

      if (this.outSegmentFilter !== '') {
        this.outSegmentFilter = '';
      }
    }

    handleOutSegmentFilterInput(e) {
      this.outSegmentFilter = e.target.value;

      if (this.inSegmentFilter !== '') {
        this.inSegmentFilter = '';
      }
    }

    removeFromSegment(jcInfo) {
      this.outSegment = [jcInfo, ...this.outSegment];
      this.inSegment = this.inSegment.filter(x => x.contactId !== jcInfo.contactId);
    }

    addToSegment(jcInfo) {
      this.inSegment = [jcInfo, ...this.inSegment];
      this.outSegment = this.outSegment.filter(x => x.contactId !== jcInfo.contactId);
    }

    async save() {
      this.saving = true;

      for (let i = 0; i < this.inSegment.length; i++) {
        let jcInfo = this.inSegment[i];

        if (!jcInfo.journalContact.segments.some(x => x === this.model.segmentName)) {
          jcInfo.journalContact.segments.push(this.model.segmentName);

          try {
            await jcInfo.journalContact.save();
          } catch (error) {
            this.error = error;
            this.saving = false;
            return;
          }
        }
      }

      for (let i = 0; i < this.outSegment.length; i++) {
        let jcInfo = this.outSegment[i];

        if (jcInfo.journalContact.segments.some(x => x === this.model.segmentName)) {
          jcInfo.journalContact.segments = jcInfo.journalContact.segments.filter(x => x !== this.model.segmentName);

          try {
            await jcInfo.journalContact.save();
          } catch (error) {
            this.error = error;
            this.saving = false;
            return;
          }
        }
      }

      this.router.transitionTo('funds.fund.journals.journal.segments');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "saving", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "error", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "inSegmentFilter", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "outSegmentFilter", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "inSegment", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "outSegment", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleInSegmentFilterInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleInSegmentFilterInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleOutSegmentFilterInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleOutSegmentFilterInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeFromSegment", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeFromSegment"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addToSegment", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addToSegment"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class);
  _exports.default = JournalSegmentController;
});