define("donor-elf-web/templates/components/contact-upload-mapping", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "zuQNKea0",
    "block": "[[[10,\"h4\"],[14,0,\"mb-3\"],[12],[1,[33,0,[\"column\"]]],[13],[1,\"\\n\"],[10,0],[12],[1,\"The '\"],[1,[33,0,[\"column\"]]],[1,\"' column Maps to\"],[13],[1,\"\\n\"],[10,\"select\"],[14,0,\"form-control mt-1 mb-2\"],[15,\"onchange\",[28,[37,1],[[30,0],\"onSelectChange\"],[[\"value\"],[\"target.value\"]]]],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[33,4]],null]],null],null,[[[1,\"    \"],[10,\"option\"],[15,2,[30,1,[\"val\"]]],[15,\"selected\",[28,[37,5],[[33,0,[\"property\"]],[30,1,[\"val\"]]],null]],[12],[1,[30,1,[\"label\"]]],[13],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\"],[10,0],[14,0,\"text-muted small mb-3\"],[12],[1,\"Leave blank if you don't want to upload this column\"],[13],[1,\"\\n\"],[10,0],[14,0,\"pl-3\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"font-weight-bold mb-1\"],[12],[1,\"Example Values\"],[13],[1,\"\\n  \"],[10,\"table\"],[14,0,\"table table-sm\"],[12],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[33,0,[\"exampleValues\"]]],null]],null],null,[[[1,\"        \"],[10,\"tr\"],[12],[10,\"td\"],[12],[1,[30,2]],[13],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"item\",\"val\"],false,[\"mapping\",\"action\",\"each\",\"-track-array\",\"propertyOptions\",\"eq\"]]",
    "moduleName": "donor-elf-web/templates/components/contact-upload-mapping.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});