define("donor-elf-web/validations/journal-create", ["exports", "@ember/utils", "donor-elf-web/lib/string-utils", "donor-elf-web/lib/object-utils", "donor-elf-web/lib/journal-goal-utils", "ember-changeset-validations/validators"], function (_exports, _utils, _stringUtils, _objectUtils, _journalGoalUtils, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    startDate: (key, newValue, oldValue, changes, content) => {
      if ((0, _utils.isEmpty)(newValue)) {
        return "Start Date can't be blank";
      }

      let goalDate = (0, _objectUtils.assignSingleProperty)(content, changes, 'goalDate');

      if (goalDate && goalDate <= newValue) {
        return 'Start Date has to be before Goal Date';
      }

      return true;
    },
    journalTemplateId: (_key, newValue, _oldValue, changes, content) => {
      let duplicateJournalId = (0, _objectUtils.assignSingleProperty)(content, changes, 'duplicateJournalId');

      if ((0, _utils.isEmpty)(newValue) && (0, _utils.isEmpty)(duplicateJournalId)) {
        return "Journal Template can't be blank";
      }

      return true;
    },
    goalDate: (0, _validators.validatePresence)({
      presence: true,
      message: "Goal Date can't be blank"
    }),
    mainGoalTypeId: (0, _validators.validatePresence)({
      presence: true,
      message: "Goal Type can't be blank"
    }),
    mainGoalAmount: (0, _validators.validateNumber)({
      gt: 0,
      message: 'Goal Amount must be greater than 0'
    }),
    secondaryOneTimeGoalAmount: (key, newValue, oldValue, changes, content) => {
      let createSecondaryOneTimeGoal = (0, _objectUtils.assignSingleProperty)(content, changes, 'createSecondaryOneTimeGoal');
      let mainGoalTypeId = (0, _objectUtils.assignSingleProperty)(content, changes, 'mainGoalTypeId');

      if (createSecondaryOneTimeGoal && (mainGoalTypeId === null || mainGoalTypeId === void 0 ? void 0 : mainGoalTypeId.toString()) !== _journalGoalUtils.oneTimeGoalTypeId.toString()) {
        if (!(0, _stringUtils.isNumber)(newValue) || Number(newValue) <= 0) {
          return 'One Time Goal Amount must be greater than 0';
        }
      }

      return true;
    }
  };
  _exports.default = _default;
});