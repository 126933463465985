define("donor-elf-web/controllers/funds/fund/news-feed", ["exports", "@ember/object/computed", "@ember/controller", "@ember/object", "@ember/utils", "@ember/service", "donor-elf-web/lib/date-utils", "donor-elf-web/lib/object-utils", "ember-parachute", "ember-changeset", "ember-changeset-validations", "donor-elf-web/validators/date-or-empty", "donor-elf-web/lib/event-type-names"], function (_exports, _computed, _controller, _object, _utils, _service, _dateUtils, _objectUtils, _emberParachute, _emberChangeset, _emberChangesetValidations, _dateOrEmpty, _eventTypeNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.queryParams = void 0;
  const queryParams = new _emberParachute.default({
    from: {
      defaultValue: '',
      refresh: true
    },
    to: {
      defaultValue: '',
      refresh: true
    },
    eventTypeId: {
      defaultValue: '',
      refresh: true
    },
    page: {
      defaultValue: 1,
      refresh: true
    },
    pageSize: {
      defaultValue: 50,
      refresh: true
    }
  });
  _exports.queryParams = queryParams;
  const filterValidation = {
    from: (0, _dateOrEmpty.default)(),
    to: (0, _dateOrEmpty.default)()
  };

  var _default = _controller.default.extend(queryParams.Mixin, {
    formatDateService: (0, _service.inject)(),
    fundsService: (0, _service.inject)(),
    from: '',
    to: '',
    eventTypeId: '',
    page: 1,
    pageSize: 50,
    filterExpanded: false,
    filterChangeset: null,
    loading: false,
    error: null,
    totalPages: 0,
    queryParamsChanged: (0, _computed.or)('queryParamsState.{from,to,eventTypeId,page,pageSize}.changed'),

    setup({
      queryParams
    }) {
      this.fetchData(queryParams);
    },

    queryParamsDidChange({
      shouldRefresh,
      queryParams
    }) {
      // if any query param with `refresh: true` is changed, `shouldRefresh` is `true`
      if (shouldRefresh) {
        this.fetchData(queryParams);
      }
    },

    reset(event, isExiting) {
      if (isExiting) {
        this.resetQueryParams();
        (0, _object.setProperties)(this, {
          filterChangeset: null,
          filterExpanded: false,
          error: null
        });
      }
    },

    fetchData(queryParams) {
      (0, _object.setProperties)(this, {
        loading: true,
        error: null
      });
      let filter = {};
      let filterRecord = {};

      if (!(0, _utils.isEmpty)(queryParams.from)) {
        filter.from = queryParams.from;
        filterRecord.from = _dateUtils.default.deserializeDateOnly(queryParams.from);
      }

      if (!(0, _utils.isEmpty)(queryParams.to)) {
        filter.to = queryParams.to;
        filterRecord.to = _dateUtils.default.deserializeDateOnly(queryParams.to);
      }

      if (!(0, _utils.isEmpty)(queryParams.eventTypeId)) {
        filter.eventTypeId = queryParams.eventTypeId;
        filterRecord.eventTypeId = queryParams.eventTypeId;
      }

      filter.offset = (+queryParams.page - 1) * +queryParams.pageSize;
      filterRecord.page = queryParams.page;
      filter.limit = +queryParams.pageSize;
      filterRecord.pageSize = queryParams.pageSize;
      let filterChangeset = new _emberChangeset.default(filterRecord, (0, _emberChangesetValidations.default)(filterValidation), filterValidation);
      (0, _object.set)(this, 'filterChangeset', filterChangeset);
      return (0, _object.get)(this, 'fundsService').confirmValidFundToken().then(() => {
        return (0, _object.get)(this, 'store').query('event', {
          filter: _objectUtils.default.decamelizeKeys(filter)
        }).then(records => {
          let totalCount = (0, _object.get)(records, 'meta.total_count');

          if ((0, _utils.isEmpty)(totalCount)) {
            totalCount = '0';
          }

          let totalPages = Math.ceil(+totalCount / queryParams.pageSize);
          (0, _object.setProperties)(this, {
            events: records,
            totalPages: totalPages,
            loading: false
          });
        });
      }, error => {
        (0, _object.set)(this, 'error', error);
      });
    },

    eventTypeOptions: (0, _object.computed)(function () {
      let options = [{
        label: 'All',
        val: ''
      }];
      Object.keys(_eventTypeNames.eventTypeNames).forEach(eventTypeId => {
        options.push({
          label: _eventTypeNames.eventTypeNames[eventTypeId],
          val: eventTypeId
        });
      });
      return options;
    }),
    filterMessage: (0, _object.computed)('filterChangeset.{from,to,eventTypeId}', function () {
      let messages = [];
      let {
        formatDateService,
        filterChangeset
      } = (0, _object.getProperties)(this, 'formatDateService', 'filterChangeset');
      let {
        from,
        to,
        eventTypeId
      } = (0, _object.getProperties)(filterChangeset, 'from', 'to', 'eventTypeId');

      if (!(0, _utils.isEmpty)(from)) {
        if (!(0, _utils.isEmpty)(to)) {
          messages.push(`Events between ${formatDateService.format(from)} and ${formatDateService.format(to)}`);
        } else {
          messages.push(`Events after ${formatDateService.format(from)}`);
        }
      } else if (!(0, _utils.isEmpty)(to)) {
        messages.push(`Events before ${formatDateService.format(to)}`);
      }

      if (!(0, _utils.isEmpty)(eventTypeId)) {
        messages.push(`'${_eventTypeNames.eventTypeNames[eventTypeId]}' type`);
      }

      if (messages.length === 0) {
        return 'All events';
      }

      return messages.join(', ');
    }),
    actionsMenuItems: (0, _object.computed)('filterExpanded', function () {
      return [{
        label: (0, _object.get)(this, 'filterExpanded') ? 'Collapse Filter' : 'Adjust Filter',
        svgJar: 'filter',
        onClick: () => {
          this.send('toggleExpanded');
        }
      }];
    }),
    actions: {
      applyFilter() {
        let filterChangeset = (0, _object.get)(this, 'filterChangeset');

        if ((0, _object.get)(filterChangeset, 'isPristine')) {
          alert('No filter values have changed');
          return;
        }

        (0, _object.setProperties)(this, {
          from: _dateUtils.default.serializeToDateOnly((0, _object.get)(filterChangeset, 'from'), {
            invalidValue: ''
          }),
          to: _dateUtils.default.serializeToDateOnly((0, _object.get)(filterChangeset, 'to'), {
            invalidValue: ''
          }),
          eventTypeId: (0, _object.get)(filterChangeset, 'eventTypeId'),
          page: 1
        });
      },

      changePage(page) {
        (0, _object.set)(this, 'page', page);
      },

      toggleExpanded() {
        this.toggleProperty('filterExpanded');
      }

    }
  });

  _exports.default = _default;
});