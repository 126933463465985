define("donor-elf-web/templates/components/delete-form", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "hzklHYz5",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[18,1,null],[1,\"\\n  \"],[10,0],[14,0,\"mt-4\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-danger\"],[16,\"disabled\",[29,[[52,[33,3,[\"isRunning\"]],\"disable\"]]]],[4,[38,4],[[30,0],\"delete\"],null],[12],[1,\"\\n      \"],[1,[28,[35,5],[\"trash\"],null]],[1,\"\\n      Yes, Delete\\n    \"],[13],[1,\"\\n\"],[41,[33,6],[[[1,\"      \"],[6,[39,7],null,[[\"class\",\"route\"],[\"btn btn-link ml-3\",[33,6]]],[[\"default\"],[[[[1,\"Cancel\"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[11,\"button\"],[24,0,\"btn btn-link ml-3\"],[4,[38,4],[[30,0],\"cancel\"],null],[12],[1,\"\\n        Cancel\\n      \"],[13],[1,\"\\n\"]],[]]],[41,[33,3,[\"isRunning\"]],[[[1,\"      \"],[10,0],[14,0,\"alert alert-info mt-4\"],[12],[1,\"\\n        Deleting...\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]]]]]],[\"&default\"],false,[\"de-form-card\",\"yield\",\"if\",\"deleteTask\",\"action\",\"svg-jar\",\"cancelRoute\",\"link-to\"]]",
    "moduleName": "donor-elf-web/templates/components/delete-form.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});