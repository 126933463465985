define("donor-elf-web/templates/funds/fund/more/mailchimp/connected-to-fund/delete", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "W1HyyDWN",
    "block": "[[[6,[39,0],null,[[\"cancelRoute\",\"deleteTask\"],[\"funds.fund.more.mailchimp.connected-to-fund\",[33,1]]],[[\"default\"],[[[[1,\"  \"],[10,2],[12],[1,\"\\n    Are you sure you want to disconnect DonorElf from your \"],[10,\"strong\"],[12],[1,[34,2]],[13],[1,\" list in MailChimp?\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"delete-form\",\"delete\",\"listName\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/more/mailchimp/connected-to-fund/delete.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});