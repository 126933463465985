define("donor-elf-web/routes/admin/funds/fund", ["exports", "@ember/routing/route", "@ember/object", "rsvp", "@ember/service"], function (_exports, _route, _object, _rsvp, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    fundsService: (0, _service.inject)(),
    store: (0, _service.inject)(),
    storeCache: (0, _service.inject)(),

    beforeModel() {
      return (0, _object.get)(this, 'fundsService').confirmValidFundToken();
    },

    model(params) {
      let storeCache = (0, _object.get)(this, 'storeCache');

      let promise = _rsvp.default.hash({
        fund: this.store.findRecord('admin/fund', params.id),
        userFundsForFund: this.store.query('admin/user-fund', {
          filter: {
            fund_id: params.id
          }
        }),
        indexUsers: storeCache.indexSingleBy('admin/user', 'id')
      });

      return promise.then(data => {
        let fund = data.fund;
        return {
          record: fund,
          users: data.userFundsForFund.map(userFund => data.indexUsers[userFund.userId])
        };
      });
    }

  });

  _exports.default = _default;
});