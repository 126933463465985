define("donor-elf-web/routes/funds", ["exports", "@ember/routing/route", "@ember/object", "@ember/service", "donor-elf-web/lib/logger", "ember-simple-auth/mixins/authenticated-route-mixin", "donor-elf-web/adapters/expired-fund-token-error", "@ember/utils"], function (_exports, _route, _object, _service, _logger, _authenticatedRouteMixin, _expiredFundTokenError, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_authenticatedRouteMixin.default, {
    currentUser: (0, _service.inject)(),
    router: (0, _service.inject)(),
    fundsService: (0, _service.inject)(),

    beforeModel() {
      this._super(...arguments);

      return this.currentUser.load().then(() => {
        if (this.currentUser.coachingOnly) {
          this.transitionTo('coaching');
        }

        if ((0, _utils.isPresent)(this.currentUser.afterLoginRoute)) {
          this.transitionTo(this.currentUser.afterLoginRoute);
        }
      });
    },

    actions: {
      error(error, transition) {
        if (error instanceof _expiredFundTokenError.default) {
          _logger.default.error(`invalid fund token while going to route: ${transition.intent.name}. From route: ${(0, _object.get)(this, 'router.currentRouteName')}`);
        }

        return true;
      }

    }
  });

  _exports.default = _default;
});