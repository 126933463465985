define("donor-elf-web/templates/components/report-actions", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "JYX0aUrW",
    "block": "[[[8,[39,0],null,[[\"@verticalPosition\",\"@horizontalPosition\"],[\"below\",\"auto-right\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"Trigger\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[11,\"button\"],[16,0,[29,[\"btn \",[52,[51,[30,2]],\"btn-sm\"],\" btn-outline-brand\"]]],[24,4,\"button\"],[17,3],[4,[38,2],[[30,0,[\"templateDidInsert\"]]],null],[4,[38,3],[[30,0,[\"templateWillDestroy\"]]],null],[12],[1,\"\\n      Actions \"],[1,[28,[35,4],[\"angle-down\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[30,1,[\"Content\"]],[[24,0,\"report-actions-dropdown-content\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[10,\"ul\"],[14,0,\"list-group\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,4]],null]],null],null,[[[1,\"        \"],[11,\"li\"],[24,0,\"list-group-item cursor-pointer\"],[24,\"role\",\"button\"],[4,[38,7],[\"click\",[28,[37,8],[[30,0,[\"handleOnClick\"]],[30,5],[30,1]],null]],null],[12],[1,\"\\n          \"],[10,0],[14,0,\"d-flex\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"report-actions-dropdown-svg-container\"],[12],[1,[28,[35,4],[[30,5,[\"svgJar\"]]],null]],[13],[1,\"\\n            \"],[10,0],[14,0,\"tw-whitespace-no-wrap\"],[12],[1,[30,5,[\"label\"]]],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[5]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"dd\",\"@regularSizeButton\",\"&attrs\",\"@menuItems\",\"item\"],false,[\"basic-dropdown\",\"unless\",\"did-insert\",\"will-destroy\",\"svg-jar\",\"each\",\"-track-array\",\"on\",\"fn\"]]",
    "moduleName": "donor-elf-web/templates/components/report-actions.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});