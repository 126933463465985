define("donor-elf-web/templates/components/textarea-auto-expand", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "eEQ/G6tp",
    "block": "[[[11,\"textarea\"],[17,1],[24,\"rows\",\"1\"],[16,2,[30,2]],[4,[38,0],[[30,0,[\"templateDidInsert\"]]],null],[4,[38,1],[\"input\",[30,0,[\"setHeight\"]]],null],[4,[38,1],[\"change\",[28,[37,2],[[30,0,[\"handleChange\"]]],null]],null],[12],[13]],[\"&attrs\",\"@value\"],false,[\"did-insert\",\"on\",\"fn\"]]",
    "moduleName": "donor-elf-web/templates/components/textarea-auto-expand.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});