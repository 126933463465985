define("donor-elf-web/modifiers/debounce-on", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let DEFAULT_DELAY = 500; // {{debounce-on 'input' this.someFunction delay=500}}
  // delay is optional. default is 500 milliseconds

  var _default = (0, _emberModifier.modifier)(function debounceOn(element, [eventName, action], {
    delay = DEFAULT_DELAY
  }) {
    let timeoutId = null;

    let handler = e => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(() => {
        action(e);
      }, delay);
    };

    element.addEventListener(eventName, handler);
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      element.removeEventListener(eventName, handler);
    };
  });

  _exports.default = _default;
});