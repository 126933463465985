define("donor-elf-web/components/power-menu", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "fuzzysort", "@ember/utils", "@ember/runloop"], function (_exports, _component, _tracking, _object, _service, _fuzzysort, _utils, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let _class = (_class2 = class _class2 extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "abilities", _descriptor, this);

      _initializerDefineProperty(this, "currentUser", _descriptor2, this);

      _initializerDefineProperty(this, "fundGoalService", _descriptor3, this);

      _initializerDefineProperty(this, "fundsService", _descriptor4, this);

      _initializerDefineProperty(this, "powerMenu", _descriptor5, this);

      _initializerDefineProperty(this, "router", _descriptor6, this);

      _initializerDefineProperty(this, "settings", _descriptor7, this);

      _initializerDefineProperty(this, "show", _descriptor8, this);

      _initializerDefineProperty(this, "q", _descriptor9, this);

      _initializerDefineProperty(this, "currentlySelectedIndex", _descriptor10, this);

      _defineProperty(this, "previousUrls", [null, null]);
    }

    get filteredItems() {
      if ((0, _utils.isEmpty)(this.q)) {
        return this.powerMenu.currentOptions.map(x => {
          return {
            obj: x,
            target: x.label,
            notFiltered: true
          };
        });
      } else {
        return _fuzzysort.default.go(this.q, this.powerMenu.currentOptions, {
          keys: ['searchByPrepared', 'labelPrepared']
        });
      }
    }

    handleOptionChosen(option) {
      if (option.fn) {
        option.fn();
      } else if (option.models) {
        if (Array.isArray(option.models)) {
          this.router.transitionTo(option.route, ...option.models);
        } else {
          this.router.transitionTo(option.route, option.models);
        }
      } else {
        this.router.transitionTo(option.route);
      }

      this.show = false;
    }

    setCurrentlySelectedIndex(index) {
      this.currentlySelectedIndex = index;
    }

    handleSearchInput(e) {
      this.currentlySelectedIndex = 0;
      this.q = e.target.value;
    }

    handleSearchKeydown(e) {
      if (e.which === 38) {
        // up arrow
        if (this.currentlySelectedIndex > 0) {
          this.currentlySelectedIndex -= 1;
          this.confirmSelectedItemIsInViewPort();
        }
      } else if (e.which === 40) {
        // down arrow
        if (this.currentlySelectedIndex < this.powerMenu.currentOptions.length - 1) {
          this.currentlySelectedIndex += 1;
          this.confirmSelectedItemIsInViewPort();
        }
      } else if (e.which === 13) {
        // enter
        this.handleOptionChosen(this.filteredItems[this.currentlySelectedIndex].obj);
      }
    }

    confirmSelectedItemIsInViewPort() {
      (0, _runloop.next)(() => {
        let selected = this.listGroupEl.querySelector('[aria-selected="true"]');
        let listGroupElScrollTop = this.listGroupEl.scrollTop;

        if (selected.offsetTop + 40 > listGroupElScrollTop + this.listGroupEl.clientHeight) {
          this.listGroupEl.scrollTop = selected.offsetTop - 60;
        } else if (selected.offsetTop < listGroupElScrollTop) {
          this.listGroupEl.scrollTop = selected.offsetTop - 150;
        }
      });
    }

    handleListGroupInserted(el) {
      this.listGroupEl = el;
    }

    handleSearchInserted(el) {
      this.currentlySelectedIndex = 0;
      this.q = '';
      setTimeout(() => {
        el.focus();
      }, 50);
    }

    handleRouteDidChange() {
      this.previousUrls = [this.previousUrls[1], this.router.currentURL];
    }

    templateDidInsert() {
      document.addEventListener('keydown', this.watchForHotKey);
      this.powerMenu.appendOptionsFn('go-back', this.powerMenuOptions);
      this.router.on('routeDidChange', this, this.handleRouteDidChange);
    }

    templateWillDestroy() {
      document.removeEventListener('keydown', this.watchForHotKey);
      this.powerMenu.removeOptions('go-back');
    }

    powerMenuOptions() {
      return [{
        label: 'Go Back',
        fn: () => {
          if (this.previousUrls[0]) {
            this.router.transitionTo(this.previousUrls[0]);
          }
        }
      }];
    }

    hide() {
      this.show = false;
    }

    watchForHotKey(e) {
      // show modal when the "cmd/ctrl + k" combination is selected
      if (e.which === 75 && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        e.stopImmediatePropagation();
        this.show = true;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "abilities", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "fundGoalService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "powerMenu", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "show", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "q", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "currentlySelectedIndex", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "handleOptionChosen", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleOptionChosen"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setCurrentlySelectedIndex", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "setCurrentlySelectedIndex"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleSearchInput", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleSearchInput"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleSearchKeydown", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleSearchKeydown"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleListGroupInserted", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleListGroupInserted"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleSearchInserted", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleSearchInserted"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "templateDidInsert"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "templateWillDestroy"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "powerMenuOptions", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "powerMenuOptions"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "hide", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "hide"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "watchForHotKey", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "watchForHotKey"), _class2.prototype)), _class2);

  _exports.default = _class;
});