define("donor-elf-web/controllers/funds/fund/more/donor-hub/connect/downloading", ["exports", "@ember/controller", "donor-elf-web/config/environment", "@ember/object", "@ember/service"], function (_exports, _controller, _environment, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    fundsService: (0, _service.inject)(),
    actions: {
      onFundUploadComplete() {
        let fundId = (0, _object.get)(this, 'fundsService.selectedFund.id');
        window.location.href = `${_environment.default.routerRootURL}funds/${fundId}`;
      }

    }
  });

  _exports.default = _default;
});