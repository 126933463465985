define("donor-elf-web/components/new-web-version", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.webVersion.newVersionAvailable}}
    <div class='position-fixed tw-bottom-0 tw-right-0 mb-2 mr-2'>
      <BasicDropdown
      as |dd|>
      <dd.Trigger>
        <button class='btn btn-warning' type='button'>
          Update Available
        </button>
      </dd.Trigger>
      <dd.Content class='de-dropdown-content p-2 tw-max-w-xs -tw-mt-3'>
        <p>
          DonorElf just got better. Refresh your browser to get the latest changes.
        </p>
        <div>
          <button
            {{on 'click' this.handleRefresh}}
            class='btn btn-brand'
            type='button'
          >
            Refresh my Browser
          </button>
        </div>
      </dd.Content>
      </BasicDropdown>
    </div>
  {{/if}}
  */
  {
    "id": "sj3C5Wap",
    "block": "[[[41,[30,0,[\"webVersion\",\"newVersionAvailable\"]],[[[1,\"  \"],[10,0],[14,0,\"position-fixed tw-bottom-0 tw-right-0 mb-2 mr-2\"],[12],[1,\"\\n    \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"Trigger\"]],null,null,[[\"default\"],[[[[1,\"\\n      \"],[10,\"button\"],[14,0,\"btn btn-warning\"],[14,4,\"button\"],[12],[1,\"\\n        Update Available\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[30,1,[\"Content\"]],[[24,0,\"de-dropdown-content p-2 tw-max-w-xs -tw-mt-3\"]],null,[[\"default\"],[[[[1,\"\\n      \"],[10,2],[12],[1,\"\\n        DonorElf just got better. Refresh your browser to get the latest changes.\\n      \"],[13],[1,\"\\n      \"],[10,0],[12],[1,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-brand\"],[24,4,\"button\"],[4,[38,2],[\"click\",[30,0,[\"handleRefresh\"]]],null],[12],[1,\"\\n          Refresh my Browser\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"]],[1]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"dd\"],false,[\"if\",\"basic-dropdown\",\"on\"]]",
    "moduleName": "donor-elf-web/components/new-web-version.hbs",
    "isStrictMode": false
  });

  let NewWebVersionComponent = (_class = class NewWebVersionComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "webVersion", _descriptor, this);
    }

    handleRefresh() {
      location.reload();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "webVersion", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handleRefresh", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleRefresh"), _class.prototype)), _class);
  _exports.default = NewWebVersionComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, NewWebVersionComponent);
});