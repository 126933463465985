define("donor-elf-web/controllers/funds/fund/custom-reports/oms-monthly-summary", ["exports", "@ember/controller", "donor-elf-web/lib/array-utils", "ember-cached-decorator-polyfill", "@ember/service", "@ember/object"], function (_exports, _controller, _arrayUtils, _emberCachedDecoratorPolyfill, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    Highcharts,
    moment
  } = window;
  let CustomReportsOmsMonthlySummaryController = (_class = class CustomReportsOmsMonthlySummaryController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "formatAmountService", _descriptor, this);

      _defineProperty(this, "model", void 0);
    }

    renderChart(el) {
      setTimeout(() => {
        Highcharts.chart(el, this.chartSettings);
      }, 50);
    }

    get descendingItems() {
      return (0, _arrayUtils.sortByProperties)(this.model.records, ['startDate:desc']);
    }

    get ascendingItems() {
      return (0, _arrayUtils.sortByProperties)(this.model.records, ['startDate:asc']);
    }

    get revenueSeriesData() {
      return this.ascendingItems.map(item => {
        return {
          y: item.totalRevenue,
          item
        };
      });
    }

    get expenseSeriesData() {
      return this.ascendingItems.map(item => {
        return {
          y: item.totalExpense * -1,
          item
        };
      });
    }

    get balanceSeriesData() {
      return this.ascendingItems.map(item => {
        return {
          y: item.closingBalance,
          item
        };
      });
    }

    get categories() {
      return this.ascendingItems.map(x => moment(x.startDate).format('MMM YYYY'));
    }

    get chartSettings() {
      let self = this;
      let minYAxis = Math.min(...[0, ...this.model.records.map(x => x.closingBalance), ...this.model.records.map(x => x.totalRevenue), ...this.model.records.map(x => x.totalExpense * -1)]);
      let series = [{
        name: 'Revenue',
        color: '#5cb85c',
        data: this.revenueSeriesData
      }, {
        name: 'Expense',
        color: '#d9534f',
        data: this.expenseSeriesData
      }, {
        name: 'Balance',
        color: '#0b68a1',
        type: 'line',
        data: this.balanceSeriesData
      }];
      return {
        chart: {
          type: 'column',
          height: 300
        },
        title: null,
        credits: {
          enabled: false
        },
        xAxis: {
          categories: this.categories,
          tickInterval: 3,
          tickLength: 0,
          labels: {
            autoRotation: false,
            align: 'left',
            y: 13
          }
        },
        yAxis: {
          min: minYAxis,
          maxPadding: 0.0,
          endOnTick: false,
          title: {
            text: null
          }
        },
        legend: {
          enabled: false
        },
        tooltip: {
          formatter: function () {
            return `${this.x} ${this.series.name}: <b>${self.formatAmountService.format(this.y)}</b>`;
          }
        },
        plotOptions: {
          column: {
            groupPadding: 0.2,
            pointPadding: 0.01
          }
        },
        series
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "formatAmountService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "renderChart", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "renderChart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "ascendingItems", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "ascendingItems"), _class.prototype)), _class);
  _exports.default = CustomReportsOmsMonthlySummaryController;
});