define("donor-elf-web/templates/funds/fund/journals/index-loading", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "NE+cucFa",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,null,null],[1,\"\\n\"]],[]]]]]],[],false,[\"form-card\",\"de-loading\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/journals/index-loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});