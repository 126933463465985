define("donor-elf-web/helpers/can-edit-line-item", ["exports", "@ember/component/helper", "@ember/service"], function (_exports, _helper, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _helper.default.extend({
    abilities: (0, _service.inject)(),

    compute([lineItem]) {
      return this.abilities.hasAbility('edit_all_transactions') || this.abilities.hasAbility('edit_manual_transactions') && lineItem.isManual;
    }

  });

  _exports.default = _default;
});