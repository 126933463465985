define("donor-elf-web/templates/funds/fund/more/donor-hub/connect/credentials", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ETFN39x6",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[1,[28,[35,1],null,[[\"changeset\",\"property\",\"label\",\"showValidationErrors\"],[[33,2,[\"changeset\"]],\"username\",\"Username\",[33,3]]]]],[1,\"\\n\\n  \"],[1,[28,[35,1],null,[[\"changeset\",\"property\",\"label\",\"inputType\",\"showValidationErrors\"],[[33,2,[\"changeset\"]],\"password\",\"Password\",\"password\",[33,3]]]]],[1,\"\\n\\n\"],[41,[33,5],[[[1,\"    \"],[10,0],[14,0,\"alert alert-success mt-4\"],[12],[1,\"\\n      \"],[10,2],[12],[1,\"\\n        Sweet! DonorElf will now download your new gifts from the DonorHub\\n        server on a nightly basis.\\n      \"],[13],[1,\"\\n      \"],[10,2],[12],[1,\"\\n        Your gifts are currently being downloaded, and you should see them in\\n        DonorElf within the next 5 to 10 minutes.\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[6,[39,6],null,[[\"class\",\"route\"],[\"btn btn-warning\",\"funds.fund.dashboard\"]],[[\"default\"],[[[[1,\"      Go to my Dashboard\\n\"]],[]]]]]],[]],[[[1,\"    \"],[10,0],[14,0,\"mt-4\"],[12],[1,\"\\n      \"],[10,\"button\"],[15,\"onclick\",[28,[37,7],[[33,8]],null]],[14,0,\"btn btn-warning\"],[12],[1,\"\\n        Save\\n      \"],[13],[1,\"\\n\"],[6,[39,6],null,[[\"class\",\"route\"],[\"btn btn-link ml-4\",\"funds.fund.more.donor-hub\"]],[[\"default\"],[[[[1,\"        Cancel\\n\"]],[]]]]],[1,\"    \"],[13],[1,\"\\n\"],[41,[33,8,[\"isRunning\"]],[[[1,\"      \"],[10,0],[14,0,\"alert alert-info mt-4\"],[12],[1,\"\\n        Saving...\\n      \"],[13],[1,\"\\n\"]],[]],null]],[]]],[41,[33,9],[[[1,\"    \"],[1,[28,[35,10],null,[[\"error\",\"class\"],[[33,9],\"mt-4\"]]]],[1,\"\\n\"]],[]],null]],[]]]]]],[],false,[\"de-form-card\",\"form-group\",\"model\",\"showValidationErrors\",\"if\",\"saved\",\"link-to\",\"perform\",\"save\",\"error\",\"generic-error\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/more/donor-hub/connect/credentials.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});