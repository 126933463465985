define("donor-elf-web/helpers/close-dropdown", ["exports", "@ember/component/helper", "@ember/debug"], function (_exports, _helper, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function closeDropdown([dropdown, handler]) {
    var _dropdown$actions;

    (false && !(typeof (dropdown === null || dropdown === void 0 ? void 0 : (_dropdown$actions = dropdown.actions) === null || _dropdown$actions === void 0 ? void 0 : _dropdown$actions.close) === 'function') && (0, _debug.assert)(`Expected '${dropdown}' to be a Ember Basic Dropdown object. (close-dropdown dd)`, typeof (dropdown === null || dropdown === void 0 ? void 0 : (_dropdown$actions = dropdown.actions) === null || _dropdown$actions === void 0 ? void 0 : _dropdown$actions.close) === 'function'));
    (false && !(!handler || typeof handler === 'function') && (0, _debug.assert)(`Expected '${handler}' to be a function, if present.`, !handler || typeof handler === 'function'));
    return function (...args) {
      dropdown.actions.close();
      if (handler) handler(...args);
    };
  }

  var _default = (0, _helper.helper)(closeDropdown);

  _exports.default = _default;
});