define("donor-elf-web/initializers/leaflet", ["exports", "donor-elf-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  /* global L */
  function initialize()
  /* container, application */
  {
    L.Icon.Default.imagePath = _environment.default.leafletImagePath;
  }

  var _default = {
    name: 'leaflet-assets-cdn',
    initialize: initialize,
    after: 'leaflet-assets'
  };
  _exports.default = _default;
});