define("donor-elf-web/lib/journal-goal-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.oneTimeGoalTypeId = _exports.annualGoalTypeId = _exports.monthlyGoalTypeId = _exports.journalGoalLabels = void 0;
  let journalGoalLabels = {
    1: 'Monthly',
    2: 'Annual',
    3: 'One Time'
  };
  _exports.journalGoalLabels = journalGoalLabels;
  let monthlyGoalTypeId = 1;
  _exports.monthlyGoalTypeId = monthlyGoalTypeId;
  let annualGoalTypeId = 2;
  _exports.annualGoalTypeId = annualGoalTypeId;
  let oneTimeGoalTypeId = 3;
  _exports.oneTimeGoalTypeId = oneTimeGoalTypeId;
});