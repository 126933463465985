define("donor-elf-web/templates/admin/users/add/save", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "8/g7HZPa",
    "block": "[[[10,0],[14,0,\"row\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"col-12 col-lg-6\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"alert alert-info\"],[12],[1,\"\\n      Saving the user...\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "donor-elf-web/templates/admin/users/add/save.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});