define("donor-elf-web/routes/funds/fund/more/mailchimp/connected-to-fund", ["exports", "@ember/routing/route", "ember-changeset"], function (_exports, _route, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class MailchimpConnectedToFundRoute extends _route.default {
    model() {
      let model = this.modelFor('funds.fund.more.mailchimp');
      let mailchimpFundSetting = model.mailchimpSetting.fundSettings.firstObject;
      let mailchimpFundSettingChangeset = new _emberChangeset.default(mailchimpFundSetting);
      return { ...model,
        mailchimpFundSetting,
        mailchimpFundSettingChangeset
      };
    }

  }

  _exports.default = MailchimpConnectedToFundRoute;
});