define("donor-elf-web/templates/demo", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "zL1oOlKZ",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[10,2],[12],[1,\"\\n    If you don't mind, we would love to get your name and email so we can follow\\n    up with you.\\n  \"],[13],[1,\"\\n  \"],[10,2],[14,0,\"text-muted tw-text-sm\"],[12],[1,\"\\n    (Don't worry, we won't spam you or share your information. Feel\\n    free to skip entering your info if you want.)\\n  \"],[13],[1,\"\\n\\n  \"],[1,[28,[35,1],null,[[\"changeset\",\"property\",\"label\",\"focusOnInsert\"],[[33,2,[\"changeset\"]],\"fullName\",\"Your Name\",true]]]],[1,\"\\n\\n  \"],[1,[28,[35,1],null,[[\"changeset\",\"property\",\"label\"],[[33,2,[\"changeset\"]],\"email\",\"Email\"]]]],[1,\"\\n\\n\"],[1,\"\\n  \"],[10,0],[14,0,\"mt-4\"],[12],[1,\"\\n    \"],[10,\"button\"],[15,\"onclick\",[28,[37,3],[[33,4]],null]],[14,0,\"btn btn-warning\"],[12],[1,\"\\n      View the Demo Account\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"form-card\",\"form-group\",\"model\",\"perform\",\"save\"]]",
    "moduleName": "donor-elf-web/templates/demo.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});