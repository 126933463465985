define("donor-elf-web/modifiers/keep-in-view", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _emberModifier.modifier)((el, _posParams, namedArgs = {}) => {
    let parentEl = el.closest(namedArgs.parentQuerySelector);

    if (parentEl) {
      if (parentEl.scrollTop > el.offsetTop - el.clientHeight || parentEl.clientHeight + parentEl.scrollTop < el.offsetTop) {
        setTimeout(() => {
          el.scrollIntoView(false);
        });
      }
    }
  });

  _exports.default = _default;
});