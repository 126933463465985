define("donor-elf-web/controllers/funds/fund/groups/add", ["exports", "@ember/controller", "@ember/object", "ember-concurrency"], function (_exports, _controller, _object, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    save: (0, _emberConcurrency.task)(function* () {
      let changeset = (0, _object.get)(this, 'model.changeset');
      yield changeset.validate();

      if ((0, _object.get)(changeset, 'isValid')) {
        this.transitionToRoute('funds.fund.groups.group.add', (0, _object.get)(changeset, 'name'));
      }
    }).drop()
  });

  _exports.default = _default;
});