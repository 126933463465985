define("donor-elf-web/routes/funds/fund/campaigns/campaign/index", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class CampaignsCampaignIndexRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", {
        filters: {
          replace: true
        }
      });
    }

    async model() {
      let campaignModel = this.modelFor('funds.fund.campaigns.campaign');
      let campaignGoals = (await this.store.query('campaign-goal', {
        filter: {
          campaign_id: campaignModel.campaign.id
        }
      })).toArray();
      return { ...campaignModel,
        campaignGoals
      };
    }

  }

  _exports.default = CampaignsCampaignIndexRoute;
});