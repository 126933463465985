define("donor-elf-web/lib/models/journal-digest-info", ["exports", "@glimmer/tracking", "donor-elf-web/lib/models/journal-report-intervarsity", "donor-elf-web/lib/store-utils", "donor-elf-web/lib/models/journal-info", "donor-elf-web/lib/models/journal-contact-info", "donor-elf-web/lib/models/journal-report-default", "donor-elf-web/lib/journal-utils"], function (_exports, _tracking, _journalReportIntervarsity, _storeUtils, _journalInfo, _journalContactInfo, _journalReportDefault, _journalUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let JournalDigestInfo = (_class = class JournalDigestInfo {
    constructor(journalSlim, store, fundsService, formatDateService) {
      _defineProperty(this, "journalSlim", void 0);

      _defineProperty(this, "journalInfo", void 0);

      _defineProperty(this, "store", void 0);

      _defineProperty(this, "formatDateService", void 0);

      _initializerDefineProperty(this, "loading", _descriptor, this);

      _initializerDefineProperty(this, "journalDashboard", _descriptor2, this);

      _initializerDefineProperty(this, "journalContactInfos", _descriptor3, this);

      _initializerDefineProperty(this, "journalReport", _descriptor4, this);

      _initializerDefineProperty(this, "reportDefault", _descriptor5, this);

      _initializerDefineProperty(this, "reportIntervarsity", _descriptor6, this);

      _initializerDefineProperty(this, "showProjectedGoal", _descriptor7, this);

      _defineProperty(this, "engageStage", void 0);

      _defineProperty(this, "askStage", void 0);

      _defineProperty(this, "mainGoalAndDecisions", void 0);

      _defineProperty(this, "viewJournalUrl", void 0);

      this.journalSlim = journalSlim;
      this.store = store;
      this.formatDateService = formatDateService;
      this.viewJournalUrl = fundsService.viewFundUrl(journalSlim.fundId, {
        urlPath: `journals/${journalSlim.id}`
      });
    }

    get selectedIntervalItems() {
      if (this.reportDefault) {
        return this.reportDefault.intervalItems.slice(Math.max(this.reportDefault.intervalItems.length - 6, 0));
      }

      return [];
    }

    get firstGoalDecisionPercent() {
      var _this$journalInfo, _this$reportDefault;

      if (this.loading) {
        return undefined;
      }

      let firstGoal = (_this$journalInfo = this.journalInfo) === null || _this$journalInfo === void 0 ? void 0 : _this$journalInfo.goals[0];

      if (firstGoal == null) {
        return undefined;
      }

      let lastIntervalItem = (_this$reportDefault = this.reportDefault) === null || _this$reportDefault === void 0 ? void 0 : _this$reportDefault.intervalItems[this.reportDefault.intervalItems.length - 1];

      if (lastIntervalItem == null) {
        return undefined;
      }

      return lastIntervalItem.decisions[firstGoal.id].yesCumulativePercent;
    }

    async load() {
      let digest = (await this.store.query('all-funds/journal-digest', {
        filter: {
          journal_ids: [this.journalSlim.id]
        }
      })).toArray()[0];
      this.journalDashboard = digest.journalDashboard;
      let journalInfo = new _journalInfo.default(digest.journal);
      this.journalInfo = journalInfo;
      this.engageStage = journalInfo.sortedStages.find(stage => stage.name.includes('Engage')) || journalInfo.sortedStages[0];
      this.engageStage.weeklyGoal = 30;
      this.askStage = journalInfo.askStage;
      this.askStage.weeklyGoal = 4;
      let journalContactInfos = [];
      await (0, _storeUtils.queryWithPages)({
        store: this.store,
        modelName: 'all-funds/journal-contact-detail',
        queryOptions: {
          filter: {
            journal_id: this.journalSlim.id
          }
        },
        pageSize: 200,
        pageCallback: args => {
          journalContactInfos.push(...args.pageRecords.map(journalContactDetailModel => new _journalContactInfo.default({
            journalContactDetailModel,
            journalInfo
          })));
        }
      });
      this.journalContactInfos = journalContactInfos;
      this.journalReport = (await this.store.query('all-funds/journal-report', {
        filter: {
          journal_id: this.journalSlim.id
        }
      })).toArray()[0];

      if (this.journalInfo.journal.reportName === 'intervarsity') {
        this.reportIntervarsity = new _journalReportIntervarsity.default({
          journalInfo,
          journalReport: this.journalReport,
          journalContactInfos,
          formatDateService: this.formatDateService
        });
        let defaultReport = (await this.store.query('all-funds/journal-report', {
          filter: {
            journal_id: this.journalSlim.id,
            report_name: 'default'
          }
        })).toArray()[0];
        this.reportDefault = new _journalReportDefault.default({
          journalInfo,
          journalReportModel: defaultReport,
          formatDateService: this.formatDateService
        });
      }

      let mainGoal = journalInfo.goals[0];
      this.mainGoalAndDecisions = {
        goal: mainGoal,
        intervalCumulativeAmounts: (0, _journalUtils.GetIntervalCumulativeAmounts)(this.reportDefault.intervalDates, this.reportDefault.yesDecisions[mainGoal.id])
      };
      this.loading = false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "journalDashboard", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "journalContactInfos", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "journalReport", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "reportDefault", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "reportIntervarsity", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "showProjectedGoal", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class);
  _exports.default = JournalDigestInfo;
});