define("donor-elf-web/components/line-item/approve-campaign", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "@ember/utils"], function (_exports, _component, _templateFactory, _component2, _object, _service, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes>
    <div>
      {{#if @row.approved}}
        <div class='tw-font-bold'>
          {{this.campaign.name}}
        </div>
        <div>
          <a
            {{on 'click' this.edit}}
            class='icon-link tw-ml-2px'
            href='#'
          >
            {{svg-jar 'edit'}}
          </a>
        </div>
      {{else}}
        <Form::Underline::Select
          @changeset={{this.changeset}}
          @property='campaignId'
          @options={{@campaignSelectOptions}}
          @placeholder='None'
          @showValidationErrors={{@row.showValidationErrors}}
          @dropdownMatchTriggerWidth={{true}}
        />
        <button
          {{on 'click' this.approve}}
          class='btn btn-xs btn-outline-brand mt-2'
          type='button'
        >
          {{#if @row.approving}}
            Approving...
          {{else}}
            Approve
          {{/if}}
        </button>
        {{#if @row.approveError}}
          <GenericError
            @error={{@row.approveError}}
            @useLeftBorderCard={{true}}
            class='mt-2'
          />
        {{/if}}
      {{/if}}
    </div>
  </div>
  */
  {
    "id": "y6c2QhdE",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,0],[12],[1,\"\\n\"],[41,[30,2,[\"approved\"]],[[[1,\"      \"],[10,0],[14,0,\"tw-font-bold\"],[12],[1,\"\\n        \"],[1,[30,0,[\"campaign\",\"name\"]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[12],[1,\"\\n        \"],[11,3],[24,0,\"icon-link tw-ml-2px\"],[24,6,\"#\"],[4,[38,1],[\"click\",[30,0,[\"edit\"]]],null],[12],[1,\"\\n          \"],[1,[28,[35,2],[\"edit\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,3],null,[[\"@changeset\",\"@property\",\"@options\",\"@placeholder\",\"@showValidationErrors\",\"@dropdownMatchTriggerWidth\"],[[30,0,[\"changeset\"]],\"campaignId\",[30,3],\"None\",[30,2,[\"showValidationErrors\"]],true]],null],[1,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-xs btn-outline-brand mt-2\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"approve\"]]],null],[12],[1,\"\\n\"],[41,[30,2,[\"approving\"]],[[[1,\"          Approving...\\n\"]],[]],[[[1,\"          Approve\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\"],[41,[30,2,[\"approveError\"]],[[[1,\"        \"],[8,[39,4],[[24,0,\"mt-2\"]],[[\"@error\",\"@useLeftBorderCard\"],[[30,2,[\"approveError\"]],true]],null],[1,\"\\n\"]],[]],null]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@row\",\"@campaignSelectOptions\"],false,[\"if\",\"on\",\"svg-jar\",\"form/underline/select\",\"generic-error\"]]",
    "moduleName": "donor-elf-web/components/line-item/approve-campaign.hbs",
    "isStrictMode": false
  });

  let LineItemApproveCampaignComponent = (_class = class LineItemApproveCampaignComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "campaignService", _descriptor, this);
    }

    get campaign() {
      let campaignId = this.changeset.get('campaignId');

      if ((0, _utils.isPresent)(campaignId)) {
        return this.campaignService.campaignsById[campaignId];
      } else {
        return undefined;
      }
    }

    get lineItem() {
      return this.args.row.lineItem;
    }

    get changeset() {
      return this.args.row.campaignLineItemChangeset;
    }

    edit() {
      this.changeset.set('approved', false);
    }

    async approve() {
      if (this.args.row.approving) {
        return;
      }

      this.args.row.approving = true;
      let {
        changeset
      } = this;
      changeset.set('approved', true);
      await changeset.validate();

      if (changeset.get('isValid')) {
        this.args.row.showValidationErrors = false;

        try {
          await changeset.save();
          let {
            campaign,
            lineItem
          } = this;

          if (campaign && lineItem.contactId) {
            this.campaignService.reloadCampaignContactsIfContactIdNotInCampaign({
              contactId: lineItem.contactId,
              campaignId: campaign.id
            });
          }
        } catch (error) {
          this.changeset.set('approved', false);
          this.args.row.approveError = error;
        }
      } else {
        changeset.set('approved', false);
        this.args.row.showValidationErrors = true;
      }

      this.args.row.approving = false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "campaignService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "edit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "edit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "approve", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "approve"), _class.prototype)), _class);
  _exports.default = LineItemApproveCampaignComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LineItemApproveCampaignComponent);
});