define("donor-elf-web/templates/admin/users/show/funds/add", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "is6Np+Fz",
    "block": "[[[10,0],[14,0,\"row\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"col-12 col-lg-6\"],[12],[1,\"\\n\"],[6,[39,0],null,null,[[\"default\"],[[[[41,[33,2],[[[1,\"        Saving the fund permissions...\\n\"]],[]],[[[1,\"        \"],[6,[39,3],null,[[\"class\",\"route\"],[\"float-right\",\"admin.users.show.info\"]],[[\"default\"],[[[[1,\"Back to User\"]],[]]]]],[1,\"\\n        \"],[10,\"h4\"],[14,0,\"mb-4\"],[12],[1,\"Select Fund Permissions\"],[13],[1,\"\\n\"],[41,[33,4],[[[1,\"          \"],[10,0],[14,0,\"alert alert-danger\"],[12],[1,\"\\n            \"],[1,[34,4]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[1,[28,[35,5],null,[[\"funds\",\"userFunds\",\"onSave\"],[[33,6,[\"funds\"]],[33,6,[\"userFunds\"]],[28,[37,7],[[30,0],\"save\"],null]]]]],[1,\"\\n\"]],[]]]],[]]]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[],false,[\"de-card\",\"if\",\"saving\",\"link-to\",\"errorMessage\",\"admin-user-add-fund\",\"model\",\"action\"]]",
    "moduleName": "donor-elf-web/templates/admin/users/show/funds/add.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});