define("donor-elf-web/routes/funds/fund/more/karani-import", ["exports", "@ember/routing/route", "@ember/object", "@ember/service"], function (_exports, _route, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    navbarSettings: (0, _service.inject)(),

    beforeModel() {
      (0, _object.set)(this, 'navbarSettings.title', 'Import from Karani');
    },

    model() {
      return {
        selectedFile: null,
        includePledges: true,
        includeTasks: true
      };
    }

  });

  _exports.default = _default;
});