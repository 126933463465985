define("donor-elf-web/controllers/admin/fund-groups/fund-group/edit", ["exports", "@ember/controller", "@ember/object", "@ember/service", "ember-concurrency"], function (_exports, _controller, _object, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    fundsService: (0, _service.inject)(),
    storeCache: (0, _service.inject)(),
    cancelRoute: (0, _object.computed)('model.record.id', function () {
      if ((0, _object.get)(this, 'model.record.isNew')) {
        return 'admin.fund-groups';
      }

      return 'admin.fund-groups.fund-group';
    }),
    save: (0, _emberConcurrency.task)(function* () {
      let changeset = (0, _object.get)(this, 'model.changeset');
      yield changeset.validate();

      if ((0, _object.get)(changeset, 'isValid')) {
        try {
          yield (0, _object.get)(this, 'fundsService').confirmValidFundToken();
          yield changeset.save();
          this.transitionToRoute('admin.fund-groups.fund-group', (0, _object.get)(this, 'model.record.id'));
        } catch (e) {
          if ((0, _object.get)(this, 'model.record.errors.length') > 0) {
            (0, _object.get)(this, 'model.record.errors').forEach(({
              attribute,
              message
            }) => {
              changeset.pushErrors(attribute, message);
            });
          } else {
            throw e;
          }
        }
      }
    }).drop()
  });

  _exports.default = _default;
});