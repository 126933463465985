define("donor-elf-web/controllers/funds/fund/more/notifications/edit", ["exports", "@ember/object/computed", "@ember/controller", "@ember/object", "@ember/utils", "@ember/service", "ember-concurrency", "donor-elf-web/lib/event-type-names"], function (_exports, _computed, _controller, _object, _utils, _service, _emberConcurrency, _eventTypeNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    currentUser: (0, _service.inject)(),
    fundsService: (0, _service.inject)(),
    store: (0, _service.inject)(),
    eventTypeNames: (0, _object.computed)(function () {
      let names = { ..._eventTypeNames.eventTypeNames
      }; // don't show Donor Info Changed event

      delete names[4];
      return names;
    }),
    sortFundsBy: ['fund.name'],
    sortedFundItems: (0, _computed.sort)('model.allFundItems', 'sortFundsBy'),
    filteredFundItems: (0, _object.computed)('sortedFunds.[]', 'fundsQuery', function () {
      let {
        sortedFundItems,
        fundsQuery
      } = (0, _object.getProperties)(this, 'sortedFundItems', 'fundsQuery');
      let scrubbedFundsQuery = (fundsQuery || '').toLowerCase().trim();

      if ((0, _utils.isEmpty)(scrubbedFundsQuery)) {
        return sortedFundItems;
      }

      return sortedFundItems.filter(fundItem => {
        return (0, _object.get)(fundItem, 'fund.nameLowerCase').indexOf(scrubbedFundsQuery) >= 0;
      });
    }),
    selectedFundItems: (0, _computed.filterBy)('sortedFundItems', 'isSelected', true),
    error: null,
    saveComplete: false,
    showValidationErrors: false,
    fundsQuery: '',
    fundsQueryInput: '',

    reset() {
      (0, _object.setProperties)(this, {
        error: null,
        saveComplete: false,
        showValidationErrors: false,
        fundsQuery: '',
        fundsQueryInput: ''
      });
    },

    debounceApplyFilter: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(500);
      this.send('applyFilter');
    }).restartable(),
    save: (0, _emberConcurrency.task)(function* () {
      try {
        yield (0, _object.get)(this, 'fundsService').confirmValidFundToken();
        let changeset = (0, _object.get)(this, 'model.changeset');
        (0, _object.set)(changeset, 'fundIds', (0, _object.get)(this, 'selectedFundItems').map(item => {
          return (0, _object.get)(item, 'fund.id');
        }));
        (0, _object.set)(changeset, 'emailTo', (0, _object.get)(changeset, 'emailToString').split(','));
        yield changeset.validate();
        (0, _object.set)(this, 'showValidationErrors', true);

        if ((0, _object.get)(changeset, 'isValid')) {
          let excludeEventTypeIds = [];

          for (let eventTypeId in _eventTypeNames.eventTypeNames) {
            if ((0, _object.get)(this, `model.selectedEventsChangeset.${eventTypeId}`) === false) {
              excludeEventTypeIds.push(+eventTypeId);
            }
          }

          (0, _object.set)(changeset, 'excludeEventTypeIds', excludeEventTypeIds);
          yield changeset.save();
          this.send('refreshNotifications');
          (0, _object.set)(this, 'saveComplete', true);
        }
      } catch (error) {
        (0, _object.set)(this, 'error', error);
      }
    }).drop(),
    actions: {
      applyFilter() {
        (0, _object.set)(this, 'fundsQuery', (0, _object.get)(this, 'fundsQueryInput'));
      }

    }
  });

  _exports.default = _default;
});