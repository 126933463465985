define("donor-elf-web/components/contact/details/dates", ["exports", "@ember/component", "@ember/template-factory", "@ember/object/computed", "@glimmer/component"], function (_exports, _component, _templateFactory, _computed, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Card ...attributes>
    {{#if (has-ability 'edit_contact')}}
      <div class='float-right'>
        <LinkTo
          @route='funds.fund.contacts.contact.dates.edit'
          @model='add'
          class='btn btn-sm btn-outline-warning'
        >
          {{svg-jar 'plus'}}
          Add
        </LinkTo>
      </div>
    {{/if}}
    <h4 class='card-title'>Dates</h4>
    <table class='table table-sm'>
      <thead>
        <tr>
          <th>Label</th>
          <th>Date</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {{#each this.sortedDates as |date|}}
          <tr>
            <td>{{date.label}}</td>
            <td>{{format-date date.originalDate fourYear=true}}</td>
            <td class='text-right'>
              {{#if (has-ability 'edit_contact')}}
                <LinkTo
                  @route='funds.fund.contacts.contact.dates.edit'
                  @model={{date.id}}
                  class='icon-link ml-2'
                >
                  {{svg-jar 'edit'}}
                </LinkTo>
              {{/if}}
            </td>
          </tr>
        {{else}}
          <tr><td class='text-muted'>None</td></tr>
        {{/each}}
      </tbody>
    </table>
  </Card>
  
  */
  {
    "id": "TvbyRjig",
    "block": "[[[8,[39,0],[[17,1]],null,[[\"default\"],[[[[1,\"\\n\"],[41,[28,[37,2],[\"edit_contact\"],null],[[[1,\"    \"],[10,0],[14,0,\"float-right\"],[12],[1,\"\\n      \"],[8,[39,3],[[24,0,\"btn btn-sm btn-outline-warning\"]],[[\"@route\",\"@model\"],[\"funds.fund.contacts.contact.dates.edit\",\"add\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,4],[\"plus\"],null]],[1,\"\\n        Add\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,\"h4\"],[14,0,\"card-title\"],[12],[1,\"Dates\"],[13],[1,\"\\n  \"],[10,\"table\"],[14,0,\"table table-sm\"],[12],[1,\"\\n    \"],[10,\"thead\"],[12],[1,\"\\n      \"],[10,\"tr\"],[12],[1,\"\\n        \"],[10,\"th\"],[12],[1,\"Label\"],[13],[1,\"\\n        \"],[10,\"th\"],[12],[1,\"Date\"],[13],[1,\"\\n        \"],[10,\"th\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"sortedDates\"]]],null]],null],null,[[[1,\"        \"],[10,\"tr\"],[12],[1,\"\\n          \"],[10,\"td\"],[12],[1,[30,2,[\"label\"]]],[13],[1,\"\\n          \"],[10,\"td\"],[12],[1,[28,[35,7],[[30,2,[\"originalDate\"]]],[[\"fourYear\"],[true]]]],[13],[1,\"\\n          \"],[10,\"td\"],[14,0,\"text-right\"],[12],[1,\"\\n\"],[41,[28,[37,2],[\"edit_contact\"],null],[[[1,\"              \"],[8,[39,3],[[24,0,\"icon-link ml-2\"]],[[\"@route\",\"@model\"],[\"funds.fund.contacts.contact.dates.edit\",[30,2,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[28,[35,4],[\"edit\"],null]],[1,\"\\n              \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],[[[1,\"        \"],[10,\"tr\"],[12],[10,\"td\"],[14,0,\"text-muted\"],[12],[1,\"None\"],[13],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&attrs\",\"date\"],false,[\"card\",\"if\",\"has-ability\",\"link-to\",\"svg-jar\",\"each\",\"-track-array\",\"format-date\"]]",
    "moduleName": "donor-elf-web/components/contact/details/dates.hbs",
    "isStrictMode": false
  });

  /*
    attrs
      - contact
      - model - array of dates
  */
  let DatesComponent = (_dec = (0, _computed.sort)('args.model', 'sortBy'), (_class = class DatesComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "sortBy", ['originalDate:asc']);

      _initializerDefineProperty(this, "sortedDates", _descriptor, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sortedDates", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = DatesComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DatesComponent);
});