define("donor-elf-web/controllers/admin/users/show/funds/edit", ["exports", "@ember/object/computed", "@ember/controller", "@ember/object", "@ember/service", "ember-concurrency"], function (_exports, _computed, _controller, _object, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    store: (0, _service.inject)(),
    storeCache: (0, _service.inject)(),
    errorMessage: null,
    filteredRoles: (0, _computed.filter)('model.roles', function (role) {
      return (0, _object.get)(role, 'name') !== 'Org-Admin';
    }),
    rolesSortBy: ['name'],
    sortedRoles: (0, _computed.sort)('filteredRoles', 'rolesSortBy'),
    roleOptions: (0, _computed.mapBy)('sortedRoles', 'name'),
    save: (0, _emberConcurrency.task)(function* () {
      let changeset = (0, _object.get)(this, 'model.userFundChangeset');
      let user = (0, _object.get)(this, 'model.user');

      if ((0, _object.get)(changeset, 'role') === (0, _object.get)(user, 'role')) {
        (0, _object.set)(changeset, 'role', null);
      }

      yield changeset.save();
      this.transitionToRoute('admin.users.show');
    }).drop()
  });

  _exports.default = _default;
});