define("donor-elf-web/models/coaching/report", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    individual: belongsTo('coaching/individual'),
    startDate: attr('date-only'),
    endDate: attr('date-only'),
    sectionFieldValues: attr(),
    completed: attr('boolean'),
    coachNotes: attr('string'),
    feedback: attr('string')
  });

  _exports.default = _default;
});