define("donor-elf-web/routes/funds/fund/question", ["exports", "@ember/routing/route", "@ember/object", "@ember/service", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators"], function (_exports, _route, _object, _service, _emberChangeset, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const customerQuestionValidation = {
    text: (0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    })
  };
  const demoQuestionValidation = {
    name: (0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    }),
    email: (0, _validators.validateFormat)({
      type: 'email',
      message: "Not a valid email address"
    }),
    text: (0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    })
  };

  var _default = _route.default.extend({
    fundsService: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    store: (0, _service.inject)(),

    beforeModel() {
      (0, _object.set)(this, 'navbarSettings.title', 'Ask a Question');
      return (0, _object.get)(this, 'fundsService').confirmValidFundToken();
    },

    model() {
      let validation = (0, _object.get)(this, 'currentUser.isDemoUser') ? demoQuestionValidation : customerQuestionValidation;
      let record = (0, _object.get)(this, 'store').createRecord('question');
      return {
        record: record,
        changeset: new _emberChangeset.default(record, (0, _emberChangesetValidations.default)(validation), validation)
      };
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.reset();
      }
    }

  });

  _exports.default = _default;
});