define("donor-elf-web/components/contact/bulk-actions/edit-property/existing/readonly-cant-edit", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Contact::BulkActions::Header @contactIds={{@contactIds}}>
    Edit Property
  </Contact::BulkActions::Header>
  <Modal::HeaderSecond>
    {{@propertyInfo.l}}
  </Modal::HeaderSecond>
  <div class='p-3'>
    <Alert::Info>
      Sorry, you can't edit this property because it comes from your
      organization's system.
    </Alert::Info>
  </div>
  <Modal::Footer class='d-flex'>
    <button
      {{on 'click' @onClose}}
      class='btn btn-xs btn-warning'
      type='button'
    >
      Close
    </button>
  </Modal::Footer>
  */
  {
    "id": "/G6G1g+G",
    "block": "[[[8,[39,0],null,[[\"@contactIds\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n  Edit Property\\n\"]],[]]]]],[1,\"\\n\"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n  \"],[1,[30,2,[\"l\"]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[10,0],[14,0,\"p-3\"],[12],[1,\"\\n  \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n    Sorry, you can't edit this property because it comes from your\\n    organization's system.\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[8,[39,3],[[24,0,\"d-flex\"]],null,[[\"default\"],[[[[1,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-xs btn-warning\"],[24,4,\"button\"],[4,[38,4],[\"click\",[30,3]],null],[12],[1,\"\\n    Close\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@contactIds\",\"@propertyInfo\",\"@onClose\"],false,[\"contact/bulk-actions/header\",\"modal/header-second\",\"alert/info\",\"modal/footer\",\"on\"]]",
    "moduleName": "donor-elf-web/components/contact/bulk-actions/edit-property/existing/readonly-cant-edit.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});