define("donor-elf-web/templates/funds/fund/import/intervarsity-prospects/complete", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "+BB/g81E",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[10,\"h4\"],[14,0,\"mb-4\"],[12],[1,\"Upload Complete!\"],[13],[1,\"\\n  \"],[8,[39,1],[[24,0,\"btn btn-warning\"]],[[\"@route\"],[\"funds.fund.dashboard\"]],[[\"default\"],[[[[1,\"\\n    Go to the Dashboard\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"form-card\",\"link-to\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/import/intervarsity-prospects/complete.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});