define("donor-elf-web/lib/review-item-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reviewTypeLabels = _exports.reviewTypeNames = _exports.reviewTypes = void 0;
  let reviewTypes = {
    'contactDuplicate': 1,
    'giftBeforePledgeStartDate': 2,
    'duplicatePledges': 3
  };
  _exports.reviewTypes = reviewTypes;
  let reviewTypeNames = {};
  _exports.reviewTypeNames = reviewTypeNames;
  Object.keys(reviewTypes).forEach(key => {
    reviewTypeNames[reviewTypes[key]] = key;
  });
  let reviewTypeLabels = {};
  _exports.reviewTypeLabels = reviewTypeLabels;
  reviewTypeLabels[reviewTypes.contactDuplicate] = 'Contact Duplicate';
  reviewTypeLabels[reviewTypes.giftBeforePledgeStartDate] = 'Gift Before Pledge Start Date';
  reviewTypeLabels[reviewTypes.duplicatePledges] = 'Possible Duplicate Pledges';
});