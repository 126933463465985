define("donor-elf-web/routes/funds/fund/more/tnt-connect-import/data-sync/complete", ["exports", "@ember/routing/route", "@ember/object"], function (_exports, _route, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    // model comes from the parent funds.fund.more.tnt-connect-import.data-sync route
    // model()
    redirect(model) {
      if ((0, _object.get)(model, 'selectedFile') == null) {
        // confirm a file has been selected
        this.transitionTo('funds.fund.more.tnt-connect-import.data-sync');
      }
    }

  });

  _exports.default = _default;
});