define("donor-elf-web/templates/funds/fund/groups/group/delete", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "GhTOOonQ",
    "block": "[[[6,[39,0],null,[[\"deleteTask\"],[[33,1]]],[[\"default\"],[[[[1,\"  \"],[10,2],[12],[1,\"\\n    Are you sure you want to delete the \"],[10,\"b\"],[12],[1,[33,2,[\"group\"]]],[13],[1,\" group?\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"delete-form\",\"delete\",\"model\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/groups/group/delete.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});