define("donor-elf-web/validations/journal-goal", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    goalTypeId: (0, _validators.validatePresence)({
      presence: true,
      message: "Type can't be blank"
    }),
    amount: (0, _validators.validateNumber)({
      gt: 0,
      integer: true,
      allowBlank: false,
      message: "Amount must be positive whole number"
    })
  };
  _exports.default = _default;
});