define("donor-elf-web/routes/coaching/individuals/individual/edit", ["exports", "donor-elf-web/lib/route-reset-controller", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators", "@ember/object", "@ember/utils", "@ember/service", "donor-elf-web/lib/date-utils"], function (_exports, _routeResetController, _emberChangeset, _emberChangesetValidations, _validators, _object, _utils, _service, _dateUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const validation = {
    startDate: (0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    }),
    goalDate: (0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    }),
    frequencyTimespan: (0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    }),
    frequencyNumber: (0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    })
  };

  var _default = _routeResetController.default.extend({
    abilities: (0, _service.inject)(),

    beforeModel(transition) {
      if ((0, _object.get)(this, 'abilities').noCoachingAbility('edit_individual')) {
        transition.abort();
      }
    },

    model() {
      let record = this.modelFor('coaching.individuals.individual').record;
      let changeset = new _emberChangeset.default(record, (0, _emberChangesetValidations.default)(validation), validation); // have to stringify & parse `staticInfo` and `sections` because they're arrays which the changeset can't handle

      let staticInfo = (0, _object.get)(changeset, 'staticInfo') || [];
      (0, _object.set)(changeset, 'staticInfo', JSON.parse(JSON.stringify(staticInfo)));
      let sections = (0, _object.get)(changeset, 'sections') || [];
      (0, _object.set)(changeset, 'sections', JSON.parse(JSON.stringify(sections)));

      if ((0, _utils.isEmpty)((0, _object.get)(changeset, 'startDate'))) {
        (0, _object.set)(changeset, 'startDate', new Date());
      }

      if ((0, _utils.isEmpty)((0, _object.get)(changeset, 'frequencyNumber'))) {
        (0, _object.setProperties)(changeset, {
          frequencyNumber: 1,
          frequencyTimespan: 'week'
        });
      }

      if (!(0, _utils.isEmpty)((0, _object.get)(changeset, 'goalDate'))) {
        (0, _object.set)(changeset, 'goalDateString', _dateUtils.default.serializeToDateOnly((0, _object.get)(changeset, 'goalDate')));
      }

      return {
        record,
        changeset
      };
    }

  });

  _exports.default = _default;
});