define("donor-elf-web/templates/funds/fund/more/karani-import/processing", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "InIEjZcu",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[41,[33,2],[[[1,\"    \"],[10,\"h4\"],[12],[1,\"Upload Error\"],[13],[1,\"\\n    \"],[10,0],[14,0,\"alert alert-danger mb-3\"],[12],[1,\"\\n      Whoops! An error occurred when we were processing the file from Karani.\\n      Sorry about that.\\n    \"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      Do you mind sending us an email to let us know you weren't able to\\n      upload the file from Karani. And please attach the file to the email.\\n      We'll then take a look and fix the upload.\\n    \"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      Please send the email to \"],[10,3],[14,6,\"mailto:support@donorelf.com\"],[12],[1,\"support@donorelf.com\"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \\n    \"],[6,[39,3],null,[[\"class\",\"route\",\"model\"],[\"btn btn-warning mt-3\",\"funds.fund.dashboard\",[33,4,[\"selectedFund\",\"id\"]]]],[[\"default\"],[[[[1,\"Dashboard\"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"h4\"],[12],[1,\"Uploading\"],[13],[1,\"\\n    \"],[1,[28,[35,5],null,[[\"file\",\"onUploadComplete\",\"onUploadError\",\"class\"],[[33,6,[\"selectedFile\"]],[28,[37,7],[[30,0],\"handleS3UploadComplete\"],null],[28,[37,7],[[30,0],\"handleS3UploadError\"],null],\"mb-5\"]]]],[1,\"\\n\\n    \"],[10,\"h4\"],[12],[1,\"Processing\"],[13],[1,\"\\n    \"],[1,[28,[35,8],null,[[\"check\",\"onError\",\"onComplete\"],[[33,9],[28,[37,7],[[30,0],\"onFundUploadError\"],null],[28,[37,7],[[30,0],\"onFundUploadComplete\"],null]]]]],[1,\"\\n\"]],[]]]],[]]]]]],[],false,[\"de-form-card\",\"if\",\"hasError\",\"link-to\",\"fundsService\",\"s3-upload-progress\",\"model\",\"action\",\"fund-upload-progress\",\"checkFundUploadProgress\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/more/karani-import/processing.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});