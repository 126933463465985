define("donor-elf-web/routes/funds/fund/contacts/upload/file", ["exports", "@ember/routing/route", "@ember/object"], function (_exports, _route, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    afterModel(model) {
      (0, _object.set)(model, 'currentStep', '1');
    }

  });

  _exports.default = _default;
});