define("donor-elf-web/routes/admin/funds/fund/edit", ["exports", "@ember/routing/route", "@ember/object", "@ember/service", "ember-changeset", "ember-changeset-validations", "donor-elf-web/validations/admin/fund"], function (_exports, _route, _object, _service, _emberChangeset, _emberChangesetValidations, _fund) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    abilities: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),

    beforeModel(transition) {
      if (this.abilities.noAbility('admin_edit_funds')) {
        transition.abort();
        return;
      }

      (0, _object.set)(this, 'navbarSettings.title', 'Edit Fund');
    },

    model() {
      let parentModel = this.modelFor('admin.funds.fund');
      let changeset = new _emberChangeset.default((0, _object.get)(parentModel, 'record'), (0, _emberChangesetValidations.default)(_fund.default), _fund.default);
      return {
        record: (0, _object.get)(parentModel, 'record'),
        changeset: changeset
      };
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.reset();
      }
    }

  });

  _exports.default = _default;
});