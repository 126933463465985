define("donor-elf-web/controllers/funds/fund/dates", ["exports", "@ember/object/computed", "@ember/controller", "@ember/object"], function (_exports, _computed, _controller, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    moment
  } = window;

  var _default = _controller.default.extend({
    noDates: (0, _computed.equal)('model.length', 0),
    sixMonthsFromNow: (0, _object.computed)(function () {
      return moment().add(6, 'months').toDate();
    }),
    upcomingDates: (0, _object.computed)('model.[]', 'sixMonthsFromNow', function () {
      let sixMonthsFromNow = (0, _object.get)(this, 'sixMonthsFromNow');
      return (0, _object.get)(this, 'model').filter(record => {
        return (0, _object.get)(record, 'nextDate') <= sixMonthsFromNow;
      });
    }),
    sortUpcomingDatesBy: ['nextDate:asc'],
    sortedUpcomingDates: (0, _computed.sort)('upcomingDates', 'sortUpcomingDatesBy'),
    recentlyPassedDates: (0, _object.computed)('model.[]', 'sixMonthsFromNow', function () {
      let sixMonthsFromNow = (0, _object.get)(this, 'sixMonthsFromNow');
      return (0, _object.get)(this, 'model').filter(record => {
        return (0, _object.get)(record, 'nextDate') > sixMonthsFromNow;
      });
    }),
    sortRecentlyPassedDatesBy: ['nextDate:desc'],
    sortedRecentlyPassedDates: (0, _computed.sort)('recentlyPassedDates', 'sortRecentlyPassedDatesBy')
  });

  _exports.default = _default;
});