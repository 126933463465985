define("donor-elf-web/templates/funds/fund/journals/summaries/view", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "vUhEQrbs",
    "block": "[[[11,0],[4,[38,0],[[30,0,[\"templateDidInsert\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"loading\"]],[[[1,\"    \"],[8,[39,2],[[24,0,\"row-bottom-margin\"]],null,[[\"default\"],[[[[1,\"\\n      \"],[8,[39,3],null,null,null],[1,\"\\n      \"],[10,0],[14,0,\"progress\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"progress-bar bg-brand\"],[15,5,[30,0,[\"progressBarStyle\"]]],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"items\"]]],null]],null],null,[[[1,\"    \"],[8,[39,6],[[24,0,\"mb-3\"]],[[\"@journal\",\"@journalGoal\",\"@fund\",\"@intervalSummaries\"],[[30,1,[\"journal\"]],[30,1,[\"journalGoal\"]],[30,1,[\"fund\"]],[30,1,[\"intervalSummaries\"]]]],null],[1,\"\\n\"]],[1,2]],null],[13]],[\"item\",\"index\"],false,[\"did-insert\",\"if\",\"card\",\"de-loading\",\"each\",\"-track-array\",\"journal/summary/card\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/journals/summaries/view.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});