define("donor-elf-web/controllers/funds/fund/tasks/v2/summary", ["exports", "@ember/controller", "ember-parachute", "@ember/object", "@ember/object/computed", "@ember/string", "ember-changeset", "donor-elf-web/lib/object-utils", "donor-elf-web/lib/date-utils", "@ember/service", "@ember/utils"], function (_exports, _controller, _emberParachute, _object, _computed, _string, _emberChangeset, _objectUtils, _dateUtils, _service, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    moment
  } = window;
  const queryParams = new _emberParachute.default({
    from: {
      defaultValue: null,
      refresh: true
    },
    to: {
      defaultValue: null,
      refresh: true
    }
  });

  var _default = _controller.default.extend(queryParams.Mixin, {
    contactsCache: (0, _service.inject)(),
    fundsService: (0, _service.inject)(),
    formatDateService: (0, _service.inject)(),
    media: (0, _service.inject)(),
    store: (0, _service.inject)(),
    taskService: (0, _service.inject)(),
    from: null,
    to: null,
    filterExpanded: false,
    filter: null,
    filterChangeset: null,
    loading: false,
    queryParamsChanged: (0, _computed.or)('queryParamsState.{from,to}.changed'),

    setup({
      queryParams
    }) {
      this.fetchData(queryParams);
    },

    queryParamsDidChange({
      shouldRefresh,
      queryParams
    }) {
      if (shouldRefresh) {
        this.fetchData(queryParams);
      }
    },

    reset(event, isExiting) {
      if (isExiting) {
        this.resetQueryParams();
        (0, _object.setProperties)(this, {
          filter: null,
          filterChangeset: null,
          filterExpanded: false,
          from: null,
          to: null
        });
      }
    },

    fetchData(queryParams) {
      let filter = {
        offset: 0,
        limit: 50,
        completed: 'Completed',
        fund_ids: ['selected'],
        from: queryParams.from,
        to: queryParams.to
      };

      if ((0, _utils.isEmpty)(filter.from)) {
        filter.from = moment().add({
          months: -11
        }).startOf('month').format('YYYY-MM-DD');
      }

      if ((0, _utils.isEmpty)(filter.to)) {
        filter.to = moment().format('YYYY-MM-DD');
      }

      let filterRecord = {
        from: _dateUtils.default.deserializeDateOnly(filter.from),
        to: _dateUtils.default.deserializeDateOnly(filter.to)
      };
      (0, _object.setProperties)(this, {
        page: 1,
        totalPages: 20,
        tasks: [],
        summaryInfo: {
          actions: {},
          mediums: {}
        },
        filter,
        filterChangeset: new _emberChangeset.default(filterRecord),
        progressBarStyle: (0, _string.htmlSafe)('width: 5%'),
        loading: true
      });
      this.loadDataInPages();
    },

    async loadDataInPages() {
      await this.fundsService.confirmValidFundToken();
      let records = await this.store.query('v2-task', {
        filter: _objectUtils.default.decamelizeKeys(this.filter)
      });
      (0, _object.set)(this, 'tasks', this.tasks.concat(records.toArray()));
      this.page += 1;
      this.filter.offset = (this.page - 1) * this.filter.limit;

      if (records.meta.total_count > this.filter.offset) {
        let progressPercent = Math.max(this.filter.offset * 100 / records.meta.total_count, 5);
        (0, _object.set)(this, 'progressBarStyle', (0, _string.htmlSafe)(`width: ${progressPercent.toFixed(0)}%`));
        this.loadDataInPages();
      } else {
        this.createTableColumnsAndRows();
        (0, _object.setProperties)(this, {
          loading: false,
          totalPages: Math.ceil(Number(records.meta.total_count) / this.filter.limit)
        });
      }
    },

    createTableColumnsAndRows() {
      if (this.tasks.length === 0) {
        return;
      }

      let minDate = this.tasks[0].completedDate;
      let maxDate = this.tasks[0].completedDate;
      let tasksByContactAndMonth = {};
      let summaryInfo = {
        actions: {},
        mediums: {}
      };
      this.tasks.forEach(task => {
        if (tasksByContactAndMonth[task.contactId] == null) {
          tasksByContactAndMonth[task.contactId] = {
            lastCompletedDate: null
          };
        }

        let yearMonth = this.dateToYearAndMonth(task.completedDate);

        if (tasksByContactAndMonth[task.contactId][yearMonth] == null) {
          tasksByContactAndMonth[task.contactId][yearMonth] = [];
        }

        tasksByContactAndMonth[task.contactId][yearMonth].push(task);

        if (summaryInfo.actions[task.actionId] == null) {
          summaryInfo.actions[task.actionId] = 0;
        }

        summaryInfo.actions[task.actionId] += 1;

        if ((0, _utils.isPresent)(task.mediumId)) {
          if (summaryInfo.mediums[task.mediumId] == null) {
            summaryInfo.mediums[task.mediumId] = 0;
          }

          summaryInfo.mediums[task.mediumId] += 1;
        }

        if (task.completedDate < minDate) {
          minDate = task.completedDate;
        }

        if (task.completedDate > maxDate) {
          maxDate = task.completedDate;
        }

        let lastCompletedDate = tasksByContactAndMonth[task.contactId].lastCompletedDate;

        if (lastCompletedDate == null || lastCompletedDate < task.completedDate) {
          tasksByContactAndMonth[task.contactId].lastCompletedDate = task.completedDate;
        }
      });
      let months = [];
      let currentMonth = minDate;
      maxDate = moment(maxDate).endOf('month').toDate();

      while (currentMonth <= maxDate) {
        let yearMonth = this.dateToYearAndMonth(currentMonth);
        months.push(yearMonth);
        currentMonth = moment(currentMonth).add(1, 'month').toDate();
      }

      let rows = [];
      Object.keys(tasksByContactAndMonth).forEach(contactId => {
        let row = {
          contactId,
          contact: this.contactsCache.contactsById[contactId],
          lastCompletedDate: tasksByContactAndMonth[contactId].lastCompletedDate
        };
        months.forEach(month => {
          row[month] = tasksByContactAndMonth[contactId][month];
        });
        rows.push(row);
      });
      let tableColumns = [{
        name: 'Contact',
        valuePath: 'contactId',
        isFixed: 'left',
        width: this.media.isMediumUp ? 200 : 100
      }].concat(months.map(x => {
        return {
          name: moment(`${x}-01`).format("MMM 'YY"),
          valuePath: x,
          width: 250
        };
      }));
      (0, _object.setProperties)(this, {
        tableColumns,
        tableRows: rows,
        summaryInfo: {
          actions: Object.keys(summaryInfo.actions).map(key => {
            return {
              label: this.taskService.actionLabelsById[key],
              value: summaryInfo.actions[key]
            };
          }).sort((a, b) => {
            if (a.value > b.value) {
              return -1;
            } else if (a.value < b.value) {
              return 1;
            } else {
              return 0;
            }
          }),
          mediums: Object.keys(summaryInfo.mediums).map(key => {
            return {
              label: this.taskService.mediumLabelsById[key],
              value: summaryInfo.mediums[key]
            };
          }).sort((a, b) => {
            if (a.value > b.value) {
              return -1;
            } else if (a.value < b.value) {
              return 1;
            } else {
              return 0;
            }
          })
        }
      });
    },

    sortTableRowsBy: Object.freeze(['contact.name:asc']),
    sortedTableRows: (0, _computed.sort)('tableRows', 'sortTableRowsBy'),

    dateToYearAndMonth(date) {
      let splitISO = date.toISOString().split('-');
      return `${splitISO[0]}-${splitISO[1]}`;
    },

    adjustCardHeight() {
      let windowHeight = window.innerHeight;
      let newCardHeight = windowHeight - this.cardElement.offsetHeight + 40;
      this.cardElement.style.height = `${newCardHeight}px`;
    },

    actionsMenuItems: (0, _object.computed)('filterExpanded', function () {
      return [{
        label: this.filterExpanded ? 'Collapse Filter' : 'Adjust Filter',
        svgJar: 'filter',
        onClick: () => {
          this.toggleExpanded();
        }
      }];
    }),
    filterMessage: (0, _object.computed)('from', 'to', function () {
      let {
        from,
        to
      } = this.filter;
      return `Showing tasks completed between ${this.formatDateService.format(from)} and ${this.formatDateService.format(to)}`;
    }),
    templateDidInsert: (0, _object.action)(function (el) {
      this.cardElement = el.querySelector('.card-body');
      this.adjustCardHeight();
    }),
    applyFilter: (0, _object.action)(function () {
      if (this.filterChangeset.get('isPristine')) {
        alert('No filter values have changed');
        return;
      }

      (0, _object.setProperties)(this, {
        from: _dateUtils.default.serializeToDateOnly(this.filterChangeset.get('from'), {
          invalidVlue: null
        }),
        to: _dateUtils.default.serializeToDateOnly(this.filterChangeset.get('to'), {
          invalidVlue: null
        })
      });
    }),
    toggleExpanded: (0, _object.action)(function () {
      this.toggleProperty('filterExpanded');
    }),
    handleTaskDeleted: (0, _object.action)(function (task) {
      this.tasks = this.tasks.filter(x => x.id !== task.id);
      this.createTableColumnsAndRows();
    })
  });

  _exports.default = _default;
});