define("donor-elf-web/templates/coaching/individuals/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "UoU9bECN",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[41,[28,[37,2],[\"create_individual\"],null],[[[6,[39,3],null,[[\"class\",\"route\"],[\"btn btn-warning float-right mb-3\",\"coaching.individuals.copy\"]],[[\"default\"],[[[[1,\"      \"],[1,[28,[35,4],[\"plus\"],null]],[1,\"\\n      Add\\n\"]],[]]]]]],[]],null],[1,\"  \"],[10,\"table\"],[14,0,\"table mt-3 mb-0\"],[12],[1,\"\\n    \"],[10,\"thead\"],[12],[13],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[33,7]],null]],null],null,[[[1,\"        \"],[10,\"tr\"],[12],[1,\"\\n          \"],[10,\"td\"],[12],[1,\"\\n\"],[6,[39,3],null,[[\"route\",\"model\"],[\"coaching.individuals.individual\",[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"              \"],[1,[30,1,[\"name\"]]],[1,\"\\n\"]],[]]]]],[1,\"          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"item\"],false,[\"de-card\",\"if\",\"has-coaching-ability\",\"link-to\",\"svg-jar\",\"each\",\"-track-array\",\"sortedIndividuals\"]]",
    "moduleName": "donor-elf-web/templates/coaching/individuals/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});