define("donor-elf-web/serializers/application", ["exports", "@ember/string", "ember-data/serializers/json-api"], function (_exports, _string, _jsonApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend({
    keyForAttribute: function (attr) {
      return (0, _string.underscore)(attr);
    },
    keyForRelationship: function (attr) {
      return (0, _string.underscore)(attr);
    }
  });

  _exports.default = _default;
});