define("donor-elf-web/adapters/application", ["exports", "@ember/object", "@ember/utils", "@ember/service", "ember-data/adapters/json-api", "ember-simple-auth/mixins/data-adapter-mixin", "donor-elf-web/config/environment", "donor-elf-web/adapters/expired-fund-token-error"], function (_exports, _object, _utils, _service, _jsonApi, _dataAdapterMixin, _environment, _expiredFundTokenError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    moment
  } = window;

  let adapter = _jsonApi.default.extend(_dataAdapterMixin.default, {
    host: _environment.default.apiUrl,
    namespace: 'json-api/v1',
    cacheService: (0, _service.inject)(),
    coachingService: (0, _service.inject)(),
    fundsService: (0, _service.inject)(),
    router: (0, _service.inject)(),
    session: (0, _service.inject)(),
    webVersion: (0, _service.inject)(),

    authorize(xhr) {
      let {
        access_token
      } = (0, _object.get)(this, 'session.data.authenticated');

      if ((0, _utils.isPresent)(access_token)) {
        xhr.setRequestHeader('Authorization', `Bearer ${access_token}`);
      }
    },

    headers: (0, _object.computed)(function () {
      var _this$fundsService$se;

      let headers = {};
      headers.FundId = ((_this$fundsService$se = this.fundsService.selectedFund) === null || _this$fundsService$se === void 0 ? void 0 : _this$fundsService$se.id) || '';
      headers.Fund = this.fundsService.selectedFundToken;
      let coachingToken = (0, _object.get)(this, 'coachingService.selectedIndividualToken');

      if (coachingToken) {
        headers.CoachingIndividual = coachingToken;
      }

      return headers;
    }).volatile(),
    lastExpiredFundTokenError: null,

    handleResponse(status, headers, payload) {
      if (headers['fund-token'] && headers['fund-token'] !== this.fundsService.selectedFundToken) {
        this.fundsService.selectedFundToken = headers['fund-token'];
      }

      if (status === 401 && (0, _object.get)(payload, 'errors') === 'expired_fund_token') {
        (0, _object.set)(this, 'fundsService.selectedFundToken', ''); // prevent infinite loop for ExpiredFundTokenError
        // confirm the last error happened more than a minute ago

        let lastExpiredFundTokenError = (0, _object.get)(this, 'lastExpiredFundTokenError');

        if ((0, _utils.isEmpty)(lastExpiredFundTokenError) || lastExpiredFundTokenError < moment().subtract(1, 'minutes')) {
          (0, _object.set)(this, 'lastExpiredFundTokenError', moment());
          return new _expiredFundTokenError.default();
        }
      } else if (status === 401) {
        this.router.transitionTo('logout');
        return;
      }

      this.webVersion.setCurrentVersion(headers['x-web-version']);
      return this._super(...arguments);
    },

    // defaults namespace to `user` unless the type contains `/`
    pathForType(type) {
      let folder = 'user';
      let folderType = type;

      if (type.indexOf('/') > 0) {
        let splitType = type.split('/');
        folder = splitType[0];
        folderType = splitType[1];
      }

      return this._super(`${folder}/${folderType}`);
    },

    // always force `findAll` to not resolve until the data comes back from the server
    // otherwise it will resolve immediately if findAll was previously called on the model
    shouldReloadAll() {
      return true;
    },

    shouldReloadRecord() {
      return true;
    }

  }); // Hack for now. needed for cookies to be sent from localhost:4200 to localhost:3000


  if (_environment.default.environment === 'development') {
    adapter = adapter.extend({
      ajaxOptions(url, method, options) {
        options = options || {};
        options.xhrFields = {
          withCredentials: true
        };
        return this._super(url, method, options);
      }

    });
  }

  var _default = adapter;
  _exports.default = _default;
});