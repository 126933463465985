define("donor-elf-web/routes/coaching/individuals/individual/report/index", ["exports", "@ember/routing/route", "@ember/object"], function (_exports, _route, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    setupController(controller) {
      this._super(...arguments);

      controller.reset();

      if (Object.keys((0, _object.get)(controller, 'expandedSections')).length === 0) {
        controller.expandAllSections();
      }
    },

    resetController(controller, isExiting) {
      controller.set('preserveScrollPosition', true);

      if (isExiting) {
        controller.reset();
      }
    }

  });

  _exports.default = _default;
});