define("donor-elf-web/helpers/validation-error-message", ["exports", "@ember/component/helper", "@ember/object"], function (_exports, _helper, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function validationErrorMessage([changeset, property]) {
    return ((0, _object.get)(changeset, `error.${property}.validation`) || []).join(', ');
  }

  var _default = (0, _helper.helper)(validationErrorMessage);

  _exports.default = _default;
});