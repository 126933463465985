define("donor-elf-web/models/admin/contact-address-change", ["exports", "@ember-data/model", "@ember/utils"], function (_exports, _model, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AdminContactAddressChangeModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('string'), _dec5 = (0, _model.attr)(), _dec6 = (0, _model.attr)(), _dec7 = (0, _model.attr)('date'), _dec8 = (0, _model.attr)(), _dec9 = (0, _model.attr)(), _dec10 = (0, _model.attr)(), _dec11 = (0, _model.attr)(), (_class = class AdminContactAddressChangeModel extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "entityName", _descriptor, this);

      _initializerDefineProperty(this, "entityHostId", _descriptor2, this);

      _initializerDefineProperty(this, "userName", _descriptor3, this);

      _initializerDefineProperty(this, "userEmail", _descriptor4, this);

      _initializerDefineProperty(this, "oldAddress", _descriptor5, this);

      _initializerDefineProperty(this, "newAddress", _descriptor6, this);

      _initializerDefineProperty(this, "createdAt", _descriptor7, this);

      _initializerDefineProperty(this, "oldPhones", _descriptor8, this);

      _initializerDefineProperty(this, "newPhones", _descriptor9, this);

      _initializerDefineProperty(this, "oldEmails", _descriptor10, this);

      _initializerDefineProperty(this, "newEmails", _descriptor11, this);
    }

    get address1Changed() {
      return this.addressValueChanged('address1');
    }

    get address2Changed() {
      return this.addressValueChanged('address2');
    }

    get cityChanged() {
      return this.addressValueChanged('city');
    }

    get stateChanged() {
      return this.addressValueChanged('state');
    }

    get zipChanged() {
      return this.addressValueChanged('zip');
    }

    addressValueChanged(addressKey) {
      return (this[`oldAddress.${addressKey}`] || '') !== (this[`newAddress.${addressKey}`] || '');
    }

    get addressChanged() {
      let {
        newAddress
      } = this;
      return newAddress != null && Object.keys(newAddress).length > 0;
    }

    get phonesChanged() {
      return (0, _utils.isPresent)(this.newPhones);
    }

    get emailsChanged() {
      return (0, _utils.isPresent)(this.newEmails);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "entityName", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "entityHostId", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "userName", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "userEmail", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "oldAddress", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "newAddress", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "createdAt", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "oldPhones", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "newPhones", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "oldEmails", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "newEmails", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AdminContactAddressChangeModel;
});