define("donor-elf-web/routes/reset-password/token-error", ["exports", "@ember/routing/route", "@ember/object", "donor-elf-web/lib/logger"], function (_exports, _route, _object, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    setupController(controller, model) {
      this._super(...arguments);

      _logger.default.error("Error in reset password", (0, _object.get)(model, 'errors'));
    }

  });

  _exports.default = _default;
});