define("donor-elf-web/controllers/admin/users/show/info/funds", ["exports", "@ember/object/computed", "@ember/controller", "@ember/object", "@ember/service"], function (_exports, _computed, _controller, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    settings: (0, _service.inject)(),
    sortBy: ['fund.name:asc'],
    sortedFunds: (0, _computed.sort)('model.fundPermissions', 'sortBy'),
    userCanViewAllFunds: (0, _computed.equal)('model.user.role', 'Org-Admin'),
    canOverrideRolePerFund: (0, _object.computed)(function () {
      return (0, _object.get)(this, 'settings.orgSettings.canOverrideRolePerFund');
    })
  });

  _exports.default = _default;
});