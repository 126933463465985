define("donor-elf-web/templates/funds/fund/more/donor-hub/delete", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "OUKGMcGb",
    "block": "[[[6,[39,0],null,[[\"deleteTask\"],[[33,1]]],[[\"default\"],[[[[1,\"  \"],[10,2],[12],[1,\"\\n    Are you sure you no longer want to download your gifts from your\\n    organization's DonorHub server?\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"delete-form\",\"delete\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/more/donor-hub/delete.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});