define("donor-elf-web/components/event/new-pledge", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _service, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div {{did-insert this.templateDidInsert}}>
    {{#if this.pledge}}
      They started a {{format-amount this.pledge.amount removeNoCents=true}}
      {{to-lower-case this.pledge.frequencyLabel}} pledge on 
      {{format-date this.pledge.startDate}}.
    {{else}}
      <DeLoading />
    {{/if}}
  </div>
  */
  {
    "id": "6CbTXygl",
    "block": "[[[11,0],[4,[38,0],[[30,0,[\"templateDidInsert\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"pledge\"]],[[[1,\"    They started a \"],[1,[28,[35,2],[[30,0,[\"pledge\",\"amount\"]]],[[\"removeNoCents\"],[true]]]],[1,\"\\n    \"],[1,[28,[35,3],[[30,0,[\"pledge\",\"frequencyLabel\"]]],null]],[1,\" pledge on \\n    \"],[1,[28,[35,4],[[30,0,[\"pledge\",\"startDate\"]]],null]],[1,\".\\n\"]],[]],[[[1,\"    \"],[8,[39,5],null,null,null],[1,\"\\n\"]],[]]],[13]],[],false,[\"did-insert\",\"if\",\"format-amount\",\"to-lower-case\",\"format-date\",\"de-loading\"]]",
    "moduleName": "donor-elf-web/components/event/new-pledge.hbs",
    "isStrictMode": false
  });

  let EventInfoNewPledgeComponent = (_class = class EventInfoNewPledgeComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "pledge", _descriptor2, this);
    }

    async templateDidInsert() {
      this.pledge = await this.store.findRecord('pledge', this.args.eventModel.pledgeId);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "pledge", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype)), _class);
  _exports.default = EventInfoNewPledgeComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EventInfoNewPledgeComponent);
});