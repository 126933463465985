define("donor-elf-web/helpers/contact-has-custom-property-label", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // {{contact-has-custom-property-label contact customPropertyLabel}}
  // returns boolean
  var _default = (0, _helper.helper)(function contactHasCustomPropertyLabel([contact, label]
  /*, hash*/
  ) {
    return contact.customPropertiesByLabel[label] != null;
  });

  _exports.default = _default;
});