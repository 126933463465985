define("donor-elf-web/components/upload-file", ["exports", "@glimmer/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let UploadFileComponent = (_class = class UploadFileComponent extends _component.default {
    constructor(owner, args) {
      super(owner, args);

      _defineProperty(this, "xhr", void 0);

      this.upload();
    }

    upload() {
      let formData = new FormData();
      formData.append('key', this.args.fileUploadInfo.key);
      formData.append('AWSAccessKeyId', this.args.fileUploadInfo.accessKey);
      formData.append('acl', 'private');
      formData.append('policy', this.args.fileUploadInfo.policy);
      formData.append('signature', this.args.fileUploadInfo.signature);
      formData.append('success_action_status', '201');
      formData.append('file', this.args.file);
      this.xhr = new XMLHttpRequest();
      this.xhr.upload.addEventListener('progress', this.handleXhrProgress, false);
      this.xhr.upload.addEventListener('load', this.handleXhrLoad, false);
      this.xhr.upload.addEventListener('error', this.handleXhrError, false);
      this.xhr.upload.addEventListener('abort', this.handleXhrAbort, false);
      this.xhr.open('POST', `https://${this.args.fileUploadInfo.bucket}.s3.amazonaws.com`, true);
      this.xhr.send(formData);
    }

    handleXhrProgress(e) {
      if (e.lengthComputable) {
        var _this$args$onProgress, _this$args;

        (_this$args$onProgress = (_this$args = this.args).onProgress) === null || _this$args$onProgress === void 0 ? void 0 : _this$args$onProgress.call(_this$args, Math.round(e.loaded * 100 / e.total));
      }
    }

    handleXhrLoad() {
      // even though the file has loaded, we still have to wait for the
      // XMLHttpRequest to complete
      this.waitForXhrToComplete();
    }

    waitForXhrToComplete() {
      if (this.xhr === undefined) {
        return;
      }

      if (this.xhr.readyState === 4) {
        this.args.onComplete();
      } else {
        setTimeout(() => {
          this.waitForXhrToComplete();
        }, 100);
      }
    }

    handleXhrError(e) {
      var _this$args$onError, _this$args2;

      (_this$args$onError = (_this$args2 = this.args).onError) === null || _this$args$onError === void 0 ? void 0 : _this$args$onError.call(_this$args2, e);
    }

    handleXhrAbort(e) {
      var _this$args$onAbort, _this$args3;

      (_this$args$onAbort = (_this$args3 = this.args).onAbort) === null || _this$args$onAbort === void 0 ? void 0 : _this$args$onAbort.call(_this$args3, e);
    }

    willDestroy() {
      var _this$xhr, _this$xhr$upload, _this$xhr2, _this$xhr2$upload, _this$xhr3, _this$xhr3$upload, _this$xhr4, _this$xhr4$upload, _this$xhr5;

      (_this$xhr = this.xhr) === null || _this$xhr === void 0 ? void 0 : (_this$xhr$upload = _this$xhr.upload) === null || _this$xhr$upload === void 0 ? void 0 : _this$xhr$upload.removeEventListener('progress', this.handleXhrProgress);
      (_this$xhr2 = this.xhr) === null || _this$xhr2 === void 0 ? void 0 : (_this$xhr2$upload = _this$xhr2.upload) === null || _this$xhr2$upload === void 0 ? void 0 : _this$xhr2$upload.removeEventListener('load', this.handleXhrLoad);
      (_this$xhr3 = this.xhr) === null || _this$xhr3 === void 0 ? void 0 : (_this$xhr3$upload = _this$xhr3.upload) === null || _this$xhr3$upload === void 0 ? void 0 : _this$xhr3$upload.removeEventListener('error', this.handleXhrError);
      (_this$xhr4 = this.xhr) === null || _this$xhr4 === void 0 ? void 0 : (_this$xhr4$upload = _this$xhr4.upload) === null || _this$xhr4$upload === void 0 ? void 0 : _this$xhr4$upload.removeEventListener('abort', this.handleXhrAbort); // abort the upload if it hasn't already completed

      if (((_this$xhr5 = this.xhr) === null || _this$xhr5 === void 0 ? void 0 : _this$xhr5.readyState) !== 4) {
        var _this$xhr6;

        (_this$xhr6 = this.xhr) === null || _this$xhr6 === void 0 ? void 0 : _this$xhr6.abort();
      }

      this.xhr = undefined;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "handleXhrProgress", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleXhrProgress"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleXhrLoad", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleXhrLoad"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "waitForXhrToComplete", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "waitForXhrToComplete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleXhrError", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleXhrError"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleXhrAbort", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleXhrAbort"), _class.prototype)), _class);
  _exports.default = UploadFileComponent;
});