define("donor-elf-web/components/bulk-action/poll-for-status-change", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    {{will-destroy this.templateWillDestroy}}
    ...attributes
  >
  </div>
  */
  {
    "id": "2t7Sas/R",
    "block": "[[[11,0],[17,1],[4,[38,0],[[30,0,[\"templateWillDestroy\"]]],null],[12],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"will-destroy\"]]",
    "moduleName": "donor-elf-web/components/bulk-action/poll-for-status-change.hbs",
    "isStrictMode": false
  });

  const PollInterval = 1500;
  let BulkActionPollForStatusChangeComponent = (_class = class BulkActionPollForStatusChangeComponent extends _component2.default {
    constructor(owner, args) {
      super(owner, args);

      _defineProperty(this, "intervalId", void 0);

      this.intervalId = window.setInterval(() => {
        this.reloadModel();
      }, PollInterval);
    }

    async reloadModel() {
      let previousStatus = this.args.bulkAction.status;
      await this.args.bulkAction.reload();

      if (previousStatus !== this.args.bulkAction.status) {
        this.args.onStatusChange(this.args.bulkAction);

        if (this.args.bulkAction.status === 'completed' || this.args.bulkAction.status === 'error') {
          this.clearInterval();
        }
      }
    }

    clearInterval() {
      if (this.intervalId) {
        window.clearInterval(this.intervalId);
        this.intervalId = undefined;
      }
    }

    templateWillDestroy() {
      this.clearInterval();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "reloadModel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "reloadModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearInterval", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clearInterval"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype)), _class);
  _exports.default = BulkActionPollForStatusChangeComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BulkActionPollForStatusChangeComponent);
});