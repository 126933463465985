define("donor-elf-web/templates/admin/users/show/funds/delete", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "4tZjjorH",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[41,[33,2],[[[1,\"    \"],[10,\"h4\"],[14,0,\"mb-3\"],[12],[1,\"Delete Fund Permission\"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      Are you sure you want to delete \"],[1,[33,3,[\"user\",\"displayName\"]]],[1,\"'s permission\\n      to view the \"],[10,\"b\"],[12],[1,[33,3,[\"fund\",\"name\"]]],[13],[1,\" fund?\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"mt-4\"],[12],[1,\"\\n      \"],[10,\"button\"],[15,\"onclick\",[28,[37,4],[[33,5]],null]],[14,0,\"btn btn-danger\"],[15,\"disabled\",[52,[33,5,[\"isRunning\"]],\"disable\"]],[12],[1,\"\\n\"],[41,[33,5,[\"isIdle\"]],[[[1,\"          \"],[1,[28,[35,6],[\"trash\"],null]],[1,\"\\n          Yes, Delete\\n\"]],[]],[[[1,\"          Deleting...\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\"],[41,[33,5,[\"isIdle\"]],[[[1,\"        \"],[11,\"button\"],[24,0,\"btn btn-link ml-3\"],[4,[38,7],[[30,0],\"cancel\"],null],[12],[1,\"\\n          Cancel\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"h4\"],[14,0,\"mb-3\"],[12],[1,\"Can't Delete Fund Permission\"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      You can't remove the permission to view the \"],[10,\"b\"],[12],[1,[33,3,[\"fund\",\"name\"]]],[13],[1,\"\\n      fund since it's the only fund the user has permission to see.\\n    \"],[13],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-outline-secondary mt-3\"],[4,[38,7],[[30,0],\"cancel\"],null],[12],[1,\"\\n      Go back\\n    \"],[13],[1,\"\\n\"]],[]]]],[]]]]]],[],false,[\"de-form-card\",\"if\",\"hasOtherFundPermissions\",\"model\",\"perform\",\"delete\",\"svg-jar\",\"action\"]]",
    "moduleName": "donor-elf-web/templates/admin/users/show/funds/delete.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});