define("donor-elf-web/routes/coaching/individuals/index", ["exports", "@ember/routing/route", "@ember/service", "@ember/object"], function (_exports, _route, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    abilities: (0, _service.inject)(),
    coachingService: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),
    store: (0, _service.inject)(),

    beforeModel() {
      (0, _object.set)(this, 'navbarSettings.title', 'Individuals');
      (0, _object.get)(this, 'coachingService').clearSelectedIndividual();
    },

    model() {
      return (0, _object.get)(this, 'abilities').loadCoachingAbilities().then(() => {
        return (0, _object.get)(this, 'store').query('coaching/individual-summary', {
          filter: {
            template: false
          }
        }).then(records => {
          return {
            individualSummaries: records
          };
        });
      });
    },

    redirect(model) {
      if ((0, _object.get)(model, 'individualSummaries.length') === 1) {
        let individualId = (0, _object.get)(model, 'individualSummaries.firstObject.id');
        this.transitionTo('coaching.individuals.individual', individualId);
      }
    }

  });

  _exports.default = _default;
});