define("donor-elf-web/controllers/coaching/individuals/index", ["exports", "@ember/controller", "@ember/object/computed"], function (_exports, _controller, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    individualsSortBy: Object.freeze(['name:asc']),
    sortedIndividuals: (0, _computed.sort)('model.individualSummaries', 'individualsSortBy')
  });

  _exports.default = _default;
});