define("donor-elf-web/controllers/funds/fund/journals/journal/add-contacts", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "@ember/service", "@ember/utils"], function (_exports, _controller, _object, _tracking, _service, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.AddContactItem = void 0;

  var _class, _descriptor, _class3, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AddContactItem = (_class = class AddContactItem {
    constructor(contact) {
      _defineProperty(this, "contact", void 0);

      _initializerDefineProperty(this, "selected", _descriptor, this);

      this.contact = contact;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selected", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class);
  _exports.AddContactItem = AddContactItem;
  let JournalAddIndexController = (_class3 = class JournalAddIndexController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "contactsCache", _descriptor2, this);

      _initializerDefineProperty(this, "fundsService", _descriptor3, this);

      _initializerDefineProperty(this, "journalService", _descriptor4, this);

      _initializerDefineProperty(this, "router", _descriptor5, this);

      _initializerDefineProperty(this, "store", _descriptor6, this);

      _initializerDefineProperty(this, "q", _descriptor7, this);

      _initializerDefineProperty(this, "group", _descriptor8, this);

      _initializerDefineProperty(this, "saving", _descriptor9, this);
    }

    get contactItems() {
      return this.model.contactItems;
    }

    get filterApplied() {
      return (0, _utils.isPresent)(this.q) && this.q.length >= 2;
    }

    get filteredContactItems() {
      let filteredItems = this.contactItems;

      if (this.filterApplied) {
        let filteredContactIds = {};
        this.contactsCache.search(this.q).forEach(x => {
          filteredContactIds[x.id] = true;
        });
        filteredItems = filteredItems.filter(x => {
          return filteredContactIds[x.contact.id] || false;
        });
      }

      if ((0, _utils.isPresent)(this.group)) {
        let groupContactIds = {};
        this.contactsCache.groupContacts[this.group].contacts.forEach(x => {
          groupContactIds[x.id] = true;
        });
        filteredItems = filteredItems.filter(x => groupContactIds[x.contact.id]);
      }

      return filteredItems;
    }

    get allFilteredContactItemsSelected() {
      return this.filteredContactItems.find(x => x.selected !== true) == null;
    }

    get selectedContactItems() {
      return this.contactItems.filter(x => x.selected);
    }

    get availableGroupOptions() {
      return [{
        val: '',
        label: 'Filter by Group'
      }].concat(this.contactsCache.sortedGroups.map(x => {
        return {
          val: x,
          label: x
        };
      }));
    }

    templateWillDestroy() {
      this.q = '';
      this.group = '';
      this.saving = false;
    }

    handleSearchInput(e) {
      this.q = e.target.value;
    }

    handleSelectButtonClicked() {
      let checkedValue = this.allFilteredContactItemsSelected ? false : true;
      this.filteredContactItems.forEach(item => {
        item.selected = checkedValue;
      });
    }

    toggleSelected(item) {
      item.selected = !item.selected;
    }

    handleGroupChange(e) {
      this.group = e.target.value;
    }

    handleGroupClicked(group) {
      this.q = '';
      this.group = group;
    }

    async save() {
      let selectedContactItems = this.selectedContactItems;

      if (selectedContactItems.length === 0) {
        this.router.transitionTo('funds.fund.journals.journal.index');
        return;
      }

      this.saving = true;
      await this.fundsService.confirmValidFundToken();
      let record = this.store.createRecord('journal-add-contact', {
        journalId: this.model.journalInfo.journal.id,
        contactIds: selectedContactItems.map(x => x.contact.id)
      });
      await record.save();
      await this.journalService.load({
        forceReload: true
      });
      this.router.transitionTo('funds.fund.journals.journal');
    }

  }, (_descriptor2 = _applyDecoratedDescriptor(_class3.prototype, "contactsCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class3.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "journalService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class3.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class3.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class3.prototype, "q", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class3.prototype, "group", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class3.prototype, "saving", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class3.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "templateWillDestroy"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "handleSearchInput", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "handleSearchInput"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "handleSelectButtonClicked", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "handleSelectButtonClicked"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "toggleSelected", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "toggleSelected"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "handleGroupChange", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "handleGroupChange"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "handleGroupClicked", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "handleGroupClicked"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "save"), _class3.prototype)), _class3);
  _exports.default = JournalAddIndexController;
});