define("donor-elf-web/templates/admin/users/delete", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "GgiZlX6K",
    "block": "[[[6,[39,0],null,[[\"deleteTask\"],[[33,1]]],[[\"default\"],[[[[1,\"  \"],[10,2],[12],[1,\"\\n    Are you sure you want to delete this user?\\n  \"],[13],[1,\"\\n  \"],[10,\"dl\"],[12],[1,\"\\n    \"],[10,\"dt\"],[12],[1,\"First Name\"],[13],[1,\"\\n    \"],[10,\"dd\"],[12],[1,[33,2,[\"record\",\"firstName\"]]],[13],[1,\"\\n    \"],[10,\"dt\"],[12],[1,\"Last Name\"],[13],[1,\"\\n    \"],[10,\"dd\"],[12],[1,[33,2,[\"record\",\"lastName\"]]],[13],[1,\"\\n    \"],[10,\"dt\"],[12],[1,\"Email\"],[13],[1,\"\\n    \"],[10,\"dd\"],[12],[1,[33,2,[\"record\",\"email\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"delete-form\",\"delete\",\"model\"]]",
    "moduleName": "donor-elf-web/templates/admin/users/delete.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});