define("donor-elf-web/templates/components/event-info-stopped-giving-donor", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "jSFQ0yGE",
    "block": "[[[41,[33,1,[\"custom\",\"pledge_first_gift_is_late\"]],[[[1,\"  They are over 3 months behind in giving towards their pledge. They were expected to give \"],[1,[28,[35,2],[[33,1,[\"custom\",\"expected_donation_amount\"]]],[[\"removeNoCents\"],[true]]]],[1,\" by \"],[1,[28,[35,3],[[33,1,[\"custom\",\"expected_donation_date\"]]],null]],[1,\".\\n\"]],[]],[[[1,\"  It's been over 3 months since they last gave.\\n\"],[41,[33,1,[\"custom\",\"last_donation_date\"]],[[[1,\"    They last gave \"],[1,[28,[35,2],[[33,1,[\"custom\",\"last_donation_amount\"]]],[[\"removeNoCents\"],[true]]]],[1,\" on\\n    \"],[1,[28,[35,3],[[33,1,[\"custom\",\"last_donation_date\"]]],null]],[1,\" and were expected to give\\n    their next donation on \"],[1,[28,[35,3],[[33,1,[\"custom\",\"expected_donation_date\"]]],null]],[1,\".\\n\"]],[]],null]],[]]]],[],false,[\"if\",\"eventModel\",\"format-amount\",\"format-date\"]]",
    "moduleName": "donor-elf-web/templates/components/event-info-stopped-giving-donor.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});