define("donor-elf-web/templates/components/coaching-edit-tasks", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "jcuUOAR6",
    "block": "[[[41,[33,1],[[[42,[28,[37,3],[[28,[37,3],[[33,4]],null]],null],null,[[[1,\"    \"],[10,0],[14,0,\"d-flex my-2\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"pr-2 pt-1\"],[12],[1,\"\\n        \"],[1,[28,[35,5],null,[[\"checked\",\"type\"],[[30,1,[\"completed\"]],\"checkbox\"]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"pl-1 pt-1 border-top flex-grow-1\"],[12],[1,\"\\n        \"],[1,[30,1,[\"l\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null]],[]],[[[42,[28,[37,3],[[28,[37,3],[[33,4]],null]],null],null,[[[1,\"    \"],[10,0],[14,0,\"d-flex mb-3\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"d-flex flex-column justify-content-between align-items-center\"],[12],[1,\"\\n        \"],[10,0],[12],[1,\"\\n          \"],[1,[28,[35,5],null,[[\"checked\",\"type\"],[[30,2,[\"completed\"]],\"checkbox\"]]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[11,0],[24,0,\"icon-link-danger\"],[4,[38,6],[[30,0],\"removeTask\",[30,2]],null],[12],[1,\"\\n          \"],[1,[28,[35,7],[\"trash\"],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"flex-grow-1 pl-2\"],[12],[1,\"\\n        \"],[1,[28,[35,8],null,[[\"value\",\"class\",\"rows\"],[[30,2,[\"l\"]],\"form-control\",2]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[2]],null],[1,\"  \"],[11,\"button\"],[24,0,\"btn btn-sm btn-outline-warning\"],[4,[38,6],[[30,0],\"addTask\"],null],[12],[1,[28,[35,7],[\"plus\"],null]],[1,\" Add Task\"],[13],[1,\"\\n\"]],[]]]],[\"task\",\"task\"],false,[\"if\",\"onlyEditCompleted\",\"each\",\"-track-array\",\"tasks\",\"input\",\"action\",\"svg-jar\",\"textarea\"]]",
    "moduleName": "donor-elf-web/templates/components/coaching-edit-tasks.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});