define("donor-elf-web/controllers/funds/fund/more/newsletter/sync", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@glimmer/tracking", "donor-elf-web/lib/store-utils"], function (_exports, _controller, _object, _service, _tracking, _storeUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let NewsletterSyncController = (_class = class NewsletterSyncController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _defineProperty(this, "model", void 0);

      _initializerDefineProperty(this, "syncMessage", _descriptor2, this);

      _initializerDefineProperty(this, "syncing", _descriptor3, this);

      _initializerDefineProperty(this, "syncIsComplete", _descriptor4, this);

      _initializerDefineProperty(this, "syncHasError", _descriptor5, this);

      _initializerDefineProperty(this, "error", _descriptor6, this);

      _initializerDefineProperty(this, "downloadingNewsletterContacts", _descriptor7, this);

      _initializerDefineProperty(this, "newsletterContacts", _descriptor8, this);

      _defineProperty(this, "controllerIsActive", false);
    }

    get newsletterContactsCount() {
      if (this.downloadingNewsletterContacts) {
        return '';
      } else {
        return this.newsletterContacts.length;
      }
    }

    get noNewsletterContacts() {
      return this.newsletterContacts.length === 0;
    }

    async templateDidInsert() {
      this.controllerIsActive = true;
      await this.downloadNewsletterContacts();
      this.sync();
    }

    templateWillDestroy() {
      this.controllerIsActive = false;
      this.syncing = false;
      this.syncIsComplete = false;
      this.syncHasError = false;
      this.error = undefined;
      this.downloadingNewsletterContacts = true;
      this.newsletterContacts = [];
    }

    async downloadNewsletterContacts() {
      this.newsletterContacts = await (0, _storeUtils.queryWithPages)({
        store: this.store,
        modelName: 'newsletter-contact',
        queryOptions: {
          filter: {
            newsletter_account_id: this.model.newsletterAccount.id
          }
        },
        pageSize: 500
      });
      this.downloadingNewsletterContacts = false;
    }

    async sync() {
      if (this.syncing) {
        return;
      }

      this.syncing = true;

      try {
        this.syncMessage = 'Syncing. . .';
        await this.store.createRecord('newsletter-account-sync', {
          newsletterAccountId: this.model.newsletterAccount.id
        }).save();
        this.updateSyncMessage();
        this.checkSync();
      } catch (e) {
        this.error = e;
      }
    }

    updateSyncMessage() {
      if (!this.syncing) {
        return;
      }

      setTimeout(() => {
        let syncMessage = `${this.syncMessage} .`;

        if (syncMessage.length > 40) {
          syncMessage = 'Syncing. . .';
        }

        this.syncMessage = syncMessage;
        this.updateSyncMessage();
      }, 500);
    }

    async checkSync() {
      if (!this.controllerIsActive) {
        return;
      }

      try {
        let syncInfo = await this.store.findRecord('newsletter-account-sync', this.model.newsletterAccount.id, {
          forceReload: true
        });

        if (syncInfo.hasApiError) {
          this.syncHasError = true;
          this.syncing = false;
        } else if (syncInfo.syncing) {
          setTimeout(() => {
            this.checkSync();
          }, 2000);
        } else {
          this.syncing = false;
          this.syncIsComplete = true;
        }
      } catch (e) {
        this.error = e;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "syncMessage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "syncing", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "syncIsComplete", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "syncHasError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "error", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "downloadingNewsletterContacts", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "newsletterContacts", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "downloadNewsletterContacts", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "downloadNewsletterContacts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sync", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sync"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateSyncMessage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateSyncMessage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkSync", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "checkSync"), _class.prototype)), _class);
  _exports.default = NewsletterSyncController;
});