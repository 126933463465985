define("donor-elf-web/templates/funds/fund/more/tnt-connect-import/xml/processing", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "eQygI0up",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,\"h4\"],[12],[1,\"Uploading\"],[13],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"file\",\"onUploadComplete\",\"onUploadError\",\"class\"],[[33,2,[\"selectedFile\"]],[28,[37,3],[[30,0],\"handleS3UploadComplete\"],null],[28,[37,3],[[30,0],\"handleS3UploadError\"],null],\"mb-5\"]]]],[1,\"\\n\\n  \"],[10,\"h4\"],[12],[1,\"Processing\"],[13],[1,\"\\n  \"],[1,[28,[35,4],null,[[\"check\",\"onComplete\"],[[33,5],[28,[37,3],[[30,0],\"onFundUploadComplete\"],null]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"de-form-card\",\"s3-upload-progress\",\"model\",\"action\",\"fund-upload-progress\",\"checkFundUploadProgress\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/more/tnt-connect-import/xml/processing.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});