define("donor-elf-web/controllers/funds/fund/groups/index", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed"], function (_exports, _controller, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    sortProp: 'group',
    sortDir: 'asc',
    sortBy: (0, _object.computed)('sortProp', 'sortDir', function () {
      return [`${(0, _object.get)(this, 'sortProp')}:${(0, _object.get)(this, 'sortDir')}`];
    }),
    sortedItems: (0, _computed.sort)('model', 'sortBy'),
    actions: {
      sort(property) {
        let currentSortProp = (0, _object.get)(this, 'sortProp');

        if (currentSortProp === property) {
          if ((0, _object.get)(this, 'sortDir') === 'asc') {
            (0, _object.set)(this, 'sortDir', 'desc');
          } else {
            (0, _object.set)(this, 'sortDir', 'asc');
          }
        } else {
          (0, _object.setProperties)(this, {
            sortProp: property,
            sortDir: 'desc'
          });
        }
      }

    }
  });

  _exports.default = _default;
});