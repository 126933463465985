define("donor-elf-web/controllers/admin/fund-mappings/index", ["exports", "@ember/object/computed", "@ember/controller", "@ember/object", "@glimmer/tracking", "@ember/service", "fuzzysort"], function (_exports, _computed, _controller, _object, _tracking, _service, _fuzzysort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FundMappingsController = (_dec = (0, _computed.sort)('model.fundMappings', 'sortBy'), (_class = class FundMappingsController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fundsService", _descriptor, this);

      _defineProperty(this, "queryParams", ['q', 'page', 'inactive']);

      _initializerDefineProperty(this, "q", _descriptor2, this);

      _initializerDefineProperty(this, "page", _descriptor3, this);

      _initializerDefineProperty(this, "inactive", _descriptor4, this);

      _defineProperty(this, "perPage", 100);

      _defineProperty(this, "sortBy", ['fund.name:asc']);

      _initializerDefineProperty(this, "sortedItems", _descriptor5, this);
    }

    get filteredItems() {
      var _this$q, _this$q$trim;

      let items = this.sortedItems;

      if (!this.inactive) {
        items = items.filter(x => x.fund.active);
      }

      let query = ((_this$q = this.q) === null || _this$q === void 0 ? void 0 : (_this$q$trim = _this$q.trim()) === null || _this$q$trim === void 0 ? void 0 : _this$q$trim.toLowerCase()) || '';

      if (query.length === 0) {
        return items;
      }

      if (query.length < 3) {
        let exactMatchOnHostId = items.filter(item => {
          return item.hostIdentities.find(hostIdentity => hostIdentity.hostId.toLowerCase() === query) != null;
        });
        return exactMatchOnHostId.length > 0 ? exactMatchOnHostId : items;
      }

      return _fuzzysort.default.go(query, items, {
        threshold: -10000,
        key: 'fuzzySortPrepared'
      }).map(result => result.obj);
    }

    get pagedItems() {
      let offset = (this.page - 1) * this.perPage;
      return this.filteredItems.slice(offset, offset + this.perPage);
    }

    get totalPages() {
      return Math.ceil(this.filteredItems.length / this.perPage);
    }

    templateWillDestroy() {
      this.q = '';
      this.page = 1;
    }

    toggleInactive() {
      this.inactive = !this.inactive;
    }

    changePage(pageNumber) {
      this.page = pageNumber;
    }

    handleSearchInput(e) {
      this.q = e.target.value;
    }

    handleSearchEnter(e) {
      if (e.target.value !== (this.q || '')) {
        this.handleSearchInput(e);
      }

      let firstItem = this.filteredItems[0];

      if (firstItem) {
        this.viewFund(firstItem.fund.id);
      }
    }

    viewFund(fundId) {
      this.fundsService.viewFund(fundId);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "q", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "page", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "inactive", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sortedItems", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleInactive", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleInactive"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changePage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changePage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleSearchInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleSearchInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleSearchEnter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleSearchEnter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "viewFund", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "viewFund"), _class.prototype)), _class));
  _exports.default = FundMappingsController;
});