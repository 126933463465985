define("donor-elf-web/components/announcement", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service"], function (_exports, _component, _templateFactory, _component2, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='d-flex row-bottom-margin bg-white tw-shadow-md tw-max-w-2xl'>
    <div class='flex-grow-0 flex-shrink-0 tw-w-1 bg-brand'></div>
    <div class='flex-grow-1 p-2 border-top border-bottom'>
      {{this.settings.announcement.messageHtmlSafe}}
    </div>
    <div class='flex-shrink-0 border-top border-right border-bottom pt-1 pr-2 pl-1'>
      <button
        {{on 'click' this.markRead}}
        class='btn btn-sm btn-link'
      >
        {{svg-jar 'times'}}
      </button>
    </div>
  </div>
  */
  {
    "id": "i5gctv+2",
    "block": "[[[10,0],[14,0,\"d-flex row-bottom-margin bg-white tw-shadow-md tw-max-w-2xl\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex-grow-0 flex-shrink-0 tw-w-1 bg-brand\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex-grow-1 p-2 border-top border-bottom\"],[12],[1,\"\\n    \"],[1,[30,0,[\"settings\",\"announcement\",\"messageHtmlSafe\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex-shrink-0 border-top border-right border-bottom pt-1 pr-2 pl-1\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-sm btn-link\"],[4,[38,0],[\"click\",[30,0,[\"markRead\"]]],null],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"times\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"on\",\"svg-jar\"]]",
    "moduleName": "donor-elf-web/components/announcement.hbs",
    "isStrictMode": false
  });

  let AnnouncementComponent = (_class = class AnnouncementComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "settings", _descriptor, this);
    }

    markRead() {
      let announcement = this.settings.announcement;
      this.settings.announcement = undefined;
      announcement.read = true;
      announcement.save();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "markRead", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "markRead"), _class.prototype)), _class);
  _exports.default = AnnouncementComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AnnouncementComponent);
});