define("donor-elf-web/templates/funds/fund/journals/journal/review-items-loading", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "rFA6Lh5M",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],[[24,0,\"d-block px-3 py-2 border-right\"]],[[\"@route\",\"@activeClass\"],[\"funds.fund.journals.journal.review-items\",\"bg-gray-200\"]],[[\"default\"],[[[[1,\"\\n    To Review\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,3],null,null,null],[1,\"\\n\"]],[]]]]]],[],false,[\"journal/menu-bar\",\"link-to\",\"form-card\",\"de-loading\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/journals/journal/review-items-loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});