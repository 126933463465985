define("donor-elf-web/validators/email-domain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateEmailDomain;

  function validateEmailDomain() {
    return (key, newValue
    /*, oldValue, changes, content*/
    ) => {
      if (newValue == null || newValue.indexOf('@') === -1) {
        return true;
      }

      let emailDomain = newValue.split('@')[1].toLowerCase();

      if (emailDomain.indexOf('donorelf') >= 0) {
        return "Email domain cannot contain 'donorelf'";
      }

      return true;
    };
  }
});