define("donor-elf-web/components/dashboard/onboard/donor-hub", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='tw-max-w-xl' ...attributes>
    <Alert::Info class='mb-3'>
      <h5>Import your Gifts</h5>
      <p>
        To get started, you'll need to connect to your DonorHub account to import your gifts into DonorElf.
      </p>
      <LinkTo
        @route='funds.fund.more.donor-hub.connect.organization'
        @query={{hash tntOrganizationId=@onboard.custom.tnt_organization_id}}
        class='btn btn-sm btn-brand'
      >
        Import Gifts from DonorHub
      </LinkTo>
    </Alert::Info>
    <Alert::Info>
      <div>
        Also remember you get 3 months free for every person you refer to DonorElf!
        (<a
          href='https://donorelf.notion.site/Referrals-in-DonorElf-2619839194ce4fdf8e9e1bd52786e61f'
          target='_blank'
          rel='noopener noreferrer'
        >more info</a>)
      </div>
    </Alert::Info>
  </div>
  */
  {
    "id": "mtEO2pre",
    "block": "[[[11,0],[24,0,\"tw-max-w-xl\"],[17,1],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"mb-3\"]],null,[[\"default\"],[[[[1,\"\\n    \"],[10,\"h5\"],[12],[1,\"Import your Gifts\"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      To get started, you'll need to connect to your DonorHub account to import your gifts into DonorElf.\\n    \"],[13],[1,\"\\n    \"],[8,[39,1],[[24,0,\"btn btn-sm btn-brand\"]],[[\"@route\",\"@query\"],[\"funds.fund.more.donor-hub.connect.organization\",[28,[37,2],null,[[\"tntOrganizationId\"],[[30,2,[\"custom\",\"tnt_organization_id\"]]]]]]],[[\"default\"],[[[[1,\"\\n      Import Gifts from DonorHub\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[12],[1,\"\\n      Also remember you get 3 months free for every person you refer to DonorElf!\\n      (\"],[10,3],[14,6,\"https://donorelf.notion.site/Referrals-in-DonorElf-2619839194ce4fdf8e9e1bd52786e61f\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"more info\"],[13],[1,\")\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@onboard\"],false,[\"alert/info\",\"link-to\",\"hash\"]]",
    "moduleName": "donor-elf-web/components/dashboard/onboard/donor-hub.hbs",
    "isStrictMode": false
  });

  class DashboardOnboardDonorHubComponent extends _component2.default {}

  _exports.default = DashboardOnboardDonorHubComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DashboardOnboardDonorHubComponent);
});