define("donor-elf-web/templates/admin-error", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Zz83elfv",
    "block": "[[[1,[34,0]]],[],false,[\"generic-error-message\"]]",
    "moduleName": "donor-elf-web/templates/admin-error.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});