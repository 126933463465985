define("donor-elf-web/controllers/funds/fund/properties/property/index", ["exports", "@ember/controller", "donor-elf-web/services/contacts-cache", "@ember/service", "@ember/object", "@glimmer/tracking"], function (_exports, _controller, _contactsCache, _service, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PropertiesPropertyIndexController = (_class = class PropertiesPropertyIndexController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "contactsCache", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "model", _descriptor3, this);

      _initializerDefineProperty(this, "deleteOptionAndCount", _descriptor4, this);
    }

    get propertyLabel() {
      return this.model.property.l;
    }

    get isGroupsProperty() {
      return (0, _contactsCache.isGroupsContactCustomPropertyInfo)(this.model.property);
    }

    get contactCountWithProperty() {
      if (this.isGroupsProperty) {
        return this.contactsCache.contacts.filter(x => x.hasGroups).length;
      } else {
        let {
          propertyLabel
        } = this;
        return this.contactsCache.contacts.filter(x => x.customPropertiesByLabel[propertyLabel] != null).length;
      }
    }

    get contactsFilterPropertyName() {
      if (this.isGroupsProperty) {
        return 'contactGroups';
      } else {
        return `contact_custom_${this.propertyLabel}`;
      }
    }

    get propertyTypeLabel() {
      return _contactsCache.PropertyTypeLabels[this.model.property.t];
    }

    get propertyIsReadonly() {
      return this.model.property.r;
    }

    get propertyTypeIsList() {
      return this.model.property.t === 'l';
    }

    get propertySettingsSingleSelection() {
      var _this$model$property$;

      return (_this$model$property$ = this.model.property.s) === null || _this$model$property$ === void 0 ? void 0 : _this$model$property$.single_selection;
    }

    get propertySettingsCantEditOptions() {
      var _this$model$property$2;

      return (_this$model$property$2 = this.model.property.s) === null || _this$model$property$2 === void 0 ? void 0 : _this$model$property$2.cant_edit_options;
    }

    get propertySettingsCantDelete() {
      var _this$model$property$3;

      return (_this$model$property$3 = this.model.property.s) === null || _this$model$property$3 === void 0 ? void 0 : _this$model$property$3.cant_delete;
    }

    get propertyOptionsAndCount() {
      let {
        propertyLabel
      } = this;

      if (this.isGroupsProperty) {
        return this.model.property.o.map(option => {
          return {
            option,
            count: this.contactsCache.contacts.filter(x => {
              var _x$groups;

              return (_x$groups = x.groups) === null || _x$groups === void 0 ? void 0 : _x$groups.includes(option);
            }).length
          };
        });
      } else {
        return this.model.property.o.map(option => {
          return {
            option,
            count: this.contactsCache.contacts.filter(x => {
              var _x$customPropertiesBy, _x$customPropertiesBy2;

              return (_x$customPropertiesBy = x.customPropertiesByLabel[propertyLabel]) === null || _x$customPropertiesBy === void 0 ? void 0 : (_x$customPropertiesBy2 = _x$customPropertiesBy.v) === null || _x$customPropertiesBy2 === void 0 ? void 0 : _x$customPropertiesBy2[option];
            }).length
          };
        });
      }
    }

    get actionMenuItems() {
      return [{
        label: 'Rename',
        svgJar: 'edit',
        route: 'funds.fund.properties.property.rename'
      }, {
        label: 'Delete',
        svgJar: 'trash',
        route: 'funds.fund.properties.property.delete'
      }];
    }

    handleDeleteOption(optionAndCount, dropdown) {
      this.deleteOptionAndCount = optionAndCount;
      dropdown.actions.close();
    }

    handleDeleteOptionDeleted(_option) {
      this.deleteOptionAndCount = undefined;
      this.send('refreshPropertyRouteModel');
    }

    handleDeleteOptionClose() {
      this.deleteOptionAndCount = undefined;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "contactsCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "model", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "deleteOptionAndCount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handleDeleteOption", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleDeleteOption"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDeleteOptionDeleted", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleDeleteOptionDeleted"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDeleteOptionClose", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleDeleteOptionClose"), _class.prototype)), _class);
  _exports.default = PropertiesPropertyIndexController;
});