define("donor-elf-web/controllers/admin/users/show/activation-link", ["exports", "@ember/controller", "donor-elf-web/config/environment"], function (_exports, _controller, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AdminUsersShowActivationLinkController extends _controller.default {
    get activationLink() {
      return `${_environment.default.apiUrl}/users/invitation?code=${this.model.activationCode}`;
    }

  }

  _exports.default = AdminUsersShowActivationLinkController;
});