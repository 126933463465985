define("donor-elf-web/templates/funds/fund/dashboard/no-goal", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ZsSQTuVT",
    "block": "[[[46,\"goal/no-goal\",null,[[\"goal\",\"fund\"],[[33,1,[\"dashboard\",\"goal\"]],[33,1,[\"fund\"]]]],null],[1,\"\\n\"]],[],false,[\"component\",\"model\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/dashboard/no-goal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});