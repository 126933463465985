define("donor-elf-web/templates/admin/fund-groups/fund-group/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "0NGlxIga",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,\"h3\"],[12],[1,[52,[33,2,[\"record\",\"isNew\"]],\"Add\",\"Edit\"]],[1,\" Fund Group\"],[13],[1,\"\\n\"],[6,[39,3],null,[[\"changeset\",\"cancelRoute\",\"deleteRoute\",\"saveTask\"],[[33,2,[\"changeset\"]],[33,4],\"admin.fund-groups.fund-group.delete\",[33,5]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,6],null,[[\"changeset\",\"property\",\"label\",\"focusOnInsert\",\"showValidationErrors\"],[[33,2,[\"changeset\"]],\"name\",\"Name\",true,[30,1]]]]],[1,\"\\n\\n\"],[41,[28,[37,7],[\"canOverrideRolePerFund\",true],null],[[[1,\"      \"],[1,[28,[35,8],null,[[\"changeset\",\"property\",\"label\",\"selectOptions\"],[[33,2,[\"changeset\"]],\"role\",\"User Role\",[33,2,[\"roleOptions\"]]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"]],[1]]]]]],[]]]]]],[\"showValidationErrors\"],false,[\"de-form-card\",\"if\",\"model\",\"save-form\",\"cancelRoute\",\"save\",\"form-group\",\"org-setting-eq\",\"form-group-select\"]]",
    "moduleName": "donor-elf-web/templates/admin/fund-groups/fund-group/edit.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});