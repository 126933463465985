define("donor-elf-web/components/save-transactions", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/service", "@ember/string", "ember-concurrency"], function (_exports, _computed, _component, _object, _service, _string, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // attributes
  // - lines
  //   - array of line-items to save
  // actions
  // - onSaved()
  //   - fired when all the lines have been saved (even if some had errors)
  // - onInvalidLines(lines)
  //   - fired with an array of lines had an error when saving
  var _default = _component.default.extend({
    navbarSettings: (0, _service.inject)(),
    store: (0, _service.inject)(),
    // line number (index + 1) of the line currently being saved
    savingLineNumber: (0, _object.computed)('savedLinesCount', function () {
      let lineNumber = (0, _object.get)(this, 'savedLinesCount') + 1;

      if (lineNumber > (0, _object.get)(this, 'linesLength')) {
        lineNumber = (0, _object.get)(this, 'linesLength');
      }

      return lineNumber;
    }),
    savedLinesCount: 0,
    saveComplete: false,
    linesLength: 0,
    invalidLines: null,
    invalidLinesPresent: (0, _computed.notEmpty)('invalidLines'),
    percentageSaved: (0, _object.computed)('savedLinesCount', 'linesLength', function () {
      let savedLinesCount = (0, _object.get)(this, 'savedLinesCount');
      let linesLength = (0, _object.get)(this, 'linesLength');
      let percent = savedLinesCount * 100 / linesLength;
      return (0, _string.htmlSafe)(`width: ${percent}%`);
    }),

    init() {
      this._super(...arguments);

      (0, _object.set)(this, 'invalidLines', []);
      (0, _object.set)(this, 'linesLength', this.lines.length);
      (0, _object.get)(this, 'save').perform();
    },

    save: (0, _emberConcurrency.task)(function* () {
      yield this.pauseFundCalcs();
      yield this.saveLines();
      yield this.unpauseFundCalcs();
      yield this.performFundCalculations();
      this.onSaved();
      (0, _object.set)(this, 'saveComplete', (0, _object.get)(this, 'invalidLines.length') == 0);
    }),

    pauseFundCalcs() {
      let pauseFundCalcs = (0, _object.get)(this, 'store').createRecord('pause-fund-calc', {
        fund_id: 1
      });
      (0, _object.set)(this, 'pauseFundCalcs', pauseFundCalcs);
      return pauseFundCalcs.save();
    },

    saveLines() {
      let {
        lines,
        savedLinesCount
      } = (0, _object.getProperties)(this, 'lines', 'savedLinesCount');
      let lineToSave = lines[savedLinesCount];
      return lineToSave.changeset.save().then(() => {
        savedLinesCount = this.incrementProperty('savedLinesCount');

        if (savedLinesCount < lines.length) {
          return this.saveLines();
        } else {
          return lineToSave;
        }
      }, () => {
        (0, _object.get)(this, 'invalidLines').pushObject(lineToSave);
        savedLinesCount = this.incrementProperty('savedLinesCount');

        if (savedLinesCount < lines.length) {
          return this.saveLines();
        } else {
          return lineToSave;
        }
      });
    },

    unpauseFundCalcs() {
      return (0, _object.get)(this, 'pauseFundCalcs').destroyRecord();
    },

    performFundCalculations() {
      (0, _object.get)(this, 'store').createRecord('perform-fund-calc', {
        fund_id: 1
      }).save();
    },

    actions: {
      fixInvalidLines() {
        let {
          invalidLines,
          onInvalidLines
        } = (0, _object.getProperties)(this, 'invalidLines', 'onInvalidLines');
        onInvalidLines(invalidLines);
      },

      goBackToTheApp() {
        window.location.href = '/transactions';
      }

    }
  });

  _exports.default = _default;
});