define("donor-elf-web/components/delete-confirmation", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <BasicDropdown
    @horizontalPosition={{this.horizontalPosition}}
  as |dd|>
    <dd.Trigger>
      {{yield to='trigger'}}
    </dd.Trigger>
    <dd.Content class='de-dropdown-content tw-max-w-64 tw-text-sm {{this.dropdownContentMarginTopClass}} {{@dropdownContentClass}}'>
      <DropdownWithFooter
        @onYes={{close-dropdown dd @onYes}}
        @onNo={{close-dropdown dd}}
      >
        {{yield to='confirmation'}}
      </DropdownWithFooter>
    </dd.Content>
  </BasicDropdown>
  */
  {
    "id": "2UcbMCQd",
    "block": "[[[8,[39,0],null,[[\"@horizontalPosition\"],[[30,0,[\"horizontalPosition\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,1,[\"Trigger\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[18,4,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[30,1,[\"Content\"]],[[16,0,[29,[\"de-dropdown-content tw-max-w-64 tw-text-sm \",[30,0,[\"dropdownContentMarginTopClass\"]],\" \",[30,2]]]]],null,[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@onYes\",\"@onNo\"],[[28,[37,3],[[30,1],[30,3]],null],[28,[37,3],[[30,1]],null]]],[[\"default\"],[[[[1,\"\\n      \"],[18,5,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]]]]]],[\"dd\",\"@dropdownContentClass\",\"@onYes\",\"&trigger\",\"&confirmation\"],false,[\"basic-dropdown\",\"yield\",\"dropdown-with-footer\",\"close-dropdown\"]]",
    "moduleName": "donor-elf-web/components/delete-confirmation.hbs",
    "isStrictMode": false
  });

  class DeleteConfirmationComponent extends _component2.default {
    get horizontalPosition() {
      return this.args.horizontalPosition || 'auto-right';
    }

    get dropdownContentMarginTopClass() {
      return this.args.dropdownContentMarginTopClass || 'mt-2';
    }

  }

  _exports.default = DeleteConfirmationComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DeleteConfirmationComponent);
});