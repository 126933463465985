define("donor-elf-web/routes/old-internet-explorer", ["exports", "@ember/routing/route", "@ember/object", "@ember/service", "donor-elf-web/lib/logger"], function (_exports, _route, _object, _service, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    navbarSettings: (0, _service.inject)(),

    model() {
      (0, _object.set)(this, 'navbarSettings.title', 'Unsupported Browser');

      _logger.default.warn('Internet Explorer11- detected');
    }

  });

  _exports.default = _default;
});