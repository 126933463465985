define("donor-elf-web/services/custom-reports", ["exports", "@ember/service", "@ember/object/computed"], function (_exports, _service, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let REPORT_LABELS = {
    'tenfold-paystubs': 'Paystubs',
    'pbt-disbursement-availability': 'Disbursement Availability',
    'pbt-disbursement-summary': 'Disbursement Summary'
  }; // TODO: it seems there are 2 places for custom reports. Fund Setting and User Setting

  var _default = _service.default.extend({
    settings: (0, _service.inject)(),
    // returns [{ label: '', routeName: '' }]
    reports: (0, _computed.map)('settings.userSettings.customReports', function (reportRouteName) {
      return {
        label: REPORT_LABELS[reportRouteName],
        routeName: `funds.fund.custom-reports.${reportRouteName}`
      };
    })
  });

  _exports.default = _default;
});