define("donor-elf-web/routes/funds/fund/pledges/edit", ["exports", "@ember/routing/route", "@ember/utils", "@ember/service", "donor-elf-web/validations/pledge", "ember-changeset-validations", "ember-changeset", "donor-elf-web/lib/pledge-utils"], function (_exports, _route, _utils, _service, _pledge, _emberChangesetValidations, _emberChangeset, _pledgeUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    moment
  } = window;
  let PledgeEditRoute = (_class = class PledgeEditRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "abilities", _descriptor, this);

      _initializerDefineProperty(this, "contactsCache", _descriptor2, this);

      _initializerDefineProperty(this, "navbarSettings", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "store", _descriptor5, this);

      _defineProperty(this, "queryParams", {
        contactId: {}
      });
    }

    beforeModel(transition) {
      if (this.abilities.noAbility('edit_pledges') && this.abilities.noAbility('edit_manual_pledges') && this.abilities.noAbility('edit_system_pledge_end_date')) {
        transition.abort();
      }
    }

    async model(params) {
      var _contactLineItems;

      let fromURL = this.router.currentURL;

      if (fromURL.match(/pledges\/\d+$/)) {
        fromURL = undefined;
      }

      let record;

      if (params.pledgeId === 'add') {
        record = await this.store.createRecord('pledge', {
          contactId: params.contactId
        });
        this.navbarSettings.title = 'Add Pledge';
      } else {
        record = await this.store.findRecord('pledge', params.pledgeId);
        this.navbarSettings.title = 'Edit Pledge';
      }

      let contact;
      let contactLineItems = undefined;

      if ((0, _utils.isPresent)(record.contactId)) {
        contactLineItems = (await this.store.query('line-item', {
          filter: {
            contact_id: record.contactId
          }
        })).toArray();
        contact = this.contactsCache.contactsById[record.contactId];
      }

      let changeset = new _emberChangeset.default(record, (0, _emberChangesetValidations.default)(_pledge.default), _pledge.default);
      let gifts = ((_contactLineItems = contactLineItems) === null || _contactLineItems === void 0 ? void 0 : _contactLineItems.filter(x => x.amount > 0)) || [];

      if (record.isNew && gifts.length > 0) {
        let firstGift = gifts[gifts.length - 1];
        changeset.set('startDate', firstGift.transDate);
        changeset.set('amount', firstGift.amount);

        if (gifts.length >= 3) {
          let monthsApart = moment(gifts[0].transDate).diff(gifts[2].transDate, 'months', true);

          if (monthsApart <= 4) {
            changeset.set('frequency', 1);
          }
        }
      }

      changeset.set('endDateMonths', '12');

      if (changeset.get('frequency') === _pledgeUtils.pledgeOneTimeFrequency) {
        let endDateMonths = (0, _pledgeUtils.calculateEndDateMonthsForOneTimePledge)({
          startDate: changeset.get('startDate'),
          endDate: changeset.get('endDate')
        });

        if (endDateMonths) {
          changeset.set('endDateMonths', endDateMonths.toString());
        }
      }

      return {
        record,
        changeset,
        gifts,
        contact,
        fromURL
      };
    }

    afterModel(model, transition) {
      if (!(0, _pledgeUtils.canEditPledge)(model.record, this.abilities)) {
        transition.abort();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "abilities", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "contactsCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "navbarSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = PledgeEditRoute;
});