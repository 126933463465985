define("donor-elf-web/controllers/funds/fund/pledges/edit", ["exports", "@ember/controller", "@ember/object", "@ember/utils", "@ember/service", "ember-concurrency", "donor-elf-web/lib/pledge-utils", "@glimmer/tracking"], function (_exports, _controller, _object, _utils, _service, _emberConcurrency, _pledgeUtils, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PledgeEditController = (_dec = (0, _emberConcurrency.task)(function* () {
    let self = this;
    let changeset = self.model.changeset;
    yield changeset.validate();

    if (changeset.get('isValid')) {
      if (changeset.get('frequency') === _pledgeUtils.pledgeOneTimeFrequency) {
        changeset.set('endDate', (0, _pledgeUtils.calculateEndDateForOneTimePledge)({
          startDate: changeset.get('startDate'),
          endDateMonths: changeset.get('endDateMonths'),
          hasYtdGoalType: self.hasYtdGoalType
        }));
      }

      try {
        yield changeset.save();

        if ((0, _utils.isEmpty)(self.model.fromURL)) {
          self.transitionToRoute('funds.fund.contacts.contact', changeset.get('contactId'));
        } else {
          self.transitionToRoute(self.model.fromURL);
        }
      } catch (error) {
        if (self.model.record.errors.length > 0) {
          self.model.record.errors.forEach(({
            attribute,
            message
          }) => {
            changeset.pushErrors(attribute, message);
          });
        } else {
          self.error = error;
        }
      }
    }
  }).drop(), (_class = class PledgeEditController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "abilities", _descriptor, this);

      _initializerDefineProperty(this, "fundGoalService", _descriptor2, this);

      _initializerDefineProperty(this, "settings", _descriptor3, this);

      _defineProperty(this, "model", void 0);

      _defineProperty(this, "queryParams", ['contactId']);

      _initializerDefineProperty(this, "contactId", _descriptor4, this);

      _initializerDefineProperty(this, "error", _descriptor5, this);

      _initializerDefineProperty(this, "save", _descriptor6, this);
    }

    templateWillDestroy() {
      this.contactId = undefined;
      this.error = undefined;
    }

    get cannotEditAllFields() {
      return !this.model.record.isNew && !this.model.record.isManual && this.abilities.noAbility('edit_pledges');
    }

    get canEditEndDateField() {
      return this.abilities.hasAbility('edit_pledges') || this.abilities.hasAbility('edit_system_pledge_end_date');
    }

    get frequencyOptions() {
      let options = [{
        val: undefined,
        label: ''
      }];
      Object.keys(_pledgeUtils.pledgeFrequencyLabels).forEach(key => {
        if (_pledgeUtils.pledgeFrequencyLabels[key] !== 'Weekly') {
          options.push({
            val: Number(key),
            label: _pledgeUtils.pledgeFrequencyLabels[key]
          });
        }
      });
      return options;
    }

    get isOneTimeFrequency() {
      return this.model.changeset.get('frequency') === _pledgeUtils.pledgeOneTimeFrequency;
    }

    get oneTimeMonthlyAmount() {
      return (this.model.changeset.get('amount') || 0) / Number(this.model.changeset.get('endDateMonths'));
    }

    get hasYtdGoalType() {
      return this.fundGoalService.hasYtdGoalType;
    }

    handleFrequencyChanged()
    /* newFrequency */
    {
      this.model.changeset.set('endDateMonths', '12');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "abilities", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fundGoalService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "contactId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "error", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "save", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handleFrequencyChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleFrequencyChanged"), _class.prototype)), _class));
  _exports.default = PledgeEditController;
});