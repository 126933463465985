define("donor-elf-web/components/contact-duplicate-row", ["exports", "@ember/component", "@ember/object", "@ember/service", "ember-concurrency", "@ember/object/computed"], function (_exports, _component, _object, _service, _emberConcurrency, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    attrs
      - contactDuplicateRow = { contactDuplicate, bothHavePledges }
  
    actions
      - remove - fired when the row should be removed
  */
  var _default = _component.default.extend({
    contactsCache: (0, _service.inject)(),
    fundsService: (0, _service.inject)(),
    store: (0, _service.inject)(),
    // { contactDuplicate: record, bothHavePledges: boolean }
    contactDuplicateRow: null,
    contactDuplicate: (0, _computed.alias)('contactDuplicateRow.contactDuplicate'),
    askWhichContactToKeep: false,
    merging: false,
    error: null,
    merge: (0, _emberConcurrency.task)(function* (keepContactId) {
      try {
        (0, _object.set)(this, 'merging', true);
        let contactDuplicate = (0, _object.get)(this, 'contactDuplicate');
        let mergeContactId = (0, _object.get)(contactDuplicate, 'contactIds').find(x => x !== keepContactId);
        yield (0, _object.get)(this, 'fundsService').confirmValidFundToken();
        let contactsFromServer = yield this.store.query('contact', {
          filter: {
            id: [keepContactId, mergeContactId]
          }
        }); // confirm both contacts still exist on the server before merging

        if (contactsFromServer.length == 2) {
          let merge = (0, _object.get)(this, 'store').createRecord('merge-contact', {
            keepContactId,
            mergeContactId
          });
          yield merge.save();
        } // just marking it deleted but not persisting to server because the merge above took care of that


        contactDuplicate.deleteRecord();
        yield (0, _object.get)(this, 'contactsCache').loadContacts({
          forceLoad: true
        });
        (0, _object.get)(this, 'remove')((0, _object.get)(this, 'contactDuplicateRow'));
      } catch (error) {
        (0, _object.setProperties)(this, {
          merging: false,
          error
        });
      }
    }),
    reject: (0, _emberConcurrency.task)(function* () {
      try {
        (0, _object.set)(this, 'merging', true);
        let contactDuplicate = (0, _object.get)(this, 'contactDuplicate');
        (0, _object.set)(contactDuplicate, 'rejected', true);
        yield (0, _object.get)(this, 'fundsService').confirmValidFundToken();
        yield contactDuplicate.save();
        (0, _object.get)(this, 'remove')((0, _object.get)(this, 'contactDuplicateRow'));
      } catch (error) {
        (0, _object.setProperties)(this, {
          merging: false,
          error
        });
      }
    }),
    actions: {
      askWhichContactToKeep() {
        (0, _object.set)(this, 'askWhichContactToKeep', true);
      },

      cancelMerge() {
        (0, _object.set)(this, 'askWhichContactToKeep', false);
      }

    }
  });

  _exports.default = _default;
});