define("donor-elf-web/models/notification", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    emailTo: attr(),
    // array of email addresses
    fundIds: attr(),
    // array of fund ids in the string format
    excludeEventTypeIds: attr(),
    // array of integers
    notifySameDayOnNewTransactions: attr('boolean'),
    includeContactCityAndState: attr('boolean'),
    includeContactEmail: attr('boolean')
  });

  _exports.default = _default;
});