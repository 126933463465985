define("donor-elf-web/templates/components/admin-user-add-fund", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "qlx4cczq",
    "block": "[[[10,0],[14,0,\"d-flex mb-3\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex-grow-1 pr-3\"],[12],[1,\"\\n    \"],[1,[28,[35,0],null,[[\"value\",\"class\",\"placeholder\"],[[33,1],\"form-control\",\"Filter Funds\"]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-warning\"],[4,[38,2],[[30,0],\"save\"],null],[12],[1,\"Save\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"table\"],[14,0,\"table\"],[12],[1,\"\\n  \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[33,5]],null]],null],null,[[[1,\"      \"],[10,\"tr\"],[12],[1,\"\\n        \"],[10,\"td\"],[14,5,\"width: 2rem;\"],[12],[1,[28,[35,0],null,[[\"checked\",\"type\"],[[30,1,[\"isSelected\"]],\"checkbox\"]]]],[13],[1,\"\\n        \"],[10,\"td\"],[12],[1,[30,1,[\"fund\",\"name\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"item\"],false,[\"input\",\"q\",\"action\",\"each\",\"-track-array\",\"filteredList\"]]",
    "moduleName": "donor-elf-web/templates/components/admin-user-add-fund.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});