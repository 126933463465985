define("donor-elf-web/components/coaching-overall-chart", ["exports", "@ember/component", "@ember/object", "@ember/service", "@ember/runloop", "@ember/object/computed"], function (_exports, _component, _object, _service, _runloop, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    moment,
    Highcharts
  } = window;
  /*
    attrs
    - field
    - report
    - individual
  */

  var _default = _component.default.extend({
    coachingService: (0, _service.inject)(),
    field: null,
    individual: null,
    report: null,
    overallGoal: (0, _computed.alias)('field.overallGoal'),
    intervalGoalHigh: (0, _computed.alias)('field.intervalGoal.high'),
    intervalGoalLow: (0, _computed.alias)('field.intervalGoal.low'),
    intervalsToShow: (0, _object.computed)('field.id', 'report.id', function () {
      let {
        overallGoal,
        intervalGoalLow
      } = (0, _object.getProperties)(this, 'overallGoal', 'intervalGoalLow');
      return Math.ceil(overallGoal / intervalGoalLow);
    }),
    intervalGoalSeries: (0, _object.computed)('field.id', 'report.id', function () {
      let {
        overallGoal,
        individual
      } = (0, _object.getProperties)(this, 'overallGoal', 'individual');
      let {
        startDate,
        goalDate
      } = (0, _object.getProperties)(individual, 'startDate', 'goalDate');
      return {
        name: 'Interval Goal',
        type: 'line',
        color: '#eceeef',
        data: [[Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()), 0], [Date.UTC(goalDate.getFullYear(), goalDate.getMonth(), goalDate.getDate()), overallGoal]]
      };
    }),
    overallGoalData: (0, _object.computed)('field.id', 'report.id', function () {
      let {
        overallGoal,
        individual
      } = (0, _object.getProperties)(this, 'overallGoal', 'individual');
      let {
        startDate,
        goalDate
      } = (0, _object.getProperties)(individual, 'startDate', 'goalDate');
      return [[Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()), overallGoal], [Date.UTC(goalDate.getFullYear(), goalDate.getMonth(), goalDate.getDate()), overallGoal]];
    }),
    monthMarkerData: (0, _object.computed)('field.id', 'report.id', function () {
      let {
        overallGoal,
        individual
      } = (0, _object.getProperties)(this, 'overallGoal', 'individual');
      let {
        startDate,
        goalDate
      } = (0, _object.getProperties)(individual, 'startDate', 'goalDate');
      let monthDate = moment(startDate);
      let data = [];
      let index = 0;

      while (monthDate.toDate() < goalDate) {
        data.push({
          x: Date.UTC(monthDate.toDate().getFullYear(), monthDate.toDate().getMonth(), monthDate.toDate().getDate()),
          y: overallGoal,
          numberMonths: index
        });
        index += 1;
        monthDate = moment(startDate).add(index, 'month');
      }

      return data;
    }),
    reportIndex: (0, _object.computed)('report.id', function () {
      let {
        individual,
        report
      } = (0, _object.getProperties)(this, 'individual', 'report');
      return (0, _object.get)(individual, 'sortedReports').indexOf(report);
    }),
    data: (0, _object.computed)('field.id', 'report.id', function () {
      let {
        field,
        individual,
        reportIndex,
        coachingService
      } = (0, _object.getProperties)(this, 'field', 'individual', 'report', 'reportIndex', 'coachingService');
      let startDate = (0, _object.get)(individual, 'startDate');
      let reports = (0, _object.get)(individual, 'sortedReports').toArray();
      let data = [[Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()), 0]];

      for (let i = 0; i <= reportIndex; i++) {
        let reportEndDate = (0, _object.get)(reports[i], 'endDate');
        let val = coachingService.getFieldValue(field, reports[i]) || 0;

        if (val === 0 && i > 0) {
          let lastPresentVal = 0;

          for (let y = i - 1; y > 0; y--) {
            let prevVal = coachingService.getFieldValue(field, reports[y]) || 0;

            if (prevVal > 0) {
              lastPresentVal = prevVal;
              y = 0;
            }
          }

          val = lastPresentVal;
        }

        data.push([Date.UTC(reportEndDate.getFullYear(), reportEndDate.getMonth(), reportEndDate.getDate()), val]);
      }

      return data;
    }),
    pledgedData: (0, _object.computed)('field.id', 'report.id', function () {
      let {
        field,
        individual,
        reportIndex,
        coachingService
      } = (0, _object.getProperties)(this, 'field', 'individual', 'report', 'reportIndex', 'coachingService');

      if (field.t !== 'pledged&received') {
        return null;
      }

      let reports = (0, _object.get)(individual, 'sortedReports').toArray();
      let startDate = (0, _object.get)(individual, 'startDate');
      let pledgedData = [[Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate()), 0]];
      let lastPresentVal = 0;

      for (let i = 0; i <= reportIndex; i++) {
        let reportEndDate = (0, _object.get)(reports[i], 'endDate');
        let val = coachingService.getFieldValue(field, reports[i], {
          propertyName: 'p'
        }) || 0;

        if (val > 0) {
          lastPresentVal = val;
        } else {
          val = lastPresentVal;
        }

        pledgedData.push([Date.UTC(reportEndDate.getFullYear(), reportEndDate.getMonth(), reportEndDate.getDate()), val]);
      }

      return pledgedData;
    }),
    chartSettings: (0, _object.computed)('field.id', 'report.id', function () {
      let series = [];
      let {
        intervalGoalSeries,
        overallGoalData,
        data,
        pledgedData
      } = (0, _object.getProperties)(this, 'intervalGoalSeries', 'overallGoalData', 'data', 'pledgedData');
      series.push({
        type: 'column',
        width: 1,
        color: '#eceeef',
        tooltip: {
          pointFormat: '<div class="d-flex" style="font-size: 16px"><div class="flex-grow-1">Months Raising Support:&nbsp</div><div>{point.numberMonths}</div></div>'
        },
        data: (0, _object.get)(this, 'monthMarkerData')
      });

      if (intervalGoalSeries) {
        series.push(intervalGoalSeries);
      }

      series.push({
        name: 'Goal',
        type: 'line',
        color: '#f0ad4e',
        lineWidth: 2,
        marker: {
          enabled: false
        },
        data: overallGoalData
      });

      if (pledgedData) {
        series.push({
          name: 'Committed',
          type: 'line',
          lineWidth: 3,
          color: '#f0d15b',
          data: pledgedData,
          marker: {
            enabled: false
          }
        });
      }

      series.push({
        name: 'Received',
        type: 'area',
        color: '#0b68a1',
        data: data
      });
      return {
        chart: {
          animation: false,
          height: 125,
          margin: [2, 0, 2, 0],
          borderWidth: 0
        },
        title: {
          text: null
        },
        credits: {
          enabled: false
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            // don't display the dummy year
            month: '%e. %b',
            year: '%b'
          }
        },
        yAxis: {
          min: 0,
          labels: {
            enabled: false
          },
          title: {
            text: null
          },
          startOnTick: false,
          endOnTick: false,
          tickPositions: [0]
        },
        legend: {
          enabled: false
        },
        tooltip: {
          shared: true,
          crosshairs: true,
          pointFormat: '<div class="d-flex" style="font-size: 16px"><div class="flex-grow-1">{series.name}:&nbsp</div><div>${point.y:,.0f}</div></div>',
          headerFormat: '<div style="font-size: 16px; margin-bottom: 0.25rem">{point.key}</div>',
          dateTimeLabelFormats: {
            day: '%B %e, %Y'
          },
          useHTML: true
        },
        plotOptions: {
          series: {
            animation: false,
            states: {
              hover: {
                enabled: false
              }
            }
          },
          area: {
            marker: {
              enabled: false
            }
          },
          line: {
            marker: {
              enabled: false
            }
          },
          column: {
            maxPointWidth: 1,
            borderWidth: 0,
            tooltip: {
              enabled: false
            }
          }
        },
        series
      };
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      _runloop.run.scheduleOnce('afterRender', () => {
        Highcharts.chart(this.element.querySelector('.field-chart'), this.chartSettings);
      });
    }

  });

  _exports.default = _default;
});