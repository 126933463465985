define("donor-elf-web/components/event/unthanked-donor", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _service, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    {{will-destroy this.templateWillDestroy}}
  >
    {{#if (is-present this.createdThankTask)}}
      <p>
        The following thank task has been created, and this news feed event will be
        removed.
      </p>
      <div class='mb-3'>
        <Task::Info
          @task={{this.createdThankTask}}
          @onTaskDeleted={{this.handleThankTaskDeleted}}
          class='d-inline-block'
        />
      </div>
    {{else}}
      <div class='d-flex'>
        <div class='flex-grow-1'>
          {{#if @eventModel.custom.first_gift}}
            They have not been thanked for their first gift.
          {{else if (is-present @eventModel.custom.gift_interval)}}
            Gift Interval
          {{else if (is-present @eventModel.custom.last_thank_date)}}
            They were last thanked {{moment-from-now @eventModel.custom.last_thank_date}}
            <span class='font-weight-light text-muted'>(on {{format-date @eventModel.custom.last_thank_date}})</span>
          {{else}}
            They have not been thanked yet.
          {{/if}}
        </div>
        <div class='ml-2'>
          <button
            {{on 'click' this.handleCreateTask}}
            class='btn btn-sm btn-warning'
            type='button'
          >
            Create Thank Task
          </button>
        </div>
      </div>
    {{/if}}
  </div>
  
  */
  {
    "id": "edkoG9SY",
    "block": "[[[11,0],[4,[38,0],[[30,0,[\"templateWillDestroy\"]]],null],[12],[1,\"\\n\"],[41,[28,[37,2],[[30,0,[\"createdThankTask\"]]],null],[[[1,\"    \"],[10,2],[12],[1,\"\\n      The following thank task has been created, and this news feed event will be\\n      removed.\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"mb-3\"],[12],[1,\"\\n      \"],[8,[39,3],[[24,0,\"d-inline-block\"]],[[\"@task\",\"@onTaskDeleted\"],[[30,0,[\"createdThankTask\"]],[30,0,[\"handleThankTaskDeleted\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"d-flex\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"flex-grow-1\"],[12],[1,\"\\n\"],[41,[30,1,[\"custom\",\"first_gift\"]],[[[1,\"          They have not been thanked for their first gift.\\n\"]],[]],[[[41,[28,[37,2],[[30,1,[\"custom\",\"gift_interval\"]]],null],[[[1,\"          Gift Interval\\n\"]],[]],[[[41,[28,[37,2],[[30,1,[\"custom\",\"last_thank_date\"]]],null],[[[1,\"          They were last thanked \"],[1,[28,[35,4],[[30,1,[\"custom\",\"last_thank_date\"]]],null]],[1,\"\\n          \"],[10,1],[14,0,\"font-weight-light text-muted\"],[12],[1,\"(on \"],[1,[28,[35,5],[[30,1,[\"custom\",\"last_thank_date\"]]],null]],[1,\")\"],[13],[1,\"\\n\"]],[]],[[[1,\"          They have not been thanked yet.\\n        \"]],[]]]],[]]]],[]]],[1,\"      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"ml-2\"],[12],[1,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-sm btn-warning\"],[24,4,\"button\"],[4,[38,6],[\"click\",[30,0,[\"handleCreateTask\"]]],null],[12],[1,\"\\n          Create Thank Task\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"@eventModel\"],false,[\"will-destroy\",\"if\",\"is-present\",\"task/info\",\"moment-from-now\",\"format-date\",\"on\"]]",
    "moduleName": "donor-elf-web/components/event/unthanked-donor.hbs",
    "isStrictMode": false
  });

  let EventInfoNewPledgeComponent = (_class = class EventInfoNewPledgeComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "addSomethingModal", _descriptor, this);

      _initializerDefineProperty(this, "taskService", _descriptor2, this);

      _initializerDefineProperty(this, "createdThankTask", _descriptor3, this);

      _defineProperty(this, "addTaskSubsciptionToken", void 0);
    }

    handleCreateTask() {
      if (this.addTaskSubsciptionToken == null) {
        this.addTaskSubsciptionToken = this.addSomethingModal.subscribeToTaskAdded(this.handleTaskAddedFromAddModal);
      }

      this.addSomethingModal.open({
        type: 'task',
        taskOptions: {
          contactId: this.args.eventModel.contactId,
          actionLabel: 'Thank',
          hideAddAnother: true
        }
      });
    }

    handleTaskAddedFromAddModal(_message, data) {
      var _this$taskService$act;

      if (data.task.contactId === this.args.eventModel.contactId && data.task.actionId === ((_this$taskService$act = this.taskService.actionsByLabel['Thank']) === null || _this$taskService$act === void 0 ? void 0 : _this$taskService$act.id)) {
        this.createdThankTask = data.task;
      }
    }

    handleThankTaskDeleted() {
      this.createdThankTask = undefined;
    }

    unsubscribeFromAddTaskIfPresent() {
      if (this.addTaskSubsciptionToken) {
        this.addSomethingModal.unsubscribeWithToken(this.addTaskSubsciptionToken);
        this.addTaskSubsciptionToken = undefined;
      }
    }

    templateWillDestroy() {
      this.unsubscribeFromAddTaskIfPresent();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "addSomethingModal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "taskService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "createdThankTask", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handleCreateTask", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleCreateTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleTaskAddedFromAddModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleTaskAddedFromAddModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleThankTaskDeleted", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleThankTaskDeleted"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype)), _class);
  _exports.default = EventInfoNewPledgeComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EventInfoNewPledgeComponent);
});