define("donor-elf-web/controllers/demo", ["exports", "@ember/controller", "@ember/object", "@ember/utils", "@ember/service", "ember-concurrency", "donor-elf-web/lib/logger"], function (_exports, _controller, _object, _utils, _service, _emberConcurrency, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    moment
  } = window;

  var _default = _controller.default.extend({
    cookies: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    session: (0, _service.inject)(),
    roleOptions: [{
      label: 'Missionary',
      val: 1
    }, {
      label: 'Director of Operations / Finance',
      val: 2
    }, {
      label: 'Director of Development',
      val: 3
    }, {
      label: 'Other',
      val: 4
    }],
    save: (0, _emberConcurrency.task)(function* () {
      let changeset = (0, _object.get)(this, 'model.changeset');
      yield changeset.validate();

      if ((0, _object.get)(changeset, 'isValid')) {
        try {
          yield changeset.save();
        } catch (error) {
          _logger.default.error(error);
        }

        let demoEmail = (0, _object.get)(changeset, 'email');

        if ((0, _utils.isEmpty)(demoEmail)) {
          demoEmail = 'blank';
        }

        let twoYears = moment().add(2, 'years').toDate();
        (0, _object.get)(this, 'cookies').write('demo_email', demoEmail, {
          expires: twoYears
        });
        yield (0, _object.get)(this, 'session').authenticate('authenticator:oauth2', 'demo@donorelf.com', 'password');
        yield (0, _object.get)(this, 'currentUser').load();
        this.transitionToRoute('funds.fund', 'selected-fund');
      }
    }).drop()
  });

  _exports.default = _default;
});