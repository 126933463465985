define("donor-elf-web/controllers/admin/users/show/funds/delete", ["exports", "@ember/controller", "@ember/object", "@ember/service", "ember-concurrency"], function (_exports, _controller, _object, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    store: (0, _service.inject)(),
    fundsService: (0, _service.inject)(),
    flashMessages: (0, _service.inject)(),
    hasOtherFundPermissions: (0, _object.computed)('model.userFundsRecords[]', function () {
      return (0, _object.get)(this, 'model.userFundRecords.length') > 1;
    }),
    delete: (0, _emberConcurrency.task)(function* () {
      yield (0, _object.get)(this, 'fundsService').confirmValidFundToken();
      let userFundRecord = (0, _object.get)(this, 'model.userFundRecord');
      let fundName = (0, _object.get)(this, 'model.fund.name');
      let message = `The user no longer has permission to see the ${fundName} fund`;
      yield userFundRecord.destroyRecord();
      (0, _object.get)(this, 'flashMessages').success(message);
      this.transitionToRoute('admin.users.show');
    }).drop(),
    actions: {
      cancel() {
        window.history.back();
      }

    }
  });

  _exports.default = _default;
});