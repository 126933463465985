define("donor-elf-web/components/modal", ["exports", "@glimmer/component", "@ember/object", "@glimmer/tracking"], function (_exports, _component, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let constModalRootElement;
  let ModalComponent = (_class = class ModalComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "applyShowClass", _descriptor, this);
    }

    get fadeIn() {
      return this.args.fadeIn ?? true;
    }

    get modalRootElement() {
      if (constModalRootElement) {
        return constModalRootElement;
      }

      return constModalRootElement = document.getElementById('modal-root');
    }

    closeRequested() {
      var _this$args$onCloseReq, _this$args;

      (_this$args$onCloseReq = (_this$args = this.args).onCloseRequested) === null || _this$args$onCloseReq === void 0 ? void 0 : _this$args$onCloseReq.call(_this$args);
    }

    handleClick(e) {
      if (e.target === e.currentTarget) {
        this.closeRequested();
      }
    }

    handleDocumentKeydown(e) {
      // escape key
      if (e.key === 'Escape') {
        this.closeRequested();
      }
    }

    templateDidInsert() {
      document.body.classList.add('modal-open');
      document.addEventListener('keydown', this.handleDocumentKeydown);

      if (this.fadeIn) {
        setTimeout(() => {
          this.applyShowClass = true;
        });
      } else {
        this.applyShowClass = true;
      }
    }

    templateWillDestroy() {
      document.body.classList.remove('modal-open');
      document.removeEventListener('keydown', this.handleDocumentKeydown);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "applyShowClass", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDocumentKeydown", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleDocumentKeydown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype)), _class);
  _exports.default = ModalComponent;
  ;
});