define("donor-elf-web/routes/intervarsity-first-import", ["exports", "@ember/routing/route", "ember-simple-auth/mixins/authenticated-route-mixin", "@ember/object", "@ember/service", "@ember/utils"], function (_exports, _route, _authenticatedRouteMixin, _object, _service, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_authenticatedRouteMixin.default, {
    currentUser: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),

    beforeModel() {
      return this.currentUser.load().then(() => {
        // afterLoginRoute will be empty when the first import is done
        if ((0, _utils.isEmpty)(this.currentUser.afterLoginRoute)) {
          this.transitionTo('funds.fund', 'selected-fund');
        }
      });
    },

    model() {
      (0, _object.set)(this, 'navbarSettings.title', 'Importing Data');
    }

  });

  _exports.default = _default;
});