define("donor-elf-web/controllers/funds/fund/dashboard", ["exports", "@ember/controller", "@ember/object", "ember-cached-decorator-polyfill", "@ember/service"], function (_exports, _controller, _object, _emberCachedDecoratorPolyfill, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DashboardController = (_class = class DashboardController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "abilities", _descriptor, this);

      _initializerDefineProperty(this, "campaignService", _descriptor2, this);

      _initializerDefineProperty(this, "currentUser", _descriptor3, this);

      _initializerDefineProperty(this, "contactsCache", _descriptor4, this);

      _initializerDefineProperty(this, "fundsService", _descriptor5, this);

      _initializerDefineProperty(this, "settings", _descriptor6, this);

      _initializerDefineProperty(this, "store", _descriptor7, this);

      _defineProperty(this, "model", void 0);
    }

    get showAssignedTasks() {
      return this.abilities.hasAbility('assign_tasks');
    }

    get userStripeIsDelinquent() {
      return this.currentUser.stripeIsDelinquent;
    }

    get mockYtdGoalsForYoungLife() {
      return [this.store.createRecord('goal', {
        goalType: "ytd",
        goalAmount: 180000.0,
        fundId: "145945",
        goalSettings: {
          campaignName: "Golf Event"
        },
        goalData: {
          start_date: "2023-10-01",
          end_date: "2024-09-30",
          monthly_gifts: [{
            "month": "2023-10-01",
            "amount": 6651.38
          }, {
            "month": "2023-11-01",
            "amount": 6241.38
          }, {
            "month": "2023-12-01",
            "amount": 11512.63
          }, {
            "month": "2024-01-01",
            "amount": 6806.38
          }, {
            "month": "2024-02-01",
            "amount": 5886.38
          }, {
            "month": "2024-03-01",
            "amount": 21363.88
          }, {
            "month": "2024-04-01",
            "amount": 11943.88
          }, {
            "month": "2024-05-01",
            "amount": 7233.51
          }, {
            "month": "2024-06-01",
            "amount": 5193.51
          }, {
            "month": "2024-07-01",
            "amount": 0.0
          }, {
            "month": "2024-08-01",
            "amount": 0.0
          }, {
            "month": "2024-09-01",
            "amount": 0.0
          }],
          recurring_pledges_amount: null,
          recurring_pledges: {
            amount: 80454.0,
            percent: 45
          },
          left_amount: 78567.32,
          given_amount: 82832.93,
          left_percent: 44,
          monthly_goal: 15000.0,
          total_amount: 101432.68,
          given_percent: 46,
          given_to_date: "2024-06-30",
          total_percent: 56,
          overdue_amount: 520.0,
          expected_amount: 18599.75,
          no_gifts_amount: 0.0,
          overdue_percent: 0,
          expected_percent: 10,
          expected_to_date: "2024-09-30",
          no_gifts_percent: 0,
          expected_from_date: "2024-06-01",
          expected_number_months: 4,
          exclude_no_gift_pledges: false
        }
      }), this.store.createRecord('goal', {
        goalType: "ytd",
        goalAmount: 600000.0,
        fundId: 145842,
        goalSettings: {
          campaignName: "Banquet"
        },
        goalData: {
          "start_date": "2023-10-01",
          "end_date": "2024-09-30",
          "monthly_gifts": [{
            "month": "2023-10-01",
            "amount": 42002.12
          }, {
            "month": "2023-11-01",
            "amount": 28014.06
          }, {
            "month": "2023-12-01",
            "amount": 56512.81
          }, {
            "month": "2024-01-01",
            "amount": 14831.32
          }, {
            "month": "2024-02-01",
            "amount": 20084.62
          }, {
            "month": "2024-03-01",
            "amount": 17279.62
          }, {
            "month": "2024-04-01",
            "amount": 23447.0
          }, {
            "month": "2024-05-01",
            "amount": 37041.56
          }, {
            "month": "2024-06-01",
            "amount": 28508.67
          }, {
            "month": "2024-07-01",
            "amount": 0.0
          }, {
            "month": "2024-08-01",
            "amount": 0.0
          }, {
            "month": "2024-09-01",
            "amount": 0.0
          }],
          "recurring_pledges_amount": null,
          "recurring_pledges": {
            "amount": 0.0,
            "percent": 0
          },
          "left_amount": 332278.22,
          "given_amount": 267721.78,
          "left_percent": 55,
          "monthly_goal": 50000.0,
          "total_amount": 267721.78,
          "given_percent": 45,
          "given_to_date": "2024-06-30",
          "total_percent": 45,
          "overdue_amount": 0.0,
          "expected_amount": 0.0,
          "no_gifts_amount": 0.0,
          "overdue_percent": 0,
          "expected_percent": 0,
          "expected_to_date": "2024-09-30",
          "no_gifts_percent": 0,
          "expected_from_date": "2024-06-01",
          "expected_number_months": 4,
          "exclude_no_gift_pledges": false
        }
      }), this.store.createRecord('goal', {
        goalType: "ytd",
        goalAmount: 75000.0,
        fundId: 145917,
        goalSettings: {
          campaignName: "Jane Smith Missionary"
        },
        goalData: {
          "start_date": "2023-10-01",
          "end_date": "2024-09-30",
          "monthly_gifts": [{
            "month": "2023-10-01",
            "amount": 19420.13
          }, {
            "month": "2023-11-01",
            "amount": 13263.63
          }, {
            "month": "2023-12-01",
            "amount": 5445.13
          }, {
            "month": "2024-01-01",
            "amount": 4520.13
          }, {
            "month": "2024-02-01",
            "amount": 5545.13
          }, {
            "month": "2024-03-01",
            "amount": 4370.13
          }, {
            "month": "2024-04-01",
            "amount": 4495.13
          }, {
            "month": "2024-05-01",
            "amount": 4322.41
          }, {
            "month": "2024-06-01",
            "amount": 1767.63
          }, {
            "month": "2024-07-01",
            "amount": 0.0
          }, {
            "month": "2024-08-01",
            "amount": 0.0
          }, {
            "month": "2024-09-01",
            "amount": 0.0
          }],
          "recurring_pledges_amount": null,
          "recurring_pledges": {
            "amount": 102845.56,
            "percent": 137
          },
          "left_amount": 0.0,
          "given_amount": 63149.45,
          "left_percent": 0,
          "monthly_goal": 6250.0,
          "total_amount": 88935.84,
          "given_percent": 84,
          "given_to_date": "2024-07-31",
          "total_percent": 119,
          "overdue_amount": 0.0,
          "expected_amount": 25786.39,
          "no_gifts_amount": 0.0,
          "overdue_percent": 0,
          "expected_percent": 34,
          "expected_to_date": "2024-09-30",
          "no_gifts_percent": 0,
          "expected_from_date": "2024-07-01",
          "expected_number_months": 3,
          "exclude_no_gift_pledges": false
        }
      }) // this.store.createRecord('goal', {
      //   goalType: "ytd",
      //   goalAmount: 500000.0,
      //   fundId: 145915,
      //   goalSettings: {},
      //   goalData: {
      //       "start_date": "2023-10-01",
      //       "end_date": "2024-09-30",
      //       "monthly_gifts": [
      //           {
      //               "month": "2023-10-01",
      //               "amount": 13145.88
      //           },
      //           {
      //               "month": "2023-11-01",
      //               "amount": 22371.38
      //           },
      //           {
      //               "month": "2023-12-01",
      //               "amount": 169362.82
      //           },
      //           {
      //               "month": "2024-01-01",
      //               "amount": 37378.88
      //           },
      //           {
      //               "month": "2024-02-01",
      //               "amount": 17603.88
      //           },
      //           {
      //               "month": "2024-03-01",
      //               "amount": 25323.88
      //           },
      //           {
      //               "month": "2024-04-01",
      //               "amount": 17423.46
      //           },
      //           {
      //               "month": "2024-05-01",
      //               "amount": 17422.21
      //           },
      //           {
      //               "month": "2024-06-01",
      //               "amount": 10470.96
      //           },
      //           {
      //               "month": "2024-07-01",
      //               "amount": 0.0
      //           },
      //           {
      //               "month": "2024-08-01",
      //               "amount": 0.0
      //           },
      //           {
      //               "month": "2024-09-01",
      //               "amount": 0.0
      //           }
      //       ],
      //       "recurring_pledges_amount": null,
      //       "recurring_pledges": {
      //           "amount": 6735.0,
      //           "percent": 1
      //       },
      //       "left_amount": 167617.9,
      //       "given_amount": 330503.35,
      //       "left_percent": 34,
      //       "monthly_goal": 41666.666666666664,
      //       "total_amount": 332382.1,
      //       "given_percent": 66,
      //       "given_to_date": "2024-06-30",
      //       "total_percent": 66,
      //       "overdue_amount": 0.0,
      //       "expected_amount": 1878.75,
      //       "no_gifts_amount": 0.0,
      //       "overdue_percent": 0,
      //       "expected_percent": 0,
      //       "expected_to_date": "2024-09-30",
      //       "no_gifts_percent": 0,
      //       "expected_from_date": "2024-06-01",
      //       "expected_number_months": 4,
      //       "exclude_no_gift_pledges": false
      //   }
      // })
      ];
    }

    markAllEventsRead() {
      this.model.dashboard.unreadEvents = [];
      let markRead = this.store.createRecord('mark-all-events-read', {
        fund_id: 1
      });
      return markRead.save();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "abilities", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "campaignService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "contactsCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "showAssignedTasks", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "showAssignedTasks"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "mockYtdGoalsForYoungLife", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "mockYtdGoalsForYoungLife"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "markAllEventsRead", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "markAllEventsRead"), _class.prototype)), _class);
  _exports.default = DashboardController;
});