define("donor-elf-web/routes/funds/fund/more/notifications/index", ["exports", "@ember/routing/route", "@ember/object"], function (_exports, _route, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    redirect(model) {
      let record = (0, _object.get)(model, 'record');

      if (record && !(0, _object.get)(record, 'isNew')) {
        this.transitionTo('funds.fund.more.notifications.edit');
      } else {
        this.transitionTo('funds.fund.more.notifications.none');
      }
    }

  });

  _exports.default = _default;
});