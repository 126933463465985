define("donor-elf-web/controllers/admin/users/add/referred-by", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@glimmer/tracking", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators"], function (_exports, _controller, _object, _service, _tracking, _emberChangeset, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let referredByValidation = {
    referredByFirstName: (0, _validators.validatePresence)(true),
    referredByLastName: (0, _validators.validatePresence)(true)
  };
  let AdminUserAddReferredByController = (_class = class AdminUserAddReferredByController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _defineProperty(this, "model", void 0);

      _defineProperty(this, "referralChangeset", void 0);

      _initializerDefineProperty(this, "unanswered", _descriptor3, this);

      _initializerDefineProperty(this, "showValidationErrors", _descriptor4, this);
    }

    templateWillDestroy() {
      this.referralChangeset = undefined;
      this.unanswered = true;
      this.showValidationErrors = false;
    }

    handleYesClicked() {
      this.unanswered = false;
      this.referralChangeset = new _emberChangeset.default({}, (0, _emberChangesetValidations.default)(referredByValidation), referredByValidation);
    }

    async save() {
      await this.referralChangeset.validate();

      if (this.referralChangeset.get('isValid')) {
        this.model.changeset.set('referredByFirstName', this.referralChangeset.get('referredByFirstName'));
        this.model.changeset.set('referredByLastName', this.referralChangeset.get('referredByLastName'));
        this.model.changeset.set('referredByEmail', this.referralChangeset.get('referredByEmail'));
        this.model.changeset.set('referredByOrganizationName', this.referralChangeset.get('referredByOrganizationName'));
        this.router.transitionTo('admin.users.add.funds');
      } else {
        this.showValidationErrors = true;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "unanswered", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showValidationErrors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleYesClicked", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleYesClicked"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class);
  _exports.default = AdminUserAddReferredByController;
});