define("donor-elf-web/routes/admin/users/show/info/funds", ["exports", "@ember/routing/route", "@ember/object", "rsvp", "@ember/service", "donor-elf-web/lib/model-utils"], function (_exports, _route, _object, _rsvp, _service, _modelUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    fundsService: (0, _service.inject)(),
    storeCache: (0, _service.inject)(),
    store: (0, _service.inject)(),
    abilities: (0, _service.inject)(),

    beforeModel() {
      return (0, _object.get)(this, 'fundsService').confirmValidFundToken();
    },

    model() {
      let user = this.modelFor('admin.users.show');
      let userId = (0, _object.get)(user, 'id');
      let storeCache = (0, _object.get)(this, 'storeCache');

      let logins = _rsvp.default.resolve(null);

      let emails = _rsvp.default.resolve(null);

      let fundGroupInfo = _rsvp.default.resolve([]);

      if ((0, _object.get)(this, 'abilities').hasAbility('view_logins_and_emails')) {
        logins = (0, _object.get)(this, 'store').query('admin/login', {
          filter: {
            user_id: userId
          }
        });
        emails = (0, _object.get)(this, 'store').query('admin/email', {
          filter: {
            user_id: userId
          }
        });
      }

      if ((0, _object.get)(this, 'abilities').hasAbility('view_fund_groups')) {
        fundGroupInfo = (0, _object.get)(this, 'store').query('admin/fund-group-user', {
          filter: {
            user_id: userId
          }
        }).then(fundGroupUsers => {
          if ((0, _object.get)(fundGroupUsers, 'length') > 0) {
            return (0, _object.get)(this, 'storeCache').indexSingleBy('admin/fund-group', 'id').then(fundGroupsById => {
              return fundGroupUsers.map(fgUser => {
                return {
                  fundGroupUser: fgUser,
                  fundGroup: fundGroupsById[(0, _object.get)(fgUser, 'fundGroupId')]
                };
              });
            });
          } else {
            return [];
          }
        });
      }

      let promise = _rsvp.default.hash({
        funds: this.store.query('admin/fund', {
          filter: {
            user_id: userId
          }
        }),
        userFunds: this.store.query('admin/user-fund', {
          filter: {
            user_id: userId
          }
        }),
        logins: logins,
        emails: emails,
        fundGroupInfo: fundGroupInfo
      });

      return promise.then(data => {
        var _data$userFunds;

        let userFunds = _modelUtils.default.filterPersisted(((_data$userFunds = data.userFunds) === null || _data$userFunds === void 0 ? void 0 : _data$userFunds.toArray()) || []);

        let fundsById = {};
        data.funds.forEach(fund => {
          fundsById[fund.id] = fund;
        });
        let hasUserFundForFundId = {};
        let fundPermissions = [];
        userFunds.forEach(userFund => {
          hasUserFundForFundId[(0, _object.get)(userFund, 'fundId')] = true;
          fundPermissions.push({
            userFund: userFund,
            fund: fundsById[(0, _object.get)(userFund, 'fundId')]
          });
        }), data.fundGroupInfo.forEach(item => {
          let fundGroup = (0, _object.get)(item, 'fundGroup');
          let fundGroupUser = (0, _object.get)(item, 'fundGroupUser');
          (0, _object.get)(fundGroup, 'fundIds').forEach(fundId => {
            if (!hasUserFundForFundId[fundId]) {
              fundPermissions.push({
                fundGroup: fundGroup,
                fundGroupUser: fundGroupUser,
                fund: fundsById[fundId]
              });
            }
          });
        });
        return {
          user: user,
          fundAndUserFunds: userFunds.map(userFund => {
            return {
              userFund: userFund,
              fund: fundsById[(0, _object.get)(userFund, 'fundId')]
            };
          }),
          fundPermissions: fundPermissions,
          logins: data.logins,
          emails: data.emails,
          fundGroupInfo: data.fundGroupInfo
        };
      });
    },

    actions: {
      remove(model) {
        if ((0, _object.get)(this.currentModel.fundAndUserFunds, 'length') === 1) {
          alert("You can't remove this fund since it's the only fund the user has permission to see.");
          return;
        }

        if (confirm('Are you sure you want to remove this fund permission?')) {
          (0, _object.get)(model, 'userFund').destroyRecord().then(() => {
            (0, _object.get)(this, 'storeCache').breakFindAllCache('admin/user-fund');
            this.refresh();
          });
        }
      }

    }
  });

  _exports.default = _default;
});