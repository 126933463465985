define("donor-elf-web/components/merge-contact/wizard/initial", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes class='row -tw-mt-4'>
    {{#each @contacts as |contact|}}
      <div class='col mt-3'>
        <LinkTo
          @route='funds.fund.contacts.contact'
          @model={{contact.id}}
        >
          {{contact.name}}
        </LinkTo>
        <div class='tw-text-sm tw-leading-tight'>
          <div>{{contact.address1}}</div>
          <div>{{contact.address2}}</div>
          {{#if contact.cityAndState}}
            <div>{{contact.cityAndState}} {{contact.zip}}</div>
          {{/if}}
          {{#if contact.hasEmails}}
            <div class='mt-2'>
              {{#each contact.emails.data as |item|}}
                <div>{{item.v}}</div>
              {{/each}}
            </div>
          {{/if}}
        </div>
      </div>
    {{/each}}
  </div>
  */
  {
    "id": "6w+uMfKn",
    "block": "[[[11,0],[17,1],[24,0,\"row -tw-mt-4\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,2]],null]],null],null,[[[1,\"    \"],[10,0],[14,0,\"col mt-3\"],[12],[1,\"\\n      \"],[8,[39,2],null,[[\"@route\",\"@model\"],[\"funds.fund.contacts.contact\",[30,3,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,3,[\"name\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[10,0],[14,0,\"tw-text-sm tw-leading-tight\"],[12],[1,\"\\n        \"],[10,0],[12],[1,[30,3,[\"address1\"]]],[13],[1,\"\\n        \"],[10,0],[12],[1,[30,3,[\"address2\"]]],[13],[1,\"\\n\"],[41,[30,3,[\"cityAndState\"]],[[[1,\"          \"],[10,0],[12],[1,[30,3,[\"cityAndState\"]]],[1,\" \"],[1,[30,3,[\"zip\"]]],[13],[1,\"\\n\"]],[]],null],[41,[30,3,[\"hasEmails\"]],[[[1,\"          \"],[10,0],[14,0,\"mt-2\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,3,[\"emails\",\"data\"]]],null]],null],null,[[[1,\"              \"],[10,0],[12],[1,[30,4,[\"v\"]]],[13],[1,\"\\n\"]],[4]],null],[1,\"          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[3]],null],[13]],[\"&attrs\",\"@contacts\",\"contact\",\"item\"],false,[\"each\",\"-track-array\",\"link-to\",\"if\"]]",
    "moduleName": "donor-elf-web/components/merge-contact/wizard/initial.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});