define("donor-elf-web/templates/funds/fund/contacts/contact/merge", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Wcb2wnV5",
    "block": "[[[10,\"ol\"],[14,0,\"breadcrumb row-bottom-margin\"],[12],[1,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[39,0],null,[[\"route\"],[\"funds.fund.contacts\"]],[[\"default\"],[[[[1,\"Contacts\"]],[]]]]],[13],[1,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[39,0],null,[[\"route\"],[\"funds.fund.contacts.contact\"]],[[\"default\"],[[[[1,[33,1,[\"contact\",\"name\"]]]],[]]]]],[13],[1,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[1,\"Merge\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"link-to\",\"model\",\"component\",\"-outlet\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/contacts/contact/merge.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});