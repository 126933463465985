define("donor-elf-web/templates/funds/fund/transactions/index-loading", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "tWRL9byq",
    "block": "[[[1,\"  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"col\"],[12],[1,\"\\n      \"],[1,[34,0]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"de-loading\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/transactions/index-loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});