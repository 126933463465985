define("donor-elf-web/validations/campaign-line-item", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.validateCampaignLineItem = validateCampaignLineItem;

  function validateCampaignLineItem(args) {
    let validation = {
      lineItemId: (0, _validators.validatePresence)({
        presence: true,
        message: "Can't be blank"
      }),
      amount: (0, _validators.validatePresence)({
        presence: true,
        message: "Can't be blank"
      })
    };

    if (args.campaignRequired) {
      validation.campaignId = (0, _validators.validatePresence)({
        presence: true,
        message: "Can't be blank"
      });
    }

    return validation;
  }
});