define("donor-elf-web/components/dashboard-donut-chart", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "@ember/object/computed", "@ember/service"], function (_exports, _component, _object, _runloop, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Highcharts
  } = window;
  /*
    attrs
    - percent
    - amount
    - chartColor - #f0ad4e (orange) | #0b68a1 (blue)
    - hideAmount - default false
    - onClick() event - optional
  */

  var _default = _component.default.extend({
    percent: 0,
    amount: 0,
    chartColor: '',
    hideAmount: false,
    hasClickHandler: (0, _computed.notEmpty)('onClick'),
    formatAmountService: (0, _service.inject)(),
    chartSettings: (0, _object.computed)('percent', 'amount', function () {
      let {
        percent,
        amount,
        hideAmount,
        chartColor
      } = (0, _object.getProperties)(this, 'percent', 'amount', 'hideAmount', 'chartColor');
      percent = Math.round(percent);
      let chartPercent = percent;

      if (chartPercent > 100) {
        chartPercent = 100;
      }

      let fillerPercent = 100 - chartPercent;
      let titleText = `<span>${percent}%</span>`;

      if (!hideAmount) {
        let formattedAmount = (0, _object.get)(this, 'formatAmountService').format(amount, {
          roundToDollar: true
        });
        titleText = `${titleText}<div style='font-size: 1rem; color: #636c72'>${formattedAmount}</div>`;
      }

      return {
        chart: {
          type: 'pie',
          spacing: [0, 0, 0, 0]
        },
        title: {
          text: titleText,
          align: 'center',
          verticalAlign: 'middle',
          useHTML: true,
          style: {
            fontSize: '2rem',
            color: chartColor,
            fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif'
          },
          y: hideAmount ? 7 : 0
        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          pie: {
            shadow: false,
            center: ['50%', '50%']
          }
        },
        series: [{
          size: '100%',
          innerSize: '90%',
          dataLabels: {
            enabled: false
          },
          data: [{
            y: chartPercent,
            color: chartColor
          }, {
            y: fillerPercent,
            color: '#eceeef'
          }]
        }]
      };
    }),

    didInsertElement() {
      this._super(...arguments);

      this.renderChart();
    },

    didUpdateAttrs() {
      this._super(...arguments);

      this.renderChart();
    },

    renderChart() {
      _runloop.run.scheduleOnce('afterRender', () => {
        Highcharts.chart(this.element.querySelector('.dashboard-donut-chart'), this.chartSettings);
      });
    },

    actions: {
      handleClick() {
        let onClick = (0, _object.get)(this, 'onClick');

        if (onClick) {
          onClick();
        }
      }

    }
  });

  _exports.default = _default;
});