define("donor-elf-web/lib/event-type-names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.eventTypeNames = void 0;
  const eventTypeNames = {
    1: 'New Donor',
    2: 'Late Donor',
    3: 'Stopped Giving Donor',
    4: 'Donor Info Changed',
    5: 'New Monthly Donor',
    8: 'New Pledge',
    9: 'Above Regular Amount',
    10: 'Below Regular Amount',
    11: 'Returning Late Donor',
    12: 'Returning Stopped Giving Donor',
    13: 'Catchup Gift',
    14: 'Extra Gift',
    15: 'Expiring Pledge',
    16: 'Unthanked Donor',
    17: 'Ask for Increase'
  };
  _exports.eventTypeNames = eventTypeNames;
});