define("donor-elf-web/lib/string-utils", ["exports", "ember-validators"], function (_exports, _emberValidators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isNumber = isNumber;
  _exports.normalizeAccentCharacters = normalizeAccentCharacters;

  function isNumber(val) {
    let result = (0, _emberValidators.validate)('number', val, {
      allowString: true,
      allowNone: false
    });
    return result === true;
  } // replaces characters with accents with only the character. 
  // 'Jõè' bcomes 'Joe'


  function normalizeAccentCharacters(val) {
    if (typeof val === 'string') {
      return val.normalize('NFKD').replace(/[\u0300-\u036f]/g, '');
    }

    return val;
  }
});