define("donor-elf-web/controllers/funds/fund/more/tnt-connect-import/xml/processing", ["exports", "@ember/controller", "@ember/object", "@ember/service", "donor-elf-web/lib/logger"], function (_exports, _controller, _object, _service, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    contactsCache: (0, _service.inject)(),
    fundsService: (0, _service.inject)(),
    store: (0, _service.inject)(),
    checkFundUploadProgress: false,

    reset() {
      (0, _object.setProperties)(this, {
        checkFundUploadProgress: false
      });
    },

    actions: {
      handleS3UploadComplete(s3Upload) {
        (0, _object.get)(this, 'fundsService').confirmValidFundToken().then(() => {
          let importRecord = (0, _object.get)(this, 'store').createRecord('tnt-connect-import', {
            importType: 'xml',
            s3Key: (0, _object.get)(s3Upload, 'key'),
            includeGifts: (0, _object.get)(this, 'model.includeGifts'),
            includeTasks: (0, _object.get)(this, 'model.includeTasks'),
            includeHistory: (0, _object.get)(this, 'model.includeHistory'),
            includeGroups: (0, _object.get)(this, 'model.includeGroups'),
            includePledges: (0, _object.get)(this, 'model.includePledges')
          });
          importRecord.save().then(() => {
            (0, _object.set)(this, 'checkFundUploadProgress', true);
          });
        });
      },

      handleS3UploadError() {
        _logger.default.error('There was an error uploading the TntConnect XML file');
      },

      onFundUploadComplete() {
        (0, _object.get)(this, 'contactsCache').loadContacts({
          forceLoad: true
        }).then(() => {
          this.transitionToRoute('funds.fund.more.tnt-connect-import.xml.complete');
        });
      }

    }
  });

  _exports.default = _default;
});