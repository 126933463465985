define("donor-elf-web/components/contact/details/v2tasks", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/service", "@ember/utils"], function (_exports, _component, _templateFactory, _component2, _object, _tracking, _service, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    {{did-insert this.templateDidInsert}}
    {{will-destroy this.templateWillDestroy}}
    ...attributes
  >
    {{#if this.showCard}}
      <Card
        class='row-bottom-margin'
      >
        <div class='d-flex border-bottom mb-2_5'>
          <h4 class='card-title'>Tasks</h4>
          {{#if (has-ability 'edit_contact')}}
            <div class='ml-auto'>
              <button
                {{on 'click' this.addTask}}
                class='btn btn-sm btn-outline-warning'
                type='button'
              >
                {{svg-jar 'plus'}}
                Add
              </button>
            </div>
          {{/if}}
        </div>
        <div
          class='tw-max-h-96 overflow-auto pr-2 pb-2'
        >
          {{#each this.sortedTasks as |task|}}
            <Task::Info
              @task={{task}}
              @onCancelEditTask={{this.handleCancelEditTask}}
              @onTaskSaved={{this.handleTaskSaved}}
              @onTaskDeleted={{this.handleTaskDeleted}}
              class='{{if (has-next task this.sortedTasks) 'mb-2_5'}}'
            />
          {{/each}}
        </div>
      </Card>
    {{/if}}
  </div>
  */
  {
    "id": "zwydRXj0",
    "block": "[[[11,0],[17,1],[4,[38,0],[[30,0,[\"templateDidInsert\"]]],null],[4,[38,1],[[30,0,[\"templateWillDestroy\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"showCard\"]],[[[1,\"    \"],[8,[39,3],[[24,0,\"row-bottom-margin\"]],null,[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"d-flex border-bottom mb-2_5\"],[12],[1,\"\\n        \"],[10,\"h4\"],[14,0,\"card-title\"],[12],[1,\"Tasks\"],[13],[1,\"\\n\"],[41,[28,[37,4],[\"edit_contact\"],null],[[[1,\"          \"],[10,0],[14,0,\"ml-auto\"],[12],[1,\"\\n            \"],[11,\"button\"],[24,0,\"btn btn-sm btn-outline-warning\"],[24,4,\"button\"],[4,[38,5],[\"click\",[30,0,[\"addTask\"]]],null],[12],[1,\"\\n              \"],[1,[28,[35,6],[\"plus\"],null]],[1,\"\\n              Add\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"tw-max-h-96 overflow-auto pr-2 pb-2\"],[12],[1,\"\\n\"],[42,[28,[37,8],[[28,[37,8],[[30,0,[\"sortedTasks\"]]],null]],null],null,[[[1,\"          \"],[8,[39,9],[[16,0,[29,[[52,[28,[37,10],[[30,2],[30,0,[\"sortedTasks\"]]],null],\"mb-2_5\"]]]]],[[\"@task\",\"@onCancelEditTask\",\"@onTaskSaved\",\"@onTaskDeleted\"],[[30,2],[30,0,[\"handleCancelEditTask\"]],[30,0,[\"handleTaskSaved\"]],[30,0,[\"handleTaskDeleted\"]]]],null],[1,\"\\n\"]],[2]],null],[1,\"      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[13]],[\"&attrs\",\"task\"],false,[\"did-insert\",\"will-destroy\",\"if\",\"card\",\"has-ability\",\"on\",\"svg-jar\",\"each\",\"-track-array\",\"task/info\",\"has-next\"]]",
    "moduleName": "donor-elf-web/components/contact/details/v2tasks.hbs",
    "isStrictMode": false
  });

  let ContactV2TasksComponent = (_class = class ContactV2TasksComponent extends _component2.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "addSomethingModal", _descriptor, this);

      _initializerDefineProperty(this, "taskService", _descriptor2, this);

      _defineProperty(this, "tasks", void 0);

      _initializerDefineProperty(this, "sortedTasks", _descriptor3, this);

      _defineProperty(this, "addTaskSubsciptionToken", void 0);

      this.tasks = this.args.model;
      this.sortTheTasks();
    }

    get showCard() {
      return this.sortedTasks.length > 0;
    } // - new tasks are always at the top
    // - next come unfinished tasks
    //   - overdue
    //   - no due date (dueDate is considered today for sorting purposes)
    //   - due date in the future
    // - finally finished tasks sorted by completedDate


    sortTheTasks() {
      let today = new Date();
      this.sortedTasks = this.tasks.sort((a, b) => {
        if (a.isNew) {
          return -1;
        }

        if (b.isNew) {
          return 1;
        }

        if (a.completedDate) {
          if (b.completedDate) {
            return (0, _utils.compare)(b.completedDate, a.completedDate);
          } else {
            // finished goes after unfinished
            return 1;
          }
        } else if (b.completedDate) {
          // unfinished goes before unfinished
          return -1;
        } else {
          // both unfinished
          return (0, _utils.compare)(a.dueDate || today, b.dueDate || today);
        }
      });
    }

    templateDidInsert() {
      this.unsubscribeFromAddTaskIfPresent();
      this.addTaskSubsciptionToken = this.addSomethingModal.subscribeToTaskAdded(this.handleTaskAddedFromAddModal);
    }

    templateWillDestroy() {
      this.unsubscribeFromAddTaskIfPresent();
    }

    unsubscribeFromAddTaskIfPresent() {
      if (this.addTaskSubsciptionToken) {
        this.addSomethingModal.unsubscribeWithToken(this.addTaskSubsciptionToken);
        this.addTaskSubsciptionToken = undefined;
      }
    }

    handleTaskAddedFromAddModal(_message, data) {
      if (data.task.contactId === this.args.contact.id) {
        this.tasks = [data.task, ...this.tasks]; // not sorting because we always want the new task to be at the top

        this.sortedTasks = [data.task, ...this.sortedTasks];
      }
    }

    addTask() {
      this.addSomethingModal.open({
        type: 'task'
      });
    }

    handleCancelEditTask(task) {
      if (task.isNew) {
        this.tasks = this.tasks.filter(x => x !== task);
        this.sortTheTasks();
      }
    }

    handleTaskDeleted(task) {
      this.tasks = this.tasks.filter(x => x !== task);
      this.sortTheTasks();
    }

    handleTaskSaved() {// intentionally not doing anything so the tasks won't resort
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "addSomethingModal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "taskService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "sortedTasks", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleTaskAddedFromAddModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleTaskAddedFromAddModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addTask", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleCancelEditTask", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleCancelEditTask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleTaskDeleted", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleTaskDeleted"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleTaskSaved", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleTaskSaved"), _class.prototype)), _class);
  _exports.default = ContactV2TasksComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ContactV2TasksComponent);
});