define("donor-elf-web/components/contact/details/properties/custom-property/edit-value", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "donor-elf-web/components/form/list-selection", "donor-elf-web/lib/array-utils"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _service, _listSelection, _arrayUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.textValueType}}
    <Form::Underline::Text
      @changeset={{@changeset}}
      @property='v'
      @showValidationErrors={{@showValidationErrors}}
      ...attributes
    />
  {{else if this.booleanType}}
    <Form::Underline::Select
      @changeset={{@changeset}}
      @property='v'
      @options={{this.booleanOptions}}
      @showValidationErrors={{@showValidationErrors}}
      @dropdownInitiallyOpened={{@dropdownInitiallyOpened}}
      @dropdownMatchTriggerWidth={{true}}
      @dropdownContentClass='tw-z-1100'
      ...attributes
    />
  {{else if this.listType}}
    <Form::ListSelection
      @listOptions={{this.listSelectionOptions}}
      @singleSelection={{this.singleSelectionList}}
      @userCanAddOptions={{true}}
      @listHeightClass='tw-max-h-56 tw-shadow'
      @onSelectionsChanged={{this.handleListSelectionsChanged}}
      ...attributes
    />
    {{#if this.errorMessage}}
      <div class='tw-text-sm text-danger mt-1'>
        {{this.errorMessage}}
      </div>
    {{/if}}
  {{/if}}
  */
  {
    "id": "dId1Sm01",
    "block": "[[[41,[30,0,[\"textValueType\"]],[[[1,\"  \"],[8,[39,1],[[17,1]],[[\"@changeset\",\"@property\",\"@showValidationErrors\"],[[30,2],\"v\",[30,3]]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"booleanType\"]],[[[1,\"  \"],[8,[39,2],[[17,1]],[[\"@changeset\",\"@property\",\"@options\",\"@showValidationErrors\",\"@dropdownInitiallyOpened\",\"@dropdownMatchTriggerWidth\",\"@dropdownContentClass\"],[[30,2],\"v\",[30,0,[\"booleanOptions\"]],[30,3],[30,4],true,\"tw-z-1100\"]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"listType\"]],[[[1,\"  \"],[8,[39,3],[[17,1]],[[\"@listOptions\",\"@singleSelection\",\"@userCanAddOptions\",\"@listHeightClass\",\"@onSelectionsChanged\"],[[30,0,[\"listSelectionOptions\"]],[30,0,[\"singleSelectionList\"]],true,\"tw-max-h-56 tw-shadow\",[30,0,[\"handleListSelectionsChanged\"]]]],null],[1,\"\\n\"],[41,[30,0,[\"errorMessage\"]],[[[1,\"    \"],[10,0],[14,0,\"tw-text-sm text-danger mt-1\"],[12],[1,\"\\n      \"],[1,[30,0,[\"errorMessage\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[]],null]],[]]]],[]]]],[\"&attrs\",\"@changeset\",\"@showValidationErrors\",\"@dropdownInitiallyOpened\"],false,[\"if\",\"form/underline/text\",\"form/underline/select\",\"form/list-selection\"]]",
    "moduleName": "donor-elf-web/components/contact/details/properties/custom-property/edit-value.hbs",
    "isStrictMode": false
  });

  const TextTypes = {
    s: true,
    n: true,
    c: true
  };
  let ContactDetailsPropertiesCustomPropertyEditValueComponent = (_class = class ContactDetailsPropertiesCustomPropertyEditValueComponent extends _component2.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "contactsCache", _descriptor, this);

      _initializerDefineProperty(this, "listSelectionOptions", _descriptor2, this);

      if (this.listType) {
        let currentValue = args.changeset.get('v') || {};
        let options = args.propertyInfo.o || this.contactsCache.customPropertiesByLowecaseLabel[args.propertyInfo.l.toLowerCase()].o || [];
        this.listSelectionOptions = (0, _arrayUtils.sortByProperties)(options.map((x, index) => {
          return new _listSelection.FormListSelectionOption({
            val: x,
            selected: currentValue[x] || false,
            index
          });
        }), ['selected:desc', 'index:asc']);
      }
    }

    get textValueType() {
      return this.args.propertyInfo.t === undefined || TextTypes[this.args.propertyInfo.t] === true;
    }

    get booleanType() {
      return this.args.propertyInfo.t === 'b';
    }

    get listType() {
      return this.args.propertyInfo.t === 'l';
    }

    get booleanOptions() {
      return [{
        val: true,
        label: 'Yes'
      }, {
        val: false,
        label: 'No'
      }];
    }

    get singleSelectionList() {
      var _this$args$propertyIn, _this$args$propertyIn2, _this$contactsCache$c, _this$contactsCache$c2;

      return ((_this$args$propertyIn = this.args.propertyInfo) === null || _this$args$propertyIn === void 0 ? void 0 : (_this$args$propertyIn2 = _this$args$propertyIn.s) === null || _this$args$propertyIn2 === void 0 ? void 0 : _this$args$propertyIn2.single_selection) || ((_this$contactsCache$c = this.contactsCache.customPropertiesByLowecaseLabel[this.args.propertyInfo.l.toLowerCase()]) === null || _this$contactsCache$c === void 0 ? void 0 : (_this$contactsCache$c2 = _this$contactsCache$c.s) === null || _this$contactsCache$c2 === void 0 ? void 0 : _this$contactsCache$c2.single_selection);
    }

    handleListSelectionsChanged(args) {
      let newValue = {};

      for (let option of args.selectedListOptions) {
        newValue[option.val] = true;
      }

      this.args.changeset.set('v', newValue);
      this.args.changeset.set('o', args.allListOptions.map(x => x.val));
    }

    get errorMessage() {
      if (this.args.showValidationErrors) {
        var _this$args$changeset$, _this$args$changeset$2;

        let validationErrors = ((_this$args$changeset$ = this.args.changeset.error) === null || _this$args$changeset$ === void 0 ? void 0 : (_this$args$changeset$2 = _this$args$changeset$.v) === null || _this$args$changeset$2 === void 0 ? void 0 : _this$args$changeset$2.validation) || [];
        return validationErrors.join(', ');
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "contactsCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "listSelectionOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handleListSelectionsChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleListSelectionsChanged"), _class.prototype)), _class);
  _exports.default = ContactDetailsPropertiesCustomPropertyEditValueComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ContactDetailsPropertiesCustomPropertyEditValueComponent);
});