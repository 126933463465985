define("donor-elf-web/components/coaching-edit-field", ["exports", "@ember/component", "@ember/object", "@ember/utils", "@ember/service"], function (_exports, _component, _object, _utils, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* attrs
    - reportChangeset
    - field
    - individual
    - checkForErrors
      - number that increments when the component should check for errors
  */
  var _default = _component.default.extend({
    abilities: (0, _service.inject)(),
    coachingService: (0, _service.inject)(),
    reportChangeset: null,
    field: null,
    individual: null,
    canEditGoal: (0, _object.computed)('field.t', function () {
      if ((0, _object.get)(this, 'abilities').noCoachingAbility('edit_goal')) {
        return false;
      }

      let fieldType = (0, _object.get)(this, 'field.t');
      return (0, _object.get)(this, 'coachingService.overallGoalTypes')[fieldType] || (0, _object.get)(this, 'coachingService.intervalGoalTypes')[fieldType] || false;
    }),
    showPrevVal: (0, _object.computed)('reportChangeset.id', 'field.id', function () {
      let fieldType = (0, _object.get)(this, 'field.t');
      return fieldType !== 'string' && fieldType !== 'tasks' && fieldType !== 'response' && (0, _object.get)(this, 'prevReport') != null;
    }),
    prevReport: (0, _object.computed)('reportChangeset.id', function () {
      let {
        reportChangeset,
        individual
      } = (0, _object.getProperties)(this, 'reportChangeset', 'individual');
      let reportId = (0, _object.get)(reportChangeset, 'id');
      let report = (0, _object.get)(individual, 'sortedReports').find(x => x.id === reportId);
      return individual.previousReport(report);
    }),
    errorMessage: (0, _object.computed)('checkForErrors', function () {
      let errors = (0, _object.get)(this, 'reportChangeset.error.sectionFieldValues.validation');

      if ((0, _utils.isEmpty)(errors)) {
        return undefined;
      }

      let message = undefined;
      let fieldId = (0, _object.get)(this, 'field.id');
      let errorMessages = errors[0].split('|');
      errorMessages.forEach(error => {
        let errorFieldId = error.split(':')[0];

        if (errorFieldId === fieldId) {
          message = error.split(':')[1].trim();
        }
      });
      return message;
    })
  });

  _exports.default = _default;
});