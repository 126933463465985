define("donor-elf-web/validations/v2-task", ["exports", "@ember/utils", "donor-elf-web/lib/object-utils", "ember-changeset-validations/validators"], function (_exports, _utils, _objectUtils, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    actionId: (0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    }),
    summary: (0, _validators.validateLength)({
      max: 1024,
      message: "Can't be longer than 1024 characters"
    }),
    contactId: (_key, newValue, _oldValue, changes, content) => {
      let contactIds = (0, _objectUtils.assignSingleProperty)(content, changes, 'contactIds');

      if ((0, _utils.isEmpty)(contactIds) && (0, _utils.isEmpty)(newValue)) {
        return "Can't be blank";
      }

      return true;
    },
    journalStageId: (_key, newValue, _oldValue, changes, content) => {
      let journalId = (0, _objectUtils.assignSingleProperty)(content, changes, 'journalId');

      if ((0, _utils.isPresent)(journalId) && (0, _utils.isEmpty)(newValue)) {
        return "Can't be blank since a Journal was selected";
      }

      return true;
    }
  };
  _exports.default = _default;
});