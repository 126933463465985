define("donor-elf-web/templates/funds/fund/groups/group/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "2EoKS/HT",
    "block": "[[[8,[39,0],[[24,0,\"row-bottom-margin\"],[4,[38,1],[[30,0,[\"templateDidInsert\"]]],null],[4,[38,2],[[30,0,[\"templateWillDestroy\"]]],null]],null,[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"d-flex\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex-grow-1\"],[12],[1,\"\\n      \"],[11,\"input\"],[16,2,[30,0,[\"q\"]]],[24,\"placeholder\",\"Filter Contacts\"],[24,0,\"form-control mb-3\"],[4,[38,3],[\"input\",[30,0,[\"updateFilter\"]]],null],[4,[38,4],[[30,0,[\"updateFilter\"]]],null],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"pl-2\"],[12],[1,\"\\n      \"],[8,[39,5],null,[[\"@menuItems\",\"@regularSizeButton\"],[[30,0,[\"actionMenuItems\"]],true]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"table\"],[14,0,\"table\"],[12],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,0,[\"filteredContacts\"]]],null]],null],null,[[[1,\"        \"],[8,[39,8],null,[[\"@contact\",\"@group\",\"@onRemovedFromGroup\"],[[30,1],[30,0,[\"model\",\"group\"]],[30,0,[\"handleRemovedFromGroup\"]]]],null],[1,\"\\n\"]],[1]],[[[1,\"        \"],[10,\"td\"],[14,0,\"text-muted\"],[12],[1,\"\\n\"],[41,[30,0,[\"filterApplied\"]],[[[1,\"            No contacts match the '\"],[1,[30,0,[\"q\"]]],[1,\"' filter\\n\"]],[]],[[[1,\"            It looks like no contacts are part of the '\"],[1,[30,2,[\"group\"]]],[1,\"' group yet\\n\"]],[]]],[1,\"        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"contact\",\"@model\"],false,[\"card\",\"did-insert\",\"will-destroy\",\"debounce-on\",\"on-enter\",\"report-actions\",\"each\",\"-track-array\",\"group-contact-row\",\"if\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/groups/group/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});