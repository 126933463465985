define("donor-elf-web/components/modal/footer", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='{{if @px2 'px-2' 'px-3'}} tw-py-1 border-top bg-gray-100 rounded-bottom' ...attributes>
    {{yield}}
  </div>
  */
  {
    "id": "vTyDbiuS",
    "block": "[[[11,0],[16,0,[29,[[52,[30,1],\"px-2\",\"px-3\"],\" tw-py-1 border-top bg-gray-100 rounded-bottom\"]]],[17,2],[12],[1,\"\\n  \"],[18,3,null],[1,\"\\n\"],[13]],[\"@px2\",\"&attrs\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "donor-elf-web/components/modal/footer.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});