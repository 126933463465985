define("donor-elf-web/controllers/funds/fund/pledges/index", ["exports", "@ember/object/computed", "@ember/controller", "@ember/object", "@ember/utils", "@ember/service", "donor-elf-web/lib/date-utils", "donor-elf-web/lib/object-utils", "ember-parachute", "ember-changeset", "ember-changeset-validations", "donor-elf-web/validators/date-or-empty", "file-saver", "donor-elf-web/lib/export-contacts-to-csv-rows", "donor-elf-web/lib/pledge-utils"], function (_exports, _computed, _controller, _object, _utils, _service, _dateUtils, _objectUtils, _emberParachute, _emberChangeset, _emberChangesetValidations, _dateOrEmpty, _fileSaver, _exportContactsToCsvRows, _pledgeUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.queryParams = void 0;
  const {
    moment,
    Papa
  } = window;
  const queryParams = new _emberParachute.default({
    startDate: {
      defaultValue: '',
      refresh: true
    },
    endDate: {
      defaultValue: '',
      refresh: true
    },
    frequency: {
      defaultValue: '',
      refresh: true
    },
    removeDuplicatesFromSameContact: {
      defaultValue: '',
      refresh: true
    }
  });
  _exports.queryParams = queryParams;
  const filterValidation = {
    startDate: (0, _dateOrEmpty.default)(),
    endDate: (0, _dateOrEmpty.default)()
  };

  var _default = _controller.default.extend(queryParams.Mixin, {
    contactsCache: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    formatDateService: (0, _service.inject)(),
    fundGoalService: (0, _service.inject)(),
    fundsService: (0, _service.inject)(),
    settings: (0, _service.inject)(),
    store: (0, _service.inject)(),
    startDate: '',
    endDate: '',
    frequency: '',
    removeDuplicatesFromSameContact: '',
    filterExpanded: false,
    filterChangeset: null,
    loading: false,
    error: null,
    queryParamsChanged: (0, _computed.or)('queryParamsState.{startDate,endDate,frequency,removeDuplicatesFromSameContact}.changed'),
    excludeNoGiftsFromGoal: (0, _computed.alias)('settings.fundSettings.lookingForwardExcludePledgesNoGifts'),
    excludeOverdueFromGoal: (0, _computed.alias)('settings.fundSettings.lookingForwardExcludePledgesDelinquent'),
    isAnnualRollingGoal: (0, _computed.alias)('settings.userSettings.showAnnualGoal'),

    setup({
      queryParams
    }) {
      this.fetchData(queryParams);
    },

    queryParamsDidChange({
      shouldRefresh,
      queryParams
    }) {
      // if any query param with `refresh: true` is changed, `shouldRefresh` is `true`
      if (shouldRefresh) {
        this.fetchData(queryParams);
      }
    },

    reset(event, isExiting) {
      if (isExiting) {
        this.resetQueryParams();
        (0, _object.setProperties)(this, {
          filterChangeset: null,
          filterExpanded: false,
          error: null,
          itemsAll: [],
          monthlyGoal: null
        });
      }
    },

    fetchData(queryParams) {
      (0, _object.setProperties)(this, {
        loading: true,
        error: null
      });
      let filter = {
        startDate: queryParams.startDate,
        endDate: queryParams.endDate,
        frequency: queryParams.frequency,
        removeDuplicatesFromSameContact: queryParams.removeDuplicatesFromSameContact == 'true',
        includeMetaMonthlyGoal: true
      };

      if ((0, _utils.isEmpty)(filter.startDate)) {
        filter.startDate = moment().startOf('month').format('YYYY-MM-DD');
        (0, _object.set)(this, 'startDate', filter.startDate);
      }

      if ((0, _utils.isEmpty)(filter.endDate)) {
        filter.endDate = moment().add(11, 'months').endOf('month').format('YYYY-MM-DD');
        (0, _object.set)(this, 'endDate', filter.endDate);
      }

      let filterRecord = {
        startDate: _dateUtils.default.deserializeDateOnly(filter.startDate),
        endDate: _dateUtils.default.deserializeDateOnly(filter.endDate),
        frequency: filter.frequency,
        removeDuplicatesFromSameContact: filter.removeDuplicatesFromSameContact
      };
      let filterChangeset = new _emberChangeset.default(filterRecord, (0, _emberChangesetValidations.default)(filterValidation), filterValidation);
      (0, _object.set)(this, 'filterChangeset', filterChangeset);
      return (0, _object.get)(this, 'store').query('pledge', {
        filter: _objectUtils.default.decamelizeKeys(filter)
      }).then(pledges => {
        let monthlyGoal = (0, _object.get)(pledges, 'meta.monthly_goal');

        if (monthlyGoal) {
          monthlyGoal = Number(monthlyGoal);
        }

        (0, _object.set)(this, 'monthlyGoal', monthlyGoal);
        let contactsCache = (0, _object.get)(this, 'contactsCache');
        (0, _object.set)(this, 'itemsAll', pledges.map(pledge => {
          return {
            contact: contactsCache.getById((0, _object.get)(pledge, 'contactId')),
            pledge
          };
        }));
        this.setItemGroups();
        this.setItemGroupCalculations();
        (0, _object.set)(this, 'loading', false);
      }, error => {
        (0, _object.set)(this, 'error', error);
      });
    },

    filterMessage: (0, _object.computed)('filterChangeset.{startDate,endDate,frequency}', function () {
      let messages = [];
      let {
        formatDateService,
        filterChangeset
      } = (0, _object.getProperties)(this, 'formatDateService', 'filterChangeset');
      let {
        startDate,
        endDate,
        frequency,
        removeDuplicatesFromSameContact
      } = (0, _object.getProperties)(filterChangeset, 'startDate', 'endDate', 'frequency', 'removeDuplicatesFromSameContact');
      messages.push(`Pledges between ${formatDateService.format(startDate, {
        fourYear: true
      })} and ${formatDateService.format(endDate, {
        fourYear: true
      })}`);

      if (!(0, _utils.isEmpty)(frequency)) {
        let frequencyLabel = _pledgeUtils.pledgeFrequencyLabels[frequency];
        messages.push(`${frequencyLabel} frequency`);
      }

      if (removeDuplicatesFromSameContact) {
        messages.push('removing duplicates from the same contact');
      }

      if (messages.length === 0) {
        return 'All Pledges';
      }

      return messages.join(', ');
    }),
    actionsMenuItems: (0, _object.computed)('filterExpanded', function () {
      return [{
        label: (0, _object.get)(this, 'filterExpanded') ? 'Collapse Filter' : 'Adjust Filter',
        svgJar: 'filter',
        onClick: () => {
          this.send('toggleExpanded');
        }
      }, {
        label: 'Download',
        svgJar: 'download',
        onClick: () => {
          this.send('download');
        }
      }];
    }),
    frequencyOptions: (0, _object.computed)(function () {
      let options = [{
        val: '',
        label: 'All'
      }];
      Object.keys(_pledgeUtils.pledgeFrequencyLabels).forEach(key => {
        if (_pledgeUtils.pledgeFrequencyLabels[key] !== 'Weekly') {
          options.push({
            val: key,
            label: _pledgeUtils.pledgeFrequencyLabels[key]
          });
        }
      });
      return options;
    }),
    // Don't show monthly summary for YTD goal types because it's confusing
    showSummaryCard: (0, _object.computed)(function () {
      return !this.fundGoalService.hasYtdGoalType;
    }).volatile(),

    // sets itemsNoGifts, itemsOverdue, itemsGiving
    setItemGroups() {
      let pledgesOverdueDate = (0, _object.get)(this, 'settings.fundSettings.pledgesDelinquentDate');
      let itemsNoGifts = [];
      let itemsOverdue = [];
      let itemsGiving = [];
      (0, _object.get)(this, 'itemsAll').forEach(item => {
        let nextDate = (0, _object.get)(item, 'pledge.nextExpectedDonationDate'); // pledges with an end date coming up but no more payments are due will not
        // have a nextExpectedDonationDate

        if (!(0, _utils.isEmpty)(nextDate) && (0, _object.get)(item, 'pledge.nextExpectedDonationDate') < pledgesOverdueDate) {
          itemsOverdue.push(item);
        } else {
          if ((0, _object.get)(item, 'pledge.hasGifts')) {
            itemsGiving.push(item);
          } else {
            itemsNoGifts.push(item);
          }
        }
      });
      (0, _object.setProperties)(this, {
        itemsNoGifts,
        itemsOverdue,
        itemsGiving
      });
    },

    setItemGroupCalculations() {
      let monthlyGoal = (0, _object.get)(this, 'monthlyGoal');
      let calcs = {};
      ['itemsAll', 'itemsNoGifts', 'itemsOverdue', 'itemsGiving'].forEach(groupName => {
        let items = (0, _object.get)(this, groupName);

        if (items.length) {
          let monthlyAmount = items.map(item => (0, _object.get)(item, 'pledge.monthlyAmount')).reduce((prevVal, curVal) => prevVal + curVal);
          let percentOfGoal = monthlyGoal ? Math.round(monthlyAmount * 100 / monthlyGoal) : 0;

          if ((0, _object.get)(this, 'isAnnualRollingGoal')) {
            monthlyAmount *= 12;
          }

          calcs[groupName] = {
            monthlyAmount,
            percentOfGoal
          };
        } else {
          calcs[groupName] = {
            monthlyAmount: 0,
            percentOfGoal: 0
          };
        }
      });
      (0, _object.set)(this, 'itemGroupCalculations', calcs);
    },

    // { itemsAll: { monthlyAverage, percentOfGoal }, itemsNoGifts: {}, itemsOverdue: {}, itemsGiving: {} }
    itemGroupCalculations: {},
    itemsAll: [],
    itemsNoGifts: [],
    sortedItemsNoGifts: (0, _computed.sort)('itemsNoGifts', 'sortBy'),
    itemsOverdue: [],
    sortedItemsOverdue: (0, _computed.sort)('itemsOverdue', 'sortBy'),
    itemsGiving: [],
    sortedItemsGiving: (0, _computed.sort)('itemsGiving', 'sortBy'),
    monthlyGoal: null,
    sortByProperty: 'contact.name',
    sortByDirection: 'asc',
    sortBy: (0, _object.computed)('sortByProperty', 'sortByDirection', function () {
      let {
        sortByProperty,
        sortByDirection
      } = (0, _object.getProperties)(this, 'sortByProperty', 'sortByDirection');
      return [`${sortByProperty}:${sortByDirection}`];
    }),
    overduePledgesInDanger: (0, _object.computed)('excludeOverdueFromGoal', 'itemGroupCalculations.itemsOverdue.monthlyAmount', function () {
      return (0, _object.get)(this, 'excludeOverdueFromGoal') && (0, _object.get)(this, 'itemGroupCalculations.itemsOverdue.monthlyAmount') > 0;
    }),
    noGiftsPledgesInDanger: (0, _object.computed)('excludeNoGiftsFromGoal', 'itemGroupCalculations.itemsNoGifts.monthlyAmount', function () {
      return (0, _object.get)(this, 'excludeNoGiftsFromGoal') && (0, _object.get)(this, 'itemGroupCalculations.itemsNoGifts.monthlyAmount') > 0;
    }),
    actions: {
      applyFilter() {
        let filterChangeset = (0, _object.get)(this, 'filterChangeset');

        if ((0, _object.get)(filterChangeset, 'isPristine')) {
          alert('No filter values have changed');
          return;
        }

        (0, _object.setProperties)(this, {
          startDate: _dateUtils.default.serializeToDateOnly((0, _object.get)(filterChangeset, 'startDate'), {
            invalidValue: ''
          }),
          endDate: _dateUtils.default.serializeToDateOnly((0, _object.get)(filterChangeset, 'endDate'), {
            invalidValue: ''
          }),
          frequency: (0, _object.get)(filterChangeset, 'frequency'),
          removeDuplicatesFromSameContact: (0, _object.get)(filterChangeset, 'removeDuplicatesFromSameContact')
        });
      },

      sortBy(property) {
        let currentSortByProperty = (0, _object.get)(this, 'sortByProperty');

        if (currentSortByProperty === property) {
          if ((0, _object.get)(this, 'sortByDirection') === 'asc') {
            (0, _object.set)(this, 'sortByDirection', 'desc');
          } else {
            (0, _object.set)(this, 'sortByDirection', 'asc');
          }
        } else {
          (0, _object.setProperties)(this, {
            sortByProperty: property,
            sortByDirection: 'asc'
          });
        }
      },

      toggleExpanded() {
        this.toggleProperty('filterExpanded');
      },

      download() {
        let formatDateService = (0, _object.get)(this, 'formatDateService');
        let rows = [];
        let headerRow = ['Group', 'Contact', 'Start Date', 'End Date', 'Frequency', 'Amount', 'Monthly Amount', 'Annual Amount', 'Last Gift', 'Next Gift', 'Notes', '---'];

        let headerInfo = _exportContactsToCsvRows.default.addContactsHeaderColumns(headerRow, (0, _object.get)(this, 'contactsCache.contacts'));

        rows.push(headerRow);

        let createRow = item => {
          let row = ['', (0, _object.get)(item, 'contact.name'), formatDateService.formatForFile((0, _object.get)(item, 'pledge.startDate')), formatDateService.formatForFile((0, _object.get)(item, 'pledge.endDate')), (0, _object.get)(item, 'pledge.frequencyLabel'), (0, _object.get)(item, 'pledge.amount'), (0, _object.get)(item, 'pledge.monthlyAmount'), (0, _object.get)(item, 'pledge.annualAmount'), formatDateService.formatForFile((0, _object.get)(item, 'pledge.lastDonationDate')), formatDateService.formatForFile((0, _object.get)(item, 'pledge.nextExpectedDonationDate')), (0, _object.get)(item, 'pledge.notes'), ''];

          _exportContactsToCsvRows.default.addContactInfoToRow(row, (0, _object.get)(item, 'contact'), headerInfo);

          return row;
        };

        let createGroupHeaderRow = groupName => {
          let groupRow = [groupName];

          for (let i = 1; i < headerRow.length; i++) {
            groupRow.push('');
          }

          return groupRow;
        };

        rows.push(createGroupHeaderRow('Overdue Pledges'));
        (0, _object.get)(this, 'sortedItemsOverdue').forEach(item => {
          rows.push(createRow(item));
        });
        rows.push([]);
        rows.push(createGroupHeaderRow('Pledges without Gifts'));
        (0, _object.get)(this, 'sortedItemsNoGifts').forEach(item => {
          rows.push(createRow(item));
        });
        rows.push([]);
        rows.push(createGroupHeaderRow('Actively Giving Pledges'));
        (0, _object.get)(this, 'sortedItemsGiving').forEach(item => {
          rows.push(createRow(item));
        });
        this.store.createRecord('contact-download').save();
        let csv = Papa.unparse(rows);
        var blob = new Blob([csv], {
          type: "text/csv;charset=utf-8"
        });

        _fileSaver.default.saveAs(blob, 'pledges.csv', true);
      }

    }
  });

  _exports.default = _default;
});