define("donor-elf-web/components/dashboard/onboard/ag-wm", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Alert::Info ...attributes>
    <h5>Importing your Gifts</h5>
    <p>
      To get started, read this article to 
      <a
        href='https://donorelf.notion.site/Assemblies-of-God-World-Missions-Setup-2e3a0614f5974d0da32cb5a65c713d94'
        target='_blank'
        rel='noopener noreferrer'
      >
        learn how to import your gifts into DonorElf.
      </a>
    </p>
    <div>
      Also remember you can get 3 months free for every person you refer to DonorElf.
      (<a
        href='https://donorelf.notion.site/Referrals-in-DonorElf-2619839194ce4fdf8e9e1bd52786e61f'
        target='_blank'
        rel='noopener noreferrer'
      >more info</a>)
    </div>
  </Alert::Info>
  */
  {
    "id": "+HSqcMQy",
    "block": "[[[8,[39,0],[[17,1]],null,[[\"default\"],[[[[1,\"\\n  \"],[10,\"h5\"],[12],[1,\"Importing your Gifts\"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    To get started, read this article to \\n    \"],[10,3],[14,6,\"https://donorelf.notion.site/Assemblies-of-God-World-Missions-Setup-2e3a0614f5974d0da32cb5a65c713d94\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"\\n      learn how to import your gifts into DonorElf.\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[12],[1,\"\\n    Also remember you can get 3 months free for every person you refer to DonorElf.\\n    (\"],[10,3],[14,6,\"https://donorelf.notion.site/Referrals-in-DonorElf-2619839194ce4fdf8e9e1bd52786e61f\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"more info\"],[13],[1,\")\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"&attrs\"],false,[\"alert/info\"]]",
    "moduleName": "donor-elf-web/components/dashboard/onboard/ag-wm.hbs",
    "isStrictMode": false
  });

  class DashboardOnboardAgWmComponent extends _component2.default {}

  _exports.default = DashboardOnboardAgWmComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DashboardOnboardAgWmComponent);
});