define("donor-elf-web/models/admin/process-upload", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    uploadAll: attr('boolean'),
    entitiesFileName: attr('string'),
    fundsFileName: attr('string'),
    fundBalancesFileName: attr('string'),
    combinedFileName: attr('string'),
    feDetailReportFileName: attr('string'),
    pcContributionsFileName: attr('string'),
    pcDetailedEquityFileName: attr('string'),
    pledgesFileNames: attr(),
    transactionsFileNames: attr(),
    rufRecurringGiftsFileName: attr('string'),
    rufBalancesFileName: attr('string'),
    rufAmendmentsFileName: attr('string')
  });

  _exports.default = _default;
});