define("donor-elf-web/routes/funds/fund/groups/group/add", ["exports", "@ember/routing/route", "@ember/object", "@ember/service"], function (_exports, _route, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    contactsCache: (0, _service.inject)(),

    model() {
      let groupModel = this.modelFor('funds.fund.groups.group');
      let groupContacts = (0, _object.get)(this, `contactsCache.groupContacts.${groupModel.group}.contacts`);

      if (groupContacts == null) {
        groupContacts = [];
      }

      let contactIdsInGroup = {};
      groupContacts.forEach(contact => {
        contactIdsInGroup[(0, _object.get)(contact, 'id')] = true;
      });
      let contactsNotInGroup = (0, _object.get)(this, 'contactsCache.contacts').filter(contact => {
        return contactIdsInGroup[(0, _object.get)(contact, 'id')] !== true;
      });
      return Object.assign({}, groupModel, {
        contactsNotInGroup
      });
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.reset();
      }
    }

  });

  _exports.default = _default;
});