define("donor-elf-web/routes/funds/fund/more/credit-card", ["exports", "@ember/routing/route", "@ember/object", "@ember/service", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators"], function (_exports, _route, _object, _service, _emberChangeset, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const validation = {
    number: (0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    }),
    exp_month: (0, _validators.validateLength)({
      min: 1,
      max: 2,
      message: "Must be between 1 and 12"
    }),
    exp_year: (0, _validators.validateLength)({
      is: 4,
      message: "Must be 4 digits"
    }),
    cvc: (0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    })
  };

  var _default = _route.default.extend({
    navbarSettings: (0, _service.inject)(),
    store: (0, _service.inject)(),

    beforeModel() {
      (0, _object.set)(this, 'navbarSettings.title', 'Update Credit Card');
    },

    model() {
      return {
        changeset: new _emberChangeset.default({}, (0, _emberChangesetValidations.default)(validation), validation)
      };
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.reset();
      }
    }

  });

  _exports.default = _default;
});