define("donor-elf-web/routes/demo", ["exports", "@ember/routing/route", "@ember/object", "rsvp", "@ember/utils", "@ember/service", "donor-elf-web/lib/logger", "ember-changeset", "ember-changeset-validations"], function (_exports, _route, _object, _rsvp, _utils, _service, _logger, _emberChangeset, _emberChangesetValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const demoValidation = {// nothing yet
  };

  var _default = _route.default.extend({
    cookies: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),
    session: (0, _service.inject)(),
    store: (0, _service.inject)(),

    beforeModel() {
      (0, _object.set)(this, 'navbarSettings.title', 'Demo Login');
    },

    model() {
      let logoutPromise = _rsvp.default.resolve();

      let session = (0, _object.get)(this, 'session');

      if ((0, _object.get)(session, 'isAuthenticated')) {
        logoutPromise = session.invalidate();
      }

      return logoutPromise.then(() => {
        let model = (0, _object.get)(this, 'store').createRecord('demo-viewer', {
          roleId: 1
        });
        return {
          changeset: new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(demoValidation), demoValidation)
        };
      });
    },

    afterModel(model) {
      let previousDemoEmail = (0, _object.get)(this, 'cookies').read('demo_email');

      if (!(0, _utils.isEmpty)(previousDemoEmail)) {
        let changeset = (0, _object.get)(model, 'changeset');
        (0, _object.set)(changeset, 'email', previousDemoEmail);
        (0, _object.set)(changeset, 'roleId', null);
        return changeset.save().then(() => {
          return this.loginAsDemoUser();
        });
      }
    },

    loginAsDemoUser() {
      return (0, _object.get)(this, 'session').authenticate('authenticator:oauth2', 'demo@donorelf.com', 'password').then(() => {
        return (0, _object.get)(this, 'currentUser').load().then(() => {
          this.transitionTo('funds.fund', 'selected-fund');
        });
      });
    },

    actions: {
      error(error) {
        _logger.default.error(`error logging in demo user: ${error}`);

        this.loginAsDemoUser();
        return false;
      }

    }
  });

  _exports.default = _default;
});