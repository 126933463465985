define("donor-elf-web/routes/funds/fund/dates", ["exports", "@ember/routing/route", "@ember/object", "@ember/service"], function (_exports, _route, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    abilities: (0, _service.inject)(),
    fundsService: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),
    store: (0, _service.inject)(),

    beforeModel(transition) {
      if ((0, _object.get)(this, 'abilities').noAbility('edit_contact')) {
        transition.abort();
      }

      (0, _object.set)(this, 'navbarSettings.title', 'Dates');
      return (0, _object.get)(this, 'fundsService').confirmValidFundToken();
    },

    model() {
      return (0, _object.get)(this, 'store').findAll('contact-date');
    }

  });

  _exports.default = _default;
});