define("donor-elf-web/routes/funds/fund/contacts/upload/verify", ["exports", "@ember/routing/route", "@ember/object", "@ember/service"], function (_exports, _route, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    uploadContacts: (0, _service.inject)(),

    beforeModel() {
      // confirm a file has been uploaded
      if ((0, _object.get)(this, 'uploadContacts.columns.length') === 0) {
        this.transitionTo('funds.fund.contacts.upload.file');
      }
    },

    afterModel(model) {
      (0, _object.set)(model, 'currentStep', '3');
      (0, _object.get)(this, 'uploadContacts').createContactObjects();
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.reset();
      }
    }

  });

  _exports.default = _default;
});