define("donor-elf-web/models/organization/entity", ["exports", "@ember/utils", "@ember/object", "ember-data", "@ember/object/computed"], function (_exports, _utils, _object, _emberData, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    name: attr('string'),
    nameLowerCase: (0, _object.computed)('name', function () {
      return ((0, _object.get)(this, 'name') || '').toLowerCase();
    }),
    address1: attr('string'),
    address2: attr('string'),
    city: attr('string'),
    state: attr('string'),
    cityAndState: (0, _object.computed)('city', 'state', function () {
      let {
        city,
        state
      } = (0, _object.getProperties)(this, 'city', 'state');

      if ((0, _utils.isEmpty)(city) && (0, _utils.isEmpty)(state)) {
        return null;
      } else if (!(0, _utils.isEmpty)(city) && !(0, _utils.isEmpty)(state)) {
        return `${city}, ${state}`;
      } else if (!(0, _utils.isEmpty)(city)) {
        return city;
      } else {
        return state;
      }
    }),
    zip: attr('string'),
    country: attr('string'),
    phones: attr(),
    hasPhones: (0, _computed.gt)('phones.data.length', 0),
    emails: attr(),
    hasEmails: (0, _computed.gt)('emails.data.length', 0),
    custom: attr()
  });

  _exports.default = _default;
});