define("donor-elf-web/components/s3-upload-progress", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "@ember/service", "@ember/string", "donor-elf-web/lib/logger"], function (_exports, _component, _object, _runloop, _service, _string, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    FormData,
    XMLHttpRequest
  } = window;
  /*
    attrs
    - file - the file object from a <input type='file'> element
  
    events
    - onUploadComplete(s3Upload)
      - fires when the upload to AWS is complete
    - onUploadError(s3Upload)
    - onUploadCancelled(s3Upload)
  */

  var _default = _component.default.extend({
    fundsService: (0, _service.inject)(),
    store: (0, _service.inject)(),
    s3Upload: null,
    file: null,
    uploading: false,
    completed: false,
    percentUploaded: 5,
    progressBarStyle: null,

    didInsertElement() {
      this._super(...arguments);

      this.upload();
    },

    upload() {
      if ((0, _object.get)(this, 'file') == null) {
        _logger.default.error('The file attribute on s3-upload-progress was not set');

        return;
      }

      (0, _object.set)(this, 'uploading', true);
      return this.createS3UploadRecord().then(() => {
        return this.uploadToS3();
      });
    },

    createS3UploadRecord() {
      return (0, _object.get)(this, 'fundsService').confirmValidFundToken().then(() => {
        let s3Upload = (0, _object.get)(this, 'store').createRecord('s3-upload', {
          fileName: (0, _object.get)(this, 'file.name')
        });
        return s3Upload.save().then(() => {
          (0, _object.set)(this, 's3Upload', s3Upload);
          return s3Upload;
        });
      });
    },

    uploadToS3() {
      (0, _object.set)(this, 'uploading', true);
      let formData = new FormData();
      formData.append('key', (0, _object.get)(this, 's3Upload.key'));
      formData.append('AWSAccessKeyId', (0, _object.get)(this, 's3Upload.accessKey'));
      formData.append('acl', 'private');
      formData.append('policy', (0, _object.get)(this, 's3Upload.policy'));
      formData.append('signature', (0, _object.get)(this, 's3Upload.signature'));
      formData.append('success_action_status', '201');
      formData.append('file', (0, _object.get)(this, 'file'));
      let xhr = new XMLHttpRequest();
      xhr.upload.addEventListener('progress', this.xhrProgress.bind(this), false);
      xhr.addEventListener('load', this.xhrComplete.bind(this), false);
      xhr.addEventListener('error', this.xhrError.bind(this), false);
      xhr.addEventListener('abort', this.xhrCancelled.bind(this), false);
      xhr.open('POST', (0, _object.get)(this, 'actionUrl'), true);
      xhr.send(formData);
    },

    xhrProgress(e) {
      (0, _runloop.run)(() => {
        if (e.lengthComputable) {
          (0, _object.set)(this, 'percentUploaded', Math.round(e.loaded * 100 / e.total));
          this.updateProgressBar();
        }
      });
    },

    xhrComplete() {
      (0, _runloop.run)(() => {
        (0, _object.setProperties)(this, {
          percentUploaded: 100,
          completed: true
        });
        this.updateProgressBar();
        (0, _object.get)(this, 'onUploadComplete')((0, _object.get)(this, 's3Upload'));
      });
    },

    xhrError(e) {
      (0, _runloop.run)(() => {
        let onUploadError = (0, _object.get)(this, 'onUploadError');

        if (onUploadError) {
          onUploadError((0, _object.get)(this, 's3Upload'), e);
        }
      });
    },

    xhrCancelled() {
      (0, _runloop.run)(() => {
        let onUploadCancelled = (0, _object.get)(this, 'onUploadCancelled');

        if (onUploadCancelled) {
          onUploadCancelled((0, _object.get)(this, 's3Upload'));
        }
      });
    },

    updateProgressBar() {
      (0, _object.set)(this, 'progressBarStyle', (0, _string.htmlSafe)(`width: ${(0, _object.get)(this, 'percentUploaded')}%`));
    },

    actionUrl: (0, _object.computed)('s3Upload.bucket', function () {
      return `https://${(0, _object.get)(this, 's3Upload.bucket')}.s3.amazonaws.com`;
    })
  });

  _exports.default = _default;
});