define("donor-elf-web/templates/funds/fund", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "FCWypszZ",
    "block": "[[[41,[33,1,[\"selectedFund\",\"id\"]],[[[1,\"  \"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n\"]],[]],[[[1,\"  No fund selected\\n\"]],[]]]],[],false,[\"if\",\"fundsService\",\"component\",\"-outlet\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});