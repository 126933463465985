define("donor-elf-web/routes/funds/all/journal-groups/journal-group/edit", ["exports", "@ember/routing/route", "@ember/service", "@glimmer/tracking", "ember-changeset", "ember-changeset-validations", "donor-elf-web/validations/journal-group"], function (_exports, _route, _service, _tracking, _emberChangeset, _emberChangesetValidations, _journalGroup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.JournalGroupEditRouteModel = _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _class3, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let JournalGroupEditRoute = (_class3 = class JournalGroupEditRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "currentUser", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);
    }

    async model() {
      let {
        journalGroup
      } = this.modelFor('funds.all.journal-groups.journal-group');
      let slimJournals = [];
      let journalGroupUsers = [];
      let journalGroupName = 'Add Journal Group';

      if (journalGroup.id) {
        slimJournals = (await this.store.query('all-funds/journal-slim', {
          filter: {
            journal_group_id: journalGroup.id
          }
        })).toArray();
        journalGroupUsers = (await this.store.query('all-funds/journal-group-user', {
          filter: {
            journal_group_id: journalGroup.id
          }
        })).toArray();
        journalGroupName = journalGroup.name;
      } else {
        journalGroupUsers = [this.store.createRecord('all-funds/journal-group-user', {
          userId: this.currentUser.id,
          user: this.store.createRecord('all-funds/user', {
            firstName: this.currentUser.firstName,
            lastName: this.currentUser.lastName
          }),
          isOwner: true
        })];
      }

      return new JournalGroupEditRouteModel(journalGroup, slimJournals, journalGroupUsers, journalGroupName);
    }

  }, (_descriptor3 = _applyDecoratedDescriptor(_class3.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class3);
  _exports.default = JournalGroupEditRoute;
  let JournalGroupEditRouteModel = (_class = class JournalGroupEditRouteModel {
    constructor(journalGroup, slimJournals, journalGroupUsers, journalGroupName) {
      _defineProperty(this, "journalGroup", void 0);

      _defineProperty(this, "journalGroupName", void 0);

      _initializerDefineProperty(this, "slimJournals", _descriptor, this);

      _initializerDefineProperty(this, "journalGroupUsers", _descriptor2, this);

      _defineProperty(this, "changeset", void 0);

      this.journalGroup = journalGroup;
      this.slimJournals = slimJournals;
      this.journalGroupUsers = journalGroupUsers;
      this.journalGroupName = journalGroupName;
      this.changeset = new _emberChangeset.default(journalGroup, (0, _emberChangesetValidations.default)(_journalGroup.default), _journalGroup.default);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "slimJournals", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "journalGroupUsers", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.JournalGroupEditRouteModel = JournalGroupEditRouteModel;
});