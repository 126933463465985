define("donor-elf-web/validations/contact-custom-property", ["exports", "ember-changeset-validations/validators", "@ember/utils", "donor-elf-web/lib/object-utils", "donor-elf-web/lib/string-utils"], function (_exports, _validators, _utils, _objectUtils, _stringUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateContactCustomProperty;
  let reservedLabels = {
    'address': true,
    'phone': true,
    'email': true,
    'groups': true
  };

  function validateContactCustomProperty({
    contactsCache,
    exclude,
    isNewProperty,
    allowEmptyVal
  }) {
    let validation = {
      l: (_key, newValue, _oldValue, _changes, _content) => {
        if ((0, _utils.isEmpty)(newValue)) {
          return 'Must be present';
        }

        if (reservedLabels[newValue.toLowerCase()]) {
          return "That property name already exists";
        }

        if (contactsCache.customPropertyLabelIsReadonly(newValue)) {
          return "Sorry, you can't use this property because its value comes from your organization's system";
        }

        if (isNewProperty && contactsCache.customPropertiesByLowecaseLabel[newValue.toLowerCase()] != null) {
          return "That property name already exists";
        }

        return true;
      }
    };

    if (exclude == null || !exclude.includes('t')) {
      validation.t = (0, _validators.validatePresence)({
        presence: true,
        ignoreBlank: true,
        message: "Can't be blank"
      });
    }

    if (exclude == null || !exclude.includes('v')) {
      validation.v = (_key, newValue, _oldValue, changes, content) => {
        let type = (0, _objectUtils.assignSingleProperty)(content, changes, 't');

        if (allowEmptyVal && (0, _objectUtils.isNothing)(newValue)) {
          return true;
        } else if ((0, _utils.isEmpty)(type) || type === 's') {
          if ((0, _utils.isEmpty)(newValue)) {
            return "Can't be blank";
          }
        } else if (type === 'b') {
          if (newValue !== true && newValue !== false) {
            return "Must be Yes or No";
          }
        } else if (type === 'c' || type === 'n') {
          if ((0, _stringUtils.isNumber)(newValue)) {
            return true;
          }

          if (typeof newValue === 'string') {
            if (newValue.match(/,/i)) {
              return "Can't include comma (,)";
            }

            if (newValue.match(/\$/i)) {
              return "Can't include dollar sign ($)";
            }

            if (!newValue.match(/^[\s0-9\.]+$/)) {
              return "Enter a valid number";
            }
          }
        } else if (type === 'l') {
          if ((0, _objectUtils.isNothing)(newValue)) {
            return 'Must select at least 1 option';
          }
        }

        return true;
      };
    }

    return validation;
  }

  ;
});