define("donor-elf-web/controllers/funds/fund/transactions/edit/enter", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "ember-cached-decorator-polyfill", "@ember/service"], function (_exports, _controller, _object, _tracking, _emberCachedDecoratorPolyfill, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    currency
  } = window;
  let TransactionsEditEnterController = (_class = class TransactionsEditEnterController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "transactionsService", _descriptor2, this);

      _defineProperty(this, "model", void 0);

      _initializerDefineProperty(this, "hasErrors", _descriptor3, this);
    }

    get lineItemInfos() {
      return this.transactionsService.lineItemInfos;
    }

    get transTypes() {
      return this.model.transTypes;
    }

    get sortedTransTypes() {
      return this.transTypes.sort((a, b) => {
        if (a === b) {
          return 0;
        }

        if (a > b) {
          return 1;
        }

        if (b > a) {
          return -1;
        }

        return 0;
      });
    }

    get lineItemChangesets() {
      return this.lineItemInfos.map(x => x.changeset);
    }

    get isEditingPersistedTransaction() {
      return this.lineItemInfos.length === 1 && !this.lineItemInfos[0].model.isNew;
    }

    get lineItemsTotalAmount() {
      return this.lineItemChangesets.reduce((prevVal, changeset) => {
        return currency(prevVal).add(Number(changeset.get('amount') || 0));
      }, 0);
    }

    templateWillDestroy() {
      this.hasErrors = false;
    }

    async save() {
      let valid = true;

      for (let changeset of this.lineItemChangesets) {
        await changeset.validate();

        if (changeset.get('isValid') === false) {
          valid = false;
        }
      }

      this.hasErrors = !valid;

      if (valid) {
        this.router.transitionTo('funds.fund.transactions.edit.save');
      }
    }

    cancel() {
      window.history.back();
    }

    delete(changeset) {
      let info = this.lineItemInfos.find(item => {
        return item.changeset === changeset;
      });
      let persistedTransaction = !info.model.isNew;

      if (persistedTransaction) {
        this.router.transitionTo('funds.fund.transactions.delete', info.model.id);
      } else {
        this.transactionsService.lineItemInfos = this.lineItemInfos.filter(x => x !== info);
      }
    }

    addLineItemRecord() {
      let info = this.transactionsService.addLineItemInfo();
      this.model.focusOn = {
        changeset: info.changeset,
        fieldName: 'transDate'
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "transactionsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hasErrors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "sortedTransTypes", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "sortedTransTypes"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isEditingPersistedTransaction", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "isEditingPersistedTransaction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "delete", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addLineItemRecord", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addLineItemRecord"), _class.prototype)), _class);
  _exports.default = TransactionsEditEnterController;
});