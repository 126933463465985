define("donor-elf-web/routes/admin/users/delete", ["exports", "@ember/routing/route", "@ember/object", "@ember/utils", "@ember/service"], function (_exports, _route, _object, _utils, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    abilities: (0, _service.inject)(),
    fundsService: (0, _service.inject)(),
    storeCache: (0, _service.inject)(),

    beforeModel(transition) {
      if (this.abilities.noAbility('admin_edit_users')) {
        transition.abort();
        return;
      }

      return (0, _object.get)(this, 'fundsService').confirmValidFundToken();
    },

    model(params) {
      return (0, _object.get)(this, 'storeCache').indexSingleBy('admin/user', 'id').then(usersById => {
        return {
          record: (0, _object.get)(usersById, params.id)
        };
      });
    },

    afterModel(model) {
      if ((0, _utils.isEmpty)(model.record)) {
        this.transitionTo('admin.users.not-found');
      }
    }

  });

  _exports.default = _default;
});