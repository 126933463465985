define("donor-elf-web/templates/admin/funds/fund-loading", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "PSx72z9w",
    "block": "[[[10,0],[14,0,\"row\"],[12],[1,\"\\n  \"],[10,0],[15,0,[36,0]],[12],[1,\"\\n\"],[6,[39,1],null,null,[[\"default\"],[[[[1,\"      \"],[1,[34,2]],[1,\"\\n\"]],[]]]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"default-form-columns\",\"de-card\",\"de-loading\"]]",
    "moduleName": "donor-elf-web/templates/admin/funds/fund-loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});