define("donor-elf-web/templates/components/generic-error-message", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "fH+kfz26",
    "block": "[[[10,0],[14,0,\"alert alert-danger\"],[12],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Whoops! An error just occurred. Sorry about that!\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Can you email us at \"],[10,3],[14,6,\"mailto:support@donorelf.com\"],[12],[1,\"support@donorelf.com\"],[13],[1,\" and let us know\\n    what you were doing when you received this error. We'll look into it and get\\n    it fixed for you.\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    And a lot of times if you just refresh your browser the error will go away.\\n  \"],[13],[1,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-outline-primary\"],[4,[38,0],[[30,0],\"refreshBrowser\"],null],[12],[1,\"Try refreshing my browser\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"action\"]]",
    "moduleName": "donor-elf-web/templates/components/generic-error-message.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});