define("donor-elf-web/controllers/funds/fund/contacts/upload/verify", ["exports", "@ember/controller", "@ember/object", "@ember/service"], function (_exports, _controller, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    uploadContacts: (0, _service.inject)(),
    errorMessage: null,

    reset() {
      (0, _object.set)(this, 'errorMessage', null);
    },

    actions: {
      next() {
        this.transitionToRoute('funds.fund.contacts.upload.save');
      }

    }
  });

  _exports.default = _default;
});