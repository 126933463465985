define("donor-elf-web/templates/components/tasks-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "XOrqk30r",
    "block": "[[[8,[39,0],null,[[\"@links\"],[[30,0,[\"headerBarLinks\"]]]],null]],[],false,[\"header-bar\"]]",
    "moduleName": "donor-elf-web/templates/components/tasks-header.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});