define("donor-elf-web/lib/date-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateToUtc = dateToUtc;
  _exports.default = void 0;
  const millisecondsInHour = 60000;
  var _default = {
    // correctly adjusts for timezone when deserializing date only dates
    deserializeDateOnly(dateString) {
      let valueSerialized = dateString;
      let type = typeof valueSerialized;

      if (type === 'string') {
        // Taken from http://stackoverflow.com/a/17346406/215086
        let tIndex = valueSerialized.indexOf('T');

        if (tIndex >= 0) {
          valueSerialized = valueSerialized.slice(0, tIndex);
        }

        return this.correctTimezone(new Date(valueSerialized));
      } else if (type === 'number') {
        return new Date(valueSerialized);
      } else if (valueSerialized == null) {
        // if the value is null return null
        // if the value is not present in the data return undefined
        return valueSerialized;
      } else {
        return undefined;
      }
    },

    // corrects timezone when it's a date-only date
    correctTimezone(date) {
      let offset = date.getTimezoneOffset();
      return new Date(date.getTime() + offset * millisecondsInHour);
    },

    // converts date object to 'YYYY-MM-DD' string value
    // options
    //   invalidValue: value to return if the date object is invalid
    serializeToDateOnly(dateObject, options = {}) {
      if (dateObject != null && typeof dateObject.getMonth === 'function' && dateObject.toString() !== 'Invalid Date') {
        // can't use date.toISOString().split('T')[0] because it can return the previous day
        // for people in the Eastern hemisphere
        let month = `${dateObject.getMonth() + 1}`.padStart(2, '0');
        let day = `${dateObject.getDate()}`.padStart(2, '0');
        return `${dateObject.getFullYear()}-${month}-${day}`;
      }

      return options.invalidValue;
    }

  }; // converts date to Date.UTC that HighCharts requires for date categories

  _exports.default = _default;

  function dateToUtc(date) {
    return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
  }
});