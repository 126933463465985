define("donor-elf-web/templates/funds/fund/dashboard/rolling", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "rFkHKYd5",
    "block": "[[[8,[39,0],null,[[\"@goal\",\"@fund\"],[[30,1,[\"dashboard\",\"goal\"]],[30,1,[\"fund\"]]]],null]],[\"@model\"],false,[\"goal/rolling-goal\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/dashboard/rolling.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});