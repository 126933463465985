define("donor-elf-web/controllers/funds/fund/communications/edit", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "ember-concurrency", "rsvp", "@ember/utils"], function (_exports, _controller, _object, _computed, _service, _emberConcurrency, _rsvp, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    contactsCache: (0, _service.inject)(),
    communicationsService: (0, _service.inject)(),
    fundsService: (0, _service.inject)(),
    store: (0, _service.inject)(),
    queryParams: ['contactId', 'fromDetailPage', 'fromTaskId', 'disableGroups'],
    contactId: null,
    fromDetailPage: null,
    fromTaskId: null,
    disableGroups: null,
    error: null,
    record: (0, _computed.alias)('model.record'),
    changeset: (0, _computed.alias)('model.changeset'),
    reasons: (0, _computed.alias)('communicationsService.reasons'),
    mediums: (0, _computed.alias)('communicationsService.mediums'),
    forContact: (0, _computed.equal)('changeset.for', 'contact'),
    forGroup: (0, _computed.equal)('changeset.for', 'group'),
    hasGroups: (0, _computed.notEmpty)('contactsCache.sortedGroups'),
    groups: (0, _object.computed)('contactsCache.groupsCacheIndex', function () {
      return this.contactsCache.sortedGroups;
    }),

    reset() {
      (0, _object.setProperties)(this, {
        contactId: null,
        fromDetailPage: null,
        fromTaskId: null,
        disableGroups: null,
        error: null
      });
    },

    deleteRoute: (0, _object.computed)('model.record.isNew', function () {
      return undefined;
    }),
    deleteRouteParams: (0, _object.computed)('model.record.id', function () {
      return undefined;
    }),
    showGroupsOption: (0, _object.computed)('record.isNew', 'hasGroups', 'disableGroups', function () {
      return this.record.isNew && this.hasGroups && (0, _utils.isEmpty)(this.disableGroups);
    }),
    showGoBack: (0, _computed.alias)('model.saved'),
    showSaved: (0, _object.computed)('model.saved', 'model.changeset.isPristine', function () {
      return this.model.saved && (this.forGroup || (0, _object.get)(this, 'model.changeset.isPristine'));
    }),
    showSaveFormActions: (0, _object.computed)('model.saved', 'model.changeset.isDirty', function () {
      return !this.model.saved || this.forContact && (0, _object.get)(this, 'model.changeset.isDirty');
    }),

    generateCommunicationsForGroup() {
      let changeset = this.changeset;
      let clonedProps = (0, _object.getProperties)(changeset, 'completedAt', 'summary', 'notes', 'reason', 'medium', 'massCommunication');
      let groupContacts = (0, _object.get)(this, `contactsCache.groupContacts.${(0, _object.get)(changeset, 'group')}.contacts`);
      return groupContacts.map(contact => {
        return this.store.createRecord('communication', Object.assign({
          contactId: contact.id
        }, clonedProps));
      });
    },

    saveForGroup() {
      return new _rsvp.Promise((resolve, reject) => {
        this.generateCommunicationsForGroup().reduce(function (promise, record) {
          return promise.then(function () {
            return record.save();
          });
        }, _rsvp.Promise.resolve()).then(() => {
          resolve();
        }, error => {
          reject(error);
        });
      });
    },

    save: (0, _emberConcurrency.task)(function* () {
      let changeset = (0, _object.get)(this, 'changeset');
      yield changeset.validate();

      if ((0, _object.get)(changeset, 'isValid')) {
        try {
          yield this.fundsService.confirmValidFundToken();

          if (this.forContact) {
            yield changeset.save();
          } else {
            yield this.saveForGroup();
          }

          (0, _object.set)(this, 'model.saved', true);
        } catch (error) {
          if (this.record.errors.length > 0) {
            this.record.errors.forEach(({
              attribute,
              message
            }) => {
              changeset.pushErrors(attribute, message);
            });
          } else {
            (0, _object.set)(this, 'error', error);
          }
        }
      }
    }).drop(),
    actions: {
      goBack() {
        if (this.model.returnToContactDetailsPage) {
          this.transitionToRoute('funds.fund.contacts.contact', this.model.contact.id);
        } else {
          window.history.back();
        }
      },

      setFor(val) {
        (0, _object.setProperties)(this.changeset, {
          for: val,
          contactId: null,
          group: null,
          massCommunication: val === 'group'
        });
      }

    }
  });

  _exports.default = _default;
});