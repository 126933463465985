define("donor-elf-web/routes/funds/fund/groups/group/edit-name", ["exports", "@ember/routing/route", "@ember/object", "@ember/service", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators"], function (_exports, _route, _object, _service, _emberChangeset, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const groupValidation = {
    newName: (0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    })
  };

  var _default = _route.default.extend({
    contactsCache: (0, _service.inject)(),

    model() {
      let groupModel = this.modelFor('funds.fund.groups.group');
      let record = (0, _object.get)(this, 'store').createRecord('edit-group', {
        action: 'change-name',
        name: groupModel.group,
        newName: groupModel.group
      });
      return Object.assign({}, groupModel, {
        record,
        changeset: new _emberChangeset.default(record, (0, _emberChangesetValidations.default)(groupValidation), groupValidation)
      });
    }

  });

  _exports.default = _default;
});