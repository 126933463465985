define("donor-elf-web/components/navbar-search-contacts", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/runloop", "@ember/service", "@ember/utils", "donor-elf-web/lib/document-utils"], function (_exports, _component, _tracking, _object, _runloop, _service, _utils, _documentUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // args
  // - dontAddKeydownEventListener: boolean - default false
  // - placeholder: string - default "Search Contacts"
  // - smallFormControl: boolean - default false
  let _class = (_class2 = class _class2 extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "contactsCache", _descriptor, this);

      _initializerDefineProperty(this, "powerMenu", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "q", _descriptor4, this);

      _initializerDefineProperty(this, "highlightedIndex", _descriptor5, this);

      _defineProperty(this, "dropdown", null);

      _defineProperty(this, "element", null);

      _defineProperty(this, "dontOpenDropdown", false);
    }

    get filteredContacts() {
      let query = this.q || ''; // don't search until they have entered 2 letters

      if (query.length < 2) {
        query = '';
      }

      if (/^\d{4,}$/.test(query)) {
        // if more than 4 digits, then assume searching based on contact id
        return this.contactsCache.sortedContacts.filter(x => x.id === query).slice(0, 10);
      } else {
        return this.contactsCache.search(query, {
          limit: 10
        });
      }
    }

    get noContacts() {
      var _this$contactsCache$c;

      return (((_this$contactsCache$c = this.contactsCache.contacts) === null || _this$contactsCache$c === void 0 ? void 0 : _this$contactsCache$c.length) || 0) === 0;
    }

    get placeholder() {
      return this.args.placeholder || 'Search Contacts';
    }

    get inputClass() {
      return this.args.smallFormControl ? 'form-control form-control-sm' : 'form-control';
    } // whether the dropdown should be opened when the search textbox receives focus.
    // This is set when selecting a contact by clicking the name so that the
    // dropdown doesn't stay open


    handleDocumentKeydown(e) {
      // focus on contact search when the "/" key is pressed
      if (e.which === 191 && (0, _documentUtils.eventTargetNotInTextbox)(e)) {
        e.preventDefault();
        this.focusOnSearchTextbox();
      }
    }

    focusOnSearchTextbox() {
      this.element.querySelector('input.form-control').focus();
    }

    templateDidInsert(dropdown, el) {
      this.dropdown = dropdown;
      this.element = el.closest('.navbar-search-contacts');

      if (this.args.dontAddKeydownEventListener !== true) {
        document.addEventListener('keydown', this.handleDocumentKeydown);
      }

      this.router.on('routeWillChange', this, this.closeDropdown);
      this.powerMenu.appendOptionsFn('navbar-search-contacts', this.powerMenuOptions);
    }

    templateWillDestroy() {
      this.router.off('routeWillChange', this, this.closeDropdown);
      document.removeEventListener('keydown', this.handleDocumentKeydown);
    }

    powerMenuOptions() {
      return [{
        label: 'Search Contacts',
        fn: () => {
          this.focusOnSearchTextbox();
          this.dropdown.actions.open();
        }
      }];
    }

    handleTriggerKeyDown(e) {
      // space key should not close the dropdown
      if (e.which === 32) {
        e.stopImmediatePropagation(); // enter
      } else if (e.which === 13) {
        e.stopImmediatePropagation();
        let highlightedContact = this.filteredContacts[this.highlightedIndex];

        if (highlightedContact) {
          this.selectContact(highlightedContact);
        } // tab key or escape key

      } else if (e.which === 9 || e.which === 27) {
        e.stopImmediatePropagation();
        this.dropdown.actions.close();
      } else if (e.which === 40) {
        // Down arrow
        e.preventDefault();

        if (this.highlightedIndex + 1 < this.filteredContacts.length) {
          this.highlightedIndex += 1;
        }
      } else if (e.which === 38) {
        // Up arrow
        e.preventDefault();

        if (this.highlightedIndex > 0) {
          this.highlightedIndex -= 1;
        }
      }

      return true;
    }

    handleSearchInput(e) {
      this.q = e.target.value;
      this.highlightedIndex = 0;

      if (this.q.length >= 2) {
        this.dropdown.actions.open();
      } else if (this.q.length === 0) {
        this.dropdown.actions.close();
        setTimeout(() => {
          e.target.focus();
        });
      }
    }

    handleTriggerClick(e) {
      if ((0, _utils.isEmpty)(this.q)) {
        // don't open the dropdown on click unless a search term has already been entered
        e.stopImmediatePropagation();
      }
    }

    handleOnOpen(dropdown) {
      this.dropdown = dropdown;
    }

    selectContact(contact) {
      this.q = '';
      this.highlightedIndex = 0;
      this.router.transitionTo('funds.fund.contacts.contact', contact.id);
    }

    closeDropdown() {
      if (this.dropdown) {
        this.dontOpenDropdown = true;
        this.dropdown.actions.close();
        this.element.querySelector('.form-control').blur();
        (0, _runloop.next)(() => {
          this.dontOpenDropdown = false;
        });
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "contactsCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "powerMenu", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "q", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "highlightedIndex", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "handleDocumentKeydown", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleDocumentKeydown"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "templateDidInsert"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "templateWillDestroy"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "powerMenuOptions", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "powerMenuOptions"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleTriggerKeyDown", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleTriggerKeyDown"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleSearchInput", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleSearchInput"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleTriggerClick", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleTriggerClick"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleOnOpen", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleOnOpen"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "selectContact", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "selectContact"), _class2.prototype)), _class2);

  _exports.default = _class;
});