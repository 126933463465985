define("donor-elf-web/services/fund-goal-service", ["exports", "@ember/service", "ember-cached-decorator-polyfill", "donor-elf-web/lib/date-utils"], function (_exports, _service, _emberCachedDecoratorPolyfill, _dateUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    moment
  } = window;
  let FundGoalService = (_class = class FundGoalService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fundsService", _descriptor, this);

      _initializerDefineProperty(this, "settings", _descriptor2, this);
    }

    get hasYtdGoalType() {
      var _this$fundsService$go, _this$settings$fundSe;

      return ((_this$fundsService$go = this.fundsService.goal) === null || _this$fundsService$go === void 0 ? void 0 : _this$fundsService$go.goalType) === 'ytd' || ((_this$settings$fundSe = this.settings.fundSettings) === null || _this$settings$fundSe === void 0 ? void 0 : _this$settings$fundSe.defaultGoalType) === 'ytd';
    }

    get hasTotalAndExpectingGoalType() {
      var _this$fundsService$go2, _this$settings$fundSe2, _this$settings$fundSe3;

      return ((_this$fundsService$go2 = this.fundsService.goal) === null || _this$fundsService$go2 === void 0 ? void 0 : _this$fundsService$go2.goalType) === 'total_and_expecting' || ((_this$settings$fundSe2 = this.settings.fundSettings) === null || _this$settings$fundSe2 === void 0 ? void 0 : (_this$settings$fundSe3 = _this$settings$fundSe2.availableGoalTypes) === null || _this$settings$fundSe3 === void 0 ? void 0 : _this$settings$fundSe3.toString()) === 'total_and_expecting';
    }

    get hasTotalOrTotalAndExpectingGoalType() {
      var _this$fundsService$go3, _this$fundsService$go4;

      return ((_this$fundsService$go3 = this.fundsService.goal) === null || _this$fundsService$go3 === void 0 ? void 0 : _this$fundsService$go3.goalType) === 'total' || ((_this$fundsService$go4 = this.fundsService.goal) === null || _this$fundsService$go4 === void 0 ? void 0 : _this$fundsService$go4.goalType) === 'total_and_expecting';
    }

    get ytdStartMoment() {
      var _this$settings$fundSe4, _this$settings$orgSet;

      if (!this.hasYtdGoalType) {
        return null;
      }

      let startAtMonth;

      if ((_this$settings$fundSe4 = this.settings.fundSettings) !== null && _this$settings$fundSe4 !== void 0 && _this$settings$fundSe4.ytdStartAt) {
        startAtMonth = this.settings.fundSettings.ytdStartAt.getMonth() + 1;
      } else if ((_this$settings$orgSet = this.settings.orgSettings) !== null && _this$settings$orgSet !== void 0 && _this$settings$orgSet.ytdStartAtMonth) {
        startAtMonth = this.settings.orgSettings.ytdStartAtMonth;
      } else {
        startAtMonth = new Date().getMonth() + 1;
      }

      let startDate = _dateUtils.default.deserializeDateOnly(`${new Date().getFullYear()}-${startAtMonth.toString().padStart(2, '0')}-01`);

      if (startDate > new Date()) {
        startDate = moment(startDate).subtract(1, 'years').toDate();
      }

      return moment(startDate);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "hasYtdGoalType", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "hasYtdGoalType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasTotalAndExpectingGoalType", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "hasTotalAndExpectingGoalType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasTotalOrTotalAndExpectingGoalType", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "hasTotalOrTotalAndExpectingGoalType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "ytdStartMoment", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "ytdStartMoment"), _class.prototype)), _class);
  _exports.default = FundGoalService;
});