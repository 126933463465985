define("donor-elf-web/routes/funds/fund/more/donor-hub/connect/organization", ["exports", "@ember/routing/route", "@ember/object", "@ember/service", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators", "@ember/utils"], function (_exports, _route, _object, _service, _emberChangeset, _emberChangesetValidations, _validators, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const validations = {
    tntOrganizationId: (0, _validators.validatePresence)({
      presence: true,
      message: 'Please select an organization'
    })
  };

  var _default = _route.default.extend({
    navbarSettings: (0, _service.inject)(),
    store: (0, _service.inject)(),
    queryParams: {
      tntOrganizationId: {}
    },

    beforeModel() {
      (0, _object.set)(this, 'navbarSettings.title', 'Donor Hub Login Info');
    },

    model(params) {
      let record = (0, _object.get)(this.modelFor('funds.fund.more.donor-hub.connect'), 'record');
      return (0, _object.get)(this, 'store').findAll('tnt-organization').then(tntOrganizations => {
        let changeset = new _emberChangeset.default(record, (0, _emberChangesetValidations.default)(validations), validations);

        if ((0, _utils.isEmpty)(record.tntOrganizationId) && (0, _utils.isPresent)(params.tntOrganizationId)) {
          changeset.set('tntOrganizationId', params.tntOrganizationId);
        }

        return {
          tntOrganizations,
          record,
          changeset
        };
      });
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.reset();
      }
    }

  });

  _exports.default = _default;
});