define("donor-elf-web/templates/funds/fund/groups/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "vyvzsaGM",
    "block": "[[[8,[39,0],null,null,null],[1,\"\\n\"],[6,[39,1],null,null,[[\"default\"],[[[[6,[39,2],null,[[\"class\",\"route\"],[\"btn btn-sm btn-outline-warning mb-3\",\"funds.fund.groups.add\"]],[[\"default\"],[[[[1,\"    \"],[1,[28,[35,3],[\"plus\"],null]],[1,\"\\n    Add Group\\n\"]],[]]]]],[1,\"  \"],[10,\"table\"],[14,0,\"table\"],[12],[1,\"\\n    \"],[10,\"thead\"],[12],[1,\"\\n      \"],[10,\"tr\"],[12],[1,\"\\n        \"],[11,\"th\"],[4,[38,4],[[30,0],\"sort\",\"group\"],null],[12],[1,\"Group Name\"],[13],[1,\"\\n        \"],[11,\"th\"],[4,[38,4],[[30,0],\"sort\",\"contacts.length\"],null],[12],[1,\"# Contacts\"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[33,7]],null]],null],null,[[[1,\"        \"],[10,\"tr\"],[12],[1,\"\\n          \"],[10,\"td\"],[12],[1,\"\\n            \"],[6,[39,2],null,[[\"route\",\"model\"],[\"funds.fund.groups.group\",[30,1,[\"group\"]]]],[[\"default\"],[[[[1,[30,1,[\"group\"]]]],[]]]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,\"td\"],[12],[1,\"\\n            \"],[1,[30,1,[\"contacts\",\"length\"]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"item\"],false,[\"contacts-header\",\"de-card\",\"link-to\",\"svg-jar\",\"action\",\"each\",\"-track-array\",\"sortedItems\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/groups/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});