define("donor-elf-web/templates/components/dashboard-donut-chart-split", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "4om8aFqj",
    "block": "[[[11,0],[17,1],[4,[38,0],[[30,0,[\"renderChart\"]]],null],[4,[38,1],[\"click\",[30,0,[\"handleClick\"]]],null],[4,[38,1],[\"mouseout\",[30,0,[\"handleMouseout\"]]],null],[12],[13],[1,\"\\n\"],[41,[30,0,[\"tooltipLabel\"]],[[[1,\"  \"],[8,[39,3],null,[[\"@target\",\"@attachment\",\"@targetAttachment\"],[[30,0,[\"chartElement\"]],\"top left\",\"bottom left\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"border rounded tw-shadow-md py-1 px-2\"],[12],[1,\"\\n      \"],[10,\"b\"],[12],[1,[30,0,[\"tooltipLabel\"]]],[1,\":\"],[13],[1,\" \"],[1,[28,[35,4],[[30,0,[\"tooltipAmount\"]]],[[\"round\"],[true]]]],[1,\" \"],[10,1],[14,0,\"tw-text-sm\"],[12],[1,\"(\"],[1,[30,0,[\"tooltipPercent\"]]],[1,\"%)\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"&attrs\"],false,[\"did-insert\",\"on\",\"if\",\"tether\",\"format-amount\"]]",
    "moduleName": "donor-elf-web/templates/components/dashboard-donut-chart-split.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});