define("donor-elf-web/templates/components/coaching-format-field-percent", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "5xknH5DB",
    "block": "[[[41,[33,1],[[[1,\"  --\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,2],[[33,3]],null]],[1,\"%\\n\"]],[]]]],[],false,[\"if\",\"percentIsEmpty\",\"format-number\",\"percent\"]]",
    "moduleName": "donor-elf-web/templates/components/coaching-format-field-percent.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});