define("donor-elf-web/templates/components/event-info-expiring-pledge", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "QqdAqDhq",
    "block": "[[[41,[33,1,[\"isIdle\"]],[[[1,\"  Their \"],[1,[28,[35,2],[[33,3,[\"frequencyLabel\"]]],null]],[1,\" pledge of\\n  \"],[1,[28,[35,4],[[33,3,[\"amount\"]]],[[\"removeNoCents\"],[true]]]],[1,\" will end on \"],[1,[28,[35,5],[[33,3,[\"endDate\"]]],null]],[1,\".\\n\"]],[]],[[[1,\"  \"],[1,[34,6]],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"getPledge\",\"to-lower-case\",\"pledge\",\"format-amount\",\"format-date\",\"de-loading\"]]",
    "moduleName": "donor-elf-web/templates/components/event-info-expiring-pledge.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});