define("donor-elf-web/components/contact/bulk-actions/edit-property/select-property", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes>
    <Contact::BulkActions::Header @contactIds={{@contactIds}}>
      Edit Property
    </Contact::BulkActions::Header>
    <Contact::CustomProperty::Add::SelectExistingOrNew
      @existingPropertyInfos={{this.existingPropertyInfos}}
      @contentClass='tw-max-h-56'
      @onNewPropertySelected={{@onAddNewProperty}}
      @onExistingPropertySelected={{this.handleExistingPropertySelected}}
      class='pt-2 px-3 pb-3'
    />
    <Modal::Footer>
      <button
        {{on 'click' @onClose}}
        class='btn btn-xs btn-link pl-0'
        type='button'
      >
        Cancel
      </button>
    </Modal::Footer>
  </div>
  */
  {
    "id": "w02FkT1h",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@contactIds\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n    Edit Property\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[39,1],[[24,0,\"pt-2 px-3 pb-3\"]],[[\"@existingPropertyInfos\",\"@contentClass\",\"@onNewPropertySelected\",\"@onExistingPropertySelected\"],[[30,0,[\"existingPropertyInfos\"]],\"tw-max-h-56\",[30,3],[30,0,[\"handleExistingPropertySelected\"]]]],null],[1,\"\\n  \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-xs btn-link pl-0\"],[24,4,\"button\"],[4,[38,3],[\"click\",[30,4]],null],[12],[1,\"\\n      Cancel\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@contactIds\",\"@onAddNewProperty\",\"@onClose\"],false,[\"contact/bulk-actions/header\",\"contact/custom-property/add/select-existing-or-new\",\"modal/footer\",\"on\"]]",
    "moduleName": "donor-elf-web/components/contact/bulk-actions/edit-property/select-property.hbs",
    "isStrictMode": false
  });

  let ContactBulkActionsEditPropertySelectPropertyComponent = (_class = class ContactBulkActionsEditPropertySelectPropertyComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "contactsCache", _descriptor, this);
    }

    get existingPropertyInfos() {
      return [this.contactsCache.groupsContactCustomPropertyInfo, ...this.contactsCache.customProperties.filter(x => x.r !== true)];
    }

    handleExistingPropertySelected(propertyInfo) {
      this.args.onPropertySelected(propertyInfo.l);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "contactsCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handleExistingPropertySelected", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleExistingPropertySelected"), _class.prototype)), _class);
  _exports.default = ContactBulkActionsEditPropertySelectPropertyComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ContactBulkActionsEditPropertySelectPropertyComponent);
});