define("donor-elf-web/controllers/funds/fund/custom-reports/pbt-disbursement-summary", ["exports", "@ember/controller", "donor-elf-web/config/environment", "ember-parachute", "ember-changeset", "@ember/service", "@ember/object", "@ember/utils", "@ember/object/computed", "@ember/string", "donor-elf-web/lib/object-utils", "ember-concurrency", "file-saver"], function (_exports, _controller, _environment, _emberParachute, _emberChangeset, _service, _object, _utils, _computed, _string, _objectUtils, _emberConcurrency, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.queryParams = void 0;
  const {
    moment,
    Papa
  } = window;
  const PAGE_SIZE = 20;
  const queryParams = new _emberParachute.default({
    month: {
      defaultValue: '',
      refresh: true
    },
    year: {
      defaultValue: '',
      refresh: true
    },
    company: {
      defaultValue: '',
      refresh: true
    },
    fundName: {
      defaultValue: '',
      refresh: false
    }
  });
  _exports.queryParams = queryParams;

  var _default = _controller.default.extend(queryParams.Mixin, {
    fundsService: (0, _service.inject)(),
    store: (0, _service.inject)(),
    rootURL: (0, _object.computed)(function () {
      return _environment.default.routerRootURL;
    }),
    month: '',
    year: '',
    company: '',
    fundName: '',
    fundNameInput: '',
    loading: false,
    filterExpanded: false,
    filterChangeset: null,
    loadingProgressBarStyle: '',
    sortBy: ['fund.name:asc'],
    sortedRecords: (0, _computed.sort)('records', 'sortBy'),
    filteredSortedRecords: (0, _object.computed)('sortedRecords', 'fundName', function () {
      if ((0, _utils.isPresent)(this.fundName)) {
        let fundNameLowerCase = this.fundName.toLowerCase();
        return this.sortedRecords.filter(record => {
          return record.fundNameLowerCase.indexOf(fundNameLowerCase) >= 0;
        });
      } else {
        return this.sortedRecords;
      }
    }),
    fundsById: (0, _object.computed)('model.funds', function () {
      let fundsById = {};
      this.model.funds.forEach(fund => {
        fundsById[fund.id] = fund;
      });
      return fundsById;
    }),
    toDateMoment: (0, _object.computed)('filterChangeset.{month,year}', function () {
      return moment({
        year: Number((0, _object.get)(this, 'filterChangeset.year')),
        month: Number((0, _object.get)(this, 'filterChangeset.month')) - 1,
        day: 1
      }).endOf('month');
    }),
    queryParamsChanged: (0, _computed.or)('queryParamsState.{month,year,company}.changed'),

    setup({
      queryParams
    }) {
      this.fetchData(queryParams);
    },

    queryParamsDidChange({
      shouldRefresh,
      queryParams
    }) {
      // if any query param with `refresh: true` is changed, `shouldRefresh` is `true`
      if (shouldRefresh) {
        this.fetchData(queryParams);
      }
    },

    reset(event, isExiting) {
      if (isExiting) {
        this.resetQueryParams();
        (0, _object.setProperties)(this, {
          filterChangeset: null,
          filterExpanded: false,
          fundName: '',
          fundNameInput: ''
        });
      }
    },

    fetchData(queryParams) {
      (0, _object.setProperties)(this, {
        loading: true,
        records: [],
        fundNameInput: this.fundName
      });
      let filter = {};

      if ((0, _utils.isEmpty)(queryParams.month)) {
        queryParams.month = (new Date().getMonth() + 1).toString();
      }

      if ((0, _utils.isEmpty)(queryParams.year)) {
        queryParams.year = new Date().getFullYear().toString();
      }

      filter.month = queryParams.month;
      filter.year = queryParams.year;
      filter.company = queryParams.company;
      let filterChangeset = new _emberChangeset.default(filter);
      (0, _object.set)(this, 'filterChangeset', filterChangeset);
      this.fetchDataPage(1, filter);
    },

    fetchTotalPages: (0, _object.computed)('model.funds.length', function () {
      return Math.ceil(this.model.funds.length / PAGE_SIZE);
    }),

    fetchDataPage(page, filter) {
      let loadingPercentage = 2;

      if (this.fetchTotalPages && page > 1) {
        loadingPercentage = Math.round((page - 1) * 100 / this.fetchTotalPages);
      }

      if (loadingPercentage < 2) {
        loadingPercentage = 2;
      }

      (0, _object.set)(this, 'loadingProgressBarStyle', (0, _string.htmlSafe)(`width: ${loadingPercentage}%`));
      let offset = (page - 1) * PAGE_SIZE;
      filter.fundIds = this.model.funds.slice(offset, offset + PAGE_SIZE).map(x => x.id);
      filter.attachCompanyValues = page === 1;
      this.fundsService.confirmValidFundToken().then(() => {
        this.store.query('custom-reports/disbursement-availability-report', {
          filter: _objectUtils.default.decamelizeKeys(filter)
        }).then(reports => {
          reports.forEach(report => {
            let fund = this.fundsById[report.fundId];
            this.records.pushObject({
              fund,
              fundNameLowerCase: fund.nameLowerCase,
              report
            });
          });
          let companyValues = (0, _object.get)(reports, 'meta.company_values');

          if ((0, _utils.isPresent)(companyValues)) {
            let companyOptions = [{
              label: 'All',
              val: ''
            }].concat(companyValues.map(company => {
              return {
                label: company,
                val: company
              };
            }));
            (0, _object.set)(this, 'companyOptions', companyOptions);
          }

          if (page < this.fetchTotalPages) {
            this.fetchDataPage(page + 1, filter);
          } else {
            (0, _object.set)(this, 'loading', false);
          }
        });
      }, error => {
        (0, _object.set)(this, 'error', error);
      });
    },

    monthOptions: [{
      label: 'January',
      val: '1'
    }, {
      label: 'February',
      val: '2'
    }, {
      label: 'March',
      val: '3'
    }, {
      label: 'April',
      val: '4'
    }, {
      label: 'May',
      val: '5'
    }, {
      label: 'June',
      val: '6'
    }, {
      label: 'July',
      val: '7'
    }, {
      label: 'August',
      val: '8'
    }, {
      label: 'September',
      val: '9'
    }, {
      label: 'October',
      val: '10'
    }, {
      label: 'November',
      val: '11'
    }, {
      label: 'December',
      val: '12'
    }],
    yearOptions: (0, _object.computed)(function () {
      let options = [];
      let thisYear = new Date().getFullYear();

      for (let year = thisYear - 4; year <= thisYear; year++) {
        options.push({
          label: year.toString(),
          val: year.toString()
        });
      }

      return options;
    }),
    // gets set from the first data page response
    companyOptions: [],
    actionsMenuItems: (0, _object.computed)('filterExpanded', function () {
      return [{
        label: (0, _object.get)(this, 'filterExpanded') ? 'Collapse Filter' : 'Adjust Filter',
        svgJar: 'filter',
        onClick: () => {
          this.send('toggleExpanded');
        }
      }, {
        label: 'Download',
        svgJar: 'download',
        onClick: () => {
          this.send('download');
        }
      }];
    }),
    filterMessage: (0, _object.computed)('toDateMoment', 'company', function () {
      let messages = [];
      messages.push(`${this.toDateMoment.format('MMMM YYYY')}`);

      if ((0, _utils.isPresent)(this.company)) {
        messages.push(`Company: ${this.company}`);
      } else {
        messages.push('Company: All');
      }

      return messages.join(', ');
    }),
    debounceApplyFundNameFilter: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(500);
      this.send('applyFundNameFilter');
    }).restartable(),
    actions: {
      applyFilter() {
        let filterChangeset = (0, _object.get)(this, 'filterChangeset');

        if ((0, _object.get)(filterChangeset, 'isPristine')) {
          alert('No filter values have changed');
          return;
        }

        (0, _object.setProperties)(this, {
          month: (0, _object.get)(filterChangeset, 'month'),
          year: (0, _object.get)(filterChangeset, 'year'),
          company: (0, _object.get)(filterChangeset, 'company'),
          filterExpanded: false
        });
      },

      toggleExpanded() {
        this.toggleProperty('filterExpanded');
      },

      applyFundNameFilter() {
        (0, _object.set)(this, 'fundName', this.fundNameInput);
      },

      download() {
        let rows = [];
        rows.push(['Fund', 'Balance', 'Expenses Not Yet Reimbursed', 'Advances Not Accounted For', 'Available For Disbursement']);
        this.sortedRecords.forEach(record => {
          rows.push([record.fund.name, record.report.balance, record.report.notReimbursed, record.report.advances, record.report.availableForDisbursement]);
        });
        let csv = Papa.unparse(rows);
        let blob = new Blob([csv], {
          type: "text/csv;charset=utf-8"
        });

        _fileSaver.default.saveAs(blob, 'disbursement-summary.csv', true);
      }

    }
  });

  _exports.default = _default;
});