define("donor-elf-web/controllers/coaching/individuals/individual/edit-goal-date", ["exports", "@ember/controller", "@ember/service", "ember-concurrency", "@ember/object", "@ember/utils", "@ember/object/computed", "donor-elf-web/lib/date-utils"], function (_exports, _controller, _service, _emberConcurrency, _object, _utils, _computed, _dateUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    moment
  } = window;

  var _default = _controller.default.extend({
    coachingService: (0, _service.inject)(),
    formatDateService: (0, _service.inject)(),
    store: (0, _service.inject)(),
    error: null,

    reset() {
      (0, _object.setProperties)(this, {
        error: null
      });
    },

    // [{ date, dateString, dateFormatted }]
    goalDateItems: (0, _object.computed)('model.individualRecord.{startDate,frequencyNumber,frequencyTimespan}', function () {
      let individual = (0, _object.get)(this, 'model.individualRecord');
      let {
        coachingService,
        formatDateService
      } = (0, _object.getProperties)(this, 'coachingService', 'formatDateService');
      let {
        startDate,
        frequencyNumber,
        frequencyTimespan
      } = (0, _object.getProperties)(individual, 'startDate', 'frequencyNumber', 'frequencyTimespan');
      let maxDate = moment(startDate).add(2, 'years');
      let currentDate = startDate;
      let items = [];

      while (currentDate < maxDate) {
        currentDate = coachingService.reportEndDate({
          startDate: currentDate,
          frequencyNumber,
          frequencyTimespan
        });
        items.push({
          date: currentDate,
          dateString: moment(currentDate).format('YYYY-MM-DD'),
          dateFormatted: formatDateService.format(currentDate, {
            fourYear: true
          })
        });
        currentDate = moment(currentDate).add(1, 'day').toDate();
      }

      return items;
    }),
    goalDateOptions: (0, _computed.map)('goalDateItems', function (goalDateItem) {
      return {
        val: goalDateItem.dateString,
        label: goalDateItem.dateFormatted
      };
    }),
    save: (0, _emberConcurrency.task)(function* () {
      let changeset = (0, _object.get)(this, 'model.changeset');
      let individualRecord = (0, _object.get)(this, 'model.individualRecord');
      let coachingService = (0, _object.get)(this, 'coachingService');

      try {
        let goalDate = _dateUtils.default.deserializeDateOnly((0, _object.get)(changeset, 'goalDate'));

        (0, _object.set)(individualRecord, 'goalDate', goalDate); // now update all the interval goals that have an overallGoal

        (0, _object.get)(individualRecord, 'sections').forEach(section => {
          (0, _object.get)(section, 'fields').forEach(field => {
            let overallGoal = (0, _object.get)(field, 'overallGoal');
            let intervalGoal = (0, _object.get)(field, 'intervalGoal.low');

            if (!(0, _utils.isEmpty)(overallGoal) && !(0, _utils.isEmpty)(intervalGoal)) {
              let newIntervalGoal = coachingService.calculateIntervalGoal({
                individualRecord,
                overallGoal
              });
              (0, _object.set)(field, 'intervalGoal.low', newIntervalGoal);
            }
          });
        });
        yield individualRecord.save();
        window.history.back();
      } catch (error) {
        (0, _object.set)(this, 'error', error);
      }
    }).drop()
  });

  _exports.default = _default;
});