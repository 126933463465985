define("donor-elf-web/controllers/funds/all/journal-groups/journal-group/index", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@glimmer/tracking", "ember-cached-decorator-polyfill", "donor-elf-web/lib/journal-utils", "file-saver"], function (_exports, _controller, _object, _service, _tracking, _emberCachedDecoratorPolyfill, _journalUtils, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    Papa
  } = window;
  let JournalGroupIndexController = (_class = class JournalGroupIndexController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "formatDateService", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _defineProperty(this, "model", void 0);

      _initializerDefineProperty(this, "loading", _descriptor3, this);

      _initializerDefineProperty(this, "hideFinished", _descriptor4, this);

      _initializerDefineProperty(this, "controllerIsActive", _descriptor5, this);

      _initializerDefineProperty(this, "downloading", _descriptor6, this);

      _initializerDefineProperty(this, "hiddenJournalIds", _descriptor7, this);

      _initializerDefineProperty(this, "savingHiddenJournalIds", _descriptor8, this);

      _initializerDefineProperty(this, "saveError", _descriptor9, this);

      _initializerDefineProperty(this, "sortedJournalDigestInfos", _descriptor10, this);
    }

    get actionsMenuItems() {
      return [{
        label: 'Edit',
        svgJar: 'edit',
        route: 'funds.all.journal-groups.journal-group.edit'
      }, {
        label: `${this.hideFinished ? 'Show' : 'Hide'} Finished Journals`,
        svgJar: 'info',
        onClick: () => {
          this.hideFinished = !this.hideFinished;
        }
      }, {
        label: 'Download',
        svgJar: 'download',
        onClick: this.download
      }];
    }

    get loadedCount() {
      return Math.max(this.model.journalDigestInfos.filter(info => !info.loading).length, 1);
    }

    get visibleJournalDigestInfos() {
      return this.model.journalDigestInfos.filter(info => !this.hiddenJournalIds.includes(info.journalSlim.id));
    }

    get hiddenJournalDigestInfos() {
      return this.model.journalDigestInfos.filter(info => this.hiddenJournalIds.includes(info.journalSlim.id));
    }

    templateDidInsert() {
      var _this$model$journalGr;

      this.controllerIsActive = true;
      this.hiddenJournalIds = ((_this$model$journalGr = this.model.journalGroupUserSetting) === null || _this$model$journalGr === void 0 ? void 0 : _this$model$journalGr.hiddenJournalIds) || [];
      this.sortedJournalDigestInfos = [...this.visibleJournalDigestInfos, ...this.hiddenJournalDigestInfos];
      this.loadJournalDigestInfo(this.sortedJournalDigestInfos[0]);
    }

    templateWillDestroy() {
      this.controllerIsActive = false;
      this.downloading = false;
      this.loading = true;
      this.saveError = undefined;
      ;
    }

    async loadJournalDigestInfo(info) {
      if (this.controllerIsActive) {
        await info.load();
        let infoIndex = this.sortedJournalDigestInfos.indexOf(info);
        let nextInfo = this.sortedJournalDigestInfos[infoIndex + 1];

        if (nextInfo) {
          this.loadJournalDigestInfo(nextInfo);
        } else {
          this.loading = false;
        }
      }
    }

    toggleShowProjectedGoal(info) {
      info.showProjectedGoal = !info.showProjectedGoal;
    }

    get totalJournalPledgeAmount() {
      if (this.model.journalDigestInfos.length === 0) {
        return 0;
      }

      let decisionsRequireGift = this.model.journalDigestInfos[0].journalInfo.journal.decisionsRequireGift;

      if (decisionsRequireGift) {
        return this.model.journalDigestInfos.reduce((sum, info) => {
          var _info$mainGoalAndDeci, _info$mainGoalAndDeci2;

          return sum + (((_info$mainGoalAndDeci = info.mainGoalAndDecisions) === null || _info$mainGoalAndDeci === void 0 ? void 0 : (_info$mainGoalAndDeci2 = _info$mainGoalAndDeci.intervalCumulativeAmounts[info.mainGoalAndDecisions.intervalCumulativeAmounts.length - 1]) === null || _info$mainGoalAndDeci2 === void 0 ? void 0 : _info$mainGoalAndDeci2.withGift) || 0);
        }, 0);
      } else {
        return this.model.journalDigestInfos.reduce((sum, info) => {
          var _info$mainGoalAndDeci3, _info$mainGoalAndDeci4;

          return sum + (((_info$mainGoalAndDeci3 = info.mainGoalAndDecisions) === null || _info$mainGoalAndDeci3 === void 0 ? void 0 : (_info$mainGoalAndDeci4 = _info$mainGoalAndDeci3.intervalCumulativeAmounts[info.mainGoalAndDecisions.intervalCumulativeAmounts.length - 1]) === null || _info$mainGoalAndDeci4 === void 0 ? void 0 : _info$mainGoalAndDeci4.decision) || 0);
        }, 0);
      }
    }

    toggleShowJournal(info) {
      if (this.hiddenJournalIds.includes(info.journalSlim.id)) {
        this.hiddenJournalIds = this.hiddenJournalIds.filter(id => id !== info.journalSlim.id);
      } else {
        this.hiddenJournalIds = [...this.hiddenJournalIds, info.journalSlim.id];
      } // don't need to save if it's currently saving because it will save again when it's done


      if (!this.savingHiddenJournalIds) {
        this.saveHiddenJournalIds();
      }
    }

    async saveHiddenJournalIds() {
      this.savingHiddenJournalIds = true;
      let hiddenJournalIdsToSave = [...this.hiddenJournalIds];
      this.saveError = undefined;

      try {
        let journalGroupUserSetting = this.model.journalGroupUserSetting || this.store.createRecord('all-funds/journal-group-user-setting', {
          journalGroupId: this.model.journalGroup.id
        });
        journalGroupUserSetting.hiddenJournalIds = hiddenJournalIdsToSave;

        if (hiddenJournalIdsToSave.length === 0) {
          await journalGroupUserSetting.destroyRecord();
          this.model.journalGroupUserSetting = undefined;
        } else {
          await journalGroupUserSetting.save();
          this.model.journalGroupUserSetting = journalGroupUserSetting;
        } // if the hiddenJournalIds have changed since the save started, save again


        if (hiddenJournalIdsToSave.sort().join() !== this.hiddenJournalIds.sort().join()) {
          this.saveHiddenJournalIds();
        }
      } catch (error) {
        this.saveError = error;
      }

      this.savingHiddenJournalIds = false;
    } // This is specific for InterVarsity


    download() {
      if (this.loading) {
        window.alert('Please wait until all the journals have been loaded before downloading the report');
        return;
      }

      let rows = new _journalUtils.JournalGroupDownloadInterVarsity(this.sortedJournalDigestInfos, this.formatDateService).generateReport();
      let csv = Papa.unparse(rows);
      var blob = new Blob([csv], {
        type: "text/csv;charset=utf-8"
      });

      _fileSaver.default.saveAs(blob, `journal-group-${this.model.journalGroup.name}-report.csv`, true);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "formatDateService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "hideFinished", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "controllerIsActive", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "downloading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "hiddenJournalIds", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "savingHiddenJournalIds", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "saveError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "sortedJournalDigestInfos", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "visibleJournalDigestInfos", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "visibleJournalDigestInfos"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hiddenJournalDigestInfos", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "hiddenJournalDigestInfos"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleShowProjectedGoal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleShowProjectedGoal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleShowJournal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleShowJournal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "download", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "download"), _class.prototype)), _class);
  _exports.default = JournalGroupIndexController;
});