define("donor-elf-web/controllers/funds/fund/properties/property/rename", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "@ember/service", "ember-changeset", "ember-changeset-validations", "donor-elf-web/services/contacts-cache", "@ember/utils"], function (_exports, _controller, _object, _tracking, _service, _emberChangeset, _emberChangesetValidations, _contactsCache, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let validateNewLabel = currentCustomProperties => {
    return {
      newLabel: (_key, newValue) => {
        if ((0, _utils.isEmpty)(newValue)) {
          return 'Must be present';
        }

        let lowercaseNewLabel = newValue.toLowerCase().trim();

        if (currentCustomProperties.some(p => p.l.toLowerCase() === lowercaseNewLabel)) {
          return 'That property name already exists';
        }

        return true;
      }
    };
  };

  let PropertiesPropertyRenameController = (_class = class PropertiesPropertyRenameController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "contactsCache", _descriptor, this);

      _initializerDefineProperty(this, "settings", _descriptor2, this);

      _defineProperty(this, "model", void 0);

      _initializerDefineProperty(this, "changeset", _descriptor3, this);

      _initializerDefineProperty(this, "showValidationErrors", _descriptor4, this);

      _defineProperty(this, "bulkAction", void 0);

      _initializerDefineProperty(this, "bulkActionComplete", _descriptor5, this);

      _initializerDefineProperty(this, "saving", _descriptor6, this);

      _initializerDefineProperty(this, "saveError", _descriptor7, this);
    }

    get isGroupsProperty() {
      return (0, _contactsCache.isGroupsContactCustomPropertyInfo)(this.model.property);
    }

    get isReadonlyProperty() {
      return this.model.property.r === true;
    }

    templateDidInsert() {
      let validation = validateNewLabel(this.contactsCache.customProperties);
      this.changeset = new _emberChangeset.default({
        newLabel: ''
      }, (0, _emberChangesetValidations.default)(validation), validation);
    }

    templateWillDestroy() {
      this.showValidationErrors = false;
      this.bulkAction = undefined;
      this.bulkActionComplete = false;
      this.saving = false;
      this.saveError = false;
    }

    async save() {
      await this.changeset.validate();

      if (this.changeset.get('isValid')) {
        this.showValidationErrors = false;
        this.bulkActionComplete = false;
        this.saving = true;
        this.bulkAction = this.store.createRecord('bulk-action');
        this.bulkAction.actionType = 'rename_custom_property';
        this.bulkAction.custom = {
          property_label: this.model.property.l,
          new_label: this.changeset.get('newLabel')
        };

        try {
          await this.bulkAction.save();
        } catch (error) {
          this.bulkActionComplete = true;
          this.saveError = true;
        }
      } else {
        this.showValidationErrors = true;
      }
    }

    async handleBulkActionStatusChange(bulkAction) {
      if (bulkAction.status === 'completed') {
        let response = bulkAction.response;
        await bulkAction.destroyRecord(); // Rename the property label in the settings

        let existingProperties = this.settings.fundSettings.contactCustomInfo.properties;
        let originalProperty = existingProperties.find(p => p.l === this.model.property.l);
        originalProperty.l = bulkAction.custom.new_label;
        this.settings.fundSettings.contactCustomInfo.properties = existingProperties; // break the custom properties cache

        this.contactsCache.customPropertiesCacheIndex += 1;

        if (response.contact_ids.length < 250) {
          await this.contactsCache.reloadSpecificContacts(response.contact_ids);
        } else {
          await this.contactsCache.loadContacts({
            forceLoad: true
          });
        }

        this.bulkActionComplete = true;
      } else if (bulkAction.status === 'error') {
        this.saveError = true;
        this.bulkActionComplete = true;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "contactsCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "changeset", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showValidationErrors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "bulkActionComplete", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "saving", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "saveError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleBulkActionStatusChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleBulkActionStatusChange"), _class.prototype)), _class);
  _exports.default = PropertiesPropertyRenameController;
});