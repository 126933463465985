define("donor-elf-web/templates/funds/fund/dashboard/rolling-exclude-pledges", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "+yll/8qf",
    "block": "[[[8,[39,0],null,[[\"@goal\",\"@fund\",\"@excludePledges\"],[[30,1,[\"dashboard\",\"goal\"]],[30,1,[\"fund\"]],true]],null]],[\"@model\"],false,[\"goal/rolling-goal\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/dashboard/rolling-exclude-pledges.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});