define("donor-elf-web/services/format-amount-service", ["exports", "@ember/service", "accounting"], function (_exports, _service, _accounting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FormatAmountService extends _service.default {
    // options
    // - roundToDollar
    //   - always round to the nearest dollar
    //   - default is false
    // - removeNoCents
    //   - If there are no cents, then remove them
    //   - default is false
    format(val, options = {}) {
      let opts = Object.assign({
        roundToDollar: false,
        removeNoCents: false
      }, options);
      let precision = opts.roundToDollar ? 0 : 2;

      let formatVal = _accounting.accounting.formatMoney(val, {
        precision: precision
      });

      return opts.removeNoCents ? formatVal.replace(/\.00$/, '') : formatVal;
    }

  }

  _exports.default = FormatAmountService;
});