define("donor-elf-web/validations/mailchimp-fund-sync", ["exports", "ember-changeset-validations/validators", "@ember/utils"], function (_exports, _validators, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateMailchimpFundSync;

  function validateMailchimpFundSync({
    allMailchimpFundSyncs
  }) {
    return {
      mailchimpAccountId: (0, _validators.validatePresence)({
        presence: true,
        message: "Can't be blank"
      }),
      audienceId: (0, _validators.validatePresence)({
        presence: true,
        message: "Can't be blank"
      }),
      fundId: (_key, newValue, _oldValue, _changes, content) => {
        if ((0, _utils.isEmpty)(newValue)) {
          return 'Must be present';
        }

        let thisRecordId = content.id;

        if (allMailchimpFundSyncs.any(x => x.fundId === newValue && x.id !== thisRecordId)) {
          return "This fund is already synced with MailChimp. It can't be synced with multiple MailChimp accounts";
        }

        return true;
      }
    };
  }

  ;
});