define("donor-elf-web/controllers/admin/upload/complete", ["exports", "@ember/controller", "@ember/service"], function (_exports, _controller, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    fundsService: (0, _service.inject)()
  });

  _exports.default = _default;
});