define("donor-elf-web/templates/admin/fund-groups/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "/Php6kEN",
    "block": "[[[8,[39,0],[[4,[38,1],[[30,0,[\"templateWillDestroy\"]]],null]],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],[[24,0,\"btn btn-warning float-right mb-3\"]],[[\"@route\",\"@model\"],[\"admin.fund-groups.fund-group.edit\",\"add\"]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,3],[\"plus\"],null]],[1,\"\\n    Add\\n  \"]],[]]]]],[1,\"\\n  \"],[11,\"input\"],[16,2,[30,0,[\"q\"]]],[24,0,\"form-control mb-3\"],[24,\"placeholder\",\"Filter by Name\"],[4,[38,4],null,null],[4,[38,5],[\"input\",[30,0,[\"handleSearchInput\"]]],null],[12],[13],[1,\"\\n  \"],[10,\"table\"],[14,0,\"table\"],[12],[1,\"\\n    \"],[10,\"thead\"],[12],[1,\"\\n      \"],[10,\"tr\"],[12],[1,\"\\n        \"],[10,\"th\"],[12],[1,\"Name\"],[13],[1,\"\\n        \"],[10,\"th\"],[12],[1,\"Funds\"],[13],[1,\"\\n        \"],[10,\"th\"],[12],[1,\"Users\"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,0,[\"filteredItems\"]]],null]],null],null,[[[1,\"        \"],[10,\"tr\"],[12],[1,\"\\n          \"],[10,\"td\"],[12],[1,\"\\n            \"],[8,[39,2],null,[[\"@route\",\"@model\"],[\"admin.fund-groups.fund-group\",[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n              \"],[1,[30,1,[\"name\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,\"td\"],[12],[1,[30,1,[\"fundIds\",\"length\"]]],[13],[1,\"\\n          \"],[10,\"td\"],[12],[1,[30,1,[\"userIds\",\"length\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"fundGroup\"],false,[\"card\",\"will-destroy\",\"link-to\",\"svg-jar\",\"focus-on-insert\",\"throttle-on\",\"each\",\"-track-array\"]]",
    "moduleName": "donor-elf-web/templates/admin/fund-groups/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});