define("donor-elf-web/templates/funds/fund/dashboard/rolling-no-goal", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "x3/VPyTK",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,0],[14,0,\"d-flex mb-3 mb-md-4\"],[12],[1,\"\\n    \"],[10,\"h4\"],[14,0,\"mb-0\"],[12],[1,\"Monthly Gifts\"],[13],[1,\"\\n    \"],[10,\"small\"],[14,0,\"text-muted font-weight-light ml-auto align-self-end\"],[12],[1,[28,[35,1],[[33,2,[\"fund\"]]],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[46,\"goal/monthly-gifts-without-goal-chart\",null,[[\"monthlyGifts\",\"height\"],[[33,2,[\"dashboard\",\"goal\",\"goalData\",\"monthly_gifts\"]],250]],null],[1,\"\\n\"]],[]]]]]],[],false,[\"de-card\",\"fund-last-updated-text\",\"model\",\"component\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/dashboard/rolling-no-goal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});