define("donor-elf-web/components/contact-upload-mapping", ["exports", "@ember/component", "@ember/object", "@ember/utils", "@ember/service"], function (_exports, _component, _object, _utils, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    attrs
      - mappingIndex
  */
  var _default = _component.default.extend({
    uploadContacts: (0, _service.inject)(),
    mappingIndex: null,
    mapping: (0, _object.computed)('uploadContacts.columnMappings.[]', 'mappingIndex', function () {
      return (0, _object.get)(this, 'uploadContacts.columnMappings').objectAt((0, _object.get)(this, 'mappingIndex'));
    }),
    propertyOptions: (0, _object.computed)('mapping.property', 'uploadContacts.columnMappings.@each.property', function () {
      let mappedProperties = {};
      let thisProperty = (0, _object.get)(this, 'mapping.property');
      (0, _object.get)(this, 'uploadContacts.columnMappings').forEach(mapping => {
        let prop = (0, _object.get)(mapping, 'property');

        if (!(0, _utils.isEmpty)(prop) && prop !== thisProperty) {
          mappedProperties[(0, _object.get)(mapping, 'property')] = true;
        }
      });
      return (0, _object.get)(this, 'uploadContacts.propertyOptions').filter(item => {
        return !mappedProperties[(0, _object.get)(item, 'val')];
      });
    }),
    actions: {
      onSelectChange: function (val) {
        (0, _object.set)(this, 'mapping.property', val);
      }
    }
  });

  _exports.default = _default;
});