define("donor-elf-web/routes/funds/fund/journals/journal/review-items", ["exports", "@ember/routing/route", "@ember/service", "donor-elf-web/controllers/funds/fund/journals/journal/review-items", "donor-elf-web/lib/store-utils"], function (_exports, _route, _service, _reviewItems, _storeUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let JournalsIndexRoute = (_class = class JournalsIndexRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fundsService", _descriptor, this);

      _initializerDefineProperty(this, "journalService", _descriptor2, this);

      _initializerDefineProperty(this, "navbarSettings", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);
    }

    async model() {
      await this.fundsService.confirmValidFundToken();
      await this.journalService.load(); // @ts-expect-error

      let journalId = this.paramsFor('funds.fund.journals.journal').journalId;
      let journalInfo = this.journalService.journalInfoById(journalId);
      this.navbarSettings.title = `${journalInfo.journal.label} Journal`;
      let contactDuplicates = await (0, _storeUtils.queryWithPages)({
        store: this.store,
        modelName: 'contact-duplicate',
        queryOptions: {
          filter: {
            journal_id: journalId
          }
        },
        pageSize: 50
      });
      let reviewItems = await (0, _storeUtils.queryWithPages)({
        store: this.store,
        modelName: 'review-item',
        queryOptions: {
          filter: {
            journal_id: journalId,
            answered: false
          }
        },
        pageSize: 50
      });
      return {
        items: [...contactDuplicates.map(x => new _reviewItems.JournalReviewItem({
          contactDuplicate: x
        })), ...reviewItems.map(x => new _reviewItems.JournalReviewItem({
          reviewItem: x
        }))]
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "journalService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "navbarSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = JournalsIndexRoute;
});