define("donor-elf-web/components/form/text", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/utils"], function (_exports, _component, _templateFactory, _component2, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='form-group {{if this.showError 'has-errors'}}'>
    {{#if (is-present @label)}}
      {{#if @labelIsHtmlSafe}}
        {{! template-lint-disable no-triple-curlies }}
        <label>{{{@label}}}</label>
      {{else}}
        <label>{{@label}}</label>
      {{/if}}
    {{/if}}
    <Input
      value={{get @changeset @property}}
      type='text'
      class='form-control'
      ...attributes
      {{on 'input' this.handleInput}}
    />
    {{#if this.showError}}
      <div class='text-danger tw-text-sm'>
        {{this.errorMessage}}
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "E0u1ixJY",
    "block": "[[[10,0],[15,0,[29,[\"form-group \",[52,[30,0,[\"showError\"]],\"has-errors\"]]]],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,1]],null],[[[41,[30,2],[[[1,\"      \"],[10,\"label\"],[12],[2,[30,1]],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,\"label\"],[12],[1,[30,1]],[13],[1,\"\\n\"]],[]]]],[]],null],[1,\"  \"],[8,[39,2],[[16,2,[28,[37,3],[[30,3],[30,4]],null]],[24,4,\"text\"],[24,0,\"form-control\"],[17,5],[4,[38,4],[\"input\",[30,0,[\"handleInput\"]]],null]],null,null],[1,\"\\n\"],[41,[30,0,[\"showError\"]],[[[1,\"    \"],[10,0],[14,0,\"text-danger tw-text-sm\"],[12],[1,\"\\n      \"],[1,[30,0,[\"errorMessage\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@label\",\"@labelIsHtmlSafe\",\"@changeset\",\"@property\",\"&attrs\"],false,[\"if\",\"is-present\",\"input\",\"get\",\"on\"]]",
    "moduleName": "donor-elf-web/components/form/text.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (_class = class extends _component2.default {
    handleInput(e) {
      this.args.changeset.set(this.args.property, e.target.value);
    }

    get errorMessage() {
      var _this$args$changeset$, _this$args$changeset$2;

      let validationErrors = ((_this$args$changeset$ = this.args.changeset.get('error')) === null || _this$args$changeset$ === void 0 ? void 0 : (_this$args$changeset$2 = _this$args$changeset$[this.args.property]) === null || _this$args$changeset$2 === void 0 ? void 0 : _this$args$changeset$2.validation) || [];
      return validationErrors.join(', ');
    }

    get showError() {
      return this.args.showValidationErrors && (0, _utils.isPresent)(this.errorMessage);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "handleInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleInput"), _class.prototype)), _class));

  _exports.default = _default;
});