define("donor-elf-web/routes/funds/fund/goal/annual", ["exports", "@ember/routing/route", "@ember/service", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators"], function (_exports, _route, _service, _emberChangeset, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const VALIDATION = {
    annualGoal: [(0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    }), (0, _validators.validateNumber)({
      gte: 1,
      message: 'Must be a positive number'
    })]
  };
  let FundGoalAnnualRoute = (_class = class FundGoalAnnualRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "abilities", _descriptor, this);

      _initializerDefineProperty(this, "navbarSettings", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);
    }

    beforeModel(transition) {
      if (this.abilities.noAbility('edit_goal')) {
        transition.abort();
      }
    }

    async model() {
      let record = (await this.store.query('annual-fund-goal', {})).toArray()[0];

      if (record) {
        this.navbarSettings.title = 'Edit Annual Goal';
      } else {
        this.navbarSettings.title = 'Add Annual Goal';
        record = this.store.createRecord('annual-fund-goal');
      }

      let changeset = new _emberChangeset.default(record, (0, _emberChangesetValidations.default)(VALIDATION), VALIDATION);
      return {
        changeset
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "abilities", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "navbarSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = FundGoalAnnualRoute;
});