define("donor-elf-web/templates/components/event-info-returning-stopped-giving-donor", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "kJo7NuAN",
    "block": "[[[1,\"They were a regular donor that had stopped giving, but now they're giving again!\\nThey last gave \"],[1,[28,[35,0],[[33,1,[\"custom\",\"last_donation_amount\"]]],[[\"removeNoCents\"],[true]]]],[1,\" on\\n\"],[1,[28,[35,2],[[33,1,[\"custom\",\"last_donation_date\"]]],null]],[1,\".\\n\"]],[],false,[\"format-amount\",\"eventModel\",\"format-date\"]]",
    "moduleName": "donor-elf-web/templates/components/event-info-returning-stopped-giving-donor.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});