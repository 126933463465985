define("donor-elf-web/modifiers/disable-autocorrect", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Disables the browser's autocorrect, spellcheck, etc
  var _default = (0, _emberModifier.modifier)(element => {
    element.setAttribute('autocomplete', 'off');
    element.setAttribute('autocorrect', 'off');
    element.setAttribute('autocapitalize', 'off');
    element.setAttribute('spellcheck', 'false');
  });

  _exports.default = _default;
});