define("donor-elf-web/routes/funds/fund/contacts/upload/index", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    redirect() {
      this.transitionTo('funds.fund.contacts.upload.file');
    }

  });

  _exports.default = _default;
});