define("donor-elf-web/templates/components/form-group-power-select", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "QkgAgSex",
    "block": "[[[41,[51,[33,1]],[[[1,\"  \"],[10,\"label\"],[12],[1,[34,2]],[13],[1,\"\\n\"]],[]],null],[8,[39,3],null,[[\"@searchEnabled\",\"@selected\",\"@options\",\"@placeholder\",\"@searchField\",\"@onFocus\",\"@onChange\"],[true,[30,0,[\"selectedOption\"]],[30,0,[\"options\"]],[30,0,[\"placeholder\"]],\"label\",[30,0,[\"handleFocused\"]],[30,0,[\"handleChanged\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[1,[30,1,[\"label\"]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"],[41,[33,5],[[[1,\"  \"],[10,0],[14,0,\"has-errors-message\"],[12],[1,[28,[35,6],[[33,7,[\"error\"]],[28,[37,8],[[33,9],\".validation\"],null]],null]],[13],[1,\"\\n\"]],[]],null]],[\"option\"],false,[\"unless\",\"hideLabel\",\"label\",\"power-select\",\"if\",\"hasErrors\",\"get\",\"changeset\",\"concat\",\"property\"]]",
    "moduleName": "donor-elf-web/templates/components/form-group-power-select.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});