define("donor-elf-web/templates/components/filter-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "yf4J4tol",
    "block": "[[[10,0],[14,0,\"d-flex flex-column flex-md-row filter-item\"],[12],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"changeset\",\"property\",\"label\",\"onChange\",\"selectOptions\"],[[33,1],\"prop\",\"Field\",[28,[37,2],[[30,0],\"handlePropChanged\"],null],[33,3]]]]],[1,\"\\n  \"],[1,[28,[35,0],null,[[\"changeset\",\"property\",\"label\",\"selectOptions\",\"class\"],[[33,1],\"op\",\"Operation\",[33,4],\"ml-md-3 filter-item-operation\"]]]],[1,\"\\n\"],[41,[28,[37,6],[[33,7],\"select\"],null],[[[1,\"    \"],[1,[28,[35,0],null,[[\"changeset\",\"property\",\"label\",\"selectOptions\",\"class\"],[[33,1],\"val\",\"Value\",[33,8],\"ml-md-3 flex-grow-1\"]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,9],null,[[\"changeset\",\"property\",\"label\",\"class\"],[[33,1],\"val\",\"Value\",\"ml-md-3 flex-grow-1\"]]]],[1,\"\\n\"]],[]]],[1,\"  \"],[10,0],[14,0,\"ml-md-3 pb-4 align-self-start align-self-md-end\"],[12],[1,\"\\n    \"],[11,0],[24,0,\"icon-link-danger\"],[4,[38,2],[[30,0],\"handleRemove\"],null],[12],[1,\"\\n      \"],[1,[28,[35,10],[\"trash\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"form-group-select\",\"filterItem\",\"action\",\"propertyOptions\",\"operationOptions\",\"if\",\"eq\",\"selectedPropType\",\"selectedFilterSettingOptions\",\"form-group\",\"svg-jar\"]]",
    "moduleName": "donor-elf-web/templates/components/filter-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});