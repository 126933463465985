define("donor-elf-web/templates/funds/fund/journals/add-loading", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "AF3P+jFB",
    "block": "[[[10,\"ol\"],[14,0,\"breadcrumb row-bottom-margin\"],[12],[1,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@route\"],[\"funds.fund.journals\"]],[[\"default\"],[[[[1,\"Journals\"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[1,\"Create Journal\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],null,null,null],[1,\"\\n\"]],[]]]]]],[],false,[\"link-to\",\"card\",\"de-loading\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/journals/add-loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});