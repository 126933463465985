define("donor-elf-web/controllers/admin/address-changes/address-change", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class AdminAddressChangesAddressChangeController extends _controller.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "model", void 0);
    }

    get phoneNumberChanges() {
      let oldPhones = new Set(this.model.oldPhones || []);
      let newPhones = new Set(this.model.newPhones || []);
      let phoneNumbers = [];

      for (let value of newPhones.difference(oldPhones)) {
        phoneNumbers.push({
          value,
          new: true,
          removed: false,
          same: false
        });
      }

      for (let value of oldPhones.difference(newPhones)) {
        phoneNumbers.push({
          value,
          new: false,
          removed: true,
          same: false
        });
      }

      for (let value of newPhones.intersection(oldPhones)) {
        phoneNumbers.push({
          value,
          new: false,
          removed: false,
          same: true
        });
      }

      return phoneNumbers;
    }

    get emailChanges() {
      let oldEmails = new Set(this.model.oldEmails || []);
      let newEmails = new Set(this.model.newEmails || []);
      let emails = [];

      for (let value of newEmails.difference(oldEmails)) {
        emails.push({
          value,
          new: true,
          removed: false,
          same: false
        });
      }

      for (let value of oldEmails.difference(newEmails)) {
        emails.push({
          value,
          new: false,
          removed: true,
          same: false
        });
      }

      for (let value of newEmails.intersection(oldEmails)) {
        emails.push({
          value,
          new: false,
          removed: false,
          same: true
        });
      }

      return emails;
    }

  }

  _exports.default = AdminAddressChangesAddressChangeController;
});