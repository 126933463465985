define("donor-elf-web/routes/funds/fund/custom-reports/tenfold-paystubs/tenfold-paystub", ["exports", "@ember/routing/route", "@ember/object", "@ember/service"], function (_exports, _route, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    navbarSettings: (0, _service.inject)(),
    store: (0, _service.inject)(),

    beforeModel() {
      (0, _object.set)(this, 'navbarSettings.title', 'Paystub');
    },

    model(params) {
      return (0, _object.get)(this, 'store').findRecord('custom-reports/tenfold-paystub', params.id);
    }

  });

  _exports.default = _default;
});