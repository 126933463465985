define("donor-elf-web/models/journal-decision", ["exports", "@ember-data/model", "donor-elf-web/lib/date-utils"], function (_exports, _model, _dateUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let JournalDecisionModel = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('date-only'), _dec5 = (0, _model.attr)('boolean'), _dec6 = (0, _model.attr)('number'), _dec7 = (0, _model.attr)('date-only'), _dec8 = (0, _model.attr)('boolean'), _dec9 = (0, _model.attr)(), (_class = class JournalDecisionModel extends _model.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "journalContactId", _descriptor, this);

      _initializerDefineProperty(this, "journalGoalId", _descriptor2, this);

      _initializerDefineProperty(this, "pledgeId", _descriptor3, this);

      _initializerDefineProperty(this, "decisionDate", _descriptor4, this);

      _initializerDefineProperty(this, "yesNo", _descriptor5, this);

      _initializerDefineProperty(this, "amount", _descriptor6, this);

      _initializerDefineProperty(this, "firstGiftDate", _descriptor7, this);

      _initializerDefineProperty(this, "pledgeIncreased", _descriptor8, this);

      _initializerDefineProperty(this, "prevPledgeInfo", _descriptor9, this);

      _defineProperty(this, "_prevPledge", void 0);
    }

    getPrevPledge(store) {
      if (this._prevPledge !== undefined) {
        return this._prevPledge;
      }

      let info = this.prevPledgeInfo;

      if (info == null) {
        return this._prevPledge = null;
      }

      return this._prevPledge = store.createRecord('pledge', {
        amount: info.amount,
        frequency: info.frequency,
        startDate: _dateUtils.default.deserializeDateOnly(info.start_date),
        endDate: _dateUtils.default.deserializeDateOnly(info.end_date)
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "journalContactId", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "journalGoalId", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "pledgeId", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "decisionDate", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "yesNo", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "amount", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "firstGiftDate", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "pledgeIncreased", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "prevPledgeInfo", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = JournalDecisionModel;
});