define("donor-elf-web/validators/unique-user-email", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateUniqueUserEmail;

  function validateUniqueUserEmail({
    allUsers
  } = {}) {
    let usersByEmail = {};
    allUsers.forEach(user => {
      usersByEmail[(0, _object.get)(user, 'emailLowerCase')] = user;
    });
    return (key, newValue, oldValue, changes, content) => {
      if (newValue === oldValue) {
        return true;
      }

      let userWithSameEmail = usersByEmail[newValue.toLowerCase()];

      if (userWithSameEmail != null && (0, _object.get)(userWithSameEmail, 'id') !== (0, _object.get)(content, 'id')) {
        return `Another user (${(0, _object.get)(userWithSameEmail, 'displayName')}) already has the same email address`;
      }

      return true;
    };
  }
});