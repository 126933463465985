define("donor-elf-web/helpers/format-date-from-now", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    moment
  } = window;

  var _default = _helper.default.extend({
    // - args
    //   - date
    compute(args) {
      let date = args[0];

      if (date == null) {
        return '';
      }

      let days = moment().diff(date, 'days');

      if (days === 0) {
        return 'today';
      } else if (days === 1) {
        return 'yesterday';
      } else {
        return moment(date).fromNow();
      }
    }

  });

  _exports.default = _default;
});