define("donor-elf-web/templates/funds/fund/groups/group/add", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "cX8sZEqR",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,0],[14,0,\"d-flex mb-2\"],[12],[1,\"\\n    \"],[10,\"h3\"],[12],[1,\"Add contacts to the \\\"\"],[1,[33,1,[\"group\"]]],[1,\"\\\" Group\"],[13],[1,\"\\n    \"],[10,0],[14,0,\"ml-auto pl-3\"],[12],[1,\"\\n\"],[6,[39,2],null,[[\"class\",\"activeClass\",\"route\"],[\"btn btn-warning\",\"\",\"funds.fund.groups.group\"]],[[\"default\"],[[[[1,\"        Done Adding Contacts\\n\"]],[]]]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[1,[28,[35,3],null,[[\"value\",\"keyDown\",\"enter\",\"placeholder\",\"class\"],[[33,4],[28,[37,5],[[33,6]],null],[28,[37,7],[[30,0],\"applyFilter\"],null],\"Filter Contacts\",\"mb-3\"]]]],[1,\"\\n  \"],[10,\"table\"],[14,0,\"table\"],[12],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,9],[[28,[37,9],[[33,10]],null]],null],null,[[[1,\"        \"],[1,[28,[35,11],null,[[\"contact\",\"group\",\"onAddedToGroup\"],[[30,1],[33,1,[\"group\"]],[28,[37,7],[[30,0],\"handleAddedToGroup\"],null]]]]],[1,\"\\n\"]],[1]],[[[1,\"        \"],[10,\"td\"],[14,0,\"text-muted\"],[12],[1,\"\\n\"],[41,[33,13],[[[1,\"            No contacts match the '\"],[1,[34,14]],[1,\"' filter\\n\"]],[]],[[[1,\"            It looks like all the contacts are already part of the '\"],[1,[33,1,[\"group\"]]],[1,\"' group\\n\"]],[]]],[1,\"        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"contact\"],false,[\"de-card\",\"model\",\"link-to\",\"de-input\",\"qInput\",\"perform\",\"debounceApplyFilter\",\"action\",\"each\",\"-track-array\",\"filteredContacts\",\"group-add-contact-row\",\"if\",\"filterApplied\",\"q\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/groups/group/add.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});