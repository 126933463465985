define("donor-elf-web/templates/funds/fund/more/tnt-connect-import/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "pHc/ePrQ",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,2],[12],[1,\"\\n    There are two ways you can import from TntConnect into DonorElf.\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    The first way is to export your data from TntConnect and import it into\\n    DonorElf.\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    The second way is to import the TntConnect DataSync file that you receive\\n    from your sending organization. This file is made to be imported into\\n    TntConnect, but it can also be imported into DonorElf.\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"mt-4 mb-2\"],[12],[1,\"\\n    \"],[6,[39,1],null,[[\"class\",\"route\"],[\"btn btn-warning\",\"funds.fund.more.tnt-connect-import.xml\"]],[[\"default\"],[[[[1,\"Import from TntConnect\"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"ul\"],[12],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n      Select this option if you have been using TntConnect to keep track of your\\n      support account but would like to start using DonorElf instead.\\n    \"],[13],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n      We'll show you how to export your data from TntConnect in the next step.\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"mt-5 mb-2\"],[12],[1,\"\\n    \"],[6,[39,1],null,[[\"class\",\"route\"],[\"btn btn-warning\",\"funds.fund.more.tnt-connect-import.data-sync\"]],[[\"default\"],[[[[1,\"Import TntConnect DataSync file\"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"ul\"],[12],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"The file name ends in '.tntdatasync' or '.tntmpd'\"],[13],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"Your sending organization usually sends you this file.\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"de-form-card\",\"link-to\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/more/tnt-connect-import/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});