define("donor-elf-web/validations/admin/user", ["exports", "ember-changeset-validations/validators", "donor-elf-web/validators/unique-user-email", "donor-elf-web/validators/email-domain"], function (_exports, _validators, _uniqueUserEmail, _emailDomain) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateUser;

  function validateUser({
    allUsers
  } = {}) {
    return {
      firstName: (0, _validators.validatePresence)(true),
      lastName: (0, _validators.validatePresence)(true),
      email: [(0, _validators.validateFormat)({
        type: 'email',
        message: 'Invalid email address'
      }), (0, _uniqueUserEmail.default)({
        allUsers: allUsers
      }), (0, _emailDomain.default)()],
      role: (0, _validators.validatePresence)(true)
    };
  }
});