define("donor-elf-web/routes/funds/fund/tasks/edit", ["exports", "@ember/routing/route", "@ember/object", "rsvp", "@ember/utils", "@ember/service", "ember-changeset", "ember-changeset-validations", "donor-elf-web/validations/task"], function (_exports, _route, _object, _rsvp, _utils, _service, _emberChangeset, _emberChangesetValidations, _task) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    abilities: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    contactsCache: (0, _service.inject)(),
    fundsService: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),
    store: (0, _service.inject)(),
    queryParams: {
      contactId: {},
      fromDetailPage: {}
    },

    beforeModel() {
      return (0, _object.get)(this, 'fundsService').confirmValidFundToken();
    },

    model(params) {
      let navbarTitle = null;
      let returnToContactDetailsPage = params.fromDetailPage === 'true';
      let recordPromise = null;
      let contact = null;

      if (params.taskId === 'add') {
        let userId = this.abilities.hasAbility('assign_tasks') ? this.currentUser.id : null;
        recordPromise = _rsvp.default.resolve((0, _object.get)(this, 'store').createRecord('task', {
          contactId: params.contactId,
          userId
        }));

        if (!(0, _utils.isEmpty)(params.contactId)) {
          contact = (0, _object.get)(this, 'contactsCache').getById(params.contactId);
        }

        if (returnToContactDetailsPage && !(0, _utils.isEmpty)(contact)) {
          navbarTitle = (0, _object.get)(contact, 'name');
        } else {
          navbarTitle = 'Add Task';
        }
      } else {
        recordPromise = (0, _object.get)(this, 'store').find('task', params.taskId);
        navbarTitle = 'Edit Task';
      }

      (0, _object.set)(this, 'navbarSettings.title', navbarTitle);
      return recordPromise.then(record => {
        if (returnToContactDetailsPage && !(0, _utils.isEmpty)((0, _object.get)(record, 'contactId'))) {
          contact = (0, _object.get)(this, 'contactsCache').getById((0, _object.get)(record, 'contactId'));
          (0, _object.set)(this, 'navbarSettings.title', (0, _object.get)(contact, 'name'));
        }

        return {
          record,
          contact,
          changeset: new _emberChangeset.default(record, (0, _emberChangesetValidations.default)(_task.default), _task.default),
          saved: false,
          returnToContactDetailsPage: returnToContactDetailsPage
        };
      });
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.reset();
      }
    }

  });

  _exports.default = _default;
});