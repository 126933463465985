define("donor-elf-web/templates/funds/fund/transactions/delete", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Q9CBQE8D",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,2],[12],[1,\"Are you sure you want to delete this transaction?\"],[13],[1,\"\\n  \"],[10,\"dl\"],[14,0,\"ml-0 ml-md-3\"],[12],[1,\"\\n    \"],[10,\"dt\"],[12],[1,\"Date\"],[13],[1,\"\\n    \"],[10,\"dd\"],[12],[1,[28,[35,1],[[33,2,[\"line\",\"transDate\"]]],null]],[13],[1,\"\\n    \"],[10,\"dt\"],[12],[1,\"Contact\"],[13],[1,\"\\n    \"],[10,\"dd\"],[12],[1,[28,[35,3],null,[[\"contactId\"],[[33,2,[\"line\",\"contactId\"]]]]]],[13],[1,\"\\n    \"],[10,\"dt\"],[12],[1,\"Type\"],[13],[1,\"\\n    \"],[10,\"dd\"],[12],[1,[33,2,[\"line\",\"transType\"]]],[13],[1,\"\\n    \"],[10,\"dt\"],[12],[1,\"Amount\"],[13],[1,\"\\n    \"],[10,\"dd\"],[12],[1,[28,[35,4],[[33,2,[\"line\",\"amount\"]]],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"mt-4\"],[12],[1,\"\\n    \"],[10,\"button\"],[15,\"onclick\",[28,[37,5],[[33,6]],null]],[14,0,\"btn btn-danger\"],[15,\"disabled\",[29,[[52,[33,6,[\"isRunning\"]],\"disable\"]]]],[12],[1,\"\\n\"],[41,[33,6,[\"isIdle\"]],[[[1,\"        \"],[1,[28,[35,8],[\"trash\"],null]],[1,\"\\n        Yes, Delete\\n\"]],[]],[[[1,\"        Deleting...\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"],[41,[33,6,[\"isIdle\"]],[[[6,[39,9],null,[[\"class\",\"route\",\"model\"],[\"btn btn-link\",\"funds.fund.transactions.edit.enter\",[33,2,[\"line\",\"id\"]]]],[[\"default\"],[[[[1,\"        Cancel\\n\"]],[]]]]]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"de-form-card\",\"format-date\",\"model\",\"de-contact\",\"format-amount\",\"perform\",\"delete\",\"if\",\"svg-jar\",\"link-to\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/transactions/delete.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});