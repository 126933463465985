define("donor-elf-web/templates/funds/fund/contacts/upload", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "T+C2pL+W",
    "block": "[[[6,[39,0],null,[[\"class\"],[\"row-bottom-margin\"]],[[\"default\"],[[[[1,\"  \"],[10,1],[14,0,\"text-nowrap mr-1\"],[12],[1,\"Steps: \"],[13],[1,\"\\n  \"],[10,1],[15,0,[29,[\"text-nowrap \",[52,[28,[37,2],[[33,3,[\"currentStep\"]],\"1\"],null],\"font-weight-bold\"]]]],[12],[1,\"Upload\"],[13],[1,\" >\\n  \"],[10,1],[15,0,[29,[\"text-nowrap \",[52,[28,[37,2],[[33,3,[\"currentStep\"]],\"2\"],null],\"font-weight-bold\"]]]],[12],[1,\"Map\"],[13],[1,\" >\\n  \"],[10,1],[15,0,[29,[\"text-nowrap \",[52,[28,[37,2],[[33,3,[\"currentStep\"]],\"3\"],null],\"font-weight-bold\"]]]],[12],[1,\"Verify\"],[13],[1,\" >\\n  \"],[10,1],[15,0,[29,[\"text-nowrap \",[52,[28,[37,2],[[33,3,[\"currentStep\"]],\"4\"],null],\"font-weight-bold\"]]]],[12],[1,\"Save\"],[13],[1,\"\\n\"]],[]]]]],[46,[28,[37,5],null,null],null,null,null],[1,\"\\n\"]],[],false,[\"de-card\",\"if\",\"eq\",\"model\",\"component\",\"-outlet\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/contacts/upload.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});