define("donor-elf-web/routes/funds/fund/more/mailchimp-sync", ["exports", "@ember/routing/route", "@ember/service", "@glimmer/tracking"], function (_exports, _route, _service, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.MailchimpSyncRouteModel = void 0;

  var _class, _descriptor, _class3, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MailchimpSyncRouteModel = (_class = class MailchimpSyncRouteModel {
    constructor(mailchimpAccounts, mailchimpFundSyncInfos) {
      _defineProperty(this, "mailchimpAccounts", void 0);

      _initializerDefineProperty(this, "mailchimpFundSyncInfos", _descriptor, this);

      this.mailchimpAccounts = mailchimpAccounts;
      this.mailchimpFundSyncInfos = mailchimpFundSyncInfos;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "mailchimpFundSyncInfos", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.MailchimpSyncRouteModel = MailchimpSyncRouteModel;
  let MailchimpSyncRoute = (_class3 = class MailchimpSyncRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fundsService", _descriptor2, this);

      _initializerDefineProperty(this, "navbarSettings", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);
    }

    beforeModel() {
      this.navbarSettings.title = 'MailChimp Sync';
    }

    async model() {
      let mailchimpAccounts = (await this.store.query('mailchimp-account', {})).toArray();
      let mailchimpFundSyncInfos = [];
      let mailchimpFundSyncs = mailchimpAccounts.map(ma => ma.mailchimpFundSyncs.toArray()).flat();

      if (mailchimpFundSyncs.length > 0) {
        let fundIds = mailchimpFundSyncs.map(mfs => mfs.fundId);
        let funds = await this.fundsService.getFunds(fundIds);
        mailchimpFundSyncInfos = mailchimpFundSyncs.map(mailchimpFundSync => {
          let mailchimpAccount = mailchimpAccounts.find(x => x.id == mailchimpFundSync.mailchimpAccountId);
          return {
            mailchimpFundSync,
            fund: funds.find(x => x.id == mailchimpFundSync.fundId),
            mailchimpAudience: mailchimpAccount.audiences.find(x => x.id === mailchimpFundSync.audienceId),
            mailchimpAccount
          };
        });
      }

      return new MailchimpSyncRouteModel(mailchimpAccounts, mailchimpFundSyncInfos);
    }

    redirect(model, transition) {
      var _transition$to2;

      if (model.mailchimpAccounts.length > 0) {
        var _transition$to;

        if (!((_transition$to = transition.to) !== null && _transition$to !== void 0 && _transition$to.name.endsWith('mailchimp-sync.connected'))) {
          this.transitionTo('funds.fund.more.mailchimp-sync.connected');
        }
      } else if (!((_transition$to2 = transition.to) !== null && _transition$to2 !== void 0 && _transition$to2.name.endsWith('mailchimp-sync.not-connected'))) {
        this.transitionTo('funds.fund.more.mailchimp-sync.not-connected');
      }
    }

  }, (_descriptor2 = _applyDecoratedDescriptor(_class3.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class3.prototype, "navbarSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class3);
  _exports.default = MailchimpSyncRoute;
});