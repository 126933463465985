define("donor-elf-web/templates/reset-password/token", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "US3YMqlj",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[1,[28,[35,1],null,[[\"changeset\",\"property\",\"focusOnInsert\",\"inputType\",\"label\",\"showValidationErrors\"],[[33,2,[\"changeset\"]],\"password\",true,\"password\",\"Set your new password\",[33,3]]]]],[1,\"\\n  \"],[1,[28,[35,1],null,[[\"changeset\",\"property\",\"inputType\",\"label\",\"showValidationErrors\"],[[33,2,[\"changeset\"]],\"passwordConfirmation\",\"password\",\"Confirm your new password\",[33,3]]]]],[1,\"\\n\\n  \"],[10,0],[14,0,\"mt-4\"],[12],[1,\"\\n\"],[41,[33,5],[[[1,\"      \"],[10,0],[14,0,\"alert alert-success\"],[12],[1,\"\\n        \"],[10,2],[12],[1,\"\\n          Sweet! Your password has been updated.\\n        \"],[13],[1,\"\\n        Now you can login with your new password.\\n      \"],[13],[1,\"\\n      \"],[6,[39,6],null,[[\"class\",\"route\"],[\"btn btn-warning\",\"login\"]],[[\"default\"],[[[[1,\"Login\"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,\"button\"],[15,\"onclick\",[28,[37,7],[[33,8]],null]],[14,0,\"btn btn-warning\"],[12],[1,\"Update my Password\"],[13],[1,\"\\n\"],[41,[33,8,[\"isRunning\"]],[[[1,\"        \"],[10,0],[14,0,\"alert alert-info mt-3\"],[12],[1,\"\\n          Updating...\\n        \"],[13],[1,\"\\n\"]],[]],null],[41,[33,9],[[[1,\"        \"],[1,[28,[35,10],null,[[\"error\",\"class\"],[[33,9],\"mt-3\"]]]],[1,\"\\n\"]],[]],null]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"de-form-card\",\"form-group\",\"model\",\"showValidationErrors\",\"if\",\"success\",\"link-to\",\"perform\",\"save\",\"error\",\"generic-error\"]]",
    "moduleName": "donor-elf-web/templates/reset-password/token.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});