define("donor-elf-web/controllers/organization/donors/donor/pledge", ["exports", "@ember/controller", "@ember/object", "@ember/service", "ember-concurrency", "donor-elf-web/lib/pledge-utils"], function (_exports, _controller, _object, _service, _emberConcurrency, _pledgeUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    fundsService: (0, _service.inject)(),
    error: null,

    reset() {
      (0, _object.setProperties)(this, {
        error: null
      });
    },

    fundOptions: (0, _object.computed)('model.funds.@each.id', function () {
      return (0, _object.get)(this, 'model.funds').map(fund => {
        return {
          val: (0, _object.get)(fund, 'id'),
          label: (0, _object.get)(fund, 'name')
        };
      });
    }),
    frequencyOptions: (0, _object.computed)(function () {
      let options = [];
      Object.keys(_pledgeUtils.pledgeFrequencyLabels).forEach(key => {
        // not dealing with one time frequencies yet
        if (_pledgeUtils.pledgeFrequencyLabels[key] !== 'Weekly' && _pledgeUtils.pledgeFrequencyLabels[key] !== 'One Time') {
          options.push({
            val: Number(key),
            label: _pledgeUtils.pledgeFrequencyLabels[key]
          });
        }
      });
      return options;
    }),
    save: (0, _emberConcurrency.task)(function* () {
      let changeset = (0, _object.get)(this, 'model.changeset');
      yield changeset.validate();

      if ((0, _object.get)(changeset, 'isValid')) {
        yield (0, _object.get)(this, 'fundsService').confirmValidFundToken();

        try {
          yield changeset.save();
          this.send('refreshDonorRoute');
          this.transitionToRoute('organization.donors.donor');
        } catch (error) {
          if ((0, _object.get)(this, 'model.record.errors.length') > 0) {
            (0, _object.get)(this, 'model.record.errors').forEach(({
              attribute,
              message
            }) => {
              changeset.pushErrors(attribute, message);
            });
          } else {
            (0, _object.set)(this, 'error', error);
          }
        }
      }
    }).drop(),
    actions: {
      handleFrequencyChanged()
      /* newFrequency */
      {
        (0, _object.set)(this, 'model.changeset.endDateMonths', '12');
      }

    }
  });

  _exports.default = _default;
});