define("donor-elf-web/components/delete-buttons", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    attrs
    - cancelRoute
      - the route name to go when cancel is clicked
    - deleteTask
      - the task to run when delete is clicked
  
  */
  var _default = _component.default.extend({
    deleteTask: null,
    errorDeleting: false,
    actions: {
      delete() {
        (0, _object.get)(this, 'deleteTask').perform().catch(() => {
          (0, _object.set)(this, 'errorDeleting', true);
        });
      }

    }
  });

  _exports.default = _default;
});