define("donor-elf-web/templates/funds/fund/more/intro-video", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "9OBX+SyB",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n\"],[1,\"  \"],[10,0],[14,5,\"padding:75% 0 0 0;\"],[14,0,\"position-relative tw-max-w-5xl\"],[12],[1,\"\\n    \"],[10,\"iframe\"],[14,\"src\",\"https://player.vimeo.com/video/732265566?h=cdf343fc3b\"],[14,0,\"position-absolute tw-top-0 tw-left-0 w-100 h-100\"],[14,\"frameborder\",\"0\"],[14,\"allow\",\"autoplay; fullscreen; picture-in-picture\"],[14,\"allowfullscreen\",\"\"],[14,\"title\",\"intro-video\"],[12],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"mt-4\"],[12],[1,\"\\n    Shareable link to the video: \\n    \"],[10,3],[14,6,\"https://vimeo.com/732265566\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[1,\"https://vimeo.com/732265566\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"card\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/more/intro-video.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});