define("donor-elf-web/templates/funds/fund/more/mailchimp-sync/not-connected", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "/W7xPXnh",
    "block": "[[[8,[39,0],[[24,0,\"row-bottom-margin\"],[4,[38,1],[[30,0,[\"templateDidInsert\"]]],null]],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],[[24,0,\"mb-5\"]],null,null],[1,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-lg btn-warning mb-4\"],[16,\"disabled\",[29,[[52,[30,0,[\"connecting\"]],\"disabled\"]]]],[24,4,\"button\"],[4,[38,4],[\"click\",[30,0,[\"connectToMailchimp\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"connecting\"]],[[[1,\"      Connecting...\\n\"]],[]],[[[1,\"      Connect to MailChimp\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[41,[30,0,[\"error\"]],[[[1,\"    \"],[8,[39,5],[[24,0,\"mb-3\"]],[[\"@error\",\"@useLeftBorderCard\"],[[30,0,[\"error\"]],true]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,2],[12],[1,\"\\n    If you have a MailChimp account, you can have DonorElf automatically sync\\n    your contacts in DonorElf with your MailChimp list. That way whenever a\\n    contact is added or edited in DonorElf, the contact will automatically be\\n    added or updated in your MailChimp list.\\n  \"],[13],[1,\"\\n  \"],[10,\"h4\"],[14,0,\"mt-5\"],[12],[1,\"Don't have a MailChimp Account?\"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    If you don't have a MailChimp account yet, don't worry. It's free to sign\\n    up for an account. \\n  \"],[13],[1,\"\\n  \"],[10,3],[14,6,\"https://login.mailchimp.com/signup\"],[14,0,\"btn btn-outline-brand mb-3\"],[12],[1,\"\\n    Signup for a free MailChimp account\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    And after you sign up, come back to this page and click the big orange\\n    button at the the top to connect DonorElf with your MailChimp account.\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,6],null,null,null],[1,\"\\n\"]],[]]]]]],[],false,[\"form-card\",\"did-insert\",\"mailchimp-header\",\"if\",\"on\",\"generic-error\",\"mailchimp-sync/how-the-sync-works\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/more/mailchimp-sync/not-connected.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});