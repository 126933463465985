define("donor-elf-web/templates/coaching/individuals/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "QPoQXM36",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,\"h3\"],[14,0,\"border-bottom\"],[12],[1,\"Static Info\"],[13],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[33,3,[\"changeset\",\"staticInfo\"]]],null]],null],null,[[[1,\"    \"],[1,[28,[35,4],null,[[\"item\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null]],[]]]]]],[\"item\"],false,[\"de-card\",\"each\",\"-track-array\",\"model\",\"coaching-static-info-edit-item\"]]",
    "moduleName": "donor-elf-web/templates/coaching/individuals/edit.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});