define("donor-elf-web/controllers/funds/fund/funds-summary", ["exports", "@ember/controller", "@ember/object", "@ember/utils", "@ember/service", "@ember/string", "donor-elf-web/config/environment", "donor-elf-web/lib/filter-utils", "donor-elf-web/lib/number-utils", "ember-local-storage", "@glimmer/tracking", "ember-cached-decorator-polyfill", "donor-elf-web/lib/array-utils"], function (_exports, _controller, _object, _utils, _service, _string, _environment, _filterUtils, _numberUtils, _emberLocalStorage, _tracking, _emberCachedDecoratorPolyfill, _arrayUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FundsSummaryController = (_dec = (0, _emberLocalStorage.storageFor)('local-settings'), (_class = class FundsSummaryController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "media", _descriptor, this);

      _initializerDefineProperty(this, "settings", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "storeCache", _descriptor4, this);

      _initializerDefineProperty(this, "localSettings", _descriptor5, this);

      _defineProperty(this, "queryParams", ['filter', 'sortBy', 'sortDir', 'page']);

      _initializerDefineProperty(this, "items", _descriptor6, this);

      _initializerDefineProperty(this, "sortBy", _descriptor7, this);

      _initializerDefineProperty(this, "sortDir", _descriptor8, this);

      _initializerDefineProperty(this, "filter", _descriptor9, this);

      _initializerDefineProperty(this, "loading", _descriptor10, this);

      _initializerDefineProperty(this, "loadingTotalPages", _descriptor11, this);

      _initializerDefineProperty(this, "loadingProgressBarStyle", _descriptor12, this);

      _initializerDefineProperty(this, "filteredPercentBarStyle", _descriptor13, this);

      _initializerDefineProperty(this, "error", _descriptor14, this);

      _initializerDefineProperty(this, "page", _descriptor15, this);

      _defineProperty(this, "perPage", 30);
    }

    get filterObject() {
      if ((0, _utils.isEmpty)(this.filter)) {
        return [[]];
      }

      return JSON.parse(decodeURIComponent(this.filter));
    }

    get showAnnualGoal() {
      var _this$settings$userSe;

      return ((_this$settings$userSe = this.settings.userSettings) === null || _this$settings$userSe === void 0 ? void 0 : _this$settings$userSe.showAnnualGoal) || false;
    }

    get orgHasYTdDashboard() {
      return this.settings.orgSettings.ytdStartAtMonth != null;
    }

    get canAdjustShowOptions() {
      return !this.orgHasYTdDashboard;
    }

    get fundGroups() {
      return this.model.fundGroups;
    }

    get fundGroupIdsByFundId() {
      let byFundId = {};
      this.fundGroups.forEach(fundGroup => {
        var _fundGroup$fundIds;

        (_fundGroup$fundIds = fundGroup.fundIds) === null || _fundGroup$fundIds === void 0 ? void 0 : _fundGroup$fundIds.forEach(fundId => {
          if (byFundId[fundId] == null) {
            byFundId[fundId] = [];
          }

          byFundId[fundId].push(fundGroup.id);
        });
      });
      return byFundId;
    }

    get filterSettings() {
      let filterSettings = [{
        prop: 'fundSummary.fundNameLowerCase',
        label: 'Fund Name',
        type: 'string',
        mapValFn: val => {
          return (val || '').toLowerCase();
        }
      }];

      if (!this.settings.orgSettings.incomeOnly) {
        filterSettings.push({
          prop: 'fundSummary.balance',
          label: 'Balance',
          type: 'number'
        });
      }

      if (this.orgHasYTdDashboard) {
        filterSettings = filterSettings.concat([{
          prop: 'fundSummary.ytdGoal.annualGoal',
          label: 'Annual Goal',
          type: 'number'
        }, {
          prop: 'fundSummary.ytdGoal.totalPercent',
          label: 'Total Percent',
          type: 'number'
        }, {
          prop: 'fundSummary.ytdGoal.recurringPledgesPercent',
          label: 'Recurring Percent',
          type: 'number'
        }]);
      } else {
        if (this.showAnnualGoal) {
          filterSettings = filterSettings.concat([{
            prop: 'fundSummary.rollingGoal.annualGoal',
            label: 'Annual Goal',
            type: 'number'
          }, {
            prop: 'fundSummary.rollingGoal.lastYearAnnualAmount',
            label: 'Annual Amount',
            type: 'number'
          }, {
            prop: 'fundSummary.rollingGoal.lastYearAvgPercentage',
            label: 'Annual Amount %',
            type: 'number'
          }, {
            prop: 'fundSummary.rollingGoal.pledgedAnnualAmount',
            label: 'Funded Amount',
            type: 'number'
          }, {
            prop: 'fundSummary.rollingGoal.pledgedMonthlyPercent',
            label: 'Funded %',
            type: 'number'
          }]);
        } else {
          filterSettings = filterSettings.concat([{
            prop: 'fundSummary.rollingGoal.monthlyGoal',
            label: 'Monthly Goal',
            type: 'number'
          }, {
            prop: 'fundSummary.rollingGoal.lastYearAvgAmount',
            label: 'Monthly Average',
            type: 'number'
          }, {
            prop: 'fundSummary.rollingGoal.lastYearAvgPercentage',
            label: 'Monthly Average %',
            type: 'number'
          }, {
            prop: 'fundSummary.rollingGoal.pledgedMonthlyAmount',
            label: 'Funded Amount',
            type: 'number'
          }, {
            prop: 'fundSummary.rollingGoal.pledgedMonthlyPercent',
            label: 'Funded %',
            type: 'number'
          }]);
        }
      }

      if (this.fundGroups.length > 0) {
        filterSettings = filterSettings.concat([{
          prop: 'fundGroupIds',
          label: 'Fund Group',
          type: 'select',
          options: this.fundGroups.map(fundGroup => {
            return {
              label: fundGroup.name,
              val: fundGroup.id
            };
          })
        }]);
      }

      return filterSettings;
    }

    get chartHeight() {
      return this.media.isSmallDown ? 100 : 200;
    }

    get canShowBalance() {
      return !this.settings.orgSettings.incomeOnly;
    }

    get canShowExpense() {
      return !this.settings.orgSettings.incomeOnly;
    }

    async loadFundSummariesPage(page) {
      let loadingPercentage = 5;

      if (this.loadingTotalPages && page > 1) {
        loadingPercentage = Math.round((page - 1) * 100 / this.loadingTotalPages);
      }

      if (loadingPercentage < 5) {
        loadingPercentage = 5;
      }

      this.loadingProgressBarStyle = (0, _string.htmlSafe)(`width: ${loadingPercentage}%`);

      try {
        let fundSummaries = await this.store.query('fund-summary', {
          page
        });
        this.items = [...this.items, ...fundSummaries.map(fundSummary => {
          return {
            fundSummary,
            fundGroupIds: this.fundGroupIdsByFundId[fundSummary.fundId]
          };
        })];
        this.loadingTotalPages = fundSummaries.meta.total_pages;

        if (page < fundSummaries.meta.total_pages) {
          this.loadFundSummariesPage(page + 1);
        } else {
          this.setFilteredPercentBarStyle();
          this.loading = false;
        }
      } catch (error) {
        this.error = error;
      }
    }

    get filteredItems() {
      return _filterUtils.default.getFilteredResults({
        filters: this.filterObject,
        filterSettings: this.filterSettings,
        items: this.items,
        itemIdProp: 'fundSummary.id'
      });
    }

    get filterApplied() {
      return this.filteredItems.length < this.items.length;
    }

    get filteredPercent() {
      return _numberUtils.default.roundPrecision(this.filteredItems.length * 100 / this.items.length, 1);
    }

    setFilteredPercentBarStyle() {
      this.filteredPercentBarStyle = (0, _string.htmlSafe)(`width: ${this.filteredPercent}%`);
    }

    get sortedItems() {
      return (0, _arrayUtils.sortByProperties)(this.filteredItems, [`${this.sortBy}:${this.sortDir}`]);
    }

    get pagedItems() {
      let offset = (this.page - 1) * this.perPage;
      return this.sortedItems.slice(offset, offset + this.perPage);
    }

    get totalPages() {
      return Math.ceil(this.sortedItems.length / this.perPage);
    }

    get rootURL() {
      return _environment.default.routerRootURL;
    }

    templateDidInsert() {
      this.loadFundSummariesPage(1);
    }

    templateWillDestroy() {
      this.sortBy = 'fundSummary.fundNameLowerCase';
      this.sortDir = 'asc';
      this.filter = '';
      this.loading = true;
      this.loadingTotalPages = undefined;
      this.error = undefined;
      this.items = [];
      this.page = 1;
    }

    handleSortBy(property) {
      if (this.sortBy === property) {
        if (this.sortDir === 'asc') {
          this.sortDir = 'desc';
        } else {
          this.sortDir = 'asc';
        }
      } else {
        this.sortBy = property;
        this.sortDir = 'desc';
      }

      this.page = 1;
    }

    handleApplyFilter(filters) {
      this.loading = true;
      this.page = 1;
      this.filter = encodeURIComponent(JSON.stringify(filters)); // showing loading screen for 50 ms to show the user the filter has been
      // applied

      setTimeout(() => {
        this.setFilteredPercentBarStyle();
        this.loading = false;
      }, 50);
    }

    toggle(property) {
      this.toggleProperty(property);
    }

    changePage(pageNumber) {
      this.page = pageNumber;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "media", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "storeCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "localSettings", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "items", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "sortBy", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'fundSummary.fundNameLowerCase';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "sortDir", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'asc';
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "filter", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "loadingTotalPages", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "loadingProgressBarStyle", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "filteredPercentBarStyle", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "error", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "page", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "filterObject", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "filterObject"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fundGroupIdsByFundId", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "fundGroupIdsByFundId"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleSortBy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleSortBy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleApplyFilter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleApplyFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changePage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changePage"), _class.prototype)), _class));
  _exports.default = FundsSummaryController;
});