define("donor-elf-web/components/coaching-format-field", ["exports", "@ember/component", "@ember/object", "@ember/service", "@ember/object/computed"], function (_exports, _component, _object, _service, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* attrs
    - field (coaching/individual sectionField)
    - report - the coach report
    - individual - the coach individual record
    - diff - display the difference from previous report val
    - propertyName - property name to display. Default is 'v'
  */
  var _default = _component.default.extend({
    coachingService: (0, _service.inject)(),
    field: null,
    report: null,
    individual: null,
    diff: false,
    propertyName: null,
    fieldType: (0, _computed.alias)('field.t'),
    val: (0, _object.computed)('field.id', 'report.id', 'propertyName', 'total', function () {
      let {
        field,
        report,
        coachingService,
        diff,
        propertyName
      } = (0, _object.getProperties)(this, 'field', 'report', 'coachingService', 'diff', 'propertyName');

      if (diff) {
        return (0, _object.get)(this, 'diffVal');
      } else if (propertyName) {
        return coachingService.getFieldValue(field, report, {
          propertyName
        });
      } else {
        return coachingService.getFieldValue(field, report);
      }
    }),
    diffVal: (0, _object.computed)('field.id', 'report.id', 'diff', 'propertyName', function () {
      let {
        field,
        report,
        propertyName,
        individual
      } = (0, _object.getProperties)(this, 'field', 'report', 'propertyName', 'individual');
      return this.coachingService.getFieldValueDiff(field, report, individual, {
        propertyName
      });
    }),
    prevReportTasks: (0, _object.computed)('field.id', 'report.id', 'total', function () {
      let {
        field,
        fieldType,
        report,
        individual,
        coachingService
      } = (0, _object.getProperties)(this, 'field', 'fieldType', 'report', 'individual', 'coachingService');

      if (fieldType !== 'tasks') {
        return null;
      }

      let sortedReports = (0, _object.get)(individual, 'sortedReports').toArray();
      let reportIndex = sortedReports.indexOf(report);

      if (reportIndex === 0) {
        return null;
      }

      let prevReport = sortedReports[reportIndex - 1];
      return coachingService.getFieldValue(field, prevReport);
    }),
    valIsEmpty: (0, _computed.empty)('val')
  });

  _exports.default = _default;
});