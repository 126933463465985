define("donor-elf-web/controllers/funds/fund/more/donor-hub/connect/credentials", ["exports", "@ember/controller", "@ember/object", "@ember/service", "ember-concurrency"], function (_exports, _controller, _object, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    fundsService: (0, _service.inject)(),
    store: (0, _service.inject)(),
    error: null,
    showValidationErrors: false,

    reset() {
      (0, _object.setProperties)(this, {
        error: null,
        showValidationErrors: false
      });
    },

    save: (0, _emberConcurrency.task)(function* () {
      let changeset = (0, _object.get)(this, 'model.changeset');

      try {
        yield changeset.validate();
        (0, _object.set)(this, 'showValidationErrors', true);

        if ((0, _object.get)(changeset, 'isValid')) {
          yield (0, _object.get)(this, 'fundsService').confirmValidFundToken();
          yield changeset.save();
          this.transitionToRoute('funds.fund.more.donor-hub.connect.downloading');
        }
      } catch (error) {
        if ((0, _object.get)(this, 'model.record.errors.length') > 0) {
          (0, _object.get)(this, 'model.record.errors').forEach(({
            attribute,
            message
          }) => {
            changeset.pushErrors(attribute, message);
          });
        } else {
          (0, _object.set)(this, 'error', error);
        }
      }
    }).drop()
  });

  _exports.default = _default;
});