define("donor-elf-web/routes/admin/fund-groups/fund-group/edit-users", ["exports", "@ember/routing/route", "@ember/object", "rsvp", "@ember/service", "donor-elf-web/lib/model-utils"], function (_exports, _route, _object, _rsvp, _service, _modelUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    fundsService: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),
    store: (0, _service.inject)(),
    storeCache: (0, _service.inject)(),

    beforeModel() {
      (0, _object.set)(this, 'navbarSettings.title', 'Edit Users');
      return (0, _object.get)(this, 'fundsService').confirmValidFundToken();
    },

    model() {
      let fundGroupModel = this.modelFor('admin.fund-groups.fund-group');
      let fundGroup = (0, _object.get)(fundGroupModel, 'fundGroup');
      return _rsvp.default.hash({
        fundGroupUsers: (0, _object.get)(this, 'store').query('admin/fund-group-user', {
          filter: {
            fund_group_id: (0, _object.get)(fundGroup, 'id')
          }
        }),
        allUsers: (0, _object.get)(this, 'storeCache').findAll('admin/user').then(users => {
          return _modelUtils.default.filterPersisted(users);
        }),
        usersById: (0, _object.get)(this, 'storeCache').indexSingleBy('admin/user', 'id')
      }).then(data => {
        let fgUsersByUserId = {};
        data.fundGroupUsers.forEach(fgUser => {
          fgUsersByUserId[(0, _object.get)(fgUser, 'userId')] = fgUser;
        });
        return {
          fundGroup: fundGroup,
          userItems: data.allUsers.map(user => {
            let fgUser = fgUsersByUserId[(0, _object.get)(user, 'id')];
            return {
              user: user,
              fundGroupUser: fgUser,
              isSelected: fgUser != null
            };
          })
        };
      });
    },

    setupController(controller) {
      this._super(...arguments);

      let params = this.paramsFor('admin.fund-groups.fund-group.edit-users');
      (0, _object.setProperties)(controller, {
        q: params.q,
        qInput: params.q
      });
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.reset();
      }
    }

  });

  _exports.default = _default;
});