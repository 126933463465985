define("donor-elf-web/templates/funds/fund/more/donor-hub/connect/downloading", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "i0CQfGWR",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,2],[12],[1,\"Your information is being downloaded from the Donor Hub server\"],[13],[1,\"\\n\\n  \"],[1,[28,[35,1],null,[[\"check\",\"onComplete\",\"class\"],[true,[28,[37,2],[[30,0],\"onFundUploadComplete\"],null],\"mb-4\"]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"de-form-card\",\"fund-upload-progress\",\"action\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/more/donor-hub/connect/downloading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});