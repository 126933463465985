define("donor-elf-web/routes/funds/fund/more/notifications", ["exports", "@ember/routing/route", "@ember/object", "@ember/service"], function (_exports, _route, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    fundsService: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),
    store: (0, _service.inject)(),

    beforeModel() {
      (0, _object.set)(this, 'navbarSettings.title', 'Notifications');
      return (0, _object.get)(this, 'fundsService').confirmValidFundToken();
    },

    model() {
      return (0, _object.get)(this, 'store').findAll('notification').then(records => {
        return {
          record: (0, _object.get)(records, 'firstObject')
        };
      });
    },

    actions: {
      refreshNotifications() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});