define("donor-elf-web/controllers/admin/upload/new", ["exports", "@ember/object/computed", "@ember/controller", "@ember/object", "@ember/runloop", "@ember/service", "@ember/string", "ember-concurrency"], function (_exports, _computed, _controller, _object, _runloop, _service, _string, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    currentUser: (0, _service.inject)(),
    store: (0, _service.inject)(),
    showNoFileChosenErrorMessage: false,
    showSelectAtLeastOneFileErrorMessage: false,
    uploading: false,
    error: null,
    uploadFiles: (0, _computed.alias)('model.uploadFiles'),

    reset() {
      (0, _object.setProperties)(this, {
        showNoFileChosenErrorMessage: false,
        showSelectAtLeastOneFileErrorMessage: false,
        uploading: false,
        error: null,
        uploadFilesCompleteStatus: []
      });
    },

    showUploadAllDropdown: (0, _object.computed)('currentUser.organizationId', function () {
      return this.currentUser.organizationId !== '366'; // RUF
    }),
    hasNoErrors: (0, _object.computed)('uploadFiles.@each.fileChosen', function () {
      let noFileChosen = (0, _object.get)(this, 'uploadFiles').find(uploadFile => {
        return !(0, _object.get)(uploadFile, 'fileChosen');
      });
      return noFileChosen == null;
    }),

    removeEmptyOptionalFiles() {
      let emptyOptionalFiles = (0, _object.get)(this, 'uploadFiles').filter(uploadFile => {
        return !(0, _object.get)(uploadFile, 'required') && !(0, _object.get)(uploadFile, 'fileChosen');
      });
      (0, _object.get)(this, 'uploadFiles').removeObjects(emptyOptionalFiles);
    },

    uploadFilesWithFileChosen: (0, _computed.filterBy)('uploadFiles', 'fileChosen'),
    uploadFilesCompleteStatus: [],

    checkIfAllFileUploadsAreComplete() {
      let uploadFiles = (0, _object.get)(this, 'uploadFiles');
      let completedFilesLength = (0, _object.get)(this, 'uploadFilesCompleteStatus').filter(i => {
        return i.completed;
      }).length;

      if ((0, _object.get)(uploadFiles, 'length') === completedFilesLength) {
        (0, _object.get)(this, 'processUpload').perform();
      }
    },

    processUpload: (0, _emberConcurrency.task)(function* () {
      try {
        let uploadFiles = (0, _object.get)(this, 'uploadFiles');
        let processUpload = (0, _object.get)(this, 'store').createRecord('admin/process-upload', {
          uploadAll: (0, _object.get)(this, 'model.changeset.uploadAll')
        });
        uploadFiles.forEach(uploadFile => {
          let argKey = (0, _string.camelize)((0, _object.get)(uploadFile, 'processArgKey'));

          if ((0, _object.get)(uploadFile, 'processArgIsArray')) {
            let val = (0, _object.get)(processUpload, argKey) || [];
            val.push((0, _object.get)(uploadFile, 'key'));
            (0, _object.set)(processUpload, argKey, val);
          } else {
            (0, _object.set)(processUpload, argKey, (0, _object.get)(uploadFile, 'key'));
          }
        });
        yield processUpload.save();
        this.transitionToRoute('admin.upload.complete');
      } catch (error) {
        (0, _object.setProperties)(this, {
          error,
          uploading: false
        });
      }
    }),
    actions: {
      upload() {
        if ((0, _object.get)(this, 'uploadFilesWithFileChosen.length') === 0) {
          (0, _object.set)(this, 'showSelectAtLeastOneFileErrorMessage', true);
          return;
        }

        (0, _object.setProperties)(this, {
          showSelectAtLeastOneFileErrorMessage: false,
          uploading: true
        });
        this.removeEmptyOptionalFiles(); // have to give time for the removed optional files components to completely destroy

        (0, _runloop.next)(() => {
          (0, _object.set)(this, 'showNoFileChosenErrorMessage', true);

          if ((0, _object.get)(this, 'hasNoErrors')) {
            (0, _object.set)(this, 'uploadFilesCompleteStatus', (0, _object.get)(this, 'uploadFiles').map(uploadFile => {
              return {
                uploadFile,
                completed: false
              };
            }));
            (0, _object.get)(this, 'model.beginUploadEventBus').beginUpload();
          }
        });
      },

      handleUploadComplete(uploadFile) {
        let item = (0, _object.get)(this, 'uploadFilesCompleteStatus').find(i => {
          return i.uploadFile === uploadFile;
        });

        if (item) {
          item.completed = true;
        }

        this.checkIfAllFileUploadsAreComplete();
      },

      handleUploadError(error) {
        (0, _object.set)(this, 'error', error);
      },

      handleUploadCancelled() {
        (0, _object.set)(this, 'error', new Error('upload cancelled'));
      }

    }
  });

  _exports.default = _default;
});