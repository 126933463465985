define("donor-elf-web/routes/admin/users/edit", ["exports", "@ember/routing/route", "@ember/object", "@ember/utils", "rsvp", "@ember/service", "ember-changeset", "ember-changeset-validations", "donor-elf-web/validations/admin/user"], function (_exports, _route, _object, _utils, _rsvp, _service, _emberChangeset, _emberChangesetValidations, _user) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    abilities: (0, _service.inject)(),
    fundsService: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),
    storeCache: (0, _service.inject)(),

    beforeModel(transition) {
      if (this.abilities.noAbility('admin_edit_users')) {
        transition.abort();
        return;
      }

      return (0, _object.get)(this, 'fundsService').confirmValidFundToken();
    },

    model(params) {
      let storeCache = (0, _object.get)(this, 'storeCache');

      let promise = _rsvp.default.hash({
        users: storeCache.findAll('admin/user'),
        roles: storeCache.findAll('admin/role')
      });

      return promise.then(data => {
        let model = data.users.find(user => {
          return (0, _object.get)(user, 'id') === params.id;
        });
        let validationInstance = (0, _user.default)({
          allUsers: data.users
        });
        let changeset = new _emberChangeset.default(model || {}, (0, _emberChangesetValidations.default)(validationInstance), validationInstance);
        return {
          model: model,
          changeset: changeset,
          roles: data.roles
        };
      });
    },

    afterModel(model) {
      if ((0, _utils.isEmpty)(model.model)) {
        this.transitionTo('admin.users.not-found');
      } else {
        (0, _object.set)(this, 'navbarSettings.title', (0, _object.get)(model, 'model.displayName'));
      }
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.reset();
      }
    }

  });

  _exports.default = _default;
});