define("donor-elf-web/components/de-input", ["exports", "@ember/component/text-field"], function (_exports, _textField) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    Properties
    - focusOnInsert (default false)
  
    Actions
    - onUpArrow
    - onDownArrow
    - onTab
  */
  var _default = _textField.default.extend({
    classNames: ['form-control'],
    focusOnInsert: false,

    didInsertElement() {
      this._super(...arguments);

      if (this.focusOnInsert) {
        this.element.focus();
      }
    },

    keyDown(event) {
      this._super(...arguments);

      if (event.which === 38) {
        if (this.onUpArrow != null) {
          this.onUpArrow(event);
        }
      } else if (event.which === 40) {
        if (this.onDownArrow != null) {
          this.onDownArrow(event);
        }
      } else if (event.which === 9) {
        if (this.onTab != null) {
          this.onTab(event);
        }
      }
    }

  });

  _exports.default = _default;
});