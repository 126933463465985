define("donor-elf-web/initializers/flash-messages", ["exports", "ember", "donor-elf-web/config/environment"], function (_exports, _ember, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;
  const {
    deprecate
  } = _ember.default;
  const merge = _ember.default.assign || _ember.default.merge;
  const INJECTION_FACTORIES_DEPRECATION_MESSAGE = '[ember-cli-flash] Future versions of ember-cli-flash will no longer inject the service automatically. Instead, you should explicitly inject it into your Route, Controller or Component with `Ember.inject.service`.';
  const addonDefaults = {
    timeout: 3000,
    extendedTimeout: 0,
    priority: 100,
    sticky: false,
    showProgress: false,
    type: 'info',
    types: ['success', 'info', 'warning', 'danger', 'alert', 'secondary'],
    injectionFactories: ['route', 'controller', 'view', 'component'],
    preventDuplicates: false
  };

  function initialize() {
    const application = arguments[1] || arguments[0];
    const {
      flashMessageDefaults
    } = _environment.default || {};
    const {
      injectionFactories
    } = flashMessageDefaults || [];
    const options = merge(addonDefaults, flashMessageDefaults);
    const shouldShowDeprecation = !(injectionFactories && injectionFactories.length);
    application.register('config:flash-messages', options, {
      instantiate: false
    });
    application.inject('service:flash-messages', 'flashMessageDefaults', 'config:flash-messages');
    deprecate(INJECTION_FACTORIES_DEPRECATION_MESSAGE, shouldShowDeprecation, {
      id: 'ember-cli-flash.deprecate-injection-factories',
      until: '2.0.0'
    });
    options.injectionFactories.forEach(factory => {
      application.inject(factory, 'flashMessages', 'service:flash-messages');
    });
  }

  var _default = {
    name: 'flash-messages',
    initialize
  };
  _exports.default = _default;
});