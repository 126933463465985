define("donor-elf-web/validations/campaign-goal", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    goalAmount: (0, _validators.validateNumber)({
      gt: 0,
      integer: true,
      allowBlank: false,
      message: 'Must be a positive whole number'
    }),
    startDate: (0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    })
  };
  _exports.default = _default;
});