define("donor-elf-web/routes/funds/no-access", ["exports", "@ember/routing/route", "@ember/service", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _route, _service, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_authenticatedRouteMixin.default, {
    navbarSettings: (0, _service.inject)(),

    beforeModel() {
      this.navbarSettings.title = 'No Access';
    }

  });

  _exports.default = _default;
});