define("donor-elf-web/templates/funds/fund/more/newsletter/not-connected", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "wE1RT4bZ",
    "block": "[[[8,[39,0],[[4,[38,1],[[30,0,[\"templateWillDestroy\"]]],null]],null,[[\"default\"],[[[[1,\"\\n  \"],[10,\"h4\"],[14,0,\"border-bottom\"],[12],[1,\"Connect to prayerletters.com\"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    If you have a prayerletters.com account, you can connect your DonorElf\\n    account to your prayerletters.com mailing list.\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Once connected, a new \"],[10,\"strong\"],[12],[1,\"Subscribe to Newsletter\"],[13],[1,\" checkbox\\n    field will be shown while editing the address fields for a contact. That\\n    will allow you to subscribe/unsubscribe the contact from the mailing list on\\n    prayerletters.com.\\n  \"],[13],[1,\"\\n  \"],[8,[39,2],[[24,0,\"mb-4\"]],null,[[\"default\"],[[[[1,\"\\n    Before you connect DonorElf to your prayerletters.com account, make sure to\\n    download a backup of your mailing list in prayerletters.com first since it\\n    will be replaced by your DonorElf contacts that are subscribed to the\\n    Newsletter Sync.\\n  \"]],[]]]]],[1,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-warning\"],[24,4,\"button\"],[4,[38,3],[\"click\",[30,0,[\"authenticateWithPrayerLetters\"]]],null],[12],[1,\"\\n    Connect to prayerletters.com\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"error\"]],[[[1,\"    \"],[8,[39,5],[[24,0,\"mt-3\"]],[[\"@error\"],[[30,0,[\"error\"]]]],null],[1,\"\\n\"]],[]],null]],[]]]]]],[],false,[\"form-card\",\"will-destroy\",\"alert/info\",\"on\",\"if\",\"generic-error\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/more/newsletter/not-connected.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});