define("donor-elf-web/controllers/funds/fund/more/tnt-connect-import/data-sync/index", ["exports", "@ember/controller", "@ember/object"], function (_exports, _controller, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    invalidFileExtension: (0, _object.computed)('model.selectedFile', function () {
      let selectedFile = (0, _object.get)(this, 'model.selectedFile');

      if (selectedFile) {
        // had to add tntdatasync\.txt for Wycliffe Canada. For some reason they add that
        return /\.(tntdatasync|tntmpd|tntconnect|tntdatasync\.txt)$/.test(selectedFile.name) === false;
      } else {
        return false;
      }
    }),
    actions: {
      handleFileSelected(file) {
        (0, _object.set)(this, 'model.selectedFile', file);

        if (!(0, _object.get)(this, 'invalidFileExtension')) {
          this.transitionToRoute('funds.fund.more.tnt-connect-import.data-sync.processing');
        }
      }

    }
  });

  _exports.default = _default;
});