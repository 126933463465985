define("donor-elf-web/lib/model-utils", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    // `models` is typically a DS.RecordArray return from the store
    //
    // Example usage
    // return get(this, 'store').findAll('user').then((users) => {
    //   return modelUtils.filterPersisted(users);
    // });
    filterPersisted(models) {
      return models.filter(model => {
        return !(0, _object.get)(model, 'isNew') && !(0, _object.get)(model, 'isDeleted');
      });
    }

  };
  _exports.default = _default;
});