define("donor-elf-web/routes/funds/fund/ytd-next-year-projection", ["exports", "@ember/object", "@ember/routing/route", "@ember/service", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators"], function (_exports, _object, _route, _service, _emberChangeset, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const projectionValidation = {
    goal: (0, _validators.validateNumber)({
      message: 'Needs to be a number'
    })
  };
  let YtdNextYearProjectionRoute = (_class = class YtdNextYearProjectionRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fundsService", _descriptor, this);

      _initializerDefineProperty(this, "navbarSettings", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);
    }

    beforeModel() {
      this.navbarSettings.title = 'Next Year Projection';
    }

    async model() {
      try {
        let record = await this.store.findRecord('ytd-next-year-projection', this.fundsService.selectedFund.id);
        let pledgesById = {};
        record.pledges.forEach(pledge => {
          pledgesById[pledge.id] = pledge;
        });
        let changeset = new _emberChangeset.default(record, (0, _emberChangesetValidations.default)(projectionValidation), projectionValidation);
        return {
          record,
          pledgesById,
          changeset
        };
      } catch (e) {
        var _e$errors, _e$errors$;

        if (((_e$errors = e.errors) === null || _e$errors === void 0 ? void 0 : (_e$errors$ = _e$errors[0]) === null || _e$errors$ === void 0 ? void 0 : _e$errors$.status) === '404') {
          this.transitionTo('funds.fund.ytd-next-year-projection-no-goal');
          return;
        } else {
          throw e;
        }
      }
    }

    refreshYtdNextYearProjectionRoute() {
      this.refresh();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "navbarSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "refreshYtdNextYearProjectionRoute", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "refreshYtdNextYearProjectionRoute"), _class.prototype)), _class);
  _exports.default = YtdNextYearProjectionRoute;
});