define("donor-elf-web/components/form-group-checkbox", ["exports", "@ember/component", "@ember/object", "@ember/runloop"], function (_exports, _component, _object, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    attrs
    - changeset
    - property
    - label
    - labelAbove
      - if true, places the label above the checkbox
      - default is false
    - showValidationErrors
    - onChange(newVal)
  */
  var _default = _component.default.extend({
    classNames: ['form-check'],
    classNameBindings: ['hasErrors'],
    label: null,
    labelAbove: false,
    changeset: null,
    // the associated property on the changeset
    property: null,
    showValidationErrors: false,
    hasErrors: (0, _object.computed)('showValidationErrors', 'changeset.errors.[]', 'property', function () {
      if ((0, _object.get)(this, 'showValidationErrors') === false) {
        return false;
      }

      let property = (0, _object.get)(this, 'property');
      return (0, _object.get)(this, 'changeset.errors').find(function (item) {
        return (0, _object.get)(item, 'key') === property;
      }) != null;
    }),
    actions: {
      handleChange() {
        let onChange = (0, _object.get)(this, 'onChange');

        if (onChange) {
          // give time for the actual property to update on the changeset
          (0, _runloop.next)(() => {
            if (!(0, _object.get)(this, 'isDestroyed')) {
              onChange((0, _object.get)(this, `changeset.${(0, _object.get)(this, 'property')}`));
            }
          });
        }
      }

    }
  });

  _exports.default = _default;
});