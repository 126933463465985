define("donor-elf-web/components/contact-duplicates", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /*
    attrs
      - contactDuplicates - array
  */
  let ContactDuplicatesComponent = (_class = class ContactDuplicatesComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "items", _descriptor, this);
    }

    templateDidInsert() {
      this.items = this.args.contactDuplicates.slice();
    }

    handleMerged({
      contactDuplicate
    }) {
      this.items = this.items.filter(x => x !== contactDuplicate);
    }

    handleNotMerged({
      contactDuplicate
    }) {
      this.items = this.items.filter(x => x !== contactDuplicate);
    }

    handleRecordDestroyedConfirmed(contactDuplicate) {
      this.items = this.items.filter(x => x !== contactDuplicate);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "items", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleMerged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleMerged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleNotMerged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleNotMerged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleRecordDestroyedConfirmed", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleRecordDestroyedConfirmed"), _class.prototype)), _class);
  _exports.default = ContactDuplicatesComponent;
});