define("donor-elf-web/routes/funds/fund/contacts/contact/dates/edit", ["exports", "@ember/routing/route", "@ember/object", "@ember/service", "ember-changeset", "ember-changeset-validations", "donor-elf-web/validations/contact-date"], function (_exports, _route, _object, _service, _emberChangeset, _emberChangesetValidations, _contactDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    abilities: (0, _service.inject)(),
    fundsService: (0, _service.inject)(),
    store: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),

    beforeModel(transition) {
      if ((0, _object.get)(this, 'abilities').noAbility('edit_contact')) {
        transition.abort();
      }

      return (0, _object.get)(this, 'fundsService').confirmValidFundToken();
    },

    model(params) {
      let contact = (0, _object.get)(this.modelFor('funds.fund.contacts.contact'), 'contact');

      if (params.dateId === 'add') {
        let record = (0, _object.get)(this, 'store').createRecord('contact-date', {
          contactId: (0, _object.get)(contact, 'id')
        });
        return {
          contact: contact,
          record: record,
          changeset: new _emberChangeset.default(record, (0, _emberChangesetValidations.default)(_contactDate.default), _contactDate.default)
        };
      } else {
        return (0, _object.get)(this, 'store').find('contact-date', params.dateId).then(record => {
          return {
            contact: contact,
            record: record,
            changeset: new _emberChangeset.default(record, (0, _emberChangesetValidations.default)(_contactDate.default), _contactDate.default)
          };
        });
      }
    }

  });

  _exports.default = _default;
});