define("donor-elf-web/lib/route-utils", ["exports", "@ember/object", "@ember/utils"], function (_exports, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    /*
      This is needed for the situation where the url parameter is the same
      value as the controller property. When that's the case, the `params`
      value for the property is a blank string.
       For example, say a controller has 2 properties that are also part of the
      queryParams array. Let's say the properties are named `from` and `to`.
       And on the route these 2 queryParams are set to refresh the model. And let's
      say you go to the page and `from` is set to '2017-09-05' and to is set to
      '2017-10-05'.
       Now let's say `to` is changed to '2017-11-05'. Then the `model` hook is called
      and the `params` object will have `from` and `to` on it. `params.to` will be
      the new value of '2017-11-05', but `params.from` will be '' because it didn't
      change on the controller. But the route model hook needs to know the `from`
      value, so this method is used to get the correct `from` value from the controller.
     */
    getValFromControllerOrParams(property, controller, params) {
      let value = null;

      if (controller) {
        value = (0, _object.get)(controller, property);
      }

      if ((0, _utils.isEmpty)(value)) {
        value = params[property];
      }

      return value;
    }

  };
  _exports.default = _default;
});