define("donor-elf-web/templates/funds/fund/ytd-next-year-projection-no-goal", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "UDhGehg5",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[10,\"h3\"],[12],[1,\"No Goal\"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Before you can look at next year's projection, you need to create a goal\\n    on the dashboard.\\n  \"],[13],[1,\"\\n  \"],[8,[39,1],[[24,0,\"btn btn-warning mt-2\"]],[[\"@route\"],[\"funds.fund.goal.ytd\"]],[[\"default\"],[[[[1,\"\\n    Create a Goal\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"form-card\",\"link-to\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/ytd-next-year-projection-no-goal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});