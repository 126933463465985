define("donor-elf-web/templates/organization/donors/donor/pledge-delete", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "wIuDo6DY",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,2],[12],[1,\"\\n    Are you sure you want to delete this pledge?\\n  \"],[13],[1,\"\\n    \"],[10,\"dl\"],[14,0,\"ml-0 ml-md-3\"],[12],[1,\"\\n      \"],[10,\"dt\"],[12],[1,\"Fund\"],[13],[1,\"\\n      \"],[10,\"dd\"],[12],[1,[33,1,[\"record\",\"fundName\"]]],[13],[1,\"\\n      \"],[10,\"dt\"],[12],[1,\"Frequency\"],[13],[1,\"\\n      \"],[10,\"dd\"],[12],[1,[33,1,[\"record\",\"frequencyLabel\"]]],[13],[1,\"\\n      \"],[10,\"dt\"],[12],[1,\"Amount\"],[13],[1,\"\\n      \"],[10,\"dd\"],[12],[1,[28,[35,2],[[33,1,[\"record\",\"amount\"]]],null]],[13],[1,\"\\n      \"],[10,\"dt\"],[12],[1,\"Start Date\"],[13],[1,\"\\n      \"],[10,\"dd\"],[12],[1,[28,[35,3],[[33,1,[\"record\",\"startDate\"]]],null]],[13],[1,\"\\n      \"],[10,\"dt\"],[12],[1,\"End Date\"],[13],[1,\"\\n      \"],[10,\"dd\"],[12],[1,\"\\n\"],[41,[33,1,[\"record\",\"endDate\"]],[[[1,\"          \"],[1,[28,[35,3],[[33,1,[\"record\",\"endDate\"]]],null]],[1,\"\\n\"]],[]],[[[1,\"          \"],[10,1],[14,0,\"text-muted\"],[12],[1,\"None\"],[13],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"mt-4\"],[12],[1,\"\\n    \"],[10,\"button\"],[15,\"onclick\",[28,[37,5],[[33,6]],null]],[14,0,\"btn btn-danger\"],[15,\"disabled\",[29,[[52,[33,6,[\"isRunning\"]],\"disable\"]]]],[12],[1,\"\\n\"],[41,[33,6,[\"isIdle\"]],[[[1,\"        \"],[1,[28,[35,7],[\"trash\"],null]],[1,\"\\n        Yes, Delete\\n\"]],[]],[[[1,\"        Deleting...\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"],[41,[33,6,[\"isIdle\"]],[[[1,\"      \"],[6,[39,8],null,[[\"class\",\"route\",\"model\"],[\"btn btn-link\",\"organization.donors.donor.pledge\",[33,1,[\"record\",\"id\"]]]],[[\"default\"],[[[[1,\"Cancel\"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"de-form-card\",\"model\",\"format-amount\",\"format-date\",\"if\",\"perform\",\"delete\",\"svg-jar\",\"link-to\"]]",
    "moduleName": "donor-elf-web/templates/organization/donors/donor/pledge-delete.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});