define("donor-elf-web/controllers/funds/fund/more/newsletter/edit-contacts", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@ember/utils", "@glimmer/tracking", "donor-elf-web/lib/array-utils", "donor-elf-web/lib/store-utils"], function (_exports, _controller, _object, _service, _utils, _tracking, _arrayUtils, _storeUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _class3, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SelectedNewsletterContact = (_class = class SelectedNewsletterContact {
    constructor(args) {
      _defineProperty(this, "contact", void 0);

      _defineProperty(this, "newsletterContact", void 0);

      _initializerDefineProperty(this, "selected", _descriptor, this);

      _initializerDefineProperty(this, "showCantSelectContactError", _descriptor2, this);

      this.contact = args.contact;
      this.newsletterContact = args.newsletterContact;
      this.selected = args.selected;
    }

    get contactIsMissingAddressFields() {
      return (0, _utils.isEmpty)(this.contact.address1) || (0, _utils.isEmpty)(this.contact.city) || (0, _utils.isEmpty)(this.contact.state) || (0, _utils.isEmpty)(this.contact.zip);
    }

    get changeNotSavedYet() {
      return this.selected && this.newsletterContact == null || !this.selected && this.newsletterContact != null;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selected", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "showCantSelectContactError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class);
  let NewsletterConnectedController = (_class3 = class NewsletterConnectedController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "contactsCache", _descriptor3, this);

      _initializerDefineProperty(this, "fundsService", _descriptor4, this);

      _initializerDefineProperty(this, "router", _descriptor5, this);

      _initializerDefineProperty(this, "store", _descriptor6, this);

      _defineProperty(this, "model", void 0);

      _initializerDefineProperty(this, "loading", _descriptor7, this);

      _initializerDefineProperty(this, "saving", _descriptor8, this);

      _initializerDefineProperty(this, "saved", _descriptor9, this);

      _initializerDefineProperty(this, "error", _descriptor10, this);

      _initializerDefineProperty(this, "newsletterContactsByContactId", _descriptor11, this);

      _initializerDefineProperty(this, "selectedNewsletterContacts", _descriptor12, this);

      _initializerDefineProperty(this, "nameFilter", _descriptor13, this);

      _initializerDefineProperty(this, "groupFilter", _descriptor14, this);
    }

    get filterApplied() {
      return this.nameFilter.length > 2 || this.groupFilter.length > 0;
    }

    get subscribedContactsCount() {
      var _this$selectedNewslet;

      return (((_this$selectedNewslet = this.selectedNewsletterContacts) === null || _this$selectedNewslet === void 0 ? void 0 : _this$selectedNewslet.filter(x => x.selected)) || []).length;
    }

    get filteredSelectedNewsletterContacts() {
      let filteredItems = this.selectedNewsletterContacts || [];

      if (this.filterApplied) {
        if (this.nameFilter.length > 2) {
          let filteredContactIds = {};
          this.contactsCache.search(this.nameFilter).forEach(x => {
            filteredContactIds[x.id] = true;
          });
          filteredItems = filteredItems.filter(x => {
            return filteredContactIds[x.contact.id] || false;
          });
        }

        if (this.groupFilter.length > 0) {
          let groupContactIds = {};
          this.contactsCache.groupContacts[this.groupFilter].contacts.forEach(x => {
            groupContactIds[x.id] = true;
          });
          filteredItems = filteredItems.filter(x => groupContactIds[x.contact.id]);
        }
      }

      return filteredItems;
    }

    get allFilteredSelectedNewsletterContactsSelected() {
      return this.filteredSelectedNewsletterContacts.filter(x => !x.contactIsMissingAddressFields).every(x => x.selected);
    }

    get availableGroupOptions() {
      return [{
        val: '',
        label: 'Filter by Group'
      }].concat(this.contactsCache.sortedGroups.map(x => {
        return {
          val: x,
          label: x
        };
      }));
    }

    templateDidInsert() {
      this.loadNewsletterContacts();
    }

    templateWillDestroy() {
      this.loading = false;
      this.saving = false;
      this.saved = false;
      this.error = undefined;
      this.newsletterContactsByContactId = {};
      this.selectedNewsletterContacts = undefined;
      this.nameFilter = '';
      this.groupFilter = '';
    }

    async loadNewsletterContacts() {
      this.loading = true;
      this.newsletterContactsByContactId = {};
      this.selectedNewsletterContacts = undefined;
      (await (0, _storeUtils.queryWithPages)({
        store: this.store,
        modelName: 'newsletter-contact',
        queryOptions: {
          filter: {
            newsletter_account_id: this.model.newsletterAccount.id
          }
        },
        pageSize: 500
      })).forEach(x => this.newsletterContactsByContactId[x.contactId] = x);
      this.selectedNewsletterContacts = this.contactsCache.sortedContacts.map(contact => {
        let newsletterContact = this.newsletterContactsByContactId[contact.id];
        return new SelectedNewsletterContact({
          contact,
          newsletterContact,
          selected: newsletterContact != null
        });
      });
      this.sortTheSelectedNewsletterContacts();
      this.loading = false;
    }

    handleSelectButtonClicked() {
      let checkedValue = this.allFilteredSelectedNewsletterContactsSelected ? false : true;
      this.filteredSelectedNewsletterContacts.filter(x => !x.contactIsMissingAddressFields).forEach(item => {
        item.selected = checkedValue;
      });
    }

    handleNameFilterInput(e) {
      this.nameFilter = e.target.value;

      if (!this.filterApplied) {
        this.sortTheSelectedNewsletterContacts();
      }
    }

    handleGroupChange(e) {
      this.groupFilter = e.target.value;

      if (!this.filterApplied) {
        this.sortTheSelectedNewsletterContacts();
      }
    }

    handleGroupClicked(group) {
      this.nameFilter = '';
      this.groupFilter = group;
    }

    toggleSelected(item) {
      if (!item.selected && item.contactIsMissingAddressFields) {
        // prevent the user from selecting a contact with missing address fields and show an error
        item.showCantSelectContactError = !item.showCantSelectContactError;
      } else {
        item.selected = !item.selected;
      }
    }

    sortTheSelectedNewsletterContacts() {
      this.selectedNewsletterContacts = (0, _arrayUtils.sortByProperties)(this.selectedNewsletterContacts, ['selected:desc', 'name:asc']);
    }

    async saveChanges() {
      if (this.saving) {
        return;
      }

      this.saving = true;
      let saveStartedAt = new Date();

      try {
        let record = this.store.createRecord('newsletter-contact-bulk-update', {
          newsletterAccountId: this.model.newsletterAccount.id,
          contactIds: this.selectedNewsletterContacts.filter(x => x.selected).map(x => x.contact.id)
        });
        await record.save();
        await this.loadNewsletterContacts();
        let saveTime = new Date() - saveStartedAt;

        if (saveTime < 1500) {
          await new Promise(resolve => setTimeout(resolve, 1500));
        }

        this.saving = false;
        this.saved = true;
      } catch (e) {
        this.error = e;
      }
    }

  }, (_descriptor3 = _applyDecoratedDescriptor(_class3.prototype, "contactsCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class3.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class3.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class3.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class3.prototype, "saving", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class3.prototype, "saved", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class3.prototype, "error", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class3.prototype, "newsletterContactsByContactId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class3.prototype, "selectedNewsletterContacts", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class3.prototype, "nameFilter", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class3.prototype, "groupFilter", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class3.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "templateDidInsert"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "templateWillDestroy"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "loadNewsletterContacts", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "loadNewsletterContacts"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "handleSelectButtonClicked", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "handleSelectButtonClicked"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "handleNameFilterInput", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "handleNameFilterInput"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "handleGroupChange", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "handleGroupChange"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "handleGroupClicked", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "handleGroupClicked"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "toggleSelected", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "toggleSelected"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "saveChanges", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "saveChanges"), _class3.prototype)), _class3);
  _exports.default = NewsletterConnectedController;
});