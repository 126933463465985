define("donor-elf-web/templates/not-found", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ouVo13HM",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,2],[14,0,\"mb-4\"],[12],[1,\"\\n    Whoops! We can't find the page you were trying to go to. You can click the\\n    button below to go to the dashboard page.\\n  \"],[13],[1,\"\\n  \"],[6,[39,1],null,[[\"class\",\"route\",\"model\"],[\"btn btn-warning mb-4\",\"funds.fund\",\"selected-fund\"]],[[\"default\"],[[[[1,\"Go to the Dashboard\"]],[]]]]],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    If this continues to happen, please email us at \"],[10,3],[14,6,\"mailto:support@donorelf.com\"],[12],[1,\"support@donorelf.com\"],[13],[1,\" so we can fix\\n    it.\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"de-form-card\",\"link-to\"]]",
    "moduleName": "donor-elf-web/templates/not-found.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});