define("donor-elf-web/components/contact/bulk-actions/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _object, _component2, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
      <Modal>
      {{#if this.noContacts}}
        <Contact::BulkActions::NoContactsError
          @onClose={{@onClose}}
        />
      {{else if this.askFilteredOrSelectedContacts}}
        <Contact::BulkActions::AskFilteredOrSelectedContacts
          @filteredContactIds={{@filteredContactIds}}
          @selectedContactIds={{@selectedContactIds}}
          @onAnswer={{this.handleAnsweredFilteredOrSelectedContacts}}
          @onClose={{@onClose}}
        />
      {{else if (eq 'set_custom_property' @bulkActionType)}}
        <Contact::BulkActions::EditProperty
          @contactIds={{this.contactIds}}
          @onContactsChanged={{@onContactsChanged}}
          @onClose={{@onClose}}
        />
      {{else if (eq 'add_to_journal' @bulkActionType)}}
        <Contact::BulkActions::AddToJournal
          @contactIds={{this.contactIds}}
          @onContactsChanged={{@onContactsChanged}}
          @onClose={{@onClose}}
        />
      {{else if (eq 'delete_contacts' @bulkActionType)}}
        <Contact::BulkActions::DeleteContacts
          @contactIds={{this.contactIds}}
          @onContactsChanged={{@onContactsChanged}}
          @onClose={{@onClose}}
        />
      {{/if}}
    </Modal>
  
  */
  {
    "id": "BKMyP+ty",
    "block": "[[[1,\"  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"noContacts\"]],[[[1,\"      \"],[8,[39,2],null,[[\"@onClose\"],[[30,1]]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"askFilteredOrSelectedContacts\"]],[[[1,\"      \"],[8,[39,3],null,[[\"@filteredContactIds\",\"@selectedContactIds\",\"@onAnswer\",\"@onClose\"],[[30,2],[30,3],[30,0,[\"handleAnsweredFilteredOrSelectedContacts\"]],[30,1]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,4],[\"set_custom_property\",[30,4]],null],[[[1,\"      \"],[8,[39,5],null,[[\"@contactIds\",\"@onContactsChanged\",\"@onClose\"],[[30,0,[\"contactIds\"]],[30,5],[30,1]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,4],[\"add_to_journal\",[30,4]],null],[[[1,\"      \"],[8,[39,6],null,[[\"@contactIds\",\"@onContactsChanged\",\"@onClose\"],[[30,0,[\"contactIds\"]],[30,5],[30,1]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,4],[\"delete_contacts\",[30,4]],null],[[[1,\"      \"],[8,[39,7],null,[[\"@contactIds\",\"@onContactsChanged\",\"@onClose\"],[[30,0,[\"contactIds\"]],[30,5],[30,1]]],null],[1,\"\\n    \"]],[]],null]],[]]]],[]]]],[]]]],[]]],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[\"@onClose\",\"@filteredContactIds\",\"@selectedContactIds\",\"@bulkActionType\",\"@onContactsChanged\"],false,[\"modal\",\"if\",\"contact/bulk-actions/no-contacts-error\",\"contact/bulk-actions/ask-filtered-or-selected-contacts\",\"eq\",\"contact/bulk-actions/edit-property\",\"contact/bulk-actions/add-to-journal\",\"contact/bulk-actions/delete-contacts\"]]",
    "moduleName": "donor-elf-web/components/contact/bulk-actions/index.hbs",
    "isStrictMode": false
  });

  let ContactBulkActionsIndexComponent = (_class = class ContactBulkActionsIndexComponent extends _component2.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "askFilteredOrSelectedContacts", _descriptor, this);

      _defineProperty(this, "contactIds", void 0);

      if (args.selectedContactIds.length > 0 && args.selectedContactIds.sort().join(',') !== args.filteredContactIds.sort().join(',')) {
        this.askFilteredOrSelectedContacts = true;
      } else {
        this.askFilteredOrSelectedContacts = false;
        this.contactIds = args.filteredContactIds;

        if (args.bulkActionType === 'add_task' && !this.noContacts) {
          args.onAddTask(this.contactIds);
          args.onClose();
        }
      }
    }

    get noContacts() {
      return this.args.selectedContactIds.length === 0 && this.args.filteredContactIds.length === 0;
    }

    handleAnsweredFilteredOrSelectedContacts(answer) {
      if (answer === 'filtered') {
        this.contactIds = this.args.filteredContactIds;
      } else {
        this.contactIds = this.args.selectedContactIds;
      }

      this.askFilteredOrSelectedContacts = false;

      if (this.args.bulkActionType === 'add_task') {
        this.args.onAddTask(this.contactIds);
        this.args.onClose();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "askFilteredOrSelectedContacts", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleAnsweredFilteredOrSelectedContacts", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleAnsweredFilteredOrSelectedContacts"), _class.prototype)), _class);
  _exports.default = ContactBulkActionsIndexComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ContactBulkActionsIndexComponent);
});