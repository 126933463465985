define("donor-elf-web/routes/admin/users", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    abilities: (0, _service.inject)(),

    beforeModel(transition) {
      if (this.abilities.noAbility('admin_view_users_and_funds')) {
        transition.abort();
      }
    }

  });

  _exports.default = _default;
});