define("donor-elf-web/routes/funds/fund/contacts/group-by/property", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ContactByPropertyPropertyRoute = (_class = class ContactByPropertyPropertyRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "contactsCache", _descriptor, this);

      _initializerDefineProperty(this, "navbarSettings", _descriptor2, this);
    }

    model(params) {
      if (params.label === 'groups') {
        params.label = 'Groups';
      }

      let model = {
        propertyLabel: params.label,
        filterPropertyName: `contact_custom_${params.label}`,
        filterOperation: 'any',
        contactsColumnPropertyName: params.label,
        valueInfos: []
      };
      this.navbarSettings.title = `Group by ${model.propertyLabel}`;

      if (model.propertyLabel === 'Groups') {
        model.filterPropertyName = 'contactGroups';
        model.contactsColumnPropertyName = 'groups';

        for (let group of this.contactsCache.sortedGroups) {
          model.valueInfos.push({
            val: group,
            filterVal: group,
            count: this.contactsCache.contacts.filter(x => {
              var _x$groups;

              return (_x$groups = x.groups) === null || _x$groups === void 0 ? void 0 : _x$groups.includes(group);
            }).length
          });
        }
      } else {
        let customProperty = this.contactsCache.customPropertiesByLowecaseLabel[model.propertyLabel.toLowerCase()];

        if (customProperty.t === 'l') {
          for (let option of customProperty.o) {
            model.valueInfos.push({
              val: option,
              filterVal: option,
              count: this.contactsCache.contacts.filter(x => {
                var _x$customPropertiesBy;

                return ((_x$customPropertiesBy = x.customPropertiesByLabel[model.propertyLabel]) === null || _x$customPropertiesBy === void 0 ? void 0 : _x$customPropertiesBy.v[option]) === true;
              }).length
            });
          }
        } else if (customProperty.t === 'b') {
          model.filterOperation = '=';
          model.valueInfos = [{
            val: 'Yes',
            filterVal: true,
            count: this.contactsCache.contacts.filter(x => {
              var _x$customPropertiesBy2;

              return ((_x$customPropertiesBy2 = x.customPropertiesByLabel[model.propertyLabel]) === null || _x$customPropertiesBy2 === void 0 ? void 0 : _x$customPropertiesBy2.v) === true;
            }).length
          }, {
            val: 'No',
            filterVal: false,
            count: this.contactsCache.contacts.filter(x => {
              var _x$customPropertiesBy3;

              return ((_x$customPropertiesBy3 = x.customPropertiesByLabel[model.propertyLabel]) === null || _x$customPropertiesBy3 === void 0 ? void 0 : _x$customPropertiesBy3.v) === false;
            }).length
          }];
        }
      }

      return model;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "contactsCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "navbarSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = ContactByPropertyPropertyRoute;
});