define("donor-elf-web/modifiers/scroll-into-view", ["exports", "ember-modifier", "@ember/runloop"], function (_exports, _emberModifier, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Scrolls an element into view at the bottom of the page
  // If the element is already in the view it does nothing
  //
  // We're using the burger-menu addon that adds the .bm-content div, so we have
  // to scroll that div instead of the whole window
  //
  // named args
  // - adjustment
  var _default = (0, _emberModifier.modifier)((element, params, {
    adjustment = 0
  }) => {
    if (document.body.scrollHeight < element.offsetTop + adjustment) {
      (0, _runloop.next)(() => {
        document.querySelector('.bm-content').scrollTop = element.offsetTop + adjustment - document.body.scrollHeight;
      });
    }
  });

  _exports.default = _default;
});