define("donor-elf-web/templates/funds/fund/more/karani-import/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "4RKRxeuc",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[10,\"h4\"],[12],[1,\"Select the File\"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Click the button below and select the Karani export file\\n  \"],[13],[1,\"\\n\\n  \"],[1,[28,[35,1],null,[[\"onFileSelected\"],[[28,[37,2],[[30,0],\"handleFileSelected\"],null]]]]],[1,\"\\n\"],[41,[33,4],[[[1,\"    \"],[10,0],[14,0,\"alert alert-danger mt-3\"],[12],[1,\"\\n      Whoops. It looks like you didn't select the right file. The file should\\n      end in '.csv'\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,\"h4\"],[14,0,\"mt-5\"],[12],[1,\"Import Options\"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    All the contacts and groups from Karani will be imported into DonorElf.\\n  \"],[13],[1,\"\\n  \"],[1,[28,[35,5],null,[[\"changeset\",\"property\",\"label\",\"booleanField\",\"class\"],[[33,6],\"includePledges\",\"Import Pledges\",true,\"mb-4\"]]]],[1,\"\\n\\n  \"],[1,[28,[35,5],null,[[\"changeset\",\"property\",\"label\",\"booleanField\"],[[33,6],\"includeTasks\",\"Import Tasks\",true]]]],[1,\"\\n\\n  \"],[10,\"h4\"],[14,0,\"mt-5\"],[12],[1,\"Start the Upload\"],[13],[1,\"\\n  \"],[10,0],[14,0,\"mt-3\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-warning\"],[4,[38,2],[[30,0],\"startUpload\"],null],[12],[1,\"\\n      Start Upload\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"alert alert-info mt-4 mb-0\"],[12],[1,\"\\n    Warning: If you import from Karani more than once then you will have\\n    duplicate contacts that will need to be merged.\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"form-card\",\"form-file\",\"action\",\"if\",\"invalidFileExtension\",\"form-group-select\",\"model\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/more/karani-import/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});