define("donor-elf-web/helpers/input-is-checked", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // passes boolean value of the checked state for an input element
  //
  // Usage: {{on 'change' (input-is-checked (fn this.handleCheckedChanged))}}
  //
  // Before:
  // @action
  // handleCheckedChanged(event) { let checked = event.target.checked }
  // After:
  // @action
  // handleCheckedChanged(checkedVal) { let checked = checkedVal }
  var _default = (0, _helper.helper)(function ([handler]) {
    return function (e) {
      handler(e.target.checked);
    };
  });

  _exports.default = _default;
});