define("donor-elf-web/validations/admin/edit-fund-mapping", ["exports", "ember-changeset-validations/validators", "donor-elf-web/lib/object-utils", "@ember/utils"], function (_exports, _validators, _objectUtils, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.EditFundMappingHostIdentityValidation = _exports.EditFundMappingValidation = void 0;
  const EditFundMappingValidation = {
    fundName: (0, _validators.validatePresence)(true),
    fundActive: (0, _validators.validateInclusion)({
      list: [true, false]
    })
  };
  _exports.EditFundMappingValidation = EditFundMappingValidation;
  const EditFundMappingHostIdentityValidation = {
    initialize(args) {
      return {
        database(_key, newValue)
        /*, oldValue, changes, content*/
        {
          if ((0, _utils.isEmpty)(newValue)) {
            if (args.fundMappingsService.availableDatabaseOptions.filter(x => (0, _utils.isEmpty)(x.val)).length === 0) {
              return "Can't be blank";
            }
          }

          return true;
        },

        hostId(_key, newValue, _oldValue, changes, content) {
          if ((newValue || '').length === 0) {
            return 'Mapping must be present';
          }

          if (newValue.startsWith(' ')) {
            return "Mapping can't start with spaces";
          }

          if (newValue.endsWith(' ')) {
            return "Mapping can't end with spaces";
          }

          let fundHostIdentities = args.editFundMappingChangeset.get('hostIdentities');

          if (fundHostIdentities) {
            var _args$editFundMapping, _args$editFundMapping2;

            let database = (0, _objectUtils.assignSingleProperty)(content, changes, 'database');

            if (((_args$editFundMapping = args.editFundMappingChangeset.get('hostIdentities')) === null || _args$editFundMapping === void 0 ? void 0 : (_args$editFundMapping2 = _args$editFundMapping.filter(x => x.database === database && x.hostId === newValue)) === null || _args$editFundMapping2 === void 0 ? void 0 : _args$editFundMapping2.length) > 1) {
              return "Another row has the same mapping";
            }

            let thisFundId = args.editFundMappingChangeset.get('fundId');

            if (args.fundMappingsService.hostIdentities.filter(x => x.database === database && x.hostId === newValue && x.identifiableId !== thisFundId).length > 0) {
              return "Another fund already has this mapping";
            }
          }

          return true;
        }

      };
    }

  };
  _exports.EditFundMappingHostIdentityValidation = EditFundMappingHostIdentityValidation;
});