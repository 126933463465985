define("donor-elf-web/controllers/admin/address-changes/index", ["exports", "@ember/controller", "@ember/object", "@ember/service", "file-saver"], function (_exports, _controller, _object, _service, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Papa
  } = window;

  var _default = _controller.default.extend({
    formatDateService: (0, _service.inject)(),
    actionMenuItems: (0, _object.computed)(function () {
      return [{
        label: 'Download',
        svgJar: 'download',
        onClick: () => {
          this.send('download');
        }
      }];
    }),
    actions: {
      download() {
        let rows = [['Donor Name', 'Donor Id', 'User', 'Changed On', 'Old Address 1', 'New Address 1', 'Old Address 2', 'New Address 2', 'Old City', 'New City', 'Old State', 'New State', 'Old Zip Code', 'New Zip Code']];
        this.model.forEach(item => {
          rows.push([item.entityName, item.entityHostId, `${item.userName} (${item.userEmail})`, this.formatDateService.formatForFile(item.createdAt), item.oldAddress.address1, item.newAddress.address1, item.oldAddress.address2, item.newAddress.address2, item.oldAddress.city, item.newAddress.city, item.oldAddress.state, item.newAddress.state, item.oldAddress.zip, item.newAddress.zip]);
        });
        let csv = Papa.unparse(rows);
        var blob = new Blob([csv], {
          type: "text/csv;charset=utf-8"
        });

        _fileSaver.default.saveAs(blob, 'AddressChanges.csv', true);
      }

    }
  });

  _exports.default = _default;
});