define("donor-elf-web/templates/components/header-bar", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "kQ52IzYW",
    "block": "[[[11,0],[24,0,\"bg-white border rounded row-bottom-margin d-flex\"],[17,1],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,2]],null]],null],null,[[[1,\"    \"],[8,[39,2],[[24,0,\"header-bar-link\"]],[[\"@route\",\"@models\"],[[30,3,[\"route\"]],[30,3,[\"models\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,3,[\"label\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[3]],null],[13]],[\"&attrs\",\"@links\",\"link\"],false,[\"each\",\"-track-array\",\"link-to\"]]",
    "moduleName": "donor-elf-web/templates/components/header-bar.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});