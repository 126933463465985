define("donor-elf-web/components/donut-chart", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    ...attributes
    {{did-insert this.renderChart}}
    {{did-update this.renderChart @percent @secondaryPercent}}
  >
  </div>
  */
  {
    "id": "HFVa861D",
    "block": "[[[11,0],[17,1],[4,[38,0],[[30,0,[\"renderChart\"]]],null],[4,[38,1],[[30,0,[\"renderChart\"]],[30,2],[30,3]],null],[12],[1,\"\\n\"],[13]],[\"&attrs\",\"@percent\",\"@secondaryPercent\"],false,[\"did-insert\",\"did-update\"]]",
    "moduleName": "donor-elf-web/components/donut-chart.hbs",
    "isStrictMode": false
  });

  const {
    Highcharts
  } = window;
  /*
    attrs
    - percent
    - secondaryPercent
      - optional
      - will be lighter color than regular percent unless secondaryColor is filled out
    - color - 'blue' or 'orange'
    - secondaryColor - 'blue' or 'orange'
    - innerSize - include % like '55%'
    - size - in pixels
  */

  let DonutChartComponent = (_class = class DonutChartComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "fontFamily", '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif');
    }

    get percent() {
      return this.args.percent || 0;
    }

    get secondaryPercent() {
      return this.args.secondaryPercent || 0;
    }

    get color() {
      return this.args.color || 'blue';
    }

    get colorCode() {
      if (this.color === 'orange') {
        return '#f0ad4e';
      } else {
        return '#0b68a1';
      }
    }

    get secondaryColorCode() {
      if (this.args.secondaryColor) {
        if (this.args.secondaryColor === 'orange') {
          return '#f0ad4e';
        } else {
          return '#0b68a1';
        }
      } else {
        if (this.color === 'orange') {
          return '#eacba0';
        } else {
          return '#8ac7ec';
        }
      }
    }

    get size() {
      return this.args.size || 40;
    }

    get chartSettings() {
      let {
        percent,
        secondaryPercent
      } = this;

      if (percent > 100) {
        percent = 100;
      }

      if (percent + secondaryPercent > 100) {
        secondaryPercent = 100 - percent;
      }

      let fillerPercent = 100 - percent - secondaryPercent;
      return {
        chart: {
          type: 'pie',
          spacing: [0, 0, 0, 0],
          animation: false,
          height: this.size,
          width: this.size
        },
        title: {
          text: null
        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          pie: {
            shadow: false,
            center: ['50%', '50%']
          },
          series: {
            animation: false,
            states: {
              hover: {
                enabled: false
              }
            }
          }
        },
        series: [{
          size: '100%',
          innerSize: this.args.innerSize,
          dataLabels: {
            enabled: false
          },
          data: [{
            y: percent,
            color: this.colorCode
          }, {
            y: secondaryPercent,
            color: this.secondaryColorCode
          }, {
            y: fillerPercent,
            color: '#eceeef'
          }]
        }]
      };
    }

    renderChart(el) {
      Highcharts.chart(el, this.chartSettings);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "renderChart", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "renderChart"), _class.prototype)), _class);
  _exports.default = DonutChartComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DonutChartComponent);
});