define("donor-elf-web/helpers/default-form-columns", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.defaultFormColumns = defaultFormColumns;
  _exports.default = void 0;

  function defaultFormColumns()
  /*params, hash*/
  {
    return 'col-12 col-md-8 col-lg-6';
  }

  var _default = (0, _helper.helper)(defaultFormColumns);

  _exports.default = _default;
});