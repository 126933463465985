define("donor-elf-web/controllers/funds/fund/monthly-summary", ["exports", "@ember/object/computed", "@ember/controller", "@ember/object", "@ember/utils", "@ember/service", "donor-elf-web/lib/date-utils", "donor-elf-web/lib/object-utils", "ember-parachute", "ember-changeset", "ember-changeset-validations", "donor-elf-web/validators/date-or-empty", "file-saver"], function (_exports, _computed, _controller, _object, _utils, _service, _dateUtils, _objectUtils, _emberParachute, _emberChangeset, _emberChangesetValidations, _dateOrEmpty, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.queryParams = void 0;
  const {
    Papa,
    moment
  } = window;
  const queryParams = new _emberParachute.default({
    from: {
      defaultValue: '',
      refresh: true
    },
    to: {
      defaultValue: '',
      refresh: true
    }
  });
  _exports.queryParams = queryParams;
  const filterValidation = {
    from: (0, _dateOrEmpty.default)(),
    to: (0, _dateOrEmpty.default)()
  };

  var _default = _controller.default.extend(queryParams.Mixin, {
    formatDateService: (0, _service.inject)(),
    fundsService: (0, _service.inject)(),
    settings: (0, _service.inject)(),
    includeExpenses: (0, _object.computed)('months.@each.expense', function () {
      let include = true;

      if ((0, _object.get)(this, 'settings.orgSettings.incomeOnly')) {
        include = (0, _object.get)(this, 'months').filter(x => x.expense < 0).length > 0;
      }

      return include;
    }),
    includeBalance: (0, _object.computed)('settings.orgSettings.incomeOnly', 'settings.orgSettings.hideMonthlyBalance', function () {
      return !this.settings.orgSettings.incomeOnly && !this.settings.orgSettings.hideMonthlyBalance;
    }),
    months: [],
    total: {
      income: 0,
      expense: 0
    },
    from: '',
    to: '',
    filterExpanded: false,
    filterChangeset: null,
    loading: false,
    queryParamsChanged: (0, _computed.or)('queryParamsState.{from,to}.changed'),

    setup({
      queryParams
    }) {
      this.fetchData(queryParams);
    },

    queryParamsDidChange({
      shouldRefresh,
      queryParams
    }) {
      // if any query param with `refresh: true` is changed, `shouldRefresh` is `true`
      if (shouldRefresh) {
        this.fetchData(queryParams);
      }
    },

    reset(event, isExiting) {
      if (isExiting) {
        this.resetQueryParams();
        (0, _object.setProperties)(this, {
          filterChangeset: null,
          filterExpanded: false
        });
      }
    },

    fetchData(queryParams) {
      (0, _object.set)(this, 'loading', true);
      let filter = {
        from: queryParams.from,
        to: queryParams.to,
        includeBalance: true
      };

      if ((0, _utils.isEmpty)(filter.from)) {
        filter.from = moment().subtract(11, 'months').format('YYYY-MM-DD');
      }

      if ((0, _utils.isEmpty)(filter.to)) {
        filter.to = moment().format('YYYY-MM-DD');
      }

      filter.from = moment(filter.from).startOf('month').format('YYYY-MM-DD');
      filter.to = moment(filter.to).endOf('month').format('YYYY-MM-DD');
      let filterRecord = {
        from: _dateUtils.default.deserializeDateOnly(filter.from),
        to: _dateUtils.default.deserializeDateOnly(filter.to)
      };
      let filterChangeset = new _emberChangeset.default(filterRecord, (0, _emberChangesetValidations.default)(filterValidation), filterValidation);
      (0, _object.set)(this, 'filterChangeset', filterChangeset);
      return this.fundsService.confirmValidFundToken().then(() => {
        return this.store.query('monthly-summary', {
          filter: _objectUtils.default.decamelizeKeys(filter)
        }).then(records => {
          let byMonth = {};
          let balanceByMonth = {};
          let total = {
            income: 0,
            expense: 0
          };
          records.forEach(record => {
            let {
              year,
              month,
              amount,
              kind
            } = record;

            if (kind === 'balance') {
              balanceByMonth[`${year}-${month}`] = amount;
            } else {
              byMonth[`${year}-${month}-${kind}`] = amount;
            }
          });
          let months = [];
          let date = moment(filter.from);
          let to = moment(filter.to);

          while (date < to) {
            let dateKey = date.format('YYYY-M');
            let income = (0, _object.get)(byMonth, `${dateKey}-income`) || 0;
            let expense = (0, _object.get)(byMonth, `${dateKey}-expense`) || 0;
            let balance = (0, _object.get)(balanceByMonth, dateKey) || 0;
            let diff = income + expense;
            months.push({
              label: date.format('MMM YYYY'),
              from: date.startOf('month').format('YYYY-MM-DD'),
              to: date.endOf('month').format('YYYY-MM-DD'),
              income: income,
              expense: expense,
              balance: balance,
              diff: diff
            });
            total.income += income;
            total.expense += expense;
            date.add(1, 'month').startOf('month');
          }

          (0, _object.setProperties)(this, {
            months,
            total,
            loading: false
          });
        });
      });
    },

    sortMonthsBy: ['from:desc'],
    sortedMonths: (0, _computed.sort)('months', 'sortMonthsBy'),
    filterMessage: (0, _object.computed)('filterChangeset.{from,to}', function () {
      let messages = [];
      let {
        formatDateService,
        filterChangeset
      } = (0, _object.getProperties)(this, 'formatDateService', 'filterChangeset');
      let {
        from,
        to
      } = (0, _object.getProperties)(filterChangeset, 'from', 'to');
      messages.push(`Between ${formatDateService.format(from)} and ${formatDateService.format(to)}`);
      return messages.join(', ');
    }),
    actionsMenuItems: (0, _object.computed)('filterExpanded', function () {
      return [{
        label: (0, _object.get)(this, 'filterExpanded') ? 'Collapse Filter' : 'Adjust Filter',
        svgJar: 'filter',
        onClick: () => {
          this.send('toggleExpanded');
        }
      }, {
        label: 'Download',
        svgJar: 'download',
        onClick: () => {
          this.send('download');
        }
      }];
    }),
    actions: {
      applyFilter() {
        let filterChangeset = (0, _object.get)(this, 'filterChangeset');

        if ((0, _object.get)(filterChangeset, 'isPristine')) {
          alert('No filter values have changed');
          return;
        }

        (0, _object.setProperties)(this, {
          from: _dateUtils.default.serializeToDateOnly((0, _object.get)(filterChangeset, 'from'), {
            invalidValue: ''
          }),
          to: _dateUtils.default.serializeToDateOnly((0, _object.get)(filterChangeset, 'to'), {
            invalidValue: ''
          })
        });
      },

      toggleExpanded() {
        this.toggleProperty('filterExpanded');
      },

      toggleSortDirection() {
        if ((0, _object.get)(this, 'sortMonthsBy')[0] === 'from:desc') {
          (0, _object.set)(this, 'sortMonthsBy', ['from:asc']);
        } else {
          (0, _object.set)(this, 'sortMonthsBy', ['from:desc']);
        }
      },

      handleTransitionTo(name, options) {
        this.transitionToRoute(name, options);
      },

      download() {
        let rows = [];
        let {
          includeExpenses,
          includeBalance
        } = (0, _object.getProperties)(this, 'includeExpenses', 'includeBalance');
        let headerRow = ['Month', 'Income'];

        if (includeExpenses) {
          headerRow.push('Expense');
        }

        if (includeBalance) {
          headerRow.push('Balance');
        }

        rows.push(headerRow);
        (0, _object.get)(this, 'sortedMonths').forEach(item => {
          let row = [item.label, item.income];

          if (includeExpenses) {
            row.push(item.expense);
          }

          if (includeBalance) {
            row.push(item.balance);
          }

          rows.push(row);
        });
        let csv = Papa.unparse(rows);
        var blob = new Blob([csv], {
          type: "text/csv;charset=utf-8"
        });

        _fileSaver.default.saveAs(blob, 'monthly-summary.csv', true);
      }

    }
  });

  _exports.default = _default;
});