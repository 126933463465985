define("donor-elf-web/controllers/funds/fund/more/mailchimp-sync/connected", ["exports", "@ember/controller", "@ember/service", "@ember/object", "@glimmer/tracking"], function (_exports, _controller, _service, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MailchimpConnectedController = (_class = class MailchimpConnectedController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "currentUser", _descriptor, this);

      _initializerDefineProperty(this, "fundsService", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _defineProperty(this, "model", void 0);

      _initializerDefineProperty(this, "editFundSyncInfo", _descriptor4, this);
    }

    get noFundsAreSynced() {
      return this.model.mailchimpFundSyncInfos.length === 0;
    }

    addSync() {
      this.editSync();
    }

    editSync(fundSyncInfo) {
      if (fundSyncInfo) {
        this.editFundSyncInfo = fundSyncInfo;
      } else {
        let mailchimpFundSync = this.store.createRecord('mailchimp-fund-sync');
        let fund = this.fundsService.selectedFund;
        mailchimpFundSync.fundId = fund.id;
        let mailchimpAccount = undefined;
        let mailchimpAudience = undefined;

        if (this.model.mailchimpAccounts.length === 1) {
          mailchimpAccount = this.model.mailchimpAccounts[0];
          mailchimpFundSync.mailchimpAccountId = mailchimpAccount.id;

          if (this.model.mailchimpAccounts[0].audiences.length === 1) {
            mailchimpAudience = this.model.mailchimpAccounts[0].audiences[0];
            mailchimpFundSync.audienceId = mailchimpAudience.id;
          }
        }

        this.editFundSyncInfo = {
          mailchimpFundSync,
          fund,
          mailchimpAudience,
          mailchimpAccount
        };
      }
    }

    handleSyncSaved(fundSyncInfo) {
      if (!this.model.mailchimpFundSyncInfos.any(x => x.mailchimpFundSync.id === fundSyncInfo.mailchimpFundSync.id)) {
        this.model.mailchimpFundSyncInfos = [...this.model.mailchimpFundSyncInfos, fundSyncInfo];
      }

      this.editFundSyncInfo = undefined;
    }

    handleSyncDeleted(fundSyncInfo) {
      this.model.mailchimpFundSyncInfos = this.model.mailchimpFundSyncInfos.filter(x => x.mailchimpFundSync.id !== fundSyncInfo.mailchimpFundSync.id);
    }

    handleCancelEditSync() {
      this.editFundSyncInfo = undefined;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "editFundSyncInfo", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "addSync", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addSync"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editSync", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "editSync"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleSyncSaved", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleSyncSaved"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleSyncDeleted", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleSyncDeleted"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleCancelEditSync", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleCancelEditSync"), _class.prototype)), _class);
  _exports.default = MailchimpConnectedController;
});