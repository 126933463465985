define("donor-elf-web/models/organization/pledge", ["exports", "@ember/object", "donor-elf-web/lib/pledge-utils", "ember-data"], function (_exports, _object, _pledgeUtils, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    entityId: attr('string'),
    fundId: attr('string'),
    fundName: attr('string'),
    frequency: attr('number'),
    frequencyLabel: (0, _object.computed)('frequency', function () {
      return _pledgeUtils.pledgeFrequencyLabels[this.frequency];
    }),
    startDate: attr('date-only'),
    endDate: attr('date-only'),
    amount: attr('number'),
    notes: attr('string')
  });

  _exports.default = _default;
});