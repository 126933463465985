define("donor-elf-web/templates/funds/fund/pledges/delete-error", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "BOn5PR3n",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[1,[28,[35,1],null,[[\"errorModel\",\"modelLabel\"],[[33,2],\"pledge\"]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"de-form-card\",\"handle-404-error\",\"model\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/pledges/delete-error.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});