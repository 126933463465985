define("donor-elf-web/components/top-navbar/all-funds", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='flex-grow-1 d-flex'>
    <div class='nav-item'>
      <LinkTo
        @route='funds.fund'
        @model='selected-fund'
        class='btn btn-outline-brand'
      >
        Dashboard
      </LinkTo>
    </div>
  </div>
  */
  {
    "id": "r/reF8hq",
    "block": "[[[10,0],[14,0,\"flex-grow-1 d-flex\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"nav-item\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,0,\"btn btn-outline-brand\"]],[[\"@route\",\"@model\"],[\"funds.fund\",\"selected-fund\"]],[[\"default\"],[[[[1,\"\\n      Dashboard\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"link-to\"]]",
    "moduleName": "donor-elf-web/components/top-navbar/all-funds.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});