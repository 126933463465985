define("donor-elf-web/components/contact/bulk-actions/edit-property/existing/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "ember-cached-decorator-polyfill"], function (_exports, _component, _templateFactory, _component2, _service, _emberCachedDecoratorPolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isReadonlyProperty}}
    <Contact::BulkActions::EditProperty::Existing::ReadonlyCantEdit
      @propertyInfo={{this.propertyInfo}}
      @contactIds={{@contactIds}}
      @onClose={{@onClose}}
    />
  {{else if this.propertyInfoTypeIsList}}
    <Contact::BulkActions::EditProperty::Existing::ListType
      @isGroupsProperty={{this.isGroupsProperty}}
      @propertyInfo={{this.propertyInfo}}
      @contactIds={{@contactIds}}
      @onContactsChanged={{@onContactsChanged}}
      @onClose={{@onClose}}
    />
  {{else}}
    <Contact::BulkActions::EditProperty::Existing::NotListType
      @propertyInfo={{this.propertyInfo}}
      @contactIds={{@contactIds}}
      @onContactsChanged={{@onContactsChanged}}
      @onClose={{@onClose}}
    />
  {{/if}}
  */
  {
    "id": "DUiAfrT2",
    "block": "[[[41,[30,0,[\"isReadonlyProperty\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@propertyInfo\",\"@contactIds\",\"@onClose\"],[[30,0,[\"propertyInfo\"]],[30,1],[30,2]]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"propertyInfoTypeIsList\"]],[[[1,\"  \"],[8,[39,2],null,[[\"@isGroupsProperty\",\"@propertyInfo\",\"@contactIds\",\"@onContactsChanged\",\"@onClose\"],[[30,0,[\"isGroupsProperty\"]],[30,0,[\"propertyInfo\"]],[30,1],[30,3],[30,2]]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,3],null,[[\"@propertyInfo\",\"@contactIds\",\"@onContactsChanged\",\"@onClose\"],[[30,0,[\"propertyInfo\"]],[30,1],[30,3],[30,2]]],null],[1,\"\\n\"]],[]]]],[]]]],[\"@contactIds\",\"@onClose\",\"@onContactsChanged\"],false,[\"if\",\"contact/bulk-actions/edit-property/existing/readonly-cant-edit\",\"contact/bulk-actions/edit-property/existing/list-type\",\"contact/bulk-actions/edit-property/existing/not-list-type\"]]",
    "moduleName": "donor-elf-web/components/contact/bulk-actions/edit-property/existing/index.hbs",
    "isStrictMode": false
  });

  let ContactBulkActionsEditPropertyExistingIndexComponent = (_class = class ContactBulkActionsEditPropertyExistingIndexComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "contactsCache", _descriptor, this);
    }

    get isReadonlyProperty() {
      var _this$propertyInfo;

      return ((_this$propertyInfo = this.propertyInfo) === null || _this$propertyInfo === void 0 ? void 0 : _this$propertyInfo.r) === true;
    }

    get isGroupsProperty() {
      return this.args.propertyLabel === 'Groups';
    }

    get propertyInfo() {
      if (this.isGroupsProperty) {
        return undefined;
      } else {
        return this.contactsCache.customPropertiesByLowecaseLabel[this.args.propertyLabel.toLowerCase()];
      }
    }

    get propertyInfoTypeIsList() {
      var _s;

      return this.isGroupsProperty || this.propertyInfo.t === 'l' && ((_s = this.propertyInfo.s) === null || _s === void 0 ? void 0 : _s.single_selection) !== true;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "contactsCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "propertyInfo", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "propertyInfo"), _class.prototype)), _class);
  _exports.default = ContactBulkActionsEditPropertyExistingIndexComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ContactBulkActionsEditPropertyExistingIndexComponent);
});