define("donor-elf-web/controllers/funds/fund/contacts/upload/map", ["exports", "@ember/controller", "@ember/object", "@ember/service"], function (_exports, _controller, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    uploadContacts: (0, _service.inject)(),
    errorMessage: null,
    displayNameIsMapped: (0, _object.computed)('uploadContacts.activeColumnMappings.[]', function () {
      let displayNameMapping = (0, _object.get)(this, 'uploadContacts.activeColumnMappings').find(mapping => {
        return (0, _object.get)(mapping, 'property') === 'name';
      });
      return displayNameMapping != null;
    }),

    reset() {
      (0, _object.set)(this, 'errorMessage', null);
    },

    actions: {
      next() {
        if (!(0, _object.get)(this, 'displayNameIsMapped')) {
          (0, _object.set)(this, 'errorMessage', "You have to map the 'Display Name' property to a column");
          return;
        }

        this.transitionToRoute('funds.fund.contacts.upload.verify');
      }

    }
  });

  _exports.default = _default;
});