define("donor-elf-web/services/upload-contacts", ["exports", "@ember/service", "@ember/utils", "@ember/object"], function (_exports, _service, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    fileData: [],
    columns: [],
    columnMappings: [],
    contactObjects: [],
    activeColumnMappings: (0, _object.computed)('columnMappings.@each.property', function () {
      return (0, _object.get)(this, 'columnMappings').filter(mapping => {
        return !(0, _utils.isEmpty)((0, _object.get)(mapping, 'property'));
      });
    }),
    propertyOptions: (0, _object.computed)(function () {
      // defined at the bottom of this file
      return propertyOptions;
    }),
    activePropertyOptions: (0, _object.computed)('activeColumnMappings.[]', function () {
      let propertyNames = {};
      (0, _object.get)(this, 'activeColumnMappings').forEach(mapping => {
        propertyNames[(0, _object.get)(mapping, 'property')] = true;
      });
      return (0, _object.get)(this, 'propertyOptions').filter(item => {
        return propertyNames[(0, _object.get)(item, 'val')];
      });
    }),
    importGroupName: (0, _object.computed)('columnMappings', function () {
      return `CSV Import ${moment().format('YYYY-MM-DD')}`;
    }),

    reset() {
      (0, _object.setProperties)(this, {
        fileData: [],
        columns: [],
        columnMappings: [],
        contactObjects: []
      });
    },

    populateColumnMappings() {
      let mappings = [];
      (0, _object.get)(this, 'columns').forEach(column => {
        mappings.push({
          column: column,
          property: '',
          exampleValues: this.getExampleValues(column)
        });
      });
      (0, _object.set)(this, 'columnMappings', mappings);
    },

    getExampleValues(columnName) {
      let examples = [];
      let fileData = (0, _object.get)(this, 'fileData');

      for (let i = 0; i < fileData.length; i++) {
        let val = fileData[i][columnName];

        if (!(0, _utils.isEmpty)(val)) {
          examples.push(val);
        }

        if (examples.length > 4) {
          i = fileData.length;
        }
      }

      return examples;
    },

    createContactObjects() {
      let contacts = [];
      let nameMapping = (0, _object.get)(this, 'activeColumnMappings').find(item => {
        return (0, _object.get)(item, 'property') === 'name';
      });
      let activeMappings = (0, _object.get)(this, 'activeColumnMappings').filter(item => {
        let property = (0, _object.get)(item, 'property');
        return !(0, _utils.isEmpty)(property) && property !== 'name';
      });
      let importGroupName = this.importGroupName;
      (0, _object.get)(this, 'fileData').forEach(row => {
        let name = ((0, _object.get)(row, (0, _object.get)(nameMapping, 'column')) || '').trim();

        if (!(0, _utils.isEmpty)(name)) {
          var _contact$zip;

          let contact = {
            name: name,
            active: true,
            phones: {
              data: []
            },
            emails: {
              data: []
            },
            groups: [importGroupName],
            applyMailchimpAutomaticSyncSettings: true
          };
          activeMappings.forEach(mapping => {
            let val = ((0, _object.get)(row, (0, _object.get)(mapping, 'column')) || '').trim();

            if (!(0, _utils.isEmpty)(val)) {
              if ((0, _object.get)(mapping, 'property').indexOf('phone') === 0) {
                contact.phones.data.push({
                  v: val
                });
              } else if ((0, _object.get)(mapping, 'property').indexOf('email') === 0) {
                contact.emails.data.push({
                  v: val
                });
              } else {
                contact[(0, _object.get)(mapping, 'property')] = val;
              }
            }
          }); // confirm zip is not too long

          if (((_contact$zip = contact.zip) === null || _contact$zip === void 0 ? void 0 : _contact$zip.length) > 13) {
            contact.zip = contact.zip.substring(0, 13);
          }

          contacts.push(contact);
        }
      });
      (0, _object.set)(this, 'contactObjects', contacts);
    }

  });

  _exports.default = _default;
  const propertyOptions = [{
    label: '',
    val: ''
  }, {
    label: 'Display Name',
    val: 'name'
  }, {
    label: 'Mailing Name',
    val: 'mailingName'
  }, {
    label: 'Greeting',
    val: 'greeting'
  }, {
    label: 'First Name',
    val: 'firstName'
  }, {
    label: 'Last Name',
    val: 'lastName'
  }, {
    label: 'Spouse First Name',
    val: 'spouseFirstName'
  }, {
    label: 'Spouse Last Name',
    val: 'spouseLastName'
  }, {
    label: 'Address Line 1',
    val: 'address1'
  }, {
    label: 'Address Line 2',
    val: 'address2'
  }, {
    label: 'City',
    val: 'city'
  }, {
    label: 'State',
    val: 'state'
  }, {
    label: 'Zip',
    val: 'zip'
  }, {
    label: 'Country',
    val: 'country'
  }, {
    label: 'Phone',
    val: 'phone1'
  }, {
    label: 'Phone',
    val: 'phone2'
  }, {
    label: 'Email',
    val: 'email1'
  }, {
    label: 'Email',
    val: 'email2'
  }];
});