define("donor-elf-web/controllers/funds/fund/import/intervarsity-prospects/index", ["exports", "@ember/controller", "@ember/object"], function (_exports, _controller, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let IntervarsityProspectsIndexController = (_dec = (0, _object.computed)('model.selectedFile'), (_class = class IntervarsityProspectsIndexController extends _controller.default {
    get invalidFileExtension() {
      if (this.model.selectedFile) {
        return /\.xlsx$/.test(this.model.selectedFile.name) === false;
      } else {
        return false;
      }
    }

    selectFile(file) {
      (0, _object.set)(this, 'model.selectedFile', file);
    }

    startUpload() {
      if (this.model.selectedFile == null || this.invalidFileExtension) {
        alert('Please select a file before you start the upload.');
      } else {
        this.transitionToRoute('funds.fund.import.intervarsity-prospects.processing');
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "invalidFileExtension", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "invalidFileExtension"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectFile", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectFile"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startUpload", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "startUpload"), _class.prototype)), _class));
  _exports.default = IntervarsityProspectsIndexController;
});