define("donor-elf-web/templates/components/de-loading", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "42fHvoN7",
    "block": "[[[11,2],[17,1],[12],[1,\"Loading...\"],[13]],[\"&attrs\"],false,[]]",
    "moduleName": "donor-elf-web/templates/components/de-loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});