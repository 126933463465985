define("donor-elf-web/templates/funds/fund/more/notifications/delete", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "sIrLwGeL",
    "block": "[[[6,[39,0],null,[[\"deleteTask\"],[[33,1]]],[[\"default\"],[[[[1,\"  \"],[10,2],[12],[1,\"\\n    Are you sure you no longer want to receive email notifications?\\n  \"],[13],[1,\"\\n\"],[41,[33,3],[[[1,\"    \"],[1,[28,[35,4],null,[[\"error\"],[[33,3]]]]],[1,\"\\n\"]],[]],null]],[]]]]]],[],false,[\"delete-form\",\"delete\",\"if\",\"error\",\"generic-error\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/more/notifications/delete.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});