define("donor-elf-web/services/settings", ["exports", "@ember/service", "@glimmer/tracking"], function (_exports, _service, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let Settings = (_class = class Settings extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fundsService", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _defineProperty(this, "fundId", void 0);

      _initializerDefineProperty(this, "userSettings", _descriptor3, this);

      _initializerDefineProperty(this, "fundSettings", _descriptor4, this);

      _initializerDefineProperty(this, "orgSettings", _descriptor5, this);

      _initializerDefineProperty(this, "announcement", _descriptor6, this);

      _initializerDefineProperty(this, "mailchimpFundSyncSlim", _descriptor7, this);

      _initializerDefineProperty(this, "fundIsSyncedWithUserNewsletterAccount", _descriptor8, this);

      _initializerDefineProperty(this, "contactCustomPropertyUserSetting", _descriptor9, this);
    }

    get dateFormat() {
      var _this$userSettings;

      return ((_this$userSettings = this.userSettings) === null || _this$userSettings === void 0 ? void 0 : _this$userSettings.dateFormat) || 'MM/DD/YY';
    }

    get dateFormat4Year() {
      return this.dateFormat.replace('YY', 'YYYY');
    }

    get dateFormatNoYear() {
      return this.dateFormat.replace('/YY', '');
    }

    get showUserLastLogin() {
      var _this$userSettings2;

      return ((_this$userSettings2 = this.userSettings) === null || _this$userSettings2 === void 0 ? void 0 : _this$userSettings2.showUserLastLogin) || false;
    }

    get hasMailchimpFundSync() {
      return this.mailchimpFundSyncSlim != null;
    } // set fundId to -1 if you don't want the fund settings (eg in the funds/all route)


    loadSettings(fundId) {
      if (fundId === this.fundId) {
        return Promise.resolve();
      }

      return this.store.findRecord('all-setting', fundId).then(allSetting => {
        this.fundId = fundId;
        this.userSettings = allSetting.userSettings;
        this.fundSettings = allSetting.fundSettings;
        this.orgSettings = allSetting.orgSettings;
        this.announcement = allSetting.announcement;
        this.mailchimpFundSyncSlim = allSetting.mailchimpFundSyncSlim;
        this.fundIsSyncedWithUserNewsletterAccount = allSetting.fundIsSyncedWithUserNewsletterAccount;
        this.fundsService.goal = allSetting.goal;
        this.contactCustomPropertyUserSetting = allSetting.contactCustomPropertyUserSetting;
      });
    }

    unload() {
      this.userSettings = undefined;
      this.fundSettings = undefined;
      this.orgSettings = undefined;
      this.announcement = undefined;
      this.mailchimpFundSyncSlim = undefined;
      this.fundIsSyncedWithUserNewsletterAccount = undefined;
      this.fundsService.goal = undefined;
      this.contactCustomPropertyUserSetting = undefined;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "userSettings", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "fundSettings", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "orgSettings", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "announcement", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "mailchimpFundSyncSlim", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "fundIsSyncedWithUserNewsletterAccount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "contactCustomPropertyUserSetting", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = Settings;
});