define("donor-elf-web/components/contact/custom-property/add/select-existing-or-new", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes>
    <div class='d-flex justify-content-between align-items-end border-bottom'>
      <div class='tw-font-bold tw-text-lg'>
        Property
      </div>
      <div>
        <button
          {{on 'click' @onNewPropertySelected}}
          class='btn btn-xs btn-brand'
          type='button'
        >
          Add New Property
        </button>
      </div>
    </div>
    <div class='overflow-y-auto {{@contentClass}}'>
      {{#each @existingPropertyInfos as |info|}}
        <div
          {{on 'click' (fn @onExistingPropertySelected info)}}
          class='py-1 cursor-pointer border-bottom d-flex tw-hover:bg-gray-200'
          role='button'
        >
          <div class='pl-1 icon-link'>
            {{svg-jar 'edit'}}
          </div>
          <div class='tw-ml-2'>
            {{info.l}}
          </div>
        </div>
      {{/each}}
      <div
        {{on 'click' @onNewPropertySelected}}
        class='py-1 cursor-pointer border-bottom d-flex tw-hover:bg-gray-200'
        role='button'
      >
        <div class='pl-1 icon-link-brand'>
          {{svg-jar 'plus-circle'}}
        </div>
        <div class='tw-ml-2'>
          Add New Property
        </div>
      </div>
    </div>
  </div>
  
  */
  {
    "id": "hnOpwMQX",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"d-flex justify-content-between align-items-end border-bottom\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"tw-font-bold tw-text-lg\"],[12],[1,\"\\n      Property\\n    \"],[13],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-xs btn-brand\"],[24,4,\"button\"],[4,[38,0],[\"click\",[30,2]],null],[12],[1,\"\\n        Add New Property\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[\"overflow-y-auto \",[30,3]]]],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,4]],null]],null],null,[[[1,\"      \"],[11,0],[24,0,\"py-1 cursor-pointer border-bottom d-flex tw-hover:bg-gray-200\"],[24,\"role\",\"button\"],[4,[38,0],[\"click\",[28,[37,3],[[30,6],[30,5]],null]],null],[12],[1,\"\\n        \"],[10,0],[14,0,\"pl-1 icon-link\"],[12],[1,\"\\n          \"],[1,[28,[35,4],[\"edit\"],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"tw-ml-2\"],[12],[1,\"\\n          \"],[1,[30,5,[\"l\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[5]],null],[1,\"    \"],[11,0],[24,0,\"py-1 cursor-pointer border-bottom d-flex tw-hover:bg-gray-200\"],[24,\"role\",\"button\"],[4,[38,0],[\"click\",[30,2]],null],[12],[1,\"\\n      \"],[10,0],[14,0,\"pl-1 icon-link-brand\"],[12],[1,\"\\n        \"],[1,[28,[35,4],[\"plus-circle\"],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"tw-ml-2\"],[12],[1,\"\\n        Add New Property\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@onNewPropertySelected\",\"@contentClass\",\"@existingPropertyInfos\",\"info\",\"@onExistingPropertySelected\"],false,[\"on\",\"each\",\"-track-array\",\"fn\",\"svg-jar\"]]",
    "moduleName": "donor-elf-web/components/contact/custom-property/add/select-existing-or-new.hbs",
    "isStrictMode": false
  });

  class ContactCustomPropertyAddSelectExistingOrNewComponent extends _component2.default {}

  _exports.default = ContactCustomPropertyAddSelectExistingOrNewComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ContactCustomPropertyAddSelectExistingOrNewComponent);
});