define("donor-elf-web/routes/funds/fund/more/user-settings", ["exports", "@ember/routing/route", "@ember/object", "@ember/service", "ember-changeset"], function (_exports, _route, _object, _service, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    navbarSettings: (0, _service.inject)(),
    settings: (0, _service.inject)(),

    beforeModel() {
      (0, _object.set)(this, 'navbarSettings.title', 'User Settings');
    },

    model() {
      let record = (0, _object.get)(this, 'settings.userSettings');
      return {
        record,
        changeset: new _emberChangeset.default(record)
      };
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.reset();
      }
    }

  });

  _exports.default = _default;
});