define("donor-elf-web/initializers/bugsnag", ["exports", "ember", "@bugsnag/js", "donor-elf-web/config/environment"], function (_exports, _ember, _js, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: 'bugsnag',
    initialize: function ()
    /*application*/
    {
      var _ENV$bugsnag;

      if (!((_ENV$bugsnag = _environment.default.bugsnag) !== null && _ENV$bugsnag !== void 0 && _ENV$bugsnag.apiKey)) {
        return;
      }

      _js.default.start({
        apiKey: _environment.default.bugsnag.apiKey,
        appVersion: _environment.default.appVersion
      });

      const oldOnError = _ember.default.onerror;

      _ember.default.onerror = function (error) {
        _js.default.notify(error);

        oldOnError === null || oldOnError === void 0 ? void 0 : oldOnError(error);
      };
    }
  };
  _exports.default = _default;
});