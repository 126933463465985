define("donor-elf-web/components/alert/danger", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <LeftBorderCard @leftBorderColor='bg-danger' class='tw-shadow-md' ...attributes>
    {{#if (has-block 'raw')}}
      {{yield to='raw'}}
    {{else}}
      <div class='p-2 bg-danger-50 rounded-right'>
        {{yield}}
      </div>
    {{/if}}
  </LeftBorderCard>
  */
  {
    "id": "+CMR6ydr",
    "block": "[[[8,[39,0],[[24,0,\"tw-shadow-md\"],[17,1]],[[\"@leftBorderColor\"],[\"bg-danger\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"    \"],[18,2,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"p-2 bg-danger-50 rounded-right\"],[12],[1,\"\\n      \"],[18,3,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[]]]]]],[\"&attrs\",\"&raw\",\"&default\"],false,[\"left-border-card\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "donor-elf-web/components/alert/danger.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});