define("donor-elf-web/controllers/coaching/individuals/copy", ["exports", "@ember/controller", "@ember/object", "@ember/service", "ember-concurrency", "@ember/utils"], function (_exports, _controller, _object, _service, _emberConcurrency, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    store: (0, _service.inject)(),
    error: null,

    reset() {
      (0, _object.setProperties)(this, {
        error: null
      });
    },

    templateOptions: (0, _object.computed)('model.templates.@each.id', function () {
      return [''].concat((0, _object.get)(this, 'model.templates').map(x => {
        return {
          label: (0, _object.get)(x, 'name'),
          val: (0, _object.get)(x, 'id')
        };
      }).sortBy('label'));
    }),
    selectedTemplate: (0, _object.computed)('model.changeset.templateId', function () {
      let selectedTemplateId = (0, _object.get)(this, 'model.changeset.templateId');
      return (0, _object.get)(this, 'model.templates').find(x => (0, _object.get)(x, 'id') === selectedTemplateId);
    }),
    save: (0, _emberConcurrency.task)(function* () {
      let changeset = (0, _object.get)(this, 'model.changeset');
      yield changeset.validate();

      if ((0, _object.get)(changeset, 'isValid')) {
        try {
          let selectedTemplate = (0, _object.get)(this, 'selectedTemplate');
          let copiedProperties = Object.assign({
            name: (0, _object.get)(this, 'model.changeset.name'),
            fromTemplateId: (0, _object.get)(selectedTemplate, 'id')
          }, (0, _object.getProperties)(selectedTemplate, 'frequencyTimespan', 'frequencyNumber', 'staticInfo', 'sections')); // remove values from staticInfo

          ((0, _object.get)(copiedProperties, 'staticInfo') || []).forEach(info => {
            info.v = null;
          }); // remove overall goals

          (0, _object.get)(copiedProperties, 'sections').forEach(section => {
            (0, _object.get)(section, 'fields').forEach(field => {
              if (!(0, _utils.isEmpty)(field.overallGoal)) {
                delete field.overallGoal;
                delete field.intervalGoal;
              }
            });
          });
          let record = (0, _object.get)(this, 'store').createRecord('coaching/individual', copiedProperties);
          yield record.save();
          this.transitionToRoute('coaching.individuals.individual.edit', (0, _object.get)(record, 'id'));
        } catch (error) {
          (0, _object.set)(this, 'error', error);
        }
      }
    }).drop()
  });

  _exports.default = _default;
});