define("donor-elf-web/templates/funds/fund/dashboard/update-card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "tCJdCkeT",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"border-bottom mb-2\"],[12],[1,\"\\n    \"],[10,\"h4\"],[12],[1,\"Update Credit Card\"],[13],[1,\" \\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    We attempted to bill your credit card recently for your DonorElf\\n    subscription, but the charge was declined.\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    This might have happened because the card you have on file with us has\\n    expired or has been closed. But don't worry, you're not in trouble. We\\n    know these things happen.\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    You can update your credit card for DonorElf by clicking the button below.\\n  \"],[13],[1,\"\\n  \"],[10,2],[14,0,\"mb-5\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,0,\"btn btn-warning\"]],[[\"@route\"],[\"funds.fund.more.credit-card\"]],[[\"default\"],[[[[1,\"\\n      Update Credit Card\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    After you update your card then the regular dashboard page will show. And\\n    please email us at \"],[10,3],[14,6,\"mailto:support@donorelf.com\"],[12],[1,\"support@donorelf.com\"],[13],[1,\"\\n    if you think your credit card should already be up to date.\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"form-card\",\"link-to\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/dashboard/update-card.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});