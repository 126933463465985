define("donor-elf-web/components/event-info-expiring-pledge", ["exports", "@ember/component", "@ember/object", "@ember/service", "ember-concurrency"], function (_exports, _component, _object, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    attrs
    - eventModel
  */
  var _default = _component.default.extend({
    store: (0, _service.inject)(),
    eventModel: null,
    pledge: null,
    getPledge: (0, _emberConcurrency.task)(function* () {
      let pledge = yield (0, _object.get)(this, 'store').find('pledge', (0, _object.get)(this, 'eventModel.pledgeId'));
      (0, _object.set)(this, 'pledge', pledge);
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      (0, _object.get)(this, 'getPledge').perform();
    }

  });

  _exports.default = _default;
});