define("donor-elf-web/components/tabs", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <nav
    class='d-flex flex-wrap -tw-mt-1'
    ...attributes
  >
    {{#each @tabs as |tab|}}
      <div
        {{on 'click' (fn tab.onClick tab.label)}}
        role='tab'
        class='tw-py-2 tw-px-3 cursor-pointer mr-3 tw-mt-1 tw-rounded-md {{if
          tab.active
          'bg-gray-200'
          'text-muted tw-hover:text-gray-700'
        }}'
      >
        {{tab.label}}
      </div>
    {{/each}}
  </nav>
  */
  {
    "id": "96Gn/E9p",
    "block": "[[[11,\"nav\"],[24,0,\"d-flex flex-wrap -tw-mt-1\"],[17,1],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,2]],null]],null],null,[[[1,\"    \"],[11,0],[24,\"role\",\"tab\"],[16,0,[29,[\"tw-py-2 tw-px-3 cursor-pointer mr-3 tw-mt-1 tw-rounded-md \",[52,[30,3,[\"active\"]],\"bg-gray-200\",\"text-muted tw-hover:text-gray-700\"]]]],[4,[38,3],[\"click\",[28,[37,4],[[30,3,[\"onClick\"]],[30,3,[\"label\"]]],null]],null],[12],[1,\"\\n      \"],[1,[30,3,[\"label\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[3]],null],[13]],[\"&attrs\",\"@tabs\",\"tab\"],false,[\"each\",\"-track-array\",\"if\",\"on\",\"fn\"]]",
    "moduleName": "donor-elf-web/components/tabs.hbs",
    "isStrictMode": false
  });

  class TabsComponent extends _component2.default {}

  _exports.default = TabsComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TabsComponent);
});