define("donor-elf-web/components/goal/monthly-gifts-without-goal-chart", ["exports", "@ember/object/computed", "@ember/utils", "@ember/component", "@ember/object", "@ember/runloop", "@ember/service", "donor-elf-web/lib/filter-utils-new"], function (_exports, _computed, _utils, _component, _object, _runloop, _service, _filterUtilsNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Highcharts,
    moment
  } = window;
  /*
    This is the 3rd version of this chart. Yes, they all need refactored into one chart
    but that is for later
  
    attrs
    - monthlyGifts
      - array [{ month: 'YYYY-MM-DD', amount: }]
    - goalAmount (optional)
    - height (optional)
  */

  var _default = _component.default.extend({
    formatAmountService: (0, _service.inject)(),
    router: (0, _service.inject)(),
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    height: null,
    monthlyGifts: null,
    monthlyGiftsChartCategories: (0, _computed.map)('monthlyGifts', function (item) {
      return moment(item.month).format('MMM YY');
    }),
    monthlyGiftsChartGiftsSeries: (0, _object.computed)('monthlyGifts.[]', function () {
      return this.monthlyGifts.map(item => {
        return {
          y: item.amount,
          month: item.month
        };
      });
    }),
    monthlyGiftsChartGoalSeries: (0, _object.computed)('goalAmount', 'monthlyGifts.[]', function () {
      if ((0, _utils.isEmpty)(this.goalAmount)) {
        return null;
      }

      return this.monthlyGifts.map(() => {
        return {
          y: this.goalAmount
        };
      });
    }),
    monthlyGiftsChartSettings: (0, _object.computed)('monthlyGifts.[]', function () {
      let formatAmountService = this.formatAmountService;
      let router = this.router;
      let series = [{
        type: 'column',
        name: 'Monthly Gifts',
        color: '#0b68a1',
        data: this.monthlyGiftsChartGiftsSeries,
        tooltip: {
          headerFormat: "<span style='font-size: 0.8rem'>{point.key}</span><br/>",
          pointFormatter: function () {
            let formattedAmount = formatAmountService.format(this.y);
            return `<span style='font-size=0.8rem'>${formattedAmount}</span>`;
          }
        },
        events: {
          click(event) {
            let month = event.point.month;
            router.transitionTo('funds.fund.transactions', {
              queryParams: {
                filters: (0, _filterUtilsNew.serializeAppliedFilters)([{
                  propertyName: 'transDate',
                  operation: '><',
                  val: [month, moment(month).endOf('month').format('YYYY-MM-DD')]
                }, {
                  propertyName: 'income'
                }])
              }
            });
          }

        }
      }];

      if (this.monthlyGiftsChartGoalSeries != null) {
        series.push({
          type: 'line',
          name: 'Monthly Goal',
          color: '#f0ad4e',
          lineWidth: 3,
          marker: {
            enabled: false
          },
          data: this.monthlyGiftsChartGoalSeries,
          tooltip: {
            headerFormat: "<span style='font-size: 0.8rem'>Goal</span><br/>",
            pointFormatter: function () {
              return `<span style='font-size=0.8rem'>${formatAmountService.format(this.y)}</span>`;
            }
          }
        });
      }

      return {
        chart: {
          height: this.height
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        xAxis: {
          categories: this.monthlyGiftsChartCategories,
          labels: {
            step: 3
          }
        },
        yAxis: {
          min: 0,
          title: {
            text: null
          }
        },
        legend: {
          enabled: false
        },
        series
      };
    }),

    didInsertElement() {
      this._super(...arguments);

      _runloop.run.scheduleOnce('afterRender', () => {
        Highcharts.chart(this.element, this.monthlyGiftsChartSettings);
      });
    }

  });

  _exports.default = _default;
});