define("donor-elf-web/components/bulk-action/save", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _object, _service, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes>
    {{#if this.waitingOnBulkActionToComplete}}
      <BulkAction::PollForStatusChange
        @bulkAction={{this.bulkAction}}
        @onStatusChange={{this.handleBulkActionStatusChange}}
      />
    {{/if}}
    {{#if this.saveError}}
      <GenericError
        @useLeftBorderCard={{true}}
      />
    {{else}}
      <div>
        {{yield}}
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "n/vg2PsF",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"waitingOnBulkActionToComplete\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@bulkAction\",\"@onStatusChange\"],[[30,0,[\"bulkAction\"]],[30,0,[\"handleBulkActionStatusChange\"]]]],null],[1,\"\\n\"]],[]],null],[41,[30,0,[\"saveError\"]],[[[1,\"    \"],[8,[39,2],null,[[\"@useLeftBorderCard\"],[true]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[12],[1,\"\\n      \"],[18,2,null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"&attrs\",\"&default\"],false,[\"if\",\"bulk-action/poll-for-status-change\",\"generic-error\",\"yield\"]]",
    "moduleName": "donor-elf-web/components/bulk-action/save.hbs",
    "isStrictMode": false
  });

  let BulkActionSaveComponent = (_class = class BulkActionSaveComponent extends _component2.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "bulkAction", _descriptor2, this);

      _initializerDefineProperty(this, "waitingOnBulkActionToComplete", _descriptor3, this);

      _initializerDefineProperty(this, "saveError", _descriptor4, this);

      this.createBulkAction();
    }

    async createBulkAction() {
      let bulkAction = this.store.createRecord('bulk-action');
      bulkAction.actionType = this.args.bulkActionType;
      bulkAction.custom = this.args.bulkActionCustom;
      await bulkAction.save();
      this.bulkAction = bulkAction;
      this.waitingOnBulkActionToComplete = true;
    }

    async handleBulkActionStatusChange(bulkAction) {
      if (bulkAction.status === 'completed') {
        try {
          await bulkAction.destroyRecord();
          this.args.onCompleted(bulkAction.response);
        } catch (error) {
          this.saveError = true;
          this.args.onError();
        }

        this.waitingOnBulkActionToComplete = false;
      } else if (bulkAction.status === 'error') {
        this.saveError = true;
        this.args.onError();
        this.waitingOnBulkActionToComplete = false;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "bulkAction", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "waitingOnBulkActionToComplete", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "saveError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleBulkActionStatusChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleBulkActionStatusChange"), _class.prototype)), _class);
  _exports.default = BulkActionSaveComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BulkActionSaveComponent);
});