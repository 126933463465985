define("donor-elf-web/components/review-item/card/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@glimmer/tracking", "donor-elf-web/lib/review-item-utils"], function (_exports, _component, _templateFactory, _component2, _object, _tracking, _reviewItemUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    ...attributes
    class='{{if this.saved @classToApplyOnSave}}'
  >
    {{#if (eq this.reviewTypeName 'giftBeforePledgeStartDate')}}
      <ReviewItem::Card::GiftBeforePledgeStartDate
        @reviewItem={{@reviewItem}}
        @onSave={{this.handleSave}}
      />
    {{else if (eq this.reviewTypeName 'duplicatePledges')}}
      <ReviewItem::Card::DuplicatePledges
        @reviewItem={{@reviewItem}}
        @onSave={{this.handleSave}}
      />
    {{else}}
      <div>
        {{this.reviewTypeName}}
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "HlmgPkk/",
    "block": "[[[11,0],[17,1],[16,0,[29,[[52,[30,0,[\"saved\"]],[30,2]]]]],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,0,[\"reviewTypeName\"]],\"giftBeforePledgeStartDate\"],null],[[[1,\"    \"],[8,[39,2],null,[[\"@reviewItem\",\"@onSave\"],[[30,3],[30,0,[\"handleSave\"]]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,0,[\"reviewTypeName\"]],\"duplicatePledges\"],null],[[[1,\"    \"],[8,[39,3],null,[[\"@reviewItem\",\"@onSave\"],[[30,3],[30,0,[\"handleSave\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[12],[1,\"\\n      \"],[1,[30,0,[\"reviewTypeName\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]],[]]],[13]],[\"&attrs\",\"@classToApplyOnSave\",\"@reviewItem\"],false,[\"if\",\"eq\",\"review-item/card/gift-before-pledge-start-date\",\"review-item/card/duplicate-pledges\"]]",
    "moduleName": "donor-elf-web/components/review-item/card/index.hbs",
    "isStrictMode": false
  });

  let ReviewItemCardIndexComponent = (_class = class ReviewItemCardIndexComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "saved", _descriptor, this);
    }

    handleSave(reviewItem) {
      this.saved = true;
      this.args.onSave(reviewItem);
    }

    get reviewTypeName() {
      return _reviewItemUtils.reviewTypeNames[this.args.reviewItem.reviewTypeId];
    }

    get reviewTypeLabel() {
      return _reviewItemUtils.reviewTypeLabels[this.args.reviewItem.reviewTypeId];
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "saved", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleSave", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleSave"), _class.prototype)), _class);
  _exports.default = ReviewItemCardIndexComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ReviewItemCardIndexComponent);
});