define("donor-elf-web/templates/funds/fund/more/donor-hub/connect/oauth-redirect", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ZHk96Bdd",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,2],[12],[1,\"\\n    You now need to login to your Donor Hub's server to give DonorElf access to\\n    your information.\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Click the button below to login to the Donor Hub server. After you login\\n    and grant access to DonorElf, you will be sent back to DonorElf's website.\\n  \"],[13],[1,\"\\n  \"],[10,3],[15,6,[29,[\"/users/login?token=\",[33,1,[\"token\"]],\"&token_type=classic_site_token&redirect_to=/oauth/donor-hub/request-access\"]]],[14,0,\"btn btn-warning\"],[12],[1,\"Login to the Donor Hub server\"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"de-form-card\",\"model\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/more/donor-hub/connect/oauth-redirect.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});