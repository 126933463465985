define("donor-elf-web/controllers/admin/fund-groups/fund-group/edit-users", ["exports", "@ember/object/computed", "@ember/controller", "@ember/object", "@ember/utils", "rsvp", "@ember/service", "ember-concurrency"], function (_exports, _computed, _controller, _object, _utils, _rsvp, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    store: (0, _service.inject)(),
    storeCache: (0, _service.inject)(),
    queryParams: ['q'],
    q: '',
    qInput: '',
    errorMessage: null,
    page: 1,
    perPage: 100,
    sortBy: ['user.displayNameLowerCase:asc'],
    sortedItems: (0, _computed.sort)('model.userItems', 'sortBy'),
    filteredItems: (0, _object.computed)('q', 'sortedItems.[]', function () {
      let {
        q,
        sortedItems
      } = (0, _object.getProperties)(this, 'q', 'sortedItems');

      if ((0, _utils.isEmpty)(q)) {
        return sortedItems;
      }

      q = q.toLowerCase();
      return sortedItems.filter(item => {
        return (0, _object.get)(item, 'user.nameAndEmailLowerCase').indexOf(q) >= 0;
      });
    }),
    pagedItems: (0, _object.computed)('page', 'perPage', 'filteredItems', function () {
      let offset = (this.page - 1) * this.perPage;
      return this.filteredItems.slice(offset, offset + this.perPage);
    }),
    totalPages: (0, _object.computed)('filteredItems', 'perPage', function () {
      return Math.ceil(this.filteredItems.length / this.perPage);
    }),
    selectedItems: (0, _object.computed)('sortedItems.@each.isSelected', function () {
      return (0, _object.get)(this, 'sortedItems').filter(item => {
        return (0, _object.get)(item, 'isSelected');
      });
    }),
    debounceApplyFilter: (0, _emberConcurrency.task)(function* () {
      yield (0, _emberConcurrency.timeout)(500);
      this.send('applyFilter');
    }).restartable(),
    save: (0, _emberConcurrency.task)(function* () {
      let store = (0, _object.get)(this, 'store');
      let fundGroupId = (0, _object.get)(this, 'model.fundGroup.id');
      let promises = [];

      try {
        (0, _object.get)(this, 'model.userItems').forEach(fundItem => {
          if ((0, _object.get)(fundItem, 'isSelected')) {
            if ((0, _utils.isEmpty)((0, _object.get)(fundItem, 'fundGroupUser'))) {
              let record = store.createRecord('admin/fund-group-user', {
                fundGroupId: fundGroupId,
                userId: (0, _object.get)(fundItem, 'user.id')
              });
              promises.push(record.save());
            }
          } else if (!(0, _utils.isEmpty)((0, _object.get)(fundItem, 'fundGroupUser'))) {
            promises.push((0, _object.get)(fundItem, 'fundGroupUser').destroyRecord());
          }
        });
        yield _rsvp.default.all(promises);
        yield this.model.fundGroup.reload();
        this.transitionToRoute('admin.fund-groups.fund-group');
      } catch (e) {
        (0, _object.set)(this, 'errorMessage', "Whoops! There was an error saving. Please refresh the page and try again. If it continues to happen please email us to let us know.");
      }
    }).drop(),

    reset() {
      (0, _object.setProperties)(this, {
        q: '',
        qInput: '',
        errorMessage: null,
        page: 1
      });
    },

    actions: {
      applyFilter() {
        (0, _object.setProperties)(this, {
          q: (0, _object.get)(this, 'qInput'),
          page: 1
        });
      },

      changePage(pageNumber) {
        (0, _object.set)(this, 'page', pageNumber);
        this.send('scrollToTop');
      }

    }
  });

  _exports.default = _default;
});