define("donor-elf-web/modifiers/focus-on-insert", ["exports", "ember-modifier", "@ember/utils"], function (_exports, _emberModifier, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let DEFAULT_DELAY = 0; // {{focus-on-insert}}
  // options
  // - selectAllText - will select all the text. default is false
  // - delay - adds a delay in milliseconds before the focus occurs. default is 0
  // - unless - boolean, if true, the focus will not occur. default is false
  // - onlyIfBlank - boolean, if true will only focus if the element.target.value is blank

  var _default = (0, _emberModifier.modifier)(function focusOnInsert(element, positionalArgs, {
    selectAllText = false,
    delay = DEFAULT_DELAY,
    unless = false,
    onlyIfBlank = false
  }) {
    if (unless !== true && (onlyIfBlank !== true || (0, _utils.isEmpty)(element.value))) {
      setTimeout(() => {
        if (selectAllText) {
          element.select();
        } else {
          element.focus();
        }
      }, delay);
    }
  });

  _exports.default = _default;
});