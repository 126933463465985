define("donor-elf-web/lib/models/journal-contact-info", ["exports", "@glimmer/tracking", "donor-elf-web/lib/array-utils", "donor-elf-web/lib/date-utils", "donor-elf-web/lib/models/journal-decision-info"], function (_exports, _tracking, _arrayUtils, _dateUtils, _journalDecisionInfo) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _class2, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const SORT_TASKS_BY = ['isComplete:asc', 'completedDate:desc', 'dueDate:asc'];
  let JournalContactInfo = (_class = (_temp = _class2 = class JournalContactInfo {
    static get TODAY() {
      if (this._TODAY) {
        return this._TODAY;
      }

      return this._TODAY = _dateUtils.default.serializeToDateOnly(_dateUtils.default.correctTimezone(new Date()));
    }

    static get TOMORROW() {
      if (this._TOMORROW) {
        return this._TOMORROW;
      }

      let tomorrow = _dateUtils.default.correctTimezone(new Date());

      tomorrow.setDate(tomorrow.getDate() + 1);
      return this._TOMORROW = _dateUtils.default.serializeToDateOnly(tomorrow);
    }

    constructor(args) {
      var _args$contact;

      _defineProperty(this, "contact", void 0);

      _defineProperty(this, "contactId", void 0);

      _defineProperty(this, "journalInfo", void 0);

      _defineProperty(this, "journalId", void 0);

      _initializerDefineProperty(this, "journalContact", _descriptor, this);

      _initializerDefineProperty(this, "journalDecisions", _descriptor2, this);

      _initializerDefineProperty(this, "journalDecisionInfos", _descriptor3, this);

      _initializerDefineProperty(this, "pledges", _descriptor4, this);

      _defineProperty(this, "tasks", void 0);

      _defineProperty(this, "recommendations", void 0);

      _initializerDefineProperty(this, "lastGift", _descriptor5, this);

      _initializerDefineProperty(this, "existingPledge", _descriptor6, this);

      _initializerDefineProperty(this, "tasksByStageId", _descriptor7, this);

      _initializerDefineProperty(this, "lastTaskUpdatedAt", _descriptor8, this);

      _initializerDefineProperty(this, "currentStageIndex", _descriptor9, this);

      _initializerDefineProperty(this, "lastCompletedStageIndex", _descriptor10, this);

      _initializerDefineProperty(this, "lastStageIsCompleted", _descriptor11, this);

      _initializerDefineProperty(this, "hasNotCompleteTasks", _descriptor12, this);

      _initializerDefineProperty(this, "hasNotCompleteDueByTodayTask", _descriptor13, this);

      _initializerDefineProperty(this, "earliestNotCompleteDueDate", _descriptor14, this);

      _initializerDefineProperty(this, "recommendedContactIds", _descriptor15, this);

      _initializerDefineProperty(this, "recommendedByContactIds", _descriptor16, this);

      _initializerDefineProperty(this, "decisionStage", _descriptor17, this);

      _initializerDefineProperty(this, "decisionMade", _descriptor18, this);

      this.journalInfo = args.journalInfo;
      this.contact = args.contact;
      this.contactId = (_args$contact = args.contact) === null || _args$contact === void 0 ? void 0 : _args$contact.id;
      this.updateFromJournalContactDetailModel(args.journalContactDetailModel);
      this.journalId = this.journalContact.journalId;
    }

    recalculate() {
      this.tasksByStageId = {};
      this.hasNotCompleteTasks = false;
      this.hasNotCompleteDueByTodayTask = false;
      let stageIdIndex = {};
      this.journalInfo.sortedStages.forEach((stage, index) => {
        this.tasksByStageId[stage.id] = [];
        stageIdIndex[stage.id] = index;
      });
      let decisionStage;
      let lastTaskUpdatedAt;
      let currentStageIndex = -1;
      let lastCompletedStageIndex = -1;
      let earliestNotCompleteDueDate;
      this.decisionMade = this.journalDecisions.length > 0;

      if (this.decisionMade) {
        lastCompletedStageIndex = this.journalInfo.sortedStages.findIndex(x => x.decisionStage);
        currentStageIndex = lastCompletedStageIndex + 1;
        decisionStage = {
          decisionMade: true
        };
      }

      this.tasks.forEach(task => {
        this.tasksByStageId[task.journalStageId].push(task);

        if (lastTaskUpdatedAt == null || task.updatedAt > lastTaskUpdatedAt) {
          lastTaskUpdatedAt = task.updatedAt;
        }

        if (!task.isComplete) {
          this.hasNotCompleteTasks = true;

          if (task.dueDate) {
            let serializedDueDate = _dateUtils.default.serializeToDateOnly(task.dueDate);

            if (earliestNotCompleteDueDate == null || serializedDueDate < earliestNotCompleteDueDate) {
              earliestNotCompleteDueDate = serializedDueDate;
            }

            if (serializedDueDate <= JournalContactInfo.TODAY) {
              this.hasNotCompleteDueByTodayTask = true;
            }
          }
        }

        if (!task.isComplete || task.isAttemptedResult) {
          if (currentStageIndex < stageIdIndex[task.journalStageId]) {
            currentStageIndex = stageIdIndex[task.journalStageId];
          }
        } else {
          if (lastCompletedStageIndex < stageIdIndex[task.journalStageId]) {
            lastCompletedStageIndex = stageIdIndex[task.journalStageId];
          }

          if (currentStageIndex <= lastCompletedStageIndex) {
            currentStageIndex = lastCompletedStageIndex + 1;
          }
        }
      });

      if (decisionStage == null) {
        if (this.tasksByStageId[this.journalInfo.askStage.id].find(x => x.completedDate)) {
          decisionStage = {
            waitingOnDecision: true
          };
        } else if (this.tasks.find(x => x.completedDate)) {
          decisionStage = {
            preAsk: true
          };
        } else {
          decisionStage = {
            notStarted: true
          };
        }
      }

      this.decisionStage = decisionStage;
      this.journalInfo.sortedStages.forEach(stage => {
        this.tasksByStageId[stage.id] = (0, _arrayUtils.sortByProperties)(this.tasksByStageId[stage.id], SORT_TASKS_BY);
      });
      this.lastTaskUpdatedAt = lastTaskUpdatedAt;
      this.currentStageIndex = currentStageIndex;
      this.lastCompletedStageIndex = lastCompletedStageIndex;
      this.lastStageIsCompleted = lastCompletedStageIndex === this.journalInfo.sortedStages.length - 1;
      this.earliestNotCompleteDueDate = earliestNotCompleteDueDate || JournalContactInfo.TOMORROW;
      this.recommendedContactIds = this.recommendations.filter(x => x.recommendedByContactId === this.contactId).map(x => x.recommendedContactId);
      this.recommendedByContactIds = this.recommendations.filter(x => x.recommendedContactId === this.contactId).map(x => x.recommendedByContactId);
    }

    updateFromJournalContactDetailModel(journalContactDetailModel) {
      this.journalContact = journalContactDetailModel.journalContact;
      this.journalDecisions = (0, _arrayUtils.sortByProperties)(journalContactDetailModel.journalDecisions.toArray(), ['decisionDate:asc']);
      this.journalDecisionInfos = this.journalDecisions.map(journalDecision => new _journalDecisionInfo.default({
        journalDecision,
        journalContactDetailModel,
        journalInfo: this.journalInfo
      }));
      this.pledges = journalContactDetailModel.pledges.toArray();
      this.tasks = journalContactDetailModel.tasks.toArray();
      this.recommendations = journalContactDetailModel.recommendations.toArray();
      this.lastGift = journalContactDetailModel.lastGift;
      this.existingPledge = journalContactDetailModel.existingPledge;
      this.recalculate();
    }

  }, _defineProperty(_class2, "_TODAY", void 0), _defineProperty(_class2, "_TOMORROW", void 0), _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "journalContact", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "journalDecisions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "journalDecisionInfos", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "pledges", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "lastGift", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "existingPledge", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "tasksByStageId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "lastTaskUpdatedAt", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "currentStageIndex", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return -1;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "lastCompletedStageIndex", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return -1;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "lastStageIsCompleted", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "hasNotCompleteTasks", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "hasNotCompleteDueByTodayTask", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "earliestNotCompleteDueDate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "recommendedContactIds", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "recommendedByContactIds", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "decisionStage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "decisionMade", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = JournalContactInfo;
});