define("donor-elf-web/routes/admin/users/add/funds", ["exports", "@ember/routing/route", "@ember/object"], function (_exports, _route, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    model() {
      return this.modelFor('admin.users.add');
    },

    afterModel(model) {
      // If it's an Org-Admin, then automatically set their selectedFundIds and save the user
      if ((0, _object.get)(model, 'changeset.role') === 'Org-Admin') {
        let firstFund = (0, _object.get)(model, 'funds').find(fund => {
          return (0, _object.get)(fund, 'active');
        });
        (0, _object.set)(model, 'selectedFundIds', [(0, _object.get)(firstFund, 'id')]);
        this.transitionTo('admin.users.add.save');
      }
    },

    redirect(model) {
      (0, _object.get)(model, 'changeset').validate().then(() => {
        if ((0, _object.get)(model, 'changeset.isInvalid')) {
          this.transitionTo('admin.users.add');
        }
      });
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.reset();
      }
    }

  });

  _exports.default = _default;
});