define("donor-elf-web/templates/funds/fund/more/cancel-account", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "TWqEKSc3",
    "block": "[[[8,[39,0],[[4,[38,1],[[30,0,[\"templateWillDestroy\"]]],null]],null,[[\"default\"],[[[[1,\"\\n  \"],[10,\"h4\"],[12],[1,\"Are you sure you want to cancel your account?\"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Please be sure to download any information you want from DonorElf before\\n    you cancel your account.\\n  \"],[13],[1,\"\\n  \"],[10,2],[14,0,\"mb-5\"],[12],[1,\"\\n    Once you cancel you will no longer be charged each month, and you will no\\n    longer have access to DonorElf.\\n  \"],[13],[1,\"\\n  \"],[10,\"label\"],[14,\"for\",\"cancellation-reason\"],[12],[1,\"\\n    If you don't mind, can we ask why you want to cancel?\\n  \"],[13],[1,\"\\n  \"],[11,\"textarea\"],[24,1,\"cancellation-reason\"],[24,\"rows\",\"3\"],[24,0,\"form-control p-1 mb-3\"],[4,[38,2],[\"change\",[30,0,[\"handleCancelReasonChanged\"]]],null],[4,[38,3],null,null],[12],[13],[1,\"\"],[41,[30,0,[\"cancellingAccount\"]],[[[1,\"    \"],[8,[39,5],[[24,0,\"mb-3\"]],[[\"@leftBorderCard\"],[true]],[[\"default\"],[[[[1,\"\\n      Cancelling your account...\\n    \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-danger\"],[16,\"disabled\",[52,[30,0,[\"cancellingAccount\"]],\"disabled\"]],[24,4,\"button\"],[4,[38,2],[\"click\",[30,0,[\"cancelAccount\"]]],null],[12],[1,\"\\n      \"],[1,[28,[35,6],[\"trash\"],null]],[1,\"\\n      Yes, Cancel my Account\\n    \"],[13],[1,\"\\n\"],[41,[51,[30,0,[\"cancellingAccount\"]]],[[[1,\"      \"],[8,[39,8],[[24,0,\"btn btn-outline-secondary ml-4\"]],[[\"@route\"],[\"funds.fund.dashboard\"]],[[\"default\"],[[[[1,\"\\n        No Thanks\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"form-card\",\"will-destroy\",\"on\",\"focus-on-insert\",\"if\",\"alert/info\",\"svg-jar\",\"unless\",\"link-to\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/more/cancel-account.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});