define("donor-elf-web/components/filter-wrapper", ["exports", "@ember/object/computed", "@ember/component", "@ember/utils", "@ember/object", "donor-elf-web/lib/filter-utils"], function (_exports, _computed, _component, _utils, _object, _filterUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
  attrs
    - filters
    - filterSettings = [{
      prop:  '',
      label: '',
      type:  string|number|boolean|select,
      options: [{ label: '', val: ''}]
        - only applies when type is 'select'
      mapValFn: function that maps the criteria val
        - optional
    }]
  
    - applyFilter(filters)
      - fired when filters should be applied
  
  */
  var _default = _component.default.extend({
    filters: [[]],
    filterSettings: null,
    filtersChangesets: null,
    expanded: false,
    filterChangesetsAreDirty: true,
    individualFilters: (0, _object.computed)('filtersChangesets.@each.length', function () {
      let filters = [];
      (0, _object.get)(this, 'filtersChangesets').forEach(andFilters => {
        andFilters.forEach(filter => {
          filters.push(filter);
        });
      });
      return filters;
    }),
    noFilters: (0, _computed.empty)('individualFilters'),
    filterMessage: '',

    setFilterMessage() {
      let messages = [];
      let andFiltersLength = (0, _object.get)(this, 'filters.firstObject.length');

      if (andFiltersLength == null || andFiltersLength === 0) {
        messages.push('All');
      } else {
        let filterSettings = (0, _object.get)(this, 'filterSettings');
        let andFilters = (0, _object.get)(this, 'filters')[0];
        andFilters.forEach(filter => {
          let filterSetting = filterSettings.find(x => {
            return x.prop === filter.prop;
          });
          let val = filter.val;

          if (filterSetting.type === 'select') {
            val = filterSetting.options.find(x => {
              return x.val === filter.val;
            }).label;
          }

          messages.push(`${filterSetting.label} ${_filterUtils.default.operationLabels[filter.op]} '${val}'`);
        });
      }

      (0, _object.set)(this, 'filterMessage', messages.join(' and '));
    },

    didReceiveAttrs() {
      let filters = (0, _object.get)(this, 'filters');
      let filtersChangesets = JSON.parse(JSON.stringify(filters));
      (0, _object.set)(this, 'filtersChangesets', filtersChangesets);
      this.setFilterMessage();
    },

    actions: {
      handleApplyFilter() {
        let applyFilter = (0, _object.get)(this, 'applyFilter');

        if (applyFilter) {
          let filters = [];
          (0, _object.get)(this, 'filtersChangesets').forEach(andFilters => {
            filters.push(andFilters.filter(x => {
              return !(0, _utils.isEmpty)((0, _object.get)(x, 'val')) && !(0, _utils.isEmpty)((0, _object.get)(x, 'op'));
            }));
          });
          applyFilter(filters);
        }
      },

      addAndFilter() {
        (0, _object.get)(this, 'filtersChangesets')[0].pushObject({});
      },

      handleRemoveFilter(groupFilterIndex, item) {
        (0, _object.get)(this, 'filtersChangesets')[groupFilterIndex].removeObject(item);
      },

      toggleExpanded() {
        this.toggleProperty('expanded');

        if ((0, _object.get)(this, 'expanded') && (0, _object.get)(this, 'filtersChangesets')[0].length === 0) {
          (0, _object.get)(this, 'filtersChangesets')[0].pushObject({});
        }
      }

    }
  });

  _exports.default = _default;
});