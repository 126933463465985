define("donor-elf-web/templates/components/coaching-interval-chart", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "DE+5OSbk",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,0],[14,0,\"coaching-interval-chart\"],[12],[13],[1,\"\\n\"],[41,[33,2],[[[6,[39,3],null,[[\"target\",\"attachment\",\"class\"],[[33,4],\"top center\",\"coaching-interval-chart-tooltip\"]],[[\"default\"],[[[[1,\"      \"],[10,0],[12],[1,\"\\n\"],[41,[33,5],[[[1,\"          \"],[1,[28,[35,6],[[33,7]],[[\"removeNoCents\"],[true]]]],[1,\"\\n\"]],[]],[[[1,\"          \"],[1,[28,[35,8],[[33,7]],null]],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"small text-muted font-weight-light\"],[12],[1,\"\\n\"],[41,[33,9],[[[41,[33,5],[[[1,\"            \"],[10,0],[12],[1,\"High: \"],[1,[28,[35,6],[[33,9]],[[\"removeNoCents\"],[true]]]],[13],[1,\"\\n            \"],[10,0],[12],[1,\"Low: \"],[1,[28,[35,6],[[33,10]],[[\"removeNoCents\"],[true]]]],[13],[1,\"\\n\"]],[]],[[[1,\"            \"],[10,0],[12],[1,\"High: \"],[1,[28,[35,8],[[33,9]],null]],[13],[1,\"\\n            \"],[10,0],[12],[1,\"Low: \"],[1,[28,[35,8],[[33,10]],null]],[13],[1,\"\\n\"]],[]]]],[]],[[[41,[33,10],[[[41,[33,5],[[[1,\"            \"],[10,0],[12],[1,\"Goal: \"],[1,[28,[35,6],[[33,10]],[[\"removeNoCents\"],[true]]]],[13],[1,\"\\n\"]],[]],[[[1,\"            \"],[10,0],[12],[1,\"Goal: \"],[1,[28,[35,8],[[33,10]],null]],[13],[1,\"\\n\"]],[]]],[1,\"        \"]],[]],null]],[]]],[41,[33,11],[[[41,[33,5],[[[1,\"            \"],[10,0],[12],[1,\"Total: \"],[1,[28,[35,6],[[33,11]],[[\"removeNoCents\"],[true]]]],[13],[1,\"\\n\"]],[]],[[[1,\"            \"],[10,0],[12],[1,\"Total: \"],[1,[28,[35,8],[[33,11]],null]],[13],[1,\"\\n\"]],[]]]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]]]]]],[]],null]],[]],null]],[],false,[\"if\",\"displayChart\",\"displayTooltip\",\"liquid-tether\",\"chartElement\",\"tooltipDisplayAmount\",\"format-amount\",\"tooltipVal\",\"format-number\",\"highGoal\",\"lowGoal\",\"tooltipTotalVal\"]]",
    "moduleName": "donor-elf-web/templates/components/coaching-interval-chart.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});