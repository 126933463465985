define("donor-elf-web/routes/coaching/individuals/individual/add-report", ["exports", "@ember/routing/route", "@ember/service", "@ember/object"], function (_exports, _route, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    coachingService: (0, _service.inject)(),

    model() {
      let individualRecord = this.modelFor('coaching.individuals.individual').record;
      let lastReport = (0, _object.get)(individualRecord, 'sortedReports.lastObject');

      if ((0, _object.get)(lastReport, 'completed')) {
        let nextReport = (0, _object.get)(this, 'coachingService').addReport(individualRecord);
        return nextReport.save().then(() => {
          this.transitionTo('coaching.individuals.individual.report.edit', (0, _object.get)(nextReport, 'id'));
        });
      } else {
        return {
          showNotCompletedErrorMessage: true,
          lastReportId: (0, _object.get)(lastReport, 'id')
        };
      }
    }

  });

  _exports.default = _default;
});