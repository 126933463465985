define("donor-elf-web/routes/funds/fund/groups/add", ["exports", "@ember/routing/route", "@ember/object", "@ember/service", "ember-changeset-validations", "ember-changeset", "ember-changeset-validations/validators"], function (_exports, _route, _object, _service, _emberChangesetValidations, _emberChangeset, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const addGroupValidation = {
    name: (0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    })
  };

  var _default = _route.default.extend({
    navbarSettings: (0, _service.inject)(),

    beforeModel() {
      (0, _object.set)(this, 'navbarSettings.title', 'Add Group');
    },

    model() {
      let changeset = new _emberChangeset.default({}, (0, _emberChangesetValidations.default)(addGroupValidation), addGroupValidation);
      return {
        changeset
      };
    }

  });

  _exports.default = _default;
});