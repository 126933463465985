define("donor-elf-web/components/task-list/row", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr>
    {{#if @displayOneColumn}}
      <td>
        <Task::Info
          @task={{@task}}
          @onTaskDeleted={{this.handleTaskDeleted}}
          class='mb-2'
        />
  
        {{#if (is-present @task.contactId)}}
          <DeContact @contactId={{@task.contactId}} />
        {{else}}
          <span class='font-weight-light text-muted'>No Contact</span>
        {{/if}}
      </td>
    {{else}}
      <td>
        <Task::Info 
          @task={{@task}}
          @onTaskDeleted={{this.handleTaskDeleted}}
          class='tw-w-64'
        />
      </td>
      <td class='w-100'>
        {{#if (is-present @task.contactId)}}
          <Contact @contactId={{@task.contactId}} />
        {{else}}
          <span class='font-weight-light text-muted'>No Contact</span>
        {{/if}}
      </td>
    {{/if}}
  </tr>
  */
  {
    "id": "3kZxUUMw",
    "block": "[[[10,\"tr\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[10,\"td\"],[12],[1,\"\\n      \"],[8,[39,1],[[24,0,\"mb-2\"]],[[\"@task\",\"@onTaskDeleted\"],[[30,2],[30,0,[\"handleTaskDeleted\"]]]],null],[1,\"\\n\\n\"],[41,[28,[37,2],[[30,2,[\"contactId\"]]],null],[[[1,\"        \"],[8,[39,3],null,[[\"@contactId\"],[[30,2,[\"contactId\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,1],[14,0,\"font-weight-light text-muted\"],[12],[1,\"No Contact\"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"td\"],[12],[1,\"\\n      \"],[8,[39,1],[[24,0,\"tw-w-64\"]],[[\"@task\",\"@onTaskDeleted\"],[[30,2],[30,0,[\"handleTaskDeleted\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"td\"],[14,0,\"w-100\"],[12],[1,\"\\n\"],[41,[28,[37,2],[[30,2,[\"contactId\"]]],null],[[[1,\"        \"],[8,[39,4],null,[[\"@contactId\"],[[30,2,[\"contactId\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,1],[14,0,\"font-weight-light text-muted\"],[12],[1,\"No Contact\"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"@displayOneColumn\",\"@task\"],false,[\"if\",\"task/info\",\"is-present\",\"de-contact\",\"contact\"]]",
    "moduleName": "donor-elf-web/components/task-list/row.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (_class = class extends _component2.default {
    handleTaskDeleted(task) {
      this.args.onTaskDeleted(task);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "handleTaskDeleted", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleTaskDeleted"), _class.prototype)), _class));

  _exports.default = _default;
});