define("donor-elf-web/components/journal/cell/label-description", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    ...attributes
  >
    {{#if (has-block 'label')}}
      {{yield to='label'}}
    {{else}}
      <div class='font-weight-bold text-secondary'>
        {{@label}}
      </div>
    {{/if}}
    <div class='tw-leading-tight font-weight-light'>
      {{yield}}
    </div>
  </div>
  */
  {
    "id": "rOSPLrlD",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[41,[48,[30,3]],[[[1,\"    \"],[18,3,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"font-weight-bold text-secondary\"],[12],[1,\"\\n      \"],[1,[30,2]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[10,0],[14,0,\"tw-leading-tight font-weight-light\"],[12],[1,\"\\n    \"],[18,4,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@label\",\"&label\",\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "donor-elf-web/components/journal/cell/label-description.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});