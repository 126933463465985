define("donor-elf-web/controllers/funds/fund/journals/journal/report/default", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "@ember/service", "ember-local-storage", "donor-elf-web/lib/journal-utils"], function (_exports, _controller, _object, _tracking, _service, _emberLocalStorage, _journalUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    Highcharts,
    moment
  } = window;
  let JournalReportDefaultController = (_dec = (0, _emberLocalStorage.storageFor)('local-settings'), (_class = class JournalReportDefaultController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "formatAmountService", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "localSettings", _descriptor3, this);

      _initializerDefineProperty(this, "showLastIntervalCount", _descriptor4, this);

      _initializerDefineProperty(this, "hiddenStageNames", _descriptor5, this);
    }

    get askStageName() {
      var _this$model$journalIn;

      return ((_this$model$journalIn = this.model.journalInfo.sortedStages.find(x => x.askStage)) === null || _this$model$journalIn === void 0 ? void 0 : _this$model$journalIn.name) || 'Ask';
    }

    get sortedStages() {
      return this.model.journalInfo.sortedStages;
    }

    get chartHeight() {
      return 200;
    }

    get showLastOptions() {
      let {
        totalIntervalCount
      } = this;
      let options = [{
        label: 'All',
        val: 0
      }];

      for (let index = 1; index <= totalIntervalCount; index++) {
        let label = index === 1 ? 'Last' : `Last ${index}`;
        options.push({
          label,
          val: index
        });
      }

      return options;
    }

    get showIntervalTimespanOptions() {
      return [{
        label: 'Weeks',
        val: 'week'
      }, {
        label: 'Days',
        val: 'day'
      }];
    }

    get totalIntervalCount() {
      return this.model.formattedReport.intervalItems.length;
    }

    get selectedIntervalItems() {
      if (this.showLastIntervalCount === 0) {
        return this.model.formattedReport.intervalItems;
      } else {
        return this.model.formattedReport.intervalItems.slice(this.model.formattedReport.intervalItems.length - this.showLastIntervalCount);
      }
    }

    get selectedIntervalStageTasks() {
      return this.selectedIntervalItems.map(item => {
        return {
          dateRangeFormatted: item.dateRangeFormatted,
          stageInfo: this.sortedStages.map(stage => {
            var _item$completedStageW, _item$completedStageW2;

            return {
              created: item.createdStageTasks[stage.id] || 0,
              attempted: ((_item$completedStageW = item.completedStageWork[stage.id]) === null || _item$completedStageW === void 0 ? void 0 : _item$completedStageW.attempted) || 0,
              completed: ((_item$completedStageW2 = item.completedStageWork[stage.id]) === null || _item$completedStageW2 === void 0 ? void 0 : _item$completedStageW2.completed) || 0
            };
          })
        };
      });
    }

    get timeframe() {
      let {
        selectedIntervalItems
      } = this;
      return {
        start: selectedIntervalItems[0].date,
        end: selectedIntervalItems[selectedIntervalItems.length - 1].dateEnd
      };
    }

    get completedTaskStagesToShow() {
      let {
        hiddenStageNames
      } = this;
      return this.model.journalInfo.sortedStages.filter(x => hiddenStageNames[x.name] !== true);
    } // [{ goal: JournalGoal, intervalCumulativeAmounts: [] }]


    get goalsAndDecisions() {
      let {
        model: {
          formattedReport: {
            intervalDates,
            yesDecisions
          }
        }
      } = this;
      return this.model.journalInfo.goals.map(goal => {
        return {
          goal,
          intervalCumulativeAmounts: (0, _journalUtils.GetIntervalCumulativeAmounts)(intervalDates, yesDecisions[goal.id])
        };
      });
    }

    get showProjectedGoal() {
      return this.model.intervalTimespan === 'week';
    }

    setHiddenStageNames() {
      this.hiddenStageNames = {};
      (this.localSettings.get('journalDefaultReportHideStageNames') || '').split(',').forEach(name => this.hiddenStageNames[name] = true);
    }

    templateDidInsert() {
      this.showLastIntervalCount = Math.min(this.localSettings.get('journalShowLastIntervalCount'), this.totalIntervalCount);
      this.setHiddenStageNames();
    }

    templateWillDestroy() {
      this.showLastIntervalCount = 0;
    }

    handleHiddenStageNameChange(stage, e) {
      if (e.target.checked) {
        this.hiddenStageNames[stage.name] = true;
      } else {
        delete this.hiddenStageNames[stage.name];
      } // notify a tracked property was changed


      this.hiddenStageNames = { ...this.hiddenStageNames
      };
      this.localSettings.set('journalDefaultReportHideStageNames', Object.keys(this.hiddenStageNames).join(','));
    }

    handleShowLastIntervalCountChanged(val) {
      if (this.showLastIntervalCount !== val) {
        this.showLastIntervalCount = val;
        this.localSettings.set('journalShowLastIntervalCount', this.showLastIntervalCount);
      }
    }

    handleShowIntervalTimespanChanged(val) {
      this.router.transitionTo('funds.fund.journals.journal.report', {
        queryParams: {
          interval: val
        }
      });
    }

    renderNewPledgesChart(journalGoal, e) {
      Highcharts.chart(e, this.getNewPledgesChart(journalGoal));
    }

    getNewPledgesChart(journalGoal) {
      let {
        selectedIntervalItems,
        formatAmountService
      } = this;
      let series = [];
      series.push({
        type: 'column',
        name: 'New Pledges',
        color: '#0b68a1',
        pointPadding: 0.0,
        tooltip: {
          headerFormat: '',
          pointFormatter: function () {
            let newPledges = formatAmountService.format(this.y, {
              removeNoCents: true
            });
            return `<div>${this.dateRangeFormatted}</div><div class='tw-text-sm'>Yes Decisions: ${newPledges}</div>`;
          }
        },
        data: selectedIntervalItems.map(item => {
          return {
            y: item.decisions[journalGoal.id].yesAmount,
            dateRangeFormatted: item.dateRangeFormatted
          };
        })
      });

      if (journalGoal.weeklyGoal) {
        series.push({
          type: 'line',
          name: 'Goal',
          color: '#f0ad4e',
          lineWidth: 2,
          marker: {
            enabled: false
          },
          data: [{
            x: -0.5,
            y: journalGoal.weeklyGoal
          }, ...selectedIntervalItems.map(() => journalGoal.weeklyGoal), {
            x: selectedIntervalItems.length - 0.5,
            y: journalGoal.weeklyGoal
          }]
        });
      }

      return {
        chart: {
          height: this.chartHeight
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        xAxis: {
          categories: selectedIntervalItems.map(x => x.date),
          labels: {
            formatter: function () {
              return moment(this.value).format('M/D');
            }
          },
          min: 0,
          max: selectedIntervalItems.length - 1
        },
        yAxis: {
          min: 0,
          title: {
            text: null
          }
        },
        tooltip: {
          useHTML: true
        },
        legend: {
          enabled: false
        },
        series
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "formatAmountService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "localSettings", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showLastIntervalCount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "hiddenStageNames", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleHiddenStageNameChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleHiddenStageNameChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleShowLastIntervalCountChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleShowLastIntervalCountChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleShowIntervalTimespanChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleShowIntervalTimespanChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "renderNewPledgesChart", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "renderNewPledgesChart"), _class.prototype)), _class));
  _exports.default = JournalReportDefaultController;
});