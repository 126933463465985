define("donor-elf-web/validations/messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    notANumber: '{description} must be a number (only digits or a period)'
  };
  _exports.default = _default;
});