define("donor-elf-web/routes/funds/fund/more/donor-hub/connect/credentials", ["exports", "@ember/routing/route", "@ember/object", "@ember/service", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators"], function (_exports, _route, _object, _service, _emberChangeset, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const validations = {
    username: (0, _validators.validatePresence)({
      presence: true,
      message: 'Please enter your username'
    }),
    password: (0, _validators.validatePresence)({
      presence: true,
      message: 'Please enter your password'
    })
  };

  var _default = _route.default.extend({
    navbarSettings: (0, _service.inject)(),

    beforeModel() {
      (0, _object.set)(this, 'navbarSettings.title', 'Donor Hub Login Info');
    },

    model() {
      let record = (0, _object.get)(this.modelFor('funds.fund.more.donor-hub.connect'), 'record');
      return {
        record,
        changeset: new _emberChangeset.default(record, (0, _emberChangesetValidations.default)(validations), validations)
      };
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.reset();
      }
    }

  });

  _exports.default = _default;
});