define("donor-elf-web/controllers/funds/fund/contacts/contact/merge/index", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "ember-cached-decorator-polyfill", "donor-elf-web/lib/array-utils"], function (_exports, _controller, _object, _tracking, _emberCachedDecoratorPolyfill, _arrayUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function scrubAndSplitContactNameLowerCase(nameLowerCase) {
    return nameLowerCase.replace(/(,|&)/g, '').replace(/\sand\s/g, ' ').split(' ').filter(x => x !== '');
  }

  let ContactMergeIndexController = (_class = class ContactMergeIndexController extends _controller.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['q', 'page']);

      _initializerDefineProperty(this, "q", _descriptor, this);

      _initializerDefineProperty(this, "page", _descriptor2, this);

      _defineProperty(this, "perPage", 50);
    }

    get sortedContacts() {
      return (0, _arrayUtils.sortByProperties)(this.otherContactsWithNameMatches, ['matches:desc', 'contact.name:asc']).map(x => x.contact);
    }

    get otherContactsWithNameMatches() {
      let {
        contact
      } = this.model;
      let contactDisplayNames = scrubAndSplitContactNameLowerCase(contact.nameLowerCase);
      return this.model.otherContacts.map(otherContact => {
        return {
          matches: scrubAndSplitContactNameLowerCase(otherContact.nameLowerCase).filter(y => contactDisplayNames.indexOf(y) >= 0).length,
          contact: otherContact
        };
      });
    }

    get filteredContacts() {
      let query = (this.q || '').trim().toLowerCase();

      if (query.length === 0) {
        return this.sortedContacts;
      }

      return this.sortedContacts.filter(x => {
        return x.nameLowerCase.indexOf(query) >= 0;
      });
    }

    get pagedContacts() {
      let offset = (this.page - 1) * this.perPage;
      return this.filteredContacts.slice(offset, offset + this.perPage);
    }

    get totalPages() {
      return Math.ceil(this.filteredContacts.length / this.perPage);
    }

    templateWillDestroy() {
      this.q = '';
      this.page = 1;
    }

    changePage(pageNumber) {
      this.page = pageNumber;
    }

    handleSearchInput(e) {
      this.q = e.target.value;
      this.page = 1;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "q", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "page", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "sortedContacts", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "sortedContacts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changePage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changePage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleSearchInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleSearchInput"), _class.prototype)), _class);
  _exports.default = ContactMergeIndexController;
});