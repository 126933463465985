define("donor-elf-web/validations/coaching/report", ["exports", "ember-changeset-validations/validators", "donor-elf-web/validators/coaching-report-section-field-values"], function (_exports, _validators, _coachingReportSectionFieldValues) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default({
    individual
  }) {
    return {
      startDate: (0, _validators.validatePresence)(true),
      endDate: (0, _validators.validatePresence)(true),
      sectionFieldValues: [(0, _coachingReportSectionFieldValues.default)({
        individual
      })]
    };
  }
});