define("donor-elf-web/templates/admin/fund-groups/fund-group/delete", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "oI7qRDiW",
    "block": "[[[6,[39,0],null,[[\"deleteTask\"],[[33,1]]],[[\"default\"],[[[[1,\"  \"],[10,2],[12],[1,\"\\n    Are you sure you want to delete this fund group?\\n  \"],[13],[1,\"\\n  \"],[10,\"dl\"],[12],[1,\"\\n    \"],[10,\"dt\"],[12],[1,\"Name\"],[13],[1,\"\\n    \"],[10,\"dd\"],[12],[1,[33,2,[\"record\",\"name\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"delete-form\",\"delete\",\"model\"]]",
    "moduleName": "donor-elf-web/templates/admin/fund-groups/fund-group/delete.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});