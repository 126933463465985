define("donor-elf-web/services/communications-service", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    reasons: Object.freeze(['', 'Ask', 'Follow Up', 'Increase', 'Newsletter', 'Saying Hi', 'Thank You', 'Other']),
    mediums: Object.freeze(['', 'Call', 'Email', 'FaceBook', 'Face to Face', 'Gift', 'Letter', 'Post Card', 'Text', 'FaceTime/Skype'])
  });

  _exports.default = _default;
});