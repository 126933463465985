define("donor-elf-web/templates/admin/users/show/funds/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "N8sjjo1D",
    "block": "[[[10,\"ol\"],[14,0,\"breadcrumb row-bottom-margin\"],[12],[1,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[39,0],null,[[\"route\"],[\"admin.users\"]],[[\"default\"],[[[[1,\"Users\"]],[]]]]],[13],[1,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[39,0],null,[[\"route\"],[\"admin.users.show\"]],[[\"default\"],[[[[1,[33,1,[\"user\",\"displayName\"]]]],[]]]]],[13],[1,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[1,\"Fund: \"],[1,[33,1,[\"fund\",\"name\"]]],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[6,[39,2],null,null,[[\"default\"],[[[[1,\"  \"],[10,\"h4\"],[14,0,\"mb-3\"],[12],[1,\"Edit Fund Permission\"],[13],[1,\"\\n  \"],[10,0],[14,0,\"form-group\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[1,\"Fund\"],[13],[1,\"\\n    \"],[10,\"input\"],[15,2,[33,1,[\"fund\",\"name\"]]],[14,0,\"form-control\"],[14,\"readonly\",\"readonly\"],[14,4,\"text\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[1,[28,[35,3],null,[[\"changeset\",\"property\",\"label\",\"selectOptions\",\"class\",\"showValidationErrors\"],[[33,1,[\"userFundChangeset\"]],\"role\",\"Role\",[33,4],\"mb-4\",false]]]],[1,\"\\n  \"],[10,0],[14,0,\"d-flex\"],[12],[1,\"\\n    \"],[10,\"button\"],[15,\"onclick\",[28,[37,5],[[33,6]],null]],[14,0,\"btn btn-warning\"],[12],[1,\"\\n\"],[41,[33,6,[\"isIdle\"]],[[[1,\"        Save\\n\"]],[]],[[[1,\"        Saving...\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"],[41,[33,6,[\"isIdle\"]],[[[1,\"      \"],[6,[39,0],null,[[\"class\",\"route\"],[\"btn btn-link ml-3\",\"admin.users.show\"]],[[\"default\"],[[[[1,\"Cancel\"]],[]]]]],[1,\"\\n\"],[6,[39,0],null,[[\"class\",\"route\",\"model\"],[\"link-danger mt-2 ml-auto\",\"admin.users.show.funds.delete\",[33,1,[\"fund\",\"id\"]]]],[[\"default\"],[[[[1,\"        Delete\\n\"]],[]]]]]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"link-to\",\"model\",\"de-form-card\",\"form-group\",\"roleOptions\",\"perform\",\"save\",\"if\"]]",
    "moduleName": "donor-elf-web/templates/admin/users/show/funds/edit.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});