define("donor-elf-web/routes/funds/fund/more/mailchimp/connected", ["exports", "@ember/routing/route", "@ember/service"], function (_exports, _route, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MailchimpConnectedRoute = (_class = class MailchimpConnectedRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fundsService", _descriptor, this);
    }

    beforeModel() {
      return this.fundsService.confirmValidFundToken();
    }

    async model() {
      let mailchimpModel = this.modelFor('funds.fund.more.mailchimp');
      let {
        mailchimpSetting
      } = mailchimpModel;
      let funds = await this.fundsService.getAllFunds({
        filter: {
          has_ability_to_view_contact_name: true
        }
      });
      let changeset = {
        mailchimpSettingId: mailchimpSetting.id,
        fundId: this.fundsService.selectedFund.id,
        mailchimpListId: mailchimpSetting.lists[0].id
      };
      return {
        funds,
        changeset,
        ...mailchimpModel
      };
    }

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.reset();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = MailchimpConnectedRoute;
});