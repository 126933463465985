define("donor-elf-web/components/coaching-edit-tasks", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service"], function (_exports, _component, _object, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* attrs
    - report
    - field
    - onlyEditCompleted - boolean
      - if true then can only edit the completed property
      - default is false
  */
  var _default = _component.default.extend({
    coachingService: (0, _service.inject)(),
    report: null,
    field: null,
    onlyEditCompleted: false,
    fieldId: (0, _computed.alias)('field.id'),
    fieldType: (0, _computed.alias)('field.t'),
    tasks: (0, _object.computed)('report.id', 'field.id', function () {
      let {
        report,
        field
      } = (0, _object.getProperties)(this, 'report', 'field');
      return (0, _object.get)(this, 'coachingService').getFieldValue(field, report);
    }),

    didInsertElement() {
      this._super(...arguments);

      if ((0, _object.get)(this, 'tasks.length') === 0) {
        this.send('addTask');
      }
    },

    actions: {
      addTask() {
        (0, _object.get)(this, 'tasks').pushObject({
          completed: false,
          l: ''
        });
      },

      removeTask(task) {
        (0, _object.get)(this, 'tasks').removeObject(task);
      }

    }
  });

  _exports.default = _default;
});