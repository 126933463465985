define("donor-elf-web/controllers/funds/fund/dashboard/global-interaction", ["exports", "@ember/object/computed", "@ember/controller", "@ember/object"], function (_exports, _computed, _controller, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    goal: (0, _computed.alias)('model.dashboard.goal'),
    fund: (0, _computed.alias)('model.fund'),
    supportTarget: (0, _computed.alias)('goal.goalData.support_target'),
    supportTargetPresent: (0, _computed.notEmpty)('supportTarget'),
    supportLevel: (0, _computed.alias)('goal.goalData.support_level'),
    supportLevelRounded: (0, _object.computed)('supportLevel', function () {
      return Math.round((0, _object.get)(this, 'supportLevel'));
    }),
    supportDate: (0, _computed.alias)('goal.goalData.support_level_date')
  });

  _exports.default = _default;
});