define("donor-elf-web/models/custom-reports/tenfold-paystub", ["exports", "@ember/object", "ember-data"], function (_exports, _object, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    employeeName: attr('string'),
    payCycle: attr('string'),
    endAt: attr('date-only'),
    checkNumber: attr('number'),
    benefits: attr(),
    deductions: attr(),
    hours: attr(),
    summaries: attr(),
    grossWages: (0, _object.computed)(function () {
      let summaries = (0, _object.get)(this, 'summaries') || [];
      let grossWageSummary = summaries.find(x => x['description'] === 'Gross Wages');

      if (grossWageSummary) {
        return (0, _object.get)(grossWageSummary, 'amount');
      } else {
        return 0;
      }
    }),
    netPay: (0, _object.computed)(function () {
      let summaries = (0, _object.get)(this, 'summaries') || [];
      let netPaySummary = summaries.find(x => x['description'] === 'Net Pay');

      if (netPaySummary) {
        return (0, _object.get)(netPaySummary, 'amount');
      } else {
        return 0;
      }
    })
  });

  _exports.default = _default;
});