define("donor-elf-web/components/coaching-interval-chart", ["exports", "@ember/component", "@ember/object", "@ember/service", "@ember/runloop", "@ember/utils", "@ember/object/computed"], function (_exports, _component, _object, _service, _runloop, _utils, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Highcharts
  } = window;
  const tooltipDisplayAmountTypes = {
    'amount': true,
    'pledged&received': true
  };
  /*
    attrs
    - field
    - report
    - individual
    - propertyName (optional)
    - diff - display the difference from previous report val
  */

  var _default = _component.default.extend({
    coachingService: (0, _service.inject)(),
    field: null,
    individual: null,
    report: null,
    propertyName: null,
    diff: false,
    tooltipVal: null,
    tooltipTotalVal: null,
    displayTooltip: (0, _computed.notEmpty)('tooltipVal'),
    tooltipDisplayAmount: (0, _object.computed)('field.t', function () {
      return tooltipDisplayAmountTypes[(0, _object.get)(this, 'field.t')] || false;
    }),
    chartElement: null,
    // set in didReceiveAttrs()
    displayChart: (0, _object.computed)('field.id', 'report.id', function () {
      return (0, _object.get)(this, 'reportIndex') > 0;
    }),
    reportIndex: (0, _object.computed)('report.id', function () {
      let {
        individual,
        report
      } = (0, _object.getProperties)(this, 'individual', 'report');
      return (0, _object.get)(individual, 'sortedReports').indexOf(report);
    }),
    startAtReportIndex: (0, _object.computed)('report.id', function () {
      return Math.max(0, (0, _object.get)(this, 'reportIndex') - 3);
    }),
    data: (0, _object.computed)('field.id', 'report.id', function () {
      let {
        field,
        individual,
        propertyName,
        diff,
        reportIndex,
        startAtReportIndex,
        coachingService
      } = (0, _object.getProperties)(this, 'field', 'individual', 'propertyName', 'diff', 'report', 'reportIndex', 'startAtReportIndex', 'coachingService');
      let reports = (0, _object.get)(individual, 'sortedReports').toArray();
      let data = [];
      let totalVal = 0;

      for (let i = startAtReportIndex; i <= reportIndex; i++) {
        let val = coachingService.getFieldValue(field, reports[i], {
          propertyName
        }) || 0;

        if (field.t === 'response') {
          val = (0, _object.get)(val, 'total') || 0;
        }

        let indexTotalVal = totalVal + val;

        if (diff) {
          indexTotalVal = val;
          let prevVal = 0;
          let prevReport = reports[i - 1];

          if (prevReport) {
            prevVal = coachingService.getFieldValue(field, prevReport, {
              propertyName
            }) || 0;
          }

          val = val - prevVal;
        }

        let color = i === reportIndex ? '#f0ad4e' : '#6c757d';
        data.push({
          y: val,
          color,
          events: {
            click: () => {
              (0, _object.setProperties)(this, {
                tooltipVal: val,
                tooltipTotalVal: indexTotalVal
              });
            },
            mouseOver: () => {
              (0, _object.setProperties)(this, {
                tooltipVal: val,
                tooltipTotalVal: indexTotalVal
              });
            }
          }
        }); // adding 0 so totalVal isn't exact same object as indexTotalVal

        totalVal = indexTotalVal + 0;
      }

      return data;
    }),
    highGoal: (0, _computed.alias)('field.intervalGoal.high'),
    lowGoal: (0, _computed.alias)('field.intervalGoal.low'),
    highLowSeries: (0, _object.computed)('field.id', 'report.id', function () {
      let high = (0, _object.get)(this, 'field.intervalGoal.high');
      let low = (0, _object.get)(this, 'field.intervalGoal.low');

      if (high == null && (low == null || low === 0)) {
        return null;
      }

      let data = [];
      let reportIndex = (0, _object.get)(this, 'reportIndex');
      let maxIndex = Math.min(reportIndex, 3);

      if ((0, _utils.isEmpty)(high)) {
        for (let i = 0; i <= maxIndex; i++) {
          data.push([i, low]);
        }

        return {
          type: 'line',
          color: '#dee2e6',
          marker: {
            enabled: false
          },
          data
        };
      } else {
        for (let i = 0; i <= maxIndex; i++) {
          data.push([i, low, high]);
        }

        return {
          type: 'arearange',
          color: '#dee2e6',
          data
        };
      }
    }),
    chartSettings: (0, _object.computed)('field.id', 'report.id', function () {
      let series = [];
      let {
        highLowSeries,
        data
      } = (0, _object.getProperties)(this, 'highLowSeries', 'data');

      if (highLowSeries) {
        series.push(highLowSeries);
      }

      series.push({
        type: 'column',
        color: '#6c757d',
        pointPadding: 0.0,
        data: data,
        events: {
          mouseOut: () => {
            (0, _object.set)(this, 'tooltipVal', null);
          }
        }
      });
      return {
        chart: {
          animation: false,
          height: 45,
          width: 100,
          margin: [2, 0, 2, 0],
          borderWidth: 0
        },
        title: {
          text: null
        },
        tooltip: {
          enabled: false
        },
        credits: {
          enabled: false
        },
        xAxis: {
          labels: {
            enabled: false
          },
          visible: false
        },
        yAxis: {
          min: 0,
          labels: {
            enabled: false
          },
          title: {
            text: null
          },
          startOnTick: false,
          endOnTick: false,
          tickPositions: [0]
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          series: {
            animation: false,
            states: {
              hover: {
                enabled: false
              }
            }
          }
        },
        series
      };
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      (0, _object.set)(this, 'tooltipVal', null);

      if ((0, _object.get)(this, 'displayChart')) {
        _runloop.run.scheduleOnce('afterRender', () => {
          Highcharts.chart(this.element.querySelector('.coaching-interval-chart'), this.chartSettings);
          (0, _object.set)(this, 'chartElement', this.element.querySelector('.coaching-interval-chart'));
        });
      }
    }

  });

  _exports.default = _default;
});