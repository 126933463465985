define("donor-elf-web/routes/coaching/individuals/individual/report/edit", ["exports", "@ember/routing/route", "@ember/object", "@ember/service"], function (_exports, _route, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    abilities: (0, _service.inject)(),
    coachingService: (0, _service.inject)(),

    beforeModel(transition) {
      if ((0, _object.get)(this, 'abilities').noCoachingAbility('edit_report')) {
        transition.abort();
      }
    },

    model() {
      let parentModel = this.modelFor('coaching.individuals.individual.report');
      let changeset = (0, _object.get)(this, 'coachingService').createChangesetForReport(parentModel.individualRecord, parentModel.record);
      let prevReport = parentModel.individualRecord.previousReport(parentModel.record);
      let prevChangeset = null;

      if (prevReport != null) {
        prevChangeset = (0, _object.get)(this, 'coachingService').createChangesetForReport(parentModel.individualRecord, prevReport);
      }

      return Object.assign({}, parentModel, {
        changeset,
        prevChangeset
      });
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.reset();
      }
    }

  });

  _exports.default = _default;
});