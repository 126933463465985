define("donor-elf-web/templates/components/de-card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "jnpKVP3/",
    "block": "[[[10,0],[14,0,\"card-body\"],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "donor-elf-web/templates/components/de-card.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});