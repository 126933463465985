define("donor-elf-web/templates/components/form-group-checkbox", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "NVzq38Ay",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,\"label\"],[12],[1,[34,2]],[13],[1,\"\\n  \"],[1,[28,[35,3],null,[[\"checked\",\"type\",\"class\",\"click\"],[[28,[37,4],[[28,[37,5],[[33,6],[33,7]],null]],null],\"checkbox\",\"form-control\",[28,[37,8],[[30,0],\"handleChange\"],null]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,3],null,[[\"checked\",\"type\",\"class\",\"click\"],[[28,[37,4],[[28,[37,5],[[33,6],[33,7]],null]],null],\"checkbox\",\"form-check-input\",[28,[37,8],[[30,0],\"handleChange\"],null]]]]],[1,\"\\n  \"],[10,\"label\"],[14,0,\"ml-1 mb-0\"],[12],[1,[34,2]],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"labelAbove\",\"label\",\"input\",\"mut\",\"get\",\"changeset\",\"property\",\"action\"]]",
    "moduleName": "donor-elf-web/templates/components/form-group-checkbox.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});