define("donor-elf-web/routes/admin", ["exports", "@ember/routing/route", "@ember/object", "rsvp", "@ember/service", "donor-elf-web/lib/logger", "ember-simple-auth/mixins/authenticated-route-mixin", "donor-elf-web/adapters/expired-fund-token-error"], function (_exports, _route, _object, _rsvp, _service, _logger, _authenticatedRouteMixin, _expiredFundTokenError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_authenticatedRouteMixin.default, {
    abilities: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    fundsService: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),
    settings: (0, _service.inject)(),

    beforeModel() {
      this._super(...arguments);

      if (this.fundsService.selectedFund == null) {
        return this.store.findRecord('fund', this.currentUser.selectedFundId).then(fund => {
          this.fundsService.selectedFund = fund;
        });
      }
    },

    model(params, transition) {
      return _rsvp.default.hash({
        settings: this.settings.loadSettings(this.fundsService.selectedFund.id),
        abilities: this.abilities.loadForAllFunds()
      }).then(model => {
        if (this.abilities.noAbility('view_admin_area')) {
          transition.abort();
          return null;
        }

        return model;
      });
    },

    afterModel() {
      this.navbarSettings.topNavbarComponent = 'top-navbar-admin';
    },

    actions: {
      willTransition(transition) {
        var _transition$intent;

        let intentName = ((_transition$intent = transition.intent) === null || _transition$intent === void 0 ? void 0 : _transition$intent.name) || '';

        if (intentName.length > 0 && intentName.indexOf('admin.') !== 0) {
          this.navbarSettings.topNavbarComponent = '';
        }
      },

      error(error, transition) {
        if (error instanceof _expiredFundTokenError.default) {
          _logger.default.error(`invalid fund token while going to route: ${transition.intent.name}`);
        }

        return true;
      },

      refreshAdminRoute() {
        this.refresh();
      },

      didTransition() {
        (0, _object.set)(this.controllerFor('application'), 'currentMenuLabel', 'adminMenu');
      }

    }
  });

  _exports.default = _default;
});