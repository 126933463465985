define("donor-elf-web/components/run-interval", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component"], function (_exports, _component, _templateFactory, _object, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    {{did-insert this.templateDidInsert}}
    {{will-destroy this.templateWillDestroy}}
    ...attributes
  />
  */
  {
    "id": "tbHHyEnw",
    "block": "[[[11,0],[17,1],[4,[38,0],[[30,0,[\"templateDidInsert\"]]],null],[4,[38,1],[[30,0,[\"templateWillDestroy\"]]],null],[12],[13]],[\"&attrs\"],false,[\"did-insert\",\"will-destroy\"]]",
    "moduleName": "donor-elf-web/components/run-interval.hbs",
    "isStrictMode": false
  });

  let RunInterval = (_class = class RunInterval extends _component2.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "intervalId", void 0);
    }

    templateDidInsert() {
      this.intervalId = window.setInterval(() => {
        this.args.onTick();
      }, this.args.interval);
    }

    templateWillDestroy() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype)), _class);
  _exports.default = RunInterval;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RunInterval);
});