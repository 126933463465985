define("donor-elf-web/components/journal/report/edit-weekly-goal", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/utils", "@glimmer/tracking", "@ember/service", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators"], function (_exports, _component, _templateFactory, _component2, _object, _utils, _tracking, _service, _emberChangeset, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='tw-px-3 tw-pt-2 tw-pb-3 d-flex justify-content-end tw-w-40'>
    <Form::Underline::Label
      @label='Weekly Goal'
      class='tw-w-24 mt-2 text-right'
    >
      <Form::Underline::Text
        @changeset={{this.changeset}}
        @property='weeklyGoal'
        @showValidationErrors={{this.showValidationErrors}}
        {{focus-on-insert}}
        class='w-100 text-right'
      />
    </Form::Underline::Label>
  </div>
  
  {{#if this.saveError}}
    <GenericError @error={{this.saveError}} />
  {{/if}}
  
  <div class='border-top tw-py-2 tw-px-3 bg-gray-100 rounded-bottom d-flex justify-content-between'>
    <button
      {{on 'click' this.save}}
      class='btn btn-sm btn-warning py-0'
      type='button'
    >
      {{#if this.saving}}
        Saving...
      {{else}}
        Save
      {{/if}}
    </button>
    <button
      {{on 'click' @onClose}}
      class='btn btn-sm btn-link py-0 pr-0 tw-text-sm'
      disabled='{{if this.saving 'disabled'}}'
      type='button'
    >
      Cancel
    </button>
  </div>
  */
  {
    "id": "VTkeLS6E",
    "block": "[[[10,0],[14,0,\"tw-px-3 tw-pt-2 tw-pb-3 d-flex justify-content-end tw-w-40\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"tw-w-24 mt-2 text-right\"]],[[\"@label\"],[\"Weekly Goal\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],[[24,0,\"w-100 text-right\"],[4,[38,2],null,null]],[[\"@changeset\",\"@property\",\"@showValidationErrors\"],[[30,0,[\"changeset\"]],\"weeklyGoal\",[30,0,[\"showValidationErrors\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"saveError\"]],[[[1,\"  \"],[8,[39,4],null,[[\"@error\"],[[30,0,[\"saveError\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,0],[14,0,\"border-top tw-py-2 tw-px-3 bg-gray-100 rounded-bottom d-flex justify-content-between\"],[12],[1,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-sm btn-warning py-0\"],[24,4,\"button\"],[4,[38,5],[\"click\",[30,0,[\"save\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"saving\"]],[[[1,\"      Saving...\\n\"]],[]],[[[1,\"      Save\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-sm btn-link py-0 pr-0 tw-text-sm\"],[16,\"disabled\",[29,[[52,[30,0,[\"saving\"]],\"disabled\"]]]],[24,4,\"button\"],[4,[38,5],[\"click\",[30,1]],null],[12],[1,\"\\n    Cancel\\n  \"],[13],[1,\"\\n\"],[13]],[\"@onClose\"],false,[\"form/underline/label\",\"form/underline/text\",\"focus-on-insert\",\"if\",\"generic-error\",\"on\"]]",
    "moduleName": "donor-elf-web/components/journal/report/edit-weekly-goal.hbs",
    "isStrictMode": false
  });

  /*
    args
      - model
      - onClose()
  */
  const WeeklyGoalValidation = {
    weeklyGoal: (0, _validators.validateNumber)({
      gte: 0,
      integer: true,
      allowBlank: true,
      message: 'Weekly Goal must be a positive whole number'
    })
  };

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (_class = class _class extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "fundsService", _descriptor, this);

      _defineProperty(this, "changeset", void 0);

      _initializerDefineProperty(this, "saving", _descriptor2, this);

      _initializerDefineProperty(this, "saveError", _descriptor3, this);

      _initializerDefineProperty(this, "showValidationErrors", _descriptor4, this);

      this.changeset = new _emberChangeset.default(this.args.model, (0, _emberChangesetValidations.default)(WeeklyGoalValidation), WeeklyGoalValidation);
    }

    async save() {
      if (this.saving) {
        return;
      }

      this.saving = true;
      this.showValidationErrors = true;
      await this.changeset.validate();

      if (this.changeset.get('isValid')) {
        try {
          if ((0, _utils.isEmpty)(this.changeset.get('weeklyGoal')) || this.changeset.get('weeklyGoal') == 0) {
            this.changeset.set('weeklyGoal', null);
          }

          await this.fundsService.confirmValidFundToken();
          await this.changeset.save();
          this.args.onClose();
        } catch (error) {
          this.saveError = error;
          this.saving = false;
        }
      } else {
        this.saving = false;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "saving", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "saveError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showValidationErrors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class));

  _exports.default = _default;
});