define("donor-elf-web/templates/components/select-contact", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "gmE4RpCP",
    "block": "[[[8,[39,0],null,[[\"@searchEnabled\",\"@selected\",\"@options\",\"@selectedItemComponent\",\"@placeholder\",\"@disabled\",\"@search\",\"@onFocus\",\"@onClose\",\"@buildSelection\",\"@onChange\"],[true,[30,0,[\"selectedContact\"]],[30,0,[\"initialContacts\"]],\"select-contact-selected-contact\",[30,0,[\"placeholder\"]],[30,0,[\"disabled\"]],[30,0,[\"searchContacts\"]],[30,0,[\"handleFocused\"]],[30,0,[\"handleClosed\"]],[30,0,[\"handleBuildSelection\"]],[30,0,[\"selectContact\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"py-1\"],[12],[1,\"\\n\"],[41,[28,[37,2],[[30,1,[\"type\"]],\"blank\"],null],[[[1,\"      \"],[10,1],[14,0,\"text-muted\"],[12],[1,[30,0,[\"blankContactLabel\"]]],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,2],[[30,1,[\"type\"]],\"more\"],null],[[[1,\"      There are more contacts. Please type in the search bar to see more.\\n\"]],[]],[[[1,\"      \"],[10,\"strong\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n\"],[41,[30,1,[\"cityAndState\"]],[[[1,\"        \"],[10,0],[14,0,\"ml-2\"],[12],[1,[30,1,[\"cityAndState\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"contact\"],false,[\"power-select\",\"if\",\"eq\"]]",
    "moduleName": "donor-elf-web/templates/components/select-contact.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});