define("donor-elf-web/lib/persisted-records-array", ["exports", "@ember/array/proxy", "@ember/object"], function (_exports, _proxy, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    - filters allContent so that 'content' only includes persisted records.
      - it filters out new and destroyed records
  
    TODO: this still doesn't work but one day I'll figure it out
  */
  var _default = _proxy.default.extend({
    content: (0, _object.computed)('allContent.@each.{isDeleted,isNew}', function () {
      return (0, _object.get)(this, 'allContent').filter(item => {
        return !(0, _object.get)(item, 'isDeleted') && !(0, _object.get)(item, 'isNew');
      });
    })
  });

  _exports.default = _default;
});