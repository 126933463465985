define("donor-elf-web/templates/account-cancelled", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "a7n1LQOR",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Your account has been cancelled.\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    We're sorry to see you go, but if you have any questions, please email\\n    us at \"],[10,3],[14,6,\"mailto:support@donorelf.com\"],[12],[1,\"support@donorelf.com\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"form-card\"]]",
    "moduleName": "donor-elf-web/templates/account-cancelled.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});