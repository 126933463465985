define("donor-elf-web/templates/old-internet-explorer", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "vpTj/Zpc",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,2],[12],[1,\"\\n    It looks like you're using Internet Explorer version 11 or below.\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    We're sorry but DonorElf does not work with Internet Explorer versions 11\\n    and below because it doesn't correctly display the elements on the website.\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    If you are on Windows 10, you can use Internet Explorer Edge instead.\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    If you don't have Internet Explorer Edge, then we recommend using one of the\\n    following browsers:\\n  \"],[13],[1,\"\\n  \"],[10,\"dl\"],[12],[1,\"\\n    \"],[10,\"dt\"],[12],[1,\"Chrome\"],[13],[1,\"\\n    \"],[10,\"dd\"],[12],[10,3],[14,6,\"https://www.google.com/chrome/browser/desktop/index.html\"],[12],[1,\"Download\"],[13],[13],[1,\"\\n    \"],[10,\"dt\"],[12],[1,\"FireFox\"],[13],[1,\"\\n    \"],[10,\"dd\"],[12],[10,3],[14,6,\"https://www.mozilla.org/en-US/firefox/new/\"],[12],[1,\"Download\"],[13],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Again, we're really sorry your current browser doesn't work with DonorElf.\\n    Please email us at support@donorelf.com if you need any help viewing\\n    DonorElf in one of these other browsers.\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"de-form-card\"]]",
    "moduleName": "donor-elf-web/templates/old-internet-explorer.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});