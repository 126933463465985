define("donor-elf-web/components/form-group-date", ["exports", "@ember/component", "@ember/object", "@ember/service"], function (_exports, _component, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    attrs
    - changeset
    - property
    - label
    - disabled
    - showValidationErrors
    - onSelected
      - action to call when a date is selected
    - onOpened
      - action to call when the date picker is opened
  */
  var _default = _component.default.extend({
    contactsCache: (0, _service.inject)(),
    settings: (0, _service.inject)(),
    classNames: ['form-group'],
    classNameBindings: ['hasErrors'],
    label: null,
    changeset: null,
    disabled: false,
    // the associated property on the changeset
    property: null,
    showValidationErrors: false,
    hasErrors: (0, _object.computed)('showValidationErrors', 'changeset.errors.[]', 'property', function () {
      if ((0, _object.get)(this, 'showValidationErrors') === false) {
        return false;
      }

      let property = (0, _object.get)(this, 'property');
      return (0, _object.get)(this, 'changeset.errors').find(function (item) {
        return (0, _object.get)(item, 'key') === property;
      }) != null;
    }),
    actions: {
      handleSelected(date) {
        (0, _object.set)(this, `changeset.${(0, _object.get)(this, 'property')}`, date);
        let onSelected = (0, _object.get)(this, 'onSelected');

        if (onSelected) {
          onSelected(date);
        }
      },

      handleOpened() {
        let onOpened = (0, _object.get)(this, 'onOpened');

        if (onOpened) {
          onOpened();
        }
      }

    }
  });

  _exports.default = _default;
});