define("donor-elf-web/lib/route-reset-controller", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    resetController(controller, isExiting) {
      if (isExiting && typeof controller.reset === 'function') {
        controller.reset();
      }

      this._super(...arguments);
    }

  });

  _exports.default = _default;
});