define("donor-elf-web/validations/list-selection-add-option", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateListSelectionAddOption;

  function validateListSelectionAddOption(args) {
    return {
      val: (_key, newValue, oldValue, _changes, _content) => {
        let val = newValue || oldValue;

        if ((0, _utils.isEmpty)(val)) {
          return 'Need to enter a value';
        }

        val = val.toLowerCase().trim();

        if (args.existingOptionVals.some(x => x.toLowerCase() == val)) {
          return `That ${args.addOptionsLabel} already exists`;
        }

        return true;
      }
    };
  }

  ;
});