define("donor-elf-web/routes/application", ["exports", "@ember/routing/route", "@ember/object", "@ember/utils", "@ember/service", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _route, _object, _utils, _service, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_applicationRouteMixin.default, {
    currentUser: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),
    session: (0, _service.inject)(),
    queryParams: {
      loginToken: {
        replace: true
      },
      loginEmail: {
        replace: true
      }
    },

    isExplorer() {
      // from https://stackoverflow.com/questions/9847580
      return (
        /*@cc_on!@*/
        false || !!document.documentMode
      );
    },

    beforeModel() {
      if (this.isExplorer()) {
        this.transitionTo('old-internet-explorer');
      }

      (0, _object.set)(this, 'navbarSettings.title', 'DonorElf');

      if ((0, _object.get)(this, 'session.isAuthenticated')) {
        return this.loadCurrentUser();
      }
    },

    model(params, transition) {
      if (!(0, _utils.isEmpty)(params.loginToken)) {
        let session = (0, _object.get)(this, 'session');

        if ((0, _object.get)(session, 'isAuthenticated')) {
          return session.invalidate().then(() => {
            // after we invalidate the session, we need to reload the
            // page to confirm we clear all the data from the previous login
            window.location.reload();
            transition.abort();
          });
        } else {
          // for now, if there is a login token then we never show the full menu.
          // this will change later.
          return session.authenticate('authenticator:oauth2', 'login_token', params.loginToken).then(() => {
            return this.loadCurrentUser();
          }, () => {
            this.transitionTo('login');
          });
        }
      } else if (!(0, _utils.isEmpty)(params.loginEmail)) {
        let session = (0, _object.get)(this, 'session');

        if ((0, _object.get)(session, 'isAuthenticated')) {
          if ((0, _object.get)(this, 'currentUser.email') !== params.loginEmail) {
            return session.invalidate().then(() => {
              // after we invalidate the session, we need to reload the
              // page to confirm we clear all the data from the previous login
              window.location.reload();
              transition.abort();
            });
          }
        }
      }
    },

    sessionAuthenticated() {
      return this.loadCurrentUser();
    },

    sessionInvalidated() {
      sessionStorage.clear();
      localStorage.removeItem('ember_simple_auth-session');
    },

    loadCurrentUser() {
      return (0, _object.get)(this, 'currentUser').load().then(() => {
        // remove the token from the URL since it's no longer needed
        if (this.controller) {
          (0, _object.setProperties)(this.controller, {
            loginToken: null,
            loginEmail: null
          });
        }
      }).catch(() => {
        if ((0, _object.get)(this, 'session.isAuthenticated')) {
          (0, _object.get)(this, 'session').invalidate();
        }
      });
    },

    actions: {
      didTransition() {
        // remove the token from the URL since it's no longer needed
        (0, _object.setProperties)(this.controller, {
          loginToken: null,
          loginEmail: null
        });
      },

      scrollToTop() {
        let bmContent = document.querySelector('.bm-content');

        if (bmContent) {
          bmContent.scrollTop = 0;
        }
      }

    }
  });

  _exports.default = _default;
});