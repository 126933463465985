define("donor-elf-web/templates/admin/users/show/activation-link", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ktZcg62L",
    "block": "[[[10,\"ol\"],[14,0,\"breadcrumb row-bottom-margin\"],[12],[1,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[39,0],null,[[\"route\"],[\"admin.users\"]],[[\"default\"],[[[[1,\"Users\"]],[]]]]],[13],[1,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[39,0],null,[[\"route\"],[\"admin.users.show\"]],[[\"default\"],[[[[1,[33,1,[\"displayName\"]]]],[]]]]],[13],[1,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[1,\"Activation Link\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[6,[39,2],null,null,[[\"default\"],[[[[1,\"  \"],[10,\"h2\"],[12],[1,\"Activation Link\"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Below is the activation link for the user. They should automatically\\n    receive an email with this activation link when they are first added as a\\n    user to DonorElf but sometimes it doesn't make it to their inbox.\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    You can send this link to them so they can activate their DonorElf account:\\n  \"],[13],[1,\"\\n  \"],[10,\"pre\"],[14,0,\"mb-5 mt-4 font-weight-bold\"],[12],[1,\"    \"],[1,[30,0,[\"activationLink\"]]],[1,\"  \"],[13],[1,\"\\n  \"],[6,[39,0],null,[[\"class\",\"activeClass\",\"route\"],[\"btn btn-outline-warning\",\"\",\"admin.users.show\"]],[[\"default\"],[[[[1,\"Go Back\"]],[]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"link-to\",\"model\",\"de-card\"]]",
    "moduleName": "donor-elf-web/templates/admin/users/show/activation-link.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});