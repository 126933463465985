define("donor-elf-web/routes/coaching/individuals/individual", ["exports", "@ember/routing/route", "@ember/service", "@ember/object"], function (_exports, _route, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    abilities: (0, _service.inject)(),
    coachingService: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),
    store: (0, _service.inject)(),

    model(params) {
      let coachingService = (0, _object.get)(this, 'coachingService');
      (0, _object.set)(coachingService, 'selectedIndividualId', params.individualId);
      return coachingService.refreshSelectedIndividualToken().then(() => {
        return (0, _object.get)(this, 'abilities').loadCoachingAbilities({
          individualId: params.individualId
        }).then(() => {
          return (0, _object.get)(this, 'store').findRecord('coaching/individual', params.individualId).then(record => {
            (0, _object.set)(this, 'navbarSettings.title', (0, _object.get)(record, 'name'));
            return {
              record
            };
          });
        });
      });
    }

  });

  _exports.default = _default;
});