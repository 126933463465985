define("donor-elf-web/components/event/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "donor-elf-web/lib/event-type-names"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _eventTypeNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='event-item {{this.eventColorClass}}'>
    <div class='{{if this.showContact 'event-shows-contact'}} d-flex'>
      <div>
        <div class='event-name'>
          <strong>{{this.eventName}}</strong> <span class='text-muted font-weight-light'>on {{format-date @eventModel.occurredAt}}</span>
        </div>
        {{#if this.showContact}}
          <Contact @contactId={{@eventModel.contactId}} class='event-contact' />
        {{/if}}
      </div>
      <div
        {{on 'click' this.toggleInfo}}
        class='ml-auto event-toggle-info cursor-pointer'
        role='button'
      >
        <span>{{svg-jar 'info'}}</span>
      </div>
    </div>
    <div class='event-info {{unless this.showInfo 'd-none'}}'>
      {{#if this.showInfo}}
        {{#if (eq this.eventName 'Donor Info Changed')}}
          <Event::InfoChanged @eventModel={{@eventModel}} />
        {{else if (eq this.eventName 'New Pledge')}}
          <Event::NewPledge @eventModel={{@eventModel}} />
        {{else if (eq this.eventName 'Unthanked Donor')}}
          <Event::UnthankedDonor @eventModel={{@eventModel}} />
        {{else}}
          {{component this.eventInfoComponentName eventModel=@eventModel}}
        {{/if}}
      {{/if}}
    </div>
  </div>
  */
  {
    "id": "WKmKtcjc",
    "block": "[[[10,0],[15,0,[29,[\"event-item \",[30,0,[\"eventColorClass\"]]]]],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[52,[30,0,[\"showContact\"]],\"event-shows-contact\"],\" d-flex\"]]],[12],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[10,0],[14,0,\"event-name\"],[12],[1,\"\\n        \"],[10,\"strong\"],[12],[1,[30,0,[\"eventName\"]]],[13],[1,\" \"],[10,1],[14,0,\"text-muted font-weight-light\"],[12],[1,\"on \"],[1,[28,[35,1],[[30,1,[\"occurredAt\"]]],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[30,0,[\"showContact\"]],[[[1,\"        \"],[8,[39,2],[[24,0,\"event-contact\"]],[[\"@contactId\"],[[30,1,[\"contactId\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n    \"],[11,0],[24,0,\"ml-auto event-toggle-info cursor-pointer\"],[24,\"role\",\"button\"],[4,[38,3],[\"click\",[30,0,[\"toggleInfo\"]]],null],[12],[1,\"\\n      \"],[10,1],[12],[1,[28,[35,4],[\"info\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[\"event-info \",[52,[51,[30,0,[\"showInfo\"]]],\"d-none\"]]]],[12],[1,\"\\n\"],[41,[30,0,[\"showInfo\"]],[[[41,[28,[37,6],[[30,0,[\"eventName\"]],\"Donor Info Changed\"],null],[[[1,\"        \"],[8,[39,7],null,[[\"@eventModel\"],[[30,1]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,6],[[30,0,[\"eventName\"]],\"New Pledge\"],null],[[[1,\"        \"],[8,[39,8],null,[[\"@eventModel\"],[[30,1]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,6],[[30,0,[\"eventName\"]],\"Unthanked Donor\"],null],[[[1,\"        \"],[8,[39,9],null,[[\"@eventModel\"],[[30,1]]],null],[1,\"\\n\"]],[]],[[[1,\"        \"],[46,[30,0,[\"eventInfoComponentName\"]],null,[[\"eventModel\"],[[30,1]]],null],[1,\"\\n      \"]],[]]]],[]]]],[]]]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@eventModel\"],false,[\"if\",\"format-date\",\"contact\",\"on\",\"svg-jar\",\"unless\",\"eq\",\"event/info-changed\",\"event/new-pledge\",\"event/unthanked-donor\",\"component\"]]",
    "moduleName": "donor-elf-web/components/event/index.hbs",
    "isStrictMode": false
  });

  let EventComponent = (_class = class EventComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "showInfo", _descriptor, this);
    }

    get showContact() {
      return this.args.showContact ?? true;
    }

    get eventName() {
      return _eventTypeNames.eventTypeNames[this.args.eventModel.eventTypeId];
    }

    get eventColorClass() {
      return EventColorName[this.args.eventModel.eventTypeId];
    }

    get eventInfoComponentName() {
      return EventInfoComponentNames[this.args.eventModel.eventTypeId];
    }

    toggleInfo() {
      this.showInfo = !this.showInfo;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "showInfo", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleInfo", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleInfo"), _class.prototype)), _class);
  _exports.default = EventComponent;
  const EventColorName = {
    1: 'good-event',
    2: 'bad-event',
    3: 'bad-event',
    4: 'info-event',
    5: 'good-event',
    8: 'good-event',
    9: 'good-event',
    10: 'bad-event',
    11: 'good-event',
    12: 'good-event',
    13: 'good-event',
    14: 'good-event',
    15: 'info-event',
    16: 'bad-event',
    17: 'info-event'
  };
  const EventInfoComponentNames = {
    1: 'event-info-new-donor',
    2: 'event-info-late-donor',
    3: 'event-info-stopped-giving-donor',
    4: 'event-info-address-changed',
    5: 'event-info-new-monthly-donor',
    9: 'event-info-above-regular-amount',
    10: 'event-info-below-regular-amount',
    11: 'event-info-returning-late-donor',
    12: 'event-info-returning-stopped-giving-donor',
    13: 'event-info-catchup-gift',
    14: 'event-info-extra-gift',
    15: 'event-info-expiring-pledge',
    16: 'event-info-unthanked-donor',
    17: 'event-info-ask-for-increase'
  };
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EventComponent);
});