define("donor-elf-web/controllers/funds/fund/dashboard/total-and-expecting", ["exports", "@ember/controller", "@ember/service", "donor-elf-web/lib/date-utils"], function (_exports, _controller, _service, _dateUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    moment
  } = window;
  let DashboardTotalAndExpectingController = (_class = class DashboardTotalAndExpectingController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fundsService", _descriptor, this);

      _defineProperty(this, "model", void 0);
    }

    get goal() {
      return this.model.goal;
    }

    get goalData() {
      var _this$model$goal;

      return (_this$model$goal = this.model.goal) === null || _this$model$goal === void 0 ? void 0 : _this$model$goal.goalData;
    }

    get timeLeftToRaiseLabel() {
      let goalDate = _dateUtils.default.deserializeDateOnly(this.goalData.goal_date);

      let duration = moment.duration(moment().diff(goalDate));
      let label = null;

      if (Math.abs(duration.asMonths()) >= 2) {
        label = `${Math.abs(Math.round(duration.asMonths()))} months`;
      } else if (Math.abs(duration.asWeeks()) >= 2) {
        label = `${Math.abs(Math.round(duration.asWeeks()))} weeks`;
      } else {
        label = `${Math.abs(Math.round(duration.asDays()))} days`;
      }

      if (duration.asDays() < 0) {
        label = `${label} left`;
      } else {
        label = `${label} overdue`;
      }

      return label;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = DashboardTotalAndExpectingController;
});