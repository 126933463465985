define("donor-elf-web/templates/funds/fund/goal/annual", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "du+zefqf",
    "block": "[[[8,[39,0],[[4,[38,1],[[30,0,[\"templateWillDestroy\"]]],null]],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],null,[[\"@changeset\",\"@property\",\"@label\",\"@focusOnInsert\",\"@showValidationErrors\"],[[30,1,[\"changeset\"]],\"annualGoal\",\"Annual Goal\",true,[30,0,[\"showValidationErrors\"]]]],null],[1,\"\\n  \"],[10,0],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-warning\"],[24,4,\"button\"],[4,[38,3],[\"click\",[30,0,[\"save\"]]],null],[12],[1,\"\\n      Save\\n    \"],[13],[1,\"\\n    \"],[8,[39,4],[[24,0,\"btn btn-link ml-2_5\"]],[[\"@route\"],[\"funds.fund.dashboard\"]],[[\"default\"],[[[[1,\"\\n      Cancel\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"error\"]],[[[1,\"    \"],[8,[39,6],[[24,0,\"mt-3\"]],[[\"@error\",\"@useLeftBorderCard\"],[[30,0,[\"error\"]],true]],null],[1,\"\\n\"]],[]],null]],[]]]]]],[\"@model\"],false,[\"form-card\",\"will-destroy\",\"form-group\",\"on\",\"link-to\",\"if\",\"generic-error\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/goal/annual.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});