define("donor-elf-web/components/de-card", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    attrs
    - noBorderSmDown
      - don't show the border on sm and down
      - default is true
    - slimCardBlock
      - decrease padding on the card block
      - default is false
  
  */
  var _default = _component.default.extend({
    classNames: ['card', 'de-card'],
    classNameBindings: ['noBorderSmDown:de-card-no-border-sm-down', 'slimCardBlock:de-slim-card-block'],
    noBorderSmDown: true,
    slimCardBlock: false
  });

  _exports.default = _default;
});