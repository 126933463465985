define("donor-elf-web/templates/funds/fund/import/intervarsity-prospects/processing", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "HQ6Q1Spg",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[33,2],[[[1,\"    \"],[10,\"h4\"],[12],[1,\"Upload Error\"],[13],[1,\"\\n    \"],[10,0],[14,0,\"alert alert-danger mb-3\"],[12],[1,\"\\n      Whoops! An error occurred when we were processing the file. Sorry about that!\\n    \"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      Do you mind sending us an email to let us know you weren't able to\\n      upload the Prospects file. And please attach the file to the email.\\n      We'll then take a look and fix the upload.\\n    \"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      Please send the email to \"],[10,3],[14,6,\"mailto:support@donorelf.com\"],[12],[1,\"support@donorelf.com\"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \\n    \"],[8,[39,3],[[24,0,\"btn btn-warning mt-3\"]],[[\"@route\",\"@model\"],[\"funds.fund.dashboard\",[33,4,[\"selectedFund\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n      Dashboard\\n    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"h4\"],[12],[1,\"Uploading\"],[13],[1,\"\\n    \"],[1,[28,[35,5],null,[[\"file\",\"onUploadComplete\",\"onUploadError\",\"class\"],[[33,6,[\"selectedFile\"]],[30,0,[\"handleS3UploadComplete\"]],[30,0,[\"handleS3UploadError\"]],\"mb-5\"]]]],[1,\"\\n\\n    \"],[10,\"h4\"],[12],[1,\"Processing\"],[13],[1,\"\\n    \"],[1,[28,[35,7],null,[[\"check\",\"onError\",\"onComplete\"],[[30,0,[\"checkFundUploadProgress\"]],[30,0,[\"onFundUploadError\"]],[30,0,[\"onFundUploadComplete\"]]]]]],[1,\"\\n\"]],[]]]],[]]]]]],[],false,[\"form-card\",\"if\",\"hasError\",\"link-to\",\"fundsService\",\"s3-upload-progress\",\"model\",\"fund-upload-progress\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/import/intervarsity-prospects/processing.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});