define("donor-elf-web/templates/funds/fund/more/mailchimp-loading", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "vI17Jq64",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[8,[39,1],[[24,0,\"mb-5\"]],null,null],[1,\"\\n  \"],[1,[34,2]],[1,\"\\n\"]],[]]]]]],[],false,[\"de-form-card\",\"mailchimp-header\",\"de-loading\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/more/mailchimp-loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});