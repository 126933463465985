define("donor-elf-web/components/select-contact", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/utils", "rsvp", "@ember/runloop", "@ember/service"], function (_exports, _computed, _component, _object, _utils, _rsvp, _runloop, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    attrs
      - selectedContactId (optional)
      - placeholder (optional) (default: 'Choose contact')
      - focusOnInsert - if the input box should receive focus when it's inserted
      - disabled
      - showAsInvalid
        - if true, then outlines the select box in red
      - showBlankContact
        - Whether to show a blank contact
        - This allows the user to 'unselect' a selected contact
        - default is false
      - blankContactLabel
        - Label for blank contact
        - default is 'None'
      - offerToCreateNewContact (default is false)
        - if an exact match isn't found, offer to create the contact
      - triggerClass (optional)
        - the class to add to the trigger element
      - onclose (optional)
        - the action to call when the power select is closed
      - onSelection
        - action that is fired when a user makes a selection, even if it's the same option
  */
  var _default = _component.default.extend({
    contactsCache: (0, _service.inject)(),
    classNames: ['select-contact'],
    classNameBindings: ['showAsInvalid'],
    contacts: (0, _computed.alias)('contactsCache.sortedContacts'),
    selectedContactId: null,
    selectedContact: (0, _object.computed)('selectedContactId', function () {
      return (0, _object.get)(this, 'contactsCache').getById((0, _object.get)(this, 'selectedContactId'));
    }),
    placeholder: 'Choose contact',
    showBlankContact: false,
    blankContactLabel: 'None',
    disabled: false,
    triggerClass: '',
    initialContactsToShow: 20,
    offerToCreateNewContact: false,
    showAsInvalid: false,
    initialContacts: (0, _object.computed)('contacts.[]', 'showBlankContact', 'initialContactsToShow', function () {
      let contacts = (0, _object.get)(this, 'contacts');
      let initialContacts = [];

      if ((0, _object.get)(this, 'showBlankContact')) {
        initialContacts.push({
          type: 'blank'
        });
      }

      initialContacts = initialContacts.concat(contacts.slice(0, (0, _object.get)(this, 'initialContactsToShow')));

      if (contacts.length > initialContacts.length) {
        initialContacts.push({
          type: 'more'
        });
      }

      return initialContacts;
    }),
    didInsertElement: function () {
      this._super(...arguments);

      if (this.focusOnInsert) {
        setTimeout(() => {
          this.element.querySelector('.ember-power-select-trigger').focus();
        }, 50);
      }
    },

    focusComesFromOutside(e) {
      if (e.target.classList.contains('ember-basic-dropdown-trigger--below')) {
        return false;
      }

      let blurredEl = e.relatedTarget;
      return (0, _utils.isEmpty)(blurredEl) || !blurredEl.classList.contains('ember-power-select-search-input');
    },

    // TODO: Search on multiple words
    searchContacts: (0, _object.action)(function (term) {
      let contacts = (0, _object.get)(this, 'contacts');

      if ((0, _utils.isEmpty)(term)) {
        return (0, _object.get)(this, 'initialContacts');
      }

      let termLowerCase = term.trim().toLowerCase();
      let filteredContacts = contacts.filter(contact => {
        return (0, _object.get)(contact, 'nameLowerCase').indexOf(termLowerCase) >= 0;
      }); // If there isn't an exact match, then give the option to create a new contact

      if ((0, _object.get)(this, 'offerToCreateNewContact') && filteredContacts.find(contact => {
        return (0, _object.get)(contact, 'nameLowerCase') === termLowerCase;
      }) == null) {
        filteredContacts.push({
          type: 'create',
          name: `Create '${term}' as a new contact`
        });
      }

      return filteredContacts;
    }),
    handleFocused: (0, _object.action)(function (select, e) {
      if (!e.target.classList.contains('ember-power-select-trigger')) {
        return;
      }
      /* TODO: implement this
      let focusingOnField = get(this, 'focusingOnField');
      if (focusingOnField != null && !e.target.classList.contains(`field-${focusingOnField}`)) {
        return;
      }
      */


      if (this.focusComesFromOutside(e)) {
        _runloop.run.next(() => {
          select.actions.open();
        });
      }
    }),
    handleClosed: (0, _object.action)(function () {
      if (this.onclose) {
        this.onclose();
      }
    }),
    handleBuildSelection: (0, _object.action)(function (option, select) {
      if ((0, _utils.isEqual)(option, select.selected)) {
        if (this.onSelection) {
          this.onSelection(this.selectedContactId, {
            newContact: false
          });
        }
      }

      return option;
    }),
    selectContact: (0, _object.action)(function (contact, select) {
      let contactType = (0, _object.get)(contact, 'type');

      if (contactType === 'more') {
        // do nothing
        return;
      }

      let contactId = (0, _object.get)(contact, 'id') || null;
      let newContact = false;

      let createContactPromise = _rsvp.default.resolve();

      if (contactType === 'create') {
        let name = (0, _object.get)(contact, 'name').match(/^Create '(.*)' as a new contact$/)[1];
        let newContact = (0, _object.get)(this, 'contactsCache').createContact({
          name: name
        });
        createContactPromise = newContact.save().then(() => {
          contactId = (0, _object.get)(newContact, 'id');
          newContact = true;
        });
      }

      createContactPromise.then(() => {
        let onSelection = (0, _object.get)(this, 'onSelection');

        if (onSelection) {
          onSelection(contactId, {
            newContact: newContact
          });
        }

        _runloop.run.next(() => {
          select.actions.close();
        });
      });
    })
  });

  _exports.default = _default;
});