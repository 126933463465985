define("donor-elf-web/lib/contact-duplicate-utils", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.populateMergeContactFromContacts = populateMergeContactFromContacts;
  _exports.addressFields = _exports.MergeDifferences = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class MergeDifferences {
    constructor(contact1, contact2) {
      _defineProperty(this, "generic", void 0);

      _defineProperty(this, "addressFieldsAreSame", void 0);

      this.generic = findPropertyDifferences(contact1, contact2, genericFields);
      this.addressFieldsAreSame = findPropertyDifferences(contact1, contact2, addressFields).length === 0;
    }

  }

  _exports.MergeDifferences = MergeDifferences;

  function findPropertyDifferences(contact1, contact2, propertyInfos) {
    let differences = [];
    propertyInfos.forEach(info => {
      if ((0, _utils.isPresent)(contact1[info.property]) || (0, _utils.isPresent)(contact2[info.property])) {
        if (contact1[info.property] !== contact2[info.property]) {
          differences.push({
            property: info.property,
            label: info.label,
            type: info.type
          });
        }
      }
    });
    return differences;
  }

  function populateMergeContactFromContacts(args) {
    args.mergeContact.keepContactId = args.keepContact.id;
    args.mergeContact.mergeContactId = args.otherContact.id;
    genericFields.forEach(field => {
      let keepContactValue = args.keepContact[field.property];
      let otherContactValue = args.otherContact[field.property];
      let value = (0, _utils.isPresent)(keepContactValue) ? keepContactValue : otherContactValue;
      args.mergeContact[field.property] = value;
    });
    let contactWithAddressToKeep = args.keepContact;

    if ((0, _utils.isEmpty)(contactWithAddressToKeep.address1) && (0, _utils.isPresent)(args.otherContact.address1)) {
      contactWithAddressToKeep = args.otherContact;
    }

    addressFields.forEach(field => {
      args.mergeContact[field.property] = contactWithAddressToKeep[field.property];
    });
    let pledgesToKeep = [];

    if (args.moreInfo.hasManualAndNotManualPledges) {
      pledgesToKeep = args.moreInfo.pledges.filter(x => !x.isManual);
    } else {
      pledgesToKeep = args.moreInfo.pledges.filter(x => x.contactId === args.keepContact.id || args.moreInfo.journalDecisionByPledgeId[x.id] != null);
    }

    if (pledgesToKeep.length === 0) {
      pledgesToKeep = args.moreInfo.pledges.filter(x => x.contactId === args.otherContact.id);
    }

    args.mergeContact.pledgeItemsToKeep = pledgesToKeep.map(x => {
      return {
        id: x.id
      };
    });
  }

  let genericFields = [{
    property: 'name',
    label: 'Name'
  }, {
    property: 'mailingName',
    label: 'Mailing Name'
  }, {
    property: 'firstName',
    label: 'First Name'
  }, {
    property: 'lastName',
    label: 'Last Name'
  }, {
    property: 'spouseFirstName',
    label: 'Spouse First Name'
  }, {
    property: 'spouseLastName',
    label: 'Spouse Last Name'
  }, {
    property: 'deceased',
    label: 'Deceased',
    type: 'boolean'
  }, {
    property: 'spouseDeceased',
    label: 'Spouse is Deceased',
    type: 'boolean'
  }];
  let addressFields = [{
    property: 'address1',
    label: 'Street 1'
  }, {
    property: 'address2',
    label: 'Street 2'
  }, {
    property: 'city',
    label: 'City'
  }, {
    property: 'state',
    label: 'State'
  }, {
    property: 'zip',
    label: 'Zip'
  }, {
    property: 'country',
    label: 'Country'
  }];
  _exports.addressFields = addressFields;
});