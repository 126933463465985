define("donor-elf-web/routes/index", ["exports", "@ember/routing/route", "@ember/object", "@ember/service"], function (_exports, _route, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    session: (0, _service.inject)(),

    redirect() {
      if ((0, _object.get)(this, 'session.isAuthenticated')) {
        this.transitionTo('funds.fund', 'selected-fund');
      } else {
        this.transitionTo('login');
      }
    }

  });

  _exports.default = _default;
});