define("donor-elf-web/templates/funds/fund/campaigns/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "kmE5P4NJ",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"d-flex\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"ml-auto\"],[12],[1,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-sm btn-warning mb-3\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"handleAddCampaign\"]]],null],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"plus\"],null]],[1,\"\\n        Add Campaign\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"table\"],[14,0,\"table border-bottom\"],[12],[1,\"\\n    \"],[10,\"thead\"],[12],[1,\"\\n      \"],[10,\"tr\"],[12],[1,\"\\n        \"],[10,\"th\"],[12],[1,\"Name\"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"campaigns\"]]],null]],null],null,[[[1,\"        \"],[10,\"tr\"],[12],[1,\"\\n          \"],[10,\"td\"],[12],[1,\"\\n            \"],[8,[39,5],null,[[\"@route\",\"@model\"],[\"funds.fund.campaigns.campaign\",[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n              \"],[1,[30,1,[\"name\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,5],[[24,0,\"btn btn-sm btn-brand\"]],[[\"@route\"],[\"funds.fund.campaigns.approve-transactions\"]],[[\"default\"],[[[[1,\"\\n    Approve Transactions\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[\"campaign\"],false,[\"card\",\"on\",\"svg-jar\",\"each\",\"-track-array\",\"link-to\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/campaigns/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});