define("donor-elf-web/validations/coach/contact-ask", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    askAmount: (0, _validators.validateNumber)({
      gt: 0,
      integer: true,
      allowBlank: false,
      message: 'Must be a positive whole number'
    })
  };
  _exports.default = _default;
});