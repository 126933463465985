define("donor-elf-web/modifiers/on-enter", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // {{on-enter this.someFunction}}
  var _default = (0, _emberModifier.modifier)(function onEnter(element, [action]) {
    let handler = generateOnKeyDownHandler(action);
    element.addEventListener('keydown', handler);
    return () => {
      element.removeEventListener('keydown', handler);
    };
  });

  _exports.default = _default;

  function generateOnKeyDownHandler(action) {
    return e => {
      if (e.key === 'Enter') {
        action(e);
      }
    };
  }
});