define("donor-elf-web/controllers/funds/fund/journals/mock-summaries", ["exports", "@ember/controller"], function (_exports, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    moment
  } = window;

  class JournalsSummariesController extends _controller.default {
    get journalSummaries() {
      let summaries = []; // Jane

      let startDate = moment().add(-7, 'weeks').toDate();
      let goalDate = moment(startDate).add(10, 'weeks').toDate();
      summaries.push({
        name: 'Jane Doe',
        goalAmount: 3500,
        goalDate: goalDate,
        startDate: startDate,
        intervalItems: [{
          amount: 50
        }, {
          amount: 200
        }, {
          amount: 100
        }, {
          amount: 500
        }, {
          amount: 450
        }, {
          amount: 215
        }, {
          amount: 400
        }]
      }); // John

      startDate = moment().add(-5, 'weeks').toDate();
      goalDate = moment(startDate).add(10, 'weeks').toDate();
      summaries.push({
        name: 'John Smith',
        goalAmount: 3000,
        goalDate: goalDate,
        startDate: startDate,
        intervalItems: [{
          amount: 50
        }, {
          amount: 500
        }, {
          amount: 400
        }, {
          amount: 550
        }]
      }); // Tom

      startDate = moment().add(-14, 'weeks').toDate();
      goalDate = moment(startDate).add(13, 'weeks').toDate();
      summaries.push({
        name: 'Tom Miller',
        goalAmount: 4000,
        goalDate: goalDate,
        startDate: startDate,
        intervalItems: [{
          amount: 50
        }, {
          amount: 75
        }, {
          amount: 200
        }, {
          amount: 100
        }, {
          amount: 200
        }, {
          amount: 150
        }, {
          amount: 100
        }, {
          amount: 150
        }, {
          amount: 300
        }, {
          amount: 350
        }, {
          amount: 600
        }, {
          amount: 450
        }, {
          amount: 350
        }, {
          amount: 400
        }]
      });
      return summaries;
    }

  }

  _exports.default = JournalsSummariesController;
});