define("donor-elf-web/components/form/underline/text", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/utils"], function (_exports, _component, _templateFactory, _component2, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
      <input
      value={{get @changeset @property}}
      ...attributes
      class='form-underline-input'
      {{on 'input' this.handleInput}}
      {{on 'change' this.handleChange}}
    />
    {{#if this.showError}}
      <div class='text-danger tw-text-sm'>
        {{this.errorMessage}}
      </div>
    {{/if}}
  */
  {
    "id": "54J6b/fS",
    "block": "[[[1,\"  \"],[11,\"input\"],[16,2,[28,[37,0],[[30,1],[30,2]],null]],[17,3],[24,0,\"form-underline-input\"],[4,[38,1],[\"input\",[30,0,[\"handleInput\"]]],null],[4,[38,1],[\"change\",[30,0,[\"handleChange\"]]],null],[12],[13],[1,\"\\n\"],[41,[30,0,[\"showError\"]],[[[1,\"    \"],[10,0],[14,0,\"text-danger tw-text-sm\"],[12],[1,\"\\n      \"],[1,[30,0,[\"errorMessage\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[\"@changeset\",\"@property\",\"&attrs\"],false,[\"get\",\"on\",\"if\"]]",
    "moduleName": "donor-elf-web/components/form/underline/text.hbs",
    "isStrictMode": false
  });

  let FormUnderlineTextComponent = (_class = class FormUnderlineTextComponent extends _component2.default {
    handleInput(e) {
      (0, _object.set)(this.args.changeset, this.args.property, e.target.value);
    }

    handleChange(e) {
      var _this$args$onChange, _this$args;

      (_this$args$onChange = (_this$args = this.args).onChange) === null || _this$args$onChange === void 0 ? void 0 : _this$args$onChange.call(_this$args, e.target.value);
    }

    get errorMessage() {
      let validationErrors = (0, _object.get)(this.args.changeset, `error.${this.args.property}.validation`) || [];
      return validationErrors.join(', ');
    }

    get showError() {
      return this.args.showValidationErrors && (0, _utils.isPresent)(this.errorMessage);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "handleInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleChange"), _class.prototype)), _class);
  _exports.default = FormUnderlineTextComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormUnderlineTextComponent);
});