define("donor-elf-web/routes/funds/fund/contacts/upload", ["exports", "@ember/routing/route", "@ember/object", "@ember/service"], function (_exports, _route, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    navbarSettings: (0, _service.inject)(),
    abilities: (0, _service.inject)(),
    uploadContacts: (0, _service.inject)(),

    beforeModel(transition) {
      if ((0, _object.get)(this, 'abilities').noAbility('edit_contact')) {
        transition.abort();
      }

      (0, _object.set)(this, 'navbarSettings.title', 'Upload Contacts');
    },

    model() {
      (0, _object.get)(this, 'uploadContacts').reset();
      return {
        currentStep: ''
      };
    }

  });

  _exports.default = _default;
});