define("donor-elf-web/routes/admin/fund-groups/fund-group/delete", ["exports", "@ember/routing/route", "@ember/object", "@ember/service"], function (_exports, _route, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    navbarSettings: (0, _service.inject)(),

    model() {
      (0, _object.set)(this, 'navbarSettings.title', 'Delete Fund Group');
      let fundGroupModel = this.modelFor('admin.fund-groups.fund-group');
      let fundGroup = (0, _object.get)(fundGroupModel, 'fundGroup');
      return {
        record: fundGroup
      };
    }

  });

  _exports.default = _default;
});