define("donor-elf-web/routes/admin/users/show/info", ["exports", "@ember/routing/route", "@ember/object", "rsvp", "@ember/service"], function (_exports, _route, _object, _rsvp, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    fundsService: (0, _service.inject)(),
    storeCache: (0, _service.inject)(),

    beforeModel() {
      return (0, _object.get)(this, 'fundsService').confirmValidFundToken();
    },

    model() {
      let user = this.modelFor('admin.users.show');
      let selectedFundId = user.selectedFundId;
      let selectedFund = null;

      if (selectedFundId) {
        selectedFund = this.store.findRecord('admin/fund', selectedFundId);
      }

      return _rsvp.default.hash({
        user,
        selectedFund
      });
    }

  });

  _exports.default = _default;
});