define("donor-elf-web/models/coaching/individual", ["exports", "ember-data", "@ember/object/computed", "@ember/object"], function (_exports, _emberData, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany
  } = _emberData.default;
  const {
    moment
  } = window;

  var _default = Model.extend({
    name: attr('string'),
    startDate: attr('date-only'),
    goalDate: attr('date-only'),
    filledOut: attr('boolean'),
    frequencyTimespan: attr('string'),
    frequencyNumber: attr('number'),
    staticInfo: attr(),
    sections: attr(),
    coachNotes: attr('string'),
    // only used when creating a new record from a template
    fromTemplateId: attr('string'),
    reports: hasMany('coaching/report', {
      async: false
    }),
    savedReports: (0, _computed.filter)('reports.@each.isNew', function (x) {
      return !(0, _object.get)(x, 'isNew');
    }),
    sortReportsBy: Object.freeze(['startDate:asc']),
    sortedReports: (0, _computed.sort)('savedReports', 'sortReportsBy'),
    previousReport: function (report) {
      let sortedReports = (0, _object.get)(this, 'sortedReports');
      let reportIndex = sortedReports.indexOf(report);

      if (reportIndex > 0) {
        return sortedReports[reportIndex - 1];
      } else {
        return null;
      }
    },
    sortReportsDescBy: Object.freeze(['startDate:desc']),
    sortedReportsDesc: (0, _computed.sort)('savedReports', 'sortReportsDescBy'),
    // number of reporting periods from startDate to goalDate
    reportingPeriodsToGoalDate: (0, _object.computed)('startDate', 'goalDate', function () {
      let {
        startDate,
        goalDate,
        frequencyTimespan,
        frequencyNumber
      } = (0, _object.getProperties)(this, 'startDate', 'goalDate', 'frequencyTimespan', 'frequencyNumber');
      let reportingPeriods = 0;
      let goalDateString = moment(goalDate).format('YYYY-MM-DD');
      let date = moment(startDate);

      while (date.format('YYYY-MM-DD') < goalDateString) {
        reportingPeriods += 1;
        date.add(frequencyNumber, frequencyTimespan);
      }

      return reportingPeriods;
    }),
    // returns the section index and field index for each field
    // { fieldId: { sectionIndex, fieldIndex } }
    fieldsSectionAndIndex: (0, _object.computed)('sections', function () {
      let fields = {};
      ((0, _object.get)(this, 'sections') || []).forEach((section, sectionIndex) => {
        (0, _object.get)(section, 'fields').forEach((field, fieldIndex) => {
          fields[(0, _object.get)(field, 'id')] = {
            sectionIndex,
            fieldIndex
          };
        });
      });
      return fields;
    })
  });

  _exports.default = _default;
});