define("donor-elf-web/controllers/funds/fund/journals/journal/edit", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "@ember/service", "ember-changeset", "ember-changeset-validations", "donor-elf-web/validations/journal", "donor-elf-web/validations/journal-goal"], function (_exports, _controller, _object, _tracking, _service, _emberChangeset, _emberChangesetValidations, _journal, _journalGoal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let JournalEditController = (_class = class JournalEditController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fundsService", _descriptor, this);

      _initializerDefineProperty(this, "journalService", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "error", _descriptor4, this);

      _initializerDefineProperty(this, "saving", _descriptor5, this);

      _initializerDefineProperty(this, "showValidationErrors", _descriptor6, this);

      _initializerDefineProperty(this, "journalChangeset", _descriptor7, this);

      _initializerDefineProperty(this, "goalChangesets", _descriptor8, this);
    }

    get showDecisionsRequireGift() {
      return !this.model.template.hideDecisionsRequireGift && !this.model.template.decisionsRequireGift;
    }

    templateDidInsert() {
      this.error = null;
      this.saving = false;
      this.showValidationErrors = false;
      this.journalChangeset = new _emberChangeset.default(this.model.journalInfo.journal, (0, _emberChangesetValidations.default)(_journal.default), _journal.default);
      this.goalChangesets = this.model.journalInfo.goals.map(x => new _emberChangeset.default(x, (0, _emberChangesetValidations.default)(_journalGoal.default), _journalGoal.default));
    }

    async handleSave() {
      this.showValidationErrors = true;
      await this.journalChangeset.validate();

      for (let i = 0; i < this.goalChangesets.length; i++) {
        await this.goalChangesets[i].validate();
      }

      if (!this.journalChangeset.get('isValid')) {
        return;
      }

      if (this.goalChangesets.find(x => !x.get('isValid'))) {
        return;
      }

      this.showValidationErrors = false;
      this.saving = true;

      try {
        await this.fundsService.confirmValidFundToken();
        await this.journalChangeset.save();

        for (let i = 0; i < this.goalChangesets.length; i++) {
          await this.goalChangesets[i].save();
        }

        this.router.transitionTo('funds.fund.journals.journal.index');
      } catch (error) {
        this.error = error;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "journalService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "error", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "saving", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "showValidationErrors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "journalChangeset", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "goalChangesets", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleSave", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleSave"), _class.prototype)), _class);
  _exports.default = JournalEditController;
});