define("donor-elf-web/models/assign-tasks/task", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    fundId: attr('string'),
    userId: attr('string'),
    contact: belongsTo('assign-tasks/contact', {
      async: false
    }),
    summary: attr('string'),
    notes: attr('string'),
    dueAt: attr('date-only'),
    completedAt: attr('date-only')
  });

  _exports.default = _default;
});