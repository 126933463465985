define("donor-elf-web/helpers/blur-target", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function blurTarget([handler]) {
    return function (...args) {
      args[0].target.blur();
      if (handler) handler(...args);
    };
  }

  var _default = (0, _helper.helper)(blurTarget);

  _exports.default = _default;
});