define("donor-elf-web/helpers/any-present", ["exports", "@ember/component/helper", "@ember/utils"], function (_exports, _helper, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.anyPresent = anyPresent;
  _exports.default = void 0;

  function anyPresent(params)
  /*hash*/
  {
    for (let i = 0; i < params.length; i++) {
      if ((0, _utils.isPresent)(params[i])) {
        return true;
      }
    }

    return false;
  }

  var _default = (0, _helper.helper)(anyPresent);

  _exports.default = _default;
});