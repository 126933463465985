define("donor-elf-web/lib/array-utils", ["exports", "@ember/object", "@ember/utils"], function (_exports, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sortByProperties = sortByProperties;
  _exports.indexArrayByPropertyName = indexArrayByPropertyName;
  _exports.groupArrayBy = groupArrayBy;

  /*
    Provides the same functionality as { sort } = '@ember/object/computed' but
    in a non-decorator property
  
    sortProperties = ['name:asc', 'age']
  */
  function sortByProperties(items = [], sortProperties) {
    let normalizedSortProperties = normalizeSortProperties(sortProperties);

    if (normalizedSortProperties.length === 0) {
      return items.slice();
    } else {
      return sortByNormalizedSortProperties(items, normalizedSortProperties);
    }
  }

  function normalizeSortProperties(sortProperties) {
    return sortProperties.map(p => {
      let [prop, direction] = p.split(':');
      direction = direction || 'asc';
      return [prop, direction];
    });
  }

  function sortByNormalizedSortProperties(items, normalizedSortProperties) {
    return items.slice().sort((itemA, itemB) => {
      for (let i = 0; i < normalizedSortProperties.length; i++) {
        let [prop, direction] = normalizedSortProperties[i]; // @ts-expect-error

        let result = (0, _utils.compare)((0, _object.get)(itemA, prop), (0, _object.get)(itemB, prop));

        if (result !== 0) {
          return direction === 'desc' ? -1 * result : result;
        }
      }

      return 0;
    });
  }

  function indexArrayByPropertyName(items, propertyName) {
    let byPropertyName = {};

    for (let item of items) {
      if (item[propertyName] != null) {
        byPropertyName[item[propertyName]] = item;
      }
    }

    return byPropertyName;
  }

  function groupArrayBy(items, groupBy) {
    let response = {};

    for (let item of items) {
      let groupByVal = groupBy(item);

      if (groupByVal != null) {
        if (response[groupByVal] == null) {
          response[groupByVal] = [];
        }

        response[groupByVal].push(item);
      }
    }

    return response;
  }
});