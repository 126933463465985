define("donor-elf-web/controllers/admin/users/show/funds/add", ["exports", "@ember/controller", "@ember/object", "rsvp", "@ember/service"], function (_exports, _controller, _object, _rsvp, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    store: (0, _service.inject)(),
    storeCache: (0, _service.inject)(),
    saving: false,
    errorMessage: null,

    reset() {
      (0, _object.setProperties)(this, {
        saving: false,
        errorMessage: null
      });
    },

    actions: {
      save(selectedItems) {
        if ((0, _object.get)(selectedItems, 'length') === 0) {
          (0, _object.set)(this, 'errorMessage', 'You must select at least one fund for the user');
          return;
        } else {
          (0, _object.set)(this, 'errorMessage', null);
        }

        (0, _object.set)(this, 'saving', true);
        let store = (0, _object.get)(this, 'store');
        let user = (0, _object.get)(this, 'model.user');
        let previousUserFunds = (0, _object.get)(this, 'model.userFunds');
        let savePromises = []; // Save new funds

        selectedItems.forEach(item => {
          if ((0, _object.get)(item, 'userFund') == null) {
            let newUserFund = store.createRecord('admin/user-fund', {
              userId: (0, _object.get)(user, 'id'),
              fundId: (0, _object.get)(item, 'fund.id')
            });
            savePromises.push(newUserFund.save());
          }
        }); // Remove previous funds that are no longer selected

        previousUserFunds.forEach(prevUserFund => {
          let prevUserFundId = (0, _object.get)(prevUserFund, 'id');
          let selectedItem = selectedItems.find(item => {
            return (0, _object.get)(item, 'userFund.id') === prevUserFundId;
          });

          if (selectedItem == null) {
            savePromises.push(prevUserFund.destroyRecord());
          }
        });

        _rsvp.default.allSettled(savePromises).then(() => {
          (0, _object.set)(this, 'saving', false);
          (0, _object.get)(this, 'storeCache').breakFindAllCache('admin/user-fund');
          this.transitionToRoute('admin.users.show');
        });
      }

    }
  });

  _exports.default = _default;
});