define("donor-elf-web/controllers/application", ["exports", "@ember/object/computed", "@ember/controller", "@ember/object", "@ember/runloop", "@ember/service", "donor-elf-web/config/environment"], function (_exports, _computed, _controller, _object, _runloop, _service, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    abilities: (0, _service.inject)(),
    addSomethingModal: (0, _service.inject)(),
    contactsCache: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    customReports: (0, _service.inject)(),
    flashMessages: (0, _service.inject)(),
    fundGoalService: (0, _service.inject)(),
    fundsService: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),
    media: (0, _service.inject)(),
    router: (0, _service.inject)(),
    session: (0, _service.inject)(),
    settings: (0, _service.inject)(),
    queryParams: ['loginToken', 'loginEmail'],
    loginToken: null,
    loginEmail: null,
    currentMenuLabel: (0, _computed.alias)('navbarSettings.currentMenuLabel'),
    canEditContact: (0, _computed.alias)('abilities.abilities.edit_contact'),
    showTopNavbar: (0, _computed.alias)('media.isMediumUp'),
    canViewAdminArea: (0, _computed.alias)('abilities.abilities.view_admin_area'),
    canViewClassicSite: false,
    //alias('abilities.abilities.view_classic_site'),
    hasYtdGoalType: (0, _computed.alias)('fundGoalService.hasYtdGoalType'),
    hasOmsMonthlySummaryCustomReport: (0, _object.computed)(function () {
      var _this$settings$fundSe, _this$settings$fundSe2;

      return (_this$settings$fundSe = this.settings.fundSettings) === null || _this$settings$fundSe === void 0 ? void 0 : (_this$settings$fundSe2 = _this$settings$fundSe.customReports) === null || _this$settings$fundSe2 === void 0 ? void 0 : _this$settings$fundSe2.includes('oms-monthly-summary');
    }),
    apiUrl: _environment.default.apiUrl,
    actions: {
      handleContactSelected(contact) {
        this.transitionToRoute('funds.fund.contacts.contact', (0, _object.get)(contact, 'id'));
      },

      navbarBrandClicked() {
        var _this$session;

        if ((_this$session = this.session) !== null && _this$session !== void 0 && _this$session.isAuthenticated) {
          if (this.fundsService.selectedFund) {
            this.transitionToRoute('funds.fund.dashboard', this.fundsService.selectedFund.id);
          } else {
            this.transitionToRoute('funds.fund', 'selected-fund');
          }
        }
      },

      changeMenuLabelTo(menuLabel, e) {
        (0, _object.set)(this, 'navbarSettings.currentMenuLabel', menuLabel);
        e.preventDefault();
        return false;
      },

      handleMenuClosed() {
        (0, _runloop.later)(() => {
          this.navbarSettings.resetCurrentMenuLabel(); // I know this is a hack doing DOM manipulation in a controller, but it shouldn't
          // have any side effects and it's not worth creating a component

          let bmMenu = document.querySelector('.bm-menu');

          if (bmMenu) {
            bmMenu.scrollTop = 0;
          }
        }, 500);
      }

    }
  });

  _exports.default = _default;
});