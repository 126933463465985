define("donor-elf-web/templates/components/top-navbar-coaching", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "kZhJn9AO",
    "block": "[[[10,0],[14,0,\"nav-item\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"navbar-coach-reports-title\"],[12],[1,\"\\n    Coach Reports\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[33,1,[\"hasMultipleCoachingIndividuals\"]],[[[1,\"  \"],[10,0],[14,0,\"nav-item ml-auto\"],[12],[1,\"\\n\"],[6,[39,2],null,[[\"class\",\"route\"],[\"nav-link pl-0\",\"coaching.individuals.index\"]],[[\"default\"],[[[[1,\"      All Coaching Individuals\\n\"]],[]]]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"currentUser\",\"link-to\"]]",
    "moduleName": "donor-elf-web/templates/components/top-navbar-coaching.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});