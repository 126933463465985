define("donor-elf-web/components/delete-form", ["exports", "@ember/component", "@ember/object", "@ember/service"], function (_exports, _component, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    attrs
    - cancelRoute
      - the route name to go if cancel is clicked
      - if blank, then window.history.back() is used
    - deleteTask
      - the task to run when save is clicked
  */
  var _default = _component.default.extend({
    router: (0, _service.inject)(),
    cancelRoute: null,
    deleteTask: null,
    // pointer to deleteTask that is performing
    // this is used so it can be cancelled if cancel is clicked
    deleteTaskInstance: null,

    willDestroyElement() {
      this._super(...arguments);

      let deleteTaskInstance = (0, _object.get)(this, 'deleteTaskInstance');

      if (deleteTaskInstance != null) {
        deleteTaskInstance.cancel();
      }
    },

    actions: {
      delete() {
        let deleteTaskInstance = (0, _object.get)(this, 'deleteTask').perform();
        (0, _object.set)(this, 'deleteTaskInstance', deleteTaskInstance);
      },

      cancel() {
        // TODO: replace if/else statement in HBS once Ember 2.15 lands and components
        // can use the route service
        let cancelRoute = (0, _object.get)(this, 'cancelRoute');

        if (cancelRoute) {//get(this, 'router').transitionTo(cancelRoute);
        } else {
          window.history.back();
        }
      }

    }
  });

  _exports.default = _default;
});