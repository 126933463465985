define("donor-elf-web/components/filter-item", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
  attrs
    - filterItem
    - filterSettings = [{
      prop:  '',
      label: '',
      type:  string|number|boolean|select,
      options: [{ label: '', val: '' }]
        - only applies when type is 'select'
    }]
    - removeFilter
      - action
  
  */
  const operationOptions = {
    'blank': {
      label: '',
      val: ''
    },
    '===': {
      label: 'equals',
      val: '==='
    },
    '>': {
      label: 'greater than',
      val: '>'
    },
    '>=': {
      label: 'greater or equal to',
      val: '>='
    },
    '<': {
      label: 'less than',
      val: '<'
    },
    '<=': {
      label: 'less or equal to',
      val: '<='
    },
    '=~': {
      label: 'contains',
      val: '=~'
    },
    '[]': {
      label: 'is',
      val: '[]'
    }
  };
  const filterOptionsByType = {
    'string': [operationOptions['blank'], operationOptions['=~'], operationOptions['===']],
    'number': [operationOptions['blank'], operationOptions['==='], operationOptions['>'], operationOptions['>='], operationOptions['<'], operationOptions['<=']],
    'boolean': [operationOptions['blank'], operationOptions['===']],
    'select': [operationOptions['[]']]
  };

  var _default = _component.default.extend({
    filterItem: {},
    filterSettings: null,
    filterSettingsByProp: (0, _object.computed)('filterSettings.[]', function () {
      let byProp = {};
      (0, _object.get)(this, 'filterSettings').forEach(setting => {
        byProp[(0, _object.get)(setting, 'prop')] = setting;
      });
      return byProp;
    }),
    propertyOptions: (0, _object.computed)(function () {
      return [{
        label: '',
        val: ''
      }].concat((0, _object.get)(this, 'filterSettings').map(x => {
        return {
          label: x.label,
          val: x.prop
        };
      }));
    }),
    selectedPropType: (0, _object.computed)('filterItem.prop', function () {
      let selectedProp = (0, _object.get)(this, 'filterItem.prop');
      let setting = (0, _object.get)(this, 'filterSettingsByProp')[selectedProp];
      return setting ? setting.type : null;
    }),
    operationOptions: (0, _object.computed)('selectedPropType', function () {
      let selectedPropType = (0, _object.get)(this, 'selectedPropType');
      return selectedPropType ? filterOptionsByType[selectedPropType] : [];
    }),
    selectedFilterSettingOptions: (0, _object.computed)('filterItem.prop', function () {
      let selectedProp = (0, _object.get)(this, 'filterItem.prop');
      let setting = (0, _object.get)(this, 'filterSettingsByProp')[selectedProp];
      return [{
        label: '',
        val: ''
      }].concat(setting.options);
    }),
    actions: {
      handlePropChanged(newProp) {
        let filterSetting = (0, _object.get)(this, 'filterSettingsByProp')[newProp];
        (0, _object.set)(this, 'filterItem.val', '');
        (0, _object.set)(this, 'filterItem.op', filterOptionsByType[filterSetting.type][0].val);
      },

      handleRemove() {
        (0, _object.get)(this, 'removeFilter')();
      }

    }
  });

  _exports.default = _default;
});