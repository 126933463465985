define("donor-elf-web/validations/task", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    summary: [(0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    }), (0, _validators.validateLength)({
      max: 255,
      message: "Can't be longer than 255 characters"
    })]
  };
  _exports.default = _default;
});