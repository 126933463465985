define("donor-elf-web/helpers/fund-last-updated-text", ["exports", "@ember/component/helper", "@ember/object"], function (_exports, _helper, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    moment
  } = window;

  var _default = _helper.default.extend({
    // - args
    //   - fund
    compute(args) {
      let fund = args[0];
      let lastUploadedAt = (0, _object.get)(fund, 'lastUploadedAt');

      if (lastUploadedAt == null) {
        return '';
      }

      let days = moment(moment()).diff(lastUploadedAt, 'days');

      if (days === 0) {
        return 'updated today';
      } else if (days === 1) {
        return 'updated yesterday';
      } else {
        return `updated ${days} days ago`;
      }
    }

  });

  _exports.default = _default;
});