define("donor-elf-web/controllers/funds/fund/goal/rolling", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@glimmer/tracking", "ember-concurrency"], function (_exports, _controller, _object, _service, _tracking, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    currency
  } = window;
  let GoalRollingController = (_class = class GoalRollingController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "settings", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "showValidationErrors", _descriptor3, this);

      _defineProperty(this, "saving", false);
    }

    templateWillDestroy() {
      this.showValidationErrors = false;
      this.saving = false;
    }

    get isAnnualRollingGoal() {
      var _this$settings$userSe;

      return (_this$settings$userSe = this.settings.userSettings) === null || _this$settings$userSe === void 0 ? void 0 : _this$settings$userSe.showAnnualGoal;
    }

    *save() {
      if (this.saving) {
        return;
      }

      this.saving = true;
      let changeset = this.model.changeset;
      yield changeset.validate();
      this.showValidationErrors = true;

      if (changeset.get('isValid')) {
        if (this.isAnnualRollingGoal) {
          let annualGoal = changeset.get('annualGoal');
          changeset.set('monthlyGoal', currency(annualGoal).divide(12).value);
        }

        yield changeset.save();
        this.router.transitionTo('funds.fund.dashboard');
      }

      this.saving = false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showValidationErrors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class);
  _exports.default = GoalRollingController;
});