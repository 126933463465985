define("donor-elf-web/services/current-user", ["exports", "@ember/service", "@glimmer/tracking", "@bugsnag/js"], function (_exports, _service, _tracking, _js) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CurrentUser = (_class = class CurrentUser extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "id", _descriptor3, this);

      _initializerDefineProperty(this, "firstName", _descriptor4, this);

      _initializerDefineProperty(this, "lastName", _descriptor5, this);

      _initializerDefineProperty(this, "email", _descriptor6, this);

      _initializerDefineProperty(this, "role", _descriptor7, this);

      _initializerDefineProperty(this, "selectedFundId", _descriptor8, this);

      _initializerDefineProperty(this, "hasMultipleFunds", _descriptor9, this);

      _initializerDefineProperty(this, "hasMultipleCoachingIndividuals", _descriptor10, this);

      _initializerDefineProperty(this, "coachingOnly", _descriptor11, this);

      _initializerDefineProperty(this, "afterLoginRoute", _descriptor12, this);

      _initializerDefineProperty(this, "stripeIsDelinquent", _descriptor13, this);

      _initializerDefineProperty(this, "organizationId", _descriptor14, this);

      _initializerDefineProperty(this, "organizationName", _descriptor15, this);

      _defineProperty(this, "_loadPromise", void 0);
    }

    get isSiteAdmin() {
      var _this$email;

      return ((_this$email = this.email) === null || _this$email === void 0 ? void 0 : _this$email.endsWith('admin@donorelf.com')) === true;
    }

    get isDemoUser() {
      return this.email === 'demo@donorelf.com';
    }

    get canViewAllFunds() {
      return this.organizationName.intervarsity && this.hasMultipleFunds;
    } // Quick duct tape fix for now


    get newsletterSyncEnabled() {
      return !this.isDemoUser && !this.organizationName.yl;
    }

    load() {
      if (!this.session.isAuthenticated) {
        return Promise.resolve(undefined);
      }

      if (this._loadPromise) {
        return this._loadPromise;
      }

      return this._loadPromise = this.store.query('user', {}).then(records => {
        let user = records.toArray()[0];

        if (user) {
          this.id = user.id;
          this.firstName = user.firstName;
          this.lastName = user.lastName;
          this.email = user.email;
          this.role = user.role;
          this.selectedFundId = user.selectedFundId;
          this.hasMultipleFunds = user.hasMultipleFunds;
          this.hasMultipleCoachingIndividuals = user.hasMultipleCoachingIndividuals;
          this.coachingOnly = user.coachingOnly;
          this.afterLoginRoute = user.afterLoginRoute;
          this.stripeIsDelinquent = user.stripeIsDelinquent;
          this.organizationId = user.organizationId;
          this.organizationName = {
            spo: user.organizationId === '72',
            intervarsity: user.organizationId === '164',
            ruf: user.organizationId === '366',
            yl: user.organizationId === '400'
          };

          if (_js.default.isStarted()) {
            _js.default.setUser(user.id, user.email, user.name);
          }
        }

        return user;
      });
    }

    unload() {
      this.id = undefined;
      this.firstName = undefined;
      this.lastName = undefined;
      this.email = undefined;
      this.role = undefined;
      this.selectedFundId = undefined;
      this.hasMultipleFunds = undefined;
      this.hasMultipleCoachingIndividuals = undefined;
      this.coachingOnly = undefined;
      this.afterLoginRoute = undefined;
      this.stripeIsDelinquent = undefined;
      this.organizationId = undefined;
      this.organizationName = {};
      this._loadPromise = undefined;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "id", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "firstName", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "lastName", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "email", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "role", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "selectedFundId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "hasMultipleFunds", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "hasMultipleCoachingIndividuals", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "coachingOnly", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "afterLoginRoute", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "stripeIsDelinquent", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "organizationId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "organizationName", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  })), _class);
  _exports.default = CurrentUser;
});