define("donor-elf-web/lib/models/journal-info", ["exports", "donor-elf-web/lib/array-utils"], function (_exports, _arrayUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const SORT_STAGES_BY = ['sortOrder:asc'];
  const SORT_GOALS_BY = ['goalTypeId:asc'];

  class JournalInfo {
    constructor(journalModel) {
      _defineProperty(this, "journal", void 0);

      _defineProperty(this, "goalsById", {});

      _defineProperty(this, "hasMultipleGoals", false);

      this.journal = journalModel;

      if (journalModel) {
        this.sortedStages = (0, _arrayUtils.sortByProperties)(journalModel.journalStages.toArray(), SORT_STAGES_BY);
        this.askStage = this.sortedStages.find(x => x.askStage);
        this.goals = (0, _arrayUtils.sortByProperties)(journalModel.journalGoals.toArray(), SORT_GOALS_BY);
        this.goals.forEach(goal => this.goalsById[goal.id] = goal);
        this.hasMultipleGoals = this.goals.length > 1;
      }
    } //boolean


  }

  _exports.default = JournalInfo;
});