define("donor-elf-web/templates/components/s3-upload-progress", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "B11FiKc2",
    "block": "[[[10,0],[14,0,\"progress\"],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[\"progress-bar \",[52,[33,1],\"bg-success\",\"bg-brand\"]]]],[15,5,[36,2]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"completed\",\"progressBarStyle\"]]",
    "moduleName": "donor-elf-web/templates/components/s3-upload-progress.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});