define("donor-elf-web/components/dashboard/campaign-goals/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "donor-elf-web/lib/array-utils", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _service, _arrayUtils, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Card ...attributes>
    <div class='d-flex align-items-end border-bottom'>
      <h4 class='card-title'>Campaigns</h4>
      <div class='ml-auto pb-2 small'>
        <LinkTo
          @route='funds.fund.campaigns.index'
        >
          See All
        </LinkTo>
      </div>
    </div>
    {{#if (gt this.approveCampaignTransactionsCount 0)}}
     <div class='border-bottom'>
      <Alert::Info class='my-3'>
        <LinkTo
          @route='funds.fund.campaigns.approve-transactions'
        >
          {{format-number this.approveCampaignTransactionsCount}} transactions to Assign or Approve
        </LinkTo>
      </Alert::Info>
     </div>
    {{/if}}
    <div>
      {{#each this.items as |item|}}
        <Dashboard::CampaignGoals::Goal
          @campaignGoalItem={{item}}
        />
      {{/each}}
    </div>
    <div class='pt-2'>
      <a
        {{on 'click' (prevent-default (fn this.addSomethingModal.open (hash type='campaign')))}}
        class='btn btn-xs btn-outline-brand'
        href='#'
      >
        Add Campaign
      </a>
    </div>
  </Card>
  
  */
  {
    "id": "T+mJvNYB",
    "block": "[[[8,[39,0],[[17,1]],null,[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"d-flex align-items-end border-bottom\"],[12],[1,\"\\n    \"],[10,\"h4\"],[14,0,\"card-title\"],[12],[1,\"Campaigns\"],[13],[1,\"\\n    \"],[10,0],[14,0,\"ml-auto pb-2 small\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@route\"],[\"funds.fund.campaigns.index\"]],[[\"default\"],[[[[1,\"\\n        See All\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[28,[37,3],[[30,0,[\"approveCampaignTransactionsCount\"]],0],null],[[[1,\"   \"],[10,0],[14,0,\"border-bottom\"],[12],[1,\"\\n    \"],[8,[39,4],[[24,0,\"my-3\"]],null,[[\"default\"],[[[[1,\"\\n      \"],[8,[39,1],null,[[\"@route\"],[\"funds.fund.campaigns.approve-transactions\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,5],[[30,0,[\"approveCampaignTransactionsCount\"]]],null]],[1,\" transactions to Assign or Approve\\n      \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n   \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[12],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,0,[\"items\"]]],null]],null],null,[[[1,\"      \"],[8,[39,8],null,[[\"@campaignGoalItem\"],[[30,2]]],null],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"pt-2\"],[12],[1,\"\\n    \"],[11,3],[24,0,\"btn btn-xs btn-outline-brand\"],[24,6,\"#\"],[4,[38,9],[\"click\",[28,[37,10],[[28,[37,11],[[30,0,[\"addSomethingModal\",\"open\"]],[28,[37,12],null,[[\"type\"],[\"campaign\"]]]],null]],null]],null],[12],[1,\"\\n      Add Campaign\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&attrs\",\"item\"],false,[\"card\",\"link-to\",\"if\",\"gt\",\"alert/info\",\"format-number\",\"each\",\"-track-array\",\"dashboard/campaign-goals/goal\",\"on\",\"prevent-default\",\"fn\",\"hash\"]]",
    "moduleName": "donor-elf-web/components/dashboard/campaign-goals/index.hbs",
    "isStrictMode": false
  });

  let DashboardCampaignGoalsIndexComponent = (_class = class DashboardCampaignGoalsIndexComponent extends _component2.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "addSomethingModal", _descriptor, this);

      _initializerDefineProperty(this, "campaignService", _descriptor2, this);

      _initializerDefineProperty(this, "fundGoalService", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);

      _initializerDefineProperty(this, "approveCampaignTransactionsCount", _descriptor5, this);

      _initializerDefineProperty(this, "addedCampaignGoals", _descriptor6, this);

      _defineProperty(this, "campaignSavedSubsciptionToken", void 0);

      this.campaignSavedSubsciptionToken = this.addSomethingModal.subscribeToCampaignSaved(this.handleCampaignSaved);
      this.loadApproveCampaignTransactionsCount();
    }

    get items() {
      let items = [];

      for (let campaignGoal of [...this.args.campaignGoals, ...this.addedCampaignGoals]) {
        items.push({
          campaignGoal,
          campaign: this.campaignService.campaignsById[campaignGoal.campaignId]
        });
      }

      return (0, _arrayUtils.sortByProperties)(items, ['campaign.name']);
    }

    async loadApproveCampaignTransactionsCount() {
      let filter = {
        from: this.fundGoalService.ytdStartMoment.format('YYYY-MM-DD'),
        campaign_not_approved: true,
        exclude_meta_total_amount: true,
        limit: 0 // don't return any actual records because we just need the count

      };
      let response = await this.store.query('line-item', {
        filter
      });
      this.approveCampaignTransactionsCount = response.meta.total_count;
    }

    async handleCampaignSaved(_msg, data) {
      if (data.newCampaign) {
        let {
          campaign
        } = data; // add the associated campaign goal for the new campaign

        let campaignGoals = (await this.store.query('campaign-goal', {
          filter: {
            campaign_id: campaign.id,
            currently_active: true
          }
        })).toArray();

        if (campaignGoals.length === 1) {
          this.addedCampaignGoals = [...this.addedCampaignGoals, campaignGoals[0]];
        }
      }
    }

    willDestroy() {
      if (this.campaignSavedSubsciptionToken) {
        this.addSomethingModal.unsubscribeWithToken(this.campaignSavedSubsciptionToken);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "addSomethingModal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "campaignService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "fundGoalService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "approveCampaignTransactionsCount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "addedCampaignGoals", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleCampaignSaved", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleCampaignSaved"), _class.prototype)), _class);
  _exports.default = DashboardCampaignGoalsIndexComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DashboardCampaignGoalsIndexComponent);
});