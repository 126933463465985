define("donor-elf-web/components/goal/rolling-goal", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "@glimmer/tracking", "donor-elf-web/lib/filter-utils-new"], function (_exports, _component, _object, _service, _tracking, _filterUtilsNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    Highcharts,
    moment
  } = window;
  /*
    attrs
    - goal
    - fund
    - excludePledges (boolean) default false
  */

  let DashboardRollingGoalComponent = (_class = class DashboardRollingGoalComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "formatAmountService", _descriptor, this);

      _initializerDefineProperty(this, "media", _descriptor2, this);

      _initializerDefineProperty(this, "settings", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "renderMonthlyGiftsChart", _descriptor5, this);

      _defineProperty(this, "fontFamily", '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif');
    }

    get goal() {
      return this.args.goal;
    }

    get excludePledges() {
      return this.args.excludePledges ?? false;
    }

    get isAnnualTimeFrame() {
      return this.settings.userSettings.showAnnualGoal;
    }

    get timeFrameAdjective() {
      return this.isAnnualTimeFrame ? 'Annual' : 'Monthly';
    }

    timeFrameAmount(amount) {
      return this.isAnnualTimeFrame ? amount * 12 : amount;
    }

    get goalAmount() {
      return this.timeFrameAmount(this.goal.goalAmount);
    }

    get lookingBackAmount() {
      return this.isAnnualTimeFrame ? this.goal.goalData.last_year_income : this.goal.goalData.last_year_avg_income;
    }

    get lookingBackChartSettings() {
      let percentage = this.goal.goalData.last_year_avg_percentage;

      if (percentage > 100) {
        percentage = 100;
      }

      let fillerPercentage = 100 - percentage;
      let formattedAmount = this.formatAmountService.format(this.lookingBackAmount, {
        roundToDollar: true
      });
      return {
        chart: {
          type: 'pie',
          spacing: [0, 0, 0, 0]
        },
        title: {
          text: `<span>${this.goal.goalData.last_year_avg_percentage}%</span><div style='font-size: 1rem; color: #636c72'>${formattedAmount}</div>`,
          align: 'center',
          verticalAlign: 'middle',
          useHTML: true,
          style: {
            fontSize: '2rem',
            color: '#0b68a1',
            fontFamily: this.fontFamily
          },
          y: 0
        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          pie: {
            shadow: false,
            center: ['50%', '50%']
          }
        },
        series: [{
          size: '100%',
          innerSize: '90%',
          dataLabels: {
            enabled: false
          },
          data: [{
            y: percentage,
            color: '#0b68a1'
          }, {
            y: fillerPercentage,
            color: '#eceeef'
          }]
        }]
      };
    }

    get fundedChartSettings() {
      let percentage = this.goal.goalData.pledged_monthly_percentage;

      if (percentage > 100) {
        percentage = 100;
      }

      let fillerPercentage = 100 - percentage;
      let formattedAmount = this.formatAmountService.format(this.timeFrameAmount(this.goal.goalData.pledged_monthly_amount), {
        roundToDollar: true
      });
      return {
        chart: {
          type: 'pie',
          spacing: [0, 0, 0, 0]
        },
        title: {
          text: `<span>${this.goal.goalData.pledged_monthly_percentage}%</span><div style='font-size: 1rem; color: #636c72'>${formattedAmount}</div>`,
          align: 'center',
          verticalAlign: 'middle',
          useHTML: true,
          style: {
            fontSize: '2rem',
            color: '#f0ad4e',
            fontFamily: (0, _object.get)(this, 'fontFamily')
          },
          y: 0
        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          pie: {
            shadow: false,
            center: ['50%', '50%']
          }
        },
        series: [{
          size: '100%',
          innerSize: '90%',
          dataLabels: {
            enabled: false
          },
          data: [{
            name: 'Looking Forward',
            y: percentage,
            color: '#f0ad4e'
          }, {
            y: fillerPercentage,
            color: '#eceeef'
          }]
        }]
      };
    }

    templateDidInsert(el) {
      if (this.media.isMediumDown) {
        this.renderMonthlyGiftsChart = true;
        Highcharts.chart(el.querySelector('.looking-back-chart'), this.lookingBackChartSettings);
        Highcharts.chart(el.querySelector('.looking-forward-chart'), this.fundedChartSettings);
      } else {
        setTimeout(() => {
          if (!this.isDestroyed) {
            Highcharts.chart(el.querySelector('.looking-back-chart'), this.lookingBackChartSettings);
          }
        }, 300);

        if (!this.excludePledges) {
          setTimeout(() => {
            if (!this.isDestroyed) {
              Highcharts.chart(el.querySelector('.looking-forward-chart'), this.fundedChartSettings);
            }
          }, 900);
        }

        setTimeout(() => {
          if (!this.isDestroyed) {
            this.renderMonthlyGiftsChart = true;
          }
        }, 1400);
      }
    }

    handlePastYearClicked() {
      this.router.transitionTo('funds.fund.transactions', {
        queryParams: {
          filters: (0, _filterUtilsNew.serializeAppliedFilters)([{
            propertyName: 'transDate',
            operation: '><',
            val: [moment(this.goal.goalData.last_year_from).format('YYYY-MM-DD'), moment(this.goal.goalData.last_year_to).format('YYYY-MM-DD')]
          }, {
            propertyName: 'income'
          }, {
            propertyName: 'affectsGoal'
          }])
        }
      });
    }

    handleLookingForwardClicked() {
      this.router.transitionTo('funds.fund.pledges', {
        queryParams: {
          startDate: moment(this.goal.goalData.pledged_monthly_from).format('YYYY-MM-DD'),
          endDate: moment(this.goal.goalData.pledged_monthly_to).format('YYYY-MM-DD'),
          excludeDelinquent: true
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "formatAmountService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "media", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "renderMonthlyGiftsChart", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handlePastYearClicked", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handlePastYearClicked"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleLookingForwardClicked", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleLookingForwardClicked"), _class.prototype)), _class);
  _exports.default = DashboardRollingGoalComponent;
});