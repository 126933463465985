define("donor-elf-web/templates/funds/fund/contacts/upload/map", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "2SFQ3S2e",
    "block": "[[[10,0],[14,0,\"row\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"col-12 col-lg-9\"],[12],[1,\"\\n\"],[6,[39,0],null,null,[[\"default\"],[[[[1,\"      \"],[10,\"h3\"],[12],[1,\"Map the Columns\"],[13],[1,\"\\n\"],[41,[33,2],[[[1,\"        \"],[10,0],[14,0,\"alert alert-danger\"],[12],[1,\"\\n          \"],[1,[34,2]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[10,2],[12],[1,\"\\n        Now you need to map the columns in the file to the properties on the\\n        contact.\\n      \"],[13],[1,\"\\n      \"],[10,\"table\"],[14,0,\"table mt-3\"],[12],[1,\"\\n        \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[33,5,[\"columnMappings\"]]],null]],null],null,[[[1,\"            \"],[10,\"tr\"],[12],[1,\"\\n              \"],[10,\"td\"],[12],[1,\"\\n                \"],[1,[28,[35,6],null,[[\"mappingIndex\"],[[30,2]]]]],[1,\"\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[1,2]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"mt-3\"],[12],[1,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-warning\"],[4,[38,7],[[30,0],\"next\"],null],[12],[1,\"\\n          Next\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[33,2],[[[1,\"        \"],[10,0],[14,0,\"alert alert-danger mt-3\"],[12],[1,\"\\n          \"],[1,[34,2]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null]],[]]]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"mapping\",\"index\"],false,[\"de-card\",\"if\",\"errorMessage\",\"each\",\"-track-array\",\"uploadContacts\",\"contact-upload-mapping\",\"action\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/contacts/upload/map.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});