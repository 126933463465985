define("donor-elf-web/templates/components/generic-error", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "sXTST8e2",
    "block": "[[[41,[30,0,[\"useLeftBorderCard\"]],[[[1,\"  \"],[8,[39,1],[[17,1],[4,[38,2],[[30,0,[\"templateDidInsert\"]]],null]],[[\"@leftBorderColor\"],[\"bg-danger\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"      \"],[10,0],[14,0,\"bg-danger-50\"],[12],[1,\"\\n        \"],[18,2,null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,0],[14,0,\"py-1 px-2 bg-danger-50\"],[12],[1,\"\\n        \"],[10,2],[12],[1,\"\\n          Whoops! An error just ocurred. Sorry about that.\\n        \"],[13],[1,\"\\n        \"],[10,2],[14,0,\"mb-0\"],[12],[1,\"\\n          Please email us at support@donorelf.com to let us know and we'll get it fixed.\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,0],[24,0,\"alert alert-danger\"],[17,1],[4,[38,2],[[30,0,[\"templateDidInsert\"]]],null],[12],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      Whoops! An error just ocurred. Sorry about that.\\n    \"],[13],[1,\"\\n    \"],[10,2],[14,0,\"mb-0\"],[12],[1,\"\\n      Please email us at support@donorelf.com to let us know and we'll get it fixed.\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"&attrs\",\"&default\"],false,[\"if\",\"left-border-card\",\"did-insert\",\"has-block\",\"yield\"]]",
    "moduleName": "donor-elf-web/templates/components/generic-error.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});