define("donor-elf-web/controllers/reset-password/token", ["exports", "@ember/controller", "@ember/object", "ember-concurrency"], function (_exports, _controller, _object, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    error: null,
    showValidationErrors: false,
    success: false,

    reset() {
      (0, _object.setProperties)(this, {
        error: null,
        showValidationErrors: false,
        success: false
      });
    },

    save: (0, _emberConcurrency.task)(function* () {
      let changeset = (0, _object.get)(this, 'model.changeset');
      yield changeset.validate();
      (0, _object.set)(this, 'showValidationErrors', true);

      if ((0, _object.get)(changeset, 'isValid')) {
        try {
          yield changeset.save();
          (0, _object.set)(this, 'success', true);
        } catch (error) {
          (0, _object.set)(this, 'error', error);
        }
      }
    }).drop()
  });

  _exports.default = _default;
});