define("donor-elf-web/components/add-something-modal/task/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@ember/object", "@glimmer/tracking", "@ember/utils"], function (_exports, _component, _templateFactory, _component2, _service, _object, _tracking, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Task::Info
    @task={{this.task}}
    @inAddModal={{true}}
    @onlyEdit={{true}}
    @hideAddAnother={{@openArgs.taskOptions.hideAddAnother}}
    @onCancelEditTask={{this.cancel}}
    @onTaskSaved={{this.handleTaskSaved}}
    @onTaskDeleted={{this.cancel}}
  />
  
  */
  {
    "id": "1G61+/Vt",
    "block": "[[[8,[39,0],null,[[\"@task\",\"@inAddModal\",\"@onlyEdit\",\"@hideAddAnother\",\"@onCancelEditTask\",\"@onTaskSaved\",\"@onTaskDeleted\"],[[30,0,[\"task\"]],true,true,[30,1,[\"taskOptions\",\"hideAddAnother\"]],[30,0,[\"cancel\"]],[30,0,[\"handleTaskSaved\"]],[30,0,[\"cancel\"]]]],null],[1,\"\\n\"]],[\"@openArgs\"],false,[\"task/info\"]]",
    "moduleName": "donor-elf-web/components/add-something-modal/task/index.hbs",
    "isStrictMode": false
  });

  let AddSomethingModalTaskIndexComponent = (_class = class AddSomethingModalTaskIndexComponent extends _component2.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "addSomethingModal", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "taskService", _descriptor3, this);

      _initializerDefineProperty(this, "task", _descriptor4, this);

      this.createTask();
    }

    createTask() {
      var _this$args$openArgs, _this$args$openArgs$t, _this$args$openArgs2, _this$args$openArgs2$, _this$args$openArgs2$2, _this$args$openArgs3, _this$args$openArgs3$;

      this.task = this.taskService.createRecord();

      if ((0, _utils.isPresent)((_this$args$openArgs = this.args.openArgs) === null || _this$args$openArgs === void 0 ? void 0 : (_this$args$openArgs$t = _this$args$openArgs.taskOptions) === null || _this$args$openArgs$t === void 0 ? void 0 : _this$args$openArgs$t.contactId)) {
        this.task.contactId = this.args.openArgs.taskOptions.contactId;
      } else if ((((_this$args$openArgs2 = this.args.openArgs) === null || _this$args$openArgs2 === void 0 ? void 0 : (_this$args$openArgs2$ = _this$args$openArgs2.taskOptions) === null || _this$args$openArgs2$ === void 0 ? void 0 : (_this$args$openArgs2$2 = _this$args$openArgs2$.contactIds) === null || _this$args$openArgs2$2 === void 0 ? void 0 : _this$args$openArgs2$2.length) || 0) > 0) {
        this.task.contactIds = this.args.openArgs.taskOptions.contactIds;
      } else {
        this.task.contactId = this.addSomethingModal.currentContactId;
      }

      if ((0, _utils.isPresent)((_this$args$openArgs3 = this.args.openArgs) === null || _this$args$openArgs3 === void 0 ? void 0 : (_this$args$openArgs3$ = _this$args$openArgs3.taskOptions) === null || _this$args$openArgs3$ === void 0 ? void 0 : _this$args$openArgs3$.actionLabel)) {
        var _this$taskService$act;

        this.task.actionId = (_this$taskService$act = this.taskService.actionsByLabel[this.args.openArgs.taskOptions.actionLabel]) === null || _this$taskService$act === void 0 ? void 0 : _this$taskService$act.id;
      }
    }

    handleTaskSaved(task, options) {
      this.addSomethingModal.taskAdded({
        task
      });

      if ((options === null || options === void 0 ? void 0 : options.addAnother) === true) {
        this.createTask();
      } else {
        this.addSomethingModal.close();
      }
    }

    cancel() {
      this.addSomethingModal.close();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "addSomethingModal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "taskService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "task", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handleTaskSaved", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleTaskSaved"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype)), _class);
  _exports.default = AddSomethingModalTaskIndexComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AddSomethingModalTaskIndexComponent);
});