define("donor-elf-web/components/dropdown-with-footer", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='p-2'>
    {{yield}}
  </div>
  <div class='px-2 tw-pb-2 tw-pt-1 border-top bg-gray-100 rounded-bottom'>
    <button
      {{on 'click' @onYes}}
      class='btn btn-sm btn-warning py-0 mr-2'
      type='button'
    >
      Yes
    </button>
    <button
      {{on 'click' @onNo}}
      class='btn btn-sm btn-outline-secondary py-0'
      type='button'
    >
      No
    </button>
  </div>
  
  */
  {
    "id": "u2gwvRNW",
    "block": "[[[10,0],[14,0,\"p-2\"],[12],[1,\"\\n  \"],[18,3,null],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"px-2 tw-pb-2 tw-pt-1 border-top bg-gray-100 rounded-bottom\"],[12],[1,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-sm btn-warning py-0 mr-2\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,1]],null],[12],[1,\"\\n    Yes\\n  \"],[13],[1,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-sm btn-outline-secondary py-0\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,2]],null],[12],[1,\"\\n    No\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@onYes\",\"@onNo\",\"&default\"],false,[\"yield\",\"on\"]]",
    "moduleName": "donor-elf-web/components/dropdown-with-footer.hbs",
    "isStrictMode": false
  });

  class DropdownWithFooterComponent extends _component2.default {}

  _exports.default = DropdownWithFooterComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DropdownWithFooterComponent);
});