define("donor-elf-web/helpers/task-medium-label", ["exports", "@ember/string", "@ember/component/helper", "@ember/service"], function (_exports, _string, _helper, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _helper.default.extend({
    taskService: (0, _service.inject)(),

    compute(args)
    /*namedArgs*/
    {
      let task = args[0];
      return (0, _string.htmlSafe)(this.taskService.mediumLabelsById[task.mediumId]);
    }

  });

  _exports.default = _default;
});