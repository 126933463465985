define("donor-elf-web/lib/document-utils", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.eventTargetNotInTextbox = eventTargetNotInTextbox;

  function eventTargetNotInTextbox(e) {
    let tagName = e.target.tagName.toLowerCase();
    return tagName !== 'input' && tagName !== 'textarea' && tagName !== 'select';
  }
});