define("donor-elf-web/controllers/funds/fund/contacts/contact/dates/edit", ["exports", "@ember/object/computed", "@ember/controller", "@ember/object", "@ember/service", "ember-concurrency"], function (_exports, _computed, _controller, _object, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    store: (0, _service.inject)(),
    fundsService: (0, _service.inject)(),
    alertUnitOptions: [{
      label: 'Days',
      val: 'days'
    }, {
      label: 'Weeks',
      val: 'weeks'
    }, {
      label: 'Months',
      val: 'months'
    }],
    isNew: (0, _computed.alias)('model.record.isNew'),
    save: (0, _emberConcurrency.task)(function* () {
      yield (0, _object.get)(this, 'fundsService').confirmValidFundToken();
      let changeset = (0, _object.get)(this, 'model.changeset');
      yield changeset.validate();

      if ((0, _object.get)(changeset, 'isValid')) {
        try {
          yield changeset.save();
          this.transitionToRoute('funds.fund.contacts.contact');
        } catch (e) {
          if ((0, _object.get)(this, 'model.record.errors.length') > 0) {
            (0, _object.get)(this, 'model.record.errors').forEach(({
              attribute,
              message
            }) => {
              changeset.pushErrors(attribute, message);
            });
          } else {
            throw e;
          }
        }
      }
    }).drop(),
    actions: {
      handleEnableAlertChanged(enabled) {
        if (enabled) {
          (0, _object.set)(this, 'model.changeset.alertNumber', 2);
          (0, _object.set)(this, 'model.changeset.alertUnit', 'weeks');
        }
      }

    }
  });

  _exports.default = _default;
});