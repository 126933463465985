define("donor-elf-web/controllers/funds/fund/goal/ytd", ["exports", "@ember/controller", "@ember/object", "@ember/service", "ember-concurrency", "@ember/object/computed"], function (_exports, _controller, _object, _service, _emberConcurrency, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    fundsService: (0, _service.inject)(),
    settings: (0, _service.inject)(),
    monthOptions: [{
      label: '',
      val: ''
    }, {
      label: 'January',
      val: 1
    }, {
      label: 'February',
      val: 2
    }, {
      label: 'March',
      val: 3
    }, {
      label: 'April',
      val: 4
    }, {
      label: 'May',
      val: 5
    }, {
      label: 'June',
      val: 6
    }, {
      label: 'July',
      val: 7
    }, {
      label: 'August',
      val: 8
    }, {
      label: 'September',
      val: 9
    }, {
      label: 'October',
      val: 10
    }, {
      label: 'November',
      val: 11
    }, {
      label: 'December',
      val: 12
    }],
    defaultStartAtMonthEmpty: (0, _computed.empty)('model.defaultStartAtMonth'),
    save: (0, _emberConcurrency.task)(function* () {
      let changeset = (0, _object.get)(this, 'model.changeset');
      yield changeset.validate();

      if ((0, _object.get)(changeset, 'isValid')) {
        yield (0, _object.get)(this, 'fundsService').confirmValidFundToken();
        yield changeset.save();
        this.transitionToRoute('funds.fund.dashboard');
      }
    }).drop()
  });

  _exports.default = _default;
});