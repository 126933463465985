define("donor-elf-web/templates/coaching/individuals/copy", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "r4P9rJj4",
    "block": "[[[10,0],[14,0,\"row\"],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[36,0],\" row-bottom-margin\"]]],[12],[1,\"\\n\"],[6,[39,1],null,null,[[\"default\"],[[[[6,[39,2],null,[[\"changeset\",\"cancelRoute\",\"saveLabel\",\"saveTask\"],[[33,3,[\"changeset\"]],\"coaching.individuals\",\"Next\",[33,4]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,5],null,[[\"changeset\",\"property\",\"label\",\"selectOptions\",\"showValidationErrors\"],[[33,3,[\"changeset\"]],\"templateId\",\"Template\",[33,6],[30,1]]]]],[1,\"\\n\\n        \"],[1,[28,[35,7],null,[[\"changeset\",\"property\",\"label\",\"showValidationErrors\"],[[33,3,[\"changeset\"]],\"name\",\"New Individual Name\",[30,1]]]]],[1,\"\\n\\n\"]],[1]]]]],[1,\"\\n\"],[41,[33,9],[[[1,\"        \"],[1,[28,[35,10],null,[[\"error\",\"class\"],[[33,9],\"mt-3\"]]]],[1,\"\\n\"]],[]],null]],[]]]]],[1,\"  \"],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[[36,0]]]],[12],[1,\"\\n\"],[41,[33,11],[[[6,[39,1],null,null,[[\"default\"],[[[[1,\"        \"],[10,\"h2\"],[12],[1,\"Questions on the Report\"],[13],[1,\"\\n\"],[42,[28,[37,13],[[28,[37,13],[[33,11,[\"sections\"]]],null]],null],null,[[[1,\"          \"],[10,\"h4\"],[14,0,\"mt-4\"],[12],[1,[30,2,[\"l\"]]],[13],[1,\"\\n          \"],[10,\"table\"],[14,0,\"table mb-0\"],[12],[1,\"\\n            \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,13],[[28,[37,13],[[30,2,[\"fields\"]]],null]],null],null,[[[1,\"                \"],[10,\"tr\"],[12],[10,\"td\"],[12],[1,[30,3,[\"l\"]]],[13],[13],[1,\"\\n\"]],[3]],null],[1,\"            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[2]],null]],[]]]]]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"showValidationErrors\",\"section\",\"field\"],false,[\"default-form-columns\",\"de-card\",\"save-form\",\"model\",\"save\",\"form-group-select\",\"templateOptions\",\"form-group\",\"if\",\"error\",\"generic-error\",\"selectedTemplate\",\"each\",\"-track-array\"]]",
    "moduleName": "donor-elf-web/templates/coaching/individuals/copy.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});