define("donor-elf-web/routes/organization/donors/donor/pledge-delete", ["exports", "@ember/routing/route", "@ember/service", "@ember/object"], function (_exports, _route, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    navbarSettings: (0, _service.inject)(),

    model(params) {
      let entityDetails = this.modelFor('organization.donors.donor');
      (0, _object.set)(this, 'navbarSettings.title', (0, _object.get)(entityDetails, 'entity.name'));
      let record = (0, _object.get)(entityDetails, 'pledges').find(x => (0, _object.get)(x, 'id') === params.pledgeId);
      return {
        record
      };
    }

  });

  _exports.default = _default;
});