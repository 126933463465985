define("donor-elf-web/templates/components/form-file", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "fhHju6jK",
    "block": "[[[10,\"input\"],[15,\"onchange\",[28,[37,0],[[30,0],\"onChange\"],null]],[14,0,\"form-control-file\"],[14,4,\"file\"],[12],[13],[1,\"\\n\"]],[],false,[\"action\"]]",
    "moduleName": "donor-elf-web/templates/components/form-file.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});