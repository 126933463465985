define("donor-elf-web/initializers/highcharts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application */
  {
    window.Highcharts.setOptions({
      lang: {
        thousandsSep: ','
      }
    });
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});