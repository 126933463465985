define("donor-elf-web/templates/admin/address-changes/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "0Fy3FCnL",
    "block": "[[[10,0],[14,0,\"mb-3 d-flex justify-content-end\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@menuItems\"],[[30,0,[\"actionMenuItems\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\"],[6,[39,1],null,null,[[\"default\"],[[[[1,\"  \"],[10,\"table\"],[14,0,\"table\"],[12],[1,\"\\n    \"],[10,\"thead\"],[12],[1,\"\\n      \"],[10,\"tr\"],[12],[1,\"\\n        \"],[10,\"th\"],[12],[1,\"Donor Name\"],[13],[1,\"\\n        \"],[10,\"th\"],[12],[1,\"By User\"],[13],[1,\"\\n        \"],[10,\"th\"],[12],[1,\"Changed On\"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[33,4]],null]],null],null,[[[1,\"        \"],[10,\"tr\"],[12],[1,\"\\n          \"],[10,\"td\"],[12],[6,[39,5],null,[[\"route\",\"model\"],[\"admin.address-changes.address-change\",[30,1,[\"id\"]]]],[[\"default\"],[[[[1,[30,1,[\"entityName\"]]]],[]]]]],[13],[1,\"\\n          \"],[10,\"td\"],[12],[1,[30,1,[\"userName\"]]],[13],[1,\"\\n          \"],[10,\"td\"],[12],[1,[28,[35,6],[[30,1,[\"createdAt\"]]],null]],[1,\" \"],[10,1],[14,0,\"text-muted small\"],[12],[1,\"(\"],[1,[28,[35,7],[[30,1,[\"createdAt\"]]],null]],[1,\")\"],[13],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[1]],[[[1,\"        \"],[10,\"tr\"],[12],[1,\"\\n          \"],[10,\"td\"],[12],[1,\"There are no address changes from the DonorElf users.\"],[13],[1,\"\\n          \"],[10,\"td\"],[12],[13],[1,\"\\n          \"],[10,\"td\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"record\"],false,[\"report-actions\",\"de-card\",\"each\",\"-track-array\",\"model\",\"link-to\",\"format-date\",\"format-date-from-now\"]]",
    "moduleName": "donor-elf-web/templates/admin/address-changes/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});