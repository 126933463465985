define("donor-elf-web/routes/funds/fund/import/intervarsity-prospects/processing", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IntervarsityProspectsProcessingRoute extends _route.default {
    // model comes from the parent funds.fund.import.intervarsity-prospects route
    // model()
    resetController(controller, isExiting) {
      if (isExiting) {
        controller.reset();
      }
    }

    redirect(model) {
      if (model.selectedFile == null) {
        // confirm a file has been selected
        this.transitionTo('funds.fund.import.intervarsity-prospects');
      }
    }

  }

  _exports.default = IntervarsityProspectsProcessingRoute;
});