define("donor-elf-web/routes/admin/users/add/index", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AdminUserAddIndex extends _route.default {
    redirect() {
      this.transitionTo('admin.users.add.name');
    }

  }

  _exports.default = AdminUserAddIndex;
});