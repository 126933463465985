define("donor-elf-web/validators/coaching-report-section-field-values", ["exports", "@ember/object", "@ember/utils", "@ember/string"], function (_exports, _object, _utils, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateCoachingReportSectionFieldValues;

  const isNumber = val => {
    return typeof val === 'number' && !isNaN(val);
  };

  const isInteger = val => {
    return typeof val === 'number' && isFinite(val) && Math.floor(val) === val;
  };

  const scrubNumber = val => {
    return Number((val || '').replace(/[$,]/g, ''));
  };

  const setFieldVal = (fieldVal, prop, val, scrubbedVal) => {
    if (!(0, _utils.isEmpty)(val)) {
      (0, _object.set)(fieldVal, prop, scrubbedVal.toString());
    }
  };

  const fieldTypeValidations = {
    'string': ({
      fieldVal
    }) => {
      // no validation for string. Just trimming it
      let scrubbedVal = (fieldVal.v || '').trim();
      setFieldVal(fieldVal, 'v', fieldVal.v, scrubbedVal);
    },
    'number': ({
      fieldVal,
      errors,
      field
    }) => {
      let numVal = scrubNumber(fieldVal.v);

      if (isNumber(numVal)) {
        if (isInteger(numVal)) {
          setFieldVal(fieldVal, 'v', fieldVal.v, numVal);
        } else {
          errors.push(`${field.id}: Must be an integer without decimals`);
        }
      } else {
        errors.push(`${field.id}: Must be a number`);
      }
    },
    'amount': ({
      fieldVal,
      errors,
      field
    }) => {
      let numVal = scrubNumber(fieldVal.v);

      if (isNumber(numVal)) {
        setFieldVal(fieldVal, 'v', fieldVal.v, numVal);
      } else {
        errors.push(`${field.id}: Must be a number`);
      }
    },
    'number&amount': ({
      fieldVal,
      errors,
      field
    }) => {
      ['v', 'n'].forEach(prop => {
        let numVal = scrubNumber(fieldVal[prop]);

        if (isNumber(numVal)) {
          if (prop === 'n') {
            if (isInteger(numVal)) {
              setFieldVal(fieldVal, prop, fieldVal[prop], numVal);
            } else {
              errors.push(`${field.id}: Number on left must be an integer without decimals`);
            }
          } else {
            setFieldVal(fieldVal, prop, fieldVal[prop], numVal);
          }
        } else {
          if (prop === 'n') {
            errors.push(`${field.id}: Number on left must be a number`);
          } else {
            errors.push(`${field.id}: Amount on right must be a number`);
          }
        }
      });
    },
    'pledged&received': ({
      fieldVal,
      errors,
      field
    }) => {
      ['v', 'p'].forEach(prop => {
        let numVal = scrubNumber(fieldVal[prop]);

        if (isNumber(numVal)) {
          setFieldVal(fieldVal, prop, fieldVal[prop], numVal);
        } else {
          if (prop === 'p') {
            errors.push(`${field.id}: Pledged amount on left must be a number`);
          } else {
            errors.push(`${field.id}: Received Amount on right must be a number`);
          }
        }
      });
    },
    'response': ({
      fieldVal,
      errors,
      field
    }) => {
      ['yes', 'no', 'maybe'].forEach(prop => {
        let origVal = fieldVal.v[prop];
        let numVal = scrubNumber(origVal);

        if (isNumber(numVal)) {
          if (isInteger(numVal)) {
            setFieldVal(fieldVal, `v.${prop}`, origVal, numVal);
          } else {
            errors.push(`${field.id}: ${(0, _string.capitalize)(prop)} must be an integer without decimals`);
          }
        } else {
          errors.push(`${field.id}: ${(0, _string.capitalize)(prop)} must be a number`);
        }
      });
    },
    'tasks': ({
      fieldVal
    }) => {
      // no validation for tasks. Just trimming labels and removing tasks with empty labels
      let tasksWithLabels = (fieldVal.v || []).filter(x => !(0, _utils.isEmpty)(x.l));
      tasksWithLabels.forEach(task => {
        (0, _object.set)(task, 'l', (task.l || '').trim());
      });
      (0, _object.set)(fieldVal, 'v', tasksWithLabels);
    }
  };

  function validateCoachingReportSectionFieldValues({
    individual
  }) {
    let fields = {};
    (0, _object.get)(individual, 'sections').forEach(section => {
      (0, _object.get)(section, 'fields').forEach(field => {
        fields[(0, _object.get)(field, 'id')] = field;
      });
    });
    return (key, newValue) => {
      if (newValue == null) {
        return true;
      }

      let errors = [];
      Object.keys(newValue).forEach(fieldId => {
        let field = fields[fieldId];

        if (field) {
          let fieldVal = (0, _object.get)(newValue, fieldId); // TODO: remove this if statement

          if (fieldTypeValidations[field.t]) {
            fieldTypeValidations[field.t]({
              fieldVal,
              errors,
              field
            });
          }
        }
      });

      if (errors.length > 0) {
        return errors.join('|');
      } else {
        return true;
      }
    };
  }
});