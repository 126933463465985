define("donor-elf-web/components/group-add-contact-row", ["exports", "@ember/component", "@ember/object", "@ember/service", "donor-elf-web/lib/logger"], function (_exports, _component, _object, _service, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    attrs
      - contact
      - group
      - onAddedToGroup(contact)
  */
  var _default = _component.default.extend({
    tagName: 'tr',
    contactsCache: (0, _service.inject)(),
    fundsService: (0, _service.inject)(),
    contact: null,
    group: null,
    adding: false,
    actions: {
      add() {
        (0, _object.set)(this, 'adding', true);
        let {
          contact,
          group,
          contactsCache,
          fundsService,
          onAddedToGroup
        } = (0, _object.getProperties)(this, 'contact', 'group', 'contactsCache', 'fundsService', 'onAddedToGroup');
        (0, _object.get)(contact, 'groups').addObject(group);
        fundsService.confirmValidFundToken().then(() => {
          contact.save().then(() => {
            contactsCache.incrementProperty('groupsCacheIndex');

            if (onAddedToGroup) {
              onAddedToGroup(contact);
            }
          }, error => {
            _logger.default.error(error);
          });
        });
      }

    }
  });

  _exports.default = _default;
});