define("donor-elf-web/components/report-actions", ["exports", "@glimmer/component", "@ember/object", "@ember/service"], function (_exports, _component, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ReportActionsComponent = (_class = class ReportActionsComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "powerMenu", _descriptor2, this);
    }

    handleOnClick(menuItem, dropdown) {
      if (menuItem.route) {
        this.router.transitionTo(menuItem.route);
      } else {
        var _menuItem$onClick;

        (_menuItem$onClick = menuItem.onClick) === null || _menuItem$onClick === void 0 ? void 0 : _menuItem$onClick.call(menuItem);
      }

      setTimeout(() => {
        dropdown.actions.close();
      });
    }

    templateDidInsert() {
      this.powerMenu.prependOptionsFn('actions', this.powerMenuOptions);
    }

    templateWillDestroy() {
      this.powerMenu.removeOptions('actions');
    }

    powerMenuOptions() {
      return this.args.menuItems.map(item => {
        return {
          label: `Actions > ${item.powerMenuLabel || item.label}`,
          fn: item.onClick
        };
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "powerMenu", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handleOnClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleOnClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "powerMenuOptions", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "powerMenuOptions"), _class.prototype)), _class);
  _exports.default = ReportActionsComponent;
  ;
});