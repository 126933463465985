define("donor-elf-web/components/form/underline/date", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "@ember/utils", "donor-elf-web/lib/date-utils"], function (_exports, _component, _templateFactory, _component2, _object, _service, _utils, _dateUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <PikadayInput
    @value={{this.changesetVal}}
    @format={{this.settings.dateFormat4Year}}
    @firstDay={{0}}
    class='form-underline-input'
    size='10'
    @disabled={{@disabled}}
    @onSelection={{this.handleSelection}}
    ...attributes
  />
  {{#if this.showError}}
    <div class='text-danger tw-text-sm'>
      {{this.errorMessage}}
    </div>
  {{/if}}
  */
  {
    "id": "a5Efke+A",
    "block": "[[[8,[39,0],[[24,0,\"form-underline-input\"],[24,\"size\",\"10\"],[17,1]],[[\"@value\",\"@format\",\"@firstDay\",\"@disabled\",\"@onSelection\"],[[30,0,[\"changesetVal\"]],[30,0,[\"settings\",\"dateFormat4Year\"]],0,[30,2],[30,0,[\"handleSelection\"]]]],null],[1,\"\\n\"],[41,[30,0,[\"showError\"]],[[[1,\"  \"],[10,0],[14,0,\"text-danger tw-text-sm\"],[12],[1,\"\\n    \"],[1,[30,0,[\"errorMessage\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"&attrs\",\"@disabled\"],false,[\"pikaday-input\",\"if\"]]",
    "moduleName": "donor-elf-web/components/form/underline/date.hbs",
    "isStrictMode": false
  });

  let FormUnderlineDateComponent = (_class = class FormUnderlineDateComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "settings", _descriptor, this);
    }

    get changesetVal() {
      let val = this.args.changeset.get(this.args.property);

      if (typeof val === 'string') {
        // correctly adjust for timezone
        val = _dateUtils.default.deserializeDateOnly(val);
      }

      return val;
    }

    set changesetVal(_val) {// intentionally don't do anything when Pikaday tries to change the value
      // because handleSelction() below takes care of changes
    }

    handleSelection(val) {
      var _this$args$onChange, _this$args;

      this.args.changeset.set(this.args.property, val);
      (_this$args$onChange = (_this$args = this.args).onChange) === null || _this$args$onChange === void 0 ? void 0 : _this$args$onChange.call(_this$args, val);
    }

    get showError() {
      return this.args.showValidationErrors && (0, _utils.isPresent)(this.errorMessage);
    }

    get errorMessage() {
      let validationErrors = (0, _object.get)(this.args.changeset, `error.${this.args.property}.validation`) || [];
      return validationErrors.join(', ');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handleSelection", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleSelection"), _class.prototype)), _class);
  _exports.default = FormUnderlineDateComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormUnderlineDateComponent);
});