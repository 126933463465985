define("donor-elf-web/controllers/admin/users/add/funds", ["exports", "@ember/controller", "@ember/object"], function (_exports, _controller, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    errorMessage: null,

    reset() {
      (0, _object.set)(this, 'errorMessage', null);
    },

    actions: {
      save(selectedItems) {
        if ((0, _object.get)(selectedItems, 'length') === 0) {
          (0, _object.set)(this, 'errorMessage', 'You must select at least one fund for the user');
          return;
        }

        (0, _object.set)(this, 'errorMessage', null);
        let selectedFundIds = selectedItems.map(item => {
          return (0, _object.get)(item, 'fund.id');
        });
        (0, _object.set)(this, 'model.selectedFundIds', selectedFundIds);
        this.transitionToRoute('admin.users.add.save');
      }

    }
  });

  _exports.default = _default;
});