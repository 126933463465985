define("donor-elf-web/templates/components/top-navbar-fund", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "hM3Q0Qdj",
    "block": "[[[10,0],[14,0,\"flex-grow-1 d-flex\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"mr-auto d-flex\"],[12],[1,\"\\n\"],[41,[28,[37,1],[\"edit_contact\"],null],[[[1,\"      \"],[10,0],[14,0,\"nav-item mr-3\"],[12],[1,\"\\n        \"],[8,[39,2],null,null,null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,0],[14,0,\"nav-item mr-3\"],[12],[1,\"\\n      \"],[8,[39,3],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"nav-item mr-3\"],[12],[1,\"\\n      \"],[8,[39,4],null,null,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"currentUser\",\"hasMultipleFunds\"]],[[[1,\"    \"],[10,0],[14,0,\"nav-item\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"selected-fund\"],[12],[1,\"\\n        \"],[11,0],[24,0,\"selected-fund-label\"],[24,\"role\",\"button\"],[4,[38,5],[\"click\",[30,0,[\"writeFindFundInRubyToClipboard\"]]],null],[12],[1,\"\\n          Selected Fund\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"selected-fund-name svg-icon text-truncate\"],[15,\"title\",[30,0,[\"fundsService\",\"selectedFund\",\"name\"]]],[12],[1,\"\\n          \"],[8,[39,6],null,[[\"@route\"],[\"funds\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[30,0,[\"fundsService\",\"selectedFund\",\"name\"]]],[1,\"\\n            \"],[10,1],[14,0,\"text-brand\"],[12],[1,\"\\n              \"],[1,[28,[35,7],[\"angle-down\"],null]],[1,\"\\n            \"],[13],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[],false,[\"if\",\"has-ability\",\"navbar-add\",\"navbar-reports\",\"navbar-search-contacts\",\"on\",\"link-to\",\"svg-jar\"]]",
    "moduleName": "donor-elf-web/templates/components/top-navbar-fund.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});