define("donor-elf-web/templates/components/chart/total-raised-by-month", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "hd88dyRo",
    "block": "[[[11,0],[4,[38,0],[[30,0,[\"templateDidInsert\"]]],null],[12],[13]],[],false,[\"did-insert\"]]",
    "moduleName": "donor-elf-web/templates/components/chart/total-raised-by-month.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});