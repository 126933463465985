define("donor-elf-web/templates/funds/fund/contacts/contact/gifts-by-pledge-loading", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "YWQfJPdV",
    "block": "[[[10,\"ol\"],[14,0,\"breadcrumb row-bottom-margin\"],[12],[1,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[8,[39,0],null,[[\"@route\"],[\"funds.fund.contacts\"]],[[\"default\"],[[[[1,\"Contacts\"]],[]]]]],[13],[1,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[8,[39,0],null,[[\"@route\",\"@model\"],[\"funds.fund.contacts.contact\",[30,1,[\"contact\",\"id\"]]]],[[\"default\"],[[[[1,[30,1,[\"contact\",\"name\"]]]],[]]]]],[13],[1,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[1,\"Gifts by Pledge\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],null,null,null],[1,\"\\n\"]],[]]]]]],[\"@model\"],false,[\"link-to\",\"card\",\"de-loading\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/contacts/contact/gifts-by-pledge-loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});