define("donor-elf-web/controllers/organization/donors/index", ["exports", "@ember/object/computed", "@ember/controller", "@ember/object", "@ember/utils", "@ember/service", "ember-parachute"], function (_exports, _computed, _controller, _object, _utils, _service, _emberParachute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.queryParams = void 0;
  const queryParams = new _emberParachute.default({
    q: {
      defaultValue: '',
      refresh: true
    },
    page: {
      defaultValue: 1,
      refresh: true
    },
    pageSize: {
      defaultValue: 50,
      refresh: true
    }
  });
  _exports.queryParams = queryParams;

  var _default = _controller.default.extend(queryParams.Mixin, {
    fundsService: (0, _service.inject)(),
    q: '',
    qInput: '',
    page: 1,
    pageSize: 50,
    entities: null,
    loading: false,
    error: null,
    queryParamsChanged: (0, _computed.or)('queryParamsState.{q,page}.changed'),

    setup({
      queryParams
    }) {
      this.fetchData(queryParams);
    },

    queryParamsDidChange({
      shouldRefresh,
      queryParams
    }) {
      // if any query param with `refresh: true` is changed, `shouldRefresh` is `true`
      if (shouldRefresh) {
        this.fetchData(queryParams);
      }
    },

    reset(event, isExiting) {
      if (isExiting) {
        this.resetQueryParams();
        (0, _object.setProperties)(this, {
          q: '',
          qInput: '',
          error: null,
          entities: null
        });
      }
    },

    fetchData(queryParams) {
      (0, _object.setProperties)(this, {
        loading: true,
        entities: null,
        error: null,
        qInput: queryParams.q
      });
      let q = (queryParams.q || '').trim();

      if ((0, _utils.isEmpty)(q)) {
        (0, _object.set)(this, 'loading', false);
        return;
      }

      let offset = (queryParams.page - 1) * queryParams.pageSize;
      return (0, _object.get)(this, 'fundsService').confirmValidFundToken().then(() => {
        return (0, _object.get)(this, 'store').query('organization/entity', {
          filter: {
            q,
            offset,
            limit: queryParams.pageSize
          }
        }).then(entities => {
          let totalCount = (0, _object.get)(entities, 'meta.total_count') || '0';
          let totalPages = Math.ceil(Number(totalCount) / queryParams.pageSize);
          (0, _object.setProperties)(this, {
            loading: false,
            entities,
            totalPages
          });
        });
      }, error => {
        (0, _object.setProperties)(this, {
          loading: false,
          error
        });
      });
    },

    actions: {
      applyFilter() {
        let qInput = ((0, _object.get)(this, 'qInput') || '').trim();

        if (qInput.length < 2) {
          alert('You must enter at least 2 characters in the search');
          return;
        }

        (0, _object.setProperties)(this, {
          q: qInput,
          page: 1
        });
      },

      changePage(pageNumber) {
        (0, _object.set)(this, 'page', pageNumber);
      }

    }
  });

  _exports.default = _default;
});