define("donor-elf-web/routes/funds/fund/custom-reports/pbt-disbursement-summary", ["exports", "@ember/routing/route", "@ember/object", "@ember/service"], function (_exports, _route, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    navbarSettings: (0, _service.inject)(),
    storeCache: (0, _service.inject)(),

    beforeModel() {
      (0, _object.set)(this, 'navbarSettings.title', 'Disbursement Summary');
    },

    model() {
      return this.storeCache.findAll('fund').then(funds => {
        return {
          funds
        };
      });
    }

  });

  _exports.default = _default;
});