define("donor-elf-web/controllers/funds/fund/more/cancel-account", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@glimmer/tracking"], function (_exports, _controller, _object, _service, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let CancelAccountCountroller = (_class = class CancelAccountCountroller extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "session", _descriptor3, this);

      _initializerDefineProperty(this, "cancellingAccount", _descriptor4, this);

      _defineProperty(this, "cancelReason", void 0);
    }

    templateWillDestroy() {
      this.cancellingAccount = false;
      this.cancelReason = undefined;
    }

    handleCancelReasonChanged(e) {
      this.cancelReason = e.target.value;
    }

    async cancelAccount() {
      if (this.cancellingAccount) {
        return;
      }

      let cancelAccount = this.store.createRecord('cancel-account');
      cancelAccount.cancelReason = this.cancelReason;
      await cancelAccount.save();
      await this.session.invalidate();
      this.router.transitionTo('account-cancelled');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "cancellingAccount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleCancelReasonChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleCancelReasonChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelAccount", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancelAccount"), _class.prototype)), _class);
  _exports.default = CancelAccountCountroller;
});