define("donor-elf-web/templates/funds/fund/transactions/edit/save", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "g8/bbINp",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[1,[28,[35,1],null,[[\"lines\",\"onSaved\",\"onInvalidLines\"],[[30,0,[\"lineItemInfos\"]],[30,0,[\"handleSaved\"]],[30,0,[\"handleInvalidLineItemInfos\"]]]]]],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[],false,[\"form-card\",\"save-transactions\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/transactions/edit/save.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});