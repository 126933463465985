define("donor-elf-web/templates/admin/users/show/info/funds-loading", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "QZceD8A3",
    "block": "[[[10,0],[14,0,\"row\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"col-12 col-md-6\"],[12],[1,\"\\n\"],[6,[39,0],null,null,[[\"default\"],[[[[1,\"      \"],[10,\"h5\"],[12],[1,\"Fund Permissions\"],[13],[1,\"\\n      \"],[1,[34,1]],[1,\"\\n\"]],[]]]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[],false,[\"de-card\",\"de-loading\"]]",
    "moduleName": "donor-elf-web/templates/admin/users/show/info/funds-loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});