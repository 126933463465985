define("donor-elf-web/controllers/funds/fund/journals/journal/segments/index", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@glimmer/tracking", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators"], function (_exports, _controller, _object, _service, _tracking, _emberChangeset, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.journalSegmentIntroText = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let validation = {
    name: (0, _validators.validatePresence)({
      presence: true,
      message: 'Enter a name for the segment'
    })
  };
  const journalSegmentIntroText = 'Journal Segments allow you to group contacts together in a Journal. Segments act very similar to the Groups on the contacts, but the main difference is Journal Segments are specific to an individual Journal.';
  _exports.journalSegmentIntroText = journalSegmentIntroText;
  let JournalSegmentsIndexController = (_class = class JournalSegmentsIndexController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "showValidationErrors", _descriptor2, this);

      _defineProperty(this, "newSegmentChangeset", void 0);

      _defineProperty(this, "journalSegmentIntroText", journalSegmentIntroText);
    }

    templateDidInsert() {
      this.showValidationErrors = false;
      this.newSegmentChangeset = new _emberChangeset.default({
        name: ''
      }, (0, _emberChangesetValidations.default)(validation), validation);
    }

    async handleSaveNewSegment() {
      await this.newSegmentChangeset.validate();

      if (this.newSegmentChangeset.get('isValid')) {
        let segmentName = this.newSegmentChangeset.get('name');
        this.router.transitionTo('funds.fund.journals.journal.segments.segment', segmentName);
      } else {
        this.showValidationErrors = true;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "showValidationErrors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleSaveNewSegment", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleSaveNewSegment"), _class.prototype)), _class);
  _exports.default = JournalSegmentsIndexController;
});