define("donor-elf-web/helpers/filter-query", ["exports", "@ember/component/helper", "donor-elf-web/lib/filter-utils-new"], function (_exports, _helper, _filterUtilsNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // this expects the queryParameter on the controller or route to be named 'filters'
  //
  // example
  // <LinkTo @query={{filter-query name='Paul' lastName='Yoder'}} />
  // this will apply 2 filters: [
  //  { propertyName: 'Paul', operation: '=' val: 'Paul'},
  //  { propertyName: 'lastName', operation: '=' val: 'Yoder'}
  // ]
  // if the propertyName has a space, then you must use the (filter-operation) helper
  // 
  // when the operation is 'equals' then you can just use a name/value pair
  // <LinkTo @query={{filter-query name='Paul'}} />
  // filters = [{ propertyName: 'name', operation: '=' val: 'Paul'}]
  //
  // for all operations other than '=', use the {{filter helper}} in the positional position
  // <LinkTo @query={{filter-query (filter 'contact_group' operation='any' val=(array 'Friend' 'Church')) name='Paul'}} />
  // filters = [
  //   { propertyName: 'contact_group', operation: 'any' val: ['Friend', 'Church']}
  //   { propertyName: 'name', operation: 'equals' val: 'paul'}
  // ]
  //
  // you can also pass in an additionalQueryHash to add additional query parameters
  // <LinkTo @query={{filter-query name='Paul' additionalQueryHash=(hash page=2)}} />
  var _default = (0, _helper.helper)(function (appliedPropertyFilters, namedArgs) {
    let filters = [];
    let additionalQueryHash = { ...(namedArgs.additionalQueryHash || {})
    };

    for (let filter of [...appliedPropertyFilters]) {
      filters.push({
        propertyName: filter.propertyName,
        operation: filter.operation,
        val: filter.val
      });
    }

    for (let propertyName in { ...namedArgs
    }) {
      if (propertyName === 'additionalQueryHash') {
        continue;
      }

      if (typeof namedArgs[propertyName] === 'object') {
        filters.push({ ...namedArgs[propertyName]
        });
      } else {
        filters.push({
          propertyName,
          operation: '=',
          val: namedArgs[propertyName]
        });
      }
    }

    let response = {
      filters: (0, _filterUtilsNew.serializeAppliedFilters)(filters),
      ...additionalQueryHash
    };
    console.log({
      filterQuery: response
    });
    return response;
  });

  _exports.default = _default;
});