define("donor-elf-web/controllers/funds/fund/import/ag-usa", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@glimmer/tracking", "donor-elf-web/lib/logger"], function (_exports, _controller, _object, _service, _tracking, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DashboardController = (_class = class DashboardController extends _controller.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "model", void 0);

      _initializerDefineProperty(this, "contactsCache", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _defineProperty(this, "file", void 0);

      _initializerDefineProperty(this, "uploadFile", _descriptor4, this);

      _initializerDefineProperty(this, "fileUploadInfo", _descriptor5, this);

      _initializerDefineProperty(this, "fileUploadProgressPercent", _descriptor6, this);

      _initializerDefineProperty(this, "fileUploadProgressBackgroundClass", _descriptor7, this);

      _initializerDefineProperty(this, "processFile", _descriptor8, this);

      _initializerDefineProperty(this, "fundUploadProgressPercent", _descriptor9, this);

      _initializerDefineProperty(this, "fundUploadProgressBackgroundClass", _descriptor10, this);

      _initializerDefineProperty(this, "errorMessage", _descriptor11, this);

      _initializerDefineProperty(this, "error", _descriptor12, this);
    }

    get mappedAccountNumber() {
      var _this$model$agUsaImpo;

      return (_this$model$agUsaImpo = this.model.agUsaImport) === null || _this$model$agUsaImpo === void 0 ? void 0 : _this$model$agUsaImpo.mappedAccountNumber;
    }

    get exampleAccountNumber() {
      return this.mappedAccountNumber || '123456';
    }

    get validDailyFileName() {
      return this.file.name.match(/DailyFaithPromiseCashReceipt.xls$/);
    }

    get validYtdFileName() {
      return this.file.name.match(/missionary.*faith.*promise.*and.*cash.*receipts.*ytd.*\.csv$/i);
    }

    templateWillDestroy() {
      this.file = undefined;
      this.uploadFile = false;
      this.fileUploadInfo = undefined;
      this.fileUploadProgressPercent = 0;
      this.fileUploadProgressBackgroundClass = 'bg-brand';
      this.fundUploadProgressPercent = 0;
      this.fundUploadProgressBackgroundClass = 'bg-brand';
      this.processFile = false;
    }

    handleFileInputChanged(e) {
      if (e.target.files.length === 1) {
        this.file = e.target.files[0];
        this.errorMessage = undefined;
      } else {
        this.file = undefined;
      }
    }

    async upload() {
      if (this.uploadFile) {
        return;
      }

      this.errorMessage = undefined;

      if (!this.file) {
        this.errorMessage = 'Select a file first';
        return;
      }

      if (!this.validDailyFileName && !this.validYtdFileName) {
        this.errorMessage = "Invalid file name. Check the example file names below to be sure you're uploading the correct file.";
        return;
      }

      this.fileUploadInfo = await this.store.queryRecord('file-upload-info', {
        filter: {
          file_name: this.file.name
        }
      });
      this.fileUploadProgressPercent = 5;
      this.fileUploadProgressBackgroundClass = 'bg-brand';
      this.uploadFile = true;
    }

    handleFileUploadProgress(percentUploaded) {
      this.fileUploadProgressPercent = Math.max(percentUploaded, 5);
    }

    async handleFileUploadComplete() {
      this.fileUploadProgressPercent = 100;
      setTimeout(() => {
        // delaying changing background color until the width transition completes
        this.fileUploadProgressBackgroundClass = 'bg-success';
      }, 500);
      let startImport = this.store.createRecord('ag-usa-import', {
        fileName: this.fileUploadInfo.key
      });

      try {
        await startImport.save();
        this.processFile = true;
      } catch (error) {
        if (error.errors) {
          this.errorMessage = error.errors[0].detail;
        }

        _logger.default.error(error);
      }
    }

    handleFundUploadProgress(percentUploaded) {
      this.fundUploadProgressPercent = Math.max(percentUploaded, 5);
    }

    async handleFundUploadComplete() {
      this.fundUploadProgressPercent = 100;
      setTimeout(() => {
        // delaying changing background color until the width transition completes
        this.fundUploadProgressBackgroundClass = 'bg-success';
      }, 500);
      await this.contactsCache.loadModifiedContacts();
      this.router.transitionTo('funds.fund.dashboard');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "contactsCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "uploadFile", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "fileUploadInfo", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "fileUploadProgressPercent", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "fileUploadProgressBackgroundClass", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bg-brand';
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "processFile", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "fundUploadProgressPercent", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "fundUploadProgressBackgroundClass", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'bg-brand';
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "errorMessage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "error", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleFileInputChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleFileInputChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "upload", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "upload"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleFileUploadProgress", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleFileUploadProgress"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleFileUploadComplete", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleFileUploadComplete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleFundUploadProgress", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleFundUploadProgress"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleFundUploadComplete", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleFundUploadComplete"), _class.prototype)), _class);
  _exports.default = DashboardController;
});