define("donor-elf-web/templates/funds/fund/contacts/contact/not-found", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "VVA6lll9",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,2],[12],[1,\"\\n    Whoops! We can't find this contact.\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    This often happens when you merged contacts or deleted a contact. If you\\n    didn't do either of these things, please email us at \"],[10,3],[14,6,\"mailto:support@donorelf.com\"],[12],[1,\"support@donorelf.com\"],[13],[1,\" to let us know\\n    and we'll look into it.\\n  \"],[13],[1,\"\\n  \"],[6,[39,1],null,[[\"class\",\"route\"],[\"btn btn-warning mt-4\",\"funds.fund.dashboard\"]],[[\"default\"],[[[[1,\"Go to the Dashboard\"]],[]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"de-form-card\",\"link-to\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/contacts/contact/not-found.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});