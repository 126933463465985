define("donor-elf-web/components/line-item/custom", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service"], function (_exports, _component, _templateFactory, _component2, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes>
    {{#each this.customItem as |item|}}
      <div class='indent-after-first-line tw-text-sm'>
        <span class='text-muted'>{{item.label}}:</span>
        {{#if item.isAmount}}
          {{format-amount item.val}}
        {{else}}
          {{item.val}}
        {{/if}}
      </div>
    {{/each}}
  </div>
  */
  {
    "id": "dAk5IfYv",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"customItem\"]]],null]],null],null,[[[1,\"    \"],[10,0],[14,0,\"indent-after-first-line tw-text-sm\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"text-muted\"],[12],[1,[30,2,[\"label\"]]],[1,\":\"],[13],[1,\"\\n\"],[41,[30,2,[\"isAmount\"]],[[[1,\"        \"],[1,[28,[35,3],[[30,2,[\"val\"]]],null]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[30,2,[\"val\"]]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[2]],null],[13]],[\"&attrs\",\"item\"],false,[\"each\",\"-track-array\",\"if\",\"format-amount\"]]",
    "moduleName": "donor-elf-web/components/line-item/custom.hbs",
    "isStrictMode": false
  });

  // used for caching purposes
  let CustomPropertiesInfo = {
    labelIsAmount: {}
  };
  let LineItemCustomComponent = (_class = class LineItemCustomComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "settings", _descriptor, this);
    }

    get customItem() {
      if (this.args.lineItem.custom == null) {
        return undefined;
      }

      if (CustomPropertiesInfo.sortedPropertyLabels == null) {
        CustomPropertiesInfo.sortedPropertyLabels = [];

        for (let info of this.settings.fundSettings.lineItemCustomProperties || []) {
          CustomPropertiesInfo.sortedPropertyLabels.push(info.label);
          CustomPropertiesInfo.labelIsAmount[info.label] = info.type === 'amount';
        }
      }

      let items = [];

      for (let label of CustomPropertiesInfo.sortedPropertyLabels) {
        if (this.args.lineItem.custom[label] != null) {
          items.push({
            label,
            val: this.args.lineItem.custom[label],
            isAmount: CustomPropertiesInfo.labelIsAmount[label]
          });
        }
      }

      if (Object.keys(this.args.lineItem.custom).length > items.length) {
        for (let label in this.args.lineItem.custom) {
          if (!CustomPropertiesInfo.sortedPropertyLabels.includes(label)) {
            CustomPropertiesInfo.sortedPropertyLabels.push(label);
            CustomPropertiesInfo.labelIsAmount[label] = false;
            items.push({
              label,
              val: this.args.lineItem.custom[label],
              isAmount: CustomPropertiesInfo.labelIsAmount[label]
            });
          }
        }
      }

      return items;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = LineItemCustomComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LineItemCustomComponent);
});