define("donor-elf-web/controllers/funds/fund/question", ["exports", "@ember/controller", "@ember/object", "@ember/service", "ember-concurrency"], function (_exports, _controller, _object, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    currentUser: (0, _service.inject)(),
    fundsService: (0, _service.inject)(),
    store: (0, _service.inject)(),
    error: null,
    saveComplete: false,
    showValidationErrors: false,

    reset() {
      (0, _object.setProperties)(this, {
        error: null,
        saveComplete: false,
        showValidationErrors: false
      });
    },

    save: (0, _emberConcurrency.task)(function* () {
      try {
        (0, _object.setProperties)(this, {
          saveComplete: false,
          showValidationErrors: false
        });
        yield (0, _object.get)(this, 'fundsService').confirmValidFundToken();
        let changeset = (0, _object.get)(this, 'model.changeset');
        yield changeset.validate();

        if ((0, _object.get)(changeset, 'isValid')) {
          yield changeset.save();
          (0, _object.set)(this, 'saveComplete', true);
        } else {
          (0, _object.set)(this, 'showValidationErrors', true);
        }
      } catch (error) {
        (0, _object.set)(this, 'error', error);
      }
    }).drop()
  });

  _exports.default = _default;
});