define("donor-elf-web/templates/funds/fund/question", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "kOdjYXNV",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[41,[33,2],[[[1,\"    \"],[10,0],[14,0,\"alert alert-success\"],[12],[1,\"\\n      Your question has been sent!\\n    \"],[13],[1,\"\\n\"],[6,[39,3],null,[[\"class\",\"route\"],[\"btn btn-warning mt-3\",\"funds.fund.dashboard\"]],[[\"default\"],[[[[1,\"      Go to the Dashboard\\n\"]],[]]]]]],[]],[[[1,\"    \"],[10,2],[12],[1,\"\\n      Please fill out this form, and we'll email you back shortly.\\n    \"],[13],[1,\"\\n\"],[41,[33,4,[\"isDemoUser\"]],[[[1,\"      \"],[1,[28,[35,5],null,[[\"changeset\",\"property\",\"label\",\"showValidationErrors\"],[[33,6,[\"changeset\"]],\"name\",\"Your Name\",[33,7]]]]],[1,\"\\n      \"],[1,[28,[35,5],null,[[\"changeset\",\"property\",\"label\",\"showValidationErrors\"],[[33,6,[\"changeset\"]],\"email\",\"Your Email\",[33,7]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[1,[28,[35,5],null,[[\"changeset\",\"property\",\"label\",\"textArea\",\"showValidationErrors\"],[[33,6,[\"changeset\"]],\"text\",\"What question do you have?\",true,[33,7]]]]],[1,\"\\n    \"],[10,\"button\"],[15,\"onclick\",[28,[37,8],[[33,9]],null]],[14,0,\"btn btn-warning mb-3\"],[15,\"disabled\",[52,[33,9,[\"isRunning\"]],\"disable\"]],[12],[1,\"\\n      Send your Question\\n    \"],[13],[1,\"\\n\"],[41,[33,9,[\"isRunning\"]],[[[1,\"      \"],[10,0],[14,0,\"alert alert-info\"],[12],[1,\"\\n        Sending your question...\\n      \"],[13],[1,\"\\n\"]],[]],null],[41,[33,10],[[[1,\"      \"],[1,[28,[35,11],null,[[\"error\"],[[33,10]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,0],[14,0,\"text-muted font-weight-light\"],[12],[1,\"\\n      Our goal is to respond to questions within 1 business day.\\n      Unfortunately sometimes it takes 2 business days.\\n    \"],[13],[1,\"\\n\"]],[]]]],[]]]]]],[],false,[\"de-form-card\",\"if\",\"saveComplete\",\"link-to\",\"currentUser\",\"form-group\",\"model\",\"showValidationErrors\",\"perform\",\"save\",\"error\",\"generic-error\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/question.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});