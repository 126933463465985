define("donor-elf-web/templates/funds/fund/more/tnt-connect-import/data-sync/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "iACI3Wd9",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,\"h4\"],[12],[1,\"Select the DataSync file\"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Click the button below and select the DataSync file.\\n  \"],[13],[1,\"\\n\\n  \"],[1,[28,[35,1],null,[[\"onFileSelected\"],[[28,[37,2],[[30,0],\"handleFileSelected\"],null]]]]],[1,\"\\n\\n\"],[41,[33,4],[[[1,\"    \"],[10,0],[14,0,\"alert alert-danger mt-3\"],[12],[1,\"\\n      \"],[10,2],[12],[1,\"\\n        Whoops. It looks like you didn't select the right file. The file name\\n        should end in '.tntdatasync', '.tntmpd' or '.tntconnect'\\n      \"],[13],[1,\"\\n      \"],[10,0],[12],[1,\"\\n        The file name you selected is '\"],[1,[33,5,[\"selectedFile\",\"name\"]]],[1,\"'\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,0],[14,0,\"mt-4\"],[12],[1,\"The file name usually has one of these endings:\"],[13],[1,\"\\n  \"],[10,\"ul\"],[12],[1,\"\\n    \"],[10,\"li\"],[12],[1,\".tntdatasync\"],[13],[1,\"\\n    \"],[10,\"li\"],[12],[1,\".tntmpd\"],[13],[1,\"\\n    \"],[10,\"li\"],[12],[1,\".tntconnect\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"de-form-card\",\"form-file\",\"action\",\"if\",\"invalidFileExtension\",\"model\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/more/tnt-connect-import/data-sync/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});