define("donor-elf-web/controllers/admin/funds/fund/users/add", ["exports", "@ember/object/computed", "@ember/controller", "@ember/object", "@ember/utils", "rsvp", "@ember/service", "ember-concurrency"], function (_exports, _computed, _controller, _object, _utils, _rsvp, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    store: (0, _service.inject)(),
    storeCache: (0, _service.inject)(),
    flashMessages: (0, _service.inject)(),
    q: '',
    sortBy: ['user.displayName:asc'],
    sortedItems: (0, _computed.sort)('model.items', 'sortBy'),
    filteredItems: (0, _object.computed)('q', 'sortedItems', function () {
      let {
        q,
        sortedItems
      } = (0, _object.getProperties)(this, 'q', 'sortedItems');

      if ((0, _utils.isEmpty)(q)) {
        return sortedItems;
      }

      q = q.toLowerCase();
      return sortedItems.filter(item => {
        return (0, _object.get)(item, 'user.displayNameLowerCase').indexOf(q) >= 0;
      });
    }),
    selectedItems: (0, _object.computed)('model.items.@each.isSelected', function () {
      return (0, _object.get)(this, 'model.items').filter(item => {
        return (0, _object.get)(item, 'isSelected');
      });
    }),
    save: (0, _emberConcurrency.task)(function* () {
      let fundId = (0, _object.get)(this, 'model.fund.id');
      let {
        store,
        selectedItems
      } = (0, _object.getProperties)(this, 'store', 'selectedItems');
      let savePromises = [];
      selectedItems.forEach(item => {
        let newUserFund = store.createRecord('admin/user-fund', {
          userId: (0, _object.get)(item, 'user.id'),
          fundId: fundId
        });
        savePromises.push(newUserFund.save());
      });
      yield _rsvp.default.allSettled(savePromises);
      (0, _object.get)(this, 'storeCache').breakFindAllCache('admin/user-fund');
      this.send('refreshAdminRoute');
      this.transitionToRoute('admin.funds.fund');
    }).drop(),

    reset() {
      (0, _object.set)(this, 'q', '');
    }

  });

  _exports.default = _default;
});