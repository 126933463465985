define("donor-elf-web/components/add-something-modal/contact/phone-or-email", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service"], function (_exports, _component, _templateFactory, _component2, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='d-flex' ...attributes>
    <div class='tw-w-1 bg-brand mr-2 flex-shrink-0'></div>
    <div class='flex-grow-1'>
      <Form::Underline::Label
        @label='{{if (eq @type 'phone') 'Number' 'Email'}}'
      >
        <Form::Underline::Text
            @changeset={{@changeset}}
            @property='v'
            {{focus-on-insert}}
            class='w-100'
        />
      </Form::Underline::Label>
      <Form::Underline::Label @label='Label (optional)'>
        <Form::Underline::Text
            @changeset={{@changeset}}
            @property='l'
            class='w-100'
        />
      </Form::Underline::Label>
      {{#if this.showSyncToMailchimp}}
        <Form::Underline::Label
          @label='Sync w Mailchimp'
          class='mt-2'
        >
          <Form::Underline::Checkbox
            @changeset={{@changeset}}
            @property='syncWithMailchimp'
          />
        </Form::Underline::Label>
      {{/if}}
    </div>
  </div>
  */
  {
    "id": "Uk6NtpuW",
    "block": "[[[11,0],[24,0,\"d-flex\"],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"tw-w-1 bg-brand mr-2 flex-shrink-0\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex-grow-1\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@label\"],[[29,[[52,[28,[37,2],[[30,2],\"phone\"],null],\"Number\",\"Email\"]]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,3],[[24,0,\"w-100\"],[4,[38,4],null,null]],[[\"@changeset\",\"@property\"],[[30,3],\"v\"]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[39,0],null,[[\"@label\"],[\"Label (optional)\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,3],[[24,0,\"w-100\"]],[[\"@changeset\",\"@property\"],[[30,3],\"l\"]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"],[41,[30,0,[\"showSyncToMailchimp\"]],[[[1,\"      \"],[8,[39,0],[[24,0,\"mt-2\"]],[[\"@label\"],[\"Sync w Mailchimp\"]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,5],null,[[\"@changeset\",\"@property\"],[[30,3],\"syncWithMailchimp\"]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@type\",\"@changeset\"],false,[\"form/underline/label\",\"if\",\"eq\",\"form/underline/text\",\"focus-on-insert\",\"form/underline/checkbox\"]]",
    "moduleName": "donor-elf-web/components/add-something-modal/contact/phone-or-email.hbs",
    "isStrictMode": false
  });

  let AddSomethingModalContactPhoneOrEmailComponent = (_class = class AddSomethingModalContactPhoneOrEmailComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "settings", _descriptor, this);
    }

    get showSyncToMailchimp() {
      return this.args.type === 'email' && this.settings.mailchimpFundSyncSlim != null;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = AddSomethingModalContactPhoneOrEmailComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AddSomethingModalContactPhoneOrEmailComponent);
});