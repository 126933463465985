define("donor-elf-web/components/textarea-auto-expand", ["exports", "@glimmer/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class2;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  /*
    args
      - value
      - onChange(value)
  */
  let _class = (_class2 = class extends _component.default {
    handleChange(e) {
      var _this$args$onChange, _this$args;

      (_this$args$onChange = (_this$args = this.args).onChange) === null || _this$args$onChange === void 0 ? void 0 : _this$args$onChange.call(_this$args, e.target.value);
    }

    templateDidInsert(e) {
      this.element = e;
      this.setHeight();
    } // https://gomakethings.com/automatically-expand-a-textarea-as-the-user-types-using-vanilla-javascript/


    setHeight() {
      this.element.style.height = 'inherit'; // Get the computed styles for the element

      let computed = window.getComputedStyle(this.element); // Calculate the height

      let height = parseInt(computed.getPropertyValue('border-top-width'), 10) + parseInt(computed.getPropertyValue('padding-top'), 10) + this.element.scrollHeight + parseInt(computed.getPropertyValue('padding-bottom'), 10) + parseInt(computed.getPropertyValue('border-bottom-width'), 10);
      this.element.style.height = `${height}px`;
    }

  }, (_applyDecoratedDescriptor(_class2.prototype, "handleChange", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleChange"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "templateDidInsert"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "setHeight", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "setHeight"), _class2.prototype)), _class2);

  _exports.default = _class;
});