define("donor-elf-web/components/add-something-modal/campaign/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@ember/object", "@glimmer/tracking", "ember-changeset", "ember-changeset-validations", "donor-elf-web/validations/campaign"], function (_exports, _component, _templateFactory, _component2, _service, _object, _tracking, _emberChangeset, _emberChangesetValidations, _campaign) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div>
    <Modal::Header>
      {{if this.newCampaign 'Add' 'Edit'}} Campaign
    </Modal::Header>
    <div class='p-3'>
      <Form::Underline::Label @label='Name'>
        <Form::Underline::Text
          @changeset={{this.changeset}}
          @property='name'
          @showValidationErrors={{this.showValidationErrors}}
          {{on-enter this.save}}
          {{focus-on-insert delay=50}}
          class='w-100'
        />
      </Form::Underline::Label>
      {{#if this.saveError}}
        <GenericError
          @error={{this.saveError}}
          @useLeftBorderCard={{true}}
          class='mt-3'
        />
      {{/if}}
    </div>
    <Modal::Footer>
      <button
        {{on 'click' this.save}}
        class='btn btn-xs btn-warning'
        disabled='{{if this.saving 'disabled'}}'
        type='button'
      >
        {{#if this.saving}}
          Saving...
        {{else}}
          Save
        {{/if}}
      </button>
      <button
        {{on 'click' this.close}}
        class='btn btn-xs btn-link'
        type='button'
      >
        Cancel
      </button>
    </Modal::Footer>
  </div>
  */
  {
    "id": "kVPFI3rX",
    "block": "[[[10,0],[12],[1,\"\\n  \"],[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[1,[52,[30,0,[\"newCampaign\"]],\"Add\",\"Edit\"]],[1,\" Campaign\\n  \"]],[]]]]],[1,\"\\n  \"],[10,0],[14,0,\"p-3\"],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@label\"],[\"Name\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,3],[[24,0,\"w-100\"],[4,[38,4],[[30,0,[\"save\"]]],null],[4,[38,5],null,[[\"delay\"],[50]]]],[[\"@changeset\",\"@property\",\"@showValidationErrors\"],[[30,0,[\"changeset\"]],\"name\",[30,0,[\"showValidationErrors\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"],[41,[30,0,[\"saveError\"]],[[[1,\"      \"],[8,[39,6],[[24,0,\"mt-3\"]],[[\"@error\",\"@useLeftBorderCard\"],[[30,0,[\"saveError\"]],true]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n  \"],[8,[39,7],null,null,[[\"default\"],[[[[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-xs btn-warning\"],[16,\"disabled\",[29,[[52,[30,0,[\"saving\"]],\"disabled\"]]]],[24,4,\"button\"],[4,[38,8],[\"click\",[30,0,[\"save\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"saving\"]],[[[1,\"        Saving...\\n\"]],[]],[[[1,\"        Save\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-xs btn-link\"],[24,4,\"button\"],[4,[38,8],[\"click\",[30,0,[\"close\"]]],null],[12],[1,\"\\n      Cancel\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"modal/header\",\"if\",\"form/underline/label\",\"form/underline/text\",\"on-enter\",\"focus-on-insert\",\"generic-error\",\"modal/footer\",\"on\"]]",
    "moduleName": "donor-elf-web/components/add-something-modal/campaign/index.hbs",
    "isStrictMode": false
  });

  let AddSomethingModalCampaignIndexComponent = (_class = class AddSomethingModalCampaignIndexComponent extends _component2.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "addSomethingModal", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "campaignService", _descriptor3, this);

      _initializerDefineProperty(this, "changeset", _descriptor4, this);

      _initializerDefineProperty(this, "showValidationErrors", _descriptor5, this);

      _initializerDefineProperty(this, "saving", _descriptor6, this);

      _initializerDefineProperty(this, "saveError", _descriptor7, this);

      this.createChangeset();
    }

    get newCampaign() {
      var _this$args$openArgs, _this$args$openArgs$c;

      return ((_this$args$openArgs = this.args.openArgs) === null || _this$args$openArgs === void 0 ? void 0 : (_this$args$openArgs$c = _this$args$openArgs.campaignOptions) === null || _this$args$openArgs$c === void 0 ? void 0 : _this$args$openArgs$c.existingCampaign) == null;
    }

    createChangeset() {
      let campaign = this.newCampaign ? this.store.createRecord('campaign') : this.args.openArgs.campaignOptions.existingCampaign;
      this.changeset = new _emberChangeset.default(campaign, (0, _emberChangesetValidations.default)(_campaign.default), _campaign.default);
    }

    async save() {
      if (this.saving) {
        return;
      }

      this.showValidationErrors = false;
      await this.changeset.validate();

      if (this.changeset.get('isValid')) {
        try {
          this.saving = true;
          await this.changeset.save();
          let campaign = this.changeset.get('data');

          if (this.newCampaign) {
            this.campaignService.campaigns = [...this.campaignService.campaigns, campaign];
          }

          this.addSomethingModal.campaignSaved({
            campaign,
            newCampaign: this.newCampaign
          });
          this.addSomethingModal.close();
        } catch (e) {
          this.saveError = e;
        }

        this.saving = false;
      } else {
        this.showValidationErrors = true;
      }
    }

    close() {
      this.addSomethingModal.close();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "addSomethingModal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "campaignService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "changeset", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showValidationErrors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "saving", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "saveError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype)), _class);
  _exports.default = AddSomethingModalCampaignIndexComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AddSomethingModalCampaignIndexComponent);
});