define("donor-elf-web/lib/number-utils", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    // serializes a string value to a number
    // if the value is empty, then it returns null
    deserializeNumber(valString) {
      if ((0, _utils.isEmpty)(valString)) {
        return null;
      }

      return Number(valString);
    },

    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/round
    roundPrecision(number, precision) {
      let factor = Math.pow(10, precision);
      return Math.round(number * factor) / factor;
    },

    // taken from https://github.com/offirgolan/ember-validators/blob/master/addon/number.js#L109
    isNumber(value) {
      return typeof value === 'number' && !isNaN(value);
    },

    isInteger(value) {
      return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
    },

    milesToMeters(miles) {
      return miles / 0.00062137;
    }

  };
  _exports.default = _default;
});