define("donor-elf-web/controllers/funds/fund/more/karani-import/processing", ["exports", "@ember/controller", "@ember/object", "@ember/service", "donor-elf-web/lib/logger"], function (_exports, _controller, _object, _service, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    contactsCache: (0, _service.inject)(),
    fundsService: (0, _service.inject)(),
    store: (0, _service.inject)(),
    checkFundUploadProgress: false,
    hasError: false,

    reset() {
      (0, _object.setProperties)(this, {
        checkFundUploadProgress: false,
        hasError: false
      });
    },

    actions: {
      handleS3UploadComplete(s3Upload) {
        this.fundsService.confirmValidFundToken().then(() => {
          let importRecord = this.store.createRecord('karani-import', {
            s3Key: s3Upload.key,
            includePledges: this.model.includePledges,
            includeTasks: this.model.includeTasks
          });
          importRecord.save().then(() => {
            (0, _object.set)(this, 'checkFundUploadProgress', true);
          });
        });
      },

      handleS3UploadError() {
        _logger.default.error('There was an error uploading the Karani file');
      },

      onFundUploadComplete() {
        this.contactsCache.loadContacts({
          forceLoad: true
        }).then(() => {
          this.transitionToRoute('funds.fund.more.karani-import.complete');
        });
      },

      onFundUploadError() {
        (0, _object.set)(this, 'hasError', true);
      }

    }
  });

  _exports.default = _default;
});