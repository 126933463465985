define("donor-elf-web/routes/funds/fund/transactions/delete", ["exports", "@ember/routing/route", "@ember/object", "@ember/service"], function (_exports, _route, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    abilities: (0, _service.inject)(),
    fundsService: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),
    store: (0, _service.inject)(),

    beforeModel() {
      (0, _object.set)(this, 'navbarSettings.title', 'Delete Transaction');
      return (0, _object.get)(this, 'fundsService').confirmValidFundToken();
    },

    model(params, transition) {
      return this.store.findRecord('line-item', params.id).then(line => {
        // Can't edit non-manual transaction if you don't have the edit_all_transactions ability
        if (!line.isManual && this.abilities.noAbility('edit_all_transactions')) {
          transition.abort();
          return;
        }

        return {
          line
        };
      });
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.reset();
      }
    }

  });

  _exports.default = _default;
});