define("donor-elf-web/components/coaching-response-chart", ["exports", "@ember/component", "@ember/object", "@ember/service", "@ember/runloop"], function (_exports, _component, _object, _service, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Highcharts
  } = window;
  /*
    attrs
    - field
    - report
    - individual
  */

  var _default = _component.default.extend({
    coachingService: (0, _service.inject)(),
    field: null,
    individual: null,
    report: null,
    displayTooltip: false,
    chartElement: null,
    // set in didReceiveAttrs()
    displayChart: (0, _object.computed)('field.id', 'report.id', function () {
      return (0, _object.get)(this, 'reportIndex') > 0;
    }),
    reportIndex: (0, _object.computed)('report.id', function () {
      let {
        individual,
        report
      } = (0, _object.getProperties)(this, 'individual', 'report');
      return (0, _object.get)(individual, 'sortedReports').indexOf(report);
    }),
    totalResponses: (0, _object.computed)('field.id', 'report.id', function () {
      let {
        field,
        individual,
        reportIndex,
        coachingService
      } = (0, _object.getProperties)(this, 'field', 'individual', 'report', 'reportIndex', 'coachingService');
      let reports = (0, _object.get)(individual, 'sortedReports').toArray();
      let totalResponses = {
        yes: 0,
        no: 0,
        maybe: 0
      };

      for (let i = 0; i <= reportIndex; i++) {
        let val = coachingService.getFieldValue(field, reports[i]) || {
          yes: 0,
          no: 0,
          maybe: 0
        };
        totalResponses.yes += val.yes;
        totalResponses.no += val.no;
        totalResponses.maybe += val.maybe;
      }

      totalResponses.total = totalResponses.yes + totalResponses.no + totalResponses.maybe;
      totalResponses.yesPercent = Math.round(totalResponses.yes * 100 / totalResponses.total);
      totalResponses.noPercent = Math.round(totalResponses.no * 100 / totalResponses.total);
      totalResponses.maybePercent = 100 - totalResponses.yesPercent - totalResponses.noPercent;
      return totalResponses;
    }),
    chartSettings: (0, _object.computed)('field.id', 'report.id', function () {
      let totalResponses = (0, _object.get)(this, 'totalResponses');
      return {
        chart: {
          type: 'pie',
          spacing: [0, 0, 0, 0],
          animation: false,
          height: 65,
          width: 65
        },
        title: {
          text: null
        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        plotOptions: {
          pie: {
            shadow: false,
            center: ['50%', '50%']
          },
          series: {
            animation: false,
            states: {
              hover: {
                enabled: false
              }
            },
            events: {
              click: () => {
                this.toggleProperty('displayTooltip');
              },
              mouseOver: () => {
                (0, _object.set)(this, 'displayTooltip', true);
              },
              mouseOut: () => {
                (0, _object.set)(this, 'displayTooltip', false);
              }
            }
          }
        },
        series: [{
          size: '100%',
          innerSize: '55%',
          dataLabels: {
            enabled: false
          },
          data: [{
            y: totalResponses.yes,
            color: '#3fb852'
          }, {
            y: totalResponses.no,
            color: '#d54c37'
          }, {
            y: totalResponses.maybe,
            color: '#f0d15b'
          }]
        }]
      };
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      (0, _object.set)(this, 'tooltipVal', null);

      if ((0, _object.get)(this, 'displayChart')) {
        _runloop.run.scheduleOnce('afterRender', () => {
          Highcharts.chart(this.element.querySelector('.coaching-interval-chart'), this.chartSettings);
          (0, _object.set)(this, 'chartElement', this.element.querySelector('.coaching-interval-chart'));
        });
      }
    }

  });

  _exports.default = _default;
});