define("donor-elf-web/routes/funds/fund/goal/ytd", ["exports", "@ember/routing/route", "@ember/object", "@ember/object/computed", "@ember/service", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators"], function (_exports, _route, _object, _computed, _service, _emberChangeset, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const VALIDATION = {
    annualGoal: [(0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    }), (0, _validators.validateNumber)({
      gte: 1,
      message: 'Must be a positive number'
    })],
    startAtMonth: [(0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    }), (0, _validators.validateNumber)({
      gte: 0,
      lte: 12,
      message: 'Must be a number '
    })]
  };

  var _default = _route.default.extend({
    fundsService: (0, _service.inject)(),
    abilities: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),
    settings: (0, _service.inject)(),
    store: (0, _service.inject)(),
    isAnnualRollingGoal: (0, _computed.alias)('settings.userSettings.showAnnualGoal'),

    beforeModel(transition) {
      if ((0, _object.get)(this, 'abilities').noAbility('edit_goal')) {
        transition.abort();
      }

      return (0, _object.get)(this, 'fundsService').confirmValidFundToken();
    },

    model() {
      return this.store.query('ytd-fund-goal', {}).then(records => {
        let record = records.firstObject;

        if (record) {
          (0, _object.set)(this, 'navbarSettings.title', 'Edit Annual Goal');
        } else {
          (0, _object.set)(this, 'navbarSettings.title', 'Add Annual Goal');
          record = this.store.createRecord('ytd-fund-goal');
        }

        let changeset = new _emberChangeset.default(record, (0, _emberChangesetValidations.default)(VALIDATION), VALIDATION);
        let defaultStartAtMonth = this.settings.orgSettings.ytdStartAtMonth;

        if (defaultStartAtMonth) {
          (0, _object.set)(changeset, 'startAtMonth', defaultStartAtMonth);
        }

        return {
          record,
          changeset,
          defaultStartAtMonth
        };
      });
    }

  });

  _exports.default = _default;
});