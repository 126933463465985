define("donor-elf-web/helpers/highlight-fuzzy-result", ["exports", "@ember/string", "@ember/component/helper", "fuzzysort"], function (_exports, _string, _helper, _fuzzysort) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.highlightFuzzyResult = highlightFuzzyResult;
  _exports.default = void 0;

  // if the fuzzysort uses the { keys: [] } option, then be sure to include the keysIndexTarget positional argument for the target you want to highlight
  function highlightFuzzyResult([fuzzyResult], positionalArgs) {
    if (positionalArgs.keysIndexTarget != null && fuzzyResult[positionalArgs.keysIndexTarget] == null) {
      return positionalArgs.valueWhenNull;
    }

    let open = `<span class="${positionalArgs.highlightedClass}">`;
    let close = '</span>';
    let highlighted;

    if (positionalArgs.keysIndexTarget == null) {
      highlighted = _fuzzysort.default.highlight(fuzzyResult, open, close);
    } else {
      highlighted = _fuzzysort.default.highlight(fuzzyResult[positionalArgs.keysIndexTarget], open, close);
    }

    return (0, _string.htmlSafe)(highlighted || '');
  }

  var _default = (0, _helper.helper)(highlightFuzzyResult);

  _exports.default = _default;
});