define("donor-elf-web/components/coaching-reports-pagination", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/runloop", "@ember/service", "ember-concurrency"], function (_exports, _computed, _component, _object, _runloop, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* attrs
      - individual
      - currentReport
      - checkForShowCoachingPaginationModal - boolean
      - actions
        - onShowCoachingPaginationModalChange(show - boolean)
          - only fired if checkForShowCoachingPaginationModal is true
  */
  var _default = _component.default.extend({
    tagName: 'nav',
    coachingService: (0, _service.inject)(),
    router: (0, _service.inject)(),
    individual: null,
    report: null,
    checkForShowCoachingPaginationModal: false,
    showCoachingPaginationModal: false,
    reportIndex: (0, _object.computed)('report.id', function () {
      let {
        individual,
        report
      } = (0, _object.getProperties)(this, 'individual', 'report');
      return (0, _object.get)(individual, 'sortedReports').indexOf(report);
    }),
    isPrevDisabled: (0, _computed.equal)('reportIndex', 0),
    isNextDisabled: (0, _object.computed)('reportIndex', 'individual.reports.length', function () {
      return (0, _object.get)(this, 'reportIndex') >= (0, _object.get)(this, 'individual.reports.length') - 1;
    }),
    checkShowCoachingPaginationModal: (0, _emberConcurrency.task)(function* () {
      let showCoachingPaginationModal = (0, _object.get)(this, 'scrollDiv').scrollTop > 100;

      if (showCoachingPaginationModal !== (0, _object.get)(this, 'showCoachingPaginationModal')) {
        (0, _object.set)(this, 'showCoachingPaginationModal', showCoachingPaginationModal);
        (0, _object.get)(this, 'onShowCoachingPaginationModalChange')(showCoachingPaginationModal);
      }

      yield (0, _emberConcurrency.timeout)(250);
      (0, _object.get)(this, 'checkShowCoachingPaginationModal').perform();
    }).keepLatest(),

    didInsertElement() {
      this._super(...arguments);

      if ((0, _object.get)(this, 'checkForShowCoachingPaginationModal')) {
        let scrollDiv = document.getElementsByClassName('bm-content')[0];
        (0, _object.setProperties)(this, {
          scrollDiv,
          showCoachingPaginationModal: false
        });
        (0, _object.get)(this, 'checkShowCoachingPaginationModal').perform();
      }
    },

    handleOnOpen: (0, _object.action)(function (dropdown) {
      (0, _object.setProperties)(this, {
        dropdown,
        pageVal: ''
      });
    }),
    actions: {
      closeDropdown() {
        (0, _runloop.next)(() => {
          let dropdown = (0, _object.get)(this, 'dropdown');

          if (dropdown) {
            dropdown.actions.close();
          }
        });
      },

      prevReport() {
        let prevReport = (0, _object.get)(this, 'individual.sortedReports').objectAt((0, _object.get)(this, 'reportIndex') - 1);
        (0, _object.get)(this, 'router').transitionTo('coaching.individuals.individual.report', (0, _object.get)(prevReport, 'id'));
      },

      nextReport() {
        let nextReport = (0, _object.get)(this, 'individual.sortedReports').objectAt((0, _object.get)(this, 'reportIndex') + 1);
        (0, _object.get)(this, 'router').transitionTo('coaching.individuals.individual.report', (0, _object.get)(nextReport, 'id'));
      }

    }
  });

  _exports.default = _default;
});