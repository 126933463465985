define("donor-elf-web/controllers/funds/fund/pledge-fulfillment", ["exports", "@ember/controller", "donor-elf-web/lib/export-contacts-to-csv-rows", "file-saver", "@ember/object", "@ember/service"], function (_exports, _controller, _exportContactsToCsvRows, _fileSaver, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Papa,
    moment
  } = window;

  var _default = _controller.default.extend({
    formatDateService: (0, _service.inject)(),
    fundsService: (0, _service.inject)(),
    contactsCache: (0, _service.inject)(),
    store: (0, _service.inject)(),
    actionsMenuItems: (0, _object.computed)(function () {
      return [{
        label: 'Download',
        svgJar: 'download',
        onClick: () => {
          this.send('download');
        }
      }];
    }),
    actions: {
      download() {
        let {
          formatDateService,
          contactsCache
        } = (0, _object.getProperties)(this, 'formatDateService', 'contactsCache');
        let today = moment();
        let rows = [];
        let headerRow = ['Days Overdue Group', 'Contact', 'Start Date', 'End Date', 'Frequency', 'Amount', 'Last Gift', 'Next Expected Gift', 'Days Overdue', 'Notes', '---'];

        let headerInfo = _exportContactsToCsvRows.default.addContactsHeaderColumns(headerRow, (0, _object.get)(this, 'contactsCache.contacts'));

        rows.push(headerRow);

        let createRow = (pledge, daysOverdueGroup) => {
          let contact = contactsCache.getById((0, _object.get)(pledge, 'contactId')) || {};
          let daysOverdue = today.diff(pledge.nextExpectedDonationDate, 'days');

          if (pledge.nextExpectedDonationDate) {
            if (daysOverdue < 0) {
              daysOverdue = 0;
            }
          } else {
            daysOverdue = null;
          }

          let row = [daysOverdueGroup, (0, _object.get)(contact, 'name'), formatDateService.formatForFile((0, _object.get)(pledge, 'startDate')), formatDateService.formatForFile((0, _object.get)(pledge, 'endDate')), (0, _object.get)(pledge, 'frequencyLabel'), (0, _object.get)(pledge, 'amount'), formatDateService.formatForFile((0, _object.get)(pledge, 'lastDonationDate')), formatDateService.formatForFile((0, _object.get)(pledge, 'nextExpectedDonationDate')), daysOverdue, (0, _object.get)(pledge, 'notes'), ''];

          _exportContactsToCsvRows.default.addContactInfoToRow(row, contact, headerInfo);

          return row;
        };

        let sortRows = (a, b) => {
          let pledgeAOverdue = a[7];
          let pledgeBOverdue = b[7];

          if (pledgeAOverdue > pledgeBOverdue) {
            return 1;
          } else if (pledgeBOverdue > pledgeAOverdue) {
            return -1;
          } else {
            return 0;
          }
        };

        let {
          ninetyDays,
          sixtyDays,
          thirtyDays,
          current,
          noGifts,
          ended
        } = this.model;
        let groupRows = [];
        ninetyDays.forEach(item => {
          groupRows.push(createRow(item, '90+ Days Overdue'));
        });
        rows = rows.concat(groupRows.sort(sortRows));
        groupRows = [];
        sixtyDays.forEach(item => {
          groupRows.push(createRow(item, '60+ Days Overdue'));
        });
        rows = rows.concat(groupRows.sort(sortRows));
        groupRows = [];
        thirtyDays.forEach(item => {
          groupRows.push(createRow(item, '30+ Days Overdue'));
        });
        rows = rows.concat(groupRows.sort(sortRows));
        groupRows = [];
        current.forEach(item => {
          groupRows.push(createRow(item, 'Current'));
        });
        rows = rows.concat(groupRows.sort(sortRows));
        groupRows = [];
        noGifts.forEach(item => {
          groupRows.push(createRow(item, 'No Gifts'));
        });
        rows = rows.concat(groupRows.sort(sortRows));
        groupRows = [];
        ended.forEach(item => {
          groupRows.push(createRow(item, 'Ended'));
        });
        rows = rows.concat(groupRows.sort(sortRows));
        this.store.createRecord('contact-download').save();
        let csv = Papa.unparse(rows);
        var blob = new Blob([csv], {
          type: "text/csv;charset=utf-8"
        });

        _fileSaver.default.saveAs(blob, 'pledge-fulfillment.csv', true);
      }

    }
  });

  _exports.default = _default;
});