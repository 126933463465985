define("donor-elf-web/controllers/funds/fund/journals/summaries/view", ["exports", "@ember/controller", "@glimmer/tracking", "@ember/service", "@ember/object", "donor-elf-web/lib/store-utils", "donor-elf-web/lib/journal-utils", "@ember/template"], function (_exports, _controller, _tracking, _service, _object, _storeUtils, _journalUtils, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let JournalsSummariesViewController = (_class = class JournalsSummariesViewController extends _controller.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['ids']);

      _initializerDefineProperty(this, "fundsService", _descriptor, this);

      _initializerDefineProperty(this, "formatDateService", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "journalService", _descriptor4, this);

      _initializerDefineProperty(this, "ids", _descriptor5, this);

      _initializerDefineProperty(this, "loading", _descriptor6, this);

      _initializerDefineProperty(this, "items", _descriptor7, this);
    }

    get journalIds() {
      var _this$ids;

      return ((_this$ids = this.ids) === null || _this$ids === void 0 ? void 0 : _this$ids.split('-')) || [];
    }

    get percentDownloaded() {
      let {
        journalIds,
        items
      } = this;

      if (journalIds.length === 0) {
        return 0;
      }

      return Math.max(Math.round(items.length * 100 / journalIds.length), 5);
    }

    get progressBarStyle() {
      return (0, _template.htmlSafe)(`width: ${this.percentDownloaded}%`);
    }

    async templateDidInsert() {
      this.loading = true;
      this.items = [];
      let allFundsInfo = await this.fundsService.getAllFundsInfo();
      await (0, _storeUtils.queryWithPages)({
        store: this.store,
        modelName: 'journal-summary-detail',
        queryOptions: {
          filter: {
            ids: this.journalIds
          }
        },
        pageSize: 5,
        pageCallback: args => {
          let pageItems = [];
          args.pageRecords.forEach(journalSummaryDetail => {
            let fund = allFundsInfo.fundsById[journalSummaryDetail.fundId];
            let journalGoal = journalSummaryDetail.journalGoals.toArray()[0];
            let journalIntervalDates = journalSummaryDetail.intervalDates.toArray().map(x => new _journalUtils.JournalIntervalDate({
              start: x.start,
              end: x.end,
              formatDateService: this.formatDateService
            }));
            let yesDecisions = journalSummaryDetail.decisions.filter(x => x.yesNo && x.journalGoalId == journalGoal.id).map(x => {
              return {
                decisionDate: x.decisionDateString,
                amount: x.amount,
                firstGiftDate: x.firstGiftDateString
              };
            });
            let intervalSummaries = (0, _journalUtils.CalculateIntervalCumulativeDecisionSummaries)(journalIntervalDates, yesDecisions);
            pageItems.push({
              fund,
              journal: journalSummaryDetail.journal,
              journalGoal,
              intervalSummaries
            });
          });
          this.items = [...this.items, ...pageItems];
        }
      });
      this.loading = false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "formatDateService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "journalService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "ids", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "items", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype)), _class);
  _exports.default = JournalsSummariesViewController;
});