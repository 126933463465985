define("donor-elf-web/templates/funds/fund/contacts/upload/save", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "j5vkEhI5",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[10,\"h3\"],[14,0,\"mb-3\"],[12],[1,\"Saving the Contacts\"],[13],[1,\"\\n\"],[41,[30,0,[\"hasMoreContactsToSave\"]],[[[1,\"    \"],[10,2],[12],[1,\"\\n      Saving \"],[1,[30,0,[\"savingContactNumber\"]]],[1,\" of \"],[1,[30,0,[\"contactsToSave\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,2],[[24,0,\"mb-3\"]],null,[[\"default\"],[[[[1,\"\\n      The \"],[1,[30,0,[\"contactsToSave\"]]],[1,\" contacts from the file have been uploaded.\\n    \"]],[]]]]],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      The newly imported contacts are in the \"],[10,\"strong\"],[12],[1,[30,0,[\"uploadContacts\",\"importGroupName\"]]],[13],[1,\"\\n      group. Filter by this group to view them easily.\\n    \"],[13],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[8,[39,3],[[24,0,\"btn btn-warning\"]],[[\"@route\"],[\"funds.fund.contacts\"]],[[\"default\"],[[[[1,\"\\n        View your Contacts\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[41,[30,0,[\"error\"]],[[[1,\"    \"],[8,[39,4],[[24,0,\"mt-3\"]],[[\"@error\",\"@useLeftBorderCard\"],[[30,0,[\"error\"]],true]],null],[1,\"\\n\"]],[]],null]],[]]]]],[1,\" \"]],[],false,[\"form-card\",\"if\",\"alert/info\",\"link-to\",\"generic-error\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/contacts/upload/save.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});