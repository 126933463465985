define("donor-elf-web/controllers/funds/fund/groups/create", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service", "ember-concurrency"], function (_exports, _controller, _object, _computed, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    contactsCache: (0, _service.inject)(),
    fundsService: (0, _service.inject)(),
    error: null,

    reset() {
      (0, _object.set)(this, 'error', null);
    },

    sortBy: Object.freeze(['name:asc']),
    sortedContacts: (0, _computed.sort)('model.contacts', 'sortBy'),

    addContactsToGroup(groupName) {
      return new Promise((resolve, reject) => {
        (0, _object.get)(this, 'model.contacts').reduce(function (promise, contact) {
          return promise.then(function () {
            (0, _object.get)(contact, 'groups').pushObject(groupName);
            return contact.save();
          });
        }, Promise.resolve()).then(() => {
          (0, _object.get)(this, 'contactsCache').incrementProperty('groupsCacheIndex');
          resolve();
        }, error => {
          reject(error);
        });
      });
    },

    save: (0, _emberConcurrency.task)(function* () {
      let changeset = (0, _object.get)(this, 'model.changeset');
      let groupName = (0, _object.get)(changeset, 'name');
      yield changeset.validate();

      if ((0, _object.get)(changeset, 'isValid')) {
        try {
          yield (0, _object.get)(this, 'fundsService').confirmValidFundToken();
          yield this.addContactsToGroup(groupName);
          this.transitionToRoute('funds.fund.groups.group', groupName);
        } catch (error) {
          (0, _object.set)(this, 'error', error);
        }
      }
    }).drop()
  });

  _exports.default = _default;
});