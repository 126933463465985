define("donor-elf-web/templates/components/group-add-contact-row", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Vav3IJkj",
    "block": "[[[10,\"td\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"d-flex\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"pr-3\"],[12],[1,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-sm btn-outline-warning\"],[16,\"title\",[29,[\"Add to \",[36,0],\" group\"]]],[16,\"disabled\",[29,[[52,[33,2],\"disabled\"]]]],[4,[38,3],[[30,0],\"add\"],null],[12],[1,\"\\n        \"],[1,[28,[35,4],[\"plus\"],null]],[1,\"\\n        Add\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[1,[28,[35,5],null,[[\"contactId\"],[[33,6,[\"id\"]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"group\",\"if\",\"removing\",\"action\",\"svg-jar\",\"de-contact\",\"contact\"]]",
    "moduleName": "donor-elf-web/templates/components/group-add-contact-row.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});