define("donor-elf-web/components/journal/cell/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!--
   args
    - journalRow
    - journalStageColumn
    - allSegments
    - taskToPaste
    - showAssignedUsersIfPresent - boolean
    - onGroupClicked(groupName)
    - onSegmentClicked(groupName)
    - onRecommendedBySaved(recommendation: RecommendationModel)
    - onChange(args: onJournalChangeArgs)
      - not all the child components includes the args. Need to implement that in the future
    - onCopyTask(task)
    - onRemoveJournalRow(journalRow)
  --}}
  {{#if @journalStageColumn.isContactColumn}}
    <Journal::Cell::Contact
      @journalRow={{@journalRow}}
      @allSegments={{@allSegments}}
      @onGroupClicked={{@onGroupClicked}}
      @onSegmentClicked={{@onSegmentClicked}}
      @onRecommendedBySaved={{@onRecommendedBySaved}}
      @onRemoveJournalRow={{@onRemoveJournalRow}}
      @onChange={{@onChange}}
    />
  {{else}}
    <Journal::Cell::Stage
      @journalRow={{@journalRow}}
      @journalStageColumn={{@journalStageColumn}}
      @taskToPaste={{@taskToPaste}}
      @showAssignedUsersIfPresent={{@showAssignedUsersIfPresent}}
      @onChange={{@onChange}}
      @onCopyTask={{@onCopyTask}}
    />
  {{/if}}
  */
  {
    "id": "s9XQteMG",
    "block": "[[[41,[30,1,[\"isContactColumn\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@journalRow\",\"@allSegments\",\"@onGroupClicked\",\"@onSegmentClicked\",\"@onRecommendedBySaved\",\"@onRemoveJournalRow\",\"@onChange\"],[[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8]]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,2],null,[[\"@journalRow\",\"@journalStageColumn\",\"@taskToPaste\",\"@showAssignedUsersIfPresent\",\"@onChange\",\"@onCopyTask\"],[[30,2],[30,1],[30,9],[30,10],[30,8],[30,11]]],null],[1,\"\\n\"]],[]]]],[\"@journalStageColumn\",\"@journalRow\",\"@allSegments\",\"@onGroupClicked\",\"@onSegmentClicked\",\"@onRecommendedBySaved\",\"@onRemoveJournalRow\",\"@onChange\",\"@taskToPaste\",\"@showAssignedUsersIfPresent\",\"@onCopyTask\"],false,[\"if\",\"journal/cell/contact\",\"journal/cell/stage\"]]",
    "moduleName": "donor-elf-web/components/journal/cell/index.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});