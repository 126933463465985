define("donor-elf-web/templates/components/form-group-date", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "nUDLfz9g",
    "block": "[[[41,[33,1],[[[41,[33,2],[[[1,\"    \"],[10,\"label\"],[12],[2,[36,1]],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"label\"],[12],[1,[34,1]],[13],[1,\"\\n\"]],[]]]],[]],null],[1,[28,[35,3],null,[[\"value\",\"format\",\"firstDay\",\"disabled\",\"class\",\"onOpen\",\"onSelection\"],[[28,[37,4],[[33,5],[33,6]],null],[33,7,[\"dateFormat4Year\"]],0,[30,0,[\"disabled\"]],\"form-control\",[28,[37,8],[[30,0],\"handleOpened\"],null],[28,[37,8],[[30,0],\"handleSelected\"],null]]]]],[1,\"\\n\"],[41,[33,9],[[[1,\"  \"],[10,0],[14,0,\"has-errors-message\"],[12],[1,[28,[35,4],[[33,5,[\"error\"]],[28,[37,10],[[33,6],\".validation\"],null]],null]],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"label\",\"labelIsHtmlSafe\",\"pikaday-input\",\"get\",\"changeset\",\"property\",\"settings\",\"action\",\"hasErrors\",\"concat\"]]",
    "moduleName": "donor-elf-web/templates/components/form-group-date.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});