define("donor-elf-web/routes/funds/fund/more/mailchimp", ["exports", "@ember/routing/route", "@ember/service", "@ember/object"], function (_exports, _route, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let MailchimpRoute = (_class = class MailchimpRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "abilities", _descriptor, this);

      _initializerDefineProperty(this, "navbarSettings", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);
    }

    beforeModel() {
      // forcing everyone to the new mailchimp sync
      this.transitionTo('funds.fund.more.mailchimp-sync');
      return;
    }

    async model() {
      var _mailchimpSetting$fun, _mailchimpSetting$fun2, _mailchimpSetting$fun3;

      let mailchimpSetting = (await this.store.query('mailchimp-setting', {})).toArray()[0];
      let firstMailchimpFundSettingFundId = mailchimpSetting === null || mailchimpSetting === void 0 ? void 0 : (_mailchimpSetting$fun = mailchimpSetting.fundSettings) === null || _mailchimpSetting$fun === void 0 ? void 0 : (_mailchimpSetting$fun2 = _mailchimpSetting$fun.toArray()) === null || _mailchimpSetting$fun2 === void 0 ? void 0 : (_mailchimpSetting$fun3 = _mailchimpSetting$fun2[0]) === null || _mailchimpSetting$fun3 === void 0 ? void 0 : _mailchimpSetting$fun3.fundId;

      if (firstMailchimpFundSettingFundId) {
        let firstMailchimpFundSettingFund = await this.store.findRecord('fund', firstMailchimpFundSettingFundId);
        return {
          mailchimpSetting,
          firstMailchimpFundSettingFund
        };
      } else {
        return {
          mailchimpSetting
        };
      }
    }

    refreshMailchimpRoute() {
      this.refresh();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "abilities", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "navbarSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "refreshMailchimpRoute", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "refreshMailchimpRoute"), _class.prototype)), _class);
  _exports.default = MailchimpRoute;
});