define("donor-elf-web/components/monthly-summary-chart", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/runloop", "@ember/service", "donor-elf-web/lib/filter-utils-new"], function (_exports, _computed, _component, _object, _runloop, _service, _filterUtilsNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Highcharts
  } = window;
  /*
    attrs
      - months
      - includeExpenses
      - includeBalance
  
      - transitionTo() -
  */

  var _default = _component.default.extend({
    formatAmountService: (0, _service.inject)(),
    router: (0, _service.inject)(),
    months: null,
    includeExpenses: false,
    includeBalance: false,
    incomeSeriesData: (0, _object.computed)('months.[]', function () {
      return (0, _object.get)(this, 'months').map(item => {
        return {
          y: (0, _object.get)(item, 'income'),
          item: item
        };
      });
    }),
    expenseSeriesData: (0, _object.computed)('months.[]', function () {
      return (0, _object.get)(this, 'months').map(item => {
        return {
          y: (0, _object.get)(item, 'expense') * -1,
          item: item
        };
      });
    }),
    balanceSeriesData: (0, _object.computed)('months.[]', function () {
      return (0, _object.get)(this, 'months').map(item => {
        return {
          y: (0, _object.get)(item, 'balance'),
          item: item
        };
      });
    }),
    minBalance: (0, _computed.min)('balanceSeriesData'),
    categories: (0, _object.computed)('months.[]', function () {
      return (0, _object.get)(this, 'months').map(item => {
        return (0, _object.get)(item, 'label');
      });
    }),
    chartSettings: (0, _object.computed)('months.[]', function () {
      let self = this;
      let minYAxis = undefined;

      if (!(0, _object.get)(this, 'includeBalance') || (0, _object.get)(this, 'minBalance') > 0) {
        minYAxis = 0;
      }

      let series = [{
        data: (0, _object.get)(this, 'incomeSeriesData'),
        name: 'Income',
        color: '#5cb85c',
        events: {
          click(event) {
            self.router.transitionTo('funds.fund.transactions', {
              queryParams: {
                filters: (0, _filterUtilsNew.serializeAppliedFilters)([{
                  propertyName: 'transDate',
                  operation: '><',
                  val: [event.point.item.from, event.point.item.to]
                }, {
                  propertyName: 'income'
                }])
              }
            });
          }

        }
      }];

      if ((0, _object.get)(this, 'includeExpenses')) {
        series.push({
          data: (0, _object.get)(this, 'expenseSeriesData'),
          name: 'Expenses',
          color: '#d9534f',
          events: {
            click(event) {
              self.transitionTo('funds.fund.transactions', {
                queryParams: {
                  filters: (0, _filterUtilsNew.serializeAppliedFilters)([{
                    propertyName: 'transDate',
                    operation: '><',
                    val: [event.point.item.from, event.point.item.to]
                  }, {
                    propertyName: 'expense'
                  }])
                }
              });
            }

          }
        });
      }

      if ((0, _object.get)(this, 'includeBalance')) {
        series.push({
          data: (0, _object.get)(this, 'balanceSeriesData'),
          name: 'Balance',
          color: '#0b68a1',
          type: 'line'
        });
      }

      return {
        chart: {
          type: 'column',
          height: 300
        },
        title: null,
        credits: {
          enabled: false
        },
        xAxis: {
          categories: (0, _object.get)(this, 'categories'),
          tickInterval: 3,
          tickLength: 0,
          labels: {
            autoRotation: false,
            align: 'left',
            y: 13
          }
        },
        yAxis: {
          min: minYAxis,
          maxPadding: 0.0,
          endOnTick: false,
          title: {
            text: null
          }
        },
        legend: {
          enabled: false
        },
        tooltip: {
          formatter: function () {
            return `${this.x} ${this.series.name}: <b>${self.formatAmountService.format(this.y)}</b>`;
          }
        },
        plotOptions: {
          column: {
            groupPadding: 0.2,
            pointPadding: 0.01
          }
        },
        series
      };
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      (0, _runloop.next)(() => {
        Highcharts.chart(this.element, this.chartSettings);
      });
    }

  });

  _exports.default = _default;
});