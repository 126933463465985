define("donor-elf-web/templates/components/coaching-response-chart", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "TnD3AiDp",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,0],[14,0,\"coaching-interval-chart\"],[14,5,\"margin-top: -5px\"],[12],[13],[1,\"\\n\"],[41,[33,2],[[[6,[39,3],null,[[\"target\",\"attachment\",\"class\"],[[33,4],\"middle right\",\"coaching-interval-chart-tooltip\"]],[[\"default\"],[[[[1,\"      \"],[10,0],[14,0,\"text-left\"],[12],[1,\"\\n        Total Responses\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"d-flex\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"flex-grow-1 text-left pr-4\"],[12],[1,\"\\n          \"],[10,0],[12],[1,\"Yes\"],[13],[1,\"\\n          \"],[10,0],[12],[1,\"No\"],[13],[1,\"\\n          \"],[10,0],[12],[1,\"Maybe\"],[13],[1,\"\\n          \"],[10,0],[14,0,\"mt-2\"],[12],[1,\"Total\"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"text-right\"],[12],[1,\"\\n          \"],[10,0],[12],[1,[28,[35,5],[[33,6,[\"yes\"]]],null]],[13],[1,\"\\n          \"],[10,0],[12],[1,[28,[35,5],[[33,6,[\"no\"]]],null]],[13],[1,\"\\n          \"],[10,0],[12],[1,[28,[35,5],[[33,6,[\"maybe\"]]],null]],[13],[1,\"\\n          \"],[10,0],[14,0,\"mt-2\"],[12],[1,[28,[35,5],[[33,6,[\"total\"]]],null]],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"text-muted font-weight-light pl-1\"],[12],[1,\"\\n          \"],[10,0],[12],[1,\" \"],[10,1],[14,0,\"small\"],[12],[1,[33,6,[\"yesPercent\"]]],[1,\"%\"],[13],[13],[1,\"\\n          \"],[10,0],[12],[1,\" \"],[10,1],[14,0,\"small\"],[12],[1,[33,6,[\"noPercent\"]]],[1,\"%\"],[13],[13],[1,\"\\n          \"],[10,0],[12],[1,\" \"],[10,1],[14,0,\"small\"],[12],[1,[33,6,[\"maybePercent\"]]],[1,\"%\"],[13],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]]]]],[]],null]],[]],null]],[],false,[\"if\",\"displayChart\",\"displayTooltip\",\"liquid-tether\",\"chartElement\",\"format-number\",\"totalResponses\"]]",
    "moduleName": "donor-elf-web/templates/components/coaching-response-chart.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});