define("donor-elf-web/components/tether", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes
    {{did-insert this.templateDidInsert}}
    {{will-destroy this.templateWillDestroy}}
    class='tw-z-100'
  >
    {{yield}}
  </div>
  */
  {
    "id": "Ysth3zx6",
    "block": "[[[11,0],[17,1],[24,0,\"tw-z-100\"],[4,[38,0],[[30,0,[\"templateDidInsert\"]]],null],[4,[38,1],[[30,0,[\"templateWillDestroy\"]]],null],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"did-insert\",\"will-destroy\",\"yield\"]]",
    "moduleName": "donor-elf-web/components/tether.hbs",
    "isStrictMode": false
  });

  const {
    Tether
  } = window;
  /*
    args
      - target
      - attachment
      - targetAttachment
  */

  let TetherComponent = (_class = class TetherComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "tether", void 0);
    }

    templateDidInsert(e) {
      this.tether = new Tether({
        element: e,
        target: this.args.target,
        attachment: this.args.attachment,
        targetAttachment: this.args.targetAttachment
      });
    }

    templateWillDestroy(e) {
      var _this$tether, _e$parentNode;

      (_this$tether = this.tether) === null || _this$tether === void 0 ? void 0 : _this$tether.destroy();
      this.tether = null; // have to manually remove the element since Tetherjs messes with it

      (_e$parentNode = e.parentNode) === null || _e$parentNode === void 0 ? void 0 : _e$parentNode.removeChild(e);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype)), _class);
  _exports.default = TetherComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TetherComponent);
});