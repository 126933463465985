define("donor-elf-web/controllers/funds/fund/more/mailchimp/connected-to-fund/index", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@glimmer/tracking"], function (_exports, _controller, _object, _service, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let DONOR_ELF_FIELD_MAPPING = {
    'name': 'Display Name',
    'first_name': 'First Name',
    'last_name': 'Last Name',
    'mailing_name': 'Mailing Name'
  };
  let MailchimpConnectedToFundController = (_class = class MailchimpConnectedToFundController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fundsService", _descriptor, this);

      _initializerDefineProperty(this, "currentUser", _descriptor2, this);

      _initializerDefineProperty(this, "changesSaved", _descriptor3, this);
    }

    get mailchimpFundSetting() {
      return this.model.mailchimpFundSetting;
    }

    get changeset() {
      return this.model.mailchimpFundSettingChangeset;
    }

    get listName() {
      var _mcSetting$lists$find;

      let mcSetting = this.model.mailchimpSetting;
      let mcFund = this.mailchimpFundSetting;
      let listId = mcFund.mailchimpListId;
      return (_mcSetting$lists$find = mcSetting.lists.find(x => x.id === listId)) === null || _mcSetting$lists$find === void 0 ? void 0 : _mcSetting$lists$find.name;
    }

    get formattedMergeVars() {
      return (this.mailchimpFundSetting.mergeVars || []).map(x => {
        return {
          mailchimpField: x.name,
          donorElfField: DONOR_ELF_FIELD_MAPPING[x.attribute]
        };
      });
    }

    get syncGroupsChanged() {
      return this.changeset.isDirty;
    }

    templateDidInsert() {
      this.changesSaved = false;
    }

    handleSave() {
      this.changesSaved = true;
      this.changeset.save();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "changesSaved", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleSave", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleSave"), _class.prototype)), _class);
  _exports.default = MailchimpConnectedToFundController;
});