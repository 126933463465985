define("donor-elf-web/templates/components/form-group-select-contact", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "b4owbTxw",
    "block": "[[[10,\"label\"],[12],[1,[34,0]],[13],[1,\"\\n\"],[1,[28,[35,1],null,[[\"selectedContactId\",\"onSelection\",\"focusOnInsert\",\"disabled\",\"showBlankContact\",\"blankContactLabel\",\"placeholder\",\"showAsInvalid\"],[[28,[37,2],[[33,3],[33,4]],null],[28,[37,5],[[30,0],\"handleContactSelected\"],null],[33,6],[30,0,[\"disabled\"]],[33,7],[33,8],[33,9],[33,10]]]]],[1,\"\\n\"],[41,[33,12],[[[1,\"  \"],[10,0],[14,0,\"mt-1 pl-2_5 text-muted font-weight-light\"],[12],[1,[33,12,[\"cityAndState\"]]],[13],[1,\"\\n\"]],[]],null],[41,[33,10],[[[1,\"  \"],[10,0],[14,0,\"has-errors-message\"],[12],[1,[28,[35,2],[[33,3,[\"error\"]],[28,[37,13],[[33,4],\".validation\"],null]],null]],[13],[1,\"\\n\"]],[]],null]],[],false,[\"label\",\"select-contact\",\"get\",\"changeset\",\"property\",\"action\",\"focusOnInsert\",\"showBlankContact\",\"blankContactLabel\",\"placeholder\",\"hasErrors\",\"if\",\"selectedContact\",\"concat\"]]",
    "moduleName": "donor-elf-web/templates/components/form-group-select-contact.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});