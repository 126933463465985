define("donor-elf-web/components/journal/report/projected-goal-new", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "ember-inflector", "donor-elf-web/lib/date-utils"], function (_exports, _component, _templateFactory, _component2, _object, _emberInflector, _dateUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes>
    <div
      {{did-insert this.renderChart}}
    ></div>
    <div class='mt-1'>
      {{#if (is-present @intervalSummaries)}}
        {{#if this.unknownProjectedFundedDate}}
          <span class='tw-text-lg'>
            <strong>Projected Completion:</strong> Unknown
          </span>
          <span class='font-weight-light tw-text-sm text-muted'>
            ({{this.unknownProjectedFundedDateReason}})
          </span>
        {{else if this.hasReachedGoal}}
          <span class='tw-text-lg'>
            <strong>Reached Goal:</strong> on {{format-date this.reachedGoalDate}}
          </span>
          <span class='font-weight-light tw-text-sm text-muted'>
            ({{this.reachedGoalByLabel}})
          </span>
        {{else}}
          <span class='tw-text-lg'>
            <strong>Projected Completion:</strong> {{format-date this.projectedFundedDate}}
          </span>
          <span class='font-weight-light tw-text-sm text-muted'>
            ({{this.projectedFundedDateByLabel}})
          </span>
        {{/if}}
      {{/if}}
    </div>
  </div>
  */
  {
    "id": "bNXGzH1D",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[11,0],[4,[38,0],[[30,0,[\"renderChart\"]]],null],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"mt-1\"],[12],[1,\"\\n\"],[41,[28,[37,2],[[30,2]],null],[[[41,[30,0,[\"unknownProjectedFundedDate\"]],[[[1,\"        \"],[10,1],[14,0,\"tw-text-lg\"],[12],[1,\"\\n          \"],[10,\"strong\"],[12],[1,\"Projected Completion:\"],[13],[1,\" Unknown\\n        \"],[13],[1,\"\\n        \"],[10,1],[14,0,\"font-weight-light tw-text-sm text-muted\"],[12],[1,\"\\n          (\"],[1,[30,0,[\"unknownProjectedFundedDateReason\"]]],[1,\")\\n        \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"hasReachedGoal\"]],[[[1,\"        \"],[10,1],[14,0,\"tw-text-lg\"],[12],[1,\"\\n          \"],[10,\"strong\"],[12],[1,\"Reached Goal:\"],[13],[1,\" on \"],[1,[28,[35,3],[[30,0,[\"reachedGoalDate\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,1],[14,0,\"font-weight-light tw-text-sm text-muted\"],[12],[1,\"\\n          (\"],[1,[30,0,[\"reachedGoalByLabel\"]]],[1,\")\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,1],[14,0,\"tw-text-lg\"],[12],[1,\"\\n          \"],[10,\"strong\"],[12],[1,\"Projected Completion:\"],[13],[1,\" \"],[1,[28,[35,3],[[30,0,[\"projectedFundedDate\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,1],[14,0,\"font-weight-light tw-text-sm text-muted\"],[12],[1,\"\\n          (\"],[1,[30,0,[\"projectedFundedDateByLabel\"]]],[1,\")\\n        \"],[13],[1,\"\\n      \"]],[]]]],[]]]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@intervalSummaries\"],false,[\"did-insert\",\"if\",\"is-present\",\"format-date\"]]",
    "moduleName": "donor-elf-web/components/journal/report/projected-goal-new.hbs",
    "isStrictMode": false
  });

  const {
    currency,
    moment,
    Highcharts
  } = window;
  let JournalProjectedGoalNewComponent = (_class = class JournalProjectedGoalNewComponent extends _component2.default {
    get hasEnoughData() {
      return this.args.intervalSummaries.length > this.timeFrameWeeks + 1;
    }

    get intervalSummaries() {
      return this.args.intervalSummaries;
    }

    get lastIntervalSummary() {
      return this.args.intervalSummaries[this.args.intervalSummaries.length - 1];
    }

    get goalDate() {
      return this.args.journal.goalDate;
    }

    get weeksUntilGoalDate() {
      let {
        lastIntervalSummary,
        goalDate
      } = this;
      return Math.ceil(moment(goalDate).diff(moment(lastIntervalSummary.date.end), 'weeks', true));
    }

    get timeFrameWeeks() {
      return 4;
    }

    get timeFrameIntervalSummaries() {
      let {
        intervalSummaries,
        lastIntervalSummary
      } = this;
      let sliceEnd = intervalSummaries.indexOf(lastIntervalSummary) + 1;
      let startIndex = sliceEnd - this.timeFrameWeeks;
      return intervalSummaries.slice(startIndex, sliceEnd);
    }

    get timeFrameDecisionAmount() {
      return this.timeFrameIntervalSummaries.reduce((sum, item) => {
        return currency(sum).add(item.newAmount).value;
      }, 0);
    }

    get averageWeeklyNewDecisionAmount() {
      return this.timeFrameDecisionAmount / this.timeFrameWeeks;
    }

    get currentAmount() {
      return this.lastIntervalSummary.cumulativeAmount;
    }

    get amountToRaise() {
      return currency(this.args.journalGoal.amount).subtract(this.currentAmount).value;
    }

    get weeksLeft() {
      return Math.ceil(this.amountToRaise / this.averageWeeklyNewDecisionAmount);
    }

    get hasReachedGoal() {
      return this.currentAmount >= this.args.journalGoal.amount;
    }

    get reachedGoalDate() {
      if (!this.hasReachedGoal) {
        return undefined;
      }

      let goalAmount = this.args.journalGoal.amount;
      let interval = this.intervalSummaries.find(x => x.cumulativeAmount >= goalAmount);
      return interval === null || interval === void 0 ? void 0 : interval.date.endDate;
    }

    get reachedGoalByLabel() {
      let diffWeeks = moment(this.reachedGoalDate).diff(moment(this.goalDate), 'weeks', true);

      if (diffWeeks > 8) {
        let diffMonths = Math.ceil(moment(this.reachedGoalDate).diff(moment(this.goalDate), 'weeks', true));
        return `${diffMonths} months late`;
      } else if (diffWeeks >= 1) {
        return `${(0, _emberInflector.pluralize)(Math.ceil(diffWeeks), 'week')} late`;
      } else if (diffWeeks <= 1) {
        return `${(0, _emberInflector.pluralize)(Math.ceil(diffWeeks) * -1, 'week')} early`;
      } else {
        return 'By the goal date';
      }
    }

    get unknownProjectedFundedDate() {
      return !this.hasEnoughData || this.timeFrameDecisionAmount < 1;
    }

    get unknownProjectedFundedDateReason() {
      if (!this.hasEnoughData) {
        return 'Less than 4 weeks of data';
      } else {
        return 'No new decisions in the last 4 weeks';
      }
    }

    get projectedFundedDate() {
      return moment(this.lastIntervalSummary.date.end).add(this.weeksLeft, 'weeks').toDate();
    }

    get projectedFundedDateByLabel() {
      let diffWeeks = moment(this.projectedFundedDate).diff(moment(this.goalDate), 'weeks', true);

      if (diffWeeks > 8) {
        let diffMonths = Math.ceil(moment(this.projectedFundedDate).diff(moment(this.goalDate), 'weeks', true));
        return `${diffMonths} months late`;
      } else if (diffWeeks >= 1) {
        return `${(0, _emberInflector.pluralize)(Math.ceil(diffWeeks), 'week')} late`;
      } else if (diffWeeks <= 1) {
        return `${(0, _emberInflector.pluralize)(Math.ceil(diffWeeks) * -1, 'week')} early`;
      } else {
        return 'By the goal date';
      }
    }

    get projectedFundedDateWeeksAfterGoal() {
      return Math.ceil(moment(this.projectedFundedDate).diff(moment(this.goalDate), 'weeks', true));
    }

    get projectedFundedDateMonthsAfterGoal() {
      return Math.ceil(moment(this.projectedFundedDate).diff(moment(this.goalDate), 'months', true));
    }

    get projectedFundedDateWeeksBeforeGoal() {
      return this.projectedFundedDateWeeksAfterGoal * -1;
    }

    renderChart(element) {
      Highcharts.chart(element, this.getChartSettings());
    }

    get journalGoalDateUtc() {
      return (0, _dateUtils.dateToUtc)(this.goalDate);
    }

    get seriesStartUtc() {
      let firstIntervalStartDate = this.intervalSummaries[0].date.startDate;
      let date = moment(firstIntervalStartDate).add({
        days: -1
      }).toDate();
      return (0, _dateUtils.dateToUtc)(date);
    }

    get decisionsSeries() {
      let {
        seriesStartUtc,
        args: {
          intervalSummaries,
          journal: {
            decisionsRequireGift
          }
        }
      } = this;
      return {
        name: 'All Decisions',
        type: decisionsRequireGift ? 'line' : 'area',
        color: '#8ac7ec',
        marker: {
          enabled: false
        },
        data: [[seriesStartUtc, 0]].concat(intervalSummaries.map(item => {
          return [(0, _dateUtils.dateToUtc)(item.date.endDate), item.cumulativeAmount];
        }))
      };
    }

    get withGiftSeries() {
      let {
        seriesStartUtc,
        args: {
          intervalSummaries
        }
      } = this;
      return {
        name: 'Decisions with Gifts',
        type: 'area',
        color: '#0b68a1',
        marker: {
          enabled: false
        },
        data: [[seriesStartUtc, 0]].concat(intervalSummaries.map(item => {
          return [(0, _dateUtils.dateToUtc)(item.date.endDate), item.cumulativeWithGiftAmount];
        }))
      };
    }

    get goalSeries() {
      let {
        seriesStartUtc,
        args: {
          journalGoal
        }
      } = this;
      let maxDate = this.args.journal.goalDate;

      if (this.projectedFundedDate > maxDate) {
        maxDate = this.projectedFundedDate;
      }

      return {
        name: 'Goal',
        type: 'line',
        color: '#f0ad4e',
        lineWidth: 2,
        marker: {
          enabled: false
        },
        data: [[seriesStartUtc, journalGoal.amount], [(0, _dateUtils.dateToUtc)(maxDate), journalGoal.amount]]
      };
    }

    get projectedSeries() {
      let lastIntervalUtc = (0, _dateUtils.dateToUtc)(this.lastIntervalSummary.date.endDate);
      let projectedFundedDateUtc = (0, _dateUtils.dateToUtc)(this.projectedFundedDate);
      return {
        name: 'Projected',
        type: 'line',
        color: '#eceeef',
        marker: {
          enabled: false
        },
        data: [[lastIntervalUtc, this.currentAmount], [projectedFundedDateUtc, this.args.journalGoal.amount]]
      };
    }

    get goalDateColumnSeries() {
      let {
        journalGoalDateUtc,
        args: {
          journalGoal
        }
      } = this;
      return {
        name: 'Goal Date',
        type: 'column',
        color: '#f0ad4e',
        pointWidth: 2,
        borderWidth: 0,
        marker: {
          enabled: false
        },
        data: [[journalGoalDateUtc, journalGoal.amount]]
      };
    }

    getChartSettings() {
      let series = [];
      series.push(this.decisionsSeries);
      series.push(this.withGiftSeries);
      series.push(this.goalDateColumnSeries);

      if (!this.hasReachedGoal) {
        series.push(this.projectedSeries);
      }

      series.push(this.goalSeries);
      return {
        chart: {
          height: 200,
          margin: [2, 0, 2, 0],
          borderWidth: 0
        },
        title: {
          text: null
        },
        credits: {
          enabled: false
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            // don't display the dummy year
            month: '%e. %b',
            year: '%b'
          },
          startOnTick: false
        },
        yAxis: {
          min: 0,
          labels: {
            enabled: false
          },
          max: Math.max(this.args.journalGoal.amount, this.currentAmount) * 1.05,
          title: {
            text: null
          },
          startOnTick: false,
          endOnTick: false,
          tickPositions: [0]
        },
        legend: {
          enabled: false
        },
        series
      };
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "renderChart", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "renderChart"), _class.prototype)), _class);
  _exports.default = JournalProjectedGoalNewComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, JournalProjectedGoalNewComponent);
});