define("donor-elf-web/helpers/is-empty", ["exports", "@ember/component/helper", "@ember/utils"], function (_exports, _helper, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _helper.helper)(function ([obj]) {
    return (0, _utils.isEmpty)(obj);
  });

  _exports.default = _default;
});