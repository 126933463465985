define("donor-elf-web/helpers/format-amount", ["exports", "@ember/string", "@ember/component/helper", "@ember/object", "@ember/service"], function (_exports, _string, _helper, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _helper.default.extend({
    formatAmountService: (0, _service.inject)(),

    /*
      namedArgs
        - round
          - round to the nearest dollar
          - default is false
        - removeNoCents
         - If there are no cents, then remove them
         - default is false
    */
    compute(args, namedArgs) {
      let val = args[0];

      if (namedArgs.round) {
        val = Math.round(Number(val), 0);
      }

      let formatVal = (0, _object.get)(this, 'formatAmountService').format(val, {
        roundToDollar: namedArgs.round || false,
        removeNoCents: namedArgs.removeNoCents || false
      });

      if (Number(val) < 0) {
        formatVal = `<span class='text-danger'>${formatVal}</span>`;
      }

      return (0, _string.htmlSafe)(formatVal);
    }

  });

  _exports.default = _default;
});