define("donor-elf-web/services/de-router-scroll", ["exports", "@ember/object", "ember-router-scroll/services/router-scroll"], function (_exports, _object, _routerScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _routerScroll.default.extend({
    update() {
      if (this.key) {
        let bmContent = document.querySelector('.bm-content');

        if (bmContent) {
          (0, _object.set)(this.scrollMap, this.key, {
            x: 0,
            y: bmContent.scrollTop
          });
        }
      }
    }

  });

  _exports.default = _default;
});