define("donor-elf-web/templates/funds/all/journal-groups/journal-group/index-loading", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "eNB3IRuA",
    "block": "[[[8,[39,0],null,null,null]],[],false,[\"de-loading\"]]",
    "moduleName": "donor-elf-web/templates/funds/all/journal-groups/journal-group/index-loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});