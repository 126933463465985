define("donor-elf-web/templates/funds/fund/groups/add", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "gX+5L3ym",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[6,[39,1],null,[[\"changeset\",\"saveTask\"],[[33,2,[\"changeset\"]],[33,3]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,4],null,[[\"changeset\",\"property\",\"label\",\"showValidationErrors\"],[[33,2,[\"changeset\"]],\"name\",\"Group Name\",[30,1]]]]],[1,\"\\n\"]],[1]]]]]],[]]]]]],[\"showValidationErrors\"],false,[\"de-form-card\",\"save-form\",\"model\",\"save\",\"form-group\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/groups/add.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});