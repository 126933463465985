define("donor-elf-web/routes/admin/users/show/funds/edit", ["exports", "@ember/routing/route", "@ember/object", "rsvp", "@ember/utils", "@ember/service", "ember-changeset"], function (_exports, _route, _object, _rsvp, _utils, _service, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    fundsService: (0, _service.inject)(),
    store: (0, _service.inject)(),
    storeCache: (0, _service.inject)(),

    beforeModel() {
      return (0, _object.get)(this, 'fundsService').confirmValidFundToken();
    },

    model(params) {
      let user = this.modelFor('admin.users.show');
      let userId = (0, _object.get)(user, 'id');
      let storeCache = (0, _object.get)(this, 'storeCache');

      let promise = _rsvp.default.hash({
        fund: this.store.find('admin/fund', params.fundId),
        userFunds: this.store.query('admin/user-fund', {
          filter: {
            user_id: userId
          }
        }),
        roles: storeCache.findAll('admin/role')
      });

      return promise.then(data => {
        let userFundRecord = data.userFunds.find(userFund => userFund.fundId === params.fundId);
        let userFundChangeset = new _emberChangeset.default(userFundRecord);

        if ((0, _utils.isEmpty)((0, _object.get)(userFundRecord, 'role'))) {
          (0, _object.set)(userFundChangeset, 'role', (0, _object.get)(user, 'role'));
        }

        return {
          user: user,
          roles: data.roles,
          fund: data.fund,
          userFundRecords: data.userFunds.toArray(),
          userFundRecord: userFundRecord,
          userFundChangeset: userFundChangeset
        };
      });
    }

  });

  _exports.default = _default;
});