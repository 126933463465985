define("donor-elf-web/templates/funds/fund/more/passkeys/login", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "0rf6I8s8",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[10,\"h4\"],[12],[1,\"Login with one of the following devices\"],[13],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1,[\"authOption\",\"nicknames\"]]],null]],null],null,[[[1,\"    \"],[10,0],[12],[1,[30,2]],[13],[1,\"\\n\"]],[2]],null],[1,\"  \"],[10,0],[14,0,\"mt-3\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-warning btn-sm\"],[24,4,\"button\"],[4,[38,3],[\"click\",[30,0,[\"login\"]]],null],[12],[1,\"\\n      Login\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@model\",\"nickname\"],false,[\"form-card\",\"each\",\"-track-array\",\"on\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/more/passkeys/login.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});