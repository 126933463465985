define("donor-elf-web/controllers/funds/fund/journals/summaries/index", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "@ember/service", "donor-elf-web/lib/store-utils", "donor-elf-web/lib/array-utils", "@ember/template"], function (_exports, _controller, _object, _tracking, _service, _storeUtils, _arrayUtils, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _class3, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let SummaryItem = (_class = class SummaryItem {
    constructor(summary, fund) {
      _initializerDefineProperty(this, "selected", _descriptor, this);

      _defineProperty(this, "fund", void 0);

      _defineProperty(this, "summary", void 0);

      this.summary = summary;
      this.fund = fund;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selected", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class);
  let JournalsSummariesIndexController = (_class3 = class JournalsSummariesIndexController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fundsService", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "router", _descriptor4, this);

      _initializerDefineProperty(this, "loading", _descriptor5, this);

      _initializerDefineProperty(this, "journalSummaries", _descriptor6, this);

      _initializerDefineProperty(this, "items", _descriptor7, this);

      _defineProperty(this, "allFundsInfo", void 0);

      _initializerDefineProperty(this, "page", _descriptor8, this);

      _defineProperty(this, "perPage", 50);

      _initializerDefineProperty(this, "percentDownloaded", _descriptor9, this);
    }

    get progressBarStyle() {
      return (0, _template.htmlSafe)(`width: ${this.percentDownloaded}%`);
    }

    createItems() {
      let allFundsInfo = this.allFundsInfo;
      this.items = (0, _arrayUtils.sortByProperties)(this.journalSummaries.map(summary => {
        return new SummaryItem(summary, allFundsInfo.fundsById[summary.fundId]);
      }), ['fund.name', 'summary.startDate']);
    }

    get selectedItems() {
      return this.items.filter(x => x.selected);
    }

    get pagedItems() {
      let offset = (this.page - 1) * this.perPage;
      return this.items.slice(offset, offset + this.perPage);
    }

    get totalPages() {
      return Math.ceil(this.items.length / this.perPage);
    }

    async templateDidInsert() {
      this.percentDownloaded = 5;
      this.loading = true;
      this.allFundsInfo = await this.fundsService.getAllFundsInfo();
      this.journalSummaries = await (0, _storeUtils.queryWithPages)({
        store: this.store,
        modelName: 'journal-summary',
        pageSize: 50,
        pageCallback: args => {
          let percent = Math.round(args.downloadedCount * 100 / args.totalCount);

          if (percent < 5) {
            percent = 5;
          }

          if (percent > 100) {
            percent = 100;
          }

          this.percentDownloaded = percent;
        }
      });
      this.createItems();
      this.loading = false;
    }

    viewSelectedJournals() {
      if (this.selectedItems.length > 0) {
        let ids = this.selectedItems.map(x => x.summary.id).join('-');
        this.router.transitionTo('funds.fund.journals.summaries.view', {
          queryParams: {
            ids: ids
          }
        });
      }
    }

    toggleSelected(item) {
      item.selected = !item.selected;
    }

    changePage(page) {
      this.page = page;
    }

  }, (_descriptor2 = _applyDecoratedDescriptor(_class3.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class3.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class3.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class3.prototype, "journalSummaries", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class3.prototype, "items", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class3.prototype, "page", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class3.prototype, "percentDownloaded", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class3.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "templateDidInsert"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "viewSelectedJournals", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "viewSelectedJournals"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "toggleSelected", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "toggleSelected"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "changePage", [_object.action], Object.getOwnPropertyDescriptor(_class3.prototype, "changePage"), _class3.prototype)), _class3);
  _exports.default = JournalsSummariesIndexController;
});