define("donor-elf-web/routes/funds/fund/groups/index", ["exports", "@ember/routing/route", "@ember/object", "@ember/service"], function (_exports, _route, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    contactsCache: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),

    beforeModel() {
      (0, _object.set)(this, 'navbarSettings.title', 'Groups');
    },

    model() {
      let items = [];
      let groupContacts = (0, _object.get)(this, 'contactsCache.groupContacts');
      Object.keys(groupContacts).forEach(group => {
        items.push({
          group: group,
          contacts: groupContacts[group].contacts
        });
      });
      return items;
    }

  });

  _exports.default = _default;
});