define("donor-elf-web/components/journal/report/projected-goal-mock", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "donor-elf-web/lib/date-utils", "ember-cached-decorator-polyfill"], function (_exports, _component, _templateFactory, _component2, _object, _dateUtils, _emberCachedDecoratorPolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes>
    <div
      {{did-insert this.renderChart}}
    ></div>
    <div class='mt-1'>
      {{#if (is-present this.intervalItems)}}
        <span class='tw-text-lg'>
          <strong>Projected Completion:</strong> {{format-date this.projectedFundedDate}}
        </span>
        <span class='font-weight-light tw-text-sm text-muted'>
          {{#if (gt this.projectedFundedDateWeeksAfterGoal 8)}}
            ({{this.projectedFundedDateMonthsAfterGoal}} months late)
          {{else if (gt this.projectedFundedDateWeeksAfterGoal 0)}}
            ({{this.projectedFundedDateWeeksAfterGoal}} weeks late)
          {{else}}
            {{#if (lt this.projectedFundedDateWeeksBeforeGoal 1)}}
              (By the goal date)
            {{else}}
              ({{this.projectedFundedDateWeeksBeforeGoal}} weeks early)
            {{/if}}
          {{/if}}
        </span>
      {{/if}}
    </div>
  </div>
  */
  {
    "id": "w1mwGS/p",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[11,0],[4,[38,0],[[30,0,[\"renderChart\"]]],null],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"mt-1\"],[12],[1,\"\\n\"],[41,[28,[37,2],[[30,0,[\"intervalItems\"]]],null],[[[1,\"      \"],[10,1],[14,0,\"tw-text-lg\"],[12],[1,\"\\n        \"],[10,\"strong\"],[12],[1,\"Projected Completion:\"],[13],[1,\" \"],[1,[28,[35,3],[[30,0,[\"projectedFundedDate\"]]],null]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[14,0,\"font-weight-light tw-text-sm text-muted\"],[12],[1,\"\\n\"],[41,[28,[37,4],[[30,0,[\"projectedFundedDateWeeksAfterGoal\"]],8],null],[[[1,\"          (\"],[1,[30,0,[\"projectedFundedDateMonthsAfterGoal\"]]],[1,\" months late)\\n\"]],[]],[[[41,[28,[37,4],[[30,0,[\"projectedFundedDateWeeksAfterGoal\"]],0],null],[[[1,\"          (\"],[1,[30,0,[\"projectedFundedDateWeeksAfterGoal\"]]],[1,\" weeks late)\\n\"]],[]],[[[41,[28,[37,5],[[30,0,[\"projectedFundedDateWeeksBeforeGoal\"]],1],null],[[[1,\"            (By the goal date)\\n\"]],[]],[[[1,\"            (\"],[1,[30,0,[\"projectedFundedDateWeeksBeforeGoal\"]]],[1,\" weeks early)\\n\"]],[]]],[1,\"        \"]],[]]]],[]]],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"did-insert\",\"if\",\"is-present\",\"format-date\",\"gt\",\"lt\"]]",
    "moduleName": "donor-elf-web/components/journal/report/projected-goal-mock.hbs",
    "isStrictMode": false
  });

  const {
    currency,
    moment,
    Highcharts
  } = window;
  // used in the journals/summaries page from mock data
  let JournalProjectedGoalMockComponent = (_class = class JournalProjectedGoalMockComponent extends _component2.default {
    get intervalItems() {
      let {
        startDate,
        intervalItems
      } = this.args;
      let cumulativeAmount = 0; // return currency(sum).add(item.amount).value;

      return intervalItems.map(function (item, index) {
        cumulativeAmount = currency(cumulativeAmount).add(item.amount).value;
        return {
          date: moment(startDate).add(index + 1, 'weeks').toDate(),
          amount: item.amount,
          cumulativeAmount
        };
      });
    }

    get lastFullIntervalItem() {
      return this.intervalItems[this.intervalItems.length - 1];
    }

    get goalDate() {
      return this.args.goalDate;
    }

    get weeksUntilGoalDate() {
      let {
        lastFullIntervalItem,
        goalDate
      } = this;
      return Math.ceil(moment(goalDate).diff(moment(lastFullIntervalItem.date), 'weeks', true));
    }

    get timeFrameWeeks() {
      return 4;
    }

    get timeFrameIntervalItems() {
      let {
        intervalItems,
        lastFullIntervalItem
      } = this;
      let sliceEnd = intervalItems.indexOf(lastFullIntervalItem) + 1;
      let startIndex = sliceEnd - this.timeFrameWeeks;
      return intervalItems.slice(startIndex, sliceEnd);
    }

    get timeFrameDecisionAmount() {
      return this.timeFrameIntervalItems.reduce((sum, item) => {
        return currency(sum).add(item.amount).value;
      }, 0);
    }

    get averageWeeklyNewDecisionAmount() {
      return this.timeFrameDecisionAmount / this.timeFrameWeeks;
    }

    get currentAmount() {
      let {
        intervalItems
      } = this;
      return intervalItems[intervalItems.length - 1].cumulativeAmount;
    }

    get amountToRaise() {
      return currency(this.args.goalAmount).subtract(this.currentAmount).value;
    }

    get weeksLeft() {
      return Math.ceil(this.amountToRaise / this.averageWeeklyNewDecisionAmount);
    }

    get projectedFundedDate() {
      return moment(this.lastFullIntervalItem.date).add(this.weeksLeft, 'weeks').toDate();
    }

    get projectedFundedDateWeeksAfterGoal() {
      return Math.ceil(moment(this.projectedFundedDate).diff(moment(this.goalDate), 'weeks', true));
    }

    get projectedFundedDateMonthsAfterGoal() {
      return Math.ceil(moment(this.projectedFundedDate).diff(moment(this.goalDate), 'months', true));
    }

    get projectedFundedDateWeeksBeforeGoal() {
      return this.projectedFundedDateWeeksAfterGoal * -1;
    }

    renderChart(element) {
      if (this.intervalItems.length > 0) {
        Highcharts.chart(element, this.getChartSettings());
      }
    }

    get journalGoalDateUtc() {
      return (0, _dateUtils.dateToUtc)(this.args.goalDate);
    }

    get seriesStartUtc() {
      let firstIntervalStartDate = this.intervalItems[0].date;
      let date = moment(firstIntervalStartDate).add({
        days: -1
      }).toDate();
      return (0, _dateUtils.dateToUtc)(date);
    } // get decisionsSeries() {
    //   let {
    //     seriesStartUtc,
    //     args: {
    //       intervalCumulativeAmounts,
    //       journal: { decisionsRequireGift }
    //     }
    //   } = this;
    //   return {
    //     name: 'All Decisions',
    //     type: (decisionsRequireGift ? 'line' : 'area'),
    //     color: '#8ac7ec',
    //     marker: {
    //       enabled: false
    //     },
    //     data: [
    //       [seriesStartUtc, 0]
    //     ].concat(intervalCumulativeAmounts.map(item => {
    //       let date = dateUtils.deserializeDateOnly(item.end);
    //       return [dateToUtc(date), item.decision]
    //     }))
    //   };
    // }


    get withGiftSeries() {
      let {
        seriesStartUtc,
        intervalItems
      } = this;
      return {
        name: 'Decisions with Gifts',
        type: 'area',
        color: '#0b68a1',
        marker: {
          enabled: false
        },
        data: [[seriesStartUtc, 0]].concat(intervalItems.map(item => {
          return [(0, _dateUtils.dateToUtc)(item.date), item.cumulativeAmount];
        }))
      };
    }

    get goalSeries() {
      let {
        seriesStartUtc,
        args: {
          goalAmount
        }
      } = this;
      let maxDate = this.args.goalDate;

      if (this.projectedFundedDate > maxDate) {
        maxDate = this.projectedFundedDate;
      }

      return {
        name: 'Goal',
        type: 'line',
        color: '#f0ad4e',
        lineWidth: 2,
        marker: {
          enabled: false
        },
        data: [[seriesStartUtc, goalAmount], [(0, _dateUtils.dateToUtc)(maxDate), goalAmount]]
      };
    }

    get projectedSeries() {
      let {
        intervalItems
      } = this;
      let lastIntervalUtc = (0, _dateUtils.dateToUtc)(intervalItems[intervalItems.length - 1].date);
      let projectedFundedDateUtc = (0, _dateUtils.dateToUtc)(this.projectedFundedDate);
      return {
        name: 'Projected',
        type: 'line',
        color: '#bbb',
        //'#eceeef',
        marker: {
          enabled: false
        },
        data: [[lastIntervalUtc, this.currentAmount], [projectedFundedDateUtc, this.args.goalAmount]]
      };
    }

    get goalDateColumnSeries() {
      let {
        journalGoalDateUtc,
        args: {
          goalAmount
        }
      } = this;
      return {
        name: 'Goal Date',
        type: 'column',
        color: '#f0ad4e',
        pointWidth: 2,
        marker: {
          enabled: false
        },
        data: [[journalGoalDateUtc, goalAmount]]
      };
    }

    getChartSettings() {
      let series = [];
      series.push(this.withGiftSeries);
      series.push(this.goalDateColumnSeries);
      series.push(this.projectedSeries);
      series.push(this.goalSeries);
      return {
        chart: {
          height: 175,
          margin: [2, 0, 2, 0],
          borderWidth: 0
        },
        title: {
          text: null
        },
        credits: {
          enabled: false
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            // don't display the dummy year
            month: '%e. %b',
            year: '%b'
          },
          startOnTick: false
        },
        yAxis: {
          min: 0,
          labels: {
            enabled: false
          },
          max: Math.max(this.args.goalAmount, this.currentAmount) * 1.05,
          title: {
            text: null
          },
          startOnTick: false,
          endOnTick: false,
          tickPositions: [0]
        },
        legend: {
          enabled: false
        },
        series
      };
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "intervalItems", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "intervalItems"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "renderChart", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "renderChart"), _class.prototype)), _class);
  _exports.default = JournalProjectedGoalMockComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, JournalProjectedGoalMockComponent);
});