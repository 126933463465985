define("donor-elf-web/routes/admin/index", ["exports", "@ember/routing/route", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _route, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_authenticatedRouteMixin.default, {
    redirect() {
      this.transitionTo('admin.users');
    }

  });

  _exports.default = _default;
});