define("donor-elf-web/router", ["exports", "@ember/routing/router", "donor-elf-web/config/environment", "donor-elf-web/lib/de-ember-router-scroll-mixin"], function (_exports, _router, _environment, _deEmberRouterScrollMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
  // Example when converting to NativeClassed. Must first upgrade EmberRouterScroll to 2.0
  
  export default class Router extends EmberRouter {
    location = config.locationType;
    rootURL = config.routerRootURL;
  }
  */
  const Router = _router.default.extend(_deEmberRouterScrollMixin.default, {
    location: _environment.default.locationType,
    rootURL: _environment.default.routerRootURL
  });

  var _default = Router;
  _exports.default = _default;
  Router.map(function () {
    this.route('funds', function () {
      this.route('fund', {
        path: '/:fundId'
      }, function () {
        this.route('dashboard', function () {
          this.route('annual');
          this.route('no-goal');
          this.route('rolling');
          this.route('rolling-exclude-pledges');
          this.route('rolling-no-goal');
          this.route('total');
          this.route('total-and-expecting');
          this.route('ytd');
          this.route('update-card');
          this.route('global-interaction');
          this.route('resonate-global-mission');
          this.route('spo'); // Saint Paul's Outreach

          this.route('cef'); // Child Evangelism Fellowship

          this.route('ruf'); // not used anymore but need to keep because people have bookmarked it

          this.route('monthly-rolling');
        });
        this.route('goal', function () {
          this.route('create');
          this.route('annual');
          this.route('rolling');
          this.route('total');
          this.route('total-and-expecting');
          this.route('ytd');
        });
        this.route('contacts', function () {
          this.route('contact', {
            path: '/:contactId'
          }, function () {
            this.route('details', {
              path: '/'
            });
            this.route('dates', function () {
              this.route('edit', {
                path: '/:dateId'
              });
              this.route('delete', {
                path: '/:dateId/delete'
              });
            });
            this.route('edit-note', {
              path: '/notes/:noteId'
            }, function () {
              this.route('delete');
            });
            this.route('merge', function () {
              this.route('with', {
                path: '/:mergeContactId'
              });
            });
            this.route('gifts-by-pledge');
            this.route('delete');
            this.route('not-found');
          });
          this.route('add');
          this.route('upload', function () {
            this.route('file');
            this.route('map');
            this.route('verify');
            this.route('save');
          });
          this.route('group-by', function () {
            this.route('property', {
              path: '/:label'
            });
          });
        });
        this.route('properties', function () {
          this.route('property', {
            path: '/:label'
          }, function () {
            this.route('delete');
            this.route('rename');
          });
        });
        this.route('transactions', function () {
          this.route('edit', function () {
            this.route('enter', {
              path: '/:id'
            });
            this.route('save');
          });
          this.route('delete', {
            path: '/delete/:id'
          });
        });
        this.route('pledges', function () {
          // TODO: remove the 'edit' and 'delete' routes below and all their associated files
          this.route('edit', {
            path: '/:pledgeId'
          });
          this.route('delete', {
            path: '/:pledgeId/delete'
          });
        });
        this.route('communications', function () {
          this.route('by-contact');
          this.route('edit', {
            path: '/:communicationId'
          });
        });
        this.route('tasks', function () {
          this.route('edit', {
            path: '/:taskId'
          }); // no longer using. all files can be deleted

          this.route('delete', {
            path: '/:taskId/delete'
          }); // no longer using. all files can be deleted

          this.route('v2', function () {
            this.route('assigned', function () {});
            this.route('summary');
            this.route('by-contact');
          });
        });
        this.route('groups', function () {
          this.route('add'); // create expects `contactIds` param & automatically adds those contacts to the group
          // whereas the add route above lets the user manually add contacts to the group

          this.route('create');
          this.route('group', {
            path: '/:groupName'
          }, function () {
            this.route('add');
            this.route('edit-name');
            this.route('delete');
            this.route('add-to-mailchimp');
          });
        });
        this.route('donations-by-month');
        this.route('map-my-contacts');
        this.route('funds-summary');
        this.route('monthly-summary');
        this.route('donors-by-time-frame');
        this.route('pledge-fulfillment');
        this.route('news-feed');
        this.route('review-items');
        this.route('total-given-expecting');
        this.route('annual-comparison');
        this.route('ytd-given-expecting');
        this.route('ytd-next-year-projection');
        this.route('ytd-next-year-projection-no-goal');
        this.route('dates');
        this.route('custom-reports', function () {
          this.route('tenfold-paystubs', function () {
            this.route('tenfold-paystub', {
              path: '/:id'
            });
          });
          this.route('pbt-disbursement-availability');
          this.route('pbt-disbursement-summary');
          this.route('oms-monthly-summary');
        });
        this.route('journals', function () {
          this.route('active');
          this.route('add');
          this.route('getting-started');
          this.route('summaries', function () {
            this.route('view');
          });
          this.route('mock-summaries');
          this.route('journal', {
            path: '/:journalId'
          }, function () {
            this.route('edit');
            this.route('delete');
            this.route('add-contacts');
            this.route('segments', function () {
              this.route('segment', {
                path: '/:segment'
              });
            });
            this.route('review-items');
            this.route('report', function () {
              this.route('default');
              this.route('intervarsity');
            });
          });
        });
        this.route('campaigns', function () {
          this.route('campaign', {
            path: '/:campaignId'
          }, function () {});
          this.route('approve-transactions');
        });
        this.route('more', function () {
          this.route('mailchimp-sync', function () {
            this.route('not-connected');
            this.route('connected');
          });
          this.route('newsletter', function () {
            this.route('not-connected');
            this.route('connected');
            this.route('connected-to-other-fund');
            this.route('sync');
            this.route('edit-contacts');
          });
          this.route('mailchimp', function () {
            // not connected to MailChimp at all
            this.route('not-connected'); // connected to MailChimp account but not to a DonorElf fund yet

            this.route('connected'); // user just selected which DonorElf fund to sync with

            this.route('new-connected-to-fund'); // connected to MailChimp account and DonorElf fund

            this.route('connected-to-fund', function () {
              this.route('delete');
            });
            this.route('connected-to-another-fund');
          });
          this.route('user-settings');
          this.route('notifications', function () {
            this.route('none');
            this.route('edit');
            this.route('delete');
          });
          this.route('donor-hub', function () {
            this.route('none');
            this.route('view');
            this.route('connect', function () {
              this.route('organization');
              this.route('credentials');
              this.route('oauth-redirect');
              this.route('downloading');
            });
            this.route('delete');
          });
          this.route('tnt-connect-import', function () {
            this.route('xml', function () {
              this.route('processing');
              this.route('complete');
            });
            this.route('data-sync', function () {
              this.route('processing');
              this.route('complete');
            });
          });
          this.route('karani-import', function () {
            this.route('processing');
            this.route('complete');
          });
          this.route('credit-card');
          this.route('intro-video');
          this.route('intervarsity-help');
          this.route('cancel-account');
          this.route('passkeys', function () {
            this.route('add');
            this.route('login');
          });
        });
        this.route('import', function () {
          this.route('intervarsity-prospects', function () {
            this.route('processing');
            this.route('complete');
          });
          this.route('ag-usa');
          this.route('ag-wm');
          this.route('mpdx');
        });
        this.route('question');
      });
      this.route('all', function () {
        this.route('journal-groups', function () {
          this.route('journal-group', {
            path: '/:id'
          }, function () {
            this.route('edit');
          });
        });
      });
      this.route('no-access');
      this.route('view-classic-site');
    });
    this.route('admin', function () {
      this.route('users', function () {
        this.route('show', {
          path: '/:id'
        }, function () {
          this.route('info', {
            path: '/'
          }, function () {
            this.route('funds', {
              path: '/'
            });
          });
          this.route('funds', function () {
            this.route('add');
            this.route('edit', {
              path: ':fundId/edit'
            });
            this.route('delete', {
              path: ':fundId/delete'
            });
          });
          this.route('add-funds', {
            path: '/add-funds'
          });
          this.route('activation-link');
        });
        this.route('edit', {
          path: 'edit/:id'
        });
        this.route('delete', {
          path: 'delete/:id'
        });
        this.route('add', function () {
          this.route('name');
          this.route('referred-by');
          this.route('funds');
          this.route('save');
        });
        this.route('not-found');
      });
      this.route('funds', function () {
        this.route('fund', {
          path: ':id'
        }, function () {
          this.route('edit', {
            path: '/edit'
          });
          this.route('delete', {
            path: '/delete'
          });
          this.route('users', function () {
            this.route('add');
            this.route('delete', {
              path: ':userId/delete'
            });
          });
        });
      });
      this.route('fund-groups', function () {
        this.route('fund-group', {
          path: '/:id'
        }, function () {
          this.route('show', {
            path: '/'
          });
          this.route('edit');
          this.route('edit-funds');
          this.route('edit-users');
          this.route('delete');
        });
      });
      this.route('fund-mappings', function () {
        this.route('edit', {
          path: ':fundMappingId'
        });
      }), this.route('upload', function () {
        this.route('new');
        this.route('complete');
      });
      this.route('address-changes', function () {
        this.route('address-change', {
          path: '/:id'
        });
      });
      this.route('balance-adjustments');
      this.route('oauth', function () {
        this.route('blackbaud');
        this.route('quickbooks', function () {
          this.route('connect');
          this.route('connected');
          this.route('disconnect');
          this.route('disconnected');
        });
      });
    });
    this.route('show-full-menu');
    this.route('hide-full-menu');
    this.route('old-internet-explorer');
    this.route('demo');
    this.route('login');
    this.route('logout');
    this.route('account-cancelled');
    this.route('reset-password', function () {
      this.route('email-sent');
      this.route('token', {
        path: '/:resetToken'
      });
    });
    this.route('coaching', function () {
      this.route('individuals', function () {
        this.route('copy');
        this.route('edit', {
          path: '/edit/:id'
        });
        this.route('individual', {
          path: '/:individualId'
        }, function () {
          this.route('edit-goal', {
            path: '/edit-goal/:fieldId'
          });
          this.route('edit-goal-date');
          this.route('edit');
          this.route('add-report');
          this.route('report', {
            path: '/:reportId'
          }, function () {
            this.route('edit');
          });
        });
      });
    });
    this.route('organization', function () {
      this.route('donors', function () {
        this.route('donor', {
          path: '/:id'
        }, function () {
          this.route('pledge', {
            path: 'pledge/:pledgeId'
          });
          this.route('pledge-delete', {
            path: 'pledge/:pledgeId/destroy'
          });
        });
      });
    });
    this.route('intervarsity-first-import');
    this.route('not-found', {
      path: '/*path'
    });
  });
});