define("donor-elf-web/routes/funds/fund/contacts/add", ["exports", "@ember/routing/route", "@ember/object", "@ember/service", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators"], function (_exports, _route, _object, _service, _emberChangeset, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const contactValidation = {
    name: (0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    })
  };

  var _default = _route.default.extend({
    abilities: (0, _service.inject)(),
    fundsService: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),
    store: (0, _service.inject)(),

    beforeModel(transition) {
      if ((0, _object.get)(this, 'abilities').noAbility('edit_contact')) {
        transition.abort();
      }

      (0, _object.set)(this, 'navbarSettings.title', 'Add Contact');
      return (0, _object.get)(this, 'fundsService').confirmValidFundToken();
    },

    model() {
      let record = (0, _object.get)(this, 'store').createRecord('contact', {
        active: true
      });
      return {
        record: record,
        changeset: new _emberChangeset.default(record, (0, _emberChangesetValidations.default)(contactValidation), contactValidation)
      };
    }

  });

  _exports.default = _default;
});