define("donor-elf-web/controllers/funds/fund/more/karani-import/index", ["exports", "@ember/controller", "@ember/object", "@ember/utils"], function (_exports, _controller, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    invalidFileExtension: (0, _object.computed)('model.selectedFile', function () {
      if (this.model.selectedFile) {
        return /\.csv$/.test(this.model.selectedFile.name) === false;
      } else {
        return false;
      }
    }),
    actions: {
      handleFileSelected(file) {
        (0, _object.set)(this, 'model.selectedFile', file);
      },

      startUpload() {
        if ((0, _utils.isPresent)(this.model.selectedFile) && !this.invalidFileExtension) {
          this.transitionToRoute('funds.fund.more.karani-import.processing');
        } else {
          alert('Please select a file before you start the upload.');
        }
      }

    }
  });

  _exports.default = _default;
});