define("donor-elf-web/templates/funds/fund/contacts/contact/edit-note/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "A1+S0Ow7",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,\"h2\"],[14,0,\"mb-3\"],[12],[1,[52,[33,2,[\"isNewNote\"]],\"Add a Note\",\"Edit Note\"]],[13],[1,\"\\n\"],[6,[39,3],null,[[\"changeset\",\"cancelRoute\",\"deleteRoute\",\"saveTask\"],[[33,2,[\"changeset\"]],\"funds.fund.contacts.contact\",[52,[51,[33,2,[\"isNewNote\"]]],\"funds.fund.contacts.contact.edit-note.delete\"],[33,5]]],[[\"default\"],[[[[1,\"    \"],[1,[28,[35,6],null,[[\"changeset\",\"property\",\"label\",\"textArea\",\"focusOnInsert\",\"showValidationErrors\"],[[33,2,[\"changeset\"]],\"text\",\"\",true,true,[30,1]]]]],[1,\"\\n\"]],[1]]]]]],[]]]]]],[\"showValidationErrors\"],false,[\"de-form-card\",\"if\",\"model\",\"save-form\",\"unless\",\"save\",\"form-group\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/contacts/contact/edit-note/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});