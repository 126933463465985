define("donor-elf-web/components/chart/total-and-expecting-raised-by-month", ["exports", "@glimmer/component", "@ember/object", "@ember/runloop", "donor-elf-web/lib/date-utils", "@ember/service"], function (_exports, _component, _object, _runloop, _dateUtils, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    moment,
    Highcharts
  } = window;
  let TotalAndExpectingRaisedByMonthChart = (_class = class TotalAndExpectingRaisedByMonthChart extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "formatAmountService", _descriptor, this);

      _initializerDefineProperty(this, "fundsService", _descriptor2, this);

      _initializerDefineProperty(this, "settings", _descriptor3, this);
    }

    chartDivDidInsert(el) {
      (0, _runloop.next)(() => {
        Highcharts.chart(el, this.chartSettings);
      });
    }

    get selectedFund() {
      return this.fundsService.selectedFund;
    }

    get showBalance() {
      return !this.settings.orgSettings.incomeOnly;
    }

    get goalData() {
      return this.args.goal.goalData;
    }

    get runningGivenByMonth() {
      return this.goalData.running_given_by_month;
    }

    get goalAmount() {
      return this.args.goal.goalAmount;
    }

    get goalDate() {
      return _dateUtils.default.deserializeDateOnly(this.goalData.goal_date);
    }

    get series() {
      let {
        runningGivenByMonth,
        goalAmount,
        goalDate
      } = this;

      let firstMonth = _dateUtils.default.deserializeDateOnly(runningGivenByMonth[0].month);

      let lastGivenMonth = _dateUtils.default.deserializeDateOnly(runningGivenByMonth[runningGivenByMonth.length - 1].month);

      let series = [{
        type: 'area',
        color: '#0b68a1',
        name: 'Given',
        marker: {
          enabled: false
        },
        data: runningGivenByMonth.map(item => {
          let date = _dateUtils.default.deserializeDateOnly(item.month);

          return [Number(date), item.total_given];
        })
      }, {
        type: 'line',
        color: '#f0ad4e',
        name: 'Goal',
        lineWidth: 5,
        marker: {
          enabled: false
        },
        data: [[Number(firstMonth), goalAmount], [Number(goalDate), goalAmount]]
      }];

      if (goalDate > new Date()) {
        series.insertAt(0, {
          type: 'area',
          color: '#d0eafb',
          //'#e6e6e6',
          lineWidth: 2,
          name: 'Given & Expecting',
          marker: {
            enabled: false
          },
          data: [[Number(lastGivenMonth), this.goalData.given_amount], [Number(goalDate), this.goalData.given_amount + this.goalData.expected_amount]]
        });
      }

      return series;
    }

    get chartSettings() {
      let self = this;
      return {
        chart: {
          height: 225,
          // margin: [2, 0, 2, 0],
          borderWidth: 0
        },
        title: null,
        credits: {
          enabled: false
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          min: 0,
          maxPadding: 0.0,
          endOnTick: false,
          title: {
            text: null
          }
        },
        legend: {
          enabled: false
        },
        tooltip: {
          formatter: function () {
            let date = moment(this.x);
            return `${date.format('MMMM YYYY')}<br/>${this.series.name}: <b>${self.formatAmountService.format(this.y)}</b>`;
          }
        },
        plotOptions: {},
        series: this.series
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "formatAmountService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "chartDivDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "chartDivDidInsert"), _class.prototype)), _class);
  _exports.default = TotalAndExpectingRaisedByMonthChart;
});