define("donor-elf-web/controllers/funds/fund/groups/group/add-to-mailchimp", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@glimmer/tracking"], function (_exports, _controller, _object, _service, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let GroupAddToMailchimpController = (_class = class GroupAddToMailchimpController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "savingMessage", _descriptor3, this);

      _initializerDefineProperty(this, "savedMessage", _descriptor4, this);

      _initializerDefineProperty(this, "error", _descriptor5, this);

      _defineProperty(this, "model", void 0);
    }

    templateWillDestroy() {
      this.savingMessage = undefined;
      this.savedMessage = undefined;
      this.error = undefined;
    }

    async syncFirstEmails() {
      this.savingMessage = 'Syncing first emails in this group with MailChimp...';

      try {
        let model = this.store.createRecord('mailchimp-sync-group-email', {
          groupName: this.model.group,
          syncFirstEmails: true
        });
        await model.save();
        this.savedMessage = 'Synced first emails in this group with MailChimp';
      } catch (e) {
        this.error = e;
      }
    }

    async syncAllEmails() {
      this.savingMessage = 'Syncing all emails in this group with MailChimp...';

      try {
        let model = this.store.createRecord('mailchimp-sync-group-email', {
          groupName: this.model.group,
          syncAllEmails: true
        });
        await model.save();
        this.savedMessage = 'Synced all emails in this group with MailChimp';
      } catch (e) {
        this.error = e;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "savingMessage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "savedMessage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "error", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "syncFirstEmails", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "syncFirstEmails"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "syncAllEmails", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "syncAllEmails"), _class.prototype)), _class);
  _exports.default = GroupAddToMailchimpController;
});