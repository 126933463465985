define("donor-elf-web/routes/coaching", ["exports", "@ember/routing/route", "ember-simple-auth/mixins/authenticated-route-mixin", "rsvp", "@ember/service", "@ember/object"], function (_exports, _route, _authenticatedRouteMixin, _rsvp, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_authenticatedRouteMixin.default, {
    abilities: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),
    settings: (0, _service.inject)(),
    store: (0, _service.inject)(),

    beforeModel() {
      this._super(...arguments);

      (0, _object.set)(this, 'navbarSettings.currentMenuLabel', 'coachingMenu');
      (0, _object.set)(this, 'navbarSettings.rootMenuLabel', 'coachingMenu');
      (0, _object.set)(this, 'navbarSettings.topNavbarComponent', 'top-navbar-coaching');
    },

    model() {
      return _rsvp.default.hash({
        settings: (0, _object.get)(this, 'settings').loadSettings(-1),
        abilities: (0, _object.get)(this, 'abilities').loadCoachingAbilities()
      });
    },

    actions: {
      willTransition(transition) {
        let intentName = (0, _object.get)(transition, 'intent.name') || '';

        if (intentName.length > 0 && intentName.indexOf('coaching.') !== 0) {
          (0, _object.set)(this, 'navbarSettings.currentMenuLabel', 'rootMenu');
          (0, _object.set)(this, 'navbarSettings.rootMenuLabel', 'rootMenu');
        }
      }

    }
  });

  _exports.default = _default;
});