define("donor-elf-web/controllers/funds/fund/contacts/upload/save", ["exports", "@ember/object/computed", "@ember/controller", "@ember/object", "@ember/service", "ember-concurrency"], function (_exports, _computed, _controller, _object, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    contactsCache: (0, _service.inject)(),
    store: (0, _service.inject)(),
    uploadContacts: (0, _service.inject)(),
    error: null,
    savingContactIndex: -1,
    hasMoreContactsToSave: (0, _object.computed)('savingContactIndex', 'uploadContacts.contactObjects.length', function () {
      return (0, _object.get)(this, 'savingContactIndex') < (0, _object.get)(this, 'uploadContacts.contactObjects.length');
    }),
    contactsToSave: (0, _computed.alias)('uploadContacts.contactObjects.length'),
    savingContactNumber: (0, _object.computed)('savingContactIndex', function () {
      return (0, _object.get)(this, 'savingContactIndex') + 1;
    }),

    reset() {
      (0, _object.setProperties)(this, {
        error: null,
        savingContactIndex: -1
      });
    },

    save: (0, _emberConcurrency.task)(function* () {
      try {
        this.incrementProperty('savingContactIndex');

        if (!this.hasMoreContactsToSave) {
          return;
        }

        let contactObject = this.uploadContacts.contactObjects.objectAt(this.savingContactIndex);
        let record = this.store.createRecord('contact', contactObject);
        yield record.save();
        this.contactsCache.contacts.addObject(record);
        (0, _object.get)(this, 'save').perform();
      } catch (error) {
        (0, _object.set)(this, 'error', error);
      }
    })
  });

  _exports.default = _default;
});