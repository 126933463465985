define("donor-elf-web/controllers/admin/funds/fund/edit", ["exports", "@ember/controller", "@ember/object", "@ember/service"], function (_exports, _controller, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    store: (0, _service.inject)(),
    saving: false,
    showValidationErrors: false,

    reset() {
      (0, _object.setProperties)(this, {
        saving: false,
        showValidationErrors: false
      });
    },

    actions: {
      save() {
        let changeset = (0, _object.get)(this, 'model.changeset');
        changeset.validate().then(() => {
          if ((0, _object.get)(changeset, 'isValid')) {
            (0, _object.setProperties)(this, {
              saving: true,
              showValidationErrors: false
            });
            changeset.save().then(() => {
              this.transitionToRoute('admin.funds.fund', (0, _object.get)(this, 'model.record.id'));
            });
          } else {
            (0, _object.set)(this, 'showValidationErrors', true);
          }
        });
      }

    }
  });

  _exports.default = _default;
});