define("donor-elf-web/models/organization/entity-detail", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    entity: (0, _relationships.belongsTo)('organization/entity'),
    lineItems: hasMany('organization/line-item'),
    pledges: hasMany('organization/pledge')
  });

  _exports.default = _default;
});