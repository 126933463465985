define("donor-elf-web/templates/funds/fund/contacts/contact/dates/delete", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "mEt3KSR/",
    "block": "[[[6,[39,0],null,[[\"cancelRoute\",\"deleteTask\"],[\"funds.fund.contacts.contact\",[33,1]]],[[\"default\"],[[[[1,\"  \"],[10,\"h4\"],[12],[1,\"Delete Date\"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Are you sure you want to delete this date?\\n  \"],[13],[1,\"\\n  \"],[10,\"dl\"],[12],[1,\"\\n    \"],[10,\"dt\"],[12],[1,[33,2,[\"record\",\"label\"]]],[13],[1,\"\\n    \"],[10,\"dd\"],[12],[1,[28,[35,3],[[33,2,[\"record\",\"originalDate\"]]],[[\"fourYear\"],[true]]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"delete-form\",\"delete\",\"model\",\"format-date\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/contacts/contact/dates/delete.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});