define("donor-elf-web/templates/funds/fund/properties/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "fKrST3fk",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[10,\"table\"],[14,0,\"table table-sm mb-0 -tw-mt-2 border-bottom\"],[12],[1,\"\\n    \"],[10,\"thead\"],[12],[1,\"\\n      \"],[10,\"tr\"],[12],[1,\"\\n        \"],[10,\"th\"],[14,0,\"border-top-0\"],[12],[1,\"Property\"],[13],[1,\"\\n        \"],[10,\"th\"],[14,0,\"border-top-0 text-right\"],[12],[1,\"# Contacts\"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1,[\"propertyInfos\"]]],null]],null],null,[[[1,\"        \"],[10,\"tr\"],[12],[1,\"\\n          \"],[10,\"td\"],[12],[1,\"\\n            \"],[8,[39,3],null,[[\"@route\",\"@model\"],[\"funds.fund.properties.property\",[30,2,[\"property\",\"l\"]]]],[[\"default\"],[[[[1,\"\\n              \"],[1,[30,2,[\"property\",\"l\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,\"td\"],[14,0,\"text-right\"],[12],[1,\"\\n            \"],[1,[28,[35,4],[[30,2,[\"contactCount\"]]],null]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@model\",\"item\"],false,[\"form-card\",\"each\",\"-track-array\",\"link-to\",\"format-number\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/properties/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});