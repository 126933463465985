define("donor-elf-web/templates/funds/fund/more/donor-hub/connect/organization", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "+UAOUNWl",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@changeset\",\"@property\",\"@label\",\"@placeholder\",\"@selectOptions\",\"@showValidationErrors\"],[[30,1,[\"changeset\"]],\"tntOrganizationId\",\"Please select which organization you serve with\",\"Choose Organization\",[30,0,[\"tntOrganizationOptions\"]],[30,0,[\"showValidationErrors\"]]]],null],[1,\"\\n  \"],[10,0],[14,0,\"mt-4\"],[12],[1,\"\\n    \"],[10,\"button\"],[15,\"onclick\",[28,[37,2],[[33,3]],null]],[14,0,\"btn btn-warning\"],[15,\"disabled\",[29,[[52,[30,0,[\"save\",\"isRunning\"]],\"disable\"]]]],[12],[1,\"\\n      Next\\n    \"],[13],[1,\"\\n    \"],[8,[39,5],[[24,0,\"ml-4\"]],[[\"@route\"],[\"funds.fund.more.donor-hub\"]],[[\"default\"],[[[[1,\"\\n      Cancel\\n    \"]],[]]]]],[1,\"\\n\"],[41,[30,0,[\"save\",\"isRunning\"]],[[[1,\"      \"],[10,0],[14,0,\"alert alert-info mt-4\"],[12],[1,\"\\n        Saving...\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[41,[30,0,[\"error\"]],[[[1,\"    \"],[8,[39,6],[[24,0,\"mt-4\"]],[[\"@error\"],[[30,0,[\"error\"]]]],null],[1,\"\\n\"]],[]],null]],[]]]]],[1,\"\\n\"]],[\"@model\"],false,[\"form-card\",\"form-group-power-select\",\"perform\",\"save\",\"if\",\"link-to\",\"generic-error\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/more/donor-hub/connect/organization.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});