define("donor-elf-web/controllers/funds/fund/campaigns/approve-transactions", ["exports", "@ember/controller", "@glimmer/tracking", "ember-cached-decorator-polyfill", "@ember/service", "ember-changeset", "ember-changeset-validations", "donor-elf-web/validations/campaign-line-item", "donor-elf-web/lib/filter-utils-new", "@ember/utils", "donor-elf-web/lib/object-utils", "@ember/array", "donor-elf-web/lib/store-utils", "donor-elf-web/lib/array-utils"], function (_exports, _controller, _tracking, _emberCachedDecoratorPolyfill, _service, _emberChangeset, _emberChangesetValidations, _campaignLineItem, _filterUtilsNew, _utils, _objectUtils, _array, _storeUtils, _arrayUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ApproveTransactionsRow = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _class3, _descriptor7, _class5, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    moment
  } = window;
  const PAGE_SIZE = 250;
  let ApproveTransactionsRow = (_class = class ApproveTransactionsRow {
    constructor(args) {
      _defineProperty(this, "lineItem", void 0);

      _initializerDefineProperty(this, "selected", _descriptor, this);

      _initializerDefineProperty(this, "campaignLineItemChangeset", _descriptor2, this);

      _initializerDefineProperty(this, "pledgeInfos", _descriptor3, this);

      _initializerDefineProperty(this, "showValidationErrors", _descriptor4, this);

      _initializerDefineProperty(this, "approving", _descriptor5, this);

      _initializerDefineProperty(this, "approveError", _descriptor6, this);

      this.lineItem = args.lineItem;
      this.campaignLineItemChangeset = args.campaignLineItemChangeset;
      this.pledgeInfos = args.pledgeInfos || [];
    }

    get approved() {
      var _this$campaignLineIte;

      return !this.approving && ((_this$campaignLineIte = this.campaignLineItemChangeset) === null || _this$campaignLineIte === void 0 ? void 0 : _this$campaignLineIte.get('approved')) === true;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selected", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "campaignLineItemChangeset", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "pledgeInfos", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showValidationErrors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "approving", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "approveError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.ApproveTransactionsRow = ApproveTransactionsRow;
  let PledgeAndCampaignPledge = (_class3 = class PledgeAndCampaignPledge {
    constructor(args) {
      _defineProperty(this, "pledge", void 0);

      _initializerDefineProperty(this, "campaignPledge", _descriptor7, this);

      this.pledge = args.pledge;
      this.campaignPledge = args.campaignPledge;
    }

  }, (_descriptor7 = _applyDecoratedDescriptor(_class3.prototype, "campaignPledge", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class3);
  let CampaignsApproveTransactionsController = (_class5 = class CampaignsApproveTransactionsController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "addSomethingModal", _descriptor8, this);

      _initializerDefineProperty(this, "campaignService", _descriptor9, this);

      _initializerDefineProperty(this, "fundGoalService", _descriptor10, this);

      _initializerDefineProperty(this, "media", _descriptor11, this);

      _initializerDefineProperty(this, "store", _descriptor12, this);

      _defineProperty(this, "queryParams", ['filters']);

      _initializerDefineProperty(this, "filters", _descriptor13, this);

      _initializerDefineProperty(this, "page", _descriptor14, this);

      _initializerDefineProperty(this, "appliedFilters", _descriptor15, this);

      _initializerDefineProperty(this, "loading", _descriptor16, this);

      _initializerDefineProperty(this, "loadingMore", _descriptor17, this);

      _defineProperty(this, "controllerIsActive", false);

      _initializerDefineProperty(this, "rows", _descriptor18, this);

      _initializerDefineProperty(this, "tableColumns", _descriptor19, this);

      _initializerDefineProperty(this, "queryTotal", _descriptor20, this);

      _defineProperty(this, "savePledgeSubsciptionToken", void 0);

      _defineProperty(this, "load", async () => {
        this.loading = true;
        await this.campaignService.load();
        let queryFilter = {
          offset: 0,
          limit: PAGE_SIZE,
          exclude_meta_total_amount: true
        };
        let {
          availablePropertyFilters
        } = this;

        for (let appliedFilter of this.appliedFilters || []) {
          let propertyFilter = availablePropertyFilters.find(x => x.propertyName === appliedFilter.propertyName);
          await propertyFilter.applyToQueryFilter(appliedFilter, queryFilter);
        }

        let records = await (0, _storeUtils.queryWithPages)({
          store: this.store,
          modelName: 'line-item',
          queryOptions: {
            filter: _objectUtils.default.decamelizeKeys(queryFilter)
          },
          pageSize: PAGE_SIZE
        });
        let pledges = [];
        let campaignPledges = [];

        if (records.length > 0) {
          let earliestTransDate = records.reduce((earliest, record) => {
            return earliest.transDate <= record.transDate ? earliest : record;
          }).transDate;
          pledges = await (0, _storeUtils.queryWithPages)({
            store: this.store,
            modelName: 'pledge',
            queryOptions: {
              filter: {
                start_date: moment(earliestTransDate).format('YYYY-MM-DD')
              }
            },
            pageSize: PAGE_SIZE
          });
          campaignPledges = (await this.store.query('campaign-pledge', {
            filter: {
              pledge_ids: pledges.map(x => x.id)
            }
          })).toArray();
        }

        let pledgesByContactId = (0, _arrayUtils.groupArrayBy)(pledges, pledge => pledge.contactId);
        let campaignPledgesByPledgeId = (0, _arrayUtils.groupArrayBy)(campaignPledges, campaignPledge => campaignPledge.pledgeId);
        this.queryTotal = records.length;
        let rows = [];
        let {
          campaignLineItemValidation
        } = this;

        for (let lineItem of records) {
          var _lineItem$campaignLin;

          let campaignLineItem = ((_lineItem$campaignLin = lineItem.campaignLineItemsArray) === null || _lineItem$campaignLin === void 0 ? void 0 : _lineItem$campaignLin[0]) || this.store.createRecord('campaign-line-item', {
            lineItemId: lineItem.id,
            amount: lineItem.amount,
            approved: false
          });
          let campaignLineItemChangeset = new _emberChangeset.default(campaignLineItem, (0, _emberChangesetValidations.default)(campaignLineItemValidation), campaignLineItemValidation);
          let pledges = pledgesByContactId[lineItem.contactId] || []; // if the line item isn't associated with a campaign, and there's only one
          // pledge with campaignId, set the campaignId to the pledge's campaignId

          if (campaignLineItem.campaignId == null && pledges.length === 1) {
            var _campaignPledgesByPle;

            let campaignPledge = (_campaignPledgesByPle = campaignPledgesByPledgeId[pledges[0].id]) === null || _campaignPledgesByPle === void 0 ? void 0 : _campaignPledgesByPle[0];

            if (campaignPledge) {
              campaignLineItemChangeset.set('campaignId', campaignPledge.campaignId);
            }
          }

          rows.push(new ApproveTransactionsRow({
            lineItem,
            campaignLineItemChangeset,
            pledgeInfos: pledges.map(pledge => {
              var _campaignPledgesByPle2;

              return new PledgeAndCampaignPledge({
                pledge,
                campaignPledge: (_campaignPledgesByPle2 = campaignPledgesByPledgeId[pledge.id]) === null || _campaignPledgesByPle2 === void 0 ? void 0 : _campaignPledgesByPle2[0]
              });
            })
          }));
        }

        this.rows.addObjects(rows);
        this.loading = false;
      });

      _defineProperty(this, "templateDidInsert", () => {
        this.controllerIsActive = true;
        this.setTableColumns();

        if (this.savePledgeSubsciptionToken == null) {
          this.savePledgeSubsciptionToken = this.addSomethingModal.subscribeToPledgeSaved(this.handleAddSomethingPledgeSaved);
        }

        if ((0, _utils.isEmpty)(this.filters)) {
          this.filters = (0, _filterUtilsNew.serializeAppliedFilters)([this.defaultDateRangeAppliedFilter, this.notApprovedAppliedFilter]);
        }

        this.deserializeFilters();
        this.load();
      });

      _defineProperty(this, "templateWillDestroy", () => {
        if (this.savePledgeSubsciptionToken) {
          this.addSomethingModal.unsubscribeWithToken(this.savePledgeSubsciptionToken);
        }

        this.controllerIsActive = false;
        this.loading = true;
        this.loadingMore = false;
        this.filters = undefined;
        this.page = 1;
        this.appliedFilters = undefined;
        this.rows = (0, _array.A)([]);
        this.tableColumns = [];
      });

      _defineProperty(this, "adjustTableContainerHeight", el => {
        let newHeight = window.innerHeight - (el.getBoundingClientRect().top + window.scrollY) - 40;
        el.style.height = `${newHeight}px`;
      });

      _defineProperty(this, "handleFiltersChanged", currentFilters => {
        // only allow one dateRange filter
        if (currentFilters.filter(x => x.propertyName === 'transDate').length > 1) {
          currentFilters = [currentFilters.find(x => x.propertyName === 'transDate'), ...currentFilters.filter(x => x.propertyName !== 'transDate')];
        } // confirm a dateRange filter is present


        if (!currentFilters.some(x => x.propertyName === 'transDate')) {
          currentFilters.push(this.defaultDateRangeAppliedFilter);
        }

        if (!currentFilters.some(x => x.propertyName === this.notApprovedAppliedFilter.propertyName)) {
          currentFilters.push(this.notApprovedAppliedFilter);
        }

        this.appliedFilters = currentFilters;
        this.serializeFilters();
        this.load();
      });

      _defineProperty(this, "handleAddSomethingPledgeSaved", async (_msg, data) => {
        let contactRows = this.rows.filter(r => r.lineItem.contactId === data.pledge.contactId);

        if (data.newPledge) {
          for (let row of contactRows) {
            // if the pledge is associated with a campaign but the line item isn't
            // associated with a campaign, then set the campaignId to the pledge's
            // campaignId
            if (data.campaignPledge && row.campaignLineItemChangeset.get('campaignId') == null) {
              row.campaignLineItemChangeset.set('campaignId', data.campaignPledge.campaignId);
            }

            row.pledgeInfos = [...row.pledgeInfos, new PledgeAndCampaignPledge({
              pledge: data.pledge,
              campaignPledge: data.campaignPledge
            })];
          }
        } else if (data.deleted) {
          for (let row of contactRows) {
            row.pledgeInfos = row.pledgeInfos.filter(x => x.pledge.id !== data.pledge.id);
          }
        } else {
          for (let row of contactRows) {
            let pledgeInfo = row.pledgeInfos.find(x => x.pledge.id === data.pledge.id);

            if ((pledgeInfo === null || pledgeInfo === void 0 ? void 0 : pledgeInfo.campaignPledge) !== data.campaignPledge) {
              pledgeInfo.campaignPledge = data.campaignPledge;
            } // if the pledge is associated with a campaign but the line item isn't
            // associated with a campaign, then set the campaignId to the pledge's
            // campaignId


            if (data.campaignPledge && row.campaignLineItemChangeset.get('campaignId') == null) {
              row.campaignLineItemChangeset.set('campaignId', data.campaignPledge.campaignId);
            }
          }
        }
      });
    }

    get leftToApproveCount() {
      return this.queryTotal - this.rows.filter(r => r.approved).length;
    }

    get campaignSelectOptions() {
      return this.campaignService.sortedCampaigns.map(campaign => {
        return {
          val: campaign.id,
          label: campaign.name
        };
      });
    }

    get campaignLineItemValidation() {
      return (0, _campaignLineItem.validateCampaignLineItem)({
        campaignRequired: true
      });
    }

    get availablePropertyFilters() {
      let list = [];
      list.push({
        propertyName: 'transDate',
        label: 'Date',
        propertyType: 'date',
        availableOperations: ['><', '>=', '<=', '='],
        applyToQueryFilter: async (appliedFilter, queryFilter) => {
          if (appliedFilter.operation === '>=') {
            queryFilter.from = appliedFilter.val;
          } else if (appliedFilter.operation === '<=') {
            queryFilter.to = appliedFilter.val;
          } else if (appliedFilter.operation === '><') {
            let dates = appliedFilter.val;
            queryFilter.from = dates[0];
            queryFilter.to = dates[1];
          } else {
            queryFilter.date = appliedFilter.val;
          }
        }
      });
      list.push({
        propertyName: 'campaignNotApproved',
        label: 'Not Approved',
        propertyType: 'no-operation',
        applyToQueryFilter: async (_appliedFilter, queryFilter) => {
          queryFilter.campaignNotApproved = true;
        }
      });
      return list;
    }

    setTableColumns() {
      let tableColumns = [];
      tableColumns.push({
        name: 'Campaign',
        isFixed: 'left',
        width: 50,
        isCampaignColumn: true
      });
      tableColumns.push({
        name: 'Contact',
        width: this.media.isSmallDown ? 100 : 100,
        isContactColumn: true
      });
      tableColumns.push({
        name: 'Transaction',
        width: 200,
        isTransactionColumn: true
      });
      this.tableColumns = tableColumns;
    }

    get defaultDateRangeAppliedFilter() {
      let from = this.fundGoalService.hasYtdGoalType ? this.fundGoalService.ytdStartMoment.format('YYYY-MM-DD') : moment().subtract(11, 'months').startOf('month').format('YYYY-MM-DD');
      return {
        propertyName: 'transDate',
        operation: '>=',
        val: from
      };
    }

    get notApprovedAppliedFilter() {
      return {
        propertyName: 'campaignNotApproved'
      };
    }

    serializeFilters() {
      this.filters = (0, _filterUtilsNew.serializeAppliedFilters)(this.appliedFilters);
    }

    deserializeFilters() {
      this.appliedFilters = (0, _filterUtilsNew.deserializeAppliedFilters)(this.filters);
    }

  }, (_descriptor8 = _applyDecoratedDescriptor(_class5.prototype, "addSomethingModal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class5.prototype, "campaignService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class5.prototype, "fundGoalService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class5.prototype, "media", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class5.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class5.prototype, "filters", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class5.prototype, "page", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 1;
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class5.prototype, "appliedFilters", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class5.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor17 = _applyDecoratedDescriptor(_class5.prototype, "loadingMore", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor18 = _applyDecoratedDescriptor(_class5.prototype, "rows", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _array.A)([]);
    }
  }), _descriptor19 = _applyDecoratedDescriptor(_class5.prototype, "tableColumns", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor20 = _applyDecoratedDescriptor(_class5.prototype, "queryTotal", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class5.prototype, "campaignSelectOptions", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class5.prototype, "campaignSelectOptions"), _class5.prototype), _applyDecoratedDescriptor(_class5.prototype, "campaignLineItemValidation", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class5.prototype, "campaignLineItemValidation"), _class5.prototype), _applyDecoratedDescriptor(_class5.prototype, "availablePropertyFilters", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class5.prototype, "availablePropertyFilters"), _class5.prototype)), _class5);
  _exports.default = CampaignsApproveTransactionsController;
});