define("donor-elf-web/components/contact-duplicate-contact", ["exports", "@ember/component", "@ember/object", "@ember/service"], function (_exports, _component, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    attrs
    - contactId
    - showAllNameProperties
  */
  var _default = _component.default.extend({
    contactsCache: (0, _service.inject)(),
    contactId: null,
    contact: (0, _object.computed)('contactId', function () {
      return (0, _object.get)(this, 'contactsCache').getById((0, _object.get)(this, 'contactId'));
    }),
    showAllNameProperties: true
  });

  _exports.default = _default;
});