define("donor-elf-web/helpers/is-groups-property", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Currently the 'Groups' ContactCustomProperty is not really a custom property
  // but we plan to refactor it to be one in the future. Many places in the code
  // treat 'Groups' as a custom property for consistency.
  var _default = (0, _helper.helper)(function ([contactCustomProperty]) {
    return contactCustomProperty.l === 'Groups';
  });

  _exports.default = _default;
});