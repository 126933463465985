define("donor-elf-web/routes/admin/funds/fund/delete", ["exports", "@ember/routing/route", "@ember/object", "@ember/service"], function (_exports, _route, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    abilities: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),

    beforeModel(transition) {
      if (this.abilities.noAbility('admin_edit_funds')) {
        transition.abort();
        return;
      }

      (0, _object.set)(this, 'navbarSettings.title', 'Delete Fund');
    }

  });

  _exports.default = _default;
});