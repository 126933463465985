define("donor-elf-web/routes/funds/fund/transactions/edit/enter", ["exports", "@ember/routing/route", "rsvp", "@ember/service", "@ember/utils", "@glimmer/tracking"], function (_exports, _route, _rsvp, _service, _utils, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.TransactionsEditEnterRouteModel = void 0;

  var _class, _descriptor, _class3, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TransactionsEditEnterRouteModel = (_class = class TransactionsEditEnterRouteModel {
    constructor(focusOn, lastGiftForContact, transTypes) {
      _initializerDefineProperty(this, "focusOn", _descriptor, this);

      this.lastGiftForContact = lastGiftForContact;
      this.transTypes = transTypes;
      this.focusOn = focusOn;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "focusOn", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.TransactionsEditEnterRouteModel = TransactionsEditEnterRouteModel;
  let TransactionsEditEnterRoute = (_class3 = class TransactionsEditEnterRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "abilities", _descriptor2, this);

      _initializerDefineProperty(this, "fundsService", _descriptor3, this);

      _initializerDefineProperty(this, "navbarSettings", _descriptor4, this);

      _initializerDefineProperty(this, "settings", _descriptor5, this);

      _initializerDefineProperty(this, "store", _descriptor6, this);

      _initializerDefineProperty(this, "storeCache", _descriptor7, this);

      _initializerDefineProperty(this, "transactionsService", _descriptor8, this);

      _defineProperty(this, "queryParams", {
        contactId: {}
      });
    }

    async model(params, transition) {
      if (params.id === 'add') {
        this.navbarSettings.title = 'Add Transactions';
      } else {
        this.navbarSettings.title = 'Edit Transactions';
      }

      let lineItemInfos = this.transactionsService.lineItemInfos;
      let retrieveLineItemPromise = Promise.resolve();

      if (params.id !== 'add') {
        retrieveLineItemPromise = this.store.findRecord('line-item', params.id).then(model => {
          let record = this.transactionsService.createInfoFromModel(model);
          lineItemInfos.push(record);
        });
      }

      await retrieveLineItemPromise;

      if (lineItemInfos.length === 0) {
        let model = this.store.createRecord('line-item', {
          transDate: new Date()
        });

        if ((0, _utils.isPresent)(params.contactId)) {
          model.contactId = params.contactId;
        }

        lineItemInfos.push(this.transactionsService.createInfoFromModel(model));
      }

      let focusOn = {};

      if (params.id === 'add') {
        focusOn = {
          changeset: lineItemInfos[lineItemInfos.length - 1].changeset,
          fieldName: 'transDate'
        };
      } else {
        let existingRecord = lineItemInfos[0]; // Can't edit non-manual transaction if you don't have the edit_all_transactions ability

        if (!existingRecord.model.isManual && this.abilities.noAbility('edit_all_transactions')) {
          transition.abort();
          return new TransactionsEditEnterRouteModel(focusOn, {}, []);
        }
      }

      this.transactionsService.lineItemInfos = lineItemInfos;
      return _rsvp.default.hash({
        focusOn,
        lastGiftForContact: this.getLastGiftForContact(),
        transTypes: this.getTransTypes(lineItemInfos)
      }).then(results => {
        return new TransactionsEditEnterRouteModel(results.focusOn, results.lastGiftForContact, results.transTypes);
      });
    }

    getLastGiftForContact() {
      return this.store.query('line-item', {
        filter: {
          last_gift: true
        }
      }).then(lastGifts => {
        let lastGiftForContact = {};

        for (let lineItem of lastGifts.toArray()) {
          lastGiftForContact[lineItem.contactId] = lineItem;
        }

        return lastGiftForContact;
      });
    }

    getTransTypes(lineItemInfos) {
      var _this$settings$orgSet;

      if (lineItemInfos.length === 1 && lineItemInfos[0].model.isNew && ((_this$settings$orgSet = this.settings.orgSettings) === null || _this$settings$orgSet === void 0 ? void 0 : _this$settings$orgSet.manualTransactionTypes) != null) {
        return this.settings.orgSettings.manualTransactionTypes;
      }

      return this.storeCache.findRecord('transaction-filter-option', this.fundsService.selectedFund.id).then(record => {
        let transTypes = record.transTypes;

        if (transTypes.length === 0) {
          transTypes.push('Gift');
        }

        return transTypes;
      });
    }

  }, (_descriptor2 = _applyDecoratedDescriptor(_class3.prototype, "abilities", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class3.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "navbarSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class3.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class3.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class3.prototype, "storeCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class3.prototype, "transactionsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class3);
  _exports.default = TransactionsEditEnterRoute;
});