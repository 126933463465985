define("donor-elf-web/helpers/stringify", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _helper.default.extend({
    compute(params) {
      return JSON.stringify(params[0]);
    }

  });

  _exports.default = _default;
});