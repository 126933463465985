define("donor-elf-web/validators/date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateDate;

  function validateDate() {
    return (key, newValue
    /*, oldValue, changes, content*/
    ) => {
      if (newValue != null && typeof newValue.getMonth === 'function') {
        return true;
      }

      return 'Must be a date';
    };
  }
});