define("donor-elf-web/components/form-group-select-contact", ["exports", "@ember/component", "@ember/object", "@ember/service"], function (_exports, _component, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    attrs
    - changeset
    - property
    - label (optional)
      - default is 'Contact'
    - showValidationErrors
    - focusOnInsert - if the input box should receive focus when it's inserted
    - disabled
    - showBlankContact
      - Whether to show a blank contact
      - This allows the user to 'unselect' a selected contact
      - default is false
    - blankContactLabel
      - Label for blank contact
      - default is 'None'
    - placeholder
      - Placeholder to show when a contact is not selected
      - default is 'Choose Contact'
    - onSelected
      - action to call when a contact is selected
  */
  var _default = _component.default.extend({
    contactsCache: (0, _service.inject)(),
    classNames: ['form-group'],
    classNameBindings: ['hasErrors'],
    label: 'Contact',
    changeset: null,
    // the associated property on the changeset
    property: null,
    showBlankContact: false,
    blankContactLabel: 'None',
    placeholder: 'Choose Contact',
    showValidationErrors: false,
    focusOnInsert: false,
    disabled: false,
    hasErrors: (0, _object.computed)('showValidationErrors', 'changeset.errors.[]', 'property', function () {
      if ((0, _object.get)(this, 'showValidationErrors') === false) {
        return false;
      }

      let property = (0, _object.get)(this, 'property');
      return (0, _object.get)(this, 'changeset.errors').find(function (item) {
        return (0, _object.get)(item, 'key') === property;
      }) != null;
    }),
    selectedContact: null,

    setSelectedContact() {
      let contact = null;
      let contactId = (0, _object.get)(this, `changeset.${(0, _object.get)(this, 'property')}`);

      if (contactId) {
        contact = (0, _object.get)(this, 'contactsCache').getById(contactId);
      }

      (0, _object.set)(this, 'selectedContact', contact);
    },

    didReceiveAttrs() {
      this._super(...arguments);

      this.setSelectedContact();
    },

    actions: {
      handleContactSelected(contactId) {
        (0, _object.set)(this, `changeset.${(0, _object.get)(this, 'property')}`, contactId);
        this.setSelectedContact();
        let onSelected = (0, _object.get)(this, 'onSelected');

        if (onSelected) {
          onSelected(contactId);
        }
      }

    }
  });

  _exports.default = _default;
});