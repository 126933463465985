define("donor-elf-web/controllers/funds/fund/dashboard/annual", ["exports", "@ember/object/computed", "@ember/controller", "@ember/object"], function (_exports, _computed, _controller, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    goal: (0, _computed.alias)('model.dashboard.goal'),
    ytdTotalAmount: (0, _object.computed)('goal.goalData.{annual_pledged_amount,annual_other_amount}', function () {
      return +(0, _object.get)(this, 'goal.goalData.annual_pledged_amount') + +(0, _object.get)(this, 'goal.goalData.annual_other_amount');
    }),
    lastMonthTotalAmount: (0, _object.computed)('goal.goalData.{last_month_pledged_amount,last_month_other_amount}', function () {
      return +(0, _object.get)(this, 'goal.goalData.last_month_pledged_amount') + +(0, _object.get)(this, 'goal.goalData.last_month_other_amount');
    }),
    lastMonthGoalAmount: (0, _object.computed)('goal.goalAmount', function () {
      return +(0, _object.get)(this, 'goal.goalAmount') / 12;
    })
  });

  _exports.default = _default;
});