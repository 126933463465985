define("donor-elf-web/components/form-file", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    events
    - onFileSelected(file)
      - when a file is chosen
  */
  var _default = _component.default.extend({
    actions: {
      onChange(e) {
        if (e.target.files.length === 1) {
          let file = e.target.files[0];
          this.onFileSelected(file);
        }
      }

    }
  });

  _exports.default = _default;
});