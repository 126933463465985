define("donor-elf-web/components/fund-mapping/host-identity", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@ember/utils"], function (_exports, _component, _templateFactory, _component2, _service, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class='d-flex' ...attributes>
    <div class='mr-3'>
      <FormGroupSelect
        @changeset={{@changeset}}
        @property='database'
        @options={{this.databaseOptions}}
        @showValidationErrors={{@showValidationErrors}}
      />
    </div>
    <div class='flex-grow-1 mr-2'>
      {{#if this.selectedDatabaseHasHostFunds}}
        <FormGroupPowerSelect
          @changeset={{@changeset}}
          @property='hostId'
          @hideLabel={{true}}
          @placeholder='Select Mapping'
          @options={{this.hostIdOptions}}
          @showValidationErrors={{@showValidationErrors}}
        />
      {{else}}
        <FormGroup
          @changeset={{@changeset}}
          @property='hostId'
          @showValidationErrors={{@showValidationErrors}}
        />
      {{/if}}
    </div>
    <div class='pt-1'>
      <button
        {{on 'click' @onDelete}}
        class='btn btn-sm btn-outline-danger'
        type='button'
      >
        {{svg-jar 'trash'}}
      </button>
    </div>
  </div>
  */
  {
    "id": "mi1G+f8F",
    "block": "[[[11,0],[24,0,\"d-flex\"],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"mr-3\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@changeset\",\"@property\",\"@options\",\"@showValidationErrors\"],[[30,2],\"database\",[30,0,[\"databaseOptions\"]],[30,3]]],null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex-grow-1 mr-2\"],[12],[1,\"\\n\"],[41,[30,0,[\"selectedDatabaseHasHostFunds\"]],[[[1,\"      \"],[8,[39,2],null,[[\"@changeset\",\"@property\",\"@hideLabel\",\"@placeholder\",\"@options\",\"@showValidationErrors\"],[[30,2],\"hostId\",true,\"Select Mapping\",[30,0,[\"hostIdOptions\"]],[30,3]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,3],null,[[\"@changeset\",\"@property\",\"@showValidationErrors\"],[[30,2],\"hostId\",[30,3]]],null],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"pt-1\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-sm btn-outline-danger\"],[24,4,\"button\"],[4,[38,4],[\"click\",[30,4]],null],[12],[1,\"\\n      \"],[1,[28,[35,5],[\"trash\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@changeset\",\"@showValidationErrors\",\"@onDelete\"],false,[\"form-group-select\",\"if\",\"form-group-power-select\",\"form-group\",\"on\",\"svg-jar\"]]",
    "moduleName": "donor-elf-web/components/fund-mapping/host-identity.hbs",
    "isStrictMode": false
  });

  let FundMappingHostIdentityComponent = (_class = class FundMappingHostIdentityComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fundMappingsService", _descriptor, this);
    }

    get databaseOptions() {
      let options = this.fundMappingsService.availableDatabaseOptions.slice();

      if ((0, _utils.isEmpty)(this.args.changeset.get('database'))) {
        options.insertAt(0, {
          val: undefined,
          label: ''
        });
      }

      return options;
    }

    get selectedDatabaseHasHostFunds() {
      return this.fundMappingsService.hostFundsByDatabaseAndHostId[this.args.changeset.get('database')] != null;
    }

    get otherChangesets() {
      return this.args.allChangesets.filter(x => x !== this.args.changeset);
    }

    get hostIdOptions() {
      var _this$fundMappingsSer;

      // filter out hostIds that are already in use by other changesets
      return (_this$fundMappingsSer = this.fundMappingsService.databaseHostFundOptions[this.args.changeset.get('database')]) === null || _this$fundMappingsSer === void 0 ? void 0 : _this$fundMappingsSer.filter(x => {
        return !this.otherChangesets.some(y => y.get('hostId') === x.val);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fundMappingsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = FundMappingHostIdentityComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FundMappingHostIdentityComponent);
});