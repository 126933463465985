define("donor-elf-web/templates/components/goal/balance-and-monthly-gifts-chart", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "+dJqf9bx",
    "block": "[[[11,0],[4,[38,0],[[30,0,[\"handleRenderTheChart\"]]],null],[4,[38,1],[[30,0,[\"handleRenderTheChart\"]],[30,1]],null],[12],[1,\"\\n\"],[41,[30,0,[\"showBalance\"]],[[[1,\"    \"],[10,0],[14,0,\"d-flex mb-3 mb-md-4\"],[12],[1,\"\\n      \"],[10,\"h4\"],[14,0,\"mb-0\"],[12],[1,\"Balance\"],[13],[1,\"\\n      \"],[10,\"h4\"],[14,0,\"text-brand ml-2_5 mb-0\"],[12],[1,[28,[35,3],[[30,2,[\"balance\"]]],[[\"removeNoCents\"],[true]]]],[13],[1,\"\\n      \"],[10,\"small\"],[14,0,\"text-muted font-weight-light ml-auto align-self-end\"],[12],[1,[28,[35,4],[[30,2]],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"h5\"],[12],[1,\"Monthly Gifts\"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"d-flex mb-2\"],[12],[1,\"\\n      \"],[10,\"h4\"],[14,0,\"mb-0\"],[12],[1,\"Monthly Gifts\"],[13],[1,\"\\n      \"],[10,\"small\"],[14,0,\"text-muted font-weight-light ml-auto align-self-end\"],[12],[1,[28,[35,4],[[30,2]],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[10,0],[15,0,[29,[\"monthly-gifts-chart \",[52,[30,0,[\"showBalance\"]],\"has-balance\"]]]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@renderChart\",\"@fund\"],false,[\"did-insert\",\"did-update\",\"if\",\"format-amount\",\"fund-last-updated-text\"]]",
    "moduleName": "donor-elf-web/templates/components/goal/balance-and-monthly-gifts-chart.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});