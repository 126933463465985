define("donor-elf-web/routes/admin/funds/index", ["exports", "@ember/routing/route", "@ember/object", "rsvp", "@ember/service"], function (_exports, _route, _object, _rsvp, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AdminFundsIndexRoute = (_class = class AdminFundsIndexRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fundsService", _descriptor, this);

      _initializerDefineProperty(this, "navbarSettings", _descriptor2, this);

      _initializerDefineProperty(this, "storeCache", _descriptor3, this);

      _defineProperty(this, "queryParams", {
        q: {
          replace: true
        }
      });
    }

    beforeModel() {
      this.navbarSettings.title = 'Funds';
      return this.fundsService.confirmValidFundToken();
    }

    model() {
      let promise = _rsvp.default.hash({
        usersById: this.storeCache.indexSingleBy('admin/user', 'id'),
        funds: this.storeCache.findAll('admin/fund'),
        userFundsByFundId: this.storeCache.indexMultipleBy('admin/user-fund', 'fundId')
      });

      return promise.then(data => {
        let model = [];
        data.funds.forEach(fund => {
          let userFunds = data.userFundsByFundId[fund.id] || [];
          model.push({
            fund: fund,
            users: userFunds.map(userFund => data.usersById[userFund.userId]).filter(user => (user === null || user === void 0 ? void 0 : user.role) === 'User')
          });
        });
        return model;
      });
    }

    setupController(controller) {
      super.setupController(...arguments);
      let params = this.paramsFor('admin.funds.index');
      (0, _object.setProperties)(controller, {
        q: params.q,
        qInput: params.q
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "navbarSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "storeCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = AdminFundsIndexRoute;
});