define("donor-elf-web/templates/components/contact-duplicate-contact", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "aRb52rct",
    "block": "[[[6,[39,0],null,[[\"route\",\"model\"],[\"funds.fund.contacts.contact\",[33,1,[\"id\"]]]],[[\"default\"],[[[[1,[33,1,[\"name\"]]]],[]]]]],[1,\"\\n\"],[41,[33,3],[[[1,\"  \"],[10,\"dl\"],[12],[1,\"\\n\"],[41,[33,1,[\"mailingName\"]],[[[1,\"      \"],[10,\"dt\"],[14,0,\"text-muted font-weight-light\"],[12],[1,\"Mailing Name:\"],[13],[1,\"\\n      \"],[10,\"dd\"],[12],[1,[33,1,[\"mailingName\"]]],[13],[1,\"\\n\"]],[]],null],[41,[33,1,[\"firstName\"]],[[[1,\"      \"],[10,\"dt\"],[14,0,\"text-muted font-weight-light\"],[12],[1,\"First Name:\"],[13],[1,\"\\n      \"],[10,\"dd\"],[12],[1,[33,1,[\"firstName\"]]],[13],[1,\"\\n\"]],[]],null],[41,[33,1,[\"spouseFirstName\"]],[[[1,\"      \"],[10,\"dt\"],[14,0,\"text-muted font-weight-light\"],[12],[1,\"Spouse First Name:\"],[13],[1,\"\\n      \"],[10,\"dd\"],[12],[1,[33,1,[\"spouseFirstName\"]]],[13],[1,\"\\n\"]],[]],null],[41,[33,1,[\"lastName\"]],[[[1,\"      \"],[10,\"dt\"],[14,0,\"text-muted font-weight-light\"],[12],[1,\"Last Name:\"],[13],[1,\"\\n      \"],[10,\"dd\"],[12],[1,[33,1,[\"lastName\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,\"dt\"],[14,0,\"text-muted font-weight-light\"],[12],[1,\"Address:\"],[13],[1,\"\\n    \"],[10,\"dd\"],[12],[1,\"\\n      \"],[10,0],[12],[1,[33,1,[\"address1\"]]],[13],[1,\"\\n      \"],[10,0],[12],[1,[33,1,[\"address2\"]]],[13],[1,\"\\n\"],[41,[33,1,[\"cityAndState\"]],[[[1,\"        \"],[10,0],[12],[1,[33,1,[\"cityAndState\"]]],[1,\" \"],[1,[33,1,[\"zip\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[12],[1,[33,1,[\"address1\"]]],[13],[1,\"\\n  \"],[10,0],[12],[1,[33,1,[\"address2\"]]],[13],[1,\"\\n\"],[41,[33,1,[\"cityAndState\"]],[[[1,\"    \"],[10,0],[12],[1,[33,1,[\"cityAndState\"]]],[1,\" \"],[1,[33,1,[\"zip\"]]],[13],[1,\"\\n\"]],[]],null]],[]]]],[],false,[\"link-to\",\"contact\",\"if\",\"showAllNameProperties\"]]",
    "moduleName": "donor-elf-web/templates/components/contact-duplicate-contact.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});