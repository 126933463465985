define("donor-elf-web/controllers/funds/fund/tasks/v2/by-contact", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "@ember/service", "donor-elf-web/lib/array-utils", "@ember/utils"], function (_exports, _controller, _object, _tracking, _service, _arrayUtils, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const PAGE_SIZE = 50;
  let TasksByContactController = (_dec = (0, _object.computed)('sortedItems', 'contactFilter', 'group'), _dec2 = (0, _object.computed)('page', 'filteredItems'), (_class = class TasksByContactController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "contactsCache", _descriptor, this);

      _initializerDefineProperty(this, "fundsService", _descriptor2, this);

      _initializerDefineProperty(this, "media", _descriptor3, this);

      _defineProperty(this, "queryParams", ['page', 'group', {
        contactFilter: 'q'
      }]);

      _defineProperty(this, "page", 1);

      _defineProperty(this, "contactFilter", '');

      _defineProperty(this, "group", '');

      _initializerDefineProperty(this, "sortedItems", _descriptor4, this);

      _defineProperty(this, "sortByProperty", 'notComplete.length');

      _defineProperty(this, "sortByDirection", 'desc');
    }

    get columnsToDisplay() {
      if (this.media.isSmallDown) {
        return 1;
      } else if (this.media.isMedium) {
        return 2;
      } else {
        return 3;
      }
    }

    get sortBy() {
      return [`${this.sortByProperty}:${this.sortByDirection}`];
    }

    sortThoseItems() {
      this.sortedItems = (0, _arrayUtils.sortByProperties)(this.model.contactItems, this.sortBy);
    }

    get filteredItems() {
      var _this$contactFilter;

      let items = this.sortedItems;

      if ((0, _utils.isPresent)(this.group)) {
        let groupContactIds = {};
        this.contactsCache.groupContacts[this.group].contacts.forEach(x => {
          groupContactIds[x.id] = true;
        });
        items = items.filter(x => groupContactIds[x.contact.id]);
      }

      if (this.sortedItems.length === 0 || (((_this$contactFilter = this.contactFilter) === null || _this$contactFilter === void 0 ? void 0 : _this$contactFilter.length) || 0) <= 1) {
        return items;
      }

      let matchedContactIds = {};
      this.contactsCache.search(this.contactFilter).forEach(contact => {
        matchedContactIds[contact.id] = true;
      });
      return items.filter(x => matchedContactIds[x.contact.id]);
    }

    get pagedItems() {
      let offset = (this.page - 1) * PAGE_SIZE;
      return this.filteredItems.slice(offset, offset + PAGE_SIZE);
    }

    get totalPages() {
      return Math.ceil(this.filteredItems.length / PAGE_SIZE);
    }

    get availableGroupOptions() {
      return [{
        val: '',
        label: 'Filter by Groups'
      }].concat(this.contactsCache.sortedGroups.map(x => {
        return {
          val: x,
          label: x
        };
      }));
    }

    templateDidInsert() {
      this.sortThoseItems();
    }

    templateWillDestroy() {
      (0, _object.setProperties)(this, {
        contactFilter: '',
        group: '',
        page: 1
      });
    }

    handleFilterByGroup(group) {
      (0, _object.set)(this, 'group', group);
    }

    handleFilterGroupSelectChanged(e) {
      this.handleFilterByGroup(e.target.value);
    }

    handleContactFilterInput(e) {
      (0, _object.setProperties)(this, {
        contactFilter: e.target.value,
        page: 1
      });
    }

    handlePageChange(newPage) {
      (0, _object.set)(this, 'page', newPage);
    }

    updateSortBy(property) {
      let direction = 'asc';

      if (this.sortByProperty === property) {
        direction = this.sortByDirection === 'asc' ? 'desc' : 'asc';
      }

      this.sortByProperty = property;
      this.sortByDirection = direction;
      this.sortThoseItems();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "contactsCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "media", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "sortedItems", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "filteredItems", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "filteredItems"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "pagedItems", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "pagedItems"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleFilterByGroup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleFilterByGroup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleFilterGroupSelectChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleFilterGroupSelectChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleContactFilterInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleContactFilterInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handlePageChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handlePageChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateSortBy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateSortBy"), _class.prototype)), _class));
  _exports.default = TasksByContactController;
});