define("donor-elf-web/templates/coaching/individuals/individual/edit-goal", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "s0auJ2CH",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,0],[14,0,\"mb-3 border-bottom\"],[12],[1,\"\\n    \"],[10,\"h3\"],[14,0,\"mb-1\"],[12],[1,[33,1,[\"field\",\"l\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[6,[39,2],null,[[\"changeset\",\"saveTask\"],[[30,0,[\"changeset\"]],[30,0,[\"save\"]]]],[[\"default\"],[[[[41,[30,0,[\"isOverallGoal\"]],[[[1,\"      \"],[10,0],[15,0,[29,[\"form-group \",[52,[30,0,[\"changeset\",\"isValid\"]],\"\",\"has-errors\"]]]],[12],[1,\"\\n        \"],[10,\"label\"],[12],[1,\"Total Goal\"],[13],[1,\"\\n        \"],[10,\"input\"],[15,2,[30,0,[\"changeset\",\"overallGoal\"]]],[14,0,\"form-control\"],[15,\"oninput\",[28,[37,4],[[30,0],\"handleOverallGoalChanged\"],[[\"value\"],[\"target.value\"]]]],[14,4,\"text\"],[12],[13],[1,\"\\n\"],[41,[30,0,[\"changeset\",\"error\",\"overallGoal\",\"validation\"]],[[[1,\"          \"],[10,0],[14,0,\"has-errors-message\"],[12],[1,[30,0,[\"changeset\",\"error\",\"overallGoal\",\"validation\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"mb-3 text-muted font-weight-light\"],[12],[1,\"\\n        Goal for each Report:\\n\"],[41,[30,0,[\"changeset\",\"isValid\"]],[[[1,\"          \"],[1,[28,[35,5],[[30,0,[\"changeset\",\"intervalGoal\"]]],null]],[1,\"\\n\"]],[]],[[[1,\"          ---\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"isIntervalGoal\"]],[[[1,\"      \"],[1,[28,[35,6],null,[[\"changeset\",\"property\",\"label\",\"focusOnInsert\",\"showValidationErrors\"],[[30,0,[\"changeset\"]],\"intervalGoal\",\"Goal for each Report\",true,true]]]],[1,\"\\n\"]],[]],null]],[1]]]]],[41,[30,0,[\"error\"]],[[[1,\"    \"],[1,[28,[35,7],null,[[\"error\",\"class\"],[[30,0,[\"error\"]],\"mt-3\"]]]],[1,\"\\n\"]],[]],null]],[]]]]]],[\"showValidationErrors\"],false,[\"de-form-card\",\"model\",\"save-form\",\"if\",\"action\",\"format-amount\",\"form-group\",\"generic-error\"]]",
    "moduleName": "donor-elf-web/templates/coaching/individuals/individual/edit-goal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});