define("donor-elf-web/templates/intervarsity-first-import", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "CckMGnvt",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[10,2],[12],[1,\"\\n    You successfully activated your DonorElf account!\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Now all your gift and donor information is being imported. Once that is\\n    done you can start using DonorElf.\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    You can stay on this page and it will update automatically when the import\\n    is complete, or you can close the browser tab and we'll send you an email\\n    when the import is done.\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    The import should only take about 10 minutes. If it is taking longer than\\n    that and you are a new staff, it may be because InterVarsity has not set up\\n    your donation account (GAU) yet.  Please wait until it is set up and then\\n    try logging in to DonorElf again.\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    If you know your donation account is set up and you still haven't received\\n    an import confirmation email please let us know at\\n    \"],[10,3],[14,6,\"mailto:support@donorelf.com\"],[12],[1,\"\\n      support@donorelf.com\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@interval\",\"@onTick\"],[10000,[30,0,[\"checkForImportComplete\"]]]],null]],[],false,[\"form-card\",\"run-interval\"]]",
    "moduleName": "donor-elf-web/templates/intervarsity-first-import.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});