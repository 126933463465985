define("donor-elf-web/lib/models/journal-report-intervarsity", ["exports", "@ember/utils", "donor-elf-web/lib/date-utils", "donor-elf-web/lib/models/journal-report-default", "donor-elf-web/lib/pledge-utils"], function (_exports, _utils, _dateUtils, _journalReportDefault, _pledgeUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const {
    currency,
    moment
  } = window; // todo: move this to JournalReportDefault when that is converted to typescript
  // ****

  class JournalReportIntervarsity {
    constructor({
      journalInfo,
      journalReport,
      journalContactInfos,
      formatDateService
    }) {
      _defineProperty(this, "intervalData", void 0);

      _defineProperty(this, "currentAskStatus", void 0);

      _defineProperty(this, "goals", void 0);

      _defineProperty(this, "decisionTypeAmounts", void 0);

      _defineProperty(this, "totalRecommendations", void 0);

      _defineProperty(this, "totalCompletedTaskEngagements", void 0);

      _defineProperty(this, "totalCompletedTaskAsks", void 0);

      _defineProperty(this, "cumulativePoints", void 0);

      let {
        intervalDates
      } = journalReport;
      let data = journalReport.data;
      this.goals = journalInfo.goals;
      let decisionItems = this.decisionItems(journalContactInfos);
      let dataByIntervalDate = {};
      let contactIdHasDecision = {};
      let decisionTotalAmount = 0;
      let decisionCumulativeCount = 0;
      this.totalCompletedTaskEngagements = 0;
      this.totalCompletedTaskAsks = 0;
      let completedTaskRecommendationAskCumulativeCount = 0;
      let completedTaskFollowupCumulativeCount = 0;
      let cumulativePoints = 0;

      for (let dateInfo of intervalDates || []) {
        var _data$engagement_task, _data$ask_tasks$date, _data$recommendation_, _data$followup_tasks$, _data$recommendations, _data$thank_tasks$dat;

        let date = dateInfo.start;
        let dateEnd = dateInfo.end;

        let dateRangeFormatted = _journalReportDefault.default.getDateRangeFormatted({
          formatDateService,
          ...dateInfo
        });

        let item = {
          date,
          firstTimeDecisionCount: 0,
          decisionCumulativeCount: decisionCumulativeCount
        };
        item.dateFormatted = dateRangeFormatted;
        let completedTaskEngagementCount = ((_data$engagement_task = data.engagement_tasks[date]) === null || _data$engagement_task === void 0 ? void 0 : _data$engagement_task.length) || 0;
        this.totalCompletedTaskEngagements += completedTaskEngagementCount;
        let completedTaskAskCount = ((_data$ask_tasks$date = data.ask_tasks[date]) === null || _data$ask_tasks$date === void 0 ? void 0 : _data$ask_tasks$date.length) || 0;
        this.totalCompletedTaskAsks += completedTaskAskCount;
        let completedTaskRecommendationAskCount = ((_data$recommendation_ = data.recommendation_ask_tasks[date]) === null || _data$recommendation_ === void 0 ? void 0 : _data$recommendation_.length) || 0;
        completedTaskRecommendationAskCumulativeCount += completedTaskRecommendationAskCount;
        let completedTaskFollowupCount = ((_data$followup_tasks$ = data.followup_tasks[date]) === null || _data$followup_tasks$ === void 0 ? void 0 : _data$followup_tasks$.length) || 0;
        completedTaskFollowupCumulativeCount += completedTaskFollowupCount;
        item.completedTaskCounts = {
          engagement: completedTaskEngagementCount,
          engagementTaskIds: data.engagement_tasks[date] || [],
          engagementCumulative: this.totalCompletedTaskEngagements,
          ask: completedTaskAskCount,
          askTaskIds: data.ask_tasks[date] || [],
          askCumulative: this.totalCompletedTaskAsks,
          recommendationAsk: completedTaskRecommendationAskCount,
          recommendationAskTaskIds: data.recommendation_ask_tasks[date] || [],
          recommendationAskCumulative: completedTaskRecommendationAskCumulativeCount,
          followup: completedTaskFollowupCount,
          followupTaskIds: data.followup_tasks[date] || [],
          followupCumulative: completedTaskFollowupCumulativeCount
        };
        item.openAsks = data.ask_stages.filter(x => (0, _utils.isPresent)(x.open) && x.open <= dateEnd && ((0, _utils.isEmpty)(x.decision) || x.decision > dateEnd)).map(x => {
          return {
            contactId: x.contact_id
          };
        });
        item.decisionInfos = [];

        for (let decisionItem of decisionItems) {
          if (decisionItem.date >= date && decisionItem.date <= dateEnd) {
            item.decisionInfos.push(decisionItem.decisionInfo);

            if (!contactIdHasDecision[decisionItem.decisionInfo.journalDecision.journalContactId]) {
              item.firstTimeDecisionCount += 1;
              item.decisionCumulativeCount += 1;
              decisionCumulativeCount += 1;
              contactIdHasDecision[decisionItem.decisionInfo.journalDecision.journalContactId] = true;
            }
          }
        }

        item.goalsData = {};
        this.goals.forEach(goal => {
          let decisionNewAmount = 0;
          let goalDecisionInfos = item.decisionInfos.filter(x => {
            var _x$journalGoal;

            return ((_x$journalGoal = x.journalGoal) === null || _x$journalGoal === void 0 ? void 0 : _x$journalGoal.id) === goal.id;
          });
          goalDecisionInfos.forEach(decisionInfo => {
            decisionNewAmount = currency(decisionNewAmount).add(decisionInfo.journalDecision.amount).value;
          });
          decisionTotalAmount = currency(decisionTotalAmount).add(decisionNewAmount).value;
          let decisionTotalPercent = Math.round(decisionTotalAmount * 100 / goal.amount);
          item.goalsData[goal.id] = {
            goalId: goal.id,
            decisions: goalDecisionInfos,
            decisionNewAmount,
            decisionTotalAmount,
            decisionTotalPercent
          };
        });
        let points = {
          engages: 0,
          asks: 0,
          recommendationAsks: 0,
          recommendations: 0,
          followUps: 0,
          thankYous: 0,
          total: 0
        };
        points.engages = item.completedTaskCounts.engagement * 1;
        points.asks = item.completedTaskCounts.ask * 10;
        points.recommendationAsks = item.completedTaskCounts.recommendationAsk * 2;
        points.recommendations = (((_data$recommendations = data.recommendations[date]) === null || _data$recommendations === void 0 ? void 0 : _data$recommendations.length) || 0) * 2;
        points.followUps = item.completedTaskCounts.followup * 2;
        points.thankYous = (((_data$thank_tasks$dat = data.thank_tasks[date]) === null || _data$thank_tasks$dat === void 0 ? void 0 : _data$thank_tasks$dat.length) || 0) * 2;
        points.total = points.engages + points.asks + points.recommendationAsks + points.recommendations + points.followUps + points.thankYous;
        cumulativePoints += points.total;
        item.points = points;
        dataByIntervalDate[date] = item;
      }

      this.intervalData = journalReport.intervalDates.map(x => dataByIntervalDate[x.start]);
      this.currentAskStatus = {
        closed: 0,
        open: 0,
        closedAndOpen: 0,
        pending: 0,
        none: 0,
        pendingAndNone: 0
      };
      data.ask_stages.forEach(x => {
        if (x.decision) {
          this.currentAskStatus.closed += 1;
        } else if (x.open) {
          this.currentAskStatus.open += 1;
        } else if (x.pending) {
          this.currentAskStatus.pending += 1;
        }
      });
      this.currentAskStatus.closedAndOpen = this.currentAskStatus.closed + this.currentAskStatus.open;
      this.currentAskStatus.none = journalContactInfos.filter(x => !x.journalContact.finished && !(x.decisionMade && x.journalDecisions[0].yesNo) && x.tasksByStageId[x.journalInfo.askStage.id].length === 0).length;
      this.currentAskStatus.pendingAndNone = this.currentAskStatus.pending + this.currentAskStatus.none;
      let contactIdsInThisJournal = {};

      for (let journalContactInfo of journalContactInfos) {
        contactIdsInThisJournal[journalContactInfo.contactId] = true;
      }

      let recommendedJournalContactIds = {};

      for (let journalContactInfo of journalContactInfos) {
        var _journalContactInfo$r;

        if ((_journalContactInfo$r = journalContactInfo.recommendedByContactIds) !== null && _journalContactInfo$r !== void 0 && _journalContactInfo$r.some(contactId => contactIdsInThisJournal[contactId])) {
          recommendedJournalContactIds[journalContactInfo.journalContact.id] = true;
        }
      }

      let totalDecisionAmountFromRecommendations = 0;
      let totalDecisionCountFromRecommendations = 0;
      this.decisionTypeAmounts = {
        recurring: 0,
        recurringPercent: 0,
        oneTime: 0,
        oneTimePercent: 0
      };

      for (let item of decisionItems) {
        if (item.decisionInfo.journalDecision.yesNo) {
          if (decisionTypeIsRecurring(item.decisionInfo.pledge)) {
            this.decisionTypeAmounts.recurring = currency(this.decisionTypeAmounts.recurring).add(item.decisionInfo.journalDecision.amount).value;
          } else {
            this.decisionTypeAmounts.oneTime = currency(this.decisionTypeAmounts.oneTime).add(item.decisionInfo.journalDecision.amount).value;
          }

          if (recommendedJournalContactIds[item.decisionInfo.journalDecision.journalContactId]) {
            totalDecisionCountFromRecommendations += 1;
            totalDecisionAmountFromRecommendations = currency(totalDecisionAmountFromRecommendations).add(item.decisionInfo.journalDecision.amount).value;
          }
        }
      }

      let totalDecisionAmount = this.decisionTypeAmounts.recurring + this.decisionTypeAmounts.oneTime;

      if (totalDecisionAmount > 0) {
        var _this$goals$;

        let goalAmount = ((_this$goals$ = this.goals[0]) === null || _this$goals$ === void 0 ? void 0 : _this$goals$.amount) || 1;
        this.decisionTypeAmounts.recurringPercent = Math.round(this.decisionTypeAmounts.recurring * 100 / goalAmount);
        this.decisionTypeAmounts.oneTimePercent = Math.round(this.decisionTypeAmounts.oneTime * 100 / goalAmount);
      }

      this.totalRecommendations = {
        asksMade: completedTaskRecommendationAskCumulativeCount,
        recommendationsCollected: data.total_recommendations_collected,
        totalDecisionCountFromRecommendations,
        totalDecisionAmountFromRecommendations
      };
      this.cumulativePoints = cumulativePoints;
    }
    /*  [{
          date: '2020-10-29',
          dateFormatted: '10/29/21',
          dateRangeFormatted: '10/29 - 11/03',
          completedTaskCounts: {
            engagement: 1,
            engagementCumulative: 2,
            ask: 1,
            askCumulative: 2
          }
          openAsks: [{ contactId: 123 }],
          decisionInfos: [JournalDecisionInfo],
          decisionCumulativeCount: 1,
          firstTimeDecisionCount: 1, number of first time decsisions by contact (excludes multiple decisions from contact)
          goalsData: {
            goalId: {
              goalId: string,
              decisions: [JournalDecisionInfo],
              decisionNewAmount: number,
              decisionTotalAmount: number
              decisionTotalPercent: number
            }
          },
          points: JournalReportIntervarsityPoints
        }]
    */


    // [{ date: '2021-03-03', decisionInfo: JournalDecisionInfo }]
    decisionItems(journalContactInfos) {
      let data = [];
      journalContactInfos.forEach(info => {
        info.journalDecisionInfos.forEach(decisionInfo => {
          data.push({
            date: _dateUtils.default.serializeToDateOnly(decisionInfo.journalDecision.decisionDate),
            decisionInfo
          });
        });
      });
      return data;
    }

  }

  _exports.default = JournalReportIntervarsity;

  function decisionTypeIsRecurring(pledge) {
    if (pledge.frequency === _pledgeUtils.pledgeOneTimeFrequency) {
      return false;
    }

    if (pledge.endDate) {
      return moment(pledge.startDate).add('year', 1).toDate() <= pledge.endDate;
    } else {
      return true;
    }
  }
});