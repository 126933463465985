define("donor-elf-web/components/contact/bulk-actions/no-contacts-error", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Contact::BulkActions::Header>
    Bulk Edit
  </Contact::BulkActions::Header>
  <div class='p-3'>
    There are no contact records showing to perform a bulk edit on. Adjust the filter to
    show some contact records then you can perform a bulk edit on them.
  </div>
  <Modal::Footer>
    <button
      {{on 'click' @onClose}}
      class='pl-0 btn btn-xs btn-link'
      type='button'
    >
      Close
    </button>
  </Modal::Footer>
  
  */
  {
    "id": "Y2q7jXEM",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  Bulk Edit\\n\"]],[]]]]],[1,\"\\n\"],[10,0],[14,0,\"p-3\"],[12],[1,\"\\n  There are no contact records showing to perform a bulk edit on. Adjust the filter to\\n  show some contact records then you can perform a bulk edit on them.\\n\"],[13],[1,\"\\n\"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n  \"],[11,\"button\"],[24,0,\"pl-0 btn btn-xs btn-link\"],[24,4,\"button\"],[4,[38,2],[\"click\",[30,1]],null],[12],[1,\"\\n    Close\\n  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@onClose\"],false,[\"contact/bulk-actions/header\",\"modal/footer\",\"on\"]]",
    "moduleName": "donor-elf-web/components/contact/bulk-actions/no-contacts-error.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});