define("donor-elf-web/lib/filter-utils", ["exports", "@ember/object", "@ember/utils"], function (_exports, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    getFilteredResults({
      filters,
      filterSettings,
      items,
      itemIdProp
    }) {
      let results = items;
      let mapValFnByPropName = this.getMapValFnByPropName({
        filterSettings
      });
      filters.forEach(andFilters => {
        let andResults = [];
        andFilters.forEach(criteria => {
          let resultIds = {};
          let opFunction = this.operationFunctions[criteria.op];
          results.forEach(item => {
            let mappedVal = mapValFnByPropName[criteria.prop](criteria.val);

            if (opFunction({
              item,
              prop: criteria.prop,
              val: mappedVal
            })) {
              resultIds[(0, _object.get)(item, itemIdProp)] = true;
            }
          });
          andResults.push(resultIds);
        });

        if (andResults.length === 0) {// do nothing
        } else if (andResults.length === 1) {
          let resultIds = andResults[0];
          results = results.filter(item => {
            return resultIds[(0, _object.get)(item, itemIdProp)];
          });
        } else {
          let resultIds = andResults[0];

          for (let i = 1; i < andResults.length; i++) {
            let nextResultIds = andResults[i];
            Object.keys(resultIds).forEach(resultId => {
              if (!nextResultIds[resultId]) {
                delete resultIds[resultId];
              }
            });
          }

          results = results.filter(item => {
            return resultIds[(0, _object.get)(item, itemIdProp)];
          });
        }
      });
      return results;
    },

    getMapValFnByPropName({
      filterSettings
    }) {
      let mapValFnByPropName = {};
      filterSettings.forEach(setting => {
        mapValFnByPropName[setting.prop] = setting.mapValFn || this.DEFAULT_MAP_FN;
      });
      return mapValFnByPropName;
    },

    /*
      filters = [{
        label: 
        val: 
        operation: // not hooked up yet. optional. default is '==='. Valid values are the keys from operationLabels below
      }]
    */
    serializeTransactionCustomPropertyFilters(filters) {
      return filters.filter(x => {
        return (0, _utils.isPresent)(x.val);
      }).map(x => {
        return `${x.label}:::${x.val}`;
      }).join('|||');
    },

    /*
      returns [{ label:, val: }]
    */
    deserializeTransactionCustomPropertyFilters(serialized) {
      return (serialized || '').split('|||').map(x => {
        let split = x.split(':::');
        return {
          label: split[0],
          val: split[1]
        };
      });
    },

    DEFAULT_MAP_FN: val => {
      return val;
    },
    operationFunctions: {
      '===': function ({
        item,
        prop,
        val
      }) {
        return (0, _object.get)(item, prop) === val;
      },
      '>': function ({
        item,
        prop,
        val
      }) {
        return (0, _object.get)(item, prop) > val;
      },
      '>=': function ({
        item,
        prop,
        val
      }) {
        return (0, _object.get)(item, prop) >= val;
      },
      '<': function ({
        item,
        prop,
        val
      }) {
        let itemVal = (0, _object.get)(item, prop);
        return itemVal != null && itemVal < val;
      },
      '<=': function ({
        item,
        prop,
        val
      }) {
        let itemVal = (0, _object.get)(item, prop);
        return itemVal != null && itemVal <= val;
      },
      // string contains
      '=~': function ({
        item,
        prop,
        val
      }) {
        return (0, _object.get)(item, prop).indexOf(val) >= 0;
      },
      // array contains
      '[]': function ({
        item,
        prop,
        val
      }) {
        return ((0, _object.get)(item, prop) || []).indexOf(val) >= 0;
      }
    },
    operationLabels: {
      '===': 'is equal to',
      '>': 'is greater than',
      '>=': 'is greater or equal to',
      '<': 'is less than',
      '<=': 'is less or equal to',
      '=~': 'contains',
      '[]': 'is'
    }
  };
  _exports.default = _default;
});