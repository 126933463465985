define("donor-elf-web/components/contact/bulk-actions/edit-property/new", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/service"], function (_exports, _component, _templateFactory, _component2, _object, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Contact::BulkActions::Header @contactIds={{@contactIds}}>
    Add New Property
  </Contact::BulkActions::Header>
  {{#if this.saving}}
    <Modal::HeaderSecond>
      {{this.tempCustomProperty.l}}
    </Modal::HeaderSecond>
    <div class='py-2 px-3'>
      {{!-- don't show the values for a list because it needs to know the settings for the list which we don't have yet --}}
      {{#if (not-eq this.tempCustomProperty.t 'l')}}
        <div class='mb-3'>
          <Contact::Details::Properties::CustomProperty::Show
            @customProperty={{this.tempCustomProperty}}
            class='border-bottom'
          />
        </div>
      {{/if}}
      {{#if this.saveError}}
        <GenericError
          @useLeftBorderCard={{true}}
          class='my-3'
        />
      {{else}}
        <Alert::Info class='my-3'>
          Adding the property to the {{format-number @contactIds.length}}
          {{pluralize @contactIds.length 'contact' without-count=true}}.
          This will take a few seconds...
        </Alert::Info>
        {{#if this.waitingOnBulkActionToComplete}}
          <BulkAction::PollForStatusChange
            @bulkAction={{this.bulkAction}}
            @onStatusChange={{this.handleBulkActionStatusChange}}
          />
        {{/if}}
      {{/if}}
    </div>
  {{/if}}
  <Contact::CustomProperty::Add::New
    @onSave={{this.handleOnSave}}
    @onCancel={{@onClose}}
    class='{{if this.saving 'd-none'}}'
  />
  */
  {
    "id": "uYYny+HO",
    "block": "[[[8,[39,0],null,[[\"@contactIds\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n  Add New Property\\n\"]],[]]]]],[1,\"\\n\"],[41,[30,0,[\"saving\"]],[[[1,\"  \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n    \"],[1,[30,0,[\"tempCustomProperty\",\"l\"]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[10,0],[14,0,\"py-2 px-3\"],[12],[1,\"\\n\"],[41,[28,[37,3],[[30,0,[\"tempCustomProperty\",\"t\"]],\"l\"],null],[[[1,\"      \"],[10,0],[14,0,\"mb-3\"],[12],[1,\"\\n        \"],[8,[39,4],[[24,0,\"border-bottom\"]],[[\"@customProperty\"],[[30,0,[\"tempCustomProperty\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"saveError\"]],[[[1,\"      \"],[8,[39,5],[[24,0,\"my-3\"]],[[\"@useLeftBorderCard\"],[true]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,6],[[24,0,\"my-3\"]],null,[[\"default\"],[[[[1,\"\\n        Adding the property to the \"],[1,[28,[35,7],[[30,1,[\"length\"]]],null]],[1,\"\\n        \"],[1,[28,[35,8],[[30,1,[\"length\"]],\"contact\"],[[\"without-count\"],[true]]]],[1,\".\\n        This will take a few seconds...\\n      \"]],[]]]]],[1,\"\\n\"],[41,[30,0,[\"waitingOnBulkActionToComplete\"]],[[[1,\"        \"],[8,[39,9],null,[[\"@bulkAction\",\"@onStatusChange\"],[[30,0,[\"bulkAction\"]],[30,0,[\"handleBulkActionStatusChange\"]]]],null],[1,\"\\n\"]],[]],null]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null],[8,[39,10],[[16,0,[29,[[52,[30,0,[\"saving\"]],\"d-none\"]]]]],[[\"@onSave\",\"@onCancel\"],[[30,0,[\"handleOnSave\"]],[30,2]]],null]],[\"@contactIds\",\"@onClose\"],false,[\"contact/bulk-actions/header\",\"if\",\"modal/header-second\",\"not-eq\",\"contact/details/properties/custom-property/show\",\"generic-error\",\"alert/info\",\"format-number\",\"pluralize\",\"bulk-action/poll-for-status-change\",\"contact/custom-property/add/new\"]]",
    "moduleName": "donor-elf-web/components/contact/bulk-actions/edit-property/new.hbs",
    "isStrictMode": false
  });

  let ContactBulkActionsEditPropertyAddNewComponent = (_class = class ContactBulkActionsEditPropertyAddNewComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "contactsCache", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _defineProperty(this, "bulkAction", void 0);

      _initializerDefineProperty(this, "waitingOnBulkActionToComplete", _descriptor3, this);

      _initializerDefineProperty(this, "saving", _descriptor4, this);

      _initializerDefineProperty(this, "saveError", _descriptor5, this);

      _initializerDefineProperty(this, "tempCustomProperty", _descriptor6, this);
    }

    async handleOnSave(saveArgs) {
      this.saving = true;
      this.tempCustomProperty = {
        l: saveArgs.l,
        t: saveArgs.t,
        v: saveArgs.v
      };
      this.bulkAction = this.store.createRecord('bulk-action');
      this.bulkAction.actionType = 'set_custom_property';
      this.bulkAction.custom = {
        contact_ids: this.args.contactIds,
        new_property: true,
        l: saveArgs.l,
        v: saveArgs.v,
        t: saveArgs.t,
        o: saveArgs.o,
        s: saveArgs.s
      };
      await this.bulkAction.save();
      this.waitingOnBulkActionToComplete = true;
    }

    async handleBulkActionStatusChange(bulkAction) {
      if (bulkAction.status === 'completed') {
        try {
          let updatedCustomPropertyInfo = bulkAction.response.custom_property_info;

          if (updatedCustomPropertyInfo) {
            this.contactsCache.updateCustomPropertyInfo(updatedCustomPropertyInfo);
          }

          await bulkAction.destroyRecord();

          if (this.args.contactIds.length < 250) {
            await this.contactsCache.reloadSpecificContacts(this.args.contactIds);
          } else {
            await this.contactsCache.loadContacts({
              forceLoad: true
            });
          }

          this.args.onContactsChanged({
            contactIds: this.args.contactIds,
            bulkActionType: 'set_custom_property',
            newPropertyAdded: true
          });
          this.saving = false;
          this.args.onClose();
        } catch (error) {
          this.saveError = true;
        }
      } else if (bulkAction.status === 'error') {
        this.saveError = true;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "contactsCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "waitingOnBulkActionToComplete", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "saving", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "saveError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "tempCustomProperty", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handleOnSave", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleOnSave"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleBulkActionStatusChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleBulkActionStatusChange"), _class.prototype)), _class);
  _exports.default = ContactBulkActionsEditPropertyAddNewComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ContactBulkActionsEditPropertyAddNewComponent);
});