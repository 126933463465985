define("donor-elf-web/routes/admin/funds/fund/index", ["exports", "@ember/routing/route", "@ember/object", "@ember/service"], function (_exports, _route, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    fundsService: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),
    storeCache: (0, _service.inject)(),

    beforeModel() {
      return (0, _object.get)(this, 'fundsService').confirmValidFundToken();
    },

    model() {
      let fundModel = this.modelFor('admin.funds.fund');
      let fundId = (0, _object.get)(fundModel, 'record.id');
      (0, _object.set)(this, 'navbarSettings.title', (0, _object.get)(fundModel, 'record.name'));
      return (0, _object.get)(this, 'storeCache').findAll('admin/fund-group').then(fundGroups => {
        let associatedFundGroups = fundGroups.filter(fundGroup => {
          return (0, _object.get)(fundGroup, `fundIdKeys.${fundId}`);
        });
        return Object.assign({
          fundGroups: associatedFundGroups
        }, fundModel);
      });
    }

  });

  _exports.default = _default;
});