define("donor-elf-web/modifiers/select-on-focus", ["exports", "@ember/utils", "ember-modifier"], function (_exports, _utils, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // selects all the text when an input textbox receives focus
  // {{select-on-focus}}
  var _default = (0, _emberModifier.modifier)(function selectOnFocus(element) {
    let handler = generateOnFocusHandler();
    element.addEventListener('focus', handler);
    return () => {
      element.removeEventListener('focus', handler);
    };
  });

  _exports.default = _default;

  function generateOnFocusHandler() {
    return e => {
      if ((0, _utils.isPresent)(e.target.value)) {
        e.target.select();
      }
    };
  }
});