define("donor-elf-web/controllers/funds/fund/ytd-next-year-projection", ["exports", "@ember/controller", "@ember/service", "@ember/object", "donor-elf-web/lib/pledge-utils", "donor-elf-web/lib/array-utils", "@glimmer/tracking", "ember-cached-decorator-polyfill"], function (_exports, _controller, _service, _object, _pledgeUtils, _arrayUtils, _tracking, _emberCachedDecoratorPolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    currency
  } = window;
  let YtdNextYearProjectionController = (_class = class YtdNextYearProjectionController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "addSomethingModal", _descriptor, this);

      _defineProperty(this, "model", void 0);

      _defineProperty(this, "queryParams", [{
        activeTab: 'tab'
      }]);

      _initializerDefineProperty(this, "activeTab", _descriptor2, this);

      _defineProperty(this, "savePledgeSubsciptionToken", void 0);

      _defineProperty(this, "endedPledgeInfosSortBy", ['annual_amount:desc']);
    }

    get goalAmount() {
      return this.model.record.goalAmount;
    }

    get startAt() {
      return this.model.record.startAt;
    }

    get endAt() {
      return this.model.record.endAt;
    }

    get tabs() {
      return [{
        label: 'Expecting Next Year',
        active: this.activeTab === 'Expecting Next Year',
        onClick: () => {
          this.activeTab = 'Expecting Next Year';
        }
      }, {
        label: 'Ending This Year',
        active: this.activeTab === 'Ending This Year',
        onClick: () => {
          this.activeTab = 'Ending This Year';
        }
      }, {
        label: 'Ended Last Year',
        active: this.activeTab === 'Ended Last Year',
        onClick: () => {
          this.activeTab = 'Ended Last Year';
        }
      }];
    }

    // excluding ended pledges from contacts that have pledges for next year or this year
    get endedLastYearPledgeInfos() {
      let contactIdsForNextYearAndEndingThisYear = {};
      this.nextYearPledgeInfos.forEach(x => {
        if (x.pledge.contactId) {
          contactIdsForNextYearAndEndingThisYear[x.pledge.contactId] = true;
        }
      });
      this.endingThisYearPledgeInfos.forEach(x => {
        if (x.pledge.contactId) {
          contactIdsForNextYearAndEndingThisYear[x.pledge.contactId] = true;
        }
      });
      return (0, _arrayUtils.sortByProperties)(this.model.record.newPledgeData.ended_last_year.map(x => {
        return {
          pledge: this.model.pledgesById[x.pledge_id],
          ...x
        };
      }).filter(x => {
        return contactIdsForNextYearAndEndingThisYear[x.pledge.contactId] !== true;
      }), this.endedPledgeInfosSortBy);
    }

    get endedLastYearAmount() {
      return this.endedLastYearPledgeInfos.reduce((sum, item) => currency(sum).add(item.annual_amount).value, 0);
    }

    get endedLastYearPercent() {
      return Math.round(this.endedLastYearAmount * 100 / this.goalAmount);
    }

    get endedLastYearDate() {
      return this.model.record.newPledgeData.last_year_end_date;
    }

    get endingThisYearPledgeInfos() {
      return (0, _arrayUtils.sortByProperties)(this.model.record.newPledgeData.ending_this_year.map(x => {
        return {
          pledge: this.model.pledgesById[x.pledge_id],
          ...x
        };
      }), this.endedPledgeInfosSortBy);
    }

    get endingThisYearAmount() {
      return this.endingThisYearPledgeInfos.reduce((sum, item) => currency(sum).add(item.annual_amount).value, 0);
    }

    get endingThisYearPercent() {
      return Math.round(this.endingThisYearAmount * 100 / this.goalAmount);
    }

    get endingThisYearDate() {
      return this.model.record.newPledgeData.this_year_end_date;
    }

    get nextYearPledgeInfos() {
      return (0, _arrayUtils.sortByProperties)(this.model.record.newPledgeData.next_year.map(x => {
        return {
          pledge: this.model.pledgesById[x.pledge_id],
          ...x
        };
      }), ['expected_amount:desc']);
    }

    get nextYearRecurringPledgeInfos() {
      return this.nextYearPledgeInfos.filter(x => x.pledge.frequency !== _pledgeUtils.pledgeOneTimeFrequency);
    }

    get nextYearRecurringAmount() {
      return this.nextYearRecurringPledgeInfos.reduce((sum, item) => currency(sum).add(item.expected_amount).value, 0);
    }

    get nextYearOneTimePledgeInfos() {
      return this.nextYearPledgeInfos.filter(x => x.pledge.frequency === _pledgeUtils.pledgeOneTimeFrequency);
    }

    get nextYearOneTimeAmount() {
      return this.nextYearOneTimePledgeInfos.reduce((sum, item) => currency(sum).add(item.expected_amount).value, 0);
    }

    get nextYearExpectedAmount() {
      return currency(this.nextYearRecurringAmount).add(this.nextYearOneTimeAmount).value;
    }

    get nextYearExpectedPercent() {
      return Math.round(this.nextYearExpectedAmount * 100 / this.goalAmount);
    }

    templateDidInsert() {
      this.savePledgeSubsciptionToken = this.addSomethingModal.subscribeToPledgeSaved(this.handlePledgeSaved);
    }

    templateWillDestroy() {
      this.activeTab = 'Expecting Next Year';

      if (this.savePledgeSubsciptionToken) {
        this.addSomethingModal.unsubscribeWithToken(this.savePledgeSubsciptionToken);
      }
    }

    async handlePledgeSaved(_msg, _data) {
      this.send('refreshYtdNextYearProjectionRoute');
    }

    async handleGoalAmountChanged() {
      let changeset = this.model.changeset;

      if (changeset.validate()) {
        let goalAmount = Number((0, _object.get)(changeset, 'goalAmount'));
        let record = this.model.record;

        if (goalAmount != record.goalAmount) {
          record.goalAmount = goalAmount;
          await record.save();
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "addSomethingModal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "activeTab", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'Expecting Next Year';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "endedLastYearPledgeInfos", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "endedLastYearPledgeInfos"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "endingThisYearPledgeInfos", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "endingThisYearPledgeInfos"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "nextYearPledgeInfos", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "nextYearPledgeInfos"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handlePledgeSaved", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handlePledgeSaved"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleGoalAmountChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleGoalAmountChanged"), _class.prototype)), _class);
  _exports.default = YtdNextYearProjectionController;
});