define("donor-elf-web/validations/applied-property-filter", ["exports", "ember-changeset-validations/validators", "donor-elf-web/lib/string-utils", "donor-elf-web/lib/filter-utils-new", "donor-elf-web/lib/object-utils", "@ember/utils"], function (_exports, _validators, _stringUtils, _filterUtilsNew, _objectUtils, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateFilter;

  function validateFilter(availablePropertyFilters) {
    let getFilterInfo = (content, changes) => {
      let propertyName = (0, _objectUtils.assignSingleProperty)(content, changes, 'propertyName');
      return availablePropertyFilters.find(x => x.propertyName === propertyName);
    };

    let validation = {
      propertyName: (0, _validators.validatePresence)({
        presence: true,
        message: "Can't be blank"
      }),
      operation: (_key, newValue, _oldValue, changes, content) => {
        let filterInfo = getFilterInfo(content, changes);

        if ((filterInfo === null || filterInfo === void 0 ? void 0 : filterInfo.propertyType) === 'no-operation') {
          if ((0, _utils.isPresent)(newValue)) {
            'No operation property types should not have an operation';
          }
        } else {
          if ((0, _utils.isEmpty)(newValue)) {
            return "Can't be blank";
          }
        }

        return true;
      },
      val: (_key, newValue, _oldValue, changes, content) => {
        let filterInfo = getFilterInfo(content, changes);
        let operation = (0, _objectUtils.assignSingleProperty)(content, changes, 'operation');

        if (filterInfo && operation) {
          if (filterInfo.propertyType !== 'giftHistory' && !(filterInfo.propertyType === 'date' && operation === '><')) {
            if (_filterUtilsNew.OperationsWithoutVal[operation]) {
              if ((0, _utils.isPresent)(newValue)) {
                return "Supposed to be blank";
              }
            } else {
              if ((0, _utils.isEmpty)(newValue)) {
                if ((filterInfo === null || filterInfo === void 0 ? void 0 : filterInfo.propertyType) === 'list') {
                  return 'Select one or more options';
                } else {
                  return "Can't be blank";
                }
              }

              if ((filterInfo === null || filterInfo === void 0 ? void 0 : filterInfo.propertyType) === 'number') {
                if (!(0, _stringUtils.isNumber)(newValue)) {
                  return 'Must be a number (no comma or $)';
                }
              }
            }
          }
        }

        return true;
      },
      giftHistoryVal: (_key, newValue, _oldValue, changes, content) => {
        let operation = (0, _objectUtils.assignSingleProperty)(content, changes, 'operation');

        if (operation !== 'absent') {
          var _getFilterInfo;

          if (((_getFilterInfo = getFilterInfo(content, changes)) === null || _getFilterInfo === void 0 ? void 0 : _getFilterInfo.propertyType) === 'giftHistory') {
            if ((0, _utils.isEmpty)(newValue)) {
              return "Can't be blank";
            }

            if (!(0, _stringUtils.isNumber)(newValue)) {
              return 'Must be a number (no comma or $)';
            }
          }
        }

        return true;
      },
      giftHistoryValType: (_key, newValue, _oldValue, changes, content) => {
        let operation = (0, _objectUtils.assignSingleProperty)(content, changes, 'operation');

        if (operation !== 'absent') {
          var _getFilterInfo2;

          if (((_getFilterInfo2 = getFilterInfo(content, changes)) === null || _getFilterInfo2 === void 0 ? void 0 : _getFilterInfo2.propertyType) === 'giftHistory') {
            if ((0, _utils.isEmpty)(newValue)) {
              return "Can't be blank";
            }
          }
        }

        return true;
      },
      giftHistoryFrom: (_key, newValue, _oldValue, changes, content) => {
        var _getFilterInfo3;

        if (((_getFilterInfo3 = getFilterInfo(content, changes)) === null || _getFilterInfo3 === void 0 ? void 0 : _getFilterInfo3.propertyType) === 'giftHistory') {
          if ((0, _utils.isEmpty)(newValue)) {
            return "Can't be blank";
          }
        }

        return true;
      },
      giftHistoryTo: (_key, newValue, _oldValue, changes, content) => {
        var _getFilterInfo4;

        if (((_getFilterInfo4 = getFilterInfo(content, changes)) === null || _getFilterInfo4 === void 0 ? void 0 : _getFilterInfo4.propertyType) === 'giftHistory') {
          if ((0, _utils.isEmpty)(newValue)) {
            return "Can't be blank";
          }
        }

        return true;
      },
      dateBetweenAfterVal: (_key, newValue, _oldValue, changes, content) => {
        let filterInfo = getFilterInfo(content, changes);
        let operation = (0, _objectUtils.assignSingleProperty)(content, changes, 'operation');

        if ((filterInfo === null || filterInfo === void 0 ? void 0 : filterInfo.propertyType) === 'date' && operation === '><') {
          if ((0, _utils.isEmpty)(newValue)) {
            return "Can't be blank";
          }
        }

        return true;
      },
      dateBetweenBeforeVal: (_key, newValue, _oldValue, changes, content) => {
        let filterInfo = getFilterInfo(content, changes);
        let operation = (0, _objectUtils.assignSingleProperty)(content, changes, 'operation');

        if ((filterInfo === null || filterInfo === void 0 ? void 0 : filterInfo.propertyType) === 'date' && operation === '><') {
          if ((0, _utils.isEmpty)(newValue)) {
            return "Can't be blank";
          }

          let dateBetweenAfterVal = (0, _objectUtils.assignSingleProperty)(content, changes, 'dateBetweenAfterVal');

          if (dateBetweenAfterVal && dateBetweenAfterVal >= newValue) {
            return "Must be after the other date";
          }
        }

        return true;
      }
    };
    return validation;
  }

  ;
});