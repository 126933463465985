define("donor-elf-web/controllers/coaching/individuals/individual/report/edit", ["exports", "@ember/controller", "@ember/object", "ember-concurrency", "@ember/service"], function (_exports, _controller, _object, _emberConcurrency, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    coachingService: (0, _service.inject)(),
    hasErrors: false,
    checkForErrors: 0,

    reset() {
      (0, _object.set)(this, 'hasErrors', false);
    },

    save: (0, _emberConcurrency.task)(function* () {
      let {
        changeset,
        prevChangeset
      } = (0, _object.getProperties)((0, _object.get)(this, 'model'), 'changeset', 'prevChangeset');
      yield changeset.validate();

      if (prevChangeset) {
        yield prevChangeset.validate();
      }

      if ((0, _object.get)(changeset, 'isValid') && (prevChangeset == null || (0, _object.get)(prevChangeset, 'isValid'))) {
        (0, _object.set)(this, 'hasErrors', false);
        yield (0, _object.get)(this, 'coachingService').confirmValidIndividualToken();
        yield changeset.save();

        if (prevChangeset) {
          yield prevChangeset.save();
        }

        this.transitionToRoute('coaching.individuals.individual.report', {
          queryParams: {
            preserveScrollPosition: false
          }
        });
      } else {
        (0, _object.set)(this, 'hasErrors', true);
        this.incrementProperty('checkForErrors');
      }
    }).drop(),
    actions: {
      cancel() {
        this.transitionToRoute('coaching.individuals.individual.report', {
          queryParams: {
            preserveScrollPosition: false
          }
        });
      }

    }
  });

  _exports.default = _default;
});