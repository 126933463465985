define("donor-elf-web/routes/not-found", ["exports", "@ember/routing/route", "@ember/object", "@ember/service", "donor-elf-web/lib/logger"], function (_exports, _route, _object, _service, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    navbarSettings: (0, _service.inject)(),
    router: (0, _service.inject)(),

    beforeModel(transition) {
      (0, _object.set)(this, 'navbarSettings.title', 'Not Found');

      _logger.default.error(`invalid fund token while going to url: ${transition.intent.url}. From route: ${(0, _object.get)(this, 'router.currentRouteName')}`);
    }

  });

  _exports.default = _default;
});