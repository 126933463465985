define("donor-elf-web/templates/reset-password/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "a3CnjkpZ",
    "block": "[[[8,[39,0],[[4,[38,1],[[30,0,[\"templateWillDestroy\"]]],null]],null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],null,[[\"@changeset\",\"@property\",\"@focusOnInsert\",\"@label\",\"@onEnter\",\"@showValidationErrors\"],[[30,1,[\"changeset\"]],\"email\",true,\"What is your email address?\",[30,0,[\"save\"]],[30,0,[\"showValidationErrors\"]]]],null],[1,\"\\n\\n  \"],[10,0],[14,0,\"mt-4\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"d-flex\"],[12],[1,\"\\n      \"],[10,0],[12],[1,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-warning\"],[24,4,\"button\"],[4,[38,3],[\"click\",[30,0,[\"save\"]]],null],[12],[1,\"\\n          Reset my Password\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"ml-auto\"],[12],[1,\"\\n        \"],[8,[39,4],[[24,0,\"btn btn-link\"]],[[\"@route\"],[\"login\"]],[[\"default\"],[[[[1,\"\\n          Cancel\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[30,0,[\"saving\"]],[[[1,\"      \"],[10,0],[14,0,\"alert alert-info mt-3\"],[12],[1,\"\\n        Resetting...\\n      \"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"error\"]],[[[1,\"      \"],[8,[39,6],[[24,0,\"mt-3\"]],[[\"@error\"],[[30,0,[\"error\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@model\"],false,[\"form-card\",\"will-destroy\",\"form-group\",\"on\",\"link-to\",\"if\",\"generic-error\"]]",
    "moduleName": "donor-elf-web/templates/reset-password/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});