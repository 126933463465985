define("donor-elf-web/routes/funds/fund/journals/journal/report/default", ["exports", "@ember/routing/route", "@ember/service", "donor-elf-web/lib/models/journal-report-default"], function (_exports, _route, _service, _journalReportDefault) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let JournalsIndexRoute = (_class = class JournalsIndexRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "formatDateService", _descriptor, this);

      _initializerDefineProperty(this, "journalService", _descriptor2, this);
    }

    async model() {
      let reportModel = this.modelFor('funds.fund.journals.journal.report');
      let formattedReport = new _journalReportDefault.default({
        journalInfo: reportModel.journalInfo,
        journalReportModel: reportModel.journalReport,
        formatDateService: this.formatDateService
      });
      let journalId = reportModel.journalInfo.journal.id;
      let journalContactInfos = await this.journalService.loadJournalContactInfos(journalId);
      let journalDashboard = (await this.store.query('journal-dashboard', {
        filter: {
          journal_ids: journalId
        }
      })).toArray()[0];
      return { ...reportModel,
        formattedReport,
        journalContactInfos,
        journalDashboard
      };
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "formatDateService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "journalService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = JournalsIndexRoute;
});