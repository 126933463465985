define("donor-elf-web/components/contact/details/events", ["exports", "@ember/component", "@ember/template-factory", "@ember/object/computed", "@glimmer/component"], function (_exports, _component, _templateFactory, _computed, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Card ...attributes>
    <h4 class='card-title pb-2 border-bottom'>News Feed Events</h4>
    <div class='tw-max-h-80 overflow-auto'>
      {{#each this.sortedEvents as |event|}}
        <Event @eventModel={{event}} @showContact={{false}} />
      {{/each}}
    </div>
  </Card>
  
  */
  {
    "id": "2R9707+f",
    "block": "[[[8,[39,0],[[17,1]],null,[[\"default\"],[[[[1,\"\\n  \"],[10,\"h4\"],[14,0,\"card-title pb-2 border-bottom\"],[12],[1,\"News Feed Events\"],[13],[1,\"\\n  \"],[10,0],[14,0,\"tw-max-h-80 overflow-auto\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"sortedEvents\"]]],null]],null],null,[[[1,\"      \"],[8,[39,3],null,[[\"@eventModel\",\"@showContact\"],[[30,2],false]],null],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&attrs\",\"event\"],false,[\"card\",\"each\",\"-track-array\",\"event\"]]",
    "moduleName": "donor-elf-web/components/contact/details/events.hbs",
    "isStrictMode": false
  });

  /*
    attrs
      - contact
      - model - array of events
  */
  let EventsComponent = (_dec = (0, _computed.sort)('args.model', 'sortBy'), (_class = class EventsComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "sortBy", ['occurredAt:desc']);

      _initializerDefineProperty(this, "sortedEvents", _descriptor, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "sortedEvents", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = EventsComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EventsComponent);
});