define("donor-elf-web/templates/components/event-info-below-regular-amount", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "TtdCcZnb",
    "block": "[[[1,\"They gave \"],[1,[28,[35,0],[[33,1]],[[\"removeNoCents\"],[true]]]],[1,\" below their regular amount.\\nTheir pledge is for \"],[1,[28,[35,0],[[33,2,[\"custom\",\"regular_amount\"]]],[[\"removeNoCents\"],[true]]]],[1,\", but\\nthis time they gave \"],[1,[28,[35,0],[[33,2,[\"custom\",\"donation_amount\"]]],[[\"removeNoCents\"],[true]]]],[1,\".\\n\"]],[],false,[\"format-amount\",\"decreaseAmount\",\"eventModel\"]]",
    "moduleName": "donor-elf-web/templates/components/event-info-below-regular-amount.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});