define("donor-elf-web/models/assign-tasks/contact", ["exports", "ember-data", "@ember/utils", "@ember/object"], function (_exports, _emberData, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    name: attr('string'),
    city: attr('string'),
    state: attr('string'),
    cityAndState: (0, _object.computed)('city', 'state', function () {
      return [this.city, this.state].filter(x => (0, _utils.isPresent)(x)).join(', ');
    })
  });

  _exports.default = _default;
});