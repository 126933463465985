define("donor-elf-web/components/contact/bulk-actions/edit-property/index", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _object, _component2, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (eq 'ask' this.stage)}}
    <Contact::BulkActions::EditProperty::SelectProperty
      @contactIds={{@contactIds}}
      @onAddNewProperty={{this.handleOnAddNewProperty}}
      @onPropertySelected={{this.handlePropertySelected}}
      @onClose={{@onClose}}
    />
  {{else if (eq 'new' this.stage)}}
    <Contact::BulkActions::EditProperty::New
      @contactIds={{@contactIds}}
      @onContactsChanged={{@onContactsChanged}}
      @onClose={{@onClose}}
    />
  {{else if (eq 'edit' this.stage)}}
    <Contact::BulkActions::EditProperty::Existing
      @propertyLabel={{this.existingPropertyName}}
      @contactIds={{@contactIds}}
      @onContactsChanged={{@onContactsChanged}}
      @onClose={{@onClose}}
    />
  {{/if}}
  */
  {
    "id": "6Dwm69Y/",
    "block": "[[[41,[28,[37,1],[\"ask\",[30,0,[\"stage\"]]],null],[[[1,\"  \"],[8,[39,2],null,[[\"@contactIds\",\"@onAddNewProperty\",\"@onPropertySelected\",\"@onClose\"],[[30,1],[30,0,[\"handleOnAddNewProperty\"]],[30,0,[\"handlePropertySelected\"]],[30,2]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[\"new\",[30,0,[\"stage\"]]],null],[[[1,\"  \"],[8,[39,3],null,[[\"@contactIds\",\"@onContactsChanged\",\"@onClose\"],[[30,1],[30,3],[30,2]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[\"edit\",[30,0,[\"stage\"]]],null],[[[1,\"  \"],[8,[39,4],null,[[\"@propertyLabel\",\"@contactIds\",\"@onContactsChanged\",\"@onClose\"],[[30,0,[\"existingPropertyName\"]],[30,1],[30,3],[30,2]]],null],[1,\"\\n\"]],[]],null]],[]]]],[]]]],[\"@contactIds\",\"@onClose\",\"@onContactsChanged\"],false,[\"if\",\"eq\",\"contact/bulk-actions/edit-property/select-property\",\"contact/bulk-actions/edit-property/new\",\"contact/bulk-actions/edit-property/existing\"]]",
    "moduleName": "donor-elf-web/components/contact/bulk-actions/edit-property/index.hbs",
    "isStrictMode": false
  });

  let ContactBulkActionsEditPropertyIndexComponent = (_class = class ContactBulkActionsEditPropertyIndexComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "stage", _descriptor, this);

      _initializerDefineProperty(this, "existingPropertyName", _descriptor2, this);
    }

    handleOnAddNewProperty() {
      this.stage = 'new';
    }

    handlePropertySelected(propertyName) {
      this.stage = 'edit';
      this.existingPropertyName = propertyName;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "stage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'ask';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "existingPropertyName", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handleOnAddNewProperty", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleOnAddNewProperty"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handlePropertySelected", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handlePropertySelected"), _class.prototype)), _class);
  _exports.default = ContactBulkActionsEditPropertyIndexComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ContactBulkActionsEditPropertyIndexComponent);
});