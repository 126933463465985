define("donor-elf-web/templates/funds/fund/journals/getting-started", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Xgl6N3+X",
    "block": "[[[10,\"ol\"],[14,0,\"breadcrumb row-bottom-margin\"],[12],[1,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@route\"],[\"funds.fund.journals\"]],[[\"default\"],[[[[1,\"Journals\"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[1,\"Getting Started Video\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"tw-shadow-lg\"],[14,5,\"padding:75% 0 0 0;position:relative; max-width: 1080px\"],[12],[1,\"\\n  \"],[10,\"iframe\"],[14,\"src\",\"https://player.vimeo.com/video/556445446\"],[14,5,\"position:absolute;top:0;left:0;width:100%;height:100%;\"],[14,\"frameborder\",\"0\"],[14,\"allow\",\"autoplay; fullscreen; picture-in-picture\"],[14,\"allowfullscreen\",\"\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"script\"],[14,\"src\",\"https://player.vimeo.com/api/player.js\"],[12],[13]],[],false,[\"link-to\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/journals/getting-started.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});