define("donor-elf-web/controllers/funds/fund/dashboard/resonate-global-mission", ["exports", "@ember/object/computed", "@ember/controller", "@ember/object", "@ember/utils", "donor-elf-web/lib/filter-utils-new"], function (_exports, _computed, _controller, _object, _utils, _filterUtilsNew) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    goal: (0, _computed.alias)('model.dashboard.goal'),
    fund: (0, _computed.alias)('model.fund'),
    monthlyGoalAmount: (0, _object.computed)('goal.goalAmount', function () {
      let annualGoalAmount = (0, _object.get)(this, 'goal.goalAmount');

      if ((0, _utils.isPresent)(annualGoalAmount)) {
        return Math.round(annualGoalAmount / 12);
      } else {
        return null;
      }
    }),
    ytdAmount: (0, _computed.alias)('goal.goalData.ytd_amount'),
    ytdPercent: (0, _object.computed)('ytdAmount', 'goal.goalAmount', function () {
      let goalAmount = (0, _object.get)(this, 'goal.goalAmount') || 0;
      let ytdAmount = (0, _object.get)(this, 'ytdAmount') || 0;

      if (goalAmount === 0) {
        return 0;
      }

      return Math.round(ytdAmount * 100 / goalAmount);
    }),
    actions: {
      chartClicked() {
        var _this$goal, _this$goal$goalData;

        this.transitionToRoute('funds.fund.transactions', {
          queryParams: {
            filters: (0, _filterUtilsNew.serializeAppliedFilters)([{
              propertyName: 'transDate',
              operation: '>=',
              val: (_this$goal = this.goal) === null || _this$goal === void 0 ? void 0 : (_this$goal$goalData = _this$goal.goalData) === null || _this$goal$goalData === void 0 ? void 0 : _this$goal$goalData.start_date
            }])
          }
        });
      }

    }
  });

  _exports.default = _default;
});