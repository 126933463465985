define("donor-elf-web/lib/logger", ["exports", "@bugsnag/js", "@ember/utils"], function (_exports, _js, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    error() {
      if (_js.default !== null && _js.default !== void 0 && _js.default.isStarted()) {
        let args = Array.prototype.slice.call(arguments);
        let err = isError(args[0]) ? args[0] : new Error(stringify(args));

        _js.default.notify(err);
      } // eslint-disable-next-line no-console


      console.error.apply(this, arguments);
    },

    warn() {
      if (_js.default !== null && _js.default !== void 0 && _js.default.isStarted()) {
        _js.default.notify(arguments);
      } // eslint-disable-next-line no-console


      console.warn.apply(this, arguments);
    },

    info() {
      // eslint-disable-next-line no-console
      console.info.apply(this, arguments);
    },

    debug() {
      // eslint-disable-next-line no-console
      console.debug.apply(this, arguments);
    }

  };
  _exports.default = _default;

  let isError = function (object) {
    return (0, _utils.typeOf)(object) === 'error';
  };

  let stringify = function (object) {
    return JSON ? JSON.stringify(object) : object.toString();
  };
});