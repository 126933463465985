define("donor-elf-web/services/coaching-service", ["exports", "@ember/object", "@ember/service", "@ember/utils", "rsvp", "ember-changeset", "ember-changeset-validations", "donor-elf-web/validations/coaching/report"], function (_exports, _object, _service, _utils, _rsvp, _emberChangeset, _emberChangesetValidations, _report) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    moment
  } = window;
  const NUMBER_TYPES = {
    'number': true,
    'amount': true,
    'number&amount': true,
    'pledged&received': true
  }; // how many minutes to refresh the individual token

  const REFRESH_TOKEN_MINUTES = 45;

  var _default = _service.default.extend({
    store: (0, _service.inject)(),
    selectedIndividualId: null,
    selectedIndividualToken: '',
    selectedIndividualTokenExpiresAt: null,

    refreshSelectedIndividualToken() {
      let selectedIndividualId = (0, _object.get)(this, 'selectedIndividualId');

      if (selectedIndividualId == null) {
        throw "'selectedIndividualId' property must be set before calling refreshSelectedIndividualToken()";
      }

      return (0, _object.get)(this, 'store').find('coaching/selected-individual-token', selectedIndividualId).then(record => {
        let token = (0, _object.get)(record, 'token');

        if (token == null) {
          token = '';
        }

        (0, _object.setProperties)(this, {
          selectedIndividualToken: token,
          selectedIndividualTokenExpiresAt: moment().add(REFRESH_TOKEN_MINUTES, 'minutes')
        });
      });
    },

    clearSelectedIndividual() {
      (0, _object.setProperties)(this, {
        selectedIndividualId: null,
        selectedIndividualToken: '',
        selectedIndividualTokenExpiresAt: null
      });
    },

    confirmValidIndividualToken() {
      let tokenExpiresAt = (0, _object.get)(this, 'selectedIndividualTokenExpiresAt');

      if ((0, _utils.isEmpty)(tokenExpiresAt) || moment() > tokenExpiresAt) {
        return this.refreshSelectedIndividualToken();
      } else {
        return _rsvp.default.resolve();
      }
    },

    addReport(coachingIndividual) {
      let {
        startDate,
        frequencyTimespan,
        frequencyNumber
      } = (0, _object.getProperties)(coachingIndividual, 'startDate', 'frequencyTimespan', 'frequencyNumber');
      let lastReport = (0, _object.get)(coachingIndividual, 'sortedReportsDesc.firstObject');
      let nextReportStartDate = moment(startDate).toDate();

      if (lastReport) {
        nextReportStartDate = moment((0, _object.get)(lastReport, 'endDate')).add(1, 'day').toDate();
      }

      let nextReportEndDate = this.reportEndDate({
        startDate: nextReportStartDate,
        frequencyNumber,
        frequencyTimespan
      });
      let report = (0, _object.get)(this, 'store').createRecord('coaching/report', {
        individual: coachingIndividual,
        startDate: nextReportStartDate,
        endDate: nextReportEndDate,
        sectionFieldValues: {}
      });
      return report;
    },

    reportEndDate({
      startDate,
      frequencyNumber,
      frequencyTimespan
    }) {
      return moment(startDate).add(frequencyNumber, frequencyTimespan).subtract(1, 'day').toDate();
    },

    // on 'number&amount' field types, you can retrieve the number value
    // like this: getFieldValue(field, report, { propertyName: 'n' })
    getFieldValue(field, report, options = {
      propertyName: 'v'
    }) {
      if ((0, _utils.isEmpty)(options.propertyName)) {
        options.propertyName = 'v';
      }

      let val = (0, _object.get)(report, `sectionFieldValues.${field.id}.${options.propertyName}`);

      if ((0, _utils.isEmpty)(val) && field.t !== 'tasks') {
        return null;
      }

      if (NUMBER_TYPES[field.t]) {
        return Number(val);
      }

      if (field.t === 'response') {
        let response = {
          yes: Number(val.yes || 0),
          no: Number(val.no || 0),
          maybe: Number(val.maybe || 0)
        };
        response.total = response.yes + response.no + response.maybe;
        return response;
      }

      return val;
    },

    getFieldValueDiff(field, report, individual, {
      propertyName
    }) {
      let reports = (0, _object.get)(individual, 'sortedReports').toArray();
      let reportIndex = reports.indexOf(report);
      let prevReport = reports[reportIndex - 1];
      let prevVal = 0;

      if (prevReport) {
        prevVal = this.getFieldValue(field, prevReport, {
          propertyName
        }) || 0;

        if (prevVal === 0) {
          for (let i = reportIndex - 1; i >= 0; i--) {
            prevVal = this.getFieldValue(field, reports[i], {
              propertyName
            }) || 0;

            if (prevVal > 0) {
              i = -1;
            }
          }
        }
      }

      let val = this.getFieldValue(field, report, {
        propertyName
      });

      if ((0, _utils.isEmpty)(val)) {
        return null;
      } else if (val === 0) {
        return 0;
      }

      return val - prevVal;
    },

    createChangesetForReport(individual, report) {
      let validationInstance = (0, _report.default)({
        individual
      });
      let changeset = new _emberChangeset.default(report, (0, _emberChangesetValidations.default)(validationInstance), validationInstance, {
        skipValidate: true
      }); // have to stringify and then parse sectionFieldValues to account for non-primitive types like arrays

      let sectionFieldValues = (0, _object.get)(report, 'sectionFieldValues');

      if (!(0, _utils.isEmpty)(sectionFieldValues)) {
        (0, _object.set)(changeset, 'sectionFieldValues', JSON.parse(JSON.stringify(sectionFieldValues)));
      }

      (0, _object.get)(individual, 'sections').forEach(section => {
        section.fields.forEach(field => {
          if ((0, _object.get)(report, `sectionFieldValues.${field.id}`) == null) {
            if (field.t === 'tasks') {
              (0, _object.set)(changeset, `sectionFieldValues.${field.id}`, {
                v: []
              });
            } else if (field.t === 'response') {
              (0, _object.set)(changeset, `sectionFieldValues.${field.id}`, {
                v: {}
              });
            } else {
              (0, _object.set)(changeset, `sectionFieldValues.${field.id}`, {
                v: ''
              });
            }
          }
        });
      });
      return changeset;
    },

    overallGoalTypes: {
      'pledged&received': true
    },
    intervalGoalTypes: {
      number: true,
      amount: true,
      response: true
    },

    calculateIntervalGoal({
      individualRecord,
      overallGoal
    }) {
      return Math.ceil(Number(overallGoal) / (0, _object.get)(individualRecord, 'reportingPeriodsToGoalDate'));
    }

  });

  _exports.default = _default;
});