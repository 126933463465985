define("donor-elf-web/templates/funds/fund/contacts/contact/merge/with", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ezlvqh6Y",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@contactIds\",\"@keepContactId\",\"@onMerged\",\"@onNotMerged\",\"@onRecordDestroyedConfirmed\"],[[30,1,[\"contactIds\"]],[30,1,[\"keepContact\",\"id\"]],[30,0,[\"handleCompleted\"]],[30,0,[\"handleCompleted\"]],[30,0,[\"handleCompleted\"]]]],null],[1,\"\\n\"]],[]]]]]],[\"@model\"],false,[\"form-card\",\"merge-contact\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/contacts/contact/merge/with.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});