define("donor-elf-web/services/store-cache", ["exports", "@ember/object", "@ember/service", "rsvp"], function (_exports, _object, _service, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let RECORDS_PER_PAGE = 1000;

  var _default = _service.default.extend({
    store: (0, _service.inject)(),
    // all cached values are set on this object, which makes it
    // easy to break the cache because we just create a new cacheObject
    cacheObject: {
      findRecordPromises: {},
      findAllPromises: {},
      findAllDependencies: {},
      indexes: {},
      indexPromises: {}
    },

    // caches the results from store.findRecord
    findRecord(modelName, id) {
      let promiseName = `cacheObject.findRecordPromises.${modelName}.${id}`;
      let promise = (0, _object.get)(this, promiseName);

      if (promise) {
        return promise;
      }

      promise = this.store.findRecord(modelName, id);

      if ((0, _object.get)(this, `cacheObject.findRecordPromises.${modelName}`) == null) {
        (0, _object.set)(this, `cacheObject.findRecordPromises.${modelName}`, {});
      }

      (0, _object.set)(this, promiseName, promise);
      return promise;
    },

    // caches the records
    findAll(modelName, options) {
      let promiseName = `cacheObject.findAllPromises.${modelName}`;
      let promise = this[promiseName];

      if (promise) {
        return promise;
      }

      promise = new _rsvp.Promise((resolve, reject) => {
        this.queryPage({
          modelName,
          options: options || {},
          page: 1,
          results: [],
          promiseResolve: resolve
        });
      });
      return this[promiseName] = promise;
    },

    queryPage({
      modelName,
      options,
      page,
      results,
      promiseResolve
    }) {
      let filter = {
        offset: (page - 1) * RECORDS_PER_PAGE,
        limit: RECORDS_PER_PAGE
      };
      this.store.query(modelName, { ...options,
        filter
      }).then(records => {
        var _records$meta;

        results = [...results, ...records.toArray()];

        if ((((_records$meta = records.meta) === null || _records$meta === void 0 ? void 0 : _records$meta.total_count) || 0) > results.length) {
          this.queryPage({
            modelName,
            options,
            page: page + 1,
            results,
            promiseResolve
          });
        } else {
          promiseResolve(results);
        }
      });
    },

    _addFindAllDependency(modelName, cacheObjectPropertyName) {
      if ((0, _object.get)(this, `cacheObject.findAllDependencies.${modelName}`) == null) {
        (0, _object.set)(this, `cacheObject.findAllDependencies.${modelName}`, {});
      }

      (0, _object.set)(this, `cacheObject.findAllDependencies.${modelName}.${cacheObjectPropertyName.replace(/\./g, '^')}`, true);
    },

    // indexes all the records from a findAll by the property
    // there will only be a single record per index
    indexSingleBy(modelName, property) {
      let promiseName = `cacheObject.indexPromises.${modelName}_${property}_single`;
      let promise = (0, _object.get)(this, promiseName);

      if (promise) {
        return promise;
      }

      this._addFindAllDependency(modelName, promiseName);

      promise = this.findAll(modelName).then(records => {
        let index = {};
        records.forEach(record => {
          index[(0, _object.get)(record, property)] = record;
        });
        (0, _object.set)(this, `cacheObject.indexes.${modelName}_${property}_single`, index);
        return index;
      });
      (0, _object.set)(this, promiseName, promise);
      return promise;
    },

    // indexes all the records from a findAll by the property
    // there can be multiple records per index
    indexMultipleBy(modelName, property) {
      let promiseName = `cacheObject.indexPromises.${modelName}_${property}_multiple`;
      let promise = (0, _object.get)(this, promiseName);

      if (promise) {
        return promise;
      }

      this._addFindAllDependency(modelName, promiseName);

      promise = this.findAll(modelName).then(records => {
        let index = {};
        records.forEach(record => {
          let indexVal = (0, _object.get)(record, property);

          if (index[indexVal] == null) {
            index[indexVal] = [];
          }

          index[indexVal].push(record);
        });
        (0, _object.set)(this, `cacheObject.indexes.${modelName}_${property}_multiple`, index);
        return index;
      });
      (0, _object.set)(this, promiseName, promise);
      return promise;
    },

    // gets a model by a property value that has already been indexed.
    // must call indexSingleBy() first
    getSingleIndexedBy(modelName, property, val) {
      let index = (0, _object.get)(this, `cacheObject.indexes.${modelName}_${property}_single`);

      if (index == null) {
        throw `Must call indexSingleBy('${modelName}', '${property}') first`;
      }

      return index[val];
    },

    modelNameIsCached(modelName) {
      return this.cacheObject.findAllPromises[modelName] != null;
    },

    breakFindAllCache(modelName) {
      let promiseName = `cacheObject.findAllPromises.${modelName}`;
      (0, _object.set)(this, promiseName, undefined);
      let findAllDependencies = (0, _object.get)(this, `cacheObject.findAllDependencies.${modelName}`);

      if (findAllDependencies != null) {
        Object.keys(findAllDependencies).forEach(cacheObjectPropertyName => {
          (0, _object.set)(this, cacheObjectPropertyName.replace(/\^/g, '.'), undefined);
        });
      }
    },

    breakAdminUserFundsCache() {
      (0, _object.set)(this, 'cacheObject.getAdminUserFunds', null);
    },

    unload() {
      (0, _object.set)(this, 'cacheObject', {
        findRecordPromises: {},
        findAllPromises: {},
        findAllDependencies: {},
        indexes: {},
        indexPromises: {}
      });
    }

  });

  _exports.default = _default;
});