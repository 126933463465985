define("donor-elf-web/components/fund-summary/chart", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/service", "donor-elf-web/config/environment", "donor-elf-web/lib/date-utils"], function (_exports, _component, _templateFactory, _component2, _object, _service, _environment, _dateUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    ...attributes
    {{did-insert this.renderChart}}
    {{did-update this.renderChart @height @showIncome @showExpense @showBalance @showGoal}}
    class='fund-summary-chart'
  >
  </div>
  */
  {
    "id": "B0m6syLe",
    "block": "[[[11,0],[17,1],[24,0,\"fund-summary-chart\"],[4,[38,0],[[30,0,[\"renderChart\"]]],null],[4,[38,1],[[30,0,[\"renderChart\"]],[30,2],[30,3],[30,4],[30,5],[30,6]],null],[12],[1,\"\\n\"],[13]],[\"&attrs\",\"@height\",\"@showIncome\",\"@showExpense\",\"@showBalance\",\"@showGoal\"],false,[\"did-insert\",\"did-update\"]]",
    "moduleName": "donor-elf-web/components/fund-summary/chart.hbs",
    "isStrictMode": false
  });

  const {
    moment,
    Highcharts
  } = window;
  let FundSummaryChart = (_class = class FundSummaryChart extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "formatAmountService", _descriptor, this);

      _defineProperty(this, "fontFamily", '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif');
    }

    get fundSummary() {
      return this.args.fundSummary;
    }

    get height() {
      return this.args.height || 200;
    }

    get monthlyBalance() {
      return this.fundSummary.monthlyData.map(item => {
        return {
          y: item.balance,
          monthlyData: item
        };
      });
    }

    get monthlyIncome() {
      return this.fundSummary.monthlyData.map(item => {
        return {
          y: item.income,
          monthlyData: item,
          hasMonthlyGoal: this.fundSummary.hasMonthlyGoal,
          monthlyGoal: this.fundSummary.monthlyGoal
        };
      });
    }

    get monthlyExpense() {
      return this.fundSummary.monthlyData.map(item => {
        return {
          y: item.expense * -1,
          monthlyData: item,
          hasMonthlyGoal: this.fundSummary.hasMonthlyGoal,
          monthlyGoal: this.fundSummary.monthlyGoal
        };
      });
    }

    get monthlyGoal() {
      return this.fundSummary.monthlyData.map(() => {
        return {
          y: this.fundSummary.monthlyGoal
        };
      });
    }

    get categories() {
      return this.fundSummary.monthlyData.map(item => {
        return moment(`${item.year}-${item.month}-02`, 'YYYY-M-DD').format('MMM YYYY');
      });
    }

    generateFromAndToParams(item) {
      let monthString = item.month.toString();

      if (monthString.length === 1) {
        monthString = `0${monthString}`;
      }

      let date = _dateUtils.default.deserializeDateOnly(`${item.year}-${monthString}-01`);

      return `from=${moment(date).format('YYYY-MM-DD')}&to=${moment(date).endOf('month').format('YYYY-MM-DD')}`;
    }

    get monthlyGiftsChartSettings() {
      let {
        formatAmountService
      } = this;
      let fundId = this.fundSummary.fundId;
      let self = this;
      let series = [];

      if (this.args.showIncome) {
        series.push({
          type: 'column',
          name: 'Monthly Income',
          color: '#0b68a1',
          tooltip: {
            pointFormatter: function () {
              let message = `Income: ${formatAmountService.format(this.y)}`;

              if (this.hasMonthlyGoal) {
                let percent = Math.round(this.y * 100 / this.monthlyGoal);
                message = `${message} (${percent}%)`;
              }

              return message;
            }
          },
          events: {
            click(event) {
              window.location.href = `${_environment.default.routerRootURL}funds/${fundId}/transactions?incomeOrExpense=income&${self.generateFromAndToParams(event.point.monthlyData)}`;
            }

          },
          data: this.monthlyIncome
        });
      }

      if (this.args.showExpense) {
        series.push({
          type: 'column',
          name: 'Monthly Expense',
          color: '#d9534f',
          tooltip: {
            pointFormatter: function () {
              let message = `Expense: ${formatAmountService.format(this.y)}`;

              if (this.hasMonthlyGoal) {
                let percent = Math.round(this.y * 100 / this.monthlyGoal);
                message = `${message} (${percent}%)`;
              }

              return message;
            }
          },
          events: {
            click(event) {
              window.location.href = `${_environment.default.routerRootURL}funds/${fundId}/transactions?incomeOrExpense=expense&${self.generateFromAndToParams(event.point.monthlyData)}`;
            }

          },
          data: this.monthlyExpense
        });
      }

      if (this.args.showGoal) {
        series.push({
          type: 'line',
          name: 'Monthly Goal',
          color: '#f0ad4e',
          tooltip: {
            pointFormatter: function () {
              return `Goal: ${formatAmountService.format(this.y)}`;
            }
          },
          lineWidth: 3,
          marker: {
            enabled: false
          },
          data: this.monthlyGoal
        });
      }

      if (this.args.showBalance) {
        series.push({
          type: 'line',
          name: 'Monthly Balance',
          color: '#5cb85c',
          tooltip: {
            pointFormatter: function () {
              return `Balance: ${formatAmountService.format(this.y)}`;
            }
          },
          events: {
            click() {
              window.location.href = `${_environment.default.routerRootURL}funds/${fundId}/monthly-summary`;
            }

          },
          lineWidth: 3,
          marker: {
            enabled: false
          },
          data: this.monthlyBalance
        });
      }

      return {
        chart: {
          marginBottom: 7,
          marginTop: 0,
          height: this.height
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        tooltip: {
          enabled: true,
          headerFormat: '<span style="font-size: 14px">{point.key}</span><br/>',
          style: {
            fontSize: '16px'
          }
        },
        plotOptions: {
          column: {
            groupPadding: 0.2,
            pointPadding: 0.01
          }
        },
        xAxis: {
          labels: {
            enabled: false
          },
          visible: false,
          categories: this.categories
        },
        yAxis: {
          labels: {
            enabled: false
          },
          title: {
            text: null
          },
          startOnTick: false,
          endOnTick: false,
          tickPositions: [0]
        },
        legend: {
          enabled: false
        },
        series
      };
    }

    renderChart(e) {
      Highcharts.chart(e, this.monthlyGiftsChartSettings);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "formatAmountService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "renderChart", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "renderChart"), _class.prototype)), _class);
  _exports.default = FundSummaryChart;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FundSummaryChart);
});