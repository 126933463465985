define("donor-elf-web/controllers/coaching/individuals/individual/report/index", ["exports", "@ember/controller", "@ember/service", "@ember/object", "ember-concurrency", "@ember/utils", "@ember/object/computed"], function (_exports, _controller, _service, _object, _emberConcurrency, _utils, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    moment
  } = window;

  var _default = _controller.default.extend({
    abilities: (0, _service.inject)(),
    coachingService: (0, _service.inject)(),
    store: (0, _service.inject)(),
    queryParams: ['preserveScrollPosition'],
    preserveScrollPosition: true,
    showNavigationModal: false,
    editingReportCoachNotes: false,
    saveReportCoachNotesError: null,
    editingIndividualCoachNotes: false,
    saveIndividualCoachNotesError: null,
    editingFeedback: false,
    saveFeedbackError: null,
    report: (0, _computed.alias)('model.record'),
    onLastReport: (0, _object.computed)('report.id', function () {
      return (0, _object.get)(this, 'model.individualRecord.sortedReports.lastObject.id') === (0, _object.get)(this, 'report.id');
    }),
    canAddReport: (0, _object.computed)('onLastReport', 'report.completed', function () {
      return this.onLastReport && this.report.completed && this.abilities.hasCoachingAbility('edit_report');
    }),
    canEditGoal: (0, _object.computed)('model.individualRecord.id', function () {
      return (0, _object.get)(this, 'abilities').hasCoachingAbility('edit_goal');
    }),
    monthsToRaiseSupport: (0, _object.computed)('model.individualRecord.id', function () {
      let individualRecord = (0, _object.get)(this, 'model.individualRecord');
      let {
        startDate,
        goalDate
      } = (0, _object.getProperties)(individualRecord, 'startDate', 'goalDate');

      if ((0, _utils.isEmpty)(goalDate)) {
        return 0;
      }

      let goalDateString = moment(goalDate).format('YYYYMMDD');
      let date = moment(startDate);
      let months = -1;

      while (date.format('YYYYMMDD') < goalDateString) {
        months += 1;
        date.add(1, 'months');
      } // if it's within 15 days after the goal date, then count it as another month


      if (date.subtract(15, 'days').format('YYYYMMDD') < goalDateString) {
        months += 1;
      }

      return months;
    }),

    reset() {
      (0, _object.setProperties)(this, {
        editingReportCoachNotes: false,
        saveReportCoachNotesError: null,
        editingIndividualCoachNotes: false,
        saveIndividualCoachNotesError: null,
        editingFeedback: false,
        saveFeedbackError: null
      });
    },

    expandedSections: {},

    expandAllSections() {
      let expanded = {};
      (0, _object.get)(this, 'model.individualRecord.sections').forEach(section => {
        expanded[(0, _object.get)(section, 'l')] = true;
      });
      (0, _object.set)(this, 'expandedSections', expanded);
    },

    saveReportCoachNotes: (0, _emberConcurrency.task)(function* () {
      if ((0, _object.get)(this, 'abilities').noCoachingAbility('edit_coach_note')) {
        return;
      }

      (0, _object.set)(this, 'saveReportCoachNotesError', null);
      let report = (0, _object.get)(this, 'model.record');
      let coachNotesText = (0, _object.get)(this, 'reportCoachNotesText');
      (0, _object.set)(report, 'coachNotes', coachNotesText);

      try {
        yield report.save();
        (0, _object.setProperties)(this, {
          editingReportCoachNotes: false,
          coachNotesText: ''
        });
      } catch (error) {
        (0, _object.set)(this, 'saveReportCoachNotesError', error);
      }
    }),
    saveIndividualCoachNotes: (0, _emberConcurrency.task)(function* () {
      if ((0, _object.get)(this, 'abilities').noCoachingAbility('edit_coach_note')) {
        return;
      }

      (0, _object.set)(this, 'saveIndividualCoachNotesError', null);
      let individual = (0, _object.get)(this, 'model.individualRecord');
      let coachNotesText = (0, _object.get)(this, 'individualCoachNotesText');
      (0, _object.set)(individual, 'coachNotes', coachNotesText);

      try {
        yield individual.save();
        (0, _object.setProperties)(this, {
          editingIndividualCoachNotes: false,
          individaulCoachNotesText: ''
        });
      } catch (error) {
        (0, _object.set)(this, 'saveIndividualCoachNotesError', error);
      }
    }),
    saveFeedback: (0, _emberConcurrency.task)(function* () {
      if ((0, _object.get)(this, 'abilities').noCoachingAbility('edit_coach_note')) {
        return;
      }

      (0, _object.set)(this, 'saveFeedbackError', null);
      let report = (0, _object.get)(this, 'model.record');
      let feedbackText = (0, _object.get)(this, 'feedbackText');
      (0, _object.set)(report, 'feedback', feedbackText);

      try {
        yield report.save();
        (0, _object.setProperties)(this, {
          editingFeedback: false,
          feedbackText: ''
        });
      } catch (error) {
        (0, _object.set)(this, 'saveFeedbackError', error);
      }
    }),
    actions: {
      toggleExpandedSection(sectionLabel) {
        let property = `expandedSections.${sectionLabel}`;
        this.toggleProperty(property);
      },

      handleShowCoachingPaginationModalChange(show) {
        (0, _object.set)(this, 'showNavigationModal', show);
      },

      editReportCoachNotes() {
        (0, _object.setProperties)(this, {
          editingReportCoachNotes: true,
          reportCoachNotesText: (0, _object.get)(this, 'model.record.coachNotes')
        });
      },

      cancelEditReportCoachNotes() {
        (0, _object.setProperties)(this, {
          editingReportCoachNotes: false,
          coachNotesText: ''
        });
      },

      editIndividualCoachNotes() {
        (0, _object.setProperties)(this, {
          editingIndividualCoachNotes: true,
          individualCoachNotesText: (0, _object.get)(this, 'model.individualRecord.coachNotes')
        });
      },

      cancelEditIndividualCoachNotes() {
        (0, _object.setProperties)(this, {
          editingIndividualCoachNotes: false,
          individualCoachNotesText: ''
        });
      },

      editFeedback() {
        (0, _object.setProperties)(this, {
          editingFeedback: true,
          feedbackText: (0, _object.get)(this, 'model.record.feedback')
        });
      },

      cancelEditFeedback() {
        (0, _object.setProperties)(this, {
          editingFeedback: false,
          feedbackText: ''
        });
      }

    }
  });

  _exports.default = _default;
});