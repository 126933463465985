define("donor-elf-web/templates/funds/fund/goal/rolling", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "+ylFvaXk",
    "block": "[[[8,[39,0],[[4,[38,1],[[30,0,[\"templateWillDestroy\"]]],null]],null,[[\"default\"],[[[[1,\"\\n\"],[6,[39,2],null,[[\"changeset\",\"alwaysSave\",\"saveTask\"],[[33,3,[\"changeset\"]],true,[33,4]]],[[\"default\"],[[[[41,[33,6],[[[1,\"      \"],[8,[39,7],[[4,[38,8],[[28,[37,9],[[30,0,[\"save\"]]],null]],null],[4,[38,10],null,null]],[[\"@changeset\",\"@property\",\"@label\",\"@showValidationErrors\"],[[30,1,[\"changeset\"]],\"annualGoal\",\"Annual Goal\",[30,0,[\"showValidationErrors\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,7],[[4,[38,8],[[28,[37,9],[[30,0,[\"save\"]]],null]],null],[4,[38,10],null,null]],[[\"@changeset\",\"@property\",\"@label\",\"@showValidationErrors\"],[[30,1,[\"changeset\"]],\"monthlyGoal\",\"Monthly Goal\",[30,0,[\"showValidationErrors\"]]]],null],[1,\"\\n\"]],[]]]],[]]]]]],[]]]]]],[\"@model\"],false,[\"form-card\",\"will-destroy\",\"save-form\",\"model\",\"save\",\"if\",\"isAnnualRollingGoal\",\"form/text\",\"on-enter\",\"perform\",\"focus-on-insert\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/goal/rolling.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});