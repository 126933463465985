define("donor-elf-web/components/mailchimp-sync/fund-sync/delete", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component", "@glimmer/tracking", "@ember/service"], function (_exports, _component, _templateFactory, _object, _component2, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div>
    <div class='p-2'>
      <p>
        Are you sure you want to remove this MailChimp sync?
      </p>
      <p class='tw-text-sm font-weight-light mb-0'>
        The emails currently synced with MailChimp will remain in MailChimp, but
        the DonorElf groups will be removed in MailChimp.
      </p>
    </div>
    {{#if this.error}}
      <GenericError
        @error={{this.error}}
        @useLeftBorderCard={{true}}
        class='p-2'
      />
    {{/if}}
    <div class='border-top p-2 border-bottom bg-gray-100'>
      <button
        {{on 'click' this.delete}}
        class='btn btn-sm btn-danger'
        disabled='{{if this.deleting 'disabled'}}'
        type='button'
      >
        {{#if this.deleting}}
          Removing Sync...
        {{else}}
          Yes, Remove Sync
        {{/if}}
      </button>
      {{#unless this.deleting}}
        <button
          {{on 'click' @onCancel}}
          class='btn btn-sm btn-link ml-3'
          type='button'
        >
          Cancel
        </button>
      {{/unless}}
    </div>
  </div>
  */
  {
    "id": "yNV9FgTw",
    "block": "[[[10,0],[12],[1,\"\\n  \"],[10,0],[14,0,\"p-2\"],[12],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      Are you sure you want to remove this MailChimp sync?\\n    \"],[13],[1,\"\\n    \"],[10,2],[14,0,\"tw-text-sm font-weight-light mb-0\"],[12],[1,\"\\n      The emails currently synced with MailChimp will remain in MailChimp, but\\n      the DonorElf groups will be removed in MailChimp.\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"error\"]],[[[1,\"    \"],[8,[39,1],[[24,0,\"p-2\"]],[[\"@error\",\"@useLeftBorderCard\"],[[30,0,[\"error\"]],true]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"border-top p-2 border-bottom bg-gray-100\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-sm btn-danger\"],[16,\"disabled\",[29,[[52,[30,0,[\"deleting\"]],\"disabled\"]]]],[24,4,\"button\"],[4,[38,2],[\"click\",[30,0,[\"delete\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"deleting\"]],[[[1,\"        Removing Sync...\\n\"]],[]],[[[1,\"        Yes, Remove Sync\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"],[41,[51,[30,0,[\"deleting\"]]],[[[1,\"      \"],[11,\"button\"],[24,0,\"btn btn-sm btn-link ml-3\"],[24,4,\"button\"],[4,[38,2],[\"click\",[30,1]],null],[12],[1,\"\\n        Cancel\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@onCancel\"],false,[\"if\",\"generic-error\",\"on\",\"unless\"]]",
    "moduleName": "donor-elf-web/components/mailchimp-sync/fund-sync/delete.hbs",
    "isStrictMode": false
  });

  let MailchimpSyncFundSyncIndexComponent = (_class = class MailchimpSyncFundSyncIndexComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fundsService", _descriptor, this);

      _initializerDefineProperty(this, "settings", _descriptor2, this);

      _initializerDefineProperty(this, "deleting", _descriptor3, this);

      _initializerDefineProperty(this, "error", _descriptor4, this);
    }

    async delete() {
      this.deleting = true;
      let mailchimpFundSync = this.args.mailchimpFundSyncInfo.mailchimpFundSync;

      try {
        await mailchimpFundSync.destroyRecord(); // have to update settings.mailchimpFundSyncSlim

        this.settings.unload();
        await this.settings.loadSettings(this.fundsService.selectedFund.id);
        this.args.onDeleted(this.args.mailchimpFundSyncInfo);
      } catch (e) {
        this.error = e;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "deleting", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "error", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "delete", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype)), _class);
  _exports.default = MailchimpSyncFundSyncIndexComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MailchimpSyncFundSyncIndexComponent);
});