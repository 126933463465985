define("donor-elf-web/routes/coaching/individuals/individual/index", ["exports", "@ember/routing/route", "@ember/object", "@ember/service"], function (_exports, _route, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    coachingService: (0, _service.inject)(),
    queryParams: {
      notComplete: {}
    },

    model(params) {
      let individual = this.modelFor('coaching.individuals.individual').record;

      if ((0, _object.get)(individual, 'filledOut')) {
        let recentReport = (0, _object.get)(individual, 'sortedReports.lastObject'); // create the first report if one doesn't exist

        if (recentReport == null) {
          let firstReport = (0, _object.get)(this, 'coachingService').addReport(individual);
          return firstReport.save().then(() => {
            return {
              notComplete: params.notComplete
            };
          });
        }
      }

      return {
        notComplete: params.notComplete
      };
    },

    redirect() {
      let individual = this.modelFor('coaching.individuals.individual').record;

      if ((0, _object.get)(individual, 'filledOut')) {
        let lastReport = (0, _object.get)(individual, 'sortedReports.lastObject');

        if (lastReport != null) {
          this.transitionTo('coaching.individuals.individual.report', (0, _object.get)(lastReport, 'id'));
        }
      } else {
        this.transitionTo('coaching.individuals.individual.edit', (0, _object.get)(individual, 'id'));
      }
    }

  });

  _exports.default = _default;
});