define("donor-elf-web/routes/funds/fund/dashboard", ["exports", "@ember/routing/route", "@ember/service", "@ember/string"], function (_exports, _route, _service, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.RedirectToNamedDashboardRoute = RedirectToNamedDashboardRoute;
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  // used both here and in funds/fund/dashboard/index.ts
  function RedirectToNamedDashboardRoute(args) {
    if (args.currentUser.stripeIsDelinquent) {
      args.transitionTo('funds.fund.dashboard.update-card');
    } else {
      var _args$model$goal;

      let goalType = ((_args$model$goal = args.model.goal) === null || _args$model$goal === void 0 ? void 0 : _args$model$goal.goalType) || 'no_goal'; // ***
      // duct tape fix for now

      if (goalType !== 'no_goal' && args.currentUser.organizationName.spo) {
        goalType = 'spo';
      } else if (goalType === 'ytd' && args.currentUser.organizationName.ruf) {
        goalType = 'ruf';
      } // ***


      args.transitionTo(`funds.fund.dashboard.${(0, _string.dasherize)(goalType)}`);
    }
  }

  let DashboardRoute = (_class = class DashboardRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "abilities", _descriptor, this);

      _initializerDefineProperty(this, "campaignService", _descriptor2, this);

      _initializerDefineProperty(this, "currentUser", _descriptor3, this);

      _initializerDefineProperty(this, "fundsService", _descriptor4, this);

      _initializerDefineProperty(this, "navbarSettings", _descriptor5, this);
    }

    beforeModel() {
      this.navbarSettings.title = 'Dashboard';
    }

    async model() {
      let fund = this.fundsService.selectedFund;
      let dashboard = await this.store.findRecord('dashboard', fund.id, {
        reload: true
      });

      if (dashboard !== null && dashboard !== void 0 && dashboard.hasActiveJournal && !this.currentUser.organizationName.yl) {
        this.navbarSettings.showActiveJournalButton = true;
      }

      if (this.abilities.hasAbility('import_ag_usa')) {
        this.navbarSettings.importButtonRoute = 'funds.fund.import.ag-usa';
      } else if (this.abilities.hasAbility('import_ag_wm')) {
        this.navbarSettings.importButtonRoute = 'funds.fund.import.ag-wm';
      }

      let campaignGoals = undefined;

      if (this.campaignService.campaignsEnabled) {
        campaignGoals = (await this.store.query('campaign-goal', {
          filter: {
            currently_active: true
          }
        })).toArray();
      }

      this.fundsService.dashboard = dashboard;
      this.fundsService.goal = dashboard.goal;
      return {
        fund,
        dashboard,
        goal: dashboard.goal,
        campaignGoals
      };
    } // need it here to handle the user manually entering a url for the wrong dashboard


    redirect(model) {
      RedirectToNamedDashboardRoute({
        model,
        currentUser: this.currentUser,
        transitionTo: this.transitionTo.bind(this)
      });
    }

    deactivate() {
      if (this.navbarSettings.importButtonRoute != null) {
        this.navbarSettings.importButtonRoute = undefined;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "abilities", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "campaignService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "navbarSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = DashboardRoute;
  ;
});