define("donor-elf-web/validations/contact", ["exports", "@ember/utils", "donor-elf-web/lib/object-utils"], function (_exports, _utils, _objectUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    name: (_key, newValue, _oldValue, _changes, content) => {
      if ((0, _utils.isEmpty)(newValue)) {
        return "Can't be blank";
      }

      if (content.anonymousDonor && newValue !== 'Anonymous Donors') {
        return "Can't change the name of 'Anonymous Donors' contact";
      }

      return true;
    },
    subscribeToNewsletter: (_key, newValue, _oldValue, changes, content) => {
      if (newValue) {
        if ((0, _utils.isEmpty)((0, _objectUtils.assignSingleProperty)(content, changes, 'address1')) || (0, _utils.isEmpty)((0, _objectUtils.assignSingleProperty)(content, changes, 'city')) || (0, _utils.isEmpty)((0, _objectUtils.assignSingleProperty)(content, changes, 'state')) || (0, _utils.isEmpty)((0, _objectUtils.assignSingleProperty)(content, changes, 'zip'))) {
          return "Can't subscribe to Newsletter when Street 1, City, State or Zip is blank";
        }
      }

      return true;
    }
  };
  _exports.default = _default;
});