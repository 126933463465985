define("donor-elf-web/templates/components/contact-duplicates", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "r4waK/FR",
    "block": "[[[11,0],[4,[38,0],[[30,0,[\"templateDidInsert\"]]],null],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"items\"]]],null]],null],null,[[[1,\"    \"],[8,[39,3],[[16,0,[29,[[52,[28,[37,5],[[30,2],[30,0,[\"items\"]]],null],\"mb-3\"]]]]],[[\"@contactDuplicate\",\"@onMerged\",\"@onNotMerged\",\"@onRecordDestroyedConfirmed\"],[[30,1],[30,0,[\"handleMerged\"]],[30,0,[\"handleNotMerged\"]],[28,[37,6],[[30,0,[\"handleRecordDestroyedConfirmed\"]],[30,1]],null]]],null],[1,\"\\n\"]],[1,2]],[[[1,\"    \"],[10,0],[14,0,\"text-muted font-weight-light\"],[12],[1,\"\\n      All the possible duplicates have been reviewed\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"contactDuplicate\",\"index\"],false,[\"did-insert\",\"each\",\"-track-array\",\"merge-contact\",\"if\",\"not-last-index\",\"fn\"]]",
    "moduleName": "donor-elf-web/templates/components/contact-duplicates.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});