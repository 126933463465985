define("donor-elf-web/helpers/has-validation-error", ["exports", "@ember/component/helper", "@ember/object"], function (_exports, _helper, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function hasValidationError([changeset, property]) {
    return (0, _object.get)(changeset, `error.${property}.validation`) != null;
  }

  var _default = (0, _helper.helper)(hasValidationError);

  _exports.default = _default;
});