define("donor-elf-web/components/contact/details/review-items", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@glimmer/tracking"], function (_exports, _component, _templateFactory, _component2, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Card ...attributes>
    <div class='d-flex border-bottom mb-2_5'>
      <h4 class='card-title'>Review Items</h4>
    </div>
    <div
      class='tw-max-h-96 overflow-auto pr-2 pb-2'
    >
    {{#each this.reviewItems as |reviewItem index|}}
      <div>
        <ReviewItem::Card
          @reviewItem={{reviewItem}}
          @onSave={{this.handleSave}}
          @classToApplyOnSave='tw-scale-0'
          class='d-inline-block tw-transition tw-transform tw-duration-700 {{if (not-last-index index this.reviewItems) 'mb-3'}}'
        />
      </div>
    {{else}}
      There are no items to review.
    {{/each}}
    </div>
  </Card>
  */
  {
    "id": "Twn9kbHM",
    "block": "[[[8,[39,0],[[17,1]],null,[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"d-flex border-bottom mb-2_5\"],[12],[1,\"\\n    \"],[10,\"h4\"],[14,0,\"card-title\"],[12],[1,\"Review Items\"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"tw-max-h-96 overflow-auto pr-2 pb-2\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"reviewItems\"]]],null]],null],null,[[[1,\"    \"],[10,0],[12],[1,\"\\n      \"],[8,[39,3],[[16,0,[29,[\"d-inline-block tw-transition tw-transform tw-duration-700 \",[52,[28,[37,5],[[30,3],[30,0,[\"reviewItems\"]]],null],\"mb-3\"]]]]],[[\"@reviewItem\",\"@onSave\",\"@classToApplyOnSave\"],[[30,2],[30,0,[\"handleSave\"]],\"tw-scale-0\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[2,3]],[[[1,\"    There are no items to review.\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]]]]]],[\"&attrs\",\"reviewItem\",\"index\"],false,[\"card\",\"each\",\"-track-array\",\"review-item/card\",\"if\",\"not-last-index\"]]",
    "moduleName": "donor-elf-web/components/contact/details/review-items.hbs",
    "isStrictMode": false
  });

  let ContactReviewItemsComponent = (_class = class ContactReviewItemsComponent extends _component2.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "reviewItems", _descriptor, this);

      this.reviewItems = this.args.model;
    }

    handleSave(item) {
      setTimeout(() => {
        this.reviewItems = this.reviewItems.filter(x => x.id !== item.id);
        this.args.onSave(item);
      }, 800);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "reviewItems", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "handleSave", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleSave"), _class.prototype)), _class);
  _exports.default = ContactReviewItemsComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ContactReviewItemsComponent);
});