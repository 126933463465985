define("donor-elf-web/templates/components/top-navbar-funds-index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "GhWrKTEz",
    "block": "[[[10,0],[14,0,\"flex-grow-1 d-flex\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"nav-item\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,0,\"btn btn-outline-brand\"]],[[\"@route\",\"@model\"],[\"funds.fund\",\"selected-fund\"]],[[\"default\"],[[[[1,\"\\n      Dashboard\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"link-to\"]]",
    "moduleName": "donor-elf-web/templates/components/top-navbar-funds-index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});