define("donor-elf-web/templates/admin/users/add/funds", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "6zfdsNiX",
    "block": "[[[10,0],[14,0,\"row\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"col-12 col-lg-6\"],[12],[1,\"\\n\"],[6,[39,0],null,null,[[\"default\"],[[[[1,\"      \"],[10,\"h4\"],[14,0,\"mb-4\"],[12],[1,\"Select Fund Permissions for \"],[1,[33,1,[\"changeset\",\"firstName\"]]],[1,\" \"],[1,[33,1,[\"changeset\",\"lastName\"]]],[13],[1,\"\\n\"],[41,[33,3],[[[1,\"        \"],[10,0],[14,0,\"alert alert-danger\"],[12],[1,\"\\n          \"],[1,[34,3]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[1,[28,[35,4],null,[[\"funds\",\"onSave\"],[[33,1,[\"funds\"]],[28,[37,5],[[30,0],\"save\"],null]]]]],[1,\"\\n\"]],[]]]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[],false,[\"de-card\",\"model\",\"if\",\"errorMessage\",\"admin-user-add-fund\",\"action\"]]",
    "moduleName": "donor-elf-web/templates/admin/users/add/funds.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});