define("donor-elf-web/templates/components/goal/ytd-and-expecting-chart", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "RQ8oRlIK",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"showBalance\"]],[[[1,\"    \"],[10,0],[14,0,\"d-flex mb-3 mb-md-4\"],[12],[1,\"\\n      \"],[10,\"h4\"],[14,0,\"mb-0\"],[12],[1,\"Balance\"],[13],[1,\"\\n      \"],[10,\"h4\"],[14,0,\"text-brand ml-2_5 mb-0\"],[12],[1,[28,[35,1],[[30,0,[\"fundsService\",\"selectedFund\",\"balance\"]]],[[\"removeNoCents\"],[true]]]],[13],[1,\"\\n      \"],[10,\"small\"],[14,0,\"text-muted font-weight-light ml-auto align-self-end\"],[12],[1,[28,[35,2],[[30,0,[\"fundsService\",\"selectedFund\"]]],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"d-flex mb-2\"],[12],[1,\"\\n    \"],[10,\"h5\"],[14,0,\"mb-0\"],[12],[1,\"Given & Expecting by Month\"],[13],[1,\"\\n\"],[41,[51,[30,0,[\"showBalance\"]]],[[[1,\"      \"],[10,\"small\"],[14,0,\"text-muted font-weight-light ml-auto align-self-end\"],[12],[1,[28,[35,2],[[30,0,[\"fundsService\",\"selectedFund\"]]],null]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n  \"],[11,0],[4,[38,4],[[30,0,[\"chartDivDidInsert\"]]],null],[12],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"if\",\"format-amount\",\"fund-last-updated-text\",\"unless\",\"did-insert\"]]",
    "moduleName": "donor-elf-web/templates/components/goal/ytd-and-expecting-chart.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});