define("donor-elf-web/helpers/not-last-index", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // {{not-last-index index array}}
  // returns boolean
  var _default = (0, _helper.helper)(function notLastIndex([index, array]
  /*, hash*/
  ) {
    return index < array.length - 1;
  });

  _exports.default = _default;
});