define("donor-elf-web/components/page-links", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "donor-elf-web/lib/document-utils"], function (_exports, _component, _tracking, _object, _documentUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PageLinksComponent = (_class = class PageLinksComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "pageVal", _descriptor, this);

      _defineProperty(this, "dropdown", void 0);
    }

    get arrowHotKeys() {
      return this.args.arrowHotKeys || false;
    }

    get isPrevDisabled() {
      return this.args.currentPage === 1;
    }

    get isNextDisabled() {
      return this.args.currentPage === this.args.totalPages;
    }

    templateDidInsert() {
      // have to wait until next cycle to give other instances a chance to call 'templateWillDestroy'
      setTimeout(() => {
        if (this.arrowHotKeys) {
          if (window.pageLinksHotKeysEnabled) {
            alert('<PageLinks @arrowHotKeys={{true}} /> can only be true for one ' + 'component instance on the page. There are multiple instances of ' + 'this component with hotkeys enabled');
            return;
          }

          document.addEventListener('keydown', this.handleDocumentKeyDown);
          window.pageLinksHotKeysEnabled = true;
        }
      });
    }

    templateWillDestroy() {
      if (this.arrowHotKeys) {
        document.removeEventListener('keydown', this.handleDocumentKeyDown);
        window.pageLinksHotKeysEnabled = false;
      }
    }

    handleDocumentKeyDown(e) {
      if (e.which === 37 && !this.isPrevDisabled && (0, _documentUtils.eventTargetNotInTextbox)(e)) {
        // Left arrow
        e.preventDefault();
        e.stopPropagation();
        this.changeToPrevPage();
      } else if (e.which === 39 && !this.isNextDisabled && (0, _documentUtils.eventTargetNotInTextbox)(e)) {
        // Right arrow
        e.preventDefault();
        e.stopPropagation();
        this.changeToNextPage();
      }
    }

    closeDropdown() {
      setTimeout(() => {
        var _this$dropdown, _this$dropdown$action;

        (_this$dropdown = this.dropdown) === null || _this$dropdown === void 0 ? void 0 : (_this$dropdown$action = _this$dropdown.actions) === null || _this$dropdown$action === void 0 ? void 0 : _this$dropdown$action.close();
      });
    }

    handleOnOpen(dropdown) {
      this.dropdown = dropdown;
      this.pageVal = '';
    }

    changeToPrevPage() {
      if (!this.isPrevDisabled) {
        this.args.onPageChange(this.args.currentPage - 1);
      }
    }

    changeToNextPage() {
      if (!this.isNextDisabled) {
        this.args.onPageChange(this.args.currentPage + 1);
      }
    }

    changeToPage(pageNumber) {
      this.args.onPageChange(pageNumber);
      this.closeDropdown();
    }

    handlePageValInput(e) {
      this.pageVal = e.target.value;
    }

    handleGoClicked() {
      let numberPageVal = Number(this.pageVal || '0');

      if (numberPageVal < 1 || numberPageVal > this.args.totalPages) {
        alert(`Invalid Page. Valid pages are 1 through ${this.args.totalPages}`);
        return;
      }

      this.changeToPage(numberPageVal);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "pageVal", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDocumentKeyDown", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleDocumentKeyDown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleOnOpen", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleOnOpen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeToPrevPage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeToPrevPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeToNextPage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeToNextPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeToPage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeToPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handlePageValInput", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handlePageValInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleGoClicked", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleGoClicked"), _class.prototype)), _class);
  _exports.default = PageLinksComponent;
});