define("donor-elf-web/components/filter/edit-date-between", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@glimmer/component", "donor-elf-web/lib/date-utils"], function (_exports, _component, _templateFactory, _object, _component2, _dateUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes>
    <div class='d-flex justify-content-between'>
      <div>
        <Form::Underline::Date
          @changeset={{@changeset}}
          @property='dateBetweenAfterVal'
          @showValidationErrors={{@showValidationErrors}}
          @onChange={{fn this.serializeDateChange 'dateBetweenAfterVal'}}
          {{focus-on-insert onlyIfBlank=true}}
        />
      </div>
      <div class='mx-2'>&amp;</div>
      <div>
        <Form::Underline::Date
          @changeset={{@changeset}}
          @property='dateBetweenBeforeVal'
          @showValidationErrors={{@showValidationErrors}}
          @onChange={{fn this.serializeDateChange 'dateBetweenBeforeVal'}}
        />
      </div>
    </div>
  </div>
  */
  {
    "id": "IhnXuWvp",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"d-flex justify-content-between\"],[12],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[8,[39,0],[[4,[38,2],null,[[\"onlyIfBlank\"],[true]]]],[[\"@changeset\",\"@property\",\"@showValidationErrors\",\"@onChange\"],[[30,2],\"dateBetweenAfterVal\",[30,3],[28,[37,1],[[30,0,[\"serializeDateChange\"]],\"dateBetweenAfterVal\"],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"mx-2\"],[12],[1,\"&\"],[13],[1,\"\\n    \"],[10,0],[12],[1,\"\\n      \"],[8,[39,0],null,[[\"@changeset\",\"@property\",\"@showValidationErrors\",\"@onChange\"],[[30,2],\"dateBetweenBeforeVal\",[30,3],[28,[37,1],[[30,0,[\"serializeDateChange\"]],\"dateBetweenBeforeVal\"],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@changeset\",\"@showValidationErrors\"],false,[\"form/underline/date\",\"fn\",\"focus-on-insert\"]]",
    "moduleName": "donor-elf-web/components/filter/edit-date-between.hbs",
    "isStrictMode": false
  });

  let FilterEditDateBetweenComponent = (_class = class FilterEditDateBetweenComponent extends _component2.default {
    constructor(owner, args) {
      super(owner, args);
      let {
        changeset
      } = this.args;
      let vals = changeset.get('val') || [];
      changeset.set('dateBetweenAfterVal', vals[0]);
      changeset.set('dateBetweenBeforeVal', vals[1]);
    }

    serializeDateChange(propertyName, val) {
      if (val && typeof val === 'object' && typeof val.getDate === 'function') {
        let serializedVal = _dateUtils.default.serializeToDateOnly(val);

        this.args.changeset.set(propertyName, serializedVal);
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "serializeDateChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "serializeDateChange"), _class.prototype)), _class);
  _exports.default = FilterEditDateBetweenComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FilterEditDateBetweenComponent);
});