define("donor-elf-web/components/form/underline/checkbox", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@ember/utils"], function (_exports, _component, _templateFactory, _component2, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes>
    <div class='form-check border-bottom'>
      <Input
        checked={{get @changeset @property}}
        type='checkbox'
        class='form-check-input'
        tabindex={{@tabindex}}
        {{! template-lint-disable no-inline-styles }}
        style='margin-top: 0.4rem'
        {{on 'change' (input-is-checked this.handleChange)}}
      />
      <label class='form-check-label font-weight-light'>
        {{@label}}
      </label>
    </div>
    {{#if this.showError}}
      <div class='text-danger tw-text-sm'>
        {{this.errorMessage}}
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "bL91M13c",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"form-check border-bottom\"],[12],[1,\"\\n    \"],[8,[39,0],[[16,\"checked\",[28,[37,1],[[30,2],[30,3]],null]],[24,0,\"form-check-input\"],[16,\"tabindex\",[30,4]],[24,5,\"margin-top: 0.4rem\"],[24,4,\"checkbox\"],[4,[38,2],[\"change\",[28,[37,3],[[30,0,[\"handleChange\"]]],null]],null]],null,null],[1,\"\\n    \"],[10,\"label\"],[14,0,\"form-check-label font-weight-light\"],[12],[1,\"\\n      \"],[1,[30,5]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"showError\"]],[[[1,\"    \"],[10,0],[14,0,\"text-danger tw-text-sm\"],[12],[1,\"\\n      \"],[1,[30,0,[\"errorMessage\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"&attrs\",\"@changeset\",\"@property\",\"@tabindex\",\"@label\"],false,[\"input\",\"get\",\"on\",\"input-is-checked\",\"if\"]]",
    "moduleName": "donor-elf-web/components/form/underline/checkbox.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (_class = class extends _component2.default {
    handleChange(checkedVal) {
      var _this$args$onChange, _this$args;

      this.args.changeset.set(this.args.property, checkedVal);
      (_this$args$onChange = (_this$args = this.args).onChange) === null || _this$args$onChange === void 0 ? void 0 : _this$args$onChange.call(_this$args, checkedVal);
    }

    get errorMessage() {
      var _this$args$changeset$, _this$args$changeset$2;

      let validationErrors = ((_this$args$changeset$ = this.args.changeset.get('error')) === null || _this$args$changeset$ === void 0 ? void 0 : (_this$args$changeset$2 = _this$args$changeset$[this.args.property]) === null || _this$args$changeset$2 === void 0 ? void 0 : _this$args$changeset$2.validation) || [];
      return validationErrors.join(', ');
    }

    get showError() {
      return this.args.showValidationErrors && (0, _utils.isPresent)(this.errorMessage);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "handleChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleChange"), _class.prototype)), _class));

  _exports.default = _default;
});