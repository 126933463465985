define("donor-elf-web/components/generic-error", ["exports", "@glimmer/component", "@ember/object", "donor-elf-web/lib/logger"], function (_exports, _component, _object, _logger) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  let GenericErrorComponent = (_class = class GenericErrorComponent extends _component.default {
    get useLeftBorderCard() {
      return this.args.useLeftBorderCard ?? false;
    }

    templateDidInsert() {
      if (this.args.error) {
        _logger.default.error(this.args.error);
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype)), _class);
  _exports.default = GenericErrorComponent;
});