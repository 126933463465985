define("donor-elf-web/services/abilities", ["exports", "@glimmer/tracking", "rsvp", "@ember/service"], function (_exports, _tracking, _rsvp, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AbilitiesService = (_class = class AbilitiesService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "abilities", _descriptor3, this);

      _initializerDefineProperty(this, "coachingAbilities", _descriptor4, this);

      _defineProperty(this, "_loadForFundPromise", {});

      _defineProperty(this, "_loadAllFundsPromise", void 0);

      _defineProperty(this, "_loadCoachingAbilitiesCache", {});
    }

    hasAbility(abilityName) {
      return this.abilities[abilityName] || false;
    }

    noAbility(abilityName) {
      return !this.hasAbility(abilityName);
    }

    hasCoachingAbility(abilityName) {
      return this.coachingAbilities[abilityName] || false;
    }

    noCoachingAbility(abilityName) {
      return !this.hasCoachingAbility(abilityName);
    }

    loadForFund(fundId) {
      if (!this.session.isAuthenticated) {
        return Promise.resolve();
      }

      if (this._loadForFundPromise[fundId] != null) {
        return this._loadForFundPromise[fundId].then(abilityModel => {
          // always update this.abilities in case the user came from another fund
          this.abilities = abilityModel.abilities;
          return undefined;
        });
      }

      this._loadForFundPromise[fundId] = this.store.query('ability', {}).then(records => {
        let abilityModel = records.toArray()[0];
        this.abilities = abilityModel.abilities;
        return abilityModel;
      });
      return this._loadForFundPromise[fundId].then(() => {
        return undefined;
      });
    }

    loadForAllFunds() {
      if (!this.session.isAuthenticated) {
        return Promise.resolve();
      }

      if (this._loadAllFundsPromise) {
        return this._loadAllFundsPromise.then(abilityModel => {
          // always update this.abilities in case the user came from a single fund
          this.abilities = abilityModel.abilities;
          return undefined;
        });
      }

      this._loadAllFundsPromise = this.store.query('all-funds/ability', {}).then(records => {
        let abilityModel = records.toArray()[0];
        this.abilities = abilityModel.abilities;
        return abilityModel;
      });
      return this._loadAllFundsPromise.then(() => {
        return undefined;
      });
    } // { individualId: { promise, record } }
    // individualId of -1 represents generic abilities not tied to any specific individual


    loadCoachingAbilities({
      individualId
    } = {}) {
      if (!this.session.isAuthenticated) {
        return _rsvp.default.resolve();
      }

      if (individualId == null) {
        individualId = -1;
      }

      let cache = this._loadCoachingAbilitiesCache[individualId];

      if (cache != null) {
        this._updateCoachingAbilitiesFromRecord(cache.record);

        return cache.promise;
      } // @ts-ignore


      cache = {};
      cache.promise = this.store.findRecord('coaching/coaching-ability', individualId).then(record => {
        cache.record = record;

        this._updateCoachingAbilitiesFromRecord(record);
      });
      this._loadCoachingAbilitiesCache[individualId] = cache;
      return cache.promise;
    }

    _updateCoachingAbilitiesFromRecord(record) {
      this.coachingAbilities = record.abilities || {};
    }

    unload() {
      this.abilities = {};
      this.coachingAbilities = {};
      this._loadPromise = undefined;
      this._loadCoachingAbilitiesCache = {};
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "abilities", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "coachingAbilities", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  })), _class);
  _exports.default = AbilitiesService;
});