define("donor-elf-web/routes/funds/fund/ytd-given-expecting", ["exports", "@ember/routing/route", "@ember/service", "donor-elf-web/lib/array-utils", "@ember/object"], function (_exports, _route, _service, _arrayUtils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let YtdGivenExpectingRoute = (_class = class YtdGivenExpectingRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "contactsCache", _descriptor, this);

      _initializerDefineProperty(this, "fundsService", _descriptor2, this);

      _initializerDefineProperty(this, "navbarSettings", _descriptor3, this);

      _initializerDefineProperty(this, "store", _descriptor4, this);
    }

    beforeModel() {
      this.navbarSettings.title = 'Given and Expecting';
    }

    async model() {
      var _pledgeSections$expec, _pledgeSections$no_gi, _pledgeSections$overd, _pledgeSections$overd2;

      let givenExpectedReport = await this.store.findRecord('ytd-given-expected-report', this.fundsService.selectedFund.id);

      if (givenExpectedReport.ytdData == null) {
        givenExpectedReport.ytdData = {};
      }

      let pledgeSections = givenExpectedReport.ytdData.pledges || {};
      let expectedPledgeIds = ((_pledgeSections$expec = pledgeSections.expected) === null || _pledgeSections$expec === void 0 ? void 0 : _pledgeSections$expec.mapBy('pledge_id')) || [];
      let noGiftsPledgeIds = ((_pledgeSections$no_gi = pledgeSections.no_gifts) === null || _pledgeSections$no_gi === void 0 ? void 0 : _pledgeSections$no_gi.mapBy('pledge_id')) || [];
      let overduePledgeIds = ((_pledgeSections$overd = pledgeSections.overdue) === null || _pledgeSections$overd === void 0 ? void 0 : _pledgeSections$overd.mapBy('pledge_id')) || [];
      let pledgeIds = [...expectedPledgeIds, ...noGiftsPledgeIds, ...overduePledgeIds];
      let pledges = [];

      if (pledgeIds.length > 0) {
        pledges = (await this.store.query('pledge', {
          filter: {
            id: pledgeIds
          }
        })).toArray();
      }

      let pledgesById = {};
      let pledgesByContactId = {};
      pledges.forEach(pledge => {
        pledgesById[pledge.id] = pledge;

        if (pledgesByContactId[pledge.contactId] == null) {
          pledgesByContactId[pledge.contactId] = [];
        }

        pledgesByContactId[pledge.contactId].push(pledge);
      });
      let expectedItemsByContactId = {};

      if (pledgeSections.expected) {
        pledgeSections.expected.forEach(item => {
          let pledge = pledgesById[item.pledge_id];
          item.pledge = pledge;

          if (expectedItemsByContactId[pledge.contactId] == null) {
            expectedItemsByContactId[pledge.contactId] = [];
          }

          expectedItemsByContactId[pledge.contactId].push(item);
        });
      }

      let contactExpectedItems = [];
      Object.keys(expectedItemsByContactId).forEach(contactId => {
        let items = expectedItemsByContactId[contactId] || [];
        contactExpectedItems.push({
          contact: this.contactsCache.contactsById[contactId],
          contactId,
          expectedItems: (0, _arrayUtils.sortByProperties)(items, ['expected_amount:desc']),
          totalExpecting: items.map(x => x.expected_amount).reduce((x, y) => x + y, 0)
        });
      });
      let noGiftItemsByContactId = {};

      if (pledgeSections.no_gifts) {
        pledgeSections.no_gifts.forEach(item => {
          let pledge = pledgesById[item.pledge_id];
          item.pledge = pledge;

          if (noGiftItemsByContactId[pledge.contactId] == null) {
            noGiftItemsByContactId[pledge.contactId] = [];
          }

          noGiftItemsByContactId[pledge.contactId].push(item);
        });
      }

      let contactNoGiftItems = [];
      Object.keys(noGiftItemsByContactId).forEach(contactId => {
        let items = noGiftItemsByContactId[contactId] || [];
        contactNoGiftItems.push({
          contact: this.contactsCache.contactsById[contactId],
          contactId,
          items,
          totalExpecting: items.map(x => x.expected_amount).reduce((x, y) => x + y, 0)
        });
      });
      let overdueItemsByContactId = {};
      (_pledgeSections$overd2 = pledgeSections.overdue) === null || _pledgeSections$overd2 === void 0 ? void 0 : _pledgeSections$overd2.forEach(item => {
        let pledge = pledgesById[item.pledge_id];
        item.pledge = pledge;

        if (overdueItemsByContactId[pledge.contactId] == null) {
          overdueItemsByContactId[pledge.contactId] = [];
        }

        overdueItemsByContactId[pledge.contactId].push(item);
      });
      let contactOverdueItems = [];
      Object.keys(overdueItemsByContactId).forEach(contactId => {
        let items = overdueItemsByContactId[contactId] || [];
        contactOverdueItems.push({
          contact: this.contactsCache.contactsById[contactId],
          contactId,
          items,
          totalExpecting: items.map(x => x.expected_amount).reduce((x, y) => x + y, 0)
        });
      });
      return {
        givenExpectedReport,
        contactExpectedItems,
        contactNoGiftItems,
        contactOverdueItems
      };
    }

    refreshYtdGivenExpectingRoute() {
      this.refresh();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "contactsCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "navbarSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "refreshYtdGivenExpectingRoute", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "refreshYtdGivenExpectingRoute"), _class.prototype)), _class);
  _exports.default = YtdGivenExpectingRoute;
});