define("donor-elf-web/components/navbar-search-contacts-contact", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // attrs
  // - contact
  var _default = _component.default.extend({
    classNames: ['navbar-search-contacts-contact'],
    contact: null
  });

  _exports.default = _default;
});