define("donor-elf-web/templates/funds/fund/dashboard/cef", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "EfdaJWUI",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"col-12 col-lg-5 order-lg-2 mb-3 mb-lg-0\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@goal\",\"@fund\"],[[30,1,[\"goal\"]],[30,1,[\"fund\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"col-12 col-lg-7 order-lg-1 mb-4 mb-sm-0\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"d-flex mb-0 mb-sm-3\"],[12],[1,\"\\n        \"],[10,\"h4\"],[12],[1,\"Balance:\"],[13],[1,\"\\n        \"],[10,\"h4\"],[14,0,\"text-warning ml-2_5\"],[12],[1,[28,[35,2],[[30,1,[\"goal\",\"goalData\",\"program_balances\",\"total\"]]],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"h5\"],[14,0,\"mb-1\"],[12],[1,\"Program Balances\"],[13],[1,\"\\n      \"],[10,\"table\"],[14,0,\"table table-sm mt-0 border-bottom\"],[12],[1,\"\\n        \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,1,[\"goal\",\"goalData\",\"program_balances\",\"programs\"]]],null]],null],null,[[[1,\"            \"],[10,\"tr\"],[12],[1,\"\\n              \"],[10,\"td\"],[12],[1,[30,2,[\"name\"]]],[13],[1,\"\\n              \"],[10,\"td\"],[14,0,\"text-right\"],[12],[1,[28,[35,2],[[30,2,[\"balance\"]]],null]],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[2]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@model\",\"program\"],false,[\"card\",\"goal/balance-and-monthly-gifts-chart\",\"format-amount\",\"each\",\"-track-array\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/dashboard/cef.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});