define("donor-elf-web/routes/admin/users/show/funds/add", ["exports", "@ember/routing/route", "@ember/object", "rsvp", "@ember/service"], function (_exports, _route, _object, _rsvp, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    fundsService: (0, _service.inject)(),
    storeCache: (0, _service.inject)(),

    beforeModel() {
      return (0, _object.get)(this, 'fundsService').confirmValidFundToken();
    },

    model() {
      let user = this.modelFor('admin.users.show');
      let userId = (0, _object.get)(user, 'id');
      let storeCache = (0, _object.get)(this, 'storeCache');

      let promise = _rsvp.default.hash({
        funds: storeCache.findAll('admin/fund'),
        userFunds: this.store.query('admin/user-fund', {
          filter: {
            user_id: userId
          }
        })
      });

      return promise.then(data => {
        return {
          user: user,
          funds: data.funds,
          userFunds: data.userFunds
        };
      });
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.reset();
      }
    }

  });

  _exports.default = _default;
});