define("donor-elf-web/helpers/coach-journal-cell-classes", ["exports", "@ember/string", "@ember/component/helper"], function (_exports, _string, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _helper.default.extend({
    /*
      namedArgs
        - columnName
        - columnStageIndex
        - currentStageIndex
    */
    compute(args, {
      columnName,
      columnStageIndex,
      currentStageIndex
    }) {
      if (columnName === 'Contact') {
        return null;
      }

      if (currentStageIndex == null || columnStageIndex > currentStageIndex) {
        return (0, _string.htmlSafe)('bg-light');
      }
    }

  });

  _exports.default = _default;
});