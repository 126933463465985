define("donor-elf-web/components/coaching-format-field-percent", ["exports", "@ember/component", "@ember/object", "@ember/service", "@ember/object/computed", "@ember/utils"], function (_exports, _component, _object, _service, _computed, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* attrs
    - field (coaching/individual sectionField)
    - report - the coach report
    - individual - the coach individual record
    - propertyName
    - goalType - interval|overall  - overall is default
  */
  var _default = _component.default.extend({
    tagName: 'span',
    coachingService: (0, _service.inject)(),
    field: null,
    report: null,
    individual: null,
    propertyName: null,
    goalType: 'overall',
    percent: (0, _object.computed)('field.id', 'report.id', 'propertyName', 'goalType', function () {
      let {
        field,
        report,
        individual,
        propertyName,
        goalType,
        coachingService
      } = (0, _object.getProperties)(this, 'field', 'report', 'individual', 'propertyName', 'goalType', 'coachingService');
      let val = goalType === 'interval' ? coachingService.getFieldValueDiff(field, report, individual, {
        propertyName
      }) : coachingService.getFieldValue(field, report, {
        propertyName
      });

      if ((0, _utils.isEmpty)(val)) {
        return null;
      }

      let goal = goalType === 'interval' ? (0, _object.get)(field, 'intervalGoal.low') : field.overallGoal;
      return val * 100 / goal;
    }),
    percentIsEmpty: (0, _computed.empty)('percent')
  });

  _exports.default = _default;
});