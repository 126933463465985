define("donor-elf-web/routes/organization/donors/donor/pledge", ["exports", "@ember/routing/route", "@ember/service", "@ember/object", "ember-changeset-validations", "ember-changeset", "ember-changeset-validations/validators", "@ember/utils"], function (_exports, _route, _service, _object, _emberChangesetValidations, _emberChangeset, _validators, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const createPledgeValidation = function ({
    record,
    existingPledges
  }) {
    let recordFundId = (0, _object.get)(record, 'fundId');
    let fundIdsOfOtherPledges = existingPledges.filter(pledge => (0, _object.get)(pledge, 'fundId') !== recordFundId).map(pledge => (0, _object.get)(pledge, 'fundId'));
    return {
      fundId(key, newValue
      /*oldValue, changes, content*/
      ) {
        if ((0, _utils.isEmpty)(newValue)) {
          return "Fund can't be blank";
        }

        if (fundIdsOfOtherPledges.includes(newValue)) {
          return "Another pledge already exists for this fund";
        }

        return true;
      },

      startDate: (0, _validators.validatePresence)({
        presence: true,
        message: "Start Date can't be blank"
      }),
      frequency: (0, _validators.validatePresence)({
        presence: true,
        message: "Frequency can't be blank"
      }),
      amount: (0, _validators.validateNumber)()
    };
  };

  var _default = _route.default.extend({
    navbarSettings: (0, _service.inject)(),
    storeCache: (0, _service.inject)(),
    store: (0, _service.inject)(),

    model(params) {
      let entityDetails = this.modelFor('organization.donors.donor');
      (0, _object.set)(this, 'navbarSettings.title', (0, _object.get)(entityDetails, 'entity.name'));
      let record = {};

      if (params.pledgeId === 'add') {
        record = (0, _object.get)(this, 'store').createRecord('organization/pledge', {
          entityId: (0, _object.get)(entityDetails, 'entity.id')
        });
      } else {
        record = (0, _object.get)(entityDetails, 'pledges').find(x => (0, _object.get)(x, 'id') === params.pledgeId);
      }

      let validation = createPledgeValidation({
        record,
        existingPledges: (0, _object.get)(entityDetails, 'pledges')
      });
      let changeset = new _emberChangeset.default(record, (0, _emberChangesetValidations.default)(validation), validation);
      return (0, _object.get)(this, 'storeCache').findAll('organization/fund').then(funds => {
        return {
          entityDetails,
          funds,
          record,
          changeset
        };
      });
    }

  });

  _exports.default = _default;
});