define("donor-elf-web/models/s3-upload", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    fileName: attr('string'),
    // desired file name. this needs to be set when creating an s3Upload
    key: attr('string'),
    bucket: attr('string'),
    accessKey: attr('string'),
    policy: attr('string'),
    signature: attr('string')
  });

  _exports.default = _default;
});