define("donor-elf-web/components/form/underline/label", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/utils"], function (_exports, _component, _templateFactory, _component2, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes>
    <div
      class='font-weight-light tw-text-xs -tw-mb-2px {{this.textClass}}'
    >
      {{@label}}
    </div>
    {{yield}}
  </div>
  */
  {
    "id": "IER5ryNf",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[\"font-weight-light tw-text-xs -tw-mb-2px \",[30,0,[\"textClass\"]]]]],[12],[1,\"\\n    \"],[1,[30,2]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[18,3,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@label\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "donor-elf-web/components/form/underline/label.hbs",
    "isStrictMode": false
  });

  /*
    args
      - label
      - textMuted - default true
      - textDanger - default false
        - takes precedence over @textMuted
  */
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, class extends _component2.default {
    get textClass() {
      if (this.args.textDanger) {
        return 'text-danger';
      }

      if ((0, _utils.isEmpty)(this.args.textMuted) || this.args.textMuted) {
        return 'text-muted';
      }
    }

  });

  _exports.default = _default;
});