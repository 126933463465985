define("donor-elf-web/services/expiring-memory-cache", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class ExpiringMemoryCache extends _service.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "cache", {});
    }

    fetch(args) {
      let cacheItem = this.cache[args.key];

      if (cacheItem && cacheItem.expiresAt > new Date()) {
        return cacheItem.valuePromise.then(value => {
          return Promise.resolve({
            value,
            fromCache: true
          });
        });
      }

      cacheItem = this.cache[args.key] = {
        expiresAt: new Date(new Date().getTime() + args.expiresInMinutes * 60000),
        valuePromise: args.loadValue()
      };
      return cacheItem.valuePromise.then(value => {
        return Promise.resolve({
          value,
          fromCache: false
        });
      });
    }

    remove(key) {
      delete this.cache[key];
    }

    removeAll() {
      this.cache = {};
    }

  }

  _exports.default = ExpiringMemoryCache;
});