define("donor-elf-web/routes/admin/funds/fund/users/delete", ["exports", "@ember/routing/route", "@ember/object", "rsvp", "@ember/service"], function (_exports, _route, _object, _rsvp, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    fundsService: (0, _service.inject)(),
    storeCache: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),

    beforeModel() {
      (0, _object.set)(this, 'navbarSettings.title', 'Remove Fund Permission');
      return (0, _object.get)(this, 'fundsService').confirmValidFundToken();
    },

    model(params) {
      let fundModel = this.modelFor('admin.funds.fund');
      let fund = (0, _object.get)(fundModel, 'record');
      let user = (0, _object.get)(fundModel, 'users').find(user => {
        return (0, _object.get)(user, 'id') === params.userId;
      });
      let storeCache = (0, _object.get)(this, 'storeCache');

      let promise = _rsvp.default.hash({
        indexUserFundsByUserId: storeCache.indexMultipleBy('admin/user-fund', 'userId')
      });

      return promise.then(data => {
        let userFundPermissions = data.indexUserFundsByUserId[(0, _object.get)(user, 'id')];
        let fundPermission = userFundPermissions.find(fundPermission => {
          return (0, _object.get)(fundPermission, 'fundId') === (0, _object.get)(fund, 'id');
        });
        return {
          fund: fund,
          user: user,
          fundPermission: fundPermission,
          userFundPermissionsCount: userFundPermissions.length
        };
      });
    }

  });

  _exports.default = _default;
});