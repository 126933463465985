define("donor-elf-web/templates/components/handle-404-error", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "1ZzQSFs9",
    "block": "[[[41,[33,1],[[[1,\"  \"],[10,\"h3\"],[14,0,\"mb-4\"],[12],[1,\"Can't find \"],[1,[28,[35,2],[[33,3]],null]],[13],[1,\"\\n  \"],[10,0],[14,0,\"alert alert-danger\"],[12],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      Whoops, it looks like that \"],[1,[34,3]],[1,\" no longer exists. Did you delete\\n      it?\\n    \"],[13],[1,\"\\n    \"],[10,2],[14,0,\"mb-0\"],[12],[1,\"\\n      Please email us at \"],[10,3],[14,6,\"mailto:support@donorelf.com\"],[12],[1,\"support@donorelf.com\"],[13],[1,\" if you think\\n      this error should not have occurred. We'll look into it for you.\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[6,[39,4],null,[[\"class\",\"route\"],[\"btn btn-outline-brand\",\"funds.fund.dashboard\"]],[[\"default\"],[[[[1,\"Go to the Dashboard\"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,\"h3\"],[14,0,\"mb-4\"],[12],[1,\"Error Message\"],[13],[1,\"\\n  \"],[10,0],[14,0,\"alert alert-danger\"],[12],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      Whoops! An error just happened. Sorry about that!\\n    \"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      Can you email us at \"],[10,3],[14,6,\"mailto:support@donorelf.com\"],[12],[1,\"support@donorelf.com\"],[13],[1,\" and let us know\\n      what you were doing when you received this error. We'll look into it and get\\n      it fixed for you.\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    And a lot of times if you just refresh your browser the error will go away.\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"mb-3\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-outline-brand\"],[4,[38,5],[[30,0],\"refreshBrowser\"],null],[12],[1,\"Try refreshing my browser\"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[6,[39,4],null,[[\"class\",\"route\"],[\"btn btn-outline-brand\",\"funds.fund.dashboard\"]],[[\"default\"],[[[[1,\"Go to the Dashboard\"]],[]]]]],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"is404Error\",\"to-capitalize\",\"modelLabel\",\"link-to\",\"action\"]]",
    "moduleName": "donor-elf-web/templates/components/handle-404-error.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});