define("donor-elf-web/routes/funds/fund/tasks/v2/by-contact", ["exports", "@ember/routing/route", "@ember/object", "@ember/service", "@glimmer/tracking", "@ember/utils"], function (_exports, _route, _object, _service, _tracking, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _class3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let TasksByContactItem = (_class = class TasksByContactItem {
    constructor({
      contact,
      additionalCompletedTasksCount
    }) {
      _defineProperty(this, "contact", void 0);

      _defineProperty(this, "lastCompleted", null);

      _initializerDefineProperty(this, "recentCompleted", _descriptor, this);

      _initializerDefineProperty(this, "notComplete", _descriptor2, this);

      _initializerDefineProperty(this, "additionalCompletedTasksCount", _descriptor3, this);

      this.contact = contact;
      this.additionalCompletedTasksCount = additionalCompletedTasksCount;
    }

    get allTasks() {
      return this.recentCompleted.concat(this.notComplete);
    }

    afterTaskSaved() {
      let {
        allTasks
      } = this;
      this.recentCompleted = allTasks.filter(task => (0, _utils.isPresent)(task.completedDate)).sort((a, b) => (0, _utils.compare)(b.completedDate, a.completedDate));
      this.lastCompleted = this.recentCompleted[0];
      this.notComplete = allTasks.filter(task => (0, _utils.isEmpty)(task.completedDate)).sort((a, b) => {
        if ((0, _utils.isEmpty)(a.dueDate) || (0, _utils.isEmpty)(b.dueDate)) {
          return -1;
        }

        return (0, _utils.compare)(a.dueDate, b.dueDate);
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "recentCompleted", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notComplete", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "additionalCompletedTasksCount", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  })), _class);
  let TasksByContactRoute = (_class3 = class TasksByContactRoute extends _route.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "contactsCache", _descriptor4, this);

      _initializerDefineProperty(this, "navbarSettings", _descriptor5, this);

      _initializerDefineProperty(this, "store", _descriptor6, this);

      _initializerDefineProperty(this, "taskService", _descriptor7, this);
    }

    beforeModel() {
      (0, _object.set)(this, 'navbarSettings.title', 'Tasks by Contact');
    }

    async model() {
      await this.taskService.load();
      let recentCompletedTasks = await this.store.query('v2-task', {
        filter: {
          recent_completed_for_each_contact: true,
          fund_ids: ['selected']
        }
      });
      let additionalCompletedTasksCountByContactId = recentCompletedTasks.meta.additional_completed_tasks_count_by_contact_id;
      let notCompletedTasks = await this.store.query('v2-task', {
        filter: {
          completed: 'Not Complete',
          fund_ids: ['selected']
        },
        sort: 'due_date'
      });
      let itemsByContactId = {};
      this.contactsCache.contacts.forEach(contact => {
        itemsByContactId[contact.id] = new TasksByContactItem({
          contact,
          additionalCompletedTasksCount: additionalCompletedTasksCountByContactId[contact.id]
        });
      });
      recentCompletedTasks.toArray().forEach(task => {
        if (itemsByContactId[task.contactId]) {
          itemsByContactId[task.contactId].recentCompleted.push(task);

          if (itemsByContactId[task.contactId].lastCompleted == null) {
            itemsByContactId[task.contactId].lastCompleted = task;
          }
        }
      });
      notCompletedTasks.toArray().forEach(task => {
        if (itemsByContactId[task.contactId]) {
          itemsByContactId[task.contactId].notComplete.push(task);
        }
      });
      let contactItems = [];
      this.contactsCache.contacts.forEach(contact => {
        contactItems.push(itemsByContactId[contact.id]);
      });
      return {
        contactItems
      };
    }

  }, (_descriptor4 = _applyDecoratedDescriptor(_class3.prototype, "contactsCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class3.prototype, "navbarSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class3.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class3.prototype, "taskService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class3);
  _exports.default = TasksByContactRoute;
});