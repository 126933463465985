define("donor-elf-web/templates/components/goal/no-goal", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Hw8k7LeG",
    "block": "[[[10,0],[14,0,\"col\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"card de-card de-card-no-border-sm-down p-0 p-md-3\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col-12 col-lg-4 d-flex align-items-center justify-content-center\"],[12],[1,\"\\n\"],[41,[28,[37,1],[\"edit_goal\"],null],[[[6,[39,2],null,[[\"class\",\"route\"],[\"btn btn-lg btn-warning mb-4\",\"funds.fund.goal.create\"]],[[\"default\"],[[[[1,\"            Create a Goal\\n\"]],[]]]]]],[]],null],[1,\"      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"col-12 col-lg-8 mb-3 mb-lg-0\"],[12],[1,\"\\n        \"],[8,[39,3],null,[[\"@goal\",\"@fund\"],[[30,1],[30,2]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@goal\",\"@fund\"],false,[\"if\",\"has-ability\",\"link-to\",\"goal/balance-and-monthly-gifts-chart\"]]",
    "moduleName": "donor-elf-web/templates/components/goal/no-goal.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});