define("donor-elf-web/services/funds-service", ["exports", "rsvp", "@ember/service", "@ember/debug", "donor-elf-web/config/environment", "@glimmer/tracking", "donor-elf-web/lib/store-utils"], function (_exports, _rsvp, _service, _debug, _environment, _tracking, _storeUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.AllFundsInfo = _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  let FundsService = (_class = class FundsService extends _service.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "currentUser", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "selectedFund", _descriptor3, this);

      _initializerDefineProperty(this, "selectedFundToken", _descriptor4, this);

      _initializerDefineProperty(this, "dashboard", _descriptor5, this);

      _initializerDefineProperty(this, "goal", _descriptor6, this);

      _defineProperty(this, "_getAllFundsPromise", void 0);

      _defineProperty(this, "_getAllFundsPromiseFulfilled", false);

      _defineProperty(this, "_allFundsInfoPromise", void 0);
    }

    async populateSelectedFundToken() {
      (false && !(this.selectedFund != null) && (0, _debug.assert)('this.selectedFund must be set before calling this method', this.selectedFund != null));
      let record = await this.store.findRecord('selected-fund-token', this.selectedFund.id);
      let token = record.token;

      if (token == null) {
        token = '';
      }

      this.selectedFundToken = token;
    }

    viewFund(fundId, options = {}) {
      window.location.assign(this.viewFundUrl(fundId, options));
    } // used for manually setting the href on an anchor tag to open a new tab


    viewFundUrl(fundId, options = {}) {
      let newLocation = `${_environment.default.routerRootURL}funds/${fundId}`;

      if (options.urlPath) {
        newLocation = `${newLocation}/${options.urlPath}`;
      }

      return newLocation;
    } // this caches the response


    getAllFunds(args) {
      if (this._getAllFundsPromise) {
        return this._getAllFundsPromise;
      }

      let queryOptions = {};

      if ((args === null || args === void 0 ? void 0 : args.filter) != null) {
        queryOptions.filter = args.filter;
      }

      this._getAllFundsPromise = (0, _storeUtils.queryWithPagesAndLocalCache)({
        store: this.store,
        modelName: 'fund',
        pageSize: 250,
        queryOptions,
        cacheKey: `funds-${this.currentUser.id}`,
        cacheLocalWhenRecordLengthGreaterThan: 500,
        onRecordsFromServer: serverArgs => {
          var _args$onRecordsFromSe;

          args === null || args === void 0 ? void 0 : (_args$onRecordsFromSe = args.onRecordsFromServer) === null || _args$onRecordsFromSe === void 0 ? void 0 : _args$onRecordsFromSe.call(args, {
            records: serverArgs.records
          });
        }
      });

      this._getAllFundsPromise.then(() => {
        this._getAllFundsPromiseFulfilled = true;
      });

      return this._getAllFundsPromise;
    } // this uses the cached response from `getAllFunds` above if it exists
    // otherwise it gets the data from the server immediately
    // This is not meant to be called with more than 50 or so fundIds


    getFunds(fundIds) {
      if (this._getAllFundsPromiseFulfilled) {
        return this._getAllFundsPromise.then(fundModels => {
          return fundModels.filter(x => fundIds.indexOf(x.id) >= 0);
        });
      } else {
        return (0, _storeUtils.queryWithPages)({
          store: this.store,
          modelName: 'fund',
          queryOptions: {
            filter: {
              id: fundIds
            }
          }
        });
      }
    }

    getAllFundsInfo() {
      if (this._allFundsInfoPromise) {
        return this._allFundsInfoPromise;
      }

      let allFunds = new AllFundsInfo();
      return this._allFundsInfoPromise = new Promise(resolve => {
        this.getAllFunds({
          onRecordsFromServer: args => {
            allFunds._handleRecordsFromServer(args.records);
          }
        }).then(funds => {
          allFunds._populateFunds(funds);

          resolve(allFunds);
        });
      });
    } // todo: this shouldn't be needed anymore


    unload() {
      this.selectedFund = undefined;
      this.selectedFundToken = '';
      this.dashboard = undefined;
      this.goal = undefined;
    } // todo: remove all calls to this and delete this function


    refreshSelectedFundToken() {
      // no longer need this
      return _rsvp.default.resolve(); // let selectedFundPromise = null;
      // if (this.selectedFund) {
      //   selectedFundPromise = RSVP.resolve(this.selectedFund);
      // } else {
      //   selectedFundPromise = this.store
      //     .findRecord('fund', this.currentUser.selectedFundId).then(fund => {
      //     return this.selectedFund = fund;
      //   });
      // }
      // return selectedFundPromise.then(selectedFund => {
      //   return this.store.findRecord('selected-fund-token', selectedFund.id).then(record => {
      //     let token = record.token;
      //     if (token == null) {
      //       token = '';
      //     }
      //     this.setNewFundToken(token);
      //   });
      // });
    } // todo: remove all calls to this and delete this function


    confirmValidFundToken() {
      // no longer need this
      return _rsvp.default.resolve(); // if (isEmpty(this.selectedFundTokenExpiresAt)) {
      //   return this.refreshSelectedFundToken();
      // } else if (this.selectedFundTokenExpiresAt > moment()) {
      //   return RSVP.resolve();
      // } else {
      //   return this.refreshSelectedFundToken();
      // }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selectedFund", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectedFundToken", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "dashboard", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "goal", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = FundsService;

  class AllFundsInfo {
    constructor() {
      _defineProperty(this, "funds", []);

      _defineProperty(this, "fundsById", {});

      _defineProperty(this, "_callbacks", []);
    }

    onRecordsFromServer(callback) {
      if (this._callbacks) {
        this._callbacks.push(callback);
      }
    }

    _populateFunds(funds) {
      this.funds.length = 0;
      funds.forEach(fund => {
        this.funds.push(fund);
        this.fundsById[fund.id] = fund;
      });
    }

    _handleRecordsFromServer(funds) {
      this._populateFunds(funds);

      if (this._callbacks) {
        this._callbacks.forEach(callback => {
          callback({
            funds: this.funds,
            fundsById: this.fundsById
          });
        });
      }

      this._callbacks = undefined;
    }

  }

  _exports.AllFundsInfo = AllFundsInfo;
});