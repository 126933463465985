define("donor-elf-web/components/upload-file-item", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "@ember/string", "@ember/object/evented"], function (_exports, _component, _object, _runloop, _string, _evented) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.BeginUploadEventBus = void 0;
  const {
    FormData,
    XMLHttpRequest
  } = window; // Allows parent controller/component to tell this component to begin the upload

  const BeginUploadEventBus = _object.default.extend(_evented.default, {
    beginUpload() {
      this.trigger('beginUpload');
    }

  });
  /*
    attrs
    - uploadFile - the uploadFile model
    - beginUploadEventBus - instance of a BeginUploadEventBus above
    - showNoFileChosenErrorMessage - when to show an error message
  
    events
    - onUploadComplete(uploadFile)
      - fires when the upload to AWS is complete
    - onUploadError()
    - onUploadCancelled()
  */


  _exports.BeginUploadEventBus = BeginUploadEventBus;

  var _default = _component.default.extend({
    uploadFile: null,
    beginUploadEventBus: null,
    showNoFileChosenErrorMessage: false,
    fileToUpload: null,
    uploading: false,
    completed: false,
    percentUploaded: 5,
    progressBarStyle: null,

    upload() {
      (0, _object.set)(this, 'uploading', true);
      let formData = new FormData();
      formData.append('key', (0, _object.get)(this, 'uploadFile.key'));
      formData.append('AWSAccessKeyId', (0, _object.get)(this, 'uploadFile.accessKey'));
      formData.append('acl', 'private');
      formData.append('policy', (0, _object.get)(this, 'uploadFile.policy'));
      formData.append('signature', (0, _object.get)(this, 'uploadFile.signature'));
      formData.append('success_action_status', '201');
      formData.append('file', (0, _object.get)(this, 'fileToUpload'));
      let xhr = new XMLHttpRequest();
      xhr.upload.addEventListener('progress', this.xhrProgress.bind(this), false);
      xhr.addEventListener('load', this.xhrComplete.bind(this), false);
      xhr.addEventListener('error', this.xhrError.bind(this), false);
      xhr.addEventListener('abort', this.xhrCancelled.bind(this), false);
      xhr.open('POST', (0, _object.get)(this, 'actionUrl'), true);
      xhr.send(formData);
    },

    xhrProgress(e) {
      (0, _runloop.run)(() => {
        if (e.lengthComputable) {
          (0, _object.set)(this, 'percentUploaded', Math.round(e.loaded * 100 / e.total));
          this.updateProgressBar();
        }
      });
    },

    xhrComplete() {
      (0, _runloop.run)(() => {
        (0, _object.setProperties)(this, {
          percentUploaded: 100,
          completed: true
        });
        this.updateProgressBar();
        (0, _object.get)(this, 'onUploadComplete')((0, _object.get)(this, 'uploadFile'));
      });
    },

    xhrError(e) {
      (0, _runloop.run)(() => {
        let onUploadError = (0, _object.get)(this, 'onUploadError');

        if (onUploadError) {
          onUploadError((0, _object.get)(this, 'uploadFile'), e);
        }
      });
    },

    xhrCancelled() {
      (0, _runloop.run)(() => {
        let onUploadCancelled = (0, _object.get)(this, 'onUploadCancelled');

        if (onUploadCancelled) {
          onUploadCancelled((0, _object.get)(this, 'uploadFile'));
        }
      });
    },

    updateProgressBar() {
      (0, _object.set)(this, 'progressBarStyle', (0, _string.htmlSafe)(`width: ${(0, _object.get)(this, 'percentUploaded')}%`));
    },

    actionUrl: (0, _object.computed)(function () {
      return `https://${(0, _object.get)(this, 'uploadFile.bucket')}.s3.amazonaws.com`;
    }),

    init() {
      this._super(...arguments);

      (0, _object.get)(this, 'beginUploadEventBus').on('beginUpload', this, this.upload);
    },

    willDestroyElement() {
      this._super(...arguments);

      (0, _object.get)(this, 'beginUploadEventBus').off('beginUpload', this, this.upload);
    },

    actions: {
      onChange(e) {
        let fileToUpload = null;

        if (e.target.files.length === 1) {
          fileToUpload = e.target.files[0];
        }

        (0, _object.set)(this, 'uploadFile.fileChosen', fileToUpload != null);
        (0, _object.set)(this, 'fileToUpload', fileToUpload);
      }

    }
  });

  _exports.default = _default;
});