define("donor-elf-web/controllers/admin/fund-mappings/edit", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "@ember/service", "ember-changeset", "ember-changeset-validations", "donor-elf-web/validations/admin/edit-fund-mapping"], function (_exports, _controller, _object, _tracking, _service, _emberChangeset, _emberChangesetValidations, _editFundMapping) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let FundMappingsEditController = (_class = class FundMappingsEditController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fundMappingsService", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "saving", _descriptor4, this);

      _initializerDefineProperty(this, "showValidationErrors", _descriptor5, this);

      _initializerDefineProperty(this, "error", _descriptor6, this);

      _initializerDefineProperty(this, "changeset", _descriptor7, this);

      _initializerDefineProperty(this, "hostIdentityChangesets", _descriptor8, this);
    }

    templateDidInsert() {
      var _this$model$fundMappi, _this$model$fundMappi2, _this$model$fundMappi3;

      this.saving = false;
      this.error = undefined;
      this.showValidationErrors = false;
      let editFundMapping = this.store.createRecord('admin/edit-fund-mapping');
      let fund = (_this$model$fundMappi = this.model.fundMapping) === null || _this$model$fundMappi === void 0 ? void 0 : _this$model$fundMappi.fund;

      if (fund) {
        editFundMapping.fundId = fund.id || 'add';
        editFundMapping.fundName = fund.name;
        editFundMapping.fundActive = fund.active;
      }

      this.changeset = new _emberChangeset.default(editFundMapping, (0, _emberChangesetValidations.default)(_editFundMapping.EditFundMappingValidation), _editFundMapping.EditFundMappingValidation);
      this.hostIdentityChangesets = [];
      (_this$model$fundMappi2 = this.model.fundMapping) === null || _this$model$fundMappi2 === void 0 ? void 0 : (_this$model$fundMappi3 = _this$model$fundMappi2.hostIdentities) === null || _this$model$fundMappi3 === void 0 ? void 0 : _this$model$fundMappi3.forEach(x => {
        this.createHostIdentityChangeset({
          database: x.database,
          hostId: x.hostId
        });
      });
    }

    createHostIdentityChangeset({
      database,
      hostId
    }) {
      let validation = _editFundMapping.EditFundMappingHostIdentityValidation.initialize({
        editFundMappingChangeset: this.changeset,
        fundMappingsService: this.fundMappingsService
      }); // @ts-expect-error


      let changeset = new _emberChangeset.default({
        database,
        hostId
      }, (0, _emberChangesetValidations.default)(validation), validation);
      this.hostIdentityChangesets = [...this.hostIdentityChangesets, changeset];
    }

    handleDeleteHostIdentity(hostIdentityChangeset) {
      this.hostIdentityChangesets = this.hostIdentityChangesets.filter(x => x !== hostIdentityChangeset);
    }

    async save() {
      this.saving = true;

      try {
        this.changeset.set('hostIdentities', this.hostIdentityChangesets.map(x => {
          return {
            database: x.get('database'),
            hostId: x.get('hostId')
          };
        }));

        for (let changeset of this.hostIdentityChangesets) {
          await changeset.validate();
        }

        await this.changeset.validate();
        this.showValidationErrors = true;

        if (this.changeset.get('isValid') && this.hostIdentityChangesets.every(x => x.get('isValid'))) {
          await this.changeset.save();
          let fundId = this.changeset.get('id');
          let fund = await this.store.findRecord('admin/fund', fundId, {
            forceReload: true
          });
          let fundHostIdentities = (await this.store.query('admin/fund-host-identity', {
            filter: {
              fund_id: fundId
            }
          })).toArray();
          this.fundMappingsService.updateMappingsForFund(fund, fundHostIdentities);
          this.router.transitionTo('admin.fund-mappings.index');
        }

        this.saving = false;
      } catch (error) {
        this.error = error;
        this.saving = false;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fundMappingsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "saving", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showValidationErrors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "error", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "changeset", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "hostIdentityChangesets", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "createHostIdentityChangeset", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "createHostIdentityChangeset"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleDeleteHostIdentity", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleDeleteHostIdentity"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class);
  _exports.default = FundMappingsEditController;
});