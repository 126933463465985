define("donor-elf-web/components/save-form", ["exports", "@ember/component", "@ember/utils", "@ember/object"], function (_exports, _component, _utils, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    attrs
    - changeset
      - the changeset being edited
    - onCancel
      - action called when cancel link is clicked (first priority)
    - cancelRoute
      - the route name to go if onCancelGoBack is false and cancel is clicked (2nd priority)
      - if blank, then window.history.back() is used
    - cancelRouteParams
      - The first param for the cancelRoute (optional)
    - deleteRoute
      - The route to delete the item
    - deleteRouteParams
      - The first param for the deleteRoute (optional)
    - saveTask
      - the task to run when save is clicked
    - saveLabel
      - default is 'Save'
    - alwaysSave
      - run the save task even if the changeset is invalid
      - default is false
    - showActions
      - default is true
      - whether to save the Save, Cancel and Delete buttons
    - showSaveAndAdd
      - default is false
      - whether to show the Save & Add button
  */
  var _default = _component.default.extend({
    changeset: null,
    cancelRoute: null,
    saveTask: null,
    saveLabel: 'Save',
    showActions: true,
    showValidationErrors: false,
    alwaysSave: false,
    showDeleteRoute: (0, _object.computed)('deleteRoute', 'changeset.isNew', function () {
      return !(0, _object.get)(this, 'changeset.isNew') && !(0, _utils.isEmpty)((0, _object.get)(this, 'deleteRoute'));
    }),
    // pointer to saveTask that is performing
    // this is used so it can be cancelled if cancel is clicked
    saveTaskInstance: null,

    willDestroyElement() {
      this._super(...arguments);

      let saveTaskInstance = (0, _object.get)(this, 'saveTaskInstance');

      if (saveTaskInstance != null) {
        saveTaskInstance.cancel();
      }
    },

    actions: {
      save({
        saveAndAdd
      } = {}) {
        let changeset = (0, _object.get)(this, 'changeset');
        changeset.validate().then(() => {
          (0, _object.set)(this, 'showValidationErrors', true);

          if ((0, _object.get)(changeset, 'isValid') || (0, _object.get)(this, 'alwaysSave')) {
            let saveTaskInstance = (0, _object.get)(this, 'saveTask').perform({
              saveAndAdd
            });
            (0, _object.set)(this, 'saveTaskInstance', saveTaskInstance);
          }
        });
      },

      saveAndAdd() {
        this.send('save', {
          saveAndAdd: true
        });
      },

      cancel() {
        if (this.onCancel) {
          this.onCancel();
        } else {
          window.history.back();
        } // TODO: replace if/else statement in HBS once Ember 2.15 lands and components
        // can use the route service
        // let cancelRoute = get(this, 'cancelRoute');
        // if (cancelRoute) {
        //   //this.transitionToRoute(cancelRoute));
        // } else {
        //   window.history.back();
        // }

      }

    }
  });

  _exports.default = _default;
});