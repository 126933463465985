define("donor-elf-web/components/journal/cell/contact/recommended-by", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "@glimmer/tracking", "@ember/service", "ember-changeset", "ember-changeset-validations/validators", "ember-changeset-validations"], function (_exports, _component, _templateFactory, _component2, _object, _tracking, _service, _emberChangeset, _validators, _emberChangesetValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class='tw-min-w-56 tw-max-w-80'
    ...attributes
  >
    <div class='px-2 pt-1 pb-3'>
      <div class='font-weight-bold border-bottom mb-3'>Recommended By</div>
      <Form::Underline::SelectContact
          @changeset={{this.changeset}}
          @property='recommendedByContactId'
          @excludeContactId={{@journalContactInfo.contactId}}
          @dropdownContentClass='tw-z-1100 mt-1'
          @placeholder='Select Contact'
          @showValidationErrors={{this.showValidationErrors}}
          class='w-100'
      />
    </div>
    {{#if (and this.showValidationErrors (is-present this.errorMessages))}}
      <div class='alert alert-danger mb-0 rounded-0 p-2 tw-text-sm'>
        {{#each this.errorMessages as |error|}}
          <div>- {{error}}</div>
        {{/each}}
      </div>
    {{/if}}
    {{#if this.saveError}}
      <GenericError
        @error={{this.saveError}}
        @useLeftBorderCard={{true}}
        class='mx-2 mb-2'
      />
    {{/if}}
    <div class='border-top p-2 bg-gray-100 rounded-bottom'>
      <button
        {{on 'click' this.save}}
        class='btn btn-sm btn-warning py-0'
        type='button'
      >
        {{#if this.saving}}
          Saving...
        {{else}}
          Save
        {{/if}}
      </button>
      {{#unless this.saving}}
        <button
          {{on 'click' @onCancel}}
          class='btn btn-sm btn-link py-0 tw-text-sm'
          type='button'
        >
          Cancel
        </button>
      {{/unless}}
    </div>
  </div>
  */
  {
    "id": "e5g9RiE0",
    "block": "[[[11,0],[24,0,\"tw-min-w-56 tw-max-w-80\"],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"px-2 pt-1 pb-3\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"font-weight-bold border-bottom mb-3\"],[12],[1,\"Recommended By\"],[13],[1,\"\\n    \"],[8,[39,0],[[24,0,\"w-100\"]],[[\"@changeset\",\"@property\",\"@excludeContactId\",\"@dropdownContentClass\",\"@placeholder\",\"@showValidationErrors\"],[[30,0,[\"changeset\"]],\"recommendedByContactId\",[30,2,[\"contactId\"]],\"tw-z-1100 mt-1\",\"Select Contact\",[30,0,[\"showValidationErrors\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[28,[37,2],[[30,0,[\"showValidationErrors\"]],[28,[37,3],[[30,0,[\"errorMessages\"]]],null]],null],[[[1,\"    \"],[10,0],[14,0,\"alert alert-danger mb-0 rounded-0 p-2 tw-text-sm\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"errorMessages\"]]],null]],null],null,[[[1,\"        \"],[10,0],[12],[1,\"- \"],[1,[30,3]],[13],[1,\"\\n\"]],[3]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"saveError\"]],[[[1,\"    \"],[8,[39,6],[[24,0,\"mx-2 mb-2\"]],[[\"@error\",\"@useLeftBorderCard\"],[[30,0,[\"saveError\"]],true]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"border-top p-2 bg-gray-100 rounded-bottom\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-sm btn-warning py-0\"],[24,4,\"button\"],[4,[38,7],[\"click\",[30,0,[\"save\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"saving\"]],[[[1,\"        Saving...\\n\"]],[]],[[[1,\"        Save\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"],[41,[51,[30,0,[\"saving\"]]],[[[1,\"      \"],[11,\"button\"],[24,0,\"btn btn-sm btn-link py-0 tw-text-sm\"],[24,4,\"button\"],[4,[38,7],[\"click\",[30,4]],null],[12],[1,\"\\n        Cancel\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@journalContactInfo\",\"error\",\"@onCancel\"],false,[\"form/underline/select-contact\",\"if\",\"and\",\"is-present\",\"each\",\"-track-array\",\"generic-error\",\"on\",\"unless\"]]",
    "moduleName": "donor-elf-web/components/journal/cell/contact/recommended-by.hbs",
    "isStrictMode": false
  });

  let validation = {
    recommendedByContactId: (0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    })
  };
  let JournalCellContactRecommendedByComponent = (_class = class JournalCellContactRecommendedByComponent extends _component2.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "saving", _descriptor2, this);

      _initializerDefineProperty(this, "showValidationErrors", _descriptor3, this);

      _initializerDefineProperty(this, "saveError", _descriptor4, this);

      _defineProperty(this, "changeset", void 0);

      let record = this.store.createRecord('recommendation', {
        recommendedContactId: args.journalContactInfo.contactId
      });
      this.changeset = new _emberChangeset.default(record, (0, _emberChangesetValidations.default)(validation), validation);
    }

    async save() {
      if (this.saving) {
        return;
      }

      this.saving = true;
      this.showValidationErrors = false;
      await this.changeset.validate();

      if (this.changeset.get('isValid')) {
        try {
          await this.changeset.save();
          this.saving = false;
          this.args.onSaved(this.changeset.get('data'));
        } catch (error) {
          this.saveError = error;
          this.saving = false;
        }
      } else {
        this.saving = false;
        this.showValidationErrors = true;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "saving", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showValidationErrors", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "saveError", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class);
  _exports.default = JournalCellContactRecommendedByComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, JournalCellContactRecommendedByComponent);
});