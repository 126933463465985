define("donor-elf-web/controllers/funds/fund/ytd-given-expecting", ["exports", "@ember/controller", "@ember/service", "@ember/object", "@glimmer/tracking", "donor-elf-web/lib/array-utils"], function (_exports, _controller, _service, _object, _tracking, _arrayUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    moment
  } = window;
  let YtdGivenExpectingController = (_class = class YtdGivenExpectingController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "addSomethingModal", _descriptor, this);

      _defineProperty(this, "savePledgeSubsciptionToken", void 0);

      _initializerDefineProperty(this, "contactExpectedItemsSortBy", _descriptor2, this);

      _initializerDefineProperty(this, "contactOverdueItemsSortBy", _descriptor3, this);

      _initializerDefineProperty(this, "contactNoGiftItemsSortBy", _descriptor4, this);
    }

    templateDidInsert() {
      this.savePledgeSubsciptionToken = this.addSomethingModal.subscribeToPledgeSaved(this.handlePledgeSaved);
    }

    templateWillDestroy() {
      if (this.savePledgeSubsciptionToken) {
        this.addSomethingModal.unsubscribeWithToken(this.savePledgeSubsciptionToken);
      }
    }

    async handlePledgeSaved(_msg, _data) {
      this.send('refreshYtdGivenExpectingRoute');
    }

    get sortedContactExpectedItems() {
      return (0, _arrayUtils.sortByProperties)(this.model.contactExpectedItems, [`${this.contactExpectedItemsSortBy.property}:${this.contactExpectedItemsSortBy.direction}`]);
    }

    get sortedContactOverdueItems() {
      return (0, _arrayUtils.sortByProperties)(this.model.contactOverdueItems, [`${this.contactOverdueItemsSortBy.property}:${this.contactOverdueItemsSortBy.direction}`]);
    }

    get sortedContactNoGiftItems() {
      return (0, _arrayUtils.sortByProperties)(this.model.contactNoGiftItems, [`${this.contactNoGiftItemsSortBy.property}:${this.contactNoGiftItemsSortBy.direction}`]);
    }

    get report() {
      return this.model.givenExpectedReport;
    }

    get givenAmount() {
      return this.report.ytdData.given_amount;
    }

    get givenPercent() {
      return this.report.ytdData.given_percent;
    }

    get expectedAmount() {
      return this.report.ytdData.expected_amount;
    }

    get expectedPercent() {
      return this.report.ytdData.expected_percent;
    }

    get noGiftsAmount() {
      return this.report.ytdData.no_gifts_amount;
    }

    get noGiftsPercent() {
      return this.report.ytdData.no_gifts_percent;
    }

    get overdueAmount() {
      return this.report.ytdData.overdue_amount;
    }

    get overduePercent() {
      return this.report.ytdData.overdue_percent;
    }

    get totalAmount() {
      return this.report.ytdData.total_amount;
    }

    get totalPercent() {
      return this.report.ytdData.total_percent;
    }

    get leftAmount() {
      return this.report.ytdData.left_amount;
    }

    get leftPercent() {
      return this.report.ytdData.left_percent;
    }

    get givenFrom() {
      return moment(this.report.startAt).format('YYYY-MM-DD');
    }

    get givenTo() {
      return this.report.ytdData.given_to_date;
    }

    get pledgesFrom() {
      return this.report.ytdData.expected_from_date;
    }

    get pledgesTo() {
      return this.report.ytdData.expected_to_date;
    }

    get excludeNoGiftPledges() {
      return this.report.ytdData.exclude_no_gift_pledges || false;
    }

    sortContactExpectedItemsBy(sortProperty) {
      if (sortProperty === this.contactExpectedItemsSortBy.property) {
        this.contactExpectedItemsSortBy = {
          property: sortProperty,
          direction: this.contactExpectedItemsSortBy.direction === 'asc' ? 'desc' : 'asc'
        };
      } else {
        this.contactExpectedItemsSortBy = {
          property: sortProperty,
          direction: 'asc'
        };
      }
    }

    sortContactOverdueItemsBy(sortProperty) {
      if (sortProperty === this.contactOverdueItemsSortBy.property) {
        this.contactOverdueItemsSortBy = {
          property: sortProperty,
          direction: this.contactOverdueItemsSortBy.direction === 'asc' ? 'desc' : 'asc'
        };
      } else {
        this.contactOverdueItemsSortBy = {
          property: sortProperty,
          direction: 'asc'
        };
      }
    }

    sortContactNoGiftItemsBy(sortProperty) {
      if (sortProperty === this.contactNoGiftItemsSortBy.property) {
        this.contactNoGiftItemsSortBy = {
          property: sortProperty,
          direction: this.contactNoGiftItemsSortBy.direction === 'asc' ? 'desc' : 'asc'
        };
      } else {
        this.contactNoGiftItemsSortBy = {
          property: sortProperty,
          direction: 'asc'
        };
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "addSomethingModal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handlePledgeSaved", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handlePledgeSaved"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "contactExpectedItemsSortBy", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        property: 'totalExpecting',
        direction: 'desc'
      };
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "contactOverdueItemsSortBy", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        property: 'totalExpecting',
        direction: 'desc'
      };
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "contactNoGiftItemsSortBy", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        property: 'totalExpecting',
        direction: 'desc'
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "sortContactExpectedItemsBy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sortContactExpectedItemsBy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortContactOverdueItemsBy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sortContactOverdueItemsBy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "sortContactNoGiftItemsBy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "sortContactNoGiftItemsBy"), _class.prototype)), _class);
  _exports.default = YtdGivenExpectingController;
});