define("donor-elf-web/components/dashboard/onboard/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if (eq 'ag_wm' @onboard.templateName)}}
    <Dashboard::Onboard::AgWm
      @onboard={{@onboard}}
      ...attributes
    />
  {{else if (eq 'donor_hub' @onboard.templateName)}}
    <Dashboard::Onboard::DonorHub
      @onboard={{@onboard}}
      ...attributes
    />
  {{/if}}
  */
  {
    "id": "YTqjJ/J5",
    "block": "[[[41,[28,[37,1],[\"ag_wm\",[30,1,[\"templateName\"]]],null],[[[1,\"  \"],[8,[39,2],[[17,2]],[[\"@onboard\"],[[30,1]]],null],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[\"donor_hub\",[30,1,[\"templateName\"]]],null],[[[1,\"  \"],[8,[39,3],[[17,2]],[[\"@onboard\"],[[30,1]]],null],[1,\"\\n\"]],[]],null]],[]]]],[\"@onboard\",\"&attrs\"],false,[\"if\",\"eq\",\"dashboard/onboard/ag-wm\",\"dashboard/onboard/donor-hub\"]]",
    "moduleName": "donor-elf-web/components/dashboard/onboard/index.hbs",
    "isStrictMode": false
  });

  class DashboardOnboardIndexComponents extends _component2.default {}

  _exports.default = DashboardOnboardIndexComponents;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DashboardOnboardIndexComponents);
});