define("donor-elf-web/templates/admin/funds/fund/edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "9zwN7Q45",
    "block": "[[[10,\"ol\"],[14,0,\"breadcrumb row-bottom-margin\"],[12],[1,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[39,0],null,[[\"route\"],[\"admin.funds\"]],[[\"default\"],[[[[1,\"Funds\"]],[]]]]],[13],[1,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[6,[39,0],null,[[\"route\"],[\"admin.funds.fund\"]],[[\"default\"],[[[[1,[33,1,[\"record\",\"name\"]]]],[]]]]],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[6,[39,2],null,null,[[\"default\"],[[[[1,\"  \"],[1,[28,[35,3],null,[[\"changeset\",\"property\",\"label\",\"focusOnInsert\",\"showValidationErrors\"],[[33,1,[\"changeset\"]],\"name\",\"Name\",true,[33,4]]]]],[1,\"\\n  \"],[1,[28,[35,3],null,[[\"changeset\",\"property\",\"label\",\"booleanField\",\"showValidationErrors\"],[[33,1,[\"changeset\"]],\"active\",\"Active\",true,[33,4]]]]],[1,\"\\n  \"],[10,0],[14,0,\"my-4\"],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-warning\"],[4,[38,5],[[30,0],\"save\"],null],[12],[1,\"Save\"],[13],[1,\"\\n    \"],[6,[39,0],null,[[\"class\",\"route\"],[\"btn btn-link\",\"admin.funds.fund\"]],[[\"default\"],[[[[1,\"Cancel\"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[28,[37,7],[[33,1,[\"changeset\",\"isInvalid\"]],[33,4]],null],[[[1,\"    \"],[10,0],[14,0,\"alert alert-danger\"],[12],[1,\"\\n      Whoops! It looks like there are some errors.\\n    \"],[13],[1,\"\\n\"]],[]],null],[41,[33,8],[[[1,\"    \"],[10,0],[14,0,\"alert alert-info\"],[12],[1,\"\\n      Saving...\\n    \"],[13],[1,\"\\n\"]],[]],null]],[]]]]]],[],false,[\"link-to\",\"model\",\"de-form-card\",\"form-group\",\"showValidationErrors\",\"action\",\"if\",\"and\",\"saving\"]]",
    "moduleName": "donor-elf-web/templates/admin/funds/fund/edit.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});