define("donor-elf-web/components/task/info/mark-complete-box", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/debug", "donor-elf-web/lib/logger", "donor-elf-web/lib/array-utils"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _service, _debug, _logger, _arrayUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    class='d-inline-block px-1 bg-gray-100 rounded border mt-1 tw-mb-2px'
    ...attributes
  >
    {{#if this.saving}}
      <div class='small'>
        Saving...
      </div>
    {{else}}
      {{#each this.availableResults as |result|}}
        <div
          class='form-check'
          {{on 'click' (fn this.saveResult result)}}
          role='button'
        >
          <input
            type='checkbox'
            class='form-check-input'
          />
          <label
            class='form-check-label d-flex svg-icon cursor-pointer'
            role='button'
          >
            {{result.label}}
          </label>
        </div>
      {{/each}}
    {{/if}}
  </div>
  */
  {
    "id": "5x9DPhhD",
    "block": "[[[11,0],[24,0,\"d-inline-block px-1 bg-gray-100 rounded border mt-1 tw-mb-2px\"],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"saving\"]],[[[1,\"    \"],[10,0],[14,0,\"small\"],[12],[1,\"\\n      Saving...\\n    \"],[13],[1,\"\\n\"]],[]],[[[42,[28,[37,2],[[28,[37,2],[[30,0,[\"availableResults\"]]],null]],null],null,[[[1,\"      \"],[11,0],[24,0,\"form-check\"],[24,\"role\",\"button\"],[4,[38,3],[\"click\",[28,[37,4],[[30,0,[\"saveResult\"]],[30,2]],null]],null],[12],[1,\"\\n        \"],[10,\"input\"],[14,0,\"form-check-input\"],[14,4,\"checkbox\"],[12],[13],[1,\"\\n        \"],[10,\"label\"],[14,0,\"form-check-label d-flex svg-icon cursor-pointer\"],[14,\"role\",\"button\"],[12],[1,\"\\n          \"],[1,[30,2,[\"label\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[2]],null]],[]]],[13]],[\"&attrs\",\"result\"],false,[\"if\",\"each\",\"-track-array\",\"on\",\"fn\"]]",
    "moduleName": "donor-elf-web/components/task/info/mark-complete-box.hbs",
    "isStrictMode": false
  });

  let TaskInfoMarkCompleteBoxComponent = (_class = class TaskInfoMarkCompleteBoxComponent extends _component2.default {
    constructor(owner, args) {
      super(owner, args);

      _initializerDefineProperty(this, "taskService", _descriptor, this);

      _initializerDefineProperty(this, "saving", _descriptor2, this);

      _defineProperty(this, "_taskAction", void 0);

      (false && !(typeof args.onTaskSaved === 'function') && (0, _debug.assert)('@onTaskSaved() argument must be present for Task::Info::NewMarkComplete', typeof args.onTaskSaved === 'function'));
    }

    get taskAction() {
      if (this._taskAction) {
        return this._taskAction;
      }

      return this._taskAction = this.taskService.actionsById[this.args.task.actionId];
    }

    get availableResults() {
      var _this$taskAction;

      return (0, _arrayUtils.sortByProperties)(this.taskService.availableResultsByActionId[(_this$taskAction = this.taskAction) === null || _this$taskAction === void 0 ? void 0 : _this$taskAction.id], ['label:asc']);
    }

    async saveResult(result) {
      try {
        var _this$args$onTaskSavi, _this$args, _this$args$onTaskSavi2, _this$args2;

        (_this$args$onTaskSavi = (_this$args = this.args).onTaskSaving) === null || _this$args$onTaskSavi === void 0 ? void 0 : _this$args$onTaskSavi.call(_this$args, {
          saving: true,
          task: this.args.task
        });
        this.saving = true;
        this.args.task.resultId = result.id;
        this.args.task.completedDate = new Date();
        await this.args.task.save();
        (_this$args$onTaskSavi2 = (_this$args2 = this.args).onTaskSaving) === null || _this$args$onTaskSavi2 === void 0 ? void 0 : _this$args$onTaskSavi2.call(_this$args2, {
          saving: false,
          task: this.args.task
        });
        this.args.onTaskSaved(this.args.task);
      } catch (error) {
        var _this$args$onTaskSavi3, _this$args3, _this$args$onTaskSave, _this$args4;

        if (!this.args.task.isNew) {
          this.args.task.rollbackAttributes();
        }

        (_this$args$onTaskSavi3 = (_this$args3 = this.args).onTaskSaving) === null || _this$args$onTaskSavi3 === void 0 ? void 0 : _this$args$onTaskSavi3.call(_this$args3, {
          saving: false,
          task: this.args.task
        });
        (_this$args$onTaskSave = (_this$args4 = this.args).onTaskSaveError) === null || _this$args$onTaskSave === void 0 ? void 0 : _this$args$onTaskSave.call(_this$args4, {
          task: this.args.task,
          error: error
        });

        _logger.default.error(error);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "taskService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "saving", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "saveResult", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveResult"), _class.prototype)), _class);
  _exports.default = TaskInfoMarkCompleteBoxComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TaskInfoMarkCompleteBoxComponent);
});