define("donor-elf-web/components/top-navbar-coaching", ["exports", "@ember/component", "@ember/service"], function (_exports, _component, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    currentUser: (0, _service.inject)(),
    classNames: ['flex-grow-1', 'd-flex']
  });

  _exports.default = _default;
});