define("donor-elf-web/templates/funds/fund/more/karani-import/complete", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "0Q8NnigK",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,\"h4\"],[14,0,\"mb-4\"],[12],[1,\"Upload Complete!\"],[13],[1,\"\\n  \"],[6,[39,1],null,[[\"class\",\"route\"],[\"btn btn-warning\",\"funds.fund.dashboard\"]],[[\"default\"],[[[[1,\"Go to the Dashboard\"]],[]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"de-form-card\",\"link-to\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/more/karani-import/complete.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});