define("donor-elf-web/templates/admin/users/show/funds/delete-loading", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "koSLCoNk",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,\"h4\"],[14,0,\"mb-3\"],[12],[1,\"Delete Fund Permission\"],[13],[1,\"\\n  \"],[1,[34,1]],[1,\"\\n\"]],[]]]]]],[],false,[\"de-form-card\",\"de-loading\"]]",
    "moduleName": "donor-elf-web/templates/admin/users/show/funds/delete-loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});