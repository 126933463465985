define("donor-elf-web/templates/funds/no-access", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "hXnF8bP8",
    "block": "[[[8,[39,0],[[4,[38,1],[[30,0,[\"templateDidInsert\"]]],null],[4,[38,2],[[30,0,[\"templateWillDestroy\"]]],null]],null,[[\"default\"],[[[[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Whoops. It looks like you don't have access to this fund.\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Click the button below to choose a fund you do have access to.\\n  \"],[13],[1,\"\\n  \"],[8,[39,3],[[24,0,\"btn btn-warning\"]],[[\"@route\"],[\"funds\"]],[[\"default\"],[[[[1,\"\\n    Select a Fund\\n  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"form-card\",\"did-insert\",\"will-destroy\",\"link-to\"]]",
    "moduleName": "donor-elf-web/templates/funds/no-access.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});