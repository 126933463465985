define("donor-elf-web/components/form-group-select-multiple", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /*
    attrs
      - changeset
      - property
      - label
      - placeholder
      - options
        - { val:, label: }
        - can also provide searchLabel: property that the search will be based off of
          - otherwise the search is based off the label
      - closeDropdownAfterEnter (boolean)
        - Whether to close the dropdown when filter textbox has focus and enter is hit
        - default false
      - maximumOptionsInDropdown (integer)
        - default 20
      - dropdownContentClass?: string; // 'tw-z-1100' can be used when the component is in a <Modal /> component
      - onChange(selectedVals) - action fired when the selected options change
  */
  var _default = _component.default.extend({
    classNames: ['form-group'],
    changeset: null,
    property: null,
    label: null,
    options: null,
    closeDropdownAfterEnter: false,
    maximumOptionsInDropdown: 20,
    selectedVals: null,
    dropdown: null,
    searchVal: '',
    searchSelectedIndex: 0,
    optionsByVal: (0, _object.computed)('options.[]', function () {
      let byVal = {};
      this.options.forEach(option => {
        byVal[option.val] = option;
      });
      return byVal;
    }),
    selectedOptions: (0, _object.computed)('selectedVals.[]', function () {
      let optionsByVal = this.optionsByVal;
      return this.selectedVals.map(val => {
        return optionsByVal[val];
      });
    }),
    notSelectedOptions: (0, _object.computed)('selectedVals.[]', 'selectedOptions.[]', function () {
      return this.options.filter(option => {
        return this.selectedVals.indexOf(option.val) === -1;
      });
    }),
    filteredNotSelectedOptions: (0, _object.computed)('searchVal', 'notSelectedOptions.[]', function () {
      if (this.searchVal == null || this.searchVal.length < 2) {
        return this.notSelectedOptions;
      }

      let scrubbedSearch = this.searchVal.trim().toLowerCase();
      return this.notSelectedOptions.filter(option => {
        if (option.searchLabel == null) {
          option.searchLabel = option.label.toLowerCase();
        }

        return option.searchLabel.indexOf(scrubbedSearch) >= 0;
      });
    }),
    dropdownOptions: (0, _object.computed)('filteredNotSelectedOptions.[]', function () {
      return this.filteredNotSelectedOptions.slice(0, this.maximumOptionsInDropdown);
    }),
    moreDropdownOptionsToShow: (0, _object.computed)('maximumOptionsInDropdown', 'filteredNotSelectedOptions.length', function () {
      return this.filteredNotSelectedOptions.length > this.maximumOptionsInDropdown;
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      if (this.changeset.get(this.property) == null) {
        this.changeset.set(this.property, []);
      }

      let selectedVals = this.changeset.get(this.property);
      (0, _object.set)(this, 'selectedVals', selectedVals);
    },

    handleDropdownOpened: (0, _object.action)(function (dropdown, e) {
      (0, _object.set)(this, 'dropdown', dropdown);

      if (e && e.target && e.target.className.indexOf('form-group-select-multiple-delete') >= 0) {
        return false;
      }

      (0, _object.set)(this, 'searchVal', '');
      (0, _object.set)(this, 'searchSelectedIndex', 0);
    }),

    focusOnInput(el) {
      el.querySelector('input').focus();
    },

    actions: {
      removeAtIndex(index) {
        var _this$onChange;

        this.selectedVals.removeAt(index);
        (_this$onChange = this.onChange) === null || _this$onChange === void 0 ? void 0 : _this$onChange.call(this, this.selectedVals);
      },

      selectOption(option) {
        var _this$onChange2;

        this.selectedVals.pushObject(option.val);
        this.dropdown.actions.close();
        (_this$onChange2 = this.onChange) === null || _this$onChange2 === void 0 ? void 0 : _this$onChange2.call(this, this.selectedVals);
      },

      handleSearchUpArrow() {
        if (this.searchSelectedIndex > 0) {
          (0, _object.set)(this, 'searchSelectedIndex', this.searchSelectedIndex - 1);
        }
      },

      handleSearchDownArrow() {
        if (this.searchSelectedIndex <= this.filteredNotSelectedOptions.length) {
          (0, _object.set)(this, 'searchSelectedIndex', this.searchSelectedIndex + 1);
        }
      },

      handleSearchTab(e) {
        e.preventDefault();
        this.dropdown.actions.close();
      },

      handleSearchEnter() {
        let option = this.filteredNotSelectedOptions[this.searchSelectedIndex];

        if (option) {
          var _this$onChange3;

          this.selectedVals.pushObject(option.val);
          (0, _object.set)(this, 'searchVal', '');

          if (this.closeDropdownAfterEnter) {
            this.dropdown.actions.close();
          } else {
            this.dropdown.actions.reposition();
          }

          (_this$onChange3 = this.onChange) === null || _this$onChange3 === void 0 ? void 0 : _this$onChange3.call(this, this.selectedVals);
        }
      }

    }
  });

  _exports.default = _default;
});