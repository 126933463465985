define("donor-elf-web/controllers/funds/fund/annual-comparison", ["exports", "@ember/controller", "@ember/object", "@ember/service", "@glimmer/tracking", "donor-elf-web/lib/array-utils", "donor-elf-web/lib/date-utils"], function (_exports, _controller, _object, _service, _tracking, _arrayUtils, _dateUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const {
    currency,
    moment
  } = window;

  class BothYearsRecord {
    constructor(currentYearRecord, previousYearRecord) {
      _defineProperty(this, "contactId", void 0);

      _defineProperty(this, "giftCountDiff", void 0);

      _defineProperty(this, "giftSumDiff", void 0);

      this.currentYearRecord = currentYearRecord;
      this.previousYearRecord = previousYearRecord;
      this.contactId = currentYearRecord.contactId;
      this.giftCountDiff = currentYearRecord.giftCount - previousYearRecord.giftCount;
      this.giftSumDiff = currency(currentYearRecord.giftSum).subtract(previousYearRecord.giftSum).value;
    }

  }

  let ComparisonController = (_class = class ComparisonController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "formatDateService", _descriptor, this);

      _initializerDefineProperty(this, "fundGoalService", _descriptor2, this);

      _initializerDefineProperty(this, "store", _descriptor3, this);

      _initializerDefineProperty(this, "currentYearStartDate", _descriptor4, this);

      _initializerDefineProperty(this, "loading", _descriptor5, this);

      _initializerDefineProperty(this, "currentYearRecords", _descriptor6, this);

      _initializerDefineProperty(this, "previousYearRecords", _descriptor7, this);

      _initializerDefineProperty(this, "currentYearContactIds", _descriptor8, this);

      _initializerDefineProperty(this, "previousYearContactIds", _descriptor9, this);

      _initializerDefineProperty(this, "newDonorRecords", _descriptor10, this);

      _initializerDefineProperty(this, "returningDonorRecords", _descriptor11, this);

      _initializerDefineProperty(this, "lapsedDonorRecords", _descriptor12, this);

      _initializerDefineProperty(this, "lapsedDonorsThatReturnedRecords", _descriptor13, this);

      _initializerDefineProperty(this, "bothYearsRecords", _descriptor14, this);

      _initializerDefineProperty(this, "increasedDonorRecords", _descriptor15, this);

      _initializerDefineProperty(this, "sameAmountDonorRecords", _descriptor16, this);

      _initializerDefineProperty(this, "decreasedDonorRecords", _descriptor17, this);
    }

    get defaultCurrentYearStartDate() {
      if (this.fundGoalService.hasYtdGoalType) {
        return this.fundGoalService.ytdStartMoment.clone().subtract(1, 'years').format('YYYY-MM-DD');
      } else {
        return new moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD');
      }
    }

    get currentYearEndDate() {
      return new moment(this.currentYearStartDate).add(1, 'year').subtract(1, 'day').format('YYYY-MM-DD');
    }

    get previousYearStartDate() {
      return new moment(this.currentYearStartDate).subtract(1, 'year').format('YYYY-MM-DD');
    }

    get previousYearEndDate() {
      return new moment(this.currentYearStartDate).subtract(1, 'day').format('YYYY-MM-DD');
    }

    get currentYearLabel() {
      if (this.currentYearStartDateIsBeginningOfYear) {
        return `current year (${this.currentYearStartDate.split('-')[0]})`;
      } else {
        return `current year (${this.formatDateService.format(this.currentYearStartDate)}-${this.formatDateService.format(this.currentYearEndDate)})`;
      }
    }

    get previousYearLabel() {
      if (this.currentYearStartDateIsBeginningOfYear) {
        return `previous year (${this.previousYearStartDate.split('-')[0]})`;
      } else {
        return `previous year (${this.formatDateService.format(this.previousYearStartDate)}-${this.formatDateService.format(this.previousYearEndDate)})`;
      }
    }

    get currentYearStartDateIsBeginningOfYear() {
      return this.currentYearStartDate.endsWith('-01-01');
    }

    get currentYearTotalSum() {
      var _this$currentYearReco;

      return (_this$currentYearReco = this.currentYearRecords) === null || _this$currentYearReco === void 0 ? void 0 : _this$currentYearReco.map(x => x.giftSum).reduce((prevVal, amount) => {
        return currency(prevVal).add(amount).value;
      }, 0);
    }

    get previousYearTotalSum() {
      var _this$previousYearRec;

      return (_this$previousYearRec = this.previousYearRecords) === null || _this$previousYearRec === void 0 ? void 0 : _this$previousYearRec.map(x => x.giftSum).reduce((prevVal, amount) => {
        return currency(prevVal).add(amount).value;
      }, 0);
    }

    get currentYearDonorCountMinusPreviousYearDonorCount() {
      var _this$currentYearReco2, _this$previousYearRec2;

      return (((_this$currentYearReco2 = this.currentYearRecords) === null || _this$currentYearReco2 === void 0 ? void 0 : _this$currentYearReco2.length) || 0) - (((_this$previousYearRec2 = this.previousYearRecords) === null || _this$previousYearRec2 === void 0 ? void 0 : _this$previousYearRec2.length) || 0);
    }

    get currentYearTotalSumMinusPreviousYearTotalSum() {
      return currency(this.currentYearTotalSum).subtract(this.previousYearTotalSum).value;
    }

    get newDonorTotalSum() {
      var _this$newDonorRecords;

      return (_this$newDonorRecords = this.newDonorRecords) === null || _this$newDonorRecords === void 0 ? void 0 : _this$newDonorRecords.map(x => x.giftSum).reduce((prevVal, amount) => {
        return currency(prevVal).add(amount).value;
      }, 0);
    }

    get lapsedDonorTotalSum() {
      var _this$lapsedDonorReco;

      return ((_this$lapsedDonorReco = this.lapsedDonorRecords) === null || _this$lapsedDonorReco === void 0 ? void 0 : _this$lapsedDonorReco.map(x => x.giftSum).reduce((prevVal, amount) => {
        return currency(prevVal).add(amount).value;
      }, 0)) * -1;
    }

    get lapsedDonorsThatReturnedTotalSum() {
      var _this$lapsedDonorsTha;

      return ((_this$lapsedDonorsTha = this.lapsedDonorsThatReturnedRecords) === null || _this$lapsedDonorsTha === void 0 ? void 0 : _this$lapsedDonorsTha.map(x => x.giftSum).reduce((prevVal, amount) => {
        return currency(prevVal).add(amount).value;
      }, 0)) * -1;
    }

    get returningDonorTotalSum() {
      var _this$returningDonorR;

      return (_this$returningDonorR = this.returningDonorRecords) === null || _this$returningDonorR === void 0 ? void 0 : _this$returningDonorR.map(x => x.giftSum).reduce((prevVal, amount) => {
        return currency(prevVal).add(amount).value;
      }, 0);
    }

    get increasedDonorTotalSum() {
      var _this$increasedDonorR;

      return (_this$increasedDonorR = this.increasedDonorRecords) === null || _this$increasedDonorR === void 0 ? void 0 : _this$increasedDonorR.map(x => x.giftSumDiff).reduce((prevVal, amount) => {
        return currency(prevVal).add(amount).value;
      }, 0);
    }

    get sameAmountDonorTotalSum() {
      var _this$sameAmountDonor;

      return (_this$sameAmountDonor = this.sameAmountDonorRecords) === null || _this$sameAmountDonor === void 0 ? void 0 : _this$sameAmountDonor.map(x => x.currentYearRecord.giftSum).reduce((prevVal, amount) => {
        return currency(prevVal).add(amount).value;
      }, 0);
    }

    get decreasedDonorTotalSum() {
      var _this$decreasedDonorR;

      return (_this$decreasedDonorR = this.decreasedDonorRecords) === null || _this$decreasedDonorR === void 0 ? void 0 : _this$decreasedDonorR.map(x => x.giftSumDiff).reduce((prevVal, amount) => {
        return currency(prevVal).add(amount).value;
      }, 0);
    }

    templateDidInsert() {
      this.currentYearStartDate = this.defaultCurrentYearStartDate;
      this.load();
    }

    templateWillDestroy() {
      this.loading = false;
    }

    async load() {
      var _this$currentYearReco3, _this$previousYearRec3;

      this.loading = true;

      let currentYearStartDateObject = _dateUtils.default.deserializeDateOnly(this.currentYearStartDate);

      let currentYearEndDateObject = _dateUtils.default.deserializeDateOnly(this.currentYearEndDate);

      this.currentYearRecords = (0, _arrayUtils.sortByProperties)((await this.store.query('contact-gift-summary', {
        filter: {
          from: this.currentYearStartDate,
          to: this.currentYearEndDate
        }
      })).toArray(), ['giftSum:desc']);
      this.previousYearRecords = (0, _arrayUtils.sortByProperties)((await this.store.query('contact-gift-summary', {
        filter: {
          from: this.previousYearStartDate,
          to: this.previousYearEndDate
        }
      })).toArray(), ['giftSum:desc']);
      this.currentYearContactIds = new Set(((_this$currentYearReco3 = this.currentYearRecords) === null || _this$currentYearReco3 === void 0 ? void 0 : _this$currentYearReco3.map(x => x.contactId)) || []);
      this.previousYearContactIds = new Set(((_this$previousYearRec3 = this.previousYearRecords) === null || _this$previousYearRec3 === void 0 ? void 0 : _this$previousYearRec3.map(x => x.contactId)) || []);
      let newDonorContactIds = this.currentYearContactIds.difference(this.previousYearContactIds);
      this.newDonorRecords = this.currentYearRecords.filter(x => newDonorContactIds.has(x.contactId) && x.firstGiftDate >= currentYearStartDateObject);
      this.returningDonorRecords = this.currentYearRecords.filter(x => newDonorContactIds.has(x.contactId) && x.firstGiftDate < currentYearStartDateObject);
      let lapsedDonorContactIds = this.previousYearContactIds.difference(this.currentYearContactIds);
      this.lapsedDonorRecords = this.previousYearRecords.filter(x => lapsedDonorContactIds.has(x.contactId) && x.lastGiftDate <= currentYearEndDateObject);
      this.lapsedDonorsThatReturnedRecords = this.previousYearRecords.filter(x => lapsedDonorContactIds.has(x.contactId) && x.lastGiftDate > currentYearEndDateObject);
      let bothYearsContactIds = this.currentYearContactIds.intersection(this.previousYearContactIds);
      let bothYearRecords = [];

      for (let contactId of bothYearsContactIds) {
        bothYearRecords.push(new BothYearsRecord(this.currentYearRecords.find(x => x.contactId === contactId), this.previousYearRecords.find(x => x.contactId === contactId)));
      }

      this.bothYearsRecords = (0, _arrayUtils.sortByProperties)(bothYearRecords, ['giftSumDiff:desc']);
      this.increasedDonorRecords = this.bothYearsRecords.filter(x => x.giftSumDiff > 0);
      this.sameAmountDonorRecords = (0, _arrayUtils.sortByProperties)(this.bothYearsRecords.filter(x => x.giftSumDiff === 0), ['currentYearRecord.giftSum:desc']);
      this.decreasedDonorRecords = (0, _arrayUtils.sortByProperties)(this.bothYearsRecords.filter(x => x.giftSumDiff < 0), ['giftSumDiff:asc']);
      this.loading = false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "formatDateService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fundGoalService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentYearStartDate", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '2023-01-01';
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "loading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "currentYearRecords", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "previousYearRecords", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "currentYearContactIds", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "previousYearContactIds", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "newDonorRecords", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "returningDonorRecords", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "lapsedDonorRecords", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "lapsedDonorsThatReturnedRecords", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "bothYearsRecords", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "increasedDonorRecords", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "sameAmountDonorRecords", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "decreasedDonorRecords", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype)), _class);
  _exports.default = ComparisonController;
});