define("donor-elf-web/services/edit-transactions-service", ["exports", "@ember/object/computed", "@ember/object", "@ember/service", "donor-elf-web/validations/line-item", "ember-changeset-validations", "ember-changeset"], function (_exports, _computed, _object, _service, _lineItem, _emberChangesetValidations, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _service.default.extend({
    store: (0, _service.inject)(),
    storeCache: (0, _service.inject)(),
    fundsService: (0, _service.inject)(),
    lastGiftForContact: null,
    transTypes: null,
    sortedTransTypes: (0, _computed.sort)('transTypes', function (a, b) {
      if (a > b) {
        return 1;
      }

      if (b > a) {
        return -1;
      }

      return 0;
    }),
    _populateDataPromise: null,

    populateData() {
      let promise = (0, _object.get)(this, '_populateDataPromise');

      if (promise) {
        return promise;
      }

      (0, _object.set)(this, 'lastGiftForContact', {});
      promise = this.store.query('line-item', {
        filter: {
          last_gift: true
        }
      }).then(lastGifts => {
        lastGifts.forEach(lineItem => {
          (0, _object.set)(this, `lastGiftForContact.${(0, _object.get)(lineItem, 'contactId')}`, lineItem);
        });
        return this.storeCache.findRecord('transaction-filter-option', this.fundsService.selectedFund.id).then(record => {
          let transTypes = (0, _object.get)(record, 'transTypes');

          if (transTypes.length === 0) {
            transTypes.push('Gift');
          }

          (0, _object.set)(this, 'transTypes', transTypes);
        });
      });
      (0, _object.set)(this, '_populateDataPromise', promise);
      return promise;
    },

    reset() {
      (0, _object.setProperties)(this, {
        _populateDataPromise: null,
        currentLineItems: [],
        lastGiftForContact: null,
        transTypes: null
      });
    },

    // the LineItem's that are currently being edited
    // format: [{ model: nil, changeset: nil }]
    currentLineItems: [],

    addLine(initialValues = {}) {
      let {
        store,
        currentLineItems
      } = (0, _object.getProperties)(this, 'store', 'currentLineItems');
      let model = store.createRecord('line-item', initialValues);
      let changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_lineItem.default), _lineItem.default);
      let item = {
        model: model,
        changeset: changeset
      };
      currentLineItems.pushObject(item);
      return item;
    },

    retrieveLine(id) {
      return (0, _object.get)(this, 'store').find('line-item', id).then(model => {
        let changeset = new _emberChangeset.default(model, (0, _emberChangesetValidations.default)(_lineItem.default), _lineItem.default);
        return {
          model: model,
          changeset: changeset
        };
      });
    }

  });

  _exports.default = _default;
});