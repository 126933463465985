define("donor-elf-web/routes/funds/fund/more/notifications/edit", ["exports", "@ember/routing/route", "@ember/object", "rsvp", "@ember/service", "donor-elf-web/lib/event-type-names", "ember-changeset", "ember-changeset-validations", "donor-elf-web/validations/notification"], function (_exports, _route, _object, _rsvp, _service, _eventTypeNames, _emberChangeset, _emberChangesetValidations, _notification) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    currentUser: (0, _service.inject)(),
    fundsService: (0, _service.inject)(),
    store: (0, _service.inject)(),
    storeCache: (0, _service.inject)(),

    model() {
      let notificationsModel = this.modelFor('funds.fund.more.notifications');
      let record = (0, _object.get)(notificationsModel, 'record');

      if (record == null) {
        record = (0, _object.get)(this, 'store').createRecord('notification');
      }

      let changeset = new _emberChangeset.default(record, (0, _emberChangesetValidations.default)(_notification.default), _notification.default);

      if ((0, _object.get)(record, 'isNew')) {
        (0, _object.setProperties)(changeset, {
          emailTo: [(0, _object.get)(this, 'currentUser.email')],
          excludeEventTypeIds: [4, 6, 7, 8],
          notifySameDayOnNewTransactions: false,
          includeContactCityAndState: true,
          includeContactEmail: true,
          fundIds: [(0, _object.get)(this, 'fundsService.selectedFund.id')],
          askToCreate: true
        });
      }

      if ((0, _object.get)(changeset, 'emailTo.length') > 0) {
        (0, _object.set)(changeset, 'emailToString', (0, _object.get)(changeset, 'emailTo').join(','));
      }

      let excludeEventTypeIds = (0, _object.get)(changeset, 'excludeEventTypeIds') || [];
      let selectedEvents = {};

      for (let prop in _eventTypeNames.eventTypeNames) {
        selectedEvents[prop] = excludeEventTypeIds.indexOf(+prop) < 0;
      }

      let selectedEventsChangeset = new _emberChangeset.default(selectedEvents);

      let allFundsPromise = _rsvp.default.resolve([(0, _object.get)(this, 'fundsService.selectedFund')]);

      let hasMultipleFunds = (0, _object.get)(this, 'currentUser.hasMultipleFunds');

      if (hasMultipleFunds) {
        allFundsPromise = this.fundsService.getAllFunds({
          filter: {
            has_ability_to_view_contact_name: true
          }
        });
      }

      return allFundsPromise.then(allFunds => {
        return {
          record,
          changeset,
          selectedEventsChangeset,
          hasMultipleFunds,
          allFunds,
          allFundItems: allFunds.map(fund => {
            return {
              isSelected: (0, _object.get)(changeset, 'fundIds').indexOf((0, _object.get)(fund, 'id')) >= 0,
              fund
            };
          })
        };
      });
    },

    resetController(controller, isExiting) {
      if (isExiting) {
        controller.reset();
      }
    }

  });

  _exports.default = _default;
});