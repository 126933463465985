define("donor-elf-web/templates/funds/fund/goal/ytd", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "bTRRxxqs",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[6,[39,1],null,[[\"changeset\",\"saveTask\"],[[33,2,[\"changeset\"]],[33,3]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,4],null,[[\"changeset\",\"property\",\"label\",\"focusOnInsert\",\"showValidationErrors\"],[[33,2,[\"changeset\"]],\"annualGoal\",\"Annual Goal\",true,[30,1]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"defaultStartAtMonthEmpty\"]],[[[1,\"      \"],[1,[28,[35,6],null,[[\"changeset\",\"property\",\"label\",\"selectOptions\",\"numberField\",\"showValidationErrors\"],[[33,2,[\"changeset\"]],\"startAtMonth\",\"Starting Month\",[33,7],true,[30,1]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"]],[1]]]]]],[]]]]]],[\"showValidationErrors\"],false,[\"de-form-card\",\"save-form\",\"model\",\"save\",\"form-group\",\"if\",\"form-group-select\",\"monthOptions\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/goal/ytd.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});