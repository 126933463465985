define("donor-elf-web/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    // Add your transitions here, like:
    //   this.transition(
    //     this.fromRoute('people.index'),
    //     this.toRoute('people.detail'),
    //     this.use('toLeft'),
    //     this.reverse('toRight')
    //   );
    this.transition(this.fromValue('rootMenu'), this.use('toLeft'));
    this.transition(this.toValue('rootMenu'), this.use('toRight'));
    this.transition(this.hasClass('coaching-navigation-modal'), this.use('toDown', {
      duration: 500
    }));
  }
});