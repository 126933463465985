define("donor-elf-web/validations/pledge", ["exports", "ember-changeset-validations/validators", "@ember/utils", "donor-elf-web/lib/string-utils", "donor-elf-web/lib/date-utils", "donor-elf-web/lib/number-utils", "donor-elf-web/lib/object-utils", "donor-elf-web/lib/pledge-utils"], function (_exports, _validators, _utils, _stringUtils, _dateUtils, _numberUtils, _objectUtils, _pledgeUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    startDate: (0, _validators.validatePresence)({
      presence: true,
      message: "Start Date can't be blank"
    }),
    contactId: (0, _validators.validatePresence)({
      presence: true,
      message: "Contact can't be blank"
    }),
    frequency: (0, _validators.validatePresence)({
      presence: true,
      message: "Frequency can't be blank"
    }),
    amount: (0, _validators.validateNumber)(),
    endDate: (key, newValue, oldValue, changes, content) => {
      if ((0, _utils.isPresent)(newValue)) {
        let startDate = (0, _objectUtils.assignSingleProperty)(content, changes, 'startDate');

        if ((0, _utils.isPresent)(startDate)) {
          let endDateString = _dateUtils.default.serializeToDateOnly(newValue);

          let startDateString = _dateUtils.default.serializeToDateOnly(startDate);

          if (startDateString > endDateString) {
            return "End Date can't be before Start Date";
          }
        }
      }

      return true;
    },
    // endDateMonths: validateNumber({ gt: 0, integer: true, allowBlank: false, message: 'Must be a positive whole number'})
    endDateMonths: (key, newValue, oldValue, changes, content) => {
      let frequency = (0, _objectUtils.assignSingleProperty)(content, changes, 'frequency');

      if (frequency === _pledgeUtils.pledgeOneTimeFrequency) {
        if (!(0, _stringUtils.isNumber)(newValue) || !_numberUtils.default.isInteger(Number(newValue)) || Number(newValue) <= 0) {
          return 'Must be a positive whole number';
        }
      }

      return true;
    }
  };
  _exports.default = _default;
});