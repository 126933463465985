define("donor-elf-web/components/merge-contact/wizard/select-address", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "donor-elf-web/lib/contact-duplicate-utils"], function (_exports, _component, _templateFactory, _component2, _object, _contactDuplicateUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes>
    <h5>Select the Address to keep</h5>
    <div class='d-flex border-top border-bottom'>
      {{#each this.items as |item|}}
        <div
          {{on 'click' (fn this.selectContactAddress item.contact)}}
          class='{{if item.selected 'bg-brand-light'}} flex-1 p-1 cursor-pointer'
          role='button'
        >
          <div class='d-flex'>
            <div>
              <div class='form-check'>
                <Input
                  checked={{item.selected}}
                  type='checkbox'
                  class='form-check-input mt-1'
                />
              </div>
            </div>
            <div class='flex-grow-1'>
              <div>{{item.contact.address1}}</div>
              <div>{{item.contact.address2}}</div>
              {{#if item.contact.cityAndState}}
                <div>{{item.contact.cityAndState}} {{item.contact.zip}}</div>
              {{/if}}
              <div>{{item.contact.country}}</div>
            </div>
          </div>
        </div>
      {{/each}}
    </div>
  </div>
  */
  {
    "id": "hhwND6Cg",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,\"h5\"],[12],[1,\"Select the Address to keep\"],[13],[1,\"\\n  \"],[10,0],[14,0,\"d-flex border-top border-bottom\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"items\"]]],null]],null],null,[[[1,\"      \"],[11,0],[16,0,[29,[[52,[30,2,[\"selected\"]],\"bg-brand-light\"],\" flex-1 p-1 cursor-pointer\"]]],[24,\"role\",\"button\"],[4,[38,3],[\"click\",[28,[37,4],[[30,0,[\"selectContactAddress\"]],[30,2,[\"contact\"]]],null]],null],[12],[1,\"\\n        \"],[10,0],[14,0,\"d-flex\"],[12],[1,\"\\n          \"],[10,0],[12],[1,\"\\n            \"],[10,0],[14,0,\"form-check\"],[12],[1,\"\\n              \"],[8,[39,5],[[16,\"checked\",[30,2,[\"selected\"]]],[24,0,\"form-check-input mt-1\"],[24,4,\"checkbox\"]],null,null],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"flex-grow-1\"],[12],[1,\"\\n            \"],[10,0],[12],[1,[30,2,[\"contact\",\"address1\"]]],[13],[1,\"\\n            \"],[10,0],[12],[1,[30,2,[\"contact\",\"address2\"]]],[13],[1,\"\\n\"],[41,[30,2,[\"contact\",\"cityAndState\"]],[[[1,\"              \"],[10,0],[12],[1,[30,2,[\"contact\",\"cityAndState\"]]],[1,\" \"],[1,[30,2,[\"contact\",\"zip\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"            \"],[10,0],[12],[1,[30,2,[\"contact\",\"country\"]]],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"item\"],false,[\"each\",\"-track-array\",\"if\",\"on\",\"fn\",\"input\"]]",
    "moduleName": "donor-elf-web/components/merge-contact/wizard/select-address.hbs",
    "isStrictMode": false
  });

  let MergeContactWizardSelectAddressComponent = (_class = class MergeContactWizardSelectAddressComponent extends _component2.default {
    get items() {
      let {
        mergeContact
      } = this.args;
      return this.args.contacts.map(contact => {
        return {
          contact,
          selected: _contactDuplicateUtils.addressFields.every(field => mergeContact[field.property] === contact[field.property])
        };
      });
    }

    selectContactAddress(contact) {
      _contactDuplicateUtils.addressFields.forEach(item => {
        this.args.mergeContact[item.property] = contact[item.property];
      });
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "selectContactAddress", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectContactAddress"), _class.prototype)), _class);
  _exports.default = MergeContactWizardSelectAddressComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MergeContactWizardSelectAddressComponent);
});