define("donor-elf-web/routes/funds/index", ["exports", "@ember/routing/route", "@ember/object", "@ember/service", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _route, _object, _service, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend(_authenticatedRouteMixin.default, {
    fundsService: (0, _service.inject)(),
    navbarSettings: (0, _service.inject)(),
    storeCache: (0, _service.inject)(),

    beforeModel() {
      this.navbarSettings.title = 'Select a Fund';
    },

    afterModel() {
      this.navbarSettings.topNavbarComponent = 'top-navbar-funds-index';
    },

    actions: {
      willTransition(transition) {
        let intentName = (0, _object.get)(transition, 'intent.name') || '';

        if (intentName.length > 0 && intentName.indexOf('funds.index') !== 0) {
          this.navbarSettings.topNavbarComponent = '';
        }

        if ((0, _object.get)(transition, 'intent.name') === 'funds.fund') {
          // if transitioning to the fund, then set the title early as the data
          // is being retrieved
          this.navbarSettings.title = 'Dashboard';
        }
      }

    }
  });

  _exports.default = _default;
});