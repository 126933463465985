define("donor-elf-web/components/contacts-header", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class _default extends _component.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "headerBarLinks", [{
        route: 'funds.fund.contacts',
        models: [],
        label: 'All Contacts'
      }, {
        route: 'funds.fund.groups',
        models: [],
        label: 'By Group'
      }]);
    }

  }

  _exports.default = _default;
});