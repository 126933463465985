define("donor-elf-web/routes/organization/donors/donor", ["exports", "@ember/routing/route", "@ember/service", "@ember/object"], function (_exports, _route, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    navbarSettings: (0, _service.inject)(),
    store: (0, _service.inject)(),

    model(params) {
      return (0, _object.get)(this, 'store').findRecord('organization/entity-detail', params.id).then(record => {
        (0, _object.set)(this, 'navbarSettings.title', (0, _object.get)(record, 'entity.name'));
        return record;
      });
    },

    actions: {
      refreshDonorRoute() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});