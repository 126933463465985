define("donor-elf-web/controllers/funds/fund/more/mailchimp/connected-to-fund/delete", ["exports", "@ember/object/computed", "@ember/controller", "@ember/object", "@ember/service", "ember-concurrency"], function (_exports, _computed, _controller, _object, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    fundsService: (0, _service.inject)(),
    store: (0, _service.inject)(),
    fundSetting: (0, _computed.alias)('model.mailchimpSetting.fundSettings.firstObject'),
    listName: (0, _object.computed)('fundSetting.id', function () {
      let mcSetting = (0, _object.get)(this, 'model.mailchimpSetting');
      let listId = (0, _object.get)(this, 'fundSetting.mailchimpListId');

      if (listId) {
        return (0, _object.get)(mcSetting, 'lists').find(x => {
          return (0, _object.get)(x, 'id') === listId;
        }).name;
      } else {
        return '';
      }
    }),
    delete: (0, _emberConcurrency.task)(function* () {
      yield (0, _object.get)(this, 'fundsService').confirmValidFundToken();
      let model = (0, _object.get)(this, 'model.mailchimpSetting');
      yield model.destroyRecord();
      this.send('refreshMailchimpRoute');
      this.transitionToRoute('funds.fund.more.mailchimp');
    }).drop()
  });

  _exports.default = _default;
});