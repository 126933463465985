define("donor-elf-web/components/edit-line-item-contact", ["exports", "@ember/object/computed", "@ember/component", "@ember/runloop", "@ember/object", "@ember/service", "ember-changeset"], function (_exports, _computed, _component, _runloop, _object, _service, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // attrs
  // - contact
  //
  // actions
  // - onSave
  //   - arg1 = save() promise
  // - onCancel
  var _default = _component.default.extend({
    classNames: ['pt-2'],
    media: (0, _service.inject)(),
    changeset: null,

    didInsertElement() {
      this._super(...arguments);

      _runloop.run.scheduleOnce('afterRender', () => {
        this.element.querySelector('input').focus();
      });
    },

    showCard: (0, _computed.alias)('media.isMediumDown'),
    // Whether all the fields should be stacked or not
    stackedLayout: (0, _object.computed)('media.{isExtraLarge,isMedium,isSmall}', function () {
      return !((0, _object.get)(this, 'media.isExtraLarge') || (0, _object.get)(this, 'media.isMedium') || (0, _object.get)(this, 'media.isSmall'));
    }),

    didReceiveAttrs() {
      this._super(...arguments);

      let contact = (0, _object.get)(this, 'contact');
      let changeset = new _emberChangeset.default(contact);
      (0, _object.set)(this, 'changeset', changeset);
    },

    actions: {
      save() {
        let changeset = (0, _object.get)(this, 'changeset');
        (0, _object.get)(this, 'onSave')(changeset.save());
      },

      cancel() {
        (0, _object.get)(this, 'onCancel')();
      }

    }
  });

  _exports.default = _default;
});