define("donor-elf-web/components/merge-contact/wizard/generic-differences", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes>
    <h5>Select the field values to keep</h5>
    <div class='border-bottom'>
      {{#each this.items as |item|}}
        <div class='d-flex border-top'>
          {{#each item.contactItems as |contactItem|}}
            <div
              {{on 'click' (fn this.selectContactProperty item contactItem)}}
              class='{{if contactItem.selected 'bg-brand-light'}} flex-1 p-1 cursor-pointer'
              role='button'
            >
              <div class='d-flex'>
                <div>
                  <div class='form-check'>
                    <Input
                      checked={{contactItem.selected}}
                      type='checkbox'
                      class='form-check-input mt-1'
                    />
                  </div>
                </div>
                <div class='flex-grow-1'>
                  <Form::Underline::Label @label={{item.label}}>
                    {{#if (eq item.type 'boolean')}}
                      {{if contactItem.value 'Yes' 'No'}}
                    {{else}}
                      {{contactItem.value}}
                    {{/if}}
                  </Form::Underline::Label>
                </div>
              </div>
            </div>
          {{/each}}
        </div>
      {{/each}}
    </div>
  </div>
  */
  {
    "id": "9xzpnA86",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n  \"],[10,\"h5\"],[12],[1,\"Select the field values to keep\"],[13],[1,\"\\n  \"],[10,0],[14,0,\"border-bottom\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"items\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"d-flex border-top\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,2,[\"contactItems\"]]],null]],null],null,[[[1,\"          \"],[11,0],[16,0,[29,[[52,[30,3,[\"selected\"]],\"bg-brand-light\"],\" flex-1 p-1 cursor-pointer\"]]],[24,\"role\",\"button\"],[4,[38,3],[\"click\",[28,[37,4],[[30,0,[\"selectContactProperty\"]],[30,2],[30,3]],null]],null],[12],[1,\"\\n            \"],[10,0],[14,0,\"d-flex\"],[12],[1,\"\\n              \"],[10,0],[12],[1,\"\\n                \"],[10,0],[14,0,\"form-check\"],[12],[1,\"\\n                  \"],[8,[39,5],[[16,\"checked\",[30,3,[\"selected\"]]],[24,0,\"form-check-input mt-1\"],[24,4,\"checkbox\"]],null,null],[1,\"\\n                \"],[13],[1,\"\\n              \"],[13],[1,\"\\n              \"],[10,0],[14,0,\"flex-grow-1\"],[12],[1,\"\\n                \"],[8,[39,6],null,[[\"@label\"],[[30,2,[\"label\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[28,[37,7],[[30,2,[\"type\"]],\"boolean\"],null],[[[1,\"                    \"],[1,[52,[30,3,[\"value\"]],\"Yes\",\"No\"]],[1,\"\\n\"]],[]],[[[1,\"                    \"],[1,[30,3,[\"value\"]]],[1,\"\\n\"]],[]]],[1,\"                \"]],[]]]]],[1,\"\\n              \"],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[3]],null],[1,\"      \"],[13],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"item\",\"contactItem\"],false,[\"each\",\"-track-array\",\"if\",\"on\",\"fn\",\"input\",\"form/underline/label\",\"eq\"]]",
    "moduleName": "donor-elf-web/components/merge-contact/wizard/generic-differences.hbs",
    "isStrictMode": false
  });

  let MergeContactWizardGenericDifferencesComponent = (_class = class MergeContactWizardGenericDifferencesComponent extends _component2.default {
    get items() {
      let {
        contacts,
        mergeContact
      } = this.args;
      return this.args.differenceItems.map(x => {
        return {
          property: x.property,
          label: x.label,
          type: x.type,
          contactItems: contacts.map(contact => {
            let value = contact[x.property];
            return {
              value: value,
              selected: value == mergeContact[x.property]
            };
          })
        };
      });
    }

    selectContactProperty(item, contactItem) {
      this.args.mergeContact[item.property] = contactItem.value;
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "selectContactProperty", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectContactProperty"), _class.prototype)), _class);
  _exports.default = MergeContactWizardGenericDifferencesComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MergeContactWizardGenericDifferencesComponent);
});