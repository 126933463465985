define("donor-elf-web/lib/models/journal-report-default", ["exports", "@ember/debug"], function (_exports, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  const {
    currency,
    moment
  } = window;

  class JournalReportDefault {
    constructor({
      journalInfo,
      journalReportModel,
      formatDateService
    }) {
      _defineProperty(this, "intervalItems", void 0);

      _defineProperty(this, "lastFullIntervalItem", void 0);

      _defineProperty(this, "yesDecisions", void 0);

      _defineProperty(this, "intervalDates", void 0);

      (false && !(Object.keys(journalReportModel.data).sort().join(',') === 'completed_stage_tasks,created_stage_tasks,decision_stages,decisions,yes_decisions') && (0, _debug.assert)(`expecting journalReport.data to have these keys: completed_stage_tasks,created_stage_tasks,decision_stages,decisions,yes_decisions. Actual keys: ${Object.keys(journalReportModel.data).sort().join(',')}`, Object.keys(journalReportModel.data).sort().join(',') === 'completed_stage_tasks,created_stage_tasks,decision_stages,decisions,yes_decisions'));
      this.intervalDates = journalReportModel.intervalDates;
      this.yesDecisions = [];
      let goalAmountById = {};
      let cumulativeGoalDecisionYesAmounts = {};
      journalInfo.goals.forEach(goal => {
        goalAmountById[goal.id] = Math.max(goal.amount || 0, 1);
        cumulativeGoalDecisionYesAmounts[goal.id] = 0;
        this.yesDecisions[goal.id] = [];
      });
      let completedStageTasks = journalReportModel.data.completed_stage_tasks;
      let createdStageTasksByDate = journalReportModel.data.created_stage_tasks;
      let decisionsData = journalReportModel.data.decisions;
      let decisionStagesData = journalReportModel.data.decision_stages;
      let yesDecisions = journalReportModel.data.yes_decisions;
      decisionStagesData.forEach(item => {
        item.started = item.started || item.asked || item.decision;

        if (item.decision != null && item.started > item.decision) {
          item.started = item.decision;
        } else if (item.asked != null && item.started > item.asked) {
          item.started = item.asked;
        }

        item.asked = item.asked || item.decision;

        if (item.decision != null && item.asked > item.decision) {
          item.asked = item.decision;
        }
      });
      this.intervalItems = this.intervalDates.map(dateInfo => {
        let date = dateInfo.start;
        let dateEnd = dateInfo.end;
        let completedStageWork = {
          maxTotal: 0
        };
        let createdStageTasks = {};
        journalInfo.sortedStages.forEach(stage => {
          var _completedStageTasks$, _createdStageTasksByD;

          let completedAttempted = {
            completed: 0,
            attempted: 0,
            ...((_completedStageTasks$ = completedStageTasks[date]) === null || _completedStageTasks$ === void 0 ? void 0 : _completedStageTasks$[stage.id])
          };
          completedAttempted.total = completedAttempted.completed + completedAttempted.attempted;
          completedStageWork[stage.id] = completedAttempted;

          if (completedAttempted.total > completedStageWork.maxTotal) {
            completedStageWork.maxTotal = completedAttempted.total;
          }

          createdStageTasks[stage.id] = (_createdStageTasksByD = createdStageTasksByDate[date]) === null || _createdStageTasksByD === void 0 ? void 0 : _createdStageTasksByD[stage.id];
        });
        let decisions = {
          allGoals: {
            yesCount: 0,
            noCount: 0,
            totalCount: 0
          }
        };
        journalInfo.goals.forEach(goal => {
          var _decisionsData$date;

          let decisionItem = ((_decisionsData$date = decisionsData[date]) === null || _decisionsData$date === void 0 ? void 0 : _decisionsData$date[goal.id]) || {};
          let item = {
            yesCount: decisionItem.yes_count || 0,
            noCount: decisionItem.no_count || 0,
            yesAmount: decisionItem.yes_amount || 0
          };
          item.totalCount = item.yesCount + item.noCount;
          decisions.allGoals.yesCount += item.yesCount;
          decisions.allGoals.noCount += item.noCount;
          decisions.allGoals.totalCount += item.totalCount;
          cumulativeGoalDecisionYesAmounts[goal.id] = currency(cumulativeGoalDecisionYesAmounts[goal.id]).add(item.yesAmount).value;
          item.yesCumulativeAmount = cumulativeGoalDecisionYesAmounts[goal.id];
          item.yesCumulativePercent = Math.round(item.yesCumulativeAmount * 100 / goalAmountById[goal.id]);
          decisions[goal.id] = item;
        });
        let decisionStages = {
          notStarted: [],
          preAsk: [],
          waitingOnDecision: [],
          decisionMade: []
        };
        decisionStagesData.forEach(x => {
          if (x.decision <= dateEnd) {
            decisionStages.decisionMade.push(x.contact_id);
          } else if (x.asked <= dateEnd) {
            decisionStages.waitingOnDecision.push(x.contact_id);
          } else if (x.started <= dateEnd) {
            decisionStages.preAsk.push(x.contact_id);
          } else if (x.added <= dateEnd) {
            decisionStages.notStarted.push(x.contact_id);
          }
        });
        return {
          date,
          dateEnd,
          dateRangeFormatted: JournalReportDefault.getDateRangeFormatted({
            formatDateService,
            ...dateInfo
          }),
          completedStageWork,
          createdStageTasks,
          decisions,
          decisionStages
        };
      });
      this.lastFullIntervalItem = this.intervalItems[this.intervalItems.length - 2];

      if (journalInfo.journal.closedDate != null) {
        this.lastFullIntervalItem = this.intervalItems[this.intervalItems.length - 1];
      }

      yesDecisions.forEach(item => {
        this.yesDecisions[item.journal_goal_id].push({
          amount: Number(item.amount),
          decisionDate: item.decision_date,
          firstGiftDate: item.first_gift_date
        });
      });
    }
    /*
    [{
      date: '2020-10-18',
      dateEnd: '2020-10-24',
      dateRangeFormatted: '10/18 - 10/24',
      completedStageWork: { 'stage-1': { completed: 1, attempted: 2, total: 3 }, maxTotal: 3},
      createdStageTasks: { 'stage-1': 4, 'stage-2': 2},
      decisions: {
        journalGoalId: { yesCount: 1, noCount: 2, totalCount: 3, yesAmount: 50, yesCumulativeAmount: 98, yesCumulativePercent: 49 },
        allGoals: { yesCount: 1, noCount: 2, totalCount: 3 }
      },
      decisionStages: { notStarted: [contactId], preAsk: [contactId], waitingOnDecision: [contactId], decisionMade: [contactId] }
    }]
    */


    // also used in JournalReportIntervarsity
    static getDateRangeFormatted({
      start,
      end,
      formatDateService
    }) {
      if (start === end) {
        return formatDateService.format(start, {
          noYear: true
        });
      } else {
        return `${formatDateService.format(start, {
          noYear: true
        })} - ${formatDateService.format(end, {
          noYear: true
        })}`;
      }
    }

  }

  _exports.default = JournalReportDefault;
});