define("donor-elf-web/helpers/get-custom-property-item", ["exports", "@ember/component/helper", "@ember/object"], function (_exports, _helper, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _helper.default.extend({
    /*
      Returns a custom property item by its label
       namedArgs
        - custom
          - contact.custom or entity.custom
        - label
          - the label of the property to show
    */
    compute(args, namedArgs) {
      let properties = (0, _object.get)(namedArgs.custom, 'properties');

      if (properties == null) {
        return undefined;
      }

      return properties.find(x => x['l'] === namedArgs.label);
    }

  });

  _exports.default = _default;
});