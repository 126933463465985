define("donor-elf-web/validations/contact-date", ["exports", "@ember/object", "@ember/utils", "ember-changeset-validations/validators"], function (_exports, _object, _utils, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    label: (0, _validators.validatePresence)({
      presence: true,
      message: "Can't be blank"
    }),
    originalDate: (key, newValue
    /*, oldValue, changes, content*/
    ) => {
      if (newValue != null && typeof newValue.getMonth === 'function') {
        return true;
      }

      return 'Must be a date';
    },
    alertNumber: (key, newValue, oldValue, changes, content) => {
      if (enableAlert(changes, content)) {
        let numVal = Number(newValue);

        if (!(0, _utils.isEmpty)(newValue) && isNumber(numVal) && isInteger(numVal)) {
          return true;
        } else {
          return 'Must provide a number';
        }
      }

      return true;
    },
    alertUnit: (key, newValue, oldValue, changes, content) => {
      if (enableAlert(changes, content) && (0, _utils.isEmpty)(newValue)) {
        return "Can't be blank";
      }

      return true;
    }
  };
  _exports.default = _default;

  function enableAlert(changes, content) {
    let changesEnableAlert = (0, _object.get)(changes, 'enableAlert');

    if (changesEnableAlert !== undefined) {
      return changesEnableAlert;
    }

    return (0, _object.get)(content, 'enableAlert');
  } // taken from https://github.com/offirgolan/ember-validators/blob/master/addon/number.js#L109


  function isNumber(value) {
    return typeof value === 'number' && !isNaN(value);
  }

  function isInteger(value) {
    return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
  }
});