define("donor-elf-web/components/liquid-wormhole", ["exports", "liquid-wormhole/components/liquid-wormhole"], function (_exports, _liquidWormhole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _liquidWormhole.default;
    }
  });
});