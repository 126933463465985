define("donor-elf-web/controllers/login", ["exports", "@ember/controller", "@ember/object", "@ember/utils", "@ember/service", "@glimmer/tracking", "@github/webauthn-json/browser-ponyfill"], function (_exports, _controller, _object, _utils, _service, _tracking, WebAuthnJSON) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let LoginController = (_class = class LoginController extends _controller.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "model", void 0);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "settings", _descriptor2, this);

      _initializerDefineProperty(this, "currentUser", _descriptor3, this);

      _defineProperty(this, "queryParams", ['fult']);

      _initializerDefineProperty(this, "fult", _descriptor4, this);

      _initializerDefineProperty(this, "loggingIn", _descriptor5, this);

      _initializerDefineProperty(this, "errorMessage", _descriptor6, this);

      _defineProperty(this, "passkeyCredential", void 0);

      _defineProperty(this, "passkeyChallenge", void 0);

      _defineProperty(this, "passwordFormGroupElement", void 0);
    }

    get attemptedTransition() {
      return this.session.attemptedTransition;
    }

    templateDidInsert() {
      if (this.model.hasLoginToken || this.model.isForeignUserLoginToken) {
        this.submit();
      }
    }

    templateWillDestroy() {
      this.loggingIn = false;
      this.errorMessage = undefined;
      this.passkeyCredential = undefined;
      this.passkeyChallenge = undefined;
    }

    passwordFormGroupDidInsert(e) {
      this.passwordFormGroupElement = e;
    }

    async loginWithPasskey(args) {
      this.passkeyChallenge = args.passkeyChallenge;
      let options = WebAuthnJSON.parseRequestOptionsFromJSON({
        'publicKey': args.passkeyOptions
      });
      let response = await WebAuthnJSON.get(options);
      this.passkeyCredential = response;
      this.submit();
    }

    async submit() {
      this.loggingIn = true;
      this.errorMessage = undefined;
      let email = this.model.email;

      if (this.model.isForeignUserLoginToken) {
        email = 'foreign_user_login_token';
        this.fult = undefined;
      }

      let password = this.model.password;

      if (this.passkeyCredential) {
        password = JSON.stringify({
          passkey_credential: this.passkeyCredential,
          passkey_challenge: this.passkeyChallenge
        });
      }

      try {
        await this.session.authenticate('authenticator:oauth2', email, password, undefined, {
          passkey_login: (0, _utils.isPresent)(this.passkeyCredential)
        });
        await this.currentUser.load();

        if (this.attemptedTransition) {
          let attemptedTransition = this.attemptedTransition;
          this.session.attemptedTransition = undefined;
          attemptedTransition.retry();
        } else {
          this.transitionToRoute('funds.fund', 'selected-fund');
        }
      } catch (reason) {
        this.passkeyCredential = undefined;
        this.passkeyChallenge = undefined; // If the foreign user login token didn't authenticate, we want to remove it

        if (this.model.isForeignUserLoginToken) {
          this.model.isForeignUserLoginToken = false;
        }

        if (reason == null) {
          this.errorMessage = 'Whoops! There was an error logging in. Please refresh the page and try again';
        } else {
          if (reason.login_with_passkey) {
            this.loginWithPasskey({
              passkeyOptions: reason.passkey_options,
              passkeyChallenge: reason.passkey_challenge
            });
          } else if ((0, _utils.isPresent)(reason.redirectTo)) {
            window.location.assign(reason.redirectTo);
          } else {
            this.errorMessage = reason.error;
          }
        }

        this.loggingIn = false;

        if (this.model.hasLoginToken) {
          this.model.email = '';
          this.model.password = '';
          this.model.hasLoginToken = false;
          this.errorMessage = "You were supposed to automatically be logged in, but there was an error. Please send us an email at support@donorelf.com to let us know you weren't automatically logged in";
        }

        if (this.errorMessage && this.errorMessage.toLowerCase().indexOf('password') >= 0) {
          var _this$passwordFormGro, _this$passwordFormGro2;

          (_this$passwordFormGro = this.passwordFormGroupElement) === null || _this$passwordFormGro === void 0 ? void 0 : (_this$passwordFormGro2 = _this$passwordFormGro.querySelector('input')) === null || _this$passwordFormGro2 === void 0 ? void 0 : _this$passwordFormGro2.select();
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "settings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "fult", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "loggingIn", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "errorMessage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "templateWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateWillDestroy"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "passwordFormGroupDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "passwordFormGroupDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loginWithPasskey", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loginWithPasskey"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype)), _class);
  _exports.default = LoginController;
});