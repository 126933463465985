define("donor-elf-web/components/goal/monthly-gifts-chart", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/runloop"], function (_exports, _computed, _component, _object, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    moment,
    Highcharts
  } = window;
  /*
    This is made specifically for the Funds Summary report. It was taken from
    the goal/balance-and-monthly-gifts-chart
  
    attrs
    - goal
    - height
    - width
  */

  var _default = _component.default.extend({
    goal: null,
    height: 100,
    width: 200,
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    monthlyGifts: (0, _computed.alias)('goal.goalData.monthly_gifts'),
    monthlyGiftsChartCategories: (0, _computed.map)('monthlyGifts', function (item) {
      return moment(item.month).format('MMM YY');
    }),
    monthlyGiftsChartGiftsSeries: (0, _object.computed)('monthlyGifts.[]', function () {
      let monthlyGifts = (0, _object.get)(this, 'monthlyGifts');
      let series = [];

      for (let i = 0; i < (0, _object.get)(monthlyGifts, 'length'); i++) {
        let amount = (0, _object.get)(monthlyGifts[i], 'amount');
        series.push({
          y: amount
        });
      }

      return series;
    }),
    monthlyGiftsChartGoalSeries: (0, _object.computed)('goal.goalAmount', 'monthlyGifts.[]', function () {
      let goalAmount = (0, _object.get)(this, 'goal.goalAmount');

      if ((0, _object.get)(this, 'goal.goalType') === 'annual') {
        goalAmount = goalAmount / 12;
      }

      let monthlyGifts = (0, _object.get)(this, 'monthlyGifts');
      return monthlyGifts.map(function () {
        return {
          y: goalAmount
        };
      });
    }),
    monthlyGiftsChartSettings: (0, _object.computed)('monthlyGifts.[]', function () {
      return {
        chart: {
          height: (0, _object.get)(this, 'height'),
          width: (0, _object.get)(this, 'width')
        },
        title: {
          text: ''
        },
        credits: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        xAxis: {
          labels: {
            enabled: false
          },
          visible: false
        },
        yAxis: {
          min: 0,
          labels: {
            enabled: false
          },
          title: {
            text: null
          }
        },
        legend: {
          enabled: false
        },
        series: [{
          type: 'column',
          name: 'Monthly Gifts',
          color: '#0b68a1',
          data: (0, _object.get)(this, 'monthlyGiftsChartGiftsSeries')
        }, {
          type: 'line',
          name: 'Monthly Goal',
          color: '#f0ad4e',
          lineWidth: 3,
          marker: {
            enabled: false
          },
          data: (0, _object.get)(this, 'monthlyGiftsChartGoalSeries')
        }]
      };
    }),

    didInsertElement() {
      this._super(...arguments);

      _runloop.run.scheduleOnce('afterRender', () => {
        Highcharts.chart(this.element, this.monthlyGiftsChartSettings);
      });
    }

  });

  _exports.default = _default;
});