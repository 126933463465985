define("donor-elf-web/controllers/funds/fund/contacts/add", ["exports", "@ember/controller", "@ember/object", "@ember/service", "donor-elf-web/models/contact", "ember-concurrency"], function (_exports, _controller, _object, _service, _contact, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AddContactController = (_class = class AddContactController extends _controller.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "contactsCache", _descriptor, this);

      _initializerDefineProperty(this, "fundsService", _descriptor2, this);

      _defineProperty(this, "nameOrMailingNameManuallyChanged", false);
    }

    templateDidInsert() {
      this.nameOrMailingNameManuallyChanged = false;
    }

    generateNameAndMailingName() {
      if (!this.nameOrMailingNameManuallyChanged) {
        let changeset = this.model.changeset;
        let names = (0, _contact.generateNameFields)({
          firstName: changeset.get('firstName'),
          lastName: changeset.get('lastName'),
          spouseFirstName: changeset.get('spouseFirstName'),
          spouseLastName: changeset.get('spouseLastName')
        });
        changeset.set('name', names.name);
        changeset.set('mailingName', names.mailingName);
      }
    }

    handleNameOrMailingNameManuallyChanged() {
      this.nameOrMailingNameManuallyChanged = true;
    }

    *save() {
      var _changeset$get2;

      let changeset = this.model.changeset;
      ['name', 'mailingName', 'firstName', 'lastName', 'spouseFirstName'].forEach(nameProperty => {
        var _changeset$get;

        changeset.set(nameProperty, (_changeset$get = changeset.get(nameProperty)) === null || _changeset$get === void 0 ? void 0 : _changeset$get.trim());
      });
      changeset.set('name', (_changeset$get2 = changeset.get('name')) === null || _changeset$get2 === void 0 ? void 0 : _changeset$get2.trim());
      yield changeset.validate();

      if (changeset.get('isValid')) {
        yield changeset.save();
        this.contactsCache.contacts.addObject(this.model.record);
        this.transitionToRoute('funds.fund.contacts.contact', changeset.get('id'));
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "contactsCache", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "fundsService", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "templateDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "templateDidInsert"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "generateNameAndMailingName", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "generateNameAndMailingName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleNameOrMailingNameManuallyChanged", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleNameOrMailingNameManuallyChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype)), _class);
  _exports.default = AddContactController;
});