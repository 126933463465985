define("donor-elf-web/templates/funds/fund/review-items", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "gjug5Z1Z",
    "block": "[[[8,[39,0],[[4,[38,1],[[30,0,[\"templateDidInsert\"]]],null]],null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"items\"]]],null]],null],null,[[[1,\"    \"],[10,0],[12],[1,\"\\n      \"],[8,[39,4],[[24,0,\"d-inline-block mb-3 tw-transition tw-transform tw-duration-700\"]],[[\"@reviewItem\",\"@onSave\",\"@classToApplyOnSave\"],[[30,1],[30,0,[\"handleSave\"]],\"tw-scale-0\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],[[[1,\"    There are no items to review.\\n\"]],[]]]],[]]]]]],[\"item\"],false,[\"card\",\"did-insert\",\"each\",\"-track-array\",\"review-item/card\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/review-items.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});