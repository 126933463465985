define("donor-elf-web/templates/components/form-card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "SaC64DmX",
    "block": "[[[11,0],[24,0,\"row\"],[17,1],[12],[1,\"\\n  \"],[10,0],[15,0,[36,0]],[12],[1,\"\\n    \"],[8,[39,1],[[24,0,\"de-card-no-border-sm-down\"]],null,[[\"default\"],[[[[1,\"\\n      \"],[18,2,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"default-form-columns\",\"card\",\"yield\"]]",
    "moduleName": "donor-elf-web/templates/components/form-card.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});