define("donor-elf-web/templates/reset-password/email-sent", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "XTlL74NX",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n  \"],[10,2],[12],[1,\"\\n    Thanks. You should receive an email in the next couple of minutes with a\\n    link you can click that will let you reset your password.\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n    If you don't receive it please email us at support@donorelf.com to let us know.\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"mt-4\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,0,\"btn btn-warning\"]],[[\"@route\"],[\"login\"]],[[\"default\"],[[[[1,\"\\n      Login Page\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[],false,[\"form-card\",\"link-to\"]]",
    "moduleName": "donor-elf-web/templates/reset-password/email-sent.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});