define("donor-elf-web/controllers/funds/fund/tasks/delete", ["exports", "@ember/controller", "@ember/object", "@ember/service", "ember-concurrency"], function (_exports, _controller, _object, _service, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    fundsService: (0, _service.inject)(),
    delete: (0, _emberConcurrency.task)(function* () {
      yield (0, _object.get)(this, 'fundsService').confirmValidFundToken();
      let model = (0, _object.get)(this, 'model');
      let contactId = (0, _object.get)(model, 'contactId');
      yield model.destroyRecord();
      this.transitionToRoute('funds.fund.contacts.contact', contactId);
    }).drop()
  });

  _exports.default = _default;
});