define("donor-elf-web/validators/date-or-empty", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateDate;

  function validateDate() {
    return (key, newValue
    /*, oldValue, changes, content*/
    ) => {
      if ((0, _utils.isEmpty)(newValue) || typeof newValue.getMonth === 'function') {
        return true;
      }

      return 'Must be a date';
    };
  }
});