define("donor-elf-web/templates/admin/upload/complete", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "he8WCZFZ",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[10,2],[12],[1,\"\\n    The files have been uploaded and are currently being processed. It usually\\n    takes 10 to 20 minutes to complete the processing.\\n  \"],[13],[1,\"\\n  \"],[6,[39,1],null,[[\"class\",\"route\",\"model\"],[\"btn btn-warning\",\"funds.fund.dashboard\",[33,2,[\"selectedFund\",\"id\"]]]],[[\"default\"],[[[[1,\"OK, I'm Done\"]],[]]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"de-form-card\",\"link-to\",\"fundsService\"]]",
    "moduleName": "donor-elf-web/templates/admin/upload/complete.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});