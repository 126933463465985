define("donor-elf-web/lib/object-utils", ["exports", "@ember/string", "@ember/utils"], function (_exports, _string, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assignSingleProperty = assignSingleProperty;
  _exports.isEmptyObject = isEmptyObject;
  _exports.isNothing = isNothing;
  _exports.default = void 0;
  var _default = {
    decamelizeKeys(object) {
      let camelizedObject = {};

      for (let key of Object.keys(object)) {
        camelizedObject[(0, _string.decamelize)(key)] = object[key];
      }

      return camelizedObject;
    }

  }; // uses Object.assign() to return a single property off the objects

  _exports.default = _default;

  function assignSingleProperty(secondaryObject, primaryObject, property) {
    let primaryFragment = {};

    if (primaryObject.hasOwnProperty(property) || primaryObject[property] !== undefined) {
      primaryFragment[property] = primaryObject[property];
    }

    let secondaryFragment = {}; // can't just use hasOwnProperty because it doesn't work with Ember Data Models

    if (secondaryObject.hasOwnProperty(property) || secondaryObject[property] !== undefined) {
      secondaryFragment[property] = secondaryObject[property];
    }

    return Object.assign({}, secondaryFragment, primaryFragment)[property];
  } // from https://stackoverflow.com/a/32108184


  function isEmptyObject(val) {
    if (typeof val !== 'object') {
      return false;
    }

    for (let prop in val) {
      if (Object.hasOwn(val, prop)) {
        return false;
      }
    }

    return true;
  } // a more robust version of Ember's isEmpty
  // it checks for strings that are just whitespace
  // and for objects that are empty


  function isNothing(val) {
    if ((0, _utils.isEmpty)(val)) {
      return true;
    }

    if (typeof val === 'string' && (0, _utils.isEmpty)(val.trim())) {
      return true;
    }

    if (typeof val === 'object' && isEmptyObject(val)) {
      return true;
    }

    return false;
  }
});