define("donor-elf-web/routes/funds/fund/more/donor-hub/connect", ["exports", "@ember/routing/route", "@ember/object", "@ember/service"], function (_exports, _route, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _route.default.extend({
    navbarSettings: (0, _service.inject)(),
    store: (0, _service.inject)(),

    beforeModel() {
      (0, _object.set)(this, 'navbarSettings.title', 'Donor Hub Login Info');
    },

    model() {
      let record = (0, _object.get)(this.modelFor('funds.fund.more.donor-hub'), 'record');

      if (!record) {
        record = (0, _object.get)(this, 'store').createRecord('tnt-fund');
      }

      return {
        record
      };
    }

  });

  _exports.default = _default;
});