define("donor-elf-web/validations/journal", ["exports", "@ember/utils", "donor-elf-web/lib/date-utils", "donor-elf-web/lib/object-utils"], function (_exports, _utils, _dateUtils, _objectUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    startDate: (key, newValue, oldValue, changes, content) => {
      if ((0, _utils.isEmpty)(newValue)) {
        return "Start Date can't be blank";
      }

      if (_dateUtils.default.serializeToDateOnly(newValue) > _dateUtils.default.serializeToDateOnly(new Date())) {
        return "Start Date can't be in the future";
      }

      let goalDate = (0, _objectUtils.assignSingleProperty)(content, changes, 'goalDate');

      if (goalDate && goalDate <= newValue) {
        return "Start Date has to be before Goal Date";
      }

      return true;
    },
    goalDate: (key, newValue, oldValue, changes, content) => {
      if ((0, _utils.isEmpty)(newValue)) {
        return "Goal Date can't be blank";
      }

      return true;
    },
    closedDate: (key, newValue, oldValue, changes, content) => {
      if ((0, _utils.isEmpty)(newValue)) {
        return true;
      }

      let startDate = (0, _objectUtils.assignSingleProperty)(content, changes, 'startDate');

      if (startDate >= newValue) {
        return "Closed Date has to be after Start Date";
      }

      return true;
    }
  };
  _exports.default = _default;
});