define("donor-elf-web/templates/components/form-group-select", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "zGz/Zlv1",
    "block": "[[[41,[33,1],[[[41,[33,2],[[[1,\"    \"],[10,\"label\"],[12],[2,[36,1]],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"label\"],[12],[1,[34,1]],[13],[1,\"\\n\"]],[]]]],[]],null],[10,\"select\"],[14,0,\"form-control\"],[15,\"onchange\",[28,[37,3],[[30,0],\"onSelectChange\"],[[\"value\"],[\"target.value\"]]]],[15,\"disabled\",[36,4]],[12],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[33,7]],null]],null],null,[[[1,\"    \"],[10,\"option\"],[15,2,[30,1,[\"val\"]]],[15,\"selected\",[28,[37,8],[[28,[37,9],[[33,10],[33,11]],null],[30,1,[\"val\"]]],null]],[12],[1,[30,1,[\"label\"]]],[13],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\"],[41,[33,12],[[[1,\"  \"],[10,0],[14,0,\"has-errors-message\"],[12],[1,[28,[35,9],[[33,10,[\"error\"]],[28,[37,13],[[33,11],\".validation\"],null]],null]],[13],[1,\"\\n\"]],[]],null]],[\"item\"],false,[\"if\",\"label\",\"labelIsHtmlSafe\",\"action\",\"disabled\",\"each\",\"-track-array\",\"options\",\"eq\",\"get\",\"changeset\",\"property\",\"hasErrors\",\"concat\"]]",
    "moduleName": "donor-elf-web/templates/components/form-group-select.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});