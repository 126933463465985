define("donor-elf-web/controllers/funds/fund/tasks/index", ["exports", "@ember/object/computed", "@ember/controller", "@ember/object", "@ember/utils", "@ember/service", "@ember/string", "donor-elf-web/lib/object-utils", "ember-parachute", "ember-changeset"], function (_exports, _computed, _controller, _object, _utils, _service, _string, _objectUtils, _emberParachute, _emberChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.queryParams = void 0;
  const queryParams = new _emberParachute.default({
    status: {
      defaultValue: 'Unfinished',
      refresh: true
    },
    contactId: {
      defaultValue: '',
      refresh: true
    },
    sortBy: {
      defaultValue: 'dueAt',
      refresh: true
    },
    sortDir: {
      defaultValue: 'asc',
      refresh: true
    },
    page: {
      defaultValue: 1,
      refresh: true
    },
    pageSize: {
      defaultValue: 50,
      refresh: true
    }
  });
  _exports.queryParams = queryParams;

  var _default = _controller.default.extend(queryParams.Mixin, {
    fundsService: (0, _service.inject)(),
    contactsCache: (0, _service.inject)(),
    status: 'Unfinished',
    contactId: '',
    page: 1,
    pageSize: 50,
    sortBy: 'dueAt',
    sortDir: 'asc',
    filterExpanded: false,
    filterChangeset: null,
    loading: false,
    queryParamsChanged: (0, _computed.or)('queryParamsState.{from,to,sortBy,sortDir,contactId,page,pageSize}.changed'),

    setup({
      queryParams
    }) {
      this.fetchData(queryParams);
    },

    queryParamsDidChange({
      shouldRefresh,
      queryParams
    }) {
      if (shouldRefresh) {
        this.fetchData(queryParams);
      }
    },

    reset(event, isExiting) {
      if (isExiting) {
        this.resetQueryParams();
        (0, _object.setProperties)(this, {
          filterChangeset: null,
          filterExpanded: false
        });
      }
    },

    fetchData(queryParams) {
      (0, _object.set)(this, 'loading', true);
      let filter = {
        offset: (queryParams.page - 1) * queryParams.pageSize,
        limit: queryParams.pageSize
      };
      let filterRecord = {
        status: queryParams.status,
        page: queryParams.page,
        pageSize: queryParams.pageSize
      };

      if (queryParams.status !== 'All') {
        filter.status = queryParams.status;
      }

      if (!(0, _utils.isEmpty)(queryParams.contactId)) {
        filter.contactId = queryParams.contactId;
        filterRecord.contactId = queryParams.contactId;
      }

      let filterChangeset = new _emberChangeset.default(filterRecord);
      (0, _object.set)(this, 'filterChangeset', filterChangeset);
      let sort = (0, _string.decamelize)(queryParams.sortBy);

      if (queryParams.sortDir === 'desc') {
        sort = `-${sort}`;
      }

      return (0, _object.get)(this, 'fundsService').confirmValidFundToken().then(() => {
        return (0, _object.get)(this, 'store').query('task', {
          filter: _objectUtils.default.decamelizeKeys(filter),
          sort: sort
        }).then(records => {
          let totalCount = (0, _object.get)(records, 'meta.total_count');

          if ((0, _utils.isEmpty)(totalCount)) {
            totalCount = '0';
          }

          let totalPages = Math.ceil(Number(totalCount) / queryParams.pageSize);
          (0, _object.setProperties)(this, {
            tasks: records,
            page: queryParams.page,
            totalPages: totalPages,
            loading: false
          });
        });
      });
    },

    statusOptions: ['All', 'Unfinished', 'Finished'],
    filterMessage: (0, _object.computed)('status', 'contactId', function () {
      let messages = [];
      let {
        status,
        contactId
      } = (0, _object.getProperties)(this, 'status', 'contactId');

      if (!(0, _utils.isEmpty)(status)) {
        messages.push(`${status} tasks`);
      }

      if (!(0, _utils.isEmpty)(contactId)) {
        let contact = (0, _object.get)(this, 'contactsCache').getById(contactId);

        if (contact) {
          messages.push(`For '${(0, _object.get)(contact, 'name')}'`);
        }
      }

      if (messages.length === 0) {
        return 'All tasks';
      }

      return messages.join(', ');
    }),
    actionsMenuItems: (0, _object.computed)('filterExpanded', function () {
      return [{
        label: (0, _object.get)(this, 'filterExpanded') ? 'Collapse Filter' : 'Adjust Filter',
        svgJar: 'filter',
        onClick: () => {
          this.send('toggleExpanded');
        }
      }];
    }),
    actions: {
      setSortBy(property) {
        if (this.sortBy === property) {
          if (this.sortDir === 'asc') {
            (0, _object.set)(this, 'sortDir', 'desc');
          } else {
            (0, _object.set)(this, 'sortDir', 'asc');
          }
        } else {
          (0, _object.setProperties)(this, {
            sortBy: property,
            sortDir: 'asc'
          });
        }
      },

      applyFilter() {
        let filterChangeset = (0, _object.get)(this, 'filterChangeset');

        if ((0, _object.get)(filterChangeset, 'isPristine')) {
          alert('No filter values have changed');
          return;
        }

        (0, _object.setProperties)(this, {
          status: (0, _object.get)(filterChangeset, 'status') || '',
          contactId: (0, _object.get)(filterChangeset, 'contactId') || '',
          page: 1
        });
      },

      toggleExpanded() {
        this.toggleProperty('filterExpanded');
      },

      changePage(pageNumber) {
        (0, _object.set)(this, 'page', pageNumber);
      }

    }
  });

  _exports.default = _default;
});