define("donor-elf-web/routes/reset-password/token", ["exports", "@ember/routing/route", "@ember/object", "@ember/service", "ember-changeset", "ember-changeset-validations", "ember-changeset-validations/validators"], function (_exports, _route, _object, _service, _emberChangeset, _emberChangesetValidations, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const resetPasswordValidation = {
    password: (0, _validators.validateLength)({
      min: 8,
      message: "Your password must be at least 8 characters."
    }),
    passwordConfirmation: (0, _validators.validateConfirmation)({
      on: 'password'
    })
  };

  var _default = _route.default.extend({
    navbarSettings: (0, _service.inject)(),
    store: (0, _service.inject)(),

    beforeModel() {
      (0, _object.set)(this, 'navbarSettings.title', 'Reset Password');
    },

    model(params) {
      return (0, _object.get)(this, 'store').findRecord('reset-password', params.resetToken).then(record => {
        return {
          record: record,
          changeset: new _emberChangeset.default(record, (0, _emberChangesetValidations.default)(resetPasswordValidation), resetPasswordValidation)
        };
      });
    }

  });

  _exports.default = _default;
});