define("donor-elf-web/components/journal/cell/stage/task/index", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "donor-elf-web/controllers/funds/fund/journals/journal/index"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div ...attributes>
    {{#if this.standinTrigger}}
      <Journal::Cell::Stage::Task::Trigger
        @task={{@task}}
        @errorSavingTask={{@errorSavingTask}}
        @showAssignedUsersIfPresent={{@showAssignedUsersIfPresent}}
        @onTaskSaving={{@onTaskSaving}}
        @onTaskSaveError={{@onTaskSaveError}}
        @onTaskSaved={{@onTaskSaved}}
        {{on 'click' this.removeStandinTrigger}}
      />
    {{else}}
      <BasicDropdown
        @horizontalPosition='center'
        @initiallyOpened={{true}}
      as |dd|>
        <dd.Trigger>
          <Journal::Cell::Stage::Task::Trigger
            @task={{@task}}
            @errorSavingTask={{@errorSavingTask}}
            @showAssignedUsersIfPresent={{@showAssignedUsersIfPresent}}
            @onTaskSaving={{@onTaskSaving}}
            @onTaskSaveError={{@onTaskSaveError}}
            @onTaskSaved={{@onTaskSaved}}
          />
        </dd.Trigger>
        <dd.Content class='de-dropdown-content mt-2 p-2 tw-min-w-48 tw-max-w-96'>
          <Task::Info
            @task={{@task}}
            @displayJournalInfo={{false}}
            @onlyEdit={{true}}
            @showCopyIcon={{true}}
            @copyAndPasteAllConfirmation={{this.copyAndPasteAllConfirmation}}
            @onCopyTask={{fn this.handleCopyTask dd}}
            @onTaskSaving={{@onTaskSaving}}
            @onTaskSaveError={{@onTaskSaveError}}
            @onCancelEditTask={{close-dropdown dd}}
            @onTaskSaved={{close-dropdown dd @onTaskSaved}}
            @onTaskDeleted={{close-dropdown dd @onTaskDeleted}}
          />
        </dd.Content>
      </BasicDropdown>
    {{/if}}
  </div>
  */
  {
    "id": "8bFm8dTL",
    "block": "[[[11,0],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"standinTrigger\"]],[[[1,\"    \"],[8,[39,1],[[4,[38,2],[\"click\",[30,0,[\"removeStandinTrigger\"]]],null]],[[\"@task\",\"@errorSavingTask\",\"@showAssignedUsersIfPresent\",\"@onTaskSaving\",\"@onTaskSaveError\",\"@onTaskSaved\"],[[30,2],[30,3],[30,4],[30,5],[30,6],[30,7]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,3],null,[[\"@horizontalPosition\",\"@initiallyOpened\"],[\"center\",true]],[[\"default\"],[[[[1,\"\\n      \"],[8,[30,8,[\"Trigger\"]],null,null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,1],null,[[\"@task\",\"@errorSavingTask\",\"@showAssignedUsersIfPresent\",\"@onTaskSaving\",\"@onTaskSaveError\",\"@onTaskSaved\"],[[30,2],[30,3],[30,4],[30,5],[30,6],[30,7]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[8,[30,8,[\"Content\"]],[[24,0,\"de-dropdown-content mt-2 p-2 tw-min-w-48 tw-max-w-96\"]],null,[[\"default\"],[[[[1,\"\\n        \"],[8,[39,4],null,[[\"@task\",\"@displayJournalInfo\",\"@onlyEdit\",\"@showCopyIcon\",\"@copyAndPasteAllConfirmation\",\"@onCopyTask\",\"@onTaskSaving\",\"@onTaskSaveError\",\"@onCancelEditTask\",\"@onTaskSaved\",\"@onTaskDeleted\"],[[30,2],false,true,true,[30,0,[\"copyAndPasteAllConfirmation\"]],[28,[37,5],[[30,0,[\"handleCopyTask\"]],[30,8]],null],[30,5],[30,6],[28,[37,6],[[30,8]],null],[28,[37,6],[[30,8],[30,7]],null],[28,[37,6],[[30,8],[30,9]],null]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[8]]]]],[1,\"\\n\"]],[]]],[13]],[\"&attrs\",\"@task\",\"@errorSavingTask\",\"@showAssignedUsersIfPresent\",\"@onTaskSaving\",\"@onTaskSaveError\",\"@onTaskSaved\",\"dd\",\"@onTaskDeleted\"],false,[\"if\",\"journal/cell/stage/task/trigger\",\"on\",\"basic-dropdown\",\"task/info\",\"fn\",\"close-dropdown\"]]",
    "moduleName": "donor-elf-web/components/journal/cell/stage/task/index.hbs",
    "isStrictMode": false
  });

  let JournalCellStageTaskComponent = (_class = class JournalCellStageTaskComponent extends _component2.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "standinTrigger", _descriptor, this);

      _defineProperty(this, "copyAndPasteAllConfirmation", _index.JOURNAL_COPY_AND_PASTE_ALL_CONFIRMATION);
    }

    removeStandinTrigger() {
      this.standinTrigger = false;
    }

    handleCopyTask(dropdown, copyArgs) {
      if (copyArgs.pasteAll) {
        dropdown.actions.close();
      }

      this.args.onCopyTask(copyArgs);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "standinTrigger", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "removeStandinTrigger", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeStandinTrigger"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleCopyTask", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleCopyTask"), _class.prototype)), _class);
  _exports.default = JournalCellStageTaskComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, JournalCellStageTaskComponent);
});