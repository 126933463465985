define("donor-elf-web/storages/local-settings", ["exports", "ember-local-storage/local/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const Storage = _object.default.extend();

  Storage.reopenClass({
    initialState() {
      return {
        'fundSummaryChartShowIncome': true,
        'fundSummaryChartShowExpense': false,
        'fundSummaryChartShowGoal': true,
        'fundSummaryChartShowBalance': true,
        'adminUsersIndexSortByProperty': 'user.displayName',
        'adminUsersIndexSortByDirection': 'asc',
        'journalShowLastIntervalCount': 0,
        'journalDefaultReportHideStageNames': null // if present, then CSV string of stage names

      };
    }

  });
  var _default = Storage;
  _exports.default = _default;
});