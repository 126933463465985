define("donor-elf-web/helpers/get-by-property-name", ["exports", "@ember/component/helper", "@ember/debug"], function (_exports, _helper, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // This is the same helper as {{get}} but this allows the propertyName to have a 
  // period (.) in it. The {{get}} helper treats a period as a child object.
  var _default = (0, _helper.helper)(function ([object, propertyName]) {
    (false && !(typeof propertyName === 'string') && (0, _debug.assert)('propertyName must be a string', typeof propertyName === 'string'));
    return object[propertyName];
  });

  _exports.default = _default;
});