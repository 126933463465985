define("donor-elf-web/modifiers/on-keydown", ["exports", "ember-modifier"], function (_exports, _emberModifier) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // {{on-keydown 'Escape' this.someFunction}}
  var _default = (0, _emberModifier.modifier)(function onEnter(element, [eventKey, action]) {
    let handler = generateOnKeyDownHandler(action, eventKey);
    element.addEventListener('keydown', handler);
    return () => {
      element.removeEventListener('keydown', handler);
    };
  });

  _exports.default = _default;

  function generateOnKeyDownHandler(action, eventKey) {
    return e => {
      if (e.key === eventKey) {
        action(e);
      }
    };
  }
});