define("donor-elf-web/templates/funds/fund/contacts/group-by/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "EAeBSV29",
    "block": "[[[10,0],[14,0,\"row row-bottom-margin\"],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[[36,0]]]],[12],[1,\"\\n    \"],[10,\"ol\"],[14,0,\"breadcrumb mb-0\"],[12],[1,\"\\n      \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@route\"],[\"funds.fund.contacts.index\"]],[[\"default\"],[[[[1,\"\\n          Contacts\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[1,\"\\n        Group by\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n  \"],[10,\"table\"],[14,0,\"table border-bottom\"],[12],[1,\"\\n    \"],[10,\"thead\"],[12],[1,\"\\n      \"],[10,\"tr\"],[12],[1,\"\\n        \"],[10,\"th\"],[14,0,\"border-top-0 pt-0\"],[12],[1,\"Property\"],[13],[1,\"\\n        \"],[10,\"th\"],[14,0,\"border-top-0 pt-0 text-right\"],[12],[1,\"# Contacts\"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,1,[\"propertyLabels\"]]],null]],null],null,[[[1,\"        \"],[10,\"tr\"],[12],[1,\"\\n          \"],[10,\"td\"],[12],[1,\"\\n            \"],[8,[39,1],null,[[\"@route\",\"@model\"],[\"funds.fund.contacts.group-by.property\",[30,2]]],[[\"default\"],[[[[1,\"\\n              \"],[1,[30,2]],[1,\"\\n            \"]],[]]]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,\"td\"],[14,0,\"text-right\"],[12],[1,[28,[35,5],[[28,[37,6],[[30,1,[\"propertyCount\"]],[30,2]],null]],null]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"text-muted\"],[12],[1,\"\\n    Only \\\"List\\\" and \\\"Yes / No\\\" properties can be grouped together\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@model\",\"property\"],false,[\"default-form-columns\",\"link-to\",\"form-card\",\"each\",\"-track-array\",\"format-number\",\"get\"]]",
    "moduleName": "donor-elf-web/templates/funds/fund/contacts/group-by/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});